import _ from "lodash";
import placeholder from "../assets/images/placeholder.jpg";
import videoPlaceholder from "../assets/images/placeholder_video.jpg";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Grid from "@material-ui/core/Grid";
import StopIcon from "@material-ui/icons/Stop";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import Tooltip from "@material-ui/core/Tooltip";
import {useIntl} from "react-intl";
import IMG_FROZEN from '../assets/icons/status-frozen.svg';
import IMG_IN_EXPORT from '../assets/icons/status-in-export.svg';
import IMG_KO from '../assets/icons/status-ko.svg';
import IMG_OK from '../assets/icons/status-ok.svg';
import IMG_TO_EXPORT from '../assets/icons/icons8-clock.svg';
import * as moment from "moment";
import {search_types_mapping} from "../assets/resources/search_types_mapping";
import {ASSET_STATUS, COMPOSITION_STATUS_SHOWROOM, PRODUCT_STATUS_SHOWROOM} from "./constant";

export const onSecondErrorSrc = (event, src2) => {
    event.target.src = src2;
    event.target.onerror = (event) => event.target.setAttribute("src", placeholder)
};

export const onErrorSrc = (event, isVideo) => {
    isVideo ? event.target.src = videoPlaceholder : event.target.src = placeholder;
};


/**
 * This function is used to check the access to a specific application for current logged user.
 *
 * @param {string} current_app: string identifier for application that is protected and accessible just for some roles.
 * @param {List} applications: list of identifier string applications, get by be, basing on roles of the current user.
 * @returns {boolean}: true if current_app is included or there is '*' (just admin), otherwise false.
 */
export const checkApplication = (current_app, applications) => {
    return applications.includes('*') || applications.includes(current_app);
};


/**
 * This function is used to check the access to a specific action for current logged user.
 *
 * @param {string} current_act: string identifier for action that is protected and accessible just for some roles.
 * @param {List} actions: list of identifier string actions, get by be, basing on roles of the current user.
 * @returns {boolean}: true if current_act is included or there is '*' (just admin), otherwise false.
 */
export const checkAction = (current_act, actions) => {
    return actions.includes('*') || actions.includes(current_act);
};

/**
 * This function is used to render cell content of outcome result for table in MasterDataLoadingContainer,
 * SessioniOrfaneContainer, UploadDigitalAssetContainer.
 *
 * @param {object} rowData: data about the current row.
 * @param {object} classes: object of css classes classes.
 * @returns {JSX.Element|null}
 */
export const renderTableResult = (rowData, classes) => {
    if (rowData && ((rowData.messages && rowData.messages.length > 0 && rowData.done === false) || rowData.done)) {
        let successClass = null;
        let message;

        if (rowData.done && rowData.messages && rowData.messages.length === 0) {
            successClass = classes.success;
            message = 'OK';
        } else if (rowData.done && rowData.messages && rowData.messages.length > 0) {
            successClass = classes.success;
            message = 'OK - '.concat(rowData.messages.join(', '));
        } else {
            if (rowData.messages.length > 0) {
                //console.log('Ale: ', rowData);
                if (rowData.messages.length > 1) {
                    const tmpMsg = 'KO - '.concat(rowData.messages.join(', '));
                    message = tmpMsg.substring(0, tmpMsg.length - 1);
                } else {
                    message = 'KO - '.concat(rowData.messages[0]);
                }
            } else {
                message = 'KO - Unknown reason.';
            }
        }
        return <Typography className={`${successClass}`}>{message}</Typography>;
    } else {
        return null;
    }
};


/**
 * This function is used to get result message rendered into cell of outcome result for download Xlsx in
 * MasterDataLoadingContainer
 *
 * @param {object} rowData: data about the current row.
 * @returns {string|null}
 */
export const getResultMessage = (rowData) => {
    if (rowData && ((rowData.messages && rowData.messages.length > 0 && rowData.done === false) || rowData.done)) {
        let message;

        if (rowData.done && rowData.messages && rowData.messages.length === 0) {
            message = 'OK';
        } else if (rowData.done && rowData.messages && rowData.messages.length > 0) {
            message = 'OK - '.concat(rowData.messages.join(', '));
        } else {
            if (rowData.messages.length > 0) {
                if (rowData.messages.length > 1) {
                    const tmpMsg = 'KO - '.concat(rowData.messages.join(', '));
                    message = tmpMsg.substring(0, tmpMsg.length - 1);
                } else {
                    message = 'KO - '.concat(rowData.messages[0]);
                }
            } else {
                message = 'KO - Unknown reason.';
            }
        }
        return message;
    } else {
        return null;
    }
};


/**
 * This function is used to return background color css class using the value of asset status.
 *
 * @param {string} value: asset status value.
 * @param {object} classes: generic css classes object.
 * @returns {{backgroundColor: *, color: string}|{backgroundColor: *}}
 */
export const backgroundColorUsingStatus = (value, classes) => {
    let statusCssClass;

    switch (value) {
        case ASSET_STATUS.taken:
            statusCssClass = classes.backgroundColorTakenStatus;
            break;
        case ASSET_STATUS.toPostProduce:
            statusCssClass = classes.backgroundColorToBePostProductedStatus;
            break;
        case ASSET_STATUS.postProducedNew:
            statusCssClass = classes.backgroundColorNewPostProductedStatus;
            break;
        case ASSET_STATUS.postProducedApproved:
            // case "post prodotta approvata":
            statusCssClass = classes.backgroundColorPostProductedApprovedStatus;
            break;
        case ASSET_STATUS.postProducedRejected:
            // case "post prodotta rifiutata":
            statusCssClass = classes.backgroundColorPostProductedRejectedStatus;
            break;
        case "disabled":
            statusCssClass = classes.backgroundColorDisabled;
            break;
        default:
            statusCssClass = classes.backgroundColorTakenStatus;
            break;
    }

    return statusCssClass;
};


/**
 * This function is used to return text color css class using the value of asset status.
 *
 * @param {string} status: asset status value
 * @param {object} classes: generic css classes object
 * @returns {{color: *}}
 */
export const textColorUsingStatus = (status, classes) => {
    let statusCssClass;

    switch (status) {
        case ASSET_STATUS.taken:
            statusCssClass = classes.textTakenStatus;
            break;
        case ASSET_STATUS.toPostProduce:
            statusCssClass = classes.textToBePostProductedStatus;
            break;
        case ASSET_STATUS.postProducedNew:
            statusCssClass = classes.textNewPostProductedStatus;
            break;
        case ASSET_STATUS.postProducedApproved:
            // case 'post prodotta approvata':
            statusCssClass = classes.textPostProductedApprovedStatus;
            break;
        case ASSET_STATUS.postProducedRejected:
            // case 'post prodotta rifiutata':
            statusCssClass = classes.textPostProductedRejectedStatus;
            break;
        default:
            statusCssClass = classes.textTakenStatus;
            break;
    }

    return statusCssClass;
};

/**
 * This function is used to get the tooltip element that describe the legend about color status of assets.
 *
 * @param {string} position: position of tooltip.
 * @param {object} genericStyle: generic css classes object.
 * @returns {JSX.Element}
 */
export const GetTooltipStatusColorComponent = (position, genericStyle) => {
    const intl = useIntl();

    return (
        <Tooltip placement={position}
                 title={
                     <Grid container
                           direction="column"
                           justify="flex-start"
                           alignItems="flex-start">
                         <Grid item>
                             <div className={genericStyle.tooltipContainer}>
                                 <StopIcon
                                     className={`${genericStyle.tooltipTextElement} ${genericStyle.textTakenStatus}`}/>
                                 <Typography>
                                     {intl.formatMessage({
                                         id: 'tooltip.label.taken',
                                         defaultMessage: 'Taken'
                                     })}
                                 </Typography>
                             </div>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <StopIcon
                                         className={`${genericStyle.tooltipTextElement} ${genericStyle.textToBePostProductedStatus}`}/>
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.to_be_post_produced',
                                             defaultMessage: 'To be post-produced'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <StopIcon
                                         className={`${genericStyle.tooltipTextElement} ${genericStyle.textNewPostProductedStatus}`}
                                     />
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.post_produced_new',
                                             defaultMessage: 'Post-produced new'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <StopIcon
                                         className={`${genericStyle.tooltipTextElement} ${genericStyle.textPostProductedRejectedStatus}`}
                                     />
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.post_produced_rejected',
                                             defaultMessage: 'Post-produced rejected'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <StopIcon
                                         className={`${genericStyle.tooltipTextElement} ${genericStyle.textPostProductedApprovedStatus}`}/>
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.post_produced_approved',
                                             defaultMessage: 'Post-produced approved'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                         </Grid>
                     </Grid>
                 }
                 classes={{ tooltip: genericStyle.tooltip }}>
            <NotListedLocationIcon/>
        </Tooltip>
    )
};


/**
 * This function is used to get the tooltip element that describe the legend about color status of assets.
 *
 * @param {string} position: position of tooltip.
 * @param {object} genericStyle: generic css classes object.
 * @returns {JSX.Element}
 */
export const GetTooltipExportStatusComponent = (position, genericStyle) => {
    const intl = useIntl();

    return (
        <Tooltip placement={position}
                 title={
                     <Grid container
                           direction="column"
                           justify="flex-start"
                           alignItems="flex-start">
                         <Grid item>
                             <div className={genericStyle.tooltipContainer}>
                                 <img src={IMG_FROZEN} alt="" className={genericStyle.tooltipExportIcons}/>
                                 <Typography>
                                     {intl.formatMessage({
                                         id: 'tooltip.label.export.frozen.noldd',
                                         defaultMessage: 'Frozen - NO LDD'
                                     })}
                                 </Typography>
                             </div>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <img src={IMG_IN_EXPORT} alt="" className={genericStyle.tooltipExportIcons}/>
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.export.in.export',
                                             defaultMessage: 'In Export'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <img src={IMG_KO} alt="" className={genericStyle.tooltipExportIcons}/>
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.export.sent.ko',
                                             defaultMessage: 'Sent KO'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <img src={IMG_OK} alt="" className={genericStyle.tooltipExportIcons}/>
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.export.sent.oK',
                                             defaultMessage: 'Sent OK'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                             <Grid item>
                                 <div className={genericStyle.tooltipContainer}>
                                     <img src={IMG_TO_EXPORT} alt="" className={genericStyle.tooltipExportIcons}/>
                                     <Typography>
                                         {intl.formatMessage({
                                             id: 'tooltip.label.export.waiting.for.export',
                                             defaultMessage: 'Waiting for export'
                                         })}
                                     </Typography>
                                 </div>
                             </Grid>
                         </Grid>
                     </Grid>
                 }
                 classes={{ tooltip: genericStyle.tooltip }}>
            <NotListedLocationIcon/>
        </Tooltip>
    )
};


/**
 * This function is used to filter seasons using param active.
 * @param {List} seasons: list of seasons fetch by be.
 * @returns {object[] || []}: list of filtered seasons or empty list.
 */
export const filterActiveSeasons = (seasons) => {
    return _.filter(seasons, season => season.active)
};


export const getFileName = (downloadType) => {
    const format = "YYYYMMDD_hhmmss";

    switch (downloadType) {
        case 'fullTableCsv':
            return 'photolab-full_table-'.concat(moment().format(format), '.csv');
        case 'searchTableDetailedAssetsExcel':
            return 'photolab-details_assets-'.concat(moment().format(format), '.xlsx');
        case 'searchTableCompactAssetsExcel':
            return 'photolab-compact_assets-'.concat(moment().format(format), '.xlsx');
        case 'searchTableAssetsExcel':
            return 'photolab-assets-'.concat(moment().format(format), '.xlsx');
        case 'searchTableProductsExcel':
            return 'photolab-products-'.concat(moment().format(format), '.xlsx');
        case 'searchTableAssetsImageZip':
            return 'photolab-assets-'.concat(moment().format(format), '.zip');
        case 'resultTableMasterDataExcel':
            return 'photolab-masterdata_outcome-'.concat(moment().format(format), '.xlsx');
        default:
            return 'download.xlsx'
    }
}

/*const getTranslationStatus = (status, intl) => {
    console.log("STATUSSSSSSSSSSSSSSS ")

    switch (status) {
        case 'scattata':
            return intl.formatMessage({ id: "tooltip.label.taken", defaultMessage: "Taken" })
        case 'Da post produrre':
            return intl.formatMessage({
                id: "tooltip.label.to_be_post_producted",
                defaultMessage: "To be post-producted"
            })
        case 'Post prodotta nuova':
            return intl.formatMessage({ id: "tooltip.label.post_producted_new", defaultMessage: "Post-producted new" })
        case 'Post prodotta approvata':
        case 'post prodotta approvata':
            return intl.formatMessage({
                id: "tooltip.label.post_producted_approved",
                defaultMessage: "Post-producted approved"
            })
        case 'Post prodotta rifiutata':
        case 'post prodotta rifiutata':
            return intl.formatMessage({
                id: "tooltip.label.post_producted_rejected",
                defaultMessage: "Post-producted rejected"
            })
        default:
            return ""
    }

}*/

/**
 * This function is used to render fields for ViewMonitoring application (in VM and SearchResults). For this reason the
 * same field could have a different key. Furthermore, fields should be shown only if the key is present inside the
 * dataset given by backend (in this way we implement functional filters for differente roles).
 *
 * @param data {Array}: dataset given by backend.
 * @param genericStyle: common style.
 * @param intl: hooks function to translate text.
 * @param type {string}: define the view type. The allow value are "vm" or "search".
 * @returns {any[]}
 */
export const getSingleViewData = (data, genericStyle, intl, type) => {
    const vmKeys = [
        { label: { translateKey: 'product.asset.view', defaultMessage: 'View' }, key: 'original_view' },
        { label: { translateKey: 'search.keys.name', defaultMessage: 'Name' }, key: 'asset_name' },
        {
            label: { translateKey: 'view.management.label.shooting.date', defaultMessage: 'Shooting Date' },
            key: 'original_insert_date'
        },
        { label: { translateKey: 'product.asset.submitter', defaultMessage: 'Submitter' }, key: 'submitter_original' },
        { label: { translateKey: 'product.asset.status', defaultMessage: 'Status' }, key: 'status_name' },
        {
            label: { translateKey: 'view.management.status.date', defaultMessage: 'Status Date' },
            key: 'master_status_date'
        },
        {
            label: {
                translateKey: 'product.asset.post_produced.submitter',
                defaultMessage: 'Post Producted Submitter'
            },
            key: 'master_submitter'
        },
        { label: { translateKey: 'product.asset.export_type', defaultMessage: 'Export Type' }, key: 'export_type' },
        {
            label: { translateKey: 'product.asset.export_status', defaultMessage: 'Export Status' },
            key: 'export_status'
        },
        {
            label: { translateKey: 'view.management.error.status', defaultMessage: 'Error Status' },
            key: 'error_status'
        },
        { label: { translateKey: 'view.management.report.date', defaultMessage: 'Report Date' }, key: 'report_date' },
        { label: { translateKey: 'view.management.export.date', defaultMessage: 'Export Date' }, key: 'export_date' },
        { label: { translateKey: 'product.ynap_code', defaultMessage: 'YNAP CODE' }, key: 'ynap_code' }
    ];
    const searchVmKeys = [
        { label: { translateKey: 'product.asset.view', defaultMessage: 'View' }, key: 'view' },
        { label: { translateKey: 'search.keys.name', defaultMessage: 'Name' }, key: 'asset_name' },
        {
            label: { translateKey: 'view.management.label.shooting.date', defaultMessage: 'Shooting Date' },
            key: 'date_hour_shoot'
        },
        { label: { translateKey: 'product.asset.submitter', defaultMessage: 'Submitter' }, key: 'submitter' },
        { label: { translateKey: 'product.asset.status', defaultMessage: 'Status' }, key: 'status' },
        {
            label: { translateKey: 'view.management.status.date', defaultMessage: 'Status Date' },
            key: 'post_produced_approval_reject_date'
        },
        {
            label: {
                translateKey: 'product.asset.post_produced.submitter',
                defaultMessage: 'Post Producted Submitter'
            },
            key: 'sent_by'
        },
        { label: { translateKey: 'product.asset.export_type', defaultMessage: 'Export Type' }, key: 'distribution' },
        {
            label: { translateKey: 'product.asset.export_status', defaultMessage: 'Export Status' },
            key: 'export_status'
        },
        {
            label: { translateKey: 'view.management.error.status', defaultMessage: 'Error Status' },
            key: 'error_status'
        },
        { label: { translateKey: 'view.management.report.date', defaultMessage: 'Report Date' }, key: 'report_date' },
        { label: { translateKey: 'view.management.export.date', defaultMessage: 'Export Date' }, key: 'export_date' },
        { label: { translateKey: 'product.ynap_code', defaultMessage: 'YNAP CODE' }, key: 'ynap_code' },
        { label: { translateKey: 'search.keys.target', defaultMessage: 'E2S Target' }, key: 'target' }
    ];

    let fieldsList;
    const dataKeys = Object.keys(data);

    if (type === 'vm') {
        fieldsList = vmKeys
            .filter(keyObj => dataKeys.includes(keyObj.key))
            .map(keyObj => (
                <Typography className={genericStyle.fieldview} key={keyObj.key}>
                    {intl.formatMessage({
                        id: keyObj.label.translateKey,
                        defaultMessage: keyObj.label.defaultMessage,
                    })}
                    : {data[keyObj.key] ? data[keyObj.key] : ""}
                </Typography>
            ));
    } else {
        fieldsList = searchVmKeys
            .filter(keyObj => dataKeys.includes(keyObj.key))
            .map(keyObj => (
                <Typography className={genericStyle.fieldview} key={keyObj.key}>
                    {intl.formatMessage({
                        id: keyObj.label.translateKey,
                        defaultMessage: keyObj.label.defaultMessage,
                    })}
                    : {data[keyObj.key] ? data[keyObj.key] : ""}
                </Typography>
            ));
    }

    return fieldsList;
};


/**
 * This function is used to get css color border of image in composition showroom galleries tab.
 *
 * @param classes {object}: style of component.
 * @param isDraft {boolean}
 * @param lookApproved {boolean}: image's look is approved or not.
 */
export const getCompositionShowroomImageBorderColor = (classes, isDraft, lookApproved) => {
    if (isDraft) {
        return classes.draftImgStyle;
    } else if (lookApproved) {
        return classes.approvedLookImageStyle;
    }
    return null;
};

/**
 * This function is used to get css color border of image in product showroom galleries.
 *
 * @param classes {object}: generic style component.
 * @param status {string}: NEW, UNCHANGED, DELETED
 */
export const getProductShowroomImageBorderColor = (classes, status) => {
    let borderCssClass = null;

    switch (status) {
        case PRODUCT_STATUS_SHOWROOM.deleted:
            borderCssClass = classes.deletedProductImgStyle;
            break;
        case PRODUCT_STATUS_SHOWROOM.new:
            borderCssClass = classes.newProductImgStyle;
            break;
        default:
            break;
    }
    return borderCssClass;
};

/**
 * This function is used to get css color border of image in composition showroom galleries.
 *
 * @param classes {object}: generic style component.
 * @param status {string}: NEW, UNCHANGED, DELETED
 */
export const getCompositionShowroomProductImageBorderColor = (classes, status) => {
    let borderCssClass = null;

    switch (status) {
        case COMPOSITION_STATUS_SHOWROOM.complete:
            borderCssClass = classes.completeProductImgStyle;
            break;
        case COMPOSITION_STATUS_SHOWROOM.notValid:
            borderCssClass = classes.notValidProductImgStyle;
            break;
        case COMPOSITION_STATUS_SHOWROOM.wrongContext:
            borderCssClass = classes.wrongContextProductImgStyle;
            break;
        case COMPOSITION_STATUS_SHOWROOM.placeholder:
            borderCssClass = classes.placeholderProductImgStyle;
            break;
        case COMPOSITION_STATUS_SHOWROOM.placeholderFromBarcode:
            borderCssClass = classes.placeholderFromBarcodeProductImgStyle;
            break;
        default:
            break;
    }

    return borderCssClass;
};

/**
 * This function is used to get css opacity for deleted product images into product showroom application.
 *
 * @param classes {object}: generic style component.
 * @param status {string}: NEW, UNCHANGED, DELETED
 */
export const getProductImageOpacity = (classes, status) => {
    return status === PRODUCT_STATUS_SHOWROOM.deleted ? classes.productImage : null;
}

/**
 * This function is used to extract filter used to communicate with server.
 *
 * @param viewNumberType {int}
 * @returns {string || null}: filter applied and used to communicate with server.
 */
export const getAppliedFilter = (viewNumberType) => {
    switch (viewNumberType) {
        case 1:
            //return 'polaroid';
        case 2:
            //return 'final';
        case 3:
            return 'all';
        default:
            return null;
    }
};

/**
 * This function is used to format columns list used fot MaterialTable.
 *
 * @param intl: intl hooks result to translate columns header.
 * @param columnList {Array}: list of columns.
 * @returns list of formatted columns.
 */
export const validateColumnsMaterialSimpleTable = (intl, columnList) => {
    return columnList.map((column) => {
        if (column.render) {
            return {
                field: column.field,
                title: intl.formatMessage({
                    id: column.title.id,
                    defaultMessage: column.title.default,
                }),
                render: column.render
            }
        } else {
            return {
                field: column.field,
                title: intl.formatMessage({
                    id: column.title.id,
                    defaultMessage: column.title.default,
                })
            }
        }
    });
};

/**
 * This function is used to create the search body request used into: Search App, Sessioni Orfane App, Product Showroom.
 * @param filtersList {Object}: [{search_type_guid: "", name: "", operators: [], search_type: "", search_operator_guid: "", operator: "", and_or: "", value: stringa, lista, booleano}]
 */
export const createSearchBodyRequest = (filtersList) => {
    let bodyRequest = [];

    filtersList.forEach((filter) => {
        switch (filter.search_type) {
            case search_types_mapping.SINGLE_INPUT:
            case search_types_mapping.SINGLE_AUTOCOMPLETE:
            case search_types_mapping.CHECKBOX_AS_DROPDOWN:
                bodyRequest.push({
                    search_type_guid: filter.search_type_guid,
                    name: filter.name,
                    search_operator_guid: filter.search_operator_guid,
                    and_or: filter.and_or,
                    value: filter.value,
                });
                break;
            case search_types_mapping.MULTIPLE_INPUT:
                bodyRequest.push({
                    search_type_guid: filter.search_type_guid,
                    name: filter.name,
                    search_operator_guid: filter.search_operator_guid,
                    and_or: filter.and_or,
                    value: filter.value.text.stringList,
                });
                break;
            case search_types_mapping.FILE:
                break;
            case search_types_mapping.MULTIPLE_AUTOCOMPLETE:
                bodyRequest.push({
                    search_type_guid: filter.search_type_guid,
                    name: filter.name,
                    search_operator_guid: filter.search_operator_guid,
                    and_or: filter.and_or,
                    value: filter.value.map((result) => {
                        return { elem: result.elem };
                    }),
                });
                break;
            case search_types_mapping.RANGE_DATE:
                bodyRequest.push({
                    search_type_guid: filter.search_type_guid,
                    name: filter.name,
                    search_operator_guid: filter.search_operator_guid,
                    and_or: filter.and_or,
                    value: {
                        date_start: filter.value.from && filter.value.from.isMoment
                            ? filter.value.from.format("D-M-Y") : filter.value.from && !filter.value.from.isMoment ? moment(filter.value.from).format("D-M-Y")
                                : '',
                        date_end: filter.value.to && filter.value.to.isMoment
                            ? filter.value.to.format("D-M-Y") : filter.value.to && !filter.value.to.isMoment ? moment(filter.value.to).format("D-M-Y")
                                : '',
                    },
                });
                break;
            default:
                break;
        }
    });
    return bodyRequest;
};

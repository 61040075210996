import {GET_PRODUCT_TYPE_FAILURE, GET_PRODUCT_TYPE_REQUEST, GET_PRODUCT_TYPE_SUCCESS} from "../../actions/uploadDigitalAssets/productType";

const initialState = {product_type: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_TYPE_REQUEST:
            return {
                loadingProductType: true
            };
        case GET_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                loadingProductType: false,
                product_type: action.product_type,
            };
        case GET_PRODUCT_TYPE_FAILURE:
            return {
                ...state,
                loadingProductType: false,
                error: action.error
            }
        default:
            return state;
    }
}
import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const GET_SET_REQUEST = 'GET_SET_REQUEST';
export const GET_SET_SUCCESS = 'GET_SET_SUCCESS';
export const GET_SET_FAILURE = 'GET_SET_FAILURE';

const localStorageService = LocalStorage.getService();

export const getSetRequest = () => {
    return {type: GET_SET_REQUEST}
};

export const getSetSuccess = (set = {}) => {
    return {type: GET_SET_SUCCESS, set}
};

export const getSetFailure = (error) => {
    return {type: GET_SET_FAILURE, error}
};

export const startGetSet = () => {
    console.log('Start startGetSet');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getSetRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/set/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response SET search ", response.data);
                    dispatch(getSetSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getSetFailure(error));
            });
    };
};

import {
    CLEAR_IMAGE_HD,
    POST_FETCH_ASSET_IMAGE_HD_REQUEST,
    POST_FETCH_ASSETS_IMAGE_HD_FAILURE,
    POST_FETCH_ASSETS_IMAGE_HD_SUCCESS
} from "../actions/imageHd";
import {ACTION_REQUEST} from "../../utils/constant";

const initialState = { loading: false, action: null, error: null, imgUrlHD: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_FETCH_ASSET_IMAGE_HD_REQUEST:
            return {
                loading: true,
                action: ACTION_REQUEST.post,
                error: null,
                imgUrlHD: null
            };
        case POST_FETCH_ASSETS_IMAGE_HD_SUCCESS:
            return {
                ...state,
                loading: false,
                imgUrlHD: action.imgUrlHD
            };
        case POST_FETCH_ASSETS_IMAGE_HD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_IMAGE_HD:
            return initialState;
        default:
            return state;
    }
}
import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";
import {startGetShowroomGalleries} from "./galleries";

const localStorageService = LocalStorage.getService();

export const CLEAR_UPLOAD_ASSETS = "CLEAR_UPLOAD_ASSETS";
export const POST_UPLOAD_ASSETS_REQUEST = "POST_UPLOAD_ASSETS_REQUEST";
export const POST_UPLOAD_ASSETS_SUCCESS = "POST_UPLOAD_ASSETS_SUCCESS";
export const PUT_ASSET_SUCCESS = "PUT_ASSET_SUCCESS";
export const GET_SIGNED_URL_ASSET_FAILURE = "GET_SIGNED_URL_ASSET_FAILURE";
export const PUT_ASSET_FAILURE = "PUT_ASSET_FAILURE";
export const LOAD_ASSETS_FAILED = "LOAD_ASSETS_FAILED";
export const PHYSICAL_UPLOAD_COMPLETE = "PHYSICAL_UPLOAD_COMPLETE";

export const clearUploadShowroomAssets = () => {
    return { type: CLEAR_UPLOAD_ASSETS }
};

export const uploadShowroomAssetsRequest = (totalAssets) => {
    return { type: POST_UPLOAD_ASSETS_REQUEST, totalAssets }
};

export const stopLoadingAssetsShowroom = () => {
    return { type: POST_UPLOAD_ASSETS_SUCCESS }
};

export const putAssetSuccess = (asset) => {
    return { type: PUT_ASSET_SUCCESS, asset }
};

export const getSignedUrlAssetFailure = (error, asset) => {
    return { type: GET_SIGNED_URL_ASSET_FAILURE, asset }
};

export const putAssetFailure = (error, asset) => {
    return { type: PUT_ASSET_FAILURE, asset }
};

export const postLoadAssetsFailed = (error) => {
    return { type: LOAD_ASSETS_FAILED, error }
};

export const physicalUploadComplete = () => {
    return { type: PHYSICAL_UPLOAD_COMPLETE }
};

export const startUploadShowroomAssets = (assets) => {
    let token = localStorageService.getAccessToken();
    const totalAssets = assets.length;
    let currentAssetNumber = 1;

    console.log("CALLING startGetSignedUrlImages with ", assets);
    return (dispatch) => {
        // Clear state from previous upload
        //dispatch(clearUploadShowroomAssets());
        dispatch(uploadShowroomAssetsRequest(assets.length));
        assets.map((asset, index) => {
            console.log('ALE4: ', asset);
            return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/upload/${asset.path}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            })
                .then(function (response) {
                    console.log("Response startGetSignedUrlImages ", response);
                    if (response.status === 200 && response.data !== null) {
                        console.log("Ale (url): ", response.data.url);

                        let extraInfo = {
                            type: asset.type,
                            name: response.data.filename,
                            display_name: response.data.display_filename
                        };
                        console.log("asset extraInfo:", extraInfo);
                        
                        dispatch(uploadAsset(response.data.url, asset, extraInfo, currentAssetNumber, totalAssets));
                    }
                    currentAssetNumber += 1;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    if (error.response.status === 401) dispatch(loggedOut());
                    dispatch(getSignedUrlAssetFailure(error, asset));
                })
        });
    }
};


export const uploadAsset = (url, asset, extraInfo, currentAssetNumber, totalAssets) => {
    console.log("Ale (uploadSingleImage with ", url, asset, typeof asset, currentAssetNumber, totalAssets, ')');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        return axios({
            url: url,
            method: 'PUT',
            headers: {
                'Content-Type': asset.type,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: asset,
        })
            .then(function (response) {
                console.log("Response uploadSingleImage ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log('Ale (response single upload): ', response.data);
                    console.log('Ale (response single upload): ', asset);
                    dispatch(putAssetSuccess({ ...asset, ...extraInfo }));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                dispatch(putAssetFailure(error, asset));
            })
    }
};


export const loadAssets = (is_polaroid, assets, contextId, filter) => {
    console.log("Ale (loadAssets with is_polaroid: ", is_polaroid, '\nassets: ', assets, '\n context: ', contextId, ')');

    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/digital_assets?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify({ is_polaroid: is_polaroid, digital_assets: assets }),
        })
            .then(function (response) {
                console.log("Response loadAssets ", response);
                if (response.status === 201 && response.data !== null) {
                    console.log('Ale (response single upload): ', response.data);
                    dispatch(stopLoadingAssetsShowroom());
                    dispatch(startGetShowroomGalleries(contextId, filter));
                }
            })
            .catch(function (error) {
                // handle error --> 400 body sbagliato/contesto non esistente/no file nell'area di staging
                console.log(error);
                dispatch(postLoadAssetsFailed(error));
            })
    }
};

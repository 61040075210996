import { makeStyles } from "@material-ui/core/styles";

// TODO: manca la separazione tra i due elementi
export const useStyles = makeStyles((theme) => ({
	icon: {
		"& .MuiSvgIcon-root": {
			color: theme.palette.success.main,
		},
	},
	contButton: {
		button: {
			backgroundColor: 'black !important'
		},
	},
}));

import {GET_EXPORT_STATUS_FAILURE, GET_EXPORT_STATUS_REQUEST, GET_EXPORT_STATUS_SUCCESS} from "../actions/exportStatus";

const initialState = { exportStatusList: [] };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EXPORT_STATUS_REQUEST:
            return {
                loading: true
            };
        case GET_EXPORT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                exportStatusList: action.exportStatusList,
            };
        case GET_EXPORT_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}
import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import {getCompositionShowroomImageBorderColor, onErrorSrc, checkAction} from "../../../../utils/CommonFunction";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AccordionElement from "../../AccordionElement";
import IconButtonElement from "../../../IconButtonElement/IconButtonElement";
import PreserveImageRatio from "../../../PreserveImageRatio/PreserveImageRatio";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import {useStyles} from "./AccordionElementGalleryShowroomStyle";
import Tooltip from "@material-ui/core/Tooltip";
import MovieIcon from "@material-ui/icons/Movie";

const AccordionElementGalleryShowroom = props => {
    const { gallery, assetsGallery, notFoundMessage, handleClickImg, logicDeleteHandler, deleteHandler } = props;
    const classes = useStyles();

    if (gallery.id === null && gallery.name === null && gallery.output_type === null) {
        return (
            <AccordionElement id={0}
                              header={'Uploaded digitalAssets'}>
                {assetsGallery.length > 0 ?
                    <Grid container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                          spacing={3}>
                        {assetsGallery.map((asset, index) =>
                            <Grid item
                                  key={index}>
                                <div>
                                    <div className={classes.imgContainer}>
                                        <div className={classes.item}>
                                            <div className={classes.deleteIcon}>
                                                {checkAction("composition.delete_image_gallery", props.auth.actions) &&
                                                    <IconButtonElement
                                                        fontSize="small"
                                                        labelId={'showroom.remove.asset.gallery'}
                                                        label={'Remove asset from gallery.'}
                                                        icon={"delete"}
                                                        onClick={(event) => deleteHandler(event, asset.id)}
                                                    />
                                                }
                                            </div>
                                            <Paper>
                                                <Tooltip title={asset.display_filename}>
                                                    {asset.type === 'image' ?
                                                        <div className={classes.movieFrameContainer}>
                                                            <PreserveImageRatio
                                                                 src={asset.url}
                                                                 height={140}
                                                                 width={100}
                                                                 onClick={(event) => handleClickImg(event, gallery.id, asset.id, index)}
                                                                 onError={(event) => onErrorSrc(event)}
                                                                 className={getCompositionShowroomImageBorderColor(classes, asset.is_polaroid, asset.look ? asset.look.is_approved : false)}
                                                                 alt="Image not found."/>
                                                        </div>
                                                            :
                                                        <div className={classes.movieFrameContainer}>
                                                            <MovieIcon className={classes.movieIcon}/>
                                                            <PreserveImageRatio
                                                                src={asset.frame_url}
                                                                height={140}
                                                                width={100}
                                                                onClick={(event) => handleClickImg(event, gallery.id, asset.id, index)}
                                                                className={getCompositionShowroomImageBorderColor(classes, asset.is_polaroid, asset.look ? asset.look.is_approved : false)}
                                                                isVideo
                                                            />
                                                        </div>
                                                    }
                                                </Tooltip>
                                            </Paper>
                                            {!_.isEmpty(asset.look) &&
                                            <div className={classes.bookmarkIcon}>
                                                <BookmarkIcon/>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <Typography>{asset.label && asset.label.length > 12 ? asset.label.substr(0, 9).concat('...') : asset.label}</Typography>
                                </div>
                            </Grid>
                        )}
                    </Grid> :
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">
                        <Grid>
                            <Typography>{notFoundMessage}</Typography>
                        </Grid>
                    </Grid>
                }
            </AccordionElement>
        )
    } else {
        return (
            <AccordionElement id={gallery.id}
                              header={gallery.name ? gallery.name.concat(' - ', gallery.output_type.name, ' (', gallery.export_status, ')') : 'Undefined'}>
                {assetsGallery.length > 0 ?
                    <Grid container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                          spacing={3}>
                        {assetsGallery.map((asset, index) =>
                            <Grid item
                                  key={index}>
                                <div>
                                    <div className={classes.imgContainer}>
                                        <div className={classes.item}>
                                            <div className={classes.deleteIcon}>
                                                {checkAction("composition.delete_image_gallery", props.auth.actions) &&
                                                    <IconButtonElement
                                                        fontSize="small"
                                                        labelId={'showroom.remove.asset.gallery'}
                                                        label={'Remove asset from gallery.'}
                                                        icon={"delete"}
                                                        onClick={(event) => logicDeleteHandler(event, asset, gallery.id)}
                                                    />
                                                }
                                            </div>
                                            <Paper>
                                                <Tooltip title={asset.display_filename}>
                                                    {
                                                        asset.type === 'image' ?
                                                            <PreserveImageRatio src={asset.url}
                                                                 height={140}
                                                                 width={100}
                                                                 onClick={(event) => handleClickImg(event, gallery.id, asset.id, index)}
                                                                 onError={(event) => onErrorSrc(event)}
                                                                 className={getCompositionShowroomImageBorderColor(classes, asset.is_polaroid, asset.look ? asset.look.is_approved : false)}
                                                                 alt="Image not found."/> :
                                                            <div className={classes.movieFrameContainer}>
                                                                <MovieIcon className={classes.movieIcon}/>
                                                                <PreserveImageRatio
                                                                    src={asset.frame_url}
                                                                    height={140}
                                                                    width={100}
                                                                    onClick={(event) => handleClickImg(event, gallery.id, asset.id, index)}
                                                                    className={getCompositionShowroomImageBorderColor(classes, asset.is_polaroid, asset.look ? asset.look.is_approved : false)}
                                                                    isVideo
                                                                />
                                                            </div>
                                                    }
                                                </Tooltip>
                                            </Paper>
                                            {!_.isEmpty(asset.look) &&
                                            <div className={classes.bookmarkIcon}>
                                                <BookmarkIcon/>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <Typography>{asset.label && asset.label.length > 12 ? asset.label.substr(0, 9).concat('...') : asset.label}</Typography>
                                </div>
                            </Grid>
                        )}
                    </Grid> :
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">
                        <Grid>
                            <Typography>{notFoundMessage}</Typography>
                        </Grid>
                    </Grid>
                }
            </AccordionElement>
        )
    }
};

const mapStateToProps = (state) => ({
    auth: state.authReducer.auth
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AccordionElementGalleryShowroom);
import React from "react";
import MUIDataTable, {TableFilterList} from "mui-datatables";
import {useIntl} from "react-intl";
import {useStyles} from "./FilterableTableStyle";
import ChipElement from "../../ChipElement/ChipElement";

const FilterableTable = (props) => {
    const {title, rowsData, columns, options} = props;
    const intl = useIntl();
    const classes = useStyles();
    const CustomFilterList = (props) => {
        return <TableFilterList {...props} ItemComponent={ChipElement}/>;
    };
    // console.log('RENDERIZZO LA TABELLA!!!', rowsData, columns, options);
    return (
        <MUIDataTable
            title={
                title
                    ? intl.formatMessage({id: title.id, defaultMessage: title.default})
                    : null
            }
            data={rowsData}
            columns={columns}
            options={options}
            className={classes.root}
            components={{
                TableFilterList: CustomFilterList,
            }}
        />
    );
};

export default FilterableTable;

import React from "react";
import {useStyles} from "./DndMacroAreasProductShowroomElementStyle";
import {Typography} from "@material-ui/core";
import {Draggable, Droppable} from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import ReorderIcon from '@material-ui/icons/Reorder';

const DndMacroAreasProductShowroomElement = props => {
    const classes = useStyles();
    const { droppableId, title, elements } = props;

    return (
        <div className={classes.droppableContainer}>
            <Grid container
                  direction={"column"}
                  alignItems={'center'}
                  justify={'center'}>
                <Grid item className={`${classes.gridItem} ${classes.droppableTitle}`}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Grid>
            </Grid>
            <Droppable droppableId={droppableId}>
                {(provided, snapshot) => (
                    <Grid container
                          direction={"row"}
                          alignItems={'flex-start'}
                          justify={'flex-start'}
                          className={classes.draggableContainer}
                          ref={provided.innerRef}
                          {...provided.droppableDrops}>
                        {elements && elements.map((element, index) => (
                            <Draggable draggableId={element.name} index={index} key={element.name}>
                                {(provided, snapshot) => (
                                    <Grid item xs={12}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                          className={classes.gridItem}>
                                        <div className={classes.item}>
                                            <ReorderIcon className={classes.icon}/>
                                            <Typography variant={"button"}>{element.name}</Typography>
                                        </div>
                                    </Grid>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </div>
    )
};

export default DndMacroAreasProductShowroomElement;
export const logs_columns = [
    {
        title: {id: "admin.logs.operation", default: "Operation"},
        field: 'operation'
    },
    {
        title: {id: "login.label.user", default: "User"},
        field: 'user_info'
    },
    {
        title: { id: "admin.logs.info", default: "Info" },
        field: 'info'
    },
    {
        title: { id: "admin.transactions.timestamp", default: "Timestamp" },
        field: 'timestamp'
    }
];

import React, {useEffect, useState} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import FileSaver from "file-saver";
import {getProductImageOpacity, getProductShowroomImageBorderColor, onErrorSrc} from "../../../utils/CommonFunction";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ButtonElement from "../../../components/ButtonElement/ButtonElement";
import {useIntl} from "react-intl";
import IconButtonElement from "../../../components/IconButtonElement/IconButtonElement";
import {startPutProducts} from "../../../store/actions/showroom/product/products";
import {startGetProductsShowroomGallery} from "../../../store/actions/showroom/product/gallery";
import {
    PRODUCT_SHOWROOM_ACTION_REQUEST,
    PRODUCT_STATUS_SHOWROOM,
    SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM,
    SNACKBAR_LEVEL
} from "../../../utils/constant";
import {Typography} from "@material-ui/core";
import {clearExcelInfos, startGetExcelInfo} from "../../../store/actions/showroom/product/galleryActions";
import PreserveImageRatio from "../../../components/PreserveImageRatio/PreserveImageRatio";

const useStyles = makeStyles((theme) => ({
    imageGrid: {
        marginTop: theme.spacing(3),
        height: '760px',
        overflowY: 'scroll'
    },
    footerGrid: {
        marginTop: theme.spacing(2)
    },
    imgContainer: {
        display: 'flex',
        position: "relative",
        textAlign: "center"
    },
    selectCheckbox: {
        '& .MuiButtonBase-root': {
            position: 'absolute',
            zIndex: 2,
            top: 0,
            left: 0,
            color: theme.palette.icon.main
        }
    },
    detailIcon: {
        '& .MuiButtonBase-root': {
            position: 'absolute',
            zIndex: 2,
            top: 0,
            right: 0,
            marginRight: '2px',
            marginTop: '5px',
            color: theme.palette.icon.main
        }
    },
    productLabel: {
        marginTop: theme.spacing(1)
    },
    backgroundColorLabel: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.primary.main
    }
}));


const GalleryTabContainer = props => {
    const classes = useStyles();
    const intl = useIntl();
    const { genericStyle, changesState, setChangesState, setBackdrop, setSnackbar, getProductDetails } = props;

    const [productsGalleryState, setProductsGalleryState] = useState([]);

    /**
     * This is the first effect that should be executed to populate react products state.
     */
    useEffect(() => {
        if (props.galleryInfo.products) {
            const products = _.cloneDeep(props.galleryInfo.products);
            setProductsGalleryState(products);
        }
    }, [props.galleryInfo.products])

    /**
     * This function is the handler to select or deselect products.
     */
    const checkboxHandler = event => {
        const newProductsGalleryState = _.cloneDeep(productsGalleryState);
        const product = _.find(newProductsGalleryState, (product) => product.product_guid === event.target.name && product.status !== PRODUCT_STATUS_SHOWROOM.deleted);

        if (product) {
            product.selected = event.target.checked;
            if (!changesState) setChangesState(true);
        }

        setProductsGalleryState(newProductsGalleryState);
    };

    /**
     * This function is used to save changes communicating with be.
     */
    const saveChangesOnSubmit = event => {
        props.saveProducts(props.galleryInfo.gallery.id, productsGalleryState);
    };

    /**
     * This function is used to select all products with at least an images.
     */
    const selectAllProductsWithOneImageHandler = event => {
        let pendingChanges = false;

        setProductsGalleryState(prevState => {
            const newState = _.cloneDeep(prevState);
            newState.map(product => {
                if (product.has_photo && !product.selected && product.status !== PRODUCT_STATUS_SHOWROOM.deleted) {
                    product.selected = true;
                    if (!pendingChanges) pendingChanges = true;
                }
                return product;
            });

            return newState;
        });
        if (!changesState) setChangesState(pendingChanges);
    };

    /**
     * This function is used to unselect all products.
     */
    const unselectAllProductsWithOneImageHandler = event => {
        let pendingChanges = false;

        setProductsGalleryState(prevState => {
            const newState = _.cloneDeep(prevState);
            newState.map(product => {
                if (product.selected) {
                    product.selected = false;
                    if (!pendingChanges) pendingChanges = true;
                }
                return product;
            });

            return newState;
        });
        if (!changesState) setChangesState(pendingChanges);
    };

    const getExcelUrl = () => {
        if (props.sortingAction.excelUrl) {
            props.clearExcelUrl();
        }
        props.getExcelUrl(props.galleryInfo.gallery.id);
    };

    /**
     * This effect is used to get the excel url created by backend and save into a bucket of google storage and download
     * the file.
     */
    useEffect(() => {
        if (props.sortingAction.action === PRODUCT_SHOWROOM_ACTION_REQUEST.getExcelUrl) {
            if (props.sortingAction.loading) {
                setBackdrop((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.sortingAction.error) {
                    setSnackbar({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.download.excel',
                            defaultMessage: 'Cannot download excel of gallery {galleryName}.'
                        }, { galleryName: props.galleryInfo.gallery.name }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getExcelUrl,
                        type: SNACKBAR_LEVEL.error,
                        showDuration: 5000
                    });
                } else {
                    fetch(props.sortingAction.excelUrl)
                        .then(r => r.blob())
                        .then(blob => {
                            FileSaver.saveAs(blob, props.galleryInfo.gallery.name.concat('.xlsx'));
                            props.clearExcelUrl();
                        })
                        .catch(error => {
                            setSnackbar({
                                show: true,
                                message: intl.formatMessage({
                                    id: 'showroom_product.error.download.excel',
                                    defaultMessage: 'Cannot download excel of gallery {galleryName}.'
                                }, { galleryName: props.galleryInfo.gallery.name }),
                                messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getExcelUrl,
                                type: SNACKBAR_LEVEL.error,
                                showDuration: 5000
                            });
                        })
                }
                setBackdrop((prevState => ({
                    ...prevState,
                    loading: false
                })));
            }
        }
    }, [props.sortingAction]);


    const products = productsGalleryState && productsGalleryState
        .filter(product => props.showDeleted ? true : product.status !== PRODUCT_STATUS_SHOWROOM.deleted)
        .map(product => {
            const borderCssClass = getProductShowroomImageBorderColor(genericStyle, product.status);
            const backgroundTextColorCssClass = product.status === PRODUCT_STATUS_SHOWROOM.missingVariant ? classes.backgroundColorLabel : null;

            return (
                <Grid item key={product.product_guid}>
                    <div className={classes.imgContainer}>
                        <div className={classes.selectCheckbox}>
                            <Checkbox
                                icon={<Icon>{'radio_button_unchecked'}</Icon>}
                                checkedIcon={<Icon>{'radio_button_checked'}</Icon>}
                                name={product.product_guid}
                                size="medium"    // size: small, medium
                                checked={product.selected}
                                onChange={(event) => checkboxHandler(event)}
                                disabled={product.status === PRODUCT_STATUS_SHOWROOM.deleted}/>
                        </div>
                        <div className={classes.detailIcon}>
                            <IconButtonElement
                                fontSize="small"
                                icon={"search_icon"}
                                onClick={(event) => getProductDetails(event, product.product_guid)}/>
                        </div>
                        <div className={borderCssClass}/>
                        <PreserveImageRatio src={product.img_url}
                            alt={"Image not found"}
                            height={280}
                            width={200}
                            onError={(event => onErrorSrc(event))}
                            className={getProductImageOpacity(genericStyle, product.status)}/>
                    </div>
                    <div className={`${classes.productLabel} ${backgroundTextColorCssClass}`}>
                        <Typography variant={'body2'}>{product.style_fabric_color}</Typography>
                    </div>
                </Grid>
            )
        });


    return (
        <>
            <Grid container
                  direction={"row"}
                  justify={"flex-start"}
                  alignItems={"flex-start"}
                  spacing={4}
                  className={classes.imageGrid}>
                {products}
            </Grid>
            <Grid container
                  direction={"row"}
                  justify={"flex-end"}
                  alignItems={"center"}
                  className={classes.footerGrid}>
                <Grid item xs={2}>
                    {
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "common.button.label.download.excel",
                                defaultMessage: "Download Excel",
                            })}
                            onSubmit={() => getExcelUrl()}
                            disabled={null}/>
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        productsGalleryState.length > 0 &&
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "common.unselect.all",
                                defaultMessage: "Unselect All",
                            })}
                            onSubmit={(event) => unselectAllProductsWithOneImageHandler(event)}/>
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        productsGalleryState.length > 0 &&
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "common.select.all",
                                defaultMessage: "Select All",
                            })}
                            onSubmit={(event) => selectAllProductsWithOneImageHandler(event)}/>
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        productsGalleryState.length > 0 &&
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "showroom_product.save.changes",
                                defaultMessage: "Save Changes",
                            })}
                            onSubmit={() => saveChangesOnSubmit()}
                            disabled={!changesState}/>
                    }
                </Grid>
            </Grid>
        </>
    )
};

const mapStateToProps = state => ({
    galleryInfo: state.galleryProductShowroomReducer,
    sortingAction: state.galleryActionsProductShowroomReducer
});


const mapDispatchToProps = dispatch => ({
    saveProducts: (galleryId, productsList) => dispatch(startPutProducts(galleryId, productsList)),
    getProductsGallery: (galleryId) => dispatch(startGetProductsShowroomGallery(galleryId)),
    getExcelUrl: (galleryId) => dispatch(startGetExcelInfo(galleryId)),
    clearExcelUrl: () => dispatch(clearExcelInfos())
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GalleryTabContainer));
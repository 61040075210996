import React, {useEffect, useState} from "react";
import Carousel from "react-material-ui-carousel";
import PostProductionItem from "./Items/PostProductionItem/PostProductionItem";
import {useStyles} from "./CarouselElementStyle";
import DigitalAssetsItem from "./Items/DigitalAssetsItem/DigitalAssetsItem";
import StyleHOC from "../../utils/StyleHOC";

const CarouselElement = props => {
    const classes = useStyles();
    const { dataItems, itemType } = props;
    const [carouselItems, setCarouselItems] = useState([]);

    console.log("dataitems ", dataItems, "itemType ", itemType)

    useEffect(() => {
        let items = [];
        switch (itemType.type) {
            case 'postProduction':
                items = dataItems.map((dataItem, index) =>
                    <StyleHOC key={index}>
                        <PostProductionItem
                            items={dataItem}
                            onClickHandler={itemType.onClickHandler}/>
                    </StyleHOC>);
                break;
            case 'digitalAssets':
                items = dataItems.map((dataItem, index) => <DigitalAssetsItem key={index} items={dataItem}
                                                                              iconButton={itemType.iconButton}
                                                                              iconAction={itemType.iconAction}
                                                                              labelIcon={itemType.labelIcon}/>);
                break;
            default:
                break;
        }
        setCarouselItems(prevState => items);
    }, [dataItems]);

    useEffect(() => {
        return (() => {
            setCarouselItems([]);
        })
    }, []);

    if (carouselItems && carouselItems.length > 0) {
        return (
            <Carousel autoPlay={false} className={classes.cardContainer}>
                {carouselItems}
            </Carousel>
        );
    } else {
        return null;
    }
};

export default CarouselElement;
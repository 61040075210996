import {
    GET_L1_CATEGORY_FAILURE,
    GET_L1_CATEGORY_REQUEST,
    GET_L1_CATEGORY_SUCCESS
} from "../../actions/search/l1_category";

const initialState = {l1_category: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_L1_CATEGORY_REQUEST:
            return {
                loadingL1category: true
            };
        case GET_L1_CATEGORY_SUCCESS:
            return {
                ...state,
                loadingL1category: false,
                l1_category: action.l1_category,
            };
        case GET_L1_CATEGORY_FAILURE:
            return {
                ...state,
                loadingL1category: false,
                error: action.error
            }
        default:
            return state;
    }
}
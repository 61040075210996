import React from 'react';
import {IntlProvider} from 'react-intl';
import messages_it from '../translations/it.json';
import messages_en from '../translations/en.json';

const Context = React.createContext ();

class IntlProviderWrapper extends React.Component {
    constructor (...args) {
        super (...args);

        this.switchToEnglish = () => {
            this.setState ({locale: 'en', messages: messages_en});
        };

        this.switchToItaliano = () => {
            this.setState ({locale: 'it', messages: messages_it});
        };

        // pass everything in state to avoid creating object inside render method (like explained in the documentation)
        this.state = {
            locale: 'en',
            messages: messages_en,
            switchToEnglish: this.switchToEnglish,
            switchToItaliano: this.switchToItaliano,
            textComponent: React.Fragment,
        };
    }

    render () {
        const {children} = this.props;
        const {locale, messages} = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                    textComponent={React.Fragment}
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export {IntlProviderWrapper, Context as IntlContext};

import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_PHOTOLAB_REQUEST = 'GET_PHOTOLAB_REQUEST';
export const GET_PHOTOLAB_SUCCESS = 'GET_PHOTOLAB_SUCCESS';
export const GET_PHOTOLAB_FAILURE = 'GET_PHOTOLAB_FAILURE';
export const GET_PHOTOLAB_BY_GUID_SUCCESS = 'GET_PHOTOLAB_BY_GUID_SUCCESS';
export const GET_PHOTOLAB_BY_GUID_REQUEST = 'GET_PHOTOLAB_BY_GUID_REQUEST';
export const UPDATE_PHOTOLABS = 'UPDATE_PHOTOLABS';
export const UPDATE_PHOTOSETS = 'UPDATE_PHOTOSETS';
export const HIDE_PHOTOLAB_SNACKBAR = 'HIDE_PHOTOLAB_SNACKBAR';
export const MACADDRESS_ERROR = 'MACADDRESS_ERROR';

const localStorageService = LocalStorage.getService();

export const getPhotolabRequest = () => {
    console.log("aspetta i photolab")
    return { type: GET_PHOTOLAB_REQUEST }

};

export const hidePhotolabSnackBar = () => {
    return { type: HIDE_PHOTOLAB_SNACKBAR }
}

export const getPhotolabSuccess = (photolab = {}) => {
    return { type: GET_PHOTOLAB_SUCCESS, photolab }
};

export const getPhotolabFailure = (error) => {
    return { type: GET_PHOTOLAB_FAILURE, error }
};

export function updatePhotolabs(data) {
    return { type: UPDATE_PHOTOLABS, data }
}

export function updatePhotosets(data) {
    return { type: UPDATE_PHOTOSETS, data }
}

export const startGetPhotolab = () => {
    console.log('Start startGetPhotolab');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/photolab/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response photolab search ", response.data);
                    dispatch(getPhotolabSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};

export const startPostPhotolab = (newPhotolab) => {
    console.log('Start startPostPhotolab');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/photolabs?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(newPhotolab),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response post photolab  ", response.data);
                    dispatch(updatePhotolabs(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};

export const getPhotolabByGuidRequest = () => {
    return { type: GET_PHOTOLAB_BY_GUID_REQUEST }
};

export const getPhotolabByGuidSuccess = (photolab_set = {}) => {
    return { type: GET_PHOTOLAB_BY_GUID_SUCCESS, photolab_set }
};

export const macaddressError = (error) => {
    return { type: MACADDRESS_ERROR, error }
};

export const startGetPhotolabs = () => {
    console.log('Start startGetPhotolabs');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/photolabs?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get photolabs ", response.data);
                    dispatch(getPhotolabSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};

export const startGetPhotolabByGuid = (guid) => {
    console.log('Start startGetPhotolabByGuid with guid ', guid);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabByGuidRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/sets?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&photolab=${guid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get photolab by guid ", response.data);
                    dispatch(getPhotolabByGuidSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};

export const startPostPhotoset = (newPhotoset) => {
    console.log('Start startPostPhotoset');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabByGuidRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/sets?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                data: JSON.stringify(newPhotoset),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response post photoset ", response.data);
                    dispatch(updatePhotosets(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 409) dispatch(macaddressError(error));
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};

export const startPutPhotoset = (guid, photoset) => {
    console.log('Start startPutPhotoset');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabByGuidRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/sets/${guid}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                },
                data: JSON.stringify(photoset),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response put photoset ", response.data);
                    dispatch(updatePhotosets(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 409) dispatch(macaddressError(error));
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};

export const startDeletePhotoset = (guid) => {
    console.log('Start startDeletePhotoset');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabByGuidRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/sets/${guid}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response delete photoset ", response.data);
                    dispatch(updatePhotosets(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};

export const startDeletePhotolab = (guid) => {
    console.log('Start startDeletePhotolab');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPhotolabByGuidRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/photolabs/${guid}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response delete photolab ", response.data);
                    dispatch(updatePhotolabs(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getPhotolabFailure(error));
            });
    };
};


import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "./GridDetailedViewStyle";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import PreserveImageRatio from "../../../PreserveImageRatio/PreserveImageRatio";
import {backgroundColorUsingStatus, onErrorSrc} from "../../../../utils/CommonFunction";
import MovieIcon from "@material-ui/icons/Movie";

const GridDetailedView = (props) => {
    const { data, selectHandler, keys, openComparingDialogHandler, genericStyle } = props;
    const classes = useStyles();
    const intl = useIntl();

    let items = null;

    if (data) {
        items = data.map((obj, index) => {
            const statusCssClass = backgroundColorUsingStatus(obj.status, genericStyle);

            const descriptions = keys.map((keyObj, keyIndex) => {
                return (
                    <Grid item key={keyIndex}>
                        <Typography>
                            {intl.formatMessage({
                                id: keyObj.translateKey,
                                defaultMessage: keyObj.default,
                            })}
                            : {obj[keyObj.key]}
                        </Typography>
                    </Grid>
                );
            });

            return (
                <Grid item xs={6} key={index}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={3}>
                        <Grid item className={classes.paperImage}>
                            <Paper elevation={0} square>
                                <Checkbox
                                    icon={
                                        <Icon className={classes.unchecked}>
                                            {"radio_button_unchecked"}
                                        </Icon>
                                    }
                                    checkedIcon={
                                        <Icon className={classes.checked}>
                                            {"radio_button_checked"}
                                        </Icon>
                                    }
                                    name={obj.style_fabric_color}
                                    size="medium" // size: small, medium
                                    checked={obj.selected}
                                    onChange={(event) => selectHandler(event)}
                                    value={index}
                                    className={obj.asset_type === "VIDEO" ? classes.movieCheckbox : ''}/>
                                    { obj.asset_type === "VIDEO" ? 
                                        <div className={classes.movieFrameContainer}>
                                            <MovieIcon className={classes.movieIcon}/>
                                            <PreserveImageRatio
                                                src={obj.asset_img_url}
                                                height={280}
                                                width={200}
                                                isVideo
                                            />
                                        </div>
                                        :
                                        <PreserveImageRatio
                                            src={obj.asset_img_url}
                                            alt={"Image not found"}
                                            height={280}
                                            width={200}
                                            onClick={() => openComparingDialogHandler(obj.asset_guid, obj.status)}
                                            onError={(event) => onErrorSrc(event)}/>
                                    }
                            </Paper>
                            <div className={`${classes.viewBox} ${statusCssClass}`}>
                                <Typography>{obj.view}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid
                                container
                                className={classes.details}
                                direction="column"
                                justify="flex-start"
                                alignItems="flex-start">
                                {descriptions}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        });
    }

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={4}
        >
            {items}
        </Grid>
    );
};

export default GridDetailedView;

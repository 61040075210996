import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import DropDownElement from "../../../DropDownElement/DropDownElement";
import {useIntl} from "react-intl";
import _ from "lodash";
import {useStyles} from "./SearchFieldRowStyle";
import SearchFieldCellContent from "./SearchFieldCellContent/SearchFieldCellContent";
import IconButtonElement from "../../../IconButtonElement/IconButtonElement";


const SearchFieldRow = (props) => {
    const {rowData, rowIndex, operatorHandler, deleteContextHandler, searchCellChangeHandler, options, loadings, callOptionList} = props;
    const intl = useIntl();
    const classes = useStyles();

    const dropdownOptions = _.toArray(_.mapValues(rowData.operators, function (operator) {
        return ({
            label: operator.search_operator_description,
            value: operator.search_operator_guid
        })
    }));
    //console.log('OPTIONS: ', dropdownOptions);
    //console.log('ROW DATA: ', rowData);
    console.log('WHATS HAPPENING ', props);
    // TODO: delete button e dropdown allargano la dimensione della riga
    return (
        <TableRow hover
                  className={classes.row}
                  classes={{hover: classes.hover}}>
            <TableCell component={'th'}
                       scope={'row'}
                       className={classes.cellTitle}>
                <Typography>
                    {intl.formatMessage({id: rowData.external_name, defaultMessage: rowData.name})}
                </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
                <DropDownElement name={rowIndex.toString()}
                                 value={rowData.search_operator_guid ? rowData.search_operator_guid : ''}
                                 icon='filter_list'
                                 type='dense'
                                 label={{
                                     translateKey: 'search.dropdown.label.table.dropdown.label.filters',
                                     defaultText: 'Operators'
                                 }}
                                 options={dropdownOptions}
                                 onChangeHandler={(event) => operatorHandler(event, rowIndex)}/>
            </TableCell>
            <TableCell align="center"
                       className={classes.cell}>
                <SearchFieldCellContent value={rowData.value}
                                        cellContentType={rowData.search_type}
                                        rowIndex={rowIndex}
                                        rowData={rowData}
                                        searchCellChangeHandler={searchCellChangeHandler}
                                        options={options}
                                        callOptionList={callOptionList}
                                        loadings={loadings}
                />
            </TableCell>
            <TableCell align="right"
                       className={classes.cell}>
                {/*bottone di cancellazione riga*/}
                <IconButtonElement labelId={"search.tooltip.delete.filter"}
                                   label={"Delete Filter"}
                                   icon={"close"}
                                   fontSize={"small"}
                                   onClick={(event) => deleteContextHandler(event, rowData)}/>
            </TableCell>
        </TableRow>
    )
};

export default SearchFieldRow;
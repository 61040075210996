import axios from "axios";
import {loggedOut} from "../../auth";
import {startGetLooks} from "./looks";
import LocalStorage from "../../../localStorage";

const localStorageService = LocalStorage.getService();

export const PUT_LOOK_REQUEST = 'PUT_LOOK_REQUEST';
export const PUT_LOOK_SUCCESS = 'PUT_LOOK_SUCCESS';
export const PUT_LOOK_FAILURE = 'PUT_LOOK_FAILURE';
export const CLEAR_LOOK = 'CLEAR_LOOK';

export const putLookRequest = () => {
    return { type: PUT_LOOK_REQUEST }
};

export const putLookSuccess = (look = {}) => {
    return { type: PUT_LOOK_SUCCESS, look }
};

export const putLookFailure = (error) => {
    return { type: PUT_LOOK_FAILURE, error }
};

export const clearLook = (error) => {
    return { type: CLEAR_LOOK, error }
};


export const startPutLook = (contextId, lookId, body) => {
    console.log('Start startPutLook with ', contextId);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(putLookRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                if (response.status === 201 && response.data !== null) {
                    console.log("Response update look: ", response.data);
                    dispatch(putLookSuccess(response.data));
                    dispatch(startGetLooks(contextId));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(putLookFailure(error));
            });
    };
};


export const POST_LOOK_REQUEST = 'POST_LOOK_REQUEST';
export const POST_LOOK_SUCCESS = 'POST_LOOK_SUCCESS';
export const POST_LOOK_FAILURE = 'POST_LOOK_FAILURE';

export const postLookRequest = () => {
    return { type: POST_LOOK_REQUEST }
};

export const postLookSuccess = (look = {}) => {
    return { type: POST_LOOK_SUCCESS, look }
};

export const postLookFailure = (error) => {
    return { type: POST_LOOK_FAILURE, error }
};

export const startPostLook = (contextId, body, isMultiple) => {
    console.log('Start startPostLook with ', contextId);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(postLookRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&multiple=${isMultiple}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                if (response.status === 201 && response.data !== null) {
                    console.log("Response create look: ", response.data);
                    dispatch(postLookSuccess(response.data));
                    dispatch(startGetLooks(contextId));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postLookFailure(error));
            });
    };
};


export const DELETE_LOOK_REQUEST = 'DELETE_LOOK_REQUEST';
export const DELETE_LOOK_SUCCESS = 'DELETE_LOOK_SUCCESS';
export const DELETE_LOOK_FAILURE = 'DELETE_LOOK_FAILURE';

export const deleteLookRequest = () => {
    return { type: DELETE_LOOK_REQUEST }
};

export const deleteLookSuccess = () => {
    return { type: DELETE_LOOK_SUCCESS }
};

export const deleteLookFailure = (error) => {
    return { type: DELETE_LOOK_FAILURE, error }
};

export const GET_LOOK_DETAILS_REQUEST = 'GET_LOOK_DETAILS_REQUEST';
export const GET_LOOK_DETAILS_SUCCESS = 'GET_LOOK_DETAILS_SUCCESS';
export const GET_LOOK_DETAILS_FAILURE = 'GET_LOOK_DETAILS_FAILURE';
export const CLEAR_LOOK_DETAILS = 'CLEAR_LOOK_DETAILS';

export const getCurrentLookDetailsRequest = () => {
    return { type: GET_LOOK_DETAILS_REQUEST }
};

export const getCurrentLookDetailsSuccess = (lookDetails = {}) => ({ 
    type: GET_LOOK_DETAILS_SUCCESS, lookDetails
});

export const getCurrentLookDetailsFailure = (error) => ({ 
    type: GET_LOOK_DETAILS_FAILURE, error
});

export const clearCurrentLookDetails = () => ({
    type: CLEAR_LOOK_DETAILS
});

export const startDeleteLook = (contextId, lookId) => {
    console.log('Start startDeleteLook with ', contextId);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(deleteLookRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            }
        )
            .then(function (response) {
                if (response.status === 204) {
                    console.log("Response delete look: ", response.data);
                    dispatch(deleteLookSuccess());
                    dispatch(startGetLooks(contextId));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(deleteLookFailure(error));
            });
    };
};


export const PUT_LOOK_PRODUCTS_ORDER_REQUEST = 'PUT_LOOK_PRODUCTS_ORDER_REQUEST';
export const PUT_LOOK_PRODUCTS_ORDER_SUCCESS = 'PUT_LOOK_PRODUCTS_ORDER_SUCCESS';
export const PUT_LOOK_PRODUCTS_ORDER_FAILURE = 'PUT_LOOK_PRODUCTS_ORDER_FAILURE';
export const CLEAR_LOOK_PRODUCT = 'CLEAR_LOOK_PRODUCT';

export const putProductsOrderRequest = () => {
    return { type: PUT_LOOK_PRODUCTS_ORDER_REQUEST }
};

export const putProductsOrderSuccess = (look = {}) => {
    return { type: PUT_LOOK_PRODUCTS_ORDER_SUCCESS, look }
};

export const putProductsOrderFailure = (error) => {
    return { type: PUT_LOOK_PRODUCTS_ORDER_FAILURE, error }
};

export const clearProductLook = () => {
    return { type: CLEAR_LOOK_PRODUCT }
};

export const startGetCurrentLookDetails = (contextId, lookId) => {
    console.log('Start startGetCurrentLookDetails with lookId:', lookId, 'and contextId:', contextId);
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(getCurrentLookDetailsRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/details?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetCurrentLookDetails', response.data);
                    dispatch(getCurrentLookDetailsSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getCurrentLookDetailsFailure(error));
            });
    }
};

export const startUpdateProductsOrder = (contextId, lookId, body) => {
    console.log('Start startUpdateProductsOrder with ', contextId, lookId, body);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(putProductsOrderRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/products/products_order?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                if (response.status === 201 && response.data !== null) {
                    console.log("Response startUpdateProductsOrder: ", response.data);
                    dispatch(putProductsOrderSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(putProductsOrderFailure(error));
            });
    };
};


export const DELETE_PRODUCT_FROM_LOOK_REQUEST = 'DELETE_PRODUCT_FROM_LOOK_REQUEST';
export const DELETE_PRODUCT_FROM_LOOK_SUCCESS = 'DELETE_PRODUCT_FROM_LOOK_SUCCESS';
export const DELETE_PRODUCT_FROM_LOOK_FAILURE = 'DELETE_PRODUCT_FROM_LOOK_FAILURE';

export const removeProductFromLookRequest = () => {
    return { type: DELETE_PRODUCT_FROM_LOOK_REQUEST }
};

export const removeProductFromLookSuccess = (look = {}) => {
    return { type: DELETE_PRODUCT_FROM_LOOK_SUCCESS, look }
};

export const removeProductFromLookFailure = (error) => {
    return { type: DELETE_PRODUCT_FROM_LOOK_FAILURE, error }
};

export const startDeleteProduct = (contextId, lookId, product) => {
    console.log('Start startDeleteProduct with ', contextId, lookId, product);
    let token = localStorageService.getAccessToken();

    if (product.placeholder) {
        return (dispatch) => {
            dispatch(removeProductFromLookRequest());
            return axios({
                    url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/products/placeholders/${product.style_fabric_color}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        Authorization: 'Bearer ' + token,
                    }
                }
            )
                .then(function (response) {
                    if (response.status === 201 && response.data !== null) {
                        console.log("Response startDeleteProduct: ", response.data);
                        dispatch(removeProductFromLookSuccess(response.data));                        
                        dispatch(startGetCurrentLookDetails(contextId, lookId));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401) dispatch(loggedOut());
                    dispatch(removeProductFromLookFailure(error));
                });
        };
    } else {
        return (dispatch) => {
            dispatch(removeProductFromLookRequest());
            return axios({
                    url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/products/${product.style_fabric_color}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        Authorization: 'Bearer ' + token,
                    }
                }
            )
                .then(function (response) {
                    if (response.status === 201 && response.data !== null) {
                        console.log("Response startDeleteProduct: ", response.data);
                        dispatch(removeProductFromLookSuccess(response.data));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401) dispatch(loggedOut());
                    dispatch(removeProductFromLookFailure(error));
                });
        };
    }
};


export const POST_PRODUCT_TO_LOOK_REQUEST = 'POST_PRODUCT_TO_LOOK_REQUEST';
export const POST_PRODUCT_TO_LOOK_SUCCESS = 'POST_PRODUCT_TO_LOOK_SUCCESS';
export const POST_PRODUCT_TO_LOOK_PLACEHOLDER = 'POST_PRODUCT_TO_LOOK_PLACEHOLDER';
export const POST_PRODUCT_TO_LOOK_FAILURE = 'POST_PRODUCT_TO_LOOK_FAILURE';

export const addProductToLookRequest = () => {
    return { type: POST_PRODUCT_TO_LOOK_REQUEST }
};

export const addProductToLookSuccess = (look = {}) => {
    return { type: POST_PRODUCT_TO_LOOK_SUCCESS, look }
};

export const addProductToLookPlaceholder = (placeholder = {}) => {
    return { type: POST_PRODUCT_TO_LOOK_PLACEHOLDER, placeholder }
};


export const addProductToLookFailure = (error) => {
    return { type: POST_PRODUCT_TO_LOOK_FAILURE, error }
};

export const startAddProduct = (contextId, lookId, product, mustBuy = false, fromBarcode = false) => {
    console.log('Start startAddProduct with ', contextId, lookId, product, mustBuy);
    let token = localStorageService.getAccessToken();

    if (product.fakeProduct === '') {
        return (dispatch) => {
            let productCode;

            if (product.styleFabricColor !== '') {
                productCode = product.styleFabricColor;
            } else if (product.barcode !== '') {
                productCode = product.barcode;
            }

            dispatch(addProductToLookRequest());
            return axios({
                    url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/products/${productCode}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        Authorization: 'Bearer ' + token,
                    },
                    data: JSON.stringify({ must_buy: mustBuy , from_barcode: fromBarcode})
                }
            )
                .then(function (response) {
                    if (response.status === 201 && response.data !== null) {
                        console.log("Response startAddProduct: ", response.data);
                        dispatch(addProductToLookSuccess(response.data));
                        dispatch(startGetCurrentLookDetails(contextId, lookId));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401){
                        dispatch(loggedOut());
                    }
                    else if (error.response.status === 409 && error.response.data !== null) {
                        console.log("Response startAddPlaceholder: ", error.response.data);
                        dispatch(addProductToLookPlaceholder(error.response.data));
                    }
                    else {
                        dispatch(addProductToLookFailure(error));
                    }
                });
        };
    } else {
        return (dispatch) => {
            dispatch(addProductToLookRequest());
            return axios({
                    url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/products/placeholders/${product.fakeProduct}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        Authorization: 'Bearer ' + token,
                    },
                    data: JSON.stringify({ must_buy: mustBuy , from_barcode: fromBarcode })
                }
            )
                .then(function (response) {
                    if (response.status === 201 && response.data !== null) {
                        console.log("Response startAddProduct: ", response.data);
                        dispatch(addProductToLookSuccess(response.data));
                        dispatch(startGetCurrentLookDetails(contextId, lookId));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401) dispatch(loggedOut());
                    dispatch(addProductToLookFailure(error));
                });
        };
    }
};


export const PUT_LOOK_PRODUCT_REQUEST = 'PUT_LOOK_PRODUCT_REQUEST';
export const PUT_LOOK_PRODUCT_SUCCESS = 'PUT_LOOK_PRODUCT_SUCCESS';
export const PUT_LOOK_PRODUCT_FAILURE = 'PUT_LOOK_PRODUCT_FAILURE';

export const updateLookProductRequest = () => {
    return { type: PUT_LOOK_PRODUCT_REQUEST }
};

export const updateLookProductSuccess = (look = {}) => {
    return { type: PUT_LOOK_PRODUCT_SUCCESS, look }
};

export const updateLookProductFailure = (error) => {
    return { type: PUT_LOOK_PRODUCT_FAILURE, error }
};

export const startUpdateProduct = (contextId, lookId, product, mustBuy) => {
    console.log('Start startUpdateProduct with ', contextId, lookId, product, mustBuy);
    let token = localStorageService.getAccessToken();

    if (product.placeholder) {
        return (dispatch) => {
            dispatch(updateLookProductRequest());
            return axios({
                    url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/products/placeholders/${product.style_fabric_color}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        Authorization: 'Bearer ' + token,
                    },
                    data: JSON.stringify({ must_buy: mustBuy, note: product.note })
                }
            )
                .then(function (response) {
                    if (response.status === 201 && response.data !== null) {
                        console.log("Response startUpdateProduct: ", response.data);
                        dispatch(updateLookProductSuccess(response.data));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401) dispatch(loggedOut());
                    dispatch(updateLookProductFailure(error));
                });
        };
    } else {
        return (dispatch) => {
            dispatch(updateLookProductRequest());
            return axios({
                    url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/${lookId}/products/${product.style_fabric_color}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        Authorization: 'Bearer ' + token,
                    },
                data: JSON.stringify({ must_buy: mustBuy, note: product.note })
                }
            )
                .then(function (response) {
                    if (response.status === 201 && response.data !== null) {
                        console.log("Response startUpdateProduct: ", response.data);
                        dispatch(updateLookProductSuccess(response.data));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401) dispatch(loggedOut());
                    dispatch(updateLookProductFailure(error));
                });
        };
    }
};
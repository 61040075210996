export const transactions_columns = [
    {
        title: {id: "login.label.user", default: "User"},
        field: 'user_info'
    },
    {
        title: {id: "admin.transactions.product_id", default: "Product ID"},
        field: 'barcode'
    },
    {
        title: {id: "product.style", default: "Style"},
        field: 'style'
    },
    {
        title: {id: "product.fabric", default: "Fabric"},
        field: 'fabric'
    },
    {
        title: {id: "product.color", default: "Color"},
        field: 'color'
    },
    {
        title: {id: "product.richiedente", default: "Requester"},
        field: 'richiedente_info'
    },
    {
        title: {id: "product.service", default: "Service"},
        field: 'service_info'
    },
    {
        title: {id: "product.season", default: "Season"},
        field: 'season_info'
    },
    {
        title: {id: "admin.logs.operation", default: "Operation"},
        field: 'operation'
    },
    {
        title: { id: "admin.transactions.timestamp", default: "Timestamp" },
        field: 'timestamp'
    },
    {
        title: { id: "admin.transactions.set", default: "Set" },
        field: 'set_info'
    }
];

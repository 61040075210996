import {GET_SET_FAILURE, GET_SET_REQUEST, GET_SET_SUCCESS} from "../../actions/search/set";

const initialState = {set: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SET_REQUEST:
            return {
                loadingSet: true
            };
        case GET_SET_SUCCESS:
            return {
                ...state,
                loadingSet: false,
                set: action.set,
            };
        case GET_SET_FAILURE:
            return {
                ...state,
                loadingSet: false,
                error: action.error
            }
        default:
            return state;
    }
}
import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_GENDER_REQUEST = 'GET_GENDER_REQUEST';
export const GET_GENDER_SUCCESS = 'GET_GENDER_SUCCESS';
export const GET_GENDER_FAILURE = 'GET_GENDER_FAILURE';

const localStorageService = LocalStorage.getService();

export const getGenderRequest = () => {
    return {type: GET_GENDER_REQUEST}
};

export const getGenderSuccess = (gender = {}) => {
    return {type: GET_GENDER_SUCCESS, gender}
};

export const getGenderFailure = (error) => {
    return {type: GET_GENDER_FAILURE, error}
};

export const startGetGender = () => {
    console.log('Start startGetGender');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getGenderRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/gender/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response gender search ", response.data);
                    dispatch(getGenderSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getGenderFailure(error));
            });
    };
};

import {GET_TARGET_FAILURE, GET_TARGET_REQUEST, GET_TARGET_SUCCESS} from "../actions/target";

const initialState = {target: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TARGET_REQUEST:
            return {
                loadingTarget: true
            };
        case GET_TARGET_SUCCESS:
            return {
                ...state,
                loadingTarget: false,
                target: action.target,
            };
        case GET_TARGET_FAILURE:
            return {
                ...state,
                loadingTarget: false,
                error: action.error
            };
        default:
            return state;
    }
}
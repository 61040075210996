import React from 'react';
import './App.css';
import AppRouter from './routers/AppRouters';
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from "./theme";

function App() {
  return (
      <div className="App">
        <ThemeProvider theme={theme}>
           <AppRouter/>
        </ThemeProvider>
      </div>
  );
}


export default App;

import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import {useStyles} from "./AutocompleteMultivalueElementStyle";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ChipElement from "../ChipElement/ChipElement";


const AutocompleteMultivalueElement = (props) => {
    const {
        upLabel,
        label,
        labelId,
        optionList,
        loading,
        callOptionList,
        name,
        handleOnChange,
        value,
        error,
        helperText,
        disabled,
        external_name
    } = props;
    const classes = useStyles();
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(null);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;


    useEffect(() => {
        if ((open && callOptionList[name] && optionList && optionList.length === 0) ||
            (optionList && optionList.length === 0 && value.length > 0)) {
            console.log("What's happening??? ", optionList, value, name)
            callOptionList[name]();
        }
    }, [open]);

    useEffect(() => {
        console.log("I'm changing ", value)
    }, [value])

    useEffect(() => {
        if (optionList && optionList.length > 0) {
            console.log("OPTIONS ", name, value, optionList);
            let dropdownOptions = _.toArray(
                _.mapValues(optionList, function (opt) {
                    if (name === "getRoles") {
                        return {
                            elem: opt.role,
                            label: opt.role,
                            value: opt.role,
                            guid: opt.guid,
                            role: opt.role,
                            //selected: false
                            selected: !!value.find((v) => v.role === opt.role),
                        };
                    } else if( name === "galleries") {
                        return {
                            elem: opt.name,
                            label: opt.name + " (" + (opt.output_type.name || "") + ") ",
                            value: opt.name,
                            id: opt.id || "",
                            output_type_id: opt.output_type.id,
                            //selected: false
                            selected: !!value.find((v) => v.id === opt.id),
                        };
                    }else{
                        return {
                            elem: opt.name,
                            label: opt.external_name !== undefined ? intl.formatMessage({
                                id: opt.external_name,
                                defaultMessage: opt.name,
                            }) : opt.name,
                            value: opt.name,
                            id: opt.id || "",
                            //selected: false
                            selected: !!value.find((v) => v.value === opt.name),
                        };
                    }
                })
            );
            console.log("dropdownOptions ", dropdownOptions)
            setOptions(dropdownOptions);
        }
    }, [optionList]);

    return (
        <form className={classes.root}>
            {upLabel && (
                <div className={classes.upLabel}>
                    {intl.formatMessage({
                        id: upLabel.id,
                        defaultMessage: upLabel.defaultMessage,
                    })}
                </div>
            )}
            <Autocomplete
                classes={{ option: classes.option, paper: classes.paper }}
                value={value}
                multiple
                //disabled={disabled}
                loading={loading}
                disableCloseOnSelect
                options={options || []}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={handleOnChange}
                getOptionSelected={(option, value) =>
                    name !== "galleries"? option.value === value.value && value.selected === true : option.id === value.id && value.selected === true
                }
                getOptionLabel={(option) => option.label || ""}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            className={classes.checkbox}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 2 }}
                            checked={selected}
                        />
                        {option.label}
                    </React.Fragment>
                )}
                // ChipProps={{ className: classes.chips, deleteIcon: <Clear /> }}
                renderTags={(tagValue, getTagProps) => {
                    console.log("Why?",tagValue);
                    return tagValue.map((option, index) => (
                        <ChipElement {...getTagProps({ index })} label={option.label !== undefined ? option.label : option.value} cssType={'transparent'}/>
                    ));
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name={name}
                        placeholder={
                            labelId &&
                            label &&
                            intl.formatMessage({ id: labelId, defaultMessage: label })
                        }
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        error={error || false}
                        helperText={helperText}
                        className={classes.autocompleteInput}
                        InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                        onKeyDown={e => {
                            if (e.keyCode === 13) e.preventDefault();
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading && (
                                        <CircularProgress className={classes.loader} size={20}/>
                                    )}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </form>
    );
};

export default AutocompleteMultivalueElement;
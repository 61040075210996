import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {transactions_columns} from "../../assets/resources/admin_transactions_columns";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import MaterialSimpleTable from "../../components/TableElement/MaterialSimpleTable/MaterialSimpleTable";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import {validateColumnsMaterialSimpleTable} from "../../utils/CommonFunction";
import ButtonElement from "../../components/ButtonElement/ButtonElement";
import {startGetTransactionsCSV, startGetTransactionsExcel} from "../../store/actions/transactions";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginTop: theme.spacing(12),
//         flexGrow: 1,
//     }
// }));

const AdminTransactionsContainer = (props) => {
	// const classes = useStyles();
	const intl = useIntl();

	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [transactionDocDownloadStatus, setTransactionDocDownloadStatus] = useState({loading: false})
	const [snackBar, setSnackBar] = useState({
		open: false,
		type: "error",
		label: "",
		labelId: "",
	});
	const [showDetailModal, setShowDetailModal] = useState({
		open: false,
		title: intl.formatMessage({
			id: "admin.transactions.modal.title",
			defaultMessage: "Transaction Details",
		}),
		modalRows: {}
	});
	useEffect(() => {
		// setup the columns
		const columns = validateColumnsMaterialSimpleTable(intl, transactions_columns);
		setColumns(columns);
	}, []);

	useEffect(() => {
		console.log("useEffect transactions", props.transactions);
		if (props.transactions !== undefined) {
			// if transactions is updated, update data, accordingly
			setTableData(props.transactions);
			//   // reset the modals
			//   setModalAddActive(false);
			//   setModalEditActive(false);
			//   setModalDeactiveActive(false);
		}
	}, [props.transactions]);

	const onClickRow = (event, row) => {
		console.log("ROW CLICKED", row);
		const newState = { ...showDetailModal };
		newState.open = true;
		newState.modalRows = row;
		setShowDetailModal(newState);
	};

	const onClickGetExcel = () => {
		props.getExcel();

		setTransactionDocDownloadStatus({loading: true});

		setSnackBar({
			open: true,
			label: "Excel download in progress",
			labelId: "common.download.excel.in_progress",
			type: "info",
		});

	}

	const onClickGetCSV = () => {
		props.getCSV();

		setTransactionDocDownloadStatus({loading: true});

		setSnackBar({
			open: true,
			label: "CSV download in progress",
			labelId: "common.download.csv.in_progress",
			type: "info",
		});

	}

	useEffect(() => {
		console.log("Download Excel clicked ");
		setTransactionDocDownloadStatus({loading: false});
		if (!props.loadingTransactionsExcel && !props.excelError && props.transactionsExcel !== undefined) {
			console.log("Condition for excel downloading satisfied: " + props.transactionsExcel);
			window.location.replace(props.transactionsExcel);

			setSnackBar({
				open: false,
				label: "Excel download done",
				labelId: "common.download.excel.completed",
				type: "info",
			});
		}
	}, [props.transactionsExcel]);

	useEffect(() => {
		console.log("Download CSV clicked ");
		setTransactionDocDownloadStatus({loading: false});
		if (!props.loadingTransactionsCSV && !props.csvError && props.transactionsCSV !== undefined) {
			console.log("Condition for CSV downloading satisfied: " + props.transactionsCSV);
			window.location.replace(props.transactionsCSV);

			setSnackBar({
				open: false,
				label: "CSV download done",
				labelId: "common.download.csv.completed",
				type: "info",
			});
		}
	}, [props.transactionsCSV]);



	return (
		<div>
			<BackdropElement loading={props.loadingTransactions || transactionDocDownloadStatus.loading || false}/>
			<MaterialSimpleTable
				data={tableData}
				columns={columns}
				onRowClick={onClickRow}
				pageSize={10}
			/>

			{/* view detail modal */}
			<ReusableModal
				reusableModal={showDetailModal}
				closeReusableModal={() => setShowDetailModal({ ...showDetailModal, open: false })}>
				<Grid container
					  direction="row" spacing={1}>
					{
						columns.map(column =>
							<Grid container item key={column.title} justify={'center'} xs={12} spacing={2}>
								<Grid item xs={3}>
									{column.title}
								</Grid>
								<Grid item xs={5}>
									{showDetailModal.modalRows[column.field]}
								</Grid>
							</Grid>
						)
					}
				</Grid>
			</ReusableModal>
			<Grid item xs={4}>
				{
					snackBar.open &&
					<SnackbarElement
						open={snackBar.open}
						type={snackBar.type}
						label={snackBar.label}
						labelId={snackBar.labelId}
						handleClose={() =>
							setSnackBar({
								open: false,
								label: "",
								labelId: "",
								type: "error",
							})
						}
					/>
				}
			</Grid>
			<Grid container direction="row" justify="flex-end" alignItems="center">
				<Grid item xs={4} lg={2}>
					<ButtonElement
						label={intl.formatMessage({
							id: "common.button.label.download.csv",
							defaultMessage: "Download csv",
						})}
						onSubmit={onClickGetCSV}
						disabled={false}
					/>
				</Grid>
				<Grid item xs={4}lg={2}>
					<ButtonElement
						label={intl.formatMessage({
							id: "common.button.label.download.excel",
							defaultMessage: "Download Excel",
						})}
						onSubmit={onClickGetExcel}
						disabled={false}
						/>
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = (state) => ({
	transactions: state.transactionsReducer.transactions,
	loadingTransactions: state.transactionsReducer.loadingTransactions,
	transactionsExcel: state.transactionsReducer.transactionsExcel,
	excelError: state.transactionsReducer.excelError,
	loadingTransactionsExcel: state.transactionsReducer.loadingTransactionsExcel,
	transactionsCSV: state.transactionsReducer.transactionsCSV,
	loadingTransactionsCSV: state.transactionsReducer.loadingTransactionsCSV,
	csvError: state.transactionsReducer.csvError,
});

const mapDispatchToProps = (dispatch) => {
	return {
		getExcel: () => dispatch(startGetTransactionsExcel()),
		getCSV: () => dispatch(startGetTransactionsCSV()),
		//   loadingLog: () => dispatch(loadingLog()),
		// getTransactions: () => dispatch(startGetTransactions()),
		//   getRoles: () => dispatch(startGetRoles()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AdminTransactionsContainer));

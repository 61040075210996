import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useIntl} from "react-intl";
import {DragDropContext} from "react-beautiful-dnd";
import DndMacroAreasProductShowroomElement
    from "../../components/DragNDrop/DndMacroAreasProductShowroomElement/DndMacroAreasProductShowroomElement";
import Grid from "@material-ui/core/Grid";
import ButtonElement from "../../components/ButtonElement/ButtonElement";
import {
    DNDZONE,
    PRODUCT_SHOWROOM_ACTION_REQUEST,
    SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM,
    SNACKBAR_LEVEL
} from "../../utils/constant";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import {
    clearGallerySortingAction,
    clearSavingOrder,
    startGetProductShowroomGalleriesMacroAreas,
    startGetProductShowroomGalleryMacroAreasValues,
    startPostProductShowroomGalleriesMacroAreas,
    startPostProductShowroomGalleriesMacroAreasValuesOrder
} from "../../store/actions/showroom/product/galleryActions";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";
import {createSearchBodyRequest} from "../../utils/CommonFunction";
import LocalStorage from "../../store/localStorage";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DndKeyValueProductShowroomElement
    from "../../components/DragNDrop/DndKeyValueProductShowroomElement/DndKeyValueProductShowroomElement";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(12)
    },
    dndContainer: {
        display: "flex",
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    singleContainer: {
        width: '50%',
        margin: '15px'
    },
    divider: {
        backgroundColor: theme.palette.primary.text
    },
    dndGridPhase2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: '700px'
    }
}));

const SortingProductAreasContainer = props => {
    const classes = useStyles();
    const intl = useIntl();

    const [backdropState, setBackdropState] = useState({ loading: false, label: '' });
    const [snackBarStatus, setSnackBarStatus] = useState({
        show: false,
        message: '',
        type: '',
        messageAbout: '',
        values: null,
        showDuration: 7200000
    });
    const [galleryState, setGalleryState] = useState({});
    const [filtersState, setFiltersState] = useState([]);
    const [macroAreasState, setMacroAreasState] = useState([]);
    const [selectedMacroAreasState, setSelectedMacroAreasState] = useState([]);
    const [phaseState, setPhaseState] = useState(1);
    const [activeStepState, setActiveStepState] = useState(0);
    const [macroAreasValuesState, setMacroAreasValuesState] = useState([]);


    /* ******************************************* PHASE 1: MACRO AREAS ********************************************* */
    /**
     * This is the first useEffect that populate the data state to visualize macro areas.
     */
    useEffect(() => {
        if (props.location.filters) {
            const filters = createSearchBodyRequest(props.location.filters);
            setFiltersState(filters);
        }

        let gallery;
        if (props.gallery && !_.isEmpty(props.gallery)) {
            gallery = _.cloneDeep(props.gallery);
        } else {
            gallery = LocalStorage.getProductGallery();
        }

        setGalleryState(gallery);
        props.getMacroAreas(gallery.id);

        return () => {
            console.log('Smonto il SortingProductAreasContainer!!!');
            // props.clearSortingAction();
        }
    }, []);

    /**
     * This effect is used to handle the response of fetching macro areas.
     */
    useEffect(() => {
        if (props.sortingAction.action === PRODUCT_SHOWROOM_ACTION_REQUEST.getMacroAreas) {
            if (props.sortingAction.loading) {
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.sortingAction.error) {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.fetch.macroAreas',
                            defaultMessage: 'Failed fetching sorting keys.'
                        }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getMacroAreas,
                        type: SNACKBAR_LEVEL.error
                    });
                } else {
                    setMacroAreasState(props.sortingAction.macroAreas.values);
                    setSelectedMacroAreasState(props.sortingAction.macroAreas.ordered_values);
                }
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: false
                })));
            }
        }
    }, [props.sortingAction]);


    const onDragEndMacroAreas = (result) => {
        const { destination, source } = result;

        if (destination) {
            if (destination.droppableId === source.droppableId && destination.index !== source.index) {
                if (DNDZONE.filtersKey === source.droppableId) {
                    // Modifico macroAreasState
                    setMacroAreasState(prevState => {
                        const newState = _.cloneDeep(prevState);
                        const draggableObj = newState.splice(source.index, 1);
                        newState.splice(destination.index, 0, draggableObj[0]);

                        return newState;
                    });
                } else {
                    // Modifico selectedMacroAreasState
                    setSelectedMacroAreasState(prevState => {
                        const newState = _.cloneDeep(prevState);
                        const draggableObj = newState.splice(source.index, 1);
                        newState.splice(destination.index, 0, draggableObj[0]);

                        return newState;
                    });
                }
            } else if (destination.droppableId !== source.droppableId) {
                const newFiltersKey = _.cloneDeep(macroAreasState);
                const newSelectedFiltersKey = _.cloneDeep(selectedMacroAreasState);

                if (DNDZONE.filtersKey === source.droppableId) {
                    // Da filtersKey a selectedFiltersKey
                    const draggableObj = newFiltersKey.splice(source.index, 1);
                    newSelectedFiltersKey.splice(destination.index, 0, draggableObj[0]);
                } else {
                    // Da selectedFiltersKey a filtersKey
                    const draggableObj = newSelectedFiltersKey.splice(source.index, 1);
                    newFiltersKey.splice(destination.index, 0, draggableObj[0]);
                }

                setMacroAreasState(newFiltersKey);
                setSelectedMacroAreasState(newSelectedFiltersKey);
            } else {
                console.log("Please don't drop item outside of droppable zone!");
            }
        }
    };

    /**
     * This function is used to sending selected macro areas to BE. As side effect the result show a new page structure
     * to handle values inside a each macro areas selected.
     */
    const nextSortPhaseOnSubmitHandler = event => {
        const macroAreas = selectedMacroAreasState.map(selectedFilter => selectedFilter.name);
        const editGallery = props.location.pathname === "/showroom-product/edit-gallery/order";
        console.log(galleryState);
        
        if (_.isEmpty(filtersState)) props.setSearchMacroAreas(galleryState.id, editGallery, null, macroAreas);
        else props.setSearchMacroAreas(galleryState.id, editGallery, filtersState, macroAreas);
    };
    /* ************************************************************************************************************** */


    /* ************************************* PHASE 2: SORT MACRO AREAS CONTENT ************************************** */
    /**
     * This effect is used to handle the response after defined which macro areas will be used to order products of the
     * gallery. Move the application to step 2: ordering of the values of each macro areas.
     */
    useEffect(() => {
        if (props.sortingAction.action === PRODUCT_SHOWROOM_ACTION_REQUEST.postMacroAreas) {
            if (props.sortingAction.loading) {
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.sortingAction.error) {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.setting.macro.areas',
                            defaultMessage: 'Cannot save the set macro areas sort.'
                        }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.setMacroAreasValues,
                        type: SNACKBAR_LEVEL.error
                    });
                    setBackdropState((prevState => ({
                        ...prevState,
                        loading: false
                    })));
                } else {
                    props.getMacroAreasValues(galleryState.id);
                }
            }
        } else if (props.sortingAction.action === PRODUCT_SHOWROOM_ACTION_REQUEST.postOrderValues && !props.sortingAction.loading && !props.sortingAction.error) {
            props.history.push('/showroom-product/gallery');
        } else if (props.sortingAction.action === PRODUCT_SHOWROOM_ACTION_REQUEST.getMacroAreasValues) {
            if (!props.sortingAction.loading) {
                if (props.sortingAction.error) {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.fetch.macroAreasValues',
                            defaultMessage: 'Failed fetching sorting keys values.'
                        }),
                        type: SNACKBAR_LEVEL.error
                    });
                } else {
                    if (props.sortingAction.macroAreasValues.length === 0) {
                        props.clearSortingAction();
                        console.log('GALLERY: ', props.gallery);
                        props.history.push({
                            pathname: '/showroom-product',
                            missingMacroAreasValues: true,
                            galleryName: galleryState.name
                        });
                    } else {
                        setMacroAreasValuesState(prevState => _.cloneDeep(props.sortingAction.macroAreasValues.grouping_keys));
                        setPhaseState(2);
                    }
                }
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: false
                })));
            }
        }
    }, [props.sortingAction]);

    const onDragEndMacroAreaValues = (result) => {
        const { destination, source } = result;
        console.log('RESULT DND: ', result);

        if (destination) {
            if (source.droppableId === DNDZONE.startMacroAreasValues &&
                destination.droppableId === DNDZONE.endMacroAreasValues) {
                // Remove item from source list and add to destination list
                const newMacroAreas = _.cloneDeep(macroAreasValuesState);

                const item = newMacroAreas[activeStepState].values.splice(source.index, 1);
                newMacroAreas[activeStepState].ordered_values.splice(destination.index, 0, item[0]);

                setMacroAreasValuesState(newMacroAreas);
            } else if (source.droppableId === DNDZONE.endMacroAreasValues &&
                destination.droppableId === DNDZONE.endMacroAreasValues) {
                // Update item position into destination list
                setMacroAreasValuesState(prevState => {
                    const newMacroAreas = _.cloneDeep(prevState);

                    const item = newMacroAreas[activeStepState].ordered_values.splice(source.index, 1);
                    newMacroAreas[activeStepState].ordered_values.splice(destination.index, 0, item[0]);

                    return newMacroAreas;
                });
            } else {
                setSnackBarStatus(prevState => ({
                    ...prevState,
                    show: true,
                    message: intl.formatMessage({
                        id: 'showroom_product.error.dnd.from.ordered.to.values',
                        defaultMessage: 'Cannot drop an ordered item into a bucket values: update the position instead.'
                    }),
                    type: SNACKBAR_LEVEL.error,
                    showDuration: 3000
                }));
            }
        } else {
            setSnackBarStatus(prevState => ({
                ...prevState,
                show: true,
                message: intl.formatMessage({
                    id: 'common.missing.droppable.zone',
                    defaultMessage: 'Please, drag and drop items into a droppable zone!'
                }),
                type: SNACKBAR_LEVEL.warning,
                showDuration: 3000
            }));
        }
    };

    const prevPhaseOnClickHandler = event => {
        setPhaseState(1);
    };

    const nextStepOnClickHandler = event => {
        setActiveStepState(prevState => prevState + 1);
    };

    const prevStepOnClickHandler = event => {
        setActiveStepState(prevState => prevState - 1);
    };

    const searchGalleryProductsWithAutomaticOrderHandler = event => {
        console.log('Ordered Values: ', macroAreasValuesState.ordered_values);
        console.log('search_uuid: ', props.sortingAction.macroAreasValues.search_uuid);

        props.setAutomaticOrder(props.sortingAction.macroAreasValues.search_uuid, macroAreasValuesState);
    };

    const selectAllOnClickHandler = (event) => {
        const newMacroAreas = _.cloneDeep(macroAreasValuesState);
        const selectAllMacroAreaVaues = [];

        if (typeof newMacroAreas[activeStepState].ordered_values !== 'undefined') {
            selectAllMacroAreaVaues.push(...newMacroAreas[activeStepState].ordered_values);
        }
        if (typeof newMacroAreas[activeStepState].values !== 'undefined') {
            selectAllMacroAreaVaues.push(...newMacroAreas[activeStepState].values);
        }

        setMacroAreasValuesState(prevState => {
            newMacroAreas[activeStepState].values = []
            newMacroAreas[activeStepState].ordered_values = selectAllMacroAreaVaues

            return newMacroAreas;
        });

    };
    /* ************************************************************************************************************** */


    /* ************************************* HANDLER TO HIDE SNACKBAR ********************************************** */
    /**
     * Handler to close snackbar message and clear redux state to prevent the rendering of the same message.
     */
    const closeSnackbar = () => {
        switch (snackBarStatus.messageAbout) {
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.setMacroAreasValues:
                props.clearSavingOrder();
                break;
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getMacroAreas:
                props.clearSortingAction();
                break;
            default:
                break;
        }

        setSnackBarStatus({
            show: false,
            message: '',
            type: '',
            messageAbout: '',
            values: null,
            showDuration: 7200000
        });
    };
    /* ************************************************************************************************************** */

    return (
        <div className={classes.root}>
            <BackdropElement loading={backdropState.loading} label={backdropState.label}/>
            {
                phaseState === 1 &&
                <>
                    <DragDropContext onDragEnd={onDragEndMacroAreas}>
                        <div className={classes.dndContainer}>
                            <div className={classes.singleContainer}>
                                <DndMacroAreasProductShowroomElement droppableId={DNDZONE.filtersKey}
                                                                     title={intl.formatMessage({
                                                                         id: 'showroom_product.label.select.keys',
                                                                         defaultMessage: 'Select sort filter:'
                                                                     })}
                                                                     elements={macroAreasState}/>
                            </div>
                            <div className={classes.singleContainer}>
                                <DndMacroAreasProductShowroomElement droppableId={DNDZONE.selectedFiltersKey}
                                                                     title={intl.formatMessage({
                                                                         id: 'showroom_product.label.order.keys',
                                                                         defaultMessage: 'Order sort macro areas:'
                                                                     })}
                                                                     elements={selectedMacroAreasState}/>
                            </div>
                        </div>
                    </DragDropContext>
                    <Grid container
                          direction={"row"}
                          alignItems={'center'}
                          justify={"flex-end"}>
                        <Grid item xs={2} lg={2}>
                            <ButtonElement
                                label={intl.formatMessage({ id: "common.next", defaultMessage: "Next" })}
                                onSubmit={() => nextSortPhaseOnSubmitHandler()}
                                disabled={selectedMacroAreasState.length === 0}/>
                        </Grid>
                    </Grid>
                </>
            }
            {
                phaseState === 2 &&
                macroAreasValuesState &&
                <>
                    <Stepper activeStep={activeStepState} alternativeLabel>
                        {selectedMacroAreasState.map((macroArea) => (
                            <Step key={macroArea.name}>
                                <StepLabel>{intl.formatMessage({
                                    id: macroArea.external_name,
                                    defaultMessage: macroArea.name
                                })}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Grid container
                          direction={"row"}
                          alignItems={"flex-start"}
                          justify={"flex-start"}
                          spacing={5}
                          className={classes.dndGridPhase2}>
                        <DragDropContext onDragEnd={onDragEndMacroAreaValues}>
                            {
                                macroAreasValuesState &&
                                <Grid item xs={6}>
                                    <DndKeyValueProductShowroomElement droppableId={DNDZONE.startMacroAreasValues}
                                                                       draggableElements={macroAreasValuesState[activeStepState].values}
                                                                       title={intl.formatMessage({
                                                                           id: 'showroom_product.label.macro.areas.items',
                                                                           defaultMessage: 'Items:'
                                                                       })}/>
                                </Grid>
                            }
                            <Grid item xs={6}>
                                {
                                    macroAreasValuesState &&
                                    <DndKeyValueProductShowroomElement droppableId={DNDZONE.endMacroAreasValues}
                                                                       draggableElements={macroAreasValuesState[activeStepState].ordered_values}
                                                                       title={intl.formatMessage({
                                                                           id: 'showroom_product.label.macro.areas.ordered.items',
                                                                           defaultMessage: 'Ordered items:'
                                                                       })}/>
                                }
                            </Grid>
                        </DragDropContext>
                    </Grid>
                    <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                        <Grid item
                              xs={12}>
                            <Grid container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                {
                                    activeStepState === 0 &&
                                    <Grid item xs={2}>
                                        <ButtonElement label={intl.formatMessage({
                                            id: "common.back",
                                            defaultMessage: "Back",
                                        })}
                                                       onSubmit={(event) => prevPhaseOnClickHandler(event)}/>
                                    </Grid>
                                }
                                {
                                    <Grid item
                                          xs={2}>
                                        <ButtonElement label={intl.formatMessage({
                                            id: "common.select.all",
                                            defaultMessage: "Select All"
                                        })}
                                                       onSubmit={(event) => selectAllOnClickHandler(event)}
                                                       disabled={macroAreasValuesState[activeStepState].values.length === 0}/>
                                    </Grid>
                                }
                                {
                                    activeStepState > 0 &&
                                    <Grid item xs={2}>
                                        <ButtonElement label={intl.formatMessage({
                                            id: "common.previous",
                                            defaultMessage: "Previous",
                                        })}
                                                       onSubmit={(event) => prevStepOnClickHandler(event)}/>
                                    </Grid>
                                }
                                {
                                    activeStepState + 1 !== macroAreasValuesState.length &&
                                    <Grid item xs={2}>
                                        <ButtonElement label={intl.formatMessage({
                                            id: "common.next",
                                            defaultMessage: "Next",
                                        })}
                                                       onSubmit={(event) => nextStepOnClickHandler(event)}
                                                       disabled={macroAreasValuesState[activeStepState].values.length > 0}/>
                                    </Grid>
                                }
                                {
                                    activeStepState + 1 === macroAreasValuesState.length &&
                                    <Grid item xs={2}>
                                        <ButtonElement label={intl.formatMessage({
                                            id: "search.button.products",
                                            defaultMessage: "Search products",
                                        })}
                                                       onSubmit={(event) => searchGalleryProductsWithAutomaticOrderHandler()}
                                                       disabled={macroAreasValuesState[activeStepState].values.length > 0}/>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
            {snackBarStatus.show &&
            <SnackbarElement open={snackBarStatus.show}
                             type={snackBarStatus.type}
                             label={snackBarStatus.message}
                             handleClose={() => closeSnackbar()}
                             showDuration={snackBarStatus.showDuration}
            />}
        </div>
    )
}

const mapStateToProps = state => ({
    searchContext: state.searchContextReducer.searchContext,
    gallery: state.galleryProductShowroomReducer.gallery,
    sortingAction: state.galleryActionsProductShowroomReducer
});


const mapDispatchToProps = dispatch => ({
    getMacroAreas: (galleryId) => dispatch(startGetProductShowroomGalleriesMacroAreas(galleryId)),
    setSearchMacroAreas: (galleryId, editGallery, filtersList, macroAreasList) => dispatch(startPostProductShowroomGalleriesMacroAreas(galleryId, editGallery, filtersList, macroAreasList)),
    getMacroAreasValues: (galleryId) => dispatch(startGetProductShowroomGalleryMacroAreasValues(galleryId)),
    clearSortingAction: () => dispatch(clearGallerySortingAction()),
    clearSavingOrder: () => dispatch(clearSavingOrder()),
    setAutomaticOrder: (searchUUID, macroAreas) => dispatch(startPostProductShowroomGalleriesMacroAreasValuesOrder(searchUUID, macroAreas))
});


export default connect(mapStateToProps, mapDispatchToProps)(SortingProductAreasContainer);
import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import ReusableModal from "../components/Modal/ReusableModal/ReusableModal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";

const useStyles = makeStyles((theme) => ({
    settingsList: {
        width: '100%',
        backgroundColor: theme.palette.primary.main
    },
    listItems: {
        display: "inline-block",
        paddingLeft: "16px"
    },
    link: {
        color: theme.palette.text.main
    },
    table: {
        overflow: "auto",
        "& .MuiButton-label": {
            "&:hover": {
                color: theme.palette.background.main
            }
        },
        '& .MuiTable-root': {
            color: theme.palette.text.main,
            backgroundColor: theme.palette.background.main
        },
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.background.main,
            color: theme.palette.text.main,
            minHeight: "0px"
        },
        '& .MuiButtonBase-root': {
            color: theme.palette.text.main
        },
        '& .MuiInputBase-root': {
            color: theme.palette.text.main
        },
        '& .MuiTableCell-head': {
            color: theme.palette.text.main,
            textTransform: 'uppercase',
            backgroundColor: theme.palette.headerTable.main.concat(' !important')
        },
        '& .MuiTableCell-head *': {
            color: theme.palette.text.main
        },
        '& .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.main
            },
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.main
            },
            '&:hover': {
                backgroundColor: theme.palette.hoverTable.main,
                color: theme.palette.text.main
            }
        },
        '& .MuiTablePagination-root': {
            backgroundColor: theme.palette.background.main.concat(' !important')
        },
        '& .MuiTableRow-footer': {
            '&:hover': {}
        },
        '& .MuiTableCell-root': {
            color: theme.palette.text.main,
            whiteSpace: 'nowrap',
            border: '2px solid '.concat(theme.palette.background.main)
        },
        '& .MuiTableCell-paddingCheckbox': {
            backgroundColor: 'inherit'
        }
    }
}));


const VideoContainer = props => {
    const classes = useStyles();
    const { genericStyle } = props;
    const intl = useIntl();
    const [columnsStatus, setColumnsStatus] = useState([]);

    useEffect(() => {
        console.log("video containter : props.videoLink ", props.videoLink)
    }, [props.videoLink])

    return (
        <div >
            <ReusableModal
                reusableModal={props.videoContainer}
                //greenButtonClick={() => { }}
                closeReusableModal={props.closeVideoContainer}
            >
                {
                    props.videoLink && props.videoLink.length && props.columnsStatus &&
                   
                        <Grid item xs={12} className={classes.table}>
                            <MaterialTable title={''}
                                columns={props.columnsStatus}
                                data={props.videoLink}
                                options={{
                                    search: false,
                                    paging: false,
                                    sorting: false,
                                    pageSize: 10,
                                    showTitle: false,
                                }}
                                paging={false} />
                        </Grid>


                        /*<div>
                            <List className={classes.settingsList}>
                                <ListItemText className={classes.listItems} id="view" primary={obj.id}  />

                                <ListItemSecondaryAction>
                                    <Link href={obj.link} color="white" target="_blank">
                                        Click here
                                    </Link>

                                </ListItemSecondaryAction>
                            </List>
                        </div>*/
                    

                }
            </ReusableModal>
        </div>
    );
}


const mapStateToProps = state => ({

})


const mapDispatchToProps = dispatch => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(VideoContainer);
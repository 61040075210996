import React from "react";
import DropDownElement from "../DropDownElement/DropDownElement";
import ButtonElement from "../ButtonElement/ButtonElement";
import {useStyles} from "./LeftSideBarUpperStyle";
import LinkElementBox from "../Links/LinkElementBox/LinkElementBox";
import TextElement from "../TextElement/TextElement";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputTextElement from "../InputTextElement/InputTextElement";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";

export const LeftSideBarUpper = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const { groupElements } = props;
    // console.log(groupElements);
    const elements = groupElements.map((element, index) => {
        if (element.type === "dropdown")
            return (
                <DropDownElement
                    key={index}
                    name={element.name}
                    value={element.value}
                    onChangeHandler={element.onChangeHandler}
                    icon={element.icon}
                    label={element.label}
                    options={element.options}
                    disabled={element.disabled}
                    loading={element.loading}
                    open={element.open !== undefined ? element.open : null}
                />
            );
        else if (element.type === "input")
            return (
                <InputTextElement
                    key={index}
                    name={element.name}
                    value={element.value}
                    handleChange={element.handleChange}
                    type="text"
                    icon={element.icon}
                    placeholder={element.placeholder}
                    placeholderId={element.placeholderId}
                    disabled={element.disabled}
                    handleKeyPress={element.onKeyPress || null}
                    fullWidth
                />
            );
        else if (element.type === "text")
            return (
                <Typography className={classes.text} key={index}>
                    {intl.formatMessage({
                        id: element.textId,
                        defaultMessage: element.textDefault,
                    })}
                </Typography>
            );
        else if (element.type === "button") {
            if (element.position === "bottom") {
                return (
                    <div className={classes.bottomActions} key={index}>
                        <ButtonElement
                            labelId={element.labelId}
                            label={element.label}
                            onSubmit={element.onSubmit}
                            loading={element.loading}
                            disabled={element.disabled}
                            icon={element.icon}
                        />
                    </div>
                );
            }
        } else if (element.type === "linkBox" && element.links) {
            //console.log("LINKS: ", element.links);
            return (
                <div key={index} data-cy="lsbu-linkBox">
                    {element.title && <TextElement text={element.title} className={classes.boxTitle}/>}
                    {element.loading ? (
                        <CircularProgress size={14}/>
                    ) : (
                        <LinkElementBox
                            selectedIndex={element.selectedIndex}
                            links={element.links}
                            icon={element.icon}
                            deleteLabel={element.deleteLabel}
                            renameLabel={element.renameLabel}
                            deleteAction={element.deleteAction}
                            renameAction={element.renameAction}
                            onClickHandler={element.onClickHandler}
                            renameTitle={element.renameTitle}
                        />
                    )}
                </div>
            );
        } else if (element === "space") {
            return <div className={classes.divSpace} key={index}/>;
        } else {
            return <div key={index}>{element}</div>;
        }

        return null;
    });

    return <div className={classes.root} data-cy="lsbu">{elements}</div>;
};

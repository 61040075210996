import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";
import {ACTION_REQUEST} from "../../../utils/constant";

export const GET_COLUMNS_SETTINGS_REQUEST = 'GET_COLUMNS_SETTINGS_REQUEST';
export const GET_COLUMNS_SETTINGS_SUCCESS = 'GET_COLUMNS_SETTINGS_SUCCESS';
export const GET_COLUMNS_SETTINGS_FAILURE = 'GET_COLUMNS_SETTINGS_FAILURE';
export const CLEAR_COLUMNS_SETTINGS = 'CLEAR_COLUMNS_SETTINGS';

const localStorageService = LocalStorage.getService();

export const getColumnsSettingsRequest = () => {
    return { type: GET_COLUMNS_SETTINGS_REQUEST };
};

export const getColumnsSettingsSuccess = (settings, view) => {
    return { type: GET_COLUMNS_SETTINGS_SUCCESS, settings, view };
};

export const getColumnsSettingsFailure = (error) => {
    return { type: GET_COLUMNS_SETTINGS_FAILURE, error };
};

export const clearColumnsSettings = () => {
    return { type: CLEAR_COLUMNS_SETTINGS };
};

export const startGetColumnsSettings = (body, tabView) => {
    console.log('Start startGetColumnsSettings with body: ', body);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getColumnsSettingsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/paginated_column_settings?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&tab=${tabView}`,
                method: ACTION_REQUEST.post,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startGetColumnsSettings: ", response.data);
                    dispatch(getColumnsSettingsSuccess(response.data, tabView));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getColumnsSettingsFailure(error));
            });
    };
};

import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const GET_PRODUCTALL_REQUEST = 'GET_PRODUCTALL_REQUEST';
export const GET_PRODUCTALL_SUCCESS = 'GET_PRODUCTALL_SUCCESS';
export const GET_PRODUCTALL_FAILURE = 'GET_PRODUCTALL_FAILURE';
export const CLEAR_PRODUCT_ALL = 'CLEAR_PRODUCT_ALL';

const localStorageService = LocalStorage.getService();

export const getProductAllRequest = () => {
    return {type: GET_PRODUCTALL_REQUEST}
};

export const getProductAllSuccess = (productAll = {}) => {
    return {type: GET_PRODUCTALL_SUCCESS, productAll}
};

export const getProductAllFailure = (error) => {
    return {type: GET_PRODUCTALL_FAILURE, error}
};

export const startClearProductAll = () => {
    console.log("?IM CLEARRRRRRRRRRING")
    return {type: CLEAR_PRODUCT_ALL}
}

export const startGetProductAllSFC = (style, fabric, color) => {
    console.log('Start startGetProductAllSFC: ', style, fabric, color);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getProductAllRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/productAll?style=${style}&fabric=${fabric}&color=${color}&key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response productAllSFC ", response.data);
                    dispatch(getProductAllSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getProductAllFailure(error));
            });
    };
};

export const startGetProductAllBarcode = (barcode) => {
    console.log('Start startGetProductAllBarcode: ', barcode);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getProductAllRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/productAll?barcode=${barcode}&key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response productAllBarcode ", response.data);
                    dispatch(getProductAllSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getProductAllFailure(error));
            });
    };
};

export const startGetProductAllS_F_C = (style_fabric_color) => {
    console.log('Start startGetProductAllS_F_C: ', style_fabric_color);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getProductAllRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/productAll?style_fabric_color=${style_fabric_color}&key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response productAllS_F_C ", response.data);
                    dispatch(getProductAllSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getProductAllFailure(error));
            });
    };
};

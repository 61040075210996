import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    textTakenStatus: {
        color: theme.palette.shoot.main.concat(" !important"),
    },
    textToBePostProductedStatus: {
        color: theme.palette.toBePostProducted.main.concat(" !important"),
    },
    textNewPostProductedStatus: {
        color: theme.palette.newPostProducted.main.concat(" !important"),
    },
    textPostProductedRejectedStatus: {
        color: theme.palette.postProductedReject.main.concat(" !important"),
    },
    textPostProductedApprovedStatus: {
        color: theme.palette.postProductedApprove.main.concat(" !important"),
    },
    backgroundColorTakenStatus: {
        backgroundColor: theme.palette.shoot.main.concat(" !important"),
        color: 'black !important'
    },
    backgroundColorToBePostProductedStatus: {
        backgroundColor: theme.palette.toBePostProducted.main.concat(" !important"),
        color: 'black !important'
    },
    backgroundColorNewPostProductedStatus: {
        backgroundColor: theme.palette.newPostProducted.main.concat(" !important"),
    },
    backgroundColorPostProductedRejectedStatus: {
        backgroundColor: theme.palette.postProductedReject.main.concat(" !important"),
    },
    backgroundColorPostProductedApprovedStatus: {
        backgroundColor: theme.palette.postProductedApprove.main.concat(" !important"),
    },
    backgroundColorDisabled: {
        backgroundColor: theme.palette.background.main.concat("!important"),
        color: 'white !important'
    },
    tooltip: {
        width: "300px",
    },
    tooltipContainer: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        margin: "5px",
    },
    tooltipTextElement: {
        margin: "0px 3px",
    },
    tooltipExportIcons: {
        width: "25px",
        height: "25px",
        margin: "0px 7px",
    },
    fieldview: {
        textAlign: "left",
        fontSize: '.85rem'
    },
    newProductImgStyle: {
        border: '4px solid '.concat(theme.palette.new.main),
        position: 'absolute',
        zIndex: 1,
        width: '192px',
        height: '272px'
    },
    deletedProductImgStyle: {
        border: '4px solid '.concat(theme.palette.deleted.main),
        position: 'absolute',
        zIndex: 1,
        width: '192px',
        height: '272px'
    },
    productImage: {
        opacity: 0.4
    }
}));

const StyleHOC = props => {
    const classes = useStyles();

    return (
        React.cloneElement(props.children, { ...props, genericStyle: classes })
    )
};

export default StyleHOC;
import React from "react";
import {useStyles} from "./DndLookShowroomProductsElementStyle";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Grid, Typography} from "@material-ui/core";
import IconButtonElement from "../../IconButtonElement/IconButtonElement";
import BackdropElement from "../../BackdropElement/BackdropElement";
import {getCompositionShowroomProductImageBorderColor, onErrorSrc, checkAction} from "../../../utils/CommonFunction";
import {useIntl} from "react-intl";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import {connect} from "react-redux";
import {
    startUpdateProductsOrder
} from "../../../store/actions/showroom/composition/look";
import PreserveImageRatio from "../../PreserveImageRatio/PreserveImageRatio";

const DndLookShowroomProductsElement = props => {
    const {look, contextId, deleteProductHandler, updateProductHandler, editProductHandler } = props;
    const classes = useStyles();
    const intl = useIntl();

    console.log('DndLookShowroomProductsElement: \n- look: ', look);

    const onDragEndHandler = (result) => {
        const { source, destination } = result;

        if (destination && destination.index !== source.index) {
            let lookProducts = props.currentLookProducts.map(product => ({
                style_fabric_color: product.style_fabric_color,
                placeholder: product.placeholder
            }));

            const product = lookProducts.splice(parseInt(source.index), 1);
            lookProducts.splice(parseInt(destination.index), 0, product[0]);

            props.updateLookProductsOrder(contextId, look.id, { products: lookProducts });
        }
    };

    const DraggableHOC = ({ draggableId, children, key, index }) => (
        checkAction("composition.sort_products_look", props.auth.actions) ?
            <Draggable draggableId={draggableId} index={index} key={key}>
                {(provided) => (
                    <Grid container
                        direction={"row"}
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {children}
                    </Grid>
                )}
            </Draggable>
            :
            <Grid container
                direction={"row"}
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                {children}
            </Grid>
    );

    return (
        <div className={!props.loadingCurrentLookDetails && props.currentLookProducts.length ? classes.productsContainer : null}>
            <BackdropElement loading={props.loadingCurrentLookDetails} />
            {!props.loadingCurrentLookDetails &&
                <DragDropContext onDragEnd={onDragEndHandler}>
                    <Droppable droppableId={look.name} type="products" direction="vertical">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {props.currentLookProducts.map((product, index) => (
                                    <DraggableHOC 
                                        draggableId={product.style_fabric_color}
                                        index={index}
                                        key={product.style_fabric_color}
                                    >
                                        <Grid item xs={4}>
                                            <div className={classes.imgContainer}>
                                                <div className={classes.item}>
                                                    <PreserveImageRatio
                                                        src={product.img_url ? product.img_url : 'Image not found.'}
                                                        height={140}
                                                        width={100}
                                                        onError={(event) => onErrorSrc(event)}
                                                        className={getCompositionShowroomProductImageBorderColor(classes, product.status_summary)}
                                                        alt="Image not found."/>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container
                                                direction={"column"}
                                                justify="center"
                                                alignItems="flex-start">
                                                <Grid item>
                                                    <Typography
                                                        variant={'h6'}>{product.style_fabric_color}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography align="left">{intl.formatMessage({
                                                        id: 'showroom.look.product.brand',
                                                        defaultMessage: 'Brand'
                                                    })}: {product.brand}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography align="left">{intl.formatMessage({
                                                        id: 'showroom.look.product.gender',
                                                        defaultMessage: 'Gender'
                                                    })}: {product.gender}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography align="left">{intl.formatMessage({
                                                        id: 'showroom.look.product.l3',
                                                        defaultMessage: 'Product Kind (L3)'
                                                    })}: {product.L3}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography align="left">{intl.formatMessage({
                                                        id: 'showroom.look.product.status',
                                                        defaultMessage: 'Status'
                                                    })}: {product.status}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container direction={"row"} justify={"center"}
                                                alignItems={"center"}>
                                                {checkAction("composition.edit_product_look", props.auth.actions) &&
                                                    <Grid item>
                                                        <IconButtonElement icon={'edit_icon'}
                                                                        onClick={(event) => editProductHandler(event, look.id, product)}
                                                                        label={'Edit'}
                                                                        labelId={'common.label.edit'}/>
                                                    </Grid>
                                                }
                                                {checkAction("composition.delete_product_look", props.auth.actions) &&
                                                    <Grid item>
                                                        <IconButtonElement icon={'cancel_icon'}
                                                                        onClick={(event) => deleteProductHandler(event, look.id, product)}
                                                                        label={'Delete'}
                                                                        fontSize="small"
                                                                        labelId={'common.label.delete'}
                                                                        type={'delete'}/>
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    {
                                                        product.note ?
                                                            <div className={classes.infoIcon}>
                                                                <Tooltip placement="top"
                                                                        title={product.note}>
                                                                    <span>
                                                                        <InfoIcon/>
                                                                    </span>
                                                                </Tooltip>
                                                            </div> :
                                                            <div className={classes.infoIcon}>
                                                                <InfoIcon color={'disabled'}/>
                                                            </div>
                                                    }
                                                </Grid>
                                                {checkAction("composition.must_buy_product_look", props.auth.actions) &&
                                                    <Grid item>
                                                        <Tooltip placement="top" title={'MustBuy'}>
                                                            <Checkbox
                                                                icon={<Icon
                                                                    className={classes.mustBuyIcon}>{'star_border'}</Icon>}
                                                                checkedIcon={<Icon
                                                                    className={classes.mustBuyIcon}>{'star'}</Icon>}
                                                                name={product.style_fabric_color}
                                                                size={'medium'}
                                                                checked={product.must_buy}
                                                                onChange={(event) => updateProductHandler(event, look.id, product)}
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </DraggableHOC>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </div>
    );
};
const mapStateToProps = state => ({
    auth: state.authReducer.auth,
    loadingCurrentLookDetails: state.lookReducer.loadingCurrentLookDetails,
    currentLookProducts: state.lookReducer.currentLookProducts,
});

const mapDispatchToProps = dispatch => ({
    updateLookProductsOrder: (contextId, lookId, body) => dispatch(startUpdateProductsOrder(contextId, lookId, body))
});

export default connect(mapStateToProps, mapDispatchToProps)(DndLookShowroomProductsElement);
import {
    CLEAR_LOOKCAST_EXPORT,
    POST_LOOKCAST_EXPORT_FAILED,
    POST_LOOKCAST_EXPORT_REQUEST,
    POST_LOOKCAST_EXPORT_SUCCESS
} from "../../../actions/showroom/composition/exportToLookcast";

const initialState = { loadingExportLookcast: false, error: null, exportActionType: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_LOOKCAST_EXPORT_REQUEST:
            return {
                ...state,
                loadingExportLookcast: true,
                exportActionType: 'POST'
            };
        case POST_LOOKCAST_EXPORT_SUCCESS:
            return {
                ...state,
                loadingExportLookcast: false,
                exportResult: action.result,
            };
        case POST_LOOKCAST_EXPORT_FAILED:
            return {
                ...state,
                loadingExportLookcast: false,
                error: action.error
            };
        case CLEAR_LOOKCAST_EXPORT:
            return initialState;
        default:
            return state;
    }
}
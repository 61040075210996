import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useIntl} from "react-intl";
import {connect} from "react-redux";
import _ from "lodash";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import Grid from "@material-ui/core/Grid";
import AccordionElement from "../../components/AccordionElement/AccordionElement";
import DropDownElement from "../../components/DropDownElement/DropDownElement";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";
import {startGetRichiedente} from "../../store/actions/richiedente";
import {startGetServiceByRichiedenteFull} from "../../store/actions/service";
import {startGetSeasons} from "../../store/actions/season";
import {startGetBrand} from "../../store/actions/brand";
import {startGetGender} from "../../store/actions/gender";
import {startGetEvent} from "../../store/actions/showroom/event";
import {
    clearContext, 
    startGetProductContext, 
    startGetProductShowroomDefaultContext, 
    updateProductShowroomDefaultContext
} from "../../store/actions/showroom/context";
import {filterActiveSeasons} from "../../utils/CommonFunction";
import IconButtonElement from "../../components/IconButtonElement/IconButtonElement";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import InputTextElement from "../../components/InputTextElement/InputTextElement";
import {
    clearDeleteGallery,
    clearNewGallery,
    deleteProductShowroomGallery,
    startPostProductShowroomGallery,
    setCurrentProductShowroomGallery
} from "../../store/actions/showroom/product/gallery";
import {clearGalleries, startGetProductShowroomGalleries} from "../../store/actions/showroom/product/galleries";
import LocalStorage from "../../store/localStorage";
import {clearGallerySortingAction} from "../../store/actions/showroom/product/galleryActions";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM, SNACKBAR_LEVEL} from "../../utils/constant";
import InfoIcon from '@material-ui/icons/Info';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WarningIcon from '@material-ui/icons/Warning';
import {Tooltip} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(12)
    },
    galleryNameAndExportStatus: {
        textAlign: "left"
    },
    linkGallery: {
        color: theme.palette.primary.text,
    },
    linkGalleryExportInProgress: {
        color: theme.palette.text.disabled,
    },
    itemTextPosition: {
        textAlign: "left"
    },
    galleriesScrollArea: {
        overflowY: "auto",
        overflowX: "hidden",
        height: "600px"
    },
    filtersScrollMenu: {
        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        height: "60px",
        alignContent: "center"
    },
    filtersScrollMenuText: {
        display: "inline-block",
        color: theme.palette.text.placeholder,
        textAlign: "center",
        padding: "14px",
        textDecoration: "none"
    },
    iconPosition: {
        marginTop: "3px"
    },
    activeIcon: {
        color: theme.palette.primary.text
    },
    disableIcon: {
        color: theme.palette.action.disabled
    },
    newProductsIcon: {
        color: theme.palette.warning.main
    },
    deletedProductsIcon: {
        color: theme.palette.deleted.main
    }
}));

const ProductShowroomContainer = props => {
    const classes = useStyles();
    const intl = useIntl();

    const [backdropState, setBackdropState] = useState({ loading: false, label: '' });
    const [snackBarStatus, setSnackBarStatus] = useState({
        show: false,
        message: '',
        type: '',
        messageAbout: ''
    });
    const [contextAccordionOpenState, setContextAccordionOpenState] = useState(true);
    const [contextStatus, setContextStatus] = useState({
        richiedente: '',
        service: '',
        richiedenteService: null,
        season: '',
        brand: '',
        gender: '',
        event: ''
    });
    const [isContextDefinedStatus, setIsContextDefinedStatus] = useState(false);
    const [newGalleryModalStatus, setNewGalleryModalStatus] = useState({
        open: false,
        title: intl.formatMessage({ id: 'showroom.dialog.title.new.gallery', defaultMessage: 'Add new gallery' }),
        galleryName: ''
    });
    const [galleriesStatus, setGalleriesStatus] = useState({
        galleries: null
    });
    const [deleteGalleryModalStatus, setDeleteGalleryModalStatus] = useState({
        open: false,
        title: intl.formatMessage({ id: 'showroom.dialog.delete_gallery', defaultMessage: 'Delete gallery' }),
        galleryId: null,
        galleryName: ''
    });
    const [galleryInExportModalStatus, setGalleryInExportModalStatus] = useState({
        open: false,
        title: intl.formatMessage({
            id: 'showroom.dialog.title.gallery.export.in_progress',
            defaultMessage: 'Gallery export in progress'
        }),
        galleryId: null,
        galleryName: ''
    });


    /**
     * This is the effect that fetch dropdown options: except service that is fetch after applicant (or richiedente)
     * is selected. If the context is stored into localstorage we have to populate the current context with the same
     * values stored: so we fetch all information and then another effect set contextStatus.
     */
    useEffect(() => {
        // TODO: bisognerà gestire il caso in cui si ritorna sull'applicazione salvandosi il contesto definito da qualche parte!!!
        // This loading is used to prevent user action during fetching of data and in case of getting context from
        // localstorage.
        setBackdropState((prevState => ({
            ...prevState,
            loading: true
        })));

        if (props.gallery.gallery || props.gallery.error) {
            props.clearNewGallery();
            props.clearGalleryAction();
        }
        LocalStorage.clearProductGallery();
        LocalStorage.clearCurrentSearch();

        // Getting context info
        props.getRichiedente();
        props.getSeason();
        props.getBrand();
        props.getGender();
        props.getEvents();

        // Getting default context
        props.getDefaultContext();

        const savedContext = LocalStorage.getProductShowroomContext()
        if (savedContext) {
            props.getServicesByRichiedente(savedContext.richiedente);
        }

        if (props.location.missingMacroAreasValues) {
            setSnackBarStatus(prevState => ({
                ...prevState,
                show: true,
                message: intl.formatMessage({
                    id: 'showroom_product.error.missing.macroAreas.values',
                    defaultMessage: 'There are no products to order for the gallery {galleryName}.'
                }, { galleryName: <b>{props.location.galleryName}</b> }),
                type: SNACKBAR_LEVEL.warning,
                messageAbout: ''
            }))
        }
        // component willUnmount
        return () => {
            // Clear redux state
            props.clearContext();
            props.clearGalleries();
            props.clearGalleryAction();
            props.clearDeleteGallery();
            // TODO: aggiungere le clear di redux (vedi closeSnackbar)
        }
    }, []);

    /**
     * This effect is used to set context values when it is stored into localstorage.
     */
    useEffect(() => {
        const savedContext = LocalStorage.getProductShowroomContext();

        if (savedContext &&
            !props.loadingRichiedente && contextStatus.richiedente === '' && !props.errorRichiedente && props.richiedente &&
            !props.loadingSeason && contextStatus.season === '' && !props.errorSeason && props.seasons &&
            !props.loadingBrand && contextStatus.brand === '' && !props.errorBrand && props.brand &&
            !props.loadingEvents && contextStatus.event === '' && !props.errorEvents && props.events &&
            !props.loadingGender && contextStatus.gender === '' && !props.errorGender && props.gender &&
            !props.loadingService && contextStatus.service === '' && !props.errorServices && props.service) {
            setContextStatus(prevState => ({
                ...prevState,
                richiedente: savedContext.richiedente,
                season: savedContext.season,
                richiedenteService: savedContext.richiedenteService,
                brand: savedContext.brand,
                event: savedContext.event,
                gender: savedContext.gender,
                service: savedContext.service
            }));
        }
    }, [props.loadingRichiedente, props.richiedente,
        props.loadingService, props.service,
        props.loadingSeason, props.season,
        props.loadingBrand, props.brand,
        props.loadingGender, props.gender,
        props.loadingEvents, props.event]);


    /* ************************************************ CONTEXT ***************************************************** */
    /**
     * Fetch the context ID and clear redux status about context ID when something change about context. When the
     * context is set, it will be stored into localstorage.
     */
    useEffect(() => {
        const isContextSet = !Object.values(contextStatus).map(value => value !== '' && value !== null).includes(false);

        if (props.errorContext || props.showroomContext) {
            props.clearContext();
            props.clearGalleries();
        }

        if (isContextSet) {
            console.log('SALVO: ', contextStatus);
            const currentContextStatus = _.cloneDeep(contextStatus)
            LocalStorage.setProductShowroomContext(currentContextStatus);
            props.updateDefaultContext(currentContextStatus);

            console.log('Getting context!');
            props.getContext(
                contextStatus.richiedenteService,
                contextStatus.season,
                contextStatus.brand,
                contextStatus.gender,
                contextStatus.event
            );
            setIsContextDefinedStatus(true);
        } else {
            setIsContextDefinedStatus(false);
        }
    }, [contextStatus]);

    /**
     * Set the default context received
     */
     useEffect(() => {
        if (props.showroomDefaultContext) {
            console.log("Set default context:", props.showroomDefaultContext);

            if ( props.showroomDefaultContext.hasOwnProperty('richiedente') ) {
                props.getServicesByRichiedente(props.showroomDefaultContext.richiedente);
            }
            
            setContextStatus(contextStatus => ({ ...contextStatus, ...props.showroomDefaultContext }));
        }
    }, [props.showroomDefaultContext])

    const onChangeContextDropdownHandler = event => {
        let tmpContextStatus = _.cloneDeep(contextStatus);

        switch (event.target.name) {
            case ('richiedente'):
                console.log('Richiedente');
                props.getServicesByRichiedente(event.target.value);
                tmpContextStatus.richiedente = event.target.value;
                break;
            case ('service'):
                console.log('Service ', props.service);
                const serviceObj = props.service.find(obj => {
                    return obj.value === event.target.value
                });

                tmpContextStatus.service = event.target.value;
                tmpContextStatus.richiedenteService = serviceObj.richiedenteService;
                console.log('Richiedente-service: ', tmpContextStatus.richiedenteService);
                break;
            case ('season'):
                console.log('Season');
                tmpContextStatus.season = event.target.value;
                break;
            case ('brand'):
                console.log('Brand');
                tmpContextStatus.brand = event.target.value;
                break;
            case ('gender'):
                console.log('Gender');
                tmpContextStatus.gender = event.target.value;
                break;
            case ('event'):
                console.log('Event');
                tmpContextStatus.event = event.target.value;
                break;
            default:
                console.log('Unknown dropdown!');
                break;
        }
        setContextStatus(tmpContextStatus);
    };

    const groupElements = [
        {
            type: "dropdown",
            name: "richiedente",
            value: contextStatus['richiedente'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "person",
            label: { translateKey: "common.dropdown.label.richiedente", defaultText: "Richiedente" },
            options: props.richiedente || [],
            loading: props.loadingRichiedente,
        },
        {
            type: "dropdown",
            name: "service",
            value: contextStatus['service'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "settings",
            label: { translateKey: "common.dropdown.label.service", defaultText: "Service" },
            options: props.service || [],
            loading: props.loadingService,
            disabled: contextStatus.richiedente === ''
        },
        {
            type: "dropdown",
            name: "season",
            value: contextStatus['season'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "common.dropdown.label.season", defaultText: "Season" },
            options: props.seasons || [],
            loading: props.loadingSeason
        },
        {
            type: "dropdown",
            name: "brand",
            value: contextStatus['brand'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "common.dropdown.label.brand", defaultText: "Brand" },
            options: props.brand || [],
            loading: props.loadingBrand
        },
        {
            type: "dropdown",
            name: "gender",
            value: contextStatus['gender'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "common.dropdown.label.gender", defaultText: "Gender" },
            options: props.gender || [],
            loading: props.loadingGender
        },
        {
            type: "dropdown",
            name: "event",
            value: contextStatus['event'],
            onChangeHandler: onChangeContextDropdownHandler,
            icon: "miscellaneous_services",
            label: { translateKey: "showroom.dropdown.event", defaultText: "Event" },
            options: props.events || [],
            loading: props.loadingEvents
        },
        {
            type: "dropdown",
            name: "TBD",
            value: '',
            onChangeHandler: null,
            icon: "miscellaneous_services",
            label: { translateKey: "showroom.dropdown.tbd", defaultText: "TBD" },
            options: [],
            loading: false,
            disabled: true
        }
    ];

    const contextElements = groupElements.map((element, index) =>
        <Grid item xs={3} key={index}>
            <DropDownElement
                key={index}
                name={element.name}
                value={element.value}
                onChangeHandler={element.onChangeHandler}
                icon={element.icon}
                label={element.label}
                options={element.options}
                disabled={element.disabled}
                loading={element.loading}/>
        </Grid>
    );

    const contextAccordionOnChangeHandler = event => {
        setContextAccordionOpenState(prevState => !prevState);
    };
    /* ********************************************************************************************************** */

    /* ******************************************* GALLERIES **************************************************** */
    /**
     * Handler used to retrive galleries whenever the context is available
     */
    useEffect(() => {

        //Waiting for the context to be set
        if (props.showroomContext.id) {
            props.getGalleries(props.showroomContext.id)
        }

    }, [props.showroomContext]);

    /**
     * This effect is used to set galleries state when they are fetched by backend.
     */
    useEffect(() => {
        //Waiting for the galleries to be set
        if (props.galleries.galleries) {
            console.log("Galleries.galleries: ", props.galleries.galleries);
            setGalleriesStatus(prevState => ({
                ...prevState,
                galleries: props.galleries.galleries
            }));
        }
    }, [props.galleries.galleries]);

    /* ******************************************* ERROR MESSAGE **************************************************** */
    /**
     * Handler of error message during fetching of context elements and context id.
     */
    useEffect(() => {
        if (!props.loadingContext && props.errorContext) {          // error during fetching contextId
            console.log('CONTEXT: ', props.loadingContext, ' - ', props.errorContext);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.context.error',
                    defaultMessage: 'Fetch of context failed.'
                }),
                type: SNACKBAR_LEVEL.error
            });
        } else if (!props.loadingRichiedente && props.errorRichiedente) {
            console.log('RICHIEDENTE: ', props.loadingRichiedente, ' - ', props.errorRichiedente);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.richiedente.error',
                    defaultMessage: 'Fetch of applicant failed.'
                }),
                type: SNACKBAR_LEVEL.error
            });
        } else if (!props.loadingSeason && props.errorSeason) {
            console.log('SEASON: ', props.loadingSeason, ' - ', props.errorSeason);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.seasons.error',
                    defaultMessage: 'Fetch of seasons failed.'
                }),
                type: SNACKBAR_LEVEL.error
            });
        } else if (!props.loadingBrand && props.errorBrand) {
            console.log('BRAND: ', props.loadingBrand, ' - ', props.errorBrand);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.brands.error',
                    defaultMessage: 'Fetch of brands failed.'
                }),
                type: SNACKBAR_LEVEL.error
            });
        } else if (!props.loadingGender && props.errorGender) {
            console.log('GENDER: ', props.loadingGender, ' - ', props.errorGender);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.genders.error',
                    defaultMessage: 'Fetch of genders failed.'
                }),
                type: SNACKBAR_LEVEL.error
            });
        } else if (!props.loadingEvents && props.errorEvents) {
            console.log('EVENTS: ', props.loadingEvents, ' - ', props.errorEvents);
            setSnackBarStatus({
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.loading.events.error',
                    defaultMessage: 'Fetch of events failed.'
                }),
                type: SNACKBAR_LEVEL.error
            });
        }
    }, [
        props.loadingContext, props.errorContext,               // possible error during getting of contextId
        props.loadingRichiedente, props.errorRichiedente,       // possible error during fetching of richiedenti
        props.loadingSeason, props.errorSeason,                 // possible error during fetching of seasons
        props.loadingBrand, props.errorBrand,                   // possible error during fetching of brands
        props.loadingGender, props.errorGender,                 // possible error during fetching of genders
        props.loadingEvents, props.errorEvents                  // possible error during fetching of events
    ]);

    /**
     * Handler to close snackbar message and clear redux state to prevent the rendering of the same message.
     */
    const closeSnackbar = () => {
        console.log('SNACKBAR-STATUS: ', snackBarStatus);
        switch (snackBarStatus.messageAbout) {
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.newGallery:
                props.clearNewGallery();
                break;
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.deleteGallery:
                props.clearDeleteGallery();
                break;
            default:
                break;
        }

        setSnackBarStatus({
            show: false,
            message: '',
            type: '',
            messageAbout: ''
        })
    };
    /* ************************************************************************************************************** */


    /* ******************************************* GALLERY ****************************************************** */
    const createNewGallery = (event) => {
        setNewGalleryModalStatus(prevState => ({
            ...prevState,
            open: true
        }))
    };

    const galleryNameHandler = (event) => {
        const galleryName = event.target.value;
        setNewGalleryModalStatus(prevState => ({
            ...prevState,
            galleryName: galleryName
        }))
    }

    /**
     * After confirm gallery name the application will be redirect to the search page.
     */
    const confirmGalleryName = (event) => {
        props.createNewGallery(props.showroomContext.id, newGalleryModalStatus.galleryName, () => redirectToSearchContainerCallback());
    };

    /**
     * This effect is used to handle the creation of new gallery: showing backdrop, if necessary showing error message,
     * redirect to search page in case of success. To prevent the redirect to search page we check the origin path and
     * if necessary the redux state of new gallery will be cleared.
     */
    // TODO: capire quando pulire lo stato della gallery!
    useEffect(() => {
        if (props.gallery.loading && props.gallery.action === 'POST') {
            setBackdropState((prevState => ({
                ...prevState,
                loading: true
            })));
        } else if (!props.gallery.loading && props.gallery.action === 'POST') {
            setBackdropState((prevState => ({
                ...prevState,
                loading: false
            })));

            if (props.gallery.error) {
                setSnackBarStatus(prevState => ({
                    ...prevState,
                    show: true,
                    messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.newGallery,
                    type: SNACKBAR_LEVEL.error,
                    message: intl.formatMessage({
                        id: 'showroom.creation.new.gallery.failed',
                        defaultMessage: 'Cannot create new gallery.'
                    })
                }));
            }
        }
    }, [props.gallery]);

    /**
     * This function is used to redirect to the page to create a new gallery.
     */
    const redirectToSearchContainerCallback = () => {
        props.history.push({
            pathname: "/showroom-product/new-gallery"
        });
    };

    /**
     * Effect used to handle the deletion of a gallery and , if succeded, read the new list of galleries.
     */
    useEffect(() => {
        if (!props.gallery.loading && props.gallery.action === 'DELETE') {
            if (props.gallery.error) {
                setSnackBarStatus(prevState => ({
                    ...prevState,
                    show: true,
                    messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.deleteGallery,
                    type: SNACKBAR_LEVEL.error,
                    message: intl.formatMessage({
                        id: 'showroom_product.delete.gallery.failure',
                        defaultMessage: 'Gallery not deleted.'
                    })
                }));
            } else {
                setSnackBarStatus(prevState => ({
                    ...prevState,
                    show: true,
                    messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.deleteGallery,
                    type: SNACKBAR_LEVEL.success,
                    message: intl.formatMessage({
                        id: 'showroom_product.delete.gallery.success',
                        defaultMessage: 'Gallery deleted.'
                    })
                }));
                props.getGalleries(props.showroomContext.id);
            }
        }
        else if ( !_.isEmpty(props.gallery.gallery) && props.gallery.action === 'EDIT' ) {
            props.history.push({
                pathname: "/showroom-product/edit-gallery"
            });   
        } 
    }, [props.gallery]);

    /**
     * Function used to show the modal used to ask confirm before deleting a gallery
     * @param gallery_id
     * @param gallery_name
     */
    const deleteGallery = (gallery_id, gallery_name) => {
        setDeleteGalleryModalStatus(prevState => ({
            ...prevState,
            open: true,
            galleryId: gallery_id,
            galleryName: gallery_name
        }));

        if (props.gallery.action === 'DELETE') {
            props.clearDeleteGallery();
        }
        setSnackBarStatus({
            show: false,
            message: '',
            type: '',
            messageAbout: ''
        });
    };

    /**
     * Function that contains the operations to perform when the confirmation for deleting a specific gallery (via modal)
     * has been sent
     */
    const confirmDeleteGallery = () => {
        setDeleteGalleryModalStatus(prevState => ({
            ...prevState,
            open: false,
            galleryId: null,
            galleryName: ''
        }));
        props.deleteProductShowroomGallery(deleteGalleryModalStatus.galleryId);
    };

    /**
     * Function used to check if a certain gallery is in export
     * @param gallery
     */
    const isGalleryInExport = (gallery) => {
        let inExport = false;

        if (gallery.export && gallery.export.status.normalize() === "export_in_progress".normalize()) {
            inExport = true;
        }

        return inExport;
    }

    /**
     * This function is used to open the console to manage gallery products.
     */
    const showGallery = (gallery) => {

        setGalleryInExportModalStatus((prevState => ({
            ...prevState,
            open: false,
            galleryId: null,
            galleryName: ''
        })));

        if (!isGalleryInExport(gallery)) {
            LocalStorage.setProductGallery(gallery)

            if (gallery.is_manual_order) {
                console.log('Manual order defined for the gallery: ', gallery.id);
                props.history.push({
                    pathname: "/showroom-product/gallery",
                });
            } else {
                console.log('Automatic order defined for the gallery: ', gallery.id);
                props.history.push({
                    pathname: "/showroom-product/update-gallery-order",
                    gallery: gallery,
                    filters: gallery.filter_list
                });
            }
        } else {
            console.log('Gallery: ', gallery.id, ' in export');

            setGalleryInExportModalStatus((prevState => ({
                ...prevState,
                open: true,
                galleryId: gallery.id,
                galleryName: gallery.name
            })));
        }
    };

    const galleries = galleriesStatus.galleries ? galleriesStatus.galleries.map((gallery) => (
        <Grid container key={gallery.id} className={classes.itemTextPosition}>
            <Grid item xs={12}>
                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start">
                    <Grid item xs={12}>
                        <Grid container
                              direction={"row"}
                              justify="flex-start"
                              alignItems="center"
                              spacing={3}>
                            <Grid item xs={3}
                                  className={classes.itemTextPosition}>
                                <Link component="button"
                                      className ={isGalleryInExport(gallery) ? classes.linkGalleryExportInProgress : classes.linkGallery}
                                      onClick={() => showGallery(gallery)}>
                                    <Typography
                                        variant="h6"
                                        className={classes.galleryNameAndExportStatus}>
                                        {gallery.name}
                                        {
                                            gallery.export ? " (" + gallery.export.status + ")" : null
                                        }
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={7} className={classes.itemTextPosition}>
                                <div className={classes.filtersScrollMenu}>
                                    {
                                        gallery.filter_list ? gallery.filter_list.map((value) => (
                                            <Typography className={classes.filtersScrollMenuText} key={value.name}>
                                                {intl.formatMessage({
                                                    id: value.external_name,
                                                    defaultMessage: value.name
                                                })}
                                            </Typography>)) : null
                                    }
                                </div>
                            </Grid>
                            <Grid item>
                                <Grid container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                    <Grid item>
                                        <Tooltip placement="top"
                                                 title={gallery.last_update_date ?
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.last_update_date',
                                                         defaultMessage: 'Last update date: {date}'
                                                     }, { date: gallery.last_update_date }) :
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.last_update_date.not_available',
                                                         defaultMessage: 'Last update date not available'
                                                     })}>
                                            <InfoIcon className={classes.iconPosition}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip placement="top"
                                                 title={gallery.is_manual_order ?
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.manual.order',
                                                         defaultMessage: 'Manual Order'
                                                     }) :
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.automatic.order',
                                                         defaultMessage: 'Automatic Order'
                                                     })}>
                                            <BrightnessAutoIcon
                                                className={`${classes.iconPosition} ${gallery.is_manual_order ? classes.disableIcon : classes.activeIcon}`}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip placement="top"
                                                 title={gallery.new_products ?
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.new_products',
                                                         defaultMessage: 'New products available'
                                                     }) :
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.no_new_products',
                                                         defaultMessage: 'No new products'
                                                     })}>
                                            <NotificationsIcon
                                                className={`${classes.iconPosition} ${gallery.new_products ? classes.newProductsIcon : classes.activeIcon}`}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <IconButtonElement 
                                                icon={'edit_icon'}
                                                onClick={() => props.setCurrentGallery(gallery) }
                                                labelId={'showroom.tooltip.edit.gallery_search'}
                                                label={'Edit gallery'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Tooltip placement="top"
                                                 title={gallery.deleted_products ?
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.products_removed',
                                                         defaultMessage: 'Some products have been removed'
                                                     }) :
                                                     intl.formatMessage({
                                                         id: 'showroom.tooltip.no_products_removed',
                                                         defaultMessage: 'No product has been removed'
                                                     })}>
                                            <WarningIcon
                                                className={`${classes.iconPosition} ${gallery.deleted_products ? classes.deletedProductsIcon : classes.activeIcon}`}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <IconButtonElement icon={'delete_icon'}
                                                           onClick={() => deleteGallery(gallery.id, gallery.name)}
                                                           labelId={'showroom.tooltip.delete_gallery'}
                                                           label={'Delete gallery'}
                                                           disabled={isGalleryInExport(gallery)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="flex-end">
                                    <Grid item>
                                        <IconButtonElement icon={'insert_link_icon'}
                                                           onClick={() => gallery.export.minisite_url ? window.open(gallery.export.minisite_url) : null}
                                                           disabled={gallery.export ? gallery.export.minisite_url ? isGalleryInExport(gallery) : true : true}
                                                           labelId={gallery.export ?
                                                               intl.formatMessage({
                                                                   id: 'showroom.tooltip.export_date',
                                                                   defaultMessage: 'Export date'
                                                               }) + ": " + gallery.export.last_export_date
                                                               : "showroom.tooltip.export_info.not_available"}
                                                           label={gallery.export ?
                                                               intl.formatMessage({
                                                                   id: 'showroom.tooltip.export_date',
                                                                   defaultMessage: 'Export date'
                                                               }) + ": " + gallery.export.last_export_date
                                                               : "showroom.tooltip.export_info.not_available"}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )) : null;

    /* ************************************* HANDLER TO SHOW BACKDROP ********************************************** */
    /**
     * This effect is used to show backdrop during getting of contextId.
     */
    useEffect(() => {
        if (props.loadingContext || props.gallery.loading || props.galleries.loading) {
            setBackdropState((prevState => ({
                ...prevState,
                loading: true
            })));
        } else {
            setBackdropState((prevState => ({
                ...prevState,
                loading: false
            })));
        }
    }, [props.loadingContext, props.gallery, props.galleries])
    /* ************************************************************************************************************** */


    return (
        <div className={classes.root}>
            <BackdropElement loading={backdropState.loading} label={backdropState.label}/>
            <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={4}>
                <Grid item xs={12}>
                    <AccordionElement id={'showroom-context-menu'}
                                      header={'Context'}
                                      expanded={contextAccordionOpenState}
                                      onChangeHandler={contextAccordionOnChangeHandler}>
                        <Grid container
                              direction='row'
                              alignItems="flex-start"
                              justify="flex-start"
                              spacing={3}>
                            {contextElements}
                        </Grid>
                    </AccordionElement>
                </Grid>
            </Grid>
            <Grid container
                    direction={"row"}
                    justify="flex-end"
                    alignItems="center">
                <Grid item>
                    <IconButtonElement icon={'add_box_icon'}
                                        onClick={createNewGallery}
                                        labelId={'showroom.tooltip.add.gallery'}
                                        label={'Add new gallery'}
                                        disabled={!isContextDefinedStatus || !props.showroomContext}/>
                </Grid>
            </Grid>
            <Grid container
                    direction={"row"}
                    justify="flex-start"
                    alignItems="center">
                <Grid item xs={12}>
                    <div className={classes.galleriesScrollArea}>
                        {galleries}
                    </div>
                </Grid>
            </Grid>
            {
                newGalleryModalStatus.open &&
                <ReusableModal reusableModal={newGalleryModalStatus}
                               submitClick={confirmGalleryName}
                               closeReusableModal={() => setNewGalleryModalStatus((prevState => ({
                                   ...prevState,
                                   open: false,
                                   galleryName: ''
                               })))}>
                    <InputTextElement
                        labelId={"showroom.input.gallery_name.label"}
                        label={"Gallery Name"}
                        name={"gallery-name"}
                        handleChange={galleryNameHandler}
                        value={newGalleryModalStatus.galleryName}
                        type='text'
                        fullWidth
                    />
                </ReusableModal>
            }
            {
                deleteGalleryModalStatus.open &&
                <ReusableModal reusableModal={deleteGalleryModalStatus}
                               submitClick={confirmDeleteGallery}
                               closeReusableModal={() => setDeleteGalleryModalStatus((prevState => ({
                                   ...prevState,
                                   open: false,
                                   galleryId: null,
                                   galleryName: ''
                               })))}>
                    <Typography>
                        {deleteGalleryModalStatus.galleryId ? intl.formatMessage({
                            id: 'showroom.dialog.body.delete.gallery',
                            defaultMessage: 'Delete gallery:'
                        }) : null}
                        {
                            deleteGalleryModalStatus.galleryName ? " " + deleteGalleryModalStatus.galleryName + " ?" : null
                        }
                    </Typography>
                </ReusableModal>
            }
            {
                galleryInExportModalStatus.open &&
                <ReusableModal reusableModal={galleryInExportModalStatus}
                               closeReusableModal={() => setGalleryInExportModalStatus((prevState => ({
                                   ...prevState,
                                   open: false,
                                   galleryId: null,
                                   galleryName: ''
                               })))}>
                    <Typography>
                        {galleryInExportModalStatus.galleryId ? galleryInExportModalStatus.galleryName : null}
                        {
                            galleryInExportModalStatus.galleryId ?
                                intl.formatMessage({
                                    id: 'showroom.dialog.body.gallery.export.in_progress',
                                    defaultMessage: 'has export in progress, changes not available till completion'
                                })
                                :
                                null
                        }
                    </Typography>
                </ReusableModal>
            }
            {snackBarStatus.show &&
            <SnackbarElement open={snackBarStatus.show}
                             type={snackBarStatus.type}
                             label={snackBarStatus.message}
                             handleClose={() => closeSnackbar()}
                             showDuration={7200000}
            />}
        </div>
    )
}

const mapStateToProps = state => ({
    loadingRichiedente: state.richiedenteReducer.loadingRichiedente,
    loadingService: state.serviceReducer.loadingServices,
    loadingSeason: state.seasonReducer.loadingSeason,
    loadingBrand: state.brandReducer.loadingBrand,
    loadingGender: state.genderReducer.loadingGender,
    loadingEvents: state.eventsReducer.loadingEvents,
    loadingContext: state.contextShowroomReducer.loadingContext,
    richiedente: _.toArray(_.mapValues(state.richiedenteReducer.richiedente, function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    service: _.toArray(_.mapValues(state.serviceReducer.services, function (o) {
        return {
            label: o.service,
            value: o.service_guid,
            richiedenteService: o.richiedente_service_guid
        };
    })),
    seasons: _.toArray(_.mapValues(filterActiveSeasons(state.seasonReducer.seasons), function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    brand: _.toArray(_.mapValues(state.brandReducer.brand, function (o) {
        return ({
            label: o.name,
            value: o.guid
        })
    })),
    gender: _.toArray(_.mapValues(state.genderReducer.gender, function (o) {
        return ({
            label: o.name,
            value: o.guid
        })
    })),
    events: _.toArray(_.mapValues(state.eventsReducer.events, function (o) {
        return ({
            label: o.name,
            value: o.id
        })
    })),
    showroomContext: state.contextShowroomReducer.showroomContext,
    showroomDefaultContext: state.contextShowroomReducer.defaultContext,
    uploadedAssets: state.uploadAssetsCompositionShowroomReducer,
    errorContext: state.contextShowroomReducer.error,
    errorRichiedente: state.richiedenteReducer.error,
    errorSeason: state.seasonReducer.error,
    errorBrand: state.brandReducer.error,
    errorGender: state.genderReducer.error,
    errorEvents: state.eventsReducer.error,
    gallery: state.galleryProductShowroomReducer,
    galleries: state.galleriesProductShowroomReducer
});


const mapDispatchToProps = dispatch => ({
    getRichiedente: () => dispatch(startGetRichiedente()),
    getServicesByRichiedente: (richiedente) => dispatch(startGetServiceByRichiedenteFull(richiedente)),
    getSeason: () => dispatch(startGetSeasons()),
    getBrand: () => dispatch(startGetBrand()),
    getGender: () => dispatch(startGetGender()),
    getEvents: () => dispatch(startGetEvent()),
    getContext: (richiedenteService, season, brand, gender, event) => dispatch(startGetProductContext(richiedenteService, season, brand, gender, event)),
    getDefaultContext: () => dispatch(startGetProductShowroomDefaultContext()),
    clearContext: () => dispatch(clearContext()),
    clearGalleries: () => dispatch(clearGalleries()),
    setCurrentGallery: (gallery) => dispatch(setCurrentProductShowroomGallery(gallery)),
    createNewGallery: (contextId, galleryName, onSuccess) => dispatch(startPostProductShowroomGallery(contextId, galleryName, onSuccess)),
    clearNewGallery: () => dispatch(clearNewGallery()),
    clearDeleteGallery: () => dispatch(clearDeleteGallery()),
    getGalleries: (contextId) => dispatch(startGetProductShowroomGalleries(contextId)),
    deleteProductShowroomGallery: (galleryId) => dispatch(deleteProductShowroomGallery(galleryId)),
    clearGalleryAction: () => dispatch(clearGallerySortingAction()),
    updateDefaultContext: (context) => dispatch(updateProductShowroomDefaultContext(context))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductShowroomContainer);
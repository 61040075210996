import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import AccordionElementGalleryShowroom
    from "../../../components/AccordionElement/Showroom/Gallery/AccordionElementGalleryShowroom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import FileSaver from "file-saver";
import IconButtonElement from "../../../components/IconButtonElement/IconButtonElement";
import DndCompositionGalleryShowroomDetailElement
    from "../../../components/DragNDrop/DndCompositionGalleryShowroomDetailElement/DndCompositionGalleryShowroomDetailElement";
import ModalFullScreen from "../../../components/Modal/FullScreen/ModalFullScreen";
import DrawerElementShowroom from "../../../components/DrawerElement/DrawerElementShowroom/DrawerElementShowroom";
import ReusableModal from "../../../components/Modal/ReusableModal/ReusableModal";
import InputTextElement from "../../../components/InputTextElement/InputTextElement";
import DropDownElement from "../../../components/DropDownElement/DropDownElement";
import ButtonElement from "../../../components/ButtonElement/ButtonElement";
import {
    clearNewGallery,
    clearShowroomGalleries,
    startClearGalleryNames,
    startCreateNewGallery,
    startGetGalleryNamesFromOutputTypes,
    startGetShowroomGalleries,
    startUpdateAssetsOrderGallery
} from "../../../store/actions/showroom/composition/galleries";
import {clearOutputTypes, startGetOutputTypes} from "../../../store/actions/showroom/composition/output_type";
import {clearUpdateAsset, startDeleteAsset, startUpdateAssetInfos} from "../../../store/actions/showroom/context";
import {connect} from "react-redux";
import {clearLookcastExport, startLookcastExport} from "../../../store/actions/showroom/composition/exportToLookcast";
import BackdropElement from "../../../components/BackdropElement/BackdropElement";
import {clearLooks, startGetLooks} from "../../../store/actions/showroom/composition/looks";
import {
    clearGalleryDownloadInfo,
    startGetExcelGalleriesInfos,
    startGetExcelInfos,
    startGalleryDownloadAssets
} from "../../../store/actions/showroom/composition/downloads";
import SnackbarElement from "../../../components/SnackbarElement/SnackbarElement";
import ChipElement from "../../../components/ChipElement/ChipElement";
import Typography from "@material-ui/core/Typography";
import {checkAction} from "../../../utils/CommonFunction";

const useStyles = makeStyles((theme) => ({
    buttonContainerList: {
        marginBottom: '15px'
    },
    accordionContainerList: {
        overflowY: "scroll",
        overflowX: "hidden",
        minHeight: 128,
        maxHeight: 'calc(100vh - 630px)'
    },
    galleryNameChips: {
        marginTop: theme.spacing(2)
    },
    selectionTypeDropdown: {
        minWidth: '150px'
    },
    footerActions: {
        marginTop: '16px'
    }
}));

const GalleriesContainer = props => {
    const intl = useIntl();
    const classes = useStyles();
    const { contextId, filter } = props;

    const [galleriesState, setGalleriesState] = useState([]);
    const [galleriesDigitalAssetsState, setGalleriesDigitalAssetsState] = useState({});
    const [editModalStatus, setEditModalStatus] = useState({
        open: false,
        name: '',
        gallery: {},
        assetsPerRow: [],
        orderChange: false,
        selectAction: 'all',
        totalDigitalAssets: 0,
        digitalAssetsSelected: 0
    });
    const [newGalleryModalState, setNewGalleryModalState] = useState({
        open: false,
        title: intl.formatMessage({ id: 'showroom.dialog.title.new.gallery', defaultMessage: 'Add new gallery' }),
        outputType: '',
        galleryName: ''
    });
    const [carouselDrawer, setCarouselDrawer] = useState({
        open: false,
        gallery: [],
        currentAsset: {},
        currentIndex: 0,
        galleryId: null
    })
    const [currentInputState, setCurrentInputState] = useState({
        label: "",
        must_buy: "",
        look: "",
        output_types: [],
        galleries: [],
        updated: false
    })
    const [orderChangesMustExportedState, setOrderChangesMustExportedState] = useState(false);
    const [snackBarStatus, setSnackBarStatus] = useState({
        show: false,
        message: '',
        type: '',
        messageAbout: '',
        values: {}
    });


    /**
     * Effect used to clear all redux status about looks;
     */
    useEffect(() => {
        // Unmount of GalleriesContainer
        return (() => {
            props.clearGalleryNames();
            props.clearOutputTypes();
            props.clearGalleries();
            props.clearNewGallery();
            props.clearLooks();
            props.clearUpdatedAsset();
            props.clearExportLookcast();
            props.clearDownloads();
        });
    }, [])

    /**
     * Initial useEffect used to get all looks every time that contextId change.
     */
    useEffect(() => {
        if (contextId && filter) {
            props.getGalleries(contextId, filter);
        }
    }, [contextId, filter]);

    /**
     * Reading galleries based on the tap that user selected.
     */
    useEffect(() => {
        let galleries = [];
        let digitalAssetsGalleries = {};

        if (props.galleries && filter === 'all') {
            console.log('Setting data for galleries.', props.galleries);
            props.galleries.forEach(assetsGallery => {
                galleries.push(_.cloneDeep(assetsGallery.gallery));

                digitalAssetsGalleries[assetsGallery.gallery.id] = _.cloneDeep(assetsGallery.digital_assets);
            });
        } else if (props.galleriesDraft && filter === 'polaroid') {
            console.log('Setting data for draft galleries.', props.galleriesDraft);
            props.galleriesDraft.forEach(assetsGallery => {
                galleries.push(_.cloneDeep(assetsGallery.gallery));

                digitalAssetsGalleries[assetsGallery.gallery.id] = _.cloneDeep(assetsGallery.digital_assets);
            });
        } else if (props.galleriesFinal && filter === 'final') {
            console.log('Setting data for final galleries.', props.galleriesFinal);
            props.galleriesFinal.forEach(assetsGallery => {
                galleries.push(_.cloneDeep(assetsGallery.gallery));

                digitalAssetsGalleries[assetsGallery.gallery.id] = _.cloneDeep(assetsGallery.digital_assets);
            });
        } else {
            console.log('Cannot load galleries: unknown filter!!!');
        }

        setGalleriesState(galleries);
        setGalleriesDigitalAssetsState(digitalAssetsGalleries);
    }, [props.galleries, props.galleriesDraft, props.galleriesFinal, filter]);

    /** *************************************** D&D FULLWIDTH MODAL ********************************************** */
    /**
     * Handle changes caused by cancel of digitalAssets by a gallery.
     */
    useEffect(() => {
        if (editModalStatus.open) {
            let digitalAssetsGallery = galleriesDigitalAssetsState[editModalStatus.gallery.id] ?
                _.cloneDeep(galleriesDigitalAssetsState[editModalStatus.gallery.id]) :
                [];
            let chunkDigitalAssets = [];
            let tmpAssetChunk = [];

            if (digitalAssetsGallery.length > 0) {
                digitalAssetsGallery.forEach((asset, index) => {
                    asset.checked = false;
                    asset.position = index;
                    tmpAssetChunk.push(asset);

                    if (tmpAssetChunk.length === 6 || index === digitalAssetsGallery.length - 1) {
                        chunkDigitalAssets.push(tmpAssetChunk);
                        tmpAssetChunk = [];
                    }
                });

                setEditModalStatus((prevState => ({
                    ...prevState,
                    totalDigitalAssets: digitalAssetsGallery.length,
                    assetsPerRow: chunkDigitalAssets
                })));
            } else {
                setEditModalStatus((prevState => ({
                    ...prevState,
                    open: false,
                    name: '',
                    gallery: {},
                    selectAction: 'all',
                    assetsPerRow: [],
                    orderChange: false,
                    totalDigitalAssets: 0,
                    digitalAssetsSelected: 0
                })));
            }
        }
    }, [galleriesDigitalAssetsState]);

    const onClickEditHandler = (event, gallery) => {
        let digitalAssetsGallery = _.cloneDeep(galleriesDigitalAssetsState[gallery.id]);
        let chunkDigitalAssets = [];
        let tmpAssetChunk = [];

        digitalAssetsGallery.forEach((digitalAsset, index) => {
            digitalAsset.checked = false;
            digitalAsset.position = index;
            tmpAssetChunk.push(digitalAsset);

            if (tmpAssetChunk.length === 6 || index === digitalAssetsGallery.length - 1) {
                chunkDigitalAssets.push(tmpAssetChunk);
                tmpAssetChunk = [];
            }
        });

        setEditModalStatus((prevState => ({
            ...prevState,
            open: true,
            name: gallery.name.concat(' - ', gallery.output_type.name, ' (', gallery.export_status, ')'),
            gallery: { id: gallery.id, name: gallery.name },
            assetsPerRow: chunkDigitalAssets,
            selectAction: 'all',
            orderChange: false,
            totalDigitalAssets: digitalAssetsGallery.length,
            digitalAssetsSelected: 0
        })));
    };

    const onDragEndHandler = (result) => {
        const { source, destination } = result;
        console.log('DND result: ', result);

        if (source && destination) {
            let chunkDigitalAssets = [];
            let tmpAssetChunk = [];
            const startIndex = (6 * parseInt(source.droppableId)) + source.index;
            const endIndex = (6 * parseInt(destination.droppableId)) + destination.index;
            let assetsGallery = [];

            // Creating a single array of digital assets
            editModalStatus.assetsPerRow.forEach(rowAssets => {
                assetsGallery = _.concat(assetsGallery, rowAssets);

            });

            // Positioning items in the correct position
            const digitalAssetObj = assetsGallery.splice(startIndex, 1);
            assetsGallery.splice(endIndex, 0, digitalAssetObj[0]);


            // Splitting again into chuck to renderer elementes in the right way
            assetsGallery.forEach((asset, index) => {
                tmpAssetChunk.push(asset);

                if (tmpAssetChunk.length === 6 || index === assetsGallery.length - 1) {
                    chunkDigitalAssets.push(tmpAssetChunk);
                    tmpAssetChunk = [];
                }
            });

            setEditModalStatus(prevState => ({
                ...prevState,
                assetsPerRow: chunkDigitalAssets,
                orderChange: true
            }));
        }
    };

    const selectCheckboxHandler = (event, rowIndex, assetIndex) => {
        let newEditModalStatus = _.cloneDeep(editModalStatus);

        if (newEditModalStatus.assetsPerRow[rowIndex][assetIndex].checked) {
            newEditModalStatus.assetsPerRow[rowIndex][assetIndex].checked = false;
            newEditModalStatus.digitalAssetsSelected -= 1;
        } else {
            newEditModalStatus.assetsPerRow[rowIndex][assetIndex].checked = true;
            newEditModalStatus.digitalAssetsSelected += 1;
        }

        setEditModalStatus(newEditModalStatus);
    };

    const unselectAll = (event) => {
        let newEditModalStatus = _.cloneDeep(editModalStatus)

        newEditModalStatus.assetsPerRow.forEach(assetsRow => {
            assetsRow.forEach(asset => {
                asset.checked = false;
            })
        });
        newEditModalStatus.digitalAssetsSelected = 0;

        setEditModalStatus(newEditModalStatus);
    }

    const actionTypeOnChangeHandler = (event) => {
        setEditModalStatus(prevState => ({
            ...prevState,
            selectAction: event.target.value
        }));
    };

    const selectOnClickHandler = (event) => {
        let newEditModalStatus = _.cloneDeep(editModalStatus);
        let totSelected = 0;

        switch (editModalStatus.selectAction) {
            case "all":
                newEditModalStatus.assetsPerRow.forEach(assetsRow => {
                    assetsRow.forEach(asset => {
                        asset.checked = true;
                        totSelected += 1;
                    })
                });

                break;
            case "final":
                newEditModalStatus.assetsPerRow.forEach(assetsRow => {
                    assetsRow.forEach(asset => {
                        if (!asset.is_polaroid) {
                            asset.checked = true;
                            totSelected += 1;
                        } else {
                            asset.checked = false;
                        }
                    })
                });
                break;
            case "draft":
                newEditModalStatus.assetsPerRow.forEach(assetsRow => {
                    assetsRow.forEach(asset => {
                        if (asset.is_polaroid) {
                            asset.checked = true;
                            totSelected += 1;
                        } else {
                            asset.checked = false;
                        }
                    })
                });
                break;
            default:
                console.log('Unknow value! ', newEditModalStatus);
                break;
        }
        newEditModalStatus.digitalAssetsSelected = totSelected;


        setEditModalStatus(newEditModalStatus);
    };

    /**
     * This function is used to remove an image from a gallery. This action can be done into accordion or in the edit
     * ModalFullScreen.
     *
     * @param event
     * @param digitalAsset {object}: photo/video object to update.
     * @param galleryId {int}: integer of gallery. We are going to receive this value from accordion but not from modal.
     */
    const removeAssetFromGallery = (_event, digitalAsset, galleryId) => {
        const galleries = [];

        if (galleryId) {
            digitalAsset.galleries.forEach((gallery) => {
                if (gallery.gallery.id !== galleryId) {
                    galleries.push(gallery.gallery.id);
                }
            });
        } else {
            // In this case the galleryId is read by modal state.
            digitalAsset.galleries.forEach((gallery) => {
                if (gallery.gallery.id !== editModalStatus.gallery.id) {
                    galleries.push(gallery.gallery.id);
                }
            });
        }


        const body = {
            label: digitalAsset.label,
            galleries: galleries,
            look: digitalAsset.look ? digitalAsset.look.id : null,
            must_buy: digitalAsset.must_buy
        };

        if (digitalAsset.checked) {
            setEditModalStatus(prevState => ({
                ...prevState,
                digitalAssetsSelected: prevState.digitalAssetsSelected - 1
            }))
        }
        props.removeAssetFromGallery(contextId, digitalAsset.id, body, filter);
    };

    /**
     * This function is used to remove physically image/video from our application.
     * @param event
     * @param digitalAssetId {int}: identifier of the photo/video that is going to be removed.
     */
    const removeAsset = (event, digitalAssetId) => {
        props.deleteAsset(contextId, digitalAssetId, filter);
    };

    const closeSaveEditingModalHandler = () => {
        if (editModalStatus.orderChange) {
            let digitalAssetsId = [];

            editModalStatus.assetsPerRow.forEach(rowAssets => {
                rowAssets.forEach(asset => {
                    digitalAssetsId.push(asset.id);
                })
            })

            props.updateDigitalAssetsOrderOfGallery(contextId, editModalStatus.gallery.id, digitalAssetsId, filter);
        }
        setEditModalStatus((prevState => ({
            ...prevState,
            open: false,
            name: '',
            gallery: {},
            assetsPerRow: [],
            orderChange: false,
            totalDigitalAssets: 0,
            digitalAssetsSelected: 0
        })));
    };

    const exportGalleryToLookcast = (event) => {
        if (editModalStatus.orderChange) {
            console.log('Exporting with order change!');
            let digitalAssetsId = [];

            editModalStatus.assetsPerRow.forEach(rowAssets => {
                rowAssets.forEach(asset => {
                    digitalAssetsId.push(asset.id);
                });
            });

            props.updateDigitalAssetsOrderOfGallery(contextId, editModalStatus.gallery.id, digitalAssetsId, filter);
            setOrderChangesMustExportedState(true);
        } else {
            console.log('Exporting with no changes!');
            props.exportGallery(contextId, editModalStatus.gallery.id, filter);
        }
    };

    /**
     * This effect is used to handle the case of export to lookcast with order change in pending.
     */
    useEffect(() => {
        if (orderChangesMustExportedState && !props.loadingAssetsOrderGallery && props.statusAssetsOrderGallery === 'OK') {
            console.log('Exporting after update changes!', orderChangesMustExportedState, props.statusAssetsOrderGallery, props.loadingAssetsOrderGallery);
            props.exportGallery(contextId, editModalStatus.gallery.id, filter);
            setOrderChangesMustExportedState(false);
            setEditModalStatus((prevState => ({ ...prevState, orderChange: false })));
        }
    }, [orderChangesMustExportedState, props.statusAssetsOrderGallery, props.loadingAssetsOrderGallery]);

    /**
     * useEffect to close modal if export has been completed with success.
     */
    useEffect(() => {
        if (props.lookcastExportResult) {
            closeSaveEditingModalHandler();
        }
    }, [props.lookcastExportResult]);

    /**
     * [DOWNLOAD ASSETS]
     */
    const downloadAssets = () => {
        console.log("Downloading with ", editModalStatus)

        const assetsIdList = editModalStatus.assetsPerRow.reduce((prev, assetsRow) => [
            ...prev,
            ...assetsRow.filter(asset => asset.checked).reduce((list, asset) => [ ...list, asset.id], [])
        ], []);

        props.downloadAssets(contextId, editModalStatus.gallery.id, assetsIdList);
    };

    const downloadExcel = () => {
        props.getExcelInfos(contextId, editModalStatus.gallery.id);
    };

    const downloadExcelGalleries = () => {
        props.getExcelGalleriesInfos(contextId);
    };

    useEffect(() => {
        if (!props.showroomDownloads.loading && props.showroomDownloads.actionType === 'GET' && props.showroomDownloads.galleryExcelUrl) {
            fetch(props.showroomDownloads.galleryExcelUrl)
                .then(r => r.blob())
                .then(blob => {
                    FileSaver.saveAs(blob, editModalStatus.gallery.name.concat('.xlsx'));
                    props.clearDownloads();
                })
                .catch(error => {
                    console.log(error);
                    setSnackBarStatus(prevState => ({
                        ...prevState,
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom.downloading.gallery.excel.error',
                            defaultMessage: 'Cannot download gallery\'s excel.'
                        }),
                        values: {},
                        type: 'error',
                        messageAbout: 'downloadExcel'
                    }))
                })
        }
        else if (!props.showroomDownloads.loading && props.showroomDownloads.actionType === 'POST' && props.showroomDownloads.galleryAssetsUrl) {
            fetch(props.showroomDownloads.galleryAssetsUrl)
                .then(r => r.blob())
                .then(blob => {
                    FileSaver.saveAs(blob, editModalStatus.gallery.name.concat('_galleriesAssets.zip'));
                    props.clearDownloads();
                })
                .catch(error => {
                    console.log(error);
                    setSnackBarStatus(prevState => ({
                        ...prevState,
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom.downloading.gallery.zip.error',
                            defaultMessage: 'Cannot download gallery\'s assets.'
                        }),
                        values: {},
                        type: 'error',
                        messageAbout: 'downloadZip'
                    }))
                })
        }
        else if (!props.showroomDownloads.loading && props.showroomDownloads.actionType === 'GET' && props.showroomDownloads.excelSent ) {
            setSnackBarStatus(prevState => ({
                ...prevState,
                show: true,
                message: intl.formatMessage({
                    id: 'showroom.downloading.gallery.excel.success',
                    defaultMessage: 'Excel sent successfully.'
                }),
                values: {},
                type: 'success',
                messageAbout: 'downloadExcel'
            }))
        }
        else {
            // Error of creation of file is handled into showroomContainer
        }
    }, [props.showroomDownloads]);

    const editModalActions = [
        <div className={classes.selectionTypeDropdown} key={'select-action-container'}>
            <DropDownElement
                name="select-action"
                value={editModalStatus.selectAction}
                label={{
                    translateKey: "search.button.actions",
                    defaultText: "Selection Type",
                }}
                onChangeHandler={actionTypeOnChangeHandler}
                options={[
                    { value: 'all', label: 'all' },
                    { value: 'draft', label: 'draft' },
                    { value: 'final', label: 'final' }
                ]}
            />
        </div>,
        <ButtonElement key={'select-button'}
                       labelId={'common.select'}
                       label={'Select'}
                       onSubmit={selectOnClickHandler}
                       disabled={_.isEmpty(editModalStatus.selectAction)}/>,
        <ButtonElement key={'unselect-button'}
                       labelId={'common.unselect.all'}
                       label={'Unselect All'}
                       onSubmit={unselectAll}
                       disabled={editModalStatus.digitalAssetsSelected === 0}/>,
        <ButtonElement key={'download-assets-button'}
                       labelId={'common.button.label.download.images'}
                       label={'Download Assets'}
                       onSubmit={downloadAssets}
                       disabled={editModalStatus.digitalAssetsSelected === 0}/>,
        <ButtonElement key={'download-excel-button'}
                       labelId={'common.button.label.download.excel'}
                       label={'Download Excel'}
                       onSubmit={downloadExcel}
                       disabled={null}/>,
        filter !== 'polaroid' && checkAction("composition.send_gallery", props.auth.actions) ?
            <ButtonElement key={'send-gallery-button'}
                           labelId={'showroom.send.gallery'}
                           label={'Send Gallery (Only selected)'}
                           onSubmit={exportGalleryToLookcast}
                           disabled={null}/> :
            null
    ];
    /** ************************************************************************************************************* */

    /** ***************************************** DRAWER ASSETS LIST ************************************************* */
    useEffect(() => {
        if (carouselDrawer.open) {
            let currentGallery = galleriesDigitalAssetsState[carouselDrawer.galleryId];

            if (currentGallery.length > 0) {
                console.log("currentAsset: ", currentGallery);
                setCarouselDrawer({ ...carouselDrawer, gallery: currentGallery })
            } else {
                setCarouselDrawer({
                    open: false,
                    gallery: [],
                    currentAsset: {},
                    currentIndex: 0,
                    galleryId: null
                });
            }
        }
    }, [galleriesDigitalAssetsState]);

    useEffect(() => {
        if (carouselDrawer.currentAsset) {
            let currentAsset = carouselDrawer.currentAsset;
            let currentGalleries = [];
            let currentOutputTypes = [];
            if (currentAsset.galleries) {
                currentGalleries = _.toArray(_.mapValues(currentAsset.galleries, function (o) {
                    return {
                        elem: o.gallery.name,
                        label: o.gallery.name + " (" + (o.gallery.output_type.name || "") + ") ",
                        value: o.gallery.name,
                        id: o.gallery.id || "",
                        output_type_id: o.gallery.output_type.id,
                        selected: true
                    };
                }))
            }
            if (currentAsset.output_types) {
                currentOutputTypes = _.toArray(_.mapValues(currentAsset.output_types, function (o) {
                    return {
                        elem: o.name,
                        label: o.name,
                        value: o.name,
                        id: o.id || "",
                        selected: true
                    };
                }))
            }
            setCurrentInputState({
                label: currentAsset.label || "",
                must_buy: currentAsset.must_buy !== undefined ? currentAsset.must_buy : "",
                look: currentAsset.look ? currentAsset.look["id"] : "",
                output_types: currentOutputTypes,
                galleries: currentGalleries
            })
        }
    }, [carouselDrawer.currentAsset])

    const handleClickImg = (event, galleryId, id, index) => {
        console.log("I'm clicking!!!", event, galleryId, id, index);
        if ( checkAction("composition.edit_uploaded_images", props.auth.actions) ) {
            console.log("galleriesDigitalAssetsState, ", galleriesDigitalAssetsState, galleriesDigitalAssetsState[galleryId]);
            props.getLooks(contextId);
            let currentGallery = galleriesDigitalAssetsState[galleryId];
            let currentAsset = currentGallery.find(el => el.id === id);
            console.log("currentAsset: ", currentAsset);
            setCarouselDrawer({
                open: true,
                gallery: currentGallery,
                currentAsset: currentAsset,
                currentIndex: index,
                galleryId: galleryId
            })
        }
        else console.log("User not authorized to edit images.");
    }

    const updateAssetInfos = (id) => {
        setCurrentInputState({ ...currentInputState, updated: false })
        let currentStateToSend = _.cloneDeep(currentInputState);
        let galleries = [];
        if (currentStateToSend.galleries.length > 0) galleries = currentStateToSend.galleries.map(g => g.id);
        currentStateToSend.galleries = galleries;
        if (currentStateToSend.look === "") currentStateToSend.look = null;
        delete currentStateToSend.output_types;
        delete currentStateToSend.updated;
        console.log(currentInputState, galleries, id, currentStateToSend);
        props.updateAssetInfos(contextId, id, currentStateToSend, filter)
    }

    const handlePrevClick = () => {
        let advancement = 1;
        if (currentInputState.updated) {
            updateAssetInfos(carouselDrawer.currentAsset.id);
            console.log("[handlePrevClick] Not advancing since asset has just been updated");
        }else{
            console.log("[handlePrevClick] Advancing");
        }

        let newIndex = carouselDrawer.currentIndex - advancement;
        let currentAsset = carouselDrawer.gallery[newIndex];

        console.log("Gallery: ", carouselDrawer.gallery);

        if (newIndex >= 0) {
            console.log("I'm setting the new state with currentAsset: ", currentAsset, "and the current index is ", newIndex)
            setCarouselDrawer({ ...carouselDrawer, currentAsset: currentAsset, currentIndex: newIndex });
        } else {
            console.log("Let's start over with index ", carouselDrawer.gallery.length - 1)
            setCarouselDrawer({
                ...carouselDrawer,
                currentAsset: carouselDrawer.gallery[carouselDrawer.gallery.length - 1],
                currentIndex: carouselDrawer.gallery.length - 1
            });
        }
    };

    const handleNextClick = () => {
        let advancement = 1;
        let previousGallery = _.cloneDeep(carouselDrawer);
        if (currentInputState.updated) {
            console.log("current input state 1: ", currentInputState);
            updateAssetInfos(carouselDrawer.currentAsset.id);
            console.log("current input state 2: ", currentInputState);
            console.log("[handleNextClick] Not advancing since asset has just been updated");
            if(currentInputState.galleries.length > 0){
                previousGallery.gallery.splice(carouselDrawer.currentIndex, 1);
                advancement = 0;
            }

        }else{
            console.log("[handleNextClick] Advancing");
        }

        let newIndex = carouselDrawer.currentIndex + advancement;
        let currentAsset = previousGallery.gallery[newIndex];

        console.log("Gallery: ", carouselDrawer.gallery);
        console.log("PreviousGallery: ",previousGallery.gallery);

        if(previousGallery.gallery.length === 0){
            setCarouselDrawer({ ...carouselDrawer, open: false });
            // props.clearGalleryNames();
            return;
        }

        if (newIndex >= previousGallery.gallery.length) {
            console.log("Let's start over with index 0")
            setCarouselDrawer({ ...previousGallery, currentAsset: previousGallery.gallery[0], currentIndex: 0 });
        } else {
            console.log("I'm setting the new state with currentAsset: ", currentAsset, "and the current index is ", newIndex)
            setCarouselDrawer({ ...previousGallery, currentAsset: currentAsset, currentIndex: newIndex });
        }
    };

    const handleCloseDrawer = () => {
        setCarouselDrawer({ ...carouselDrawer, open: false });
        if (currentInputState.updated) updateAssetInfos(carouselDrawer.currentAsset.id);
        props.clearGalleryNames();
    }

    const handleCurrentInputState = (event) => {
        console.log(event.target.value, event.target.name, currentInputState);
        let fieldName = event.target.name;
        let value = event.target.value;
        setCurrentInputState({ ...currentInputState, [fieldName]: value, updated: true });
    }

    const handleChangeAutocomplete = (event, name, selectedValues) => {
        console.log("SEARCH TYPE AUTOCOMPLETE", name, selectedValues, currentInputState[name]);
        let newArray = Array.from(selectedValues);
        newArray.forEach((elem) => {
            elem.selected = true;
        });
        if (name === 'output_types') {
            props.clearGalleryNames();
            if (currentInputState["galleries"].length > 0) {
                let currentGals = _.cloneDeep(currentInputState["galleries"]);
                _.remove(currentGals, function (elem) {
                    return selectedValues.find(s => s.id === elem.output_type_id) === undefined
                })
                console.log(currentGals);
                setCurrentInputState({ ...currentInputState, galleries: currentGals, [name]: newArray, updated: true })
            } else {
                setCurrentInputState({ ...currentInputState, [name]: newArray, updated: true })
            }
        } else {
            setCurrentInputState({ ...currentInputState, [name]: newArray, updated: true })
        }
    };

    const callOptionList = {
        output_types: () => props.getOutputTypes(),
        galleries: () => getAllGalleries()
    }

    const getAllGalleries = () => {
        currentInputState.output_types.forEach(elem => {
            if (elem.selected) {
                console.log("Selected ")
                props.getGalleryNames(elem.id)
            }
        })
    }
    /** ********************************************************************************************************* */

    /** ***************************************** NEW GALLERY MODAL ********************************************* */
    const openNewGalleryModal = () => {
        setNewGalleryModalState(prevState => ({ ...prevState, open: true }));

        if (props.options.output_types === undefined || props.options.output_types.length === 0) {
            props.getOutputTypes();
        }
    };

    const onChangeOutputTypeNewGalleryHandler = event => {
        setNewGalleryModalState(prevState => ({ ...prevState, outputType: event.target.value }));
        props.getGalleryNames(event.target.value);
    };

    const onChangeNewGalleryNameHandler = event => {
        let newState = _.cloneDeep(newGalleryModalState);

        newState.galleryName = event.target.value;

        setNewGalleryModalState(newState);
    };

    const confirmNewGalleryOnClickHandler = event => {
        props.createGallery(newGalleryModalState.outputType, newGalleryModalState.galleryName);
        setNewGalleryModalState(prevState => ({ ...prevState, open: false, outputType: '', galleryName: '' }))
        props.clearOutputTypes();
        props.clearGalleryNames();
    };
    /** ********************************************************************************************************* */

    const navToLookcastOnClickHandler = (event, mini_site_url) => {
        window.open(mini_site_url, "_blank")
    };

    const accordionList = galleriesState.map(gallery => {
        if (filter === 'all') {
            return <React.Fragment key={gallery.id}>
                <Grid item xs={11}>
                    <AccordionElementGalleryShowroom
                        gallery={gallery}
                        handleClickImg={handleClickImg}
                        logicDeleteHandler={removeAssetFromGallery}
                        deleteHandler={removeAsset}
                        assetsGallery={galleriesDigitalAssetsState[gallery.id]}
                        notFoundMessage={intl.formatMessage({
                            id: 'showroom.warning.assets.not.found',
                            defaultMessage: 'No assets associate to the current gallery.'
                        })}
                        key={gallery.id}
                    />
                </Grid>
                {/* Removing icon for default gallery */}
                {gallery.name &&
                <Grid item xs={1}>
                    <IconButtonElement icon={'insert_link_icon'}
                                       onClick={(event) => navToLookcastOnClickHandler(event, gallery.mini_site_url)}
                                       disabled={gallery.mini_site_url === null}
                                       labelId={'showroom.tooltip.mini_site_url'}
                                       label={'Go lookcast web-site.'}/>
                    <IconButtonElement icon={'edit_icon'}
                                       onClick={(event) => onClickEditHandler(event, gallery)}
                                       disabled={galleriesDigitalAssetsState[gallery.id].length === 0}
                                       labelId={'showroom.tooltip.edit.gallery'}
                                       label={'Gallery Management'}/>
                </Grid>
                }
            </React.Fragment>
        } else {
            return <React.Fragment key={gallery.id}>
                <Grid item xs={12}>
                    <AccordionElementGalleryShowroom
                        gallery={gallery}
                        handleClickImg={handleClickImg}
                        logicDeleteHandler={removeAssetFromGallery}
                        deleteHandler={removeAsset}
                        assetsGallery={galleriesDigitalAssetsState[gallery.id]}
                        notFoundMessage={intl.formatMessage({
                            id: 'showroom.warning.assets.not.found',
                            defaultMessage: 'No assets associate to the current gallery.'
                        })}
                        key={gallery.id}
                    />
                </Grid>
            </React.Fragment>
        }
    });


    /**
     * Handler to close snackbar message and clear redux state to prevent the rendering of the same message.
     */
    const closeSnackbar = () => {
        switch (snackBarStatus.messageAbout) {
            case 'downloadExcel':
            case 'downloadZip':
                props.clearDownloads();
                break;
            default:
                break;
        }

        setSnackBarStatus({
            show: false,
            message: '',
            values: {},
            type: '',
            messageAbout: ''
        })
    };


    return (
        <div>
            <BackdropElement loading={props.showroomDownloads.loading}/>
            <Grid container
                  direction={"row"}
                  justify="flex-end"
                  alignItems="flex-start"
                  className={classes.buttonContainerList}
            >
                {checkAction("composition.add_gallery", props.auth.actions) &&
                    <Grid>
                        <IconButtonElement icon={'add_box_icon'}
                                        onClick={openNewGalleryModal}
                                        labelId={'showroom.tooltip.add.gallery'}
                                        label={'Add new gallery'}/>
                    </Grid>
                }
            </Grid>
            <Grid container
                  direction={"row"}
                  justify={"flex-start"}
                  alignItems={"center"}
                  spacing={2}
                  className={classes.accordionContainerList}>
                {accordionList}
            </Grid>
            {(props.galleries.length > 1 && filter === 'all') &&
                <Grid container
                    direction={"row"}
                    justify="flex-end"
                    alignItems="flex-start"
                    className={classes.footerActions}
                >
                    <Grid>
                        <ButtonElement key={'download-excel-button'}
                            labelId={'common.button.label.download.excel'}
                            label={'Download Excel'}
                            onSubmit={downloadExcelGalleries}
                        />
                    </Grid>
                </Grid>
            }
            {
                carouselDrawer.open &&
                <DrawerElementShowroom
                    isOpen={carouselDrawer.open}
                    closeHandler={handleCloseDrawer}
                    data={carouselDrawer.currentAsset}
                    handlePrevClick={handlePrevClick}
                    handleNextClick={handleNextClick}
                    currentInputState={currentInputState}
                    callOptionList={callOptionList}
                    optionList={props.options}
                    loadings={props.loadings}
                    handleCurrentInputState={handleCurrentInputState}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    anchor="down"
                />
            }
            {
                editModalStatus.open && editModalStatus.gallery && editModalStatus.assetsPerRow &&
                <ModalFullScreen openDialog={editModalStatus.open}
                                 dialogTitle={editModalStatus.name}
                                 closeHandler={closeSaveEditingModalHandler}
                                 saveHandler={checkAction("composition.sort_images_gallery", props.auth.actions) ?
                                    closeSaveEditingModalHandler : null
                                 }
                                 footerActions={editModalActions}>
                    <DndCompositionGalleryShowroomDetailElement gallery={editModalStatus.gallery}
                                                                chunkList={editModalStatus.assetsPerRow}
                                                                selectHandler={selectCheckboxHandler}
                                                                deleteHandler={removeAssetFromGallery}
                                                                onDragEnd={onDragEndHandler}/>
                </ModalFullScreen>
            }
            {
                newGalleryModalState.open &&
                <ReusableModal
                    reusableModal={newGalleryModalState}
                    submitClick={confirmNewGalleryOnClickHandler}
                    closeReusableModal={() => setNewGalleryModalState((prevState => ({
                        ...prevState,
                        open: false,
                        outputType: '',
                        galleryName: ''
                    })))}>
                    <DropDownElement name="outputTypes-select"
                                     value={newGalleryModalState.outputType}
                                     onChangeHandler={onChangeOutputTypeNewGalleryHandler}
                                     options={_.toArray(_.mapValues(props.options.output_types, (output_type) => {
                                         return {
                                             label: output_type.name,
                                             value: output_type.id,
                                         };
                                     }))}
                                     loading={props.loadings.output_types}
                                     upLabel={{
                                         id: 'showroom.dropdown.output_types',
                                         defaultMessage: 'Output Types'
                                     }}/>
                    <InputTextElement
                        labelId={"showroom.input.gallery_name.label"}
                        label={"Gallery Name"}
                        name={"gallery-name"}
                        handleChange={onChangeNewGalleryNameHandler}
                        value={newGalleryModalState.galleryName}
                        type='text'
                        fullWidth
                    />
                    <div className={classes.galleryNameChips}>
                        <Typography>
                            {intl.formatMessage({
                                id: 'showroom.existing.gallery.name.list',
                                defaultMessage: 'Existing galleries:'
                            })}
                        </Typography>
                        {props.galleryNames &&
                        props.galleryNames.length > 0 && props.galleryNames.map(galleryName => <ChipElement
                            key={galleryName.id}
                            label={galleryName.name}
                            cssType={'lightGrey'}/>)}
                    </div>
                </ReusableModal>
            }
            {snackBarStatus.show &&
            <SnackbarElement open={snackBarStatus.show}
                             type={snackBarStatus.type}
                             label={snackBarStatus.message}
                             handleClose={() => closeSnackbar()}
                             showDuration={7200000}
            />}
        </div>
    );
};


const mapStateToProps = state => ({
    auth: state.authReducer.auth,
    showroomContext: state.contextShowroomReducer.showroomContext,
    galleries: state.galleriesCompositionShowroomReducer.galleries,
    galleriesDraft: state.galleriesCompositionShowroomReducer.galleriesDraft,
    galleriesFinal: state.galleriesCompositionShowroomReducer.galleriesFinal,
    options: {
        looks: _.concat(_.toArray(_.mapValues(state.looksReducer.looks, function (o) {
            return {
                label: o.name,
                value: o.id,
            };
        })), { key: 0, value: "", label: "No Look" }),
        output_types: state.outputTypesReducer.outputTypes,
        galleries: state.galleriesCompositionShowroomReducer.allGalleryNames
    },
    loadings: {
        looks: state.looksReducer.loadingLooks,
        output_types: state.outputTypesReducer.loadingOutputTypes,
        galleries: state.galleriesCompositionShowroomReducer.loadingGalleryNames
    },
    errorExport: state.lookcastExportReducer.error,
    loadingAssetsOrderGallery: state.galleriesCompositionShowroomReducer.loadingAssetsOrder,
    statusAssetsOrderGallery: state.galleriesCompositionShowroomReducer.statusUpdateOrder,
    galleryNames: state.galleriesCompositionShowroomReducer.galleryNames,
    lookcastExportResult: state.lookcastExportReducer.exportResult,
    showroomDownloads: state.showroomDownloadsReducer
});


const mapDispatchToProps = dispatch => ({
    getGalleries: (contextId, filter) => dispatch(startGetShowroomGalleries(contextId, filter)),
    getLooks: (contextId) => dispatch(startGetLooks(contextId)),
    updateDigitalAssetsOrderOfGallery: (contextId, galleryId, assetsOrder, filter) => dispatch(startUpdateAssetsOrderGallery(contextId, galleryId, assetsOrder, filter)),
    getGalleryNames: (output_type_id) => dispatch(startGetGalleryNamesFromOutputTypes(output_type_id)),
    getOutputTypes: () => dispatch(startGetOutputTypes()),
    removeAssetFromGallery: (contextId, assetId, body, filter) => dispatch(startUpdateAssetInfos(contextId, assetId, body, filter)),
    updateAssetInfos: (contextId, digitalAssetId, body, filter) => dispatch(startUpdateAssetInfos(contextId, digitalAssetId, body, filter)),
    deleteAsset: (contextId, assetId, filter) => dispatch(startDeleteAsset(contextId, assetId, filter)),
    exportGallery: (contextId, galleryId, filter) => dispatch(startLookcastExport(contextId, galleryId, filter)),
    createGallery: (outputTypeId, galleryName) => dispatch(startCreateNewGallery(outputTypeId, galleryName)),
    clearGalleryNames: () => dispatch(startClearGalleryNames()),
    clearOutputTypes: () => dispatch(clearOutputTypes()),
    clearGalleries: () => dispatch(clearShowroomGalleries()),
    clearNewGallery: () => dispatch(clearNewGallery()),
    clearLooks: () => dispatch(clearLooks()),
    clearUpdatedAsset: () => dispatch(clearUpdateAsset()),
    clearExportLookcast: () => dispatch(clearLookcastExport()),
    getExcelInfos: (contextId, galleryId) => dispatch(startGetExcelInfos(contextId, galleryId)),
    getExcelGalleriesInfos: (contextId) => dispatch(startGetExcelGalleriesInfos(contextId)),
    clearDownloads: () => dispatch(clearGalleryDownloadInfo()),
    downloadAssets: (contextId, galleryId, assets) => dispatch(startGalleryDownloadAssets(contextId, galleryId, assets))
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleriesContainer);

import {GET_GENDER_FAILURE, GET_GENDER_REQUEST, GET_GENDER_SUCCESS} from "../actions/gender";

const initialState = {gender: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GENDER_REQUEST:
            return {
                loadingGender: true
            };
        case GET_GENDER_SUCCESS:
            return {
                ...state,
                loadingGender: false,
                gender: action.gender,
            };
        case GET_GENDER_FAILURE:
            return {
                ...state,
                loadingGender: false,
                error: action.error
            }
        default:
            return state;
    }
}
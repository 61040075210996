import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    imgContainer: {
        display: 'flex',
        width: '100%'
    },
    item: {
        position: "relative"
    },
    deleteIcon: {
        '& .MuiButtonBase-root': {
            color: theme.palette.button.deactive
        }
    },
    mustBuyIcon: {
        color: theme.palette.primary.text
    },
    infoIcon: {
        marginTop: '4px',
        marginLeft: '6px'
    },
    completeProductImgStyle: {
        border: '4px solid '.concat(theme.palette.complete.main)
    },
    notValidProductImgStyle: {
        border: '4px solid '.concat(theme.palette.notValid.main)
    },
    wrongContextProductImgStyle: {
        border: '4px solid '.concat(theme.palette.wrongContext.main)
    },
    placeholderProductImgStyle: {
        border: '4px solid '.concat(theme.palette.placeholder.main)
    },
    placeholderFromBarcodeProductImgStyle: {
        border: '4px solid '.concat(theme.palette.placeholderFromBarcode.main)
    },
    movieFrameContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
}));


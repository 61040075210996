import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiTextField-root": {
			'& .MuiFormHelperText-root': {
				position: 'absolute',
				top: theme.spacing(5),
				zIndex: 2,
				backgroundColor: theme.palette.background.main,
				margin: 1,
				padding: theme.spacing(1, 1.5, 0.5, 1.5),
				borderBottomLeftRadius: theme.spacing(0.5),
				borderBottomRightRadius: theme.spacing(0.5),
				width: '-webkit-fill-available'
			},
			margin: theme.spacing(1, 0),
		},
		"& .MuiSvgIcon-root": {
			fill: theme.palette.text.placeholder,
		},
		"& .MuiInputBase-input": {
			color: theme.palette.text.main,
		},
		"& .MuiChip-deleteIcon": {
			fill: theme.palette.text.placeholder,
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-disabled fieldset": {
				borderColor: "transparent",
				boxShadow: "none !important",
			},
			"& fieldset": {
				borderColor: "transparent",
			},
			"&.Mui-focused fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&:hover fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&.Mui-error fieldset": {
				borderColor: theme.palette.error.main,
				boxShadow: "0 0 5px ".concat(theme.palette.error.main),
			},
		},
	},
	autocompleteInput: {
		backgroundColor: theme.palette.background.main,
		borderRadius: theme.spacing(0.5),
		color: theme.palette.text.main,
		"& label.Mui-focused": {
			color: theme.palette.text.main,
		},
	},
	loader: {
		color: theme.palette.text.main,
	},
	paper: {
		backgroundColor: theme.palette.headerTable.main,
	},
	inputLabelRoot: {
		color: theme.palette.text.main,
		"&.focused": {
			color: theme.palette.text.main,
		},
	},
	chips: {
		color: theme.palette.text.main,
		backgroundColor: theme.palette.background.main,
		height: theme.spacing(3.5),
		"& .MuiChip-label": {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		}
	},
	option: {
		color: theme.palette.text.main,
		fontSize: "1rem",
		paddingTop: 0,
		paddingBottom: 0,
		"&:hover": {
			backgroundColor: theme.palette.background.hover,
		},
	},
	checkbox: {
		color: theme.palette.text.placeholder + " !important",
	},
	upLabel: {
		fontSize: "small",
		textAlign: "left",
		marginTop: theme.spacing(1)
	}
}));

import {
    CLEAR_FILTER_TABLE_COUNT,
    CLEAR_RESPONSE_SEARCH,
    CLEAR_SEARCH_FLAG,
    POST_COUNT_REQUEST,
    POST_SEARCH_ASSETS_REQUEST_SUCCESS,
    POST_SEARCH_COUNT_SUCCESS,
    POST_SEARCH_FILTER_TABLE_COUNT_SUCCESS,
    POST_SEARCH_PRODUCTS_REQUEST_SUCCESS,
    POST_SEARCH_REQUEST,
    POST_SEARCH_REQUEST_FAILED
} from "../../actions/search/search";
import {ACTION_REQUEST} from "../../../utils/constant";

const initialState = {
    searchExecuted: false,
    loadingSearchRequest: false,
    loadingCountRequest: false,
    action: null,
    error: null,
    products: [],
    productsCount: 0,
    assets: [],
    assetsCount: 0,
    filtersTableCount: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_COUNT_REQUEST:
            return {
                ...state,
                loadingCountRequest: true
            }
        case POST_SEARCH_REQUEST:
            return {
                ...state,
                searchExecuted: true,
                loadingSearchRequest: true,
                action: ACTION_REQUEST.post
            };
        case POST_SEARCH_ASSETS_REQUEST_SUCCESS:
            return {
                ...state,
                loadingSearchRequest: false,
                assets: action.assets
            };
        case POST_SEARCH_PRODUCTS_REQUEST_SUCCESS:
            return {
                ...state,
                loadingSearchRequest: false,
                products: action.products
            };
        case POST_SEARCH_COUNT_SUCCESS:
            return {
                ...state,
                loadingCountRequest: false,
                assetsCount: action.assetsCount,
                productsCount: action.productsCount
            };
        case POST_SEARCH_FILTER_TABLE_COUNT_SUCCESS:
            return {
                ...state,
                loadingCountRequest: false,
                filtersTableCount: action.count
            };
        case POST_SEARCH_REQUEST_FAILED:
            return {
                ...state,
                loadingCountRequest: false,
                loadingSearchRequest: false,
                error: action.error
            };
        case CLEAR_FILTER_TABLE_COUNT:
            return {
                ...state,
                filtersTableCount: null
            };
        case CLEAR_SEARCH_FLAG:
            return {
                ...state,
                searchExecuted: false
            };
        case CLEAR_RESPONSE_SEARCH:
            return initialState;
        default:
            return state;
    }
};
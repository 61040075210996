import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_SEASON_REQUEST = 'GET_SEASON_REQUEST';
export const GET_SEASON_SUCCESS = 'GET_SEASON_SUCCESS';
export const GET_SEASON_FAILURE = 'GET_SEASON_FAILURE';
export const PUT_SEASON_REQUEST = 'PUT_SEASON_REQUEST';
export const PUT_SEASON_SUCCESS = 'PUT_SEASON_SUCCESS';
export const PUT_SEASON_FAILURE = 'PUT_SEASON_FAILURE';
export const POST_SEASON_REQUEST = 'POST_SEASON_REQUEST';
export const POST_SEASON_SUCCESS = 'POST_SEASON_SUCCESS';
export const POST_SEASON_FAILURE = 'POST_SEASON_FAILURE';
export const CLEAR_SEASON = 'CLEAR_SEASON';


const localStorageService = LocalStorage.getService();

export const getSeasonRequest = () => {
    return { type: GET_SEASON_REQUEST }
};

export const getSeasonSuccess = (season = {}) => {
    return { type: GET_SEASON_SUCCESS, season }
};

export const getSeasonFailure = (error) => {
    return { type: GET_SEASON_FAILURE, error }
};

export const clearSeasons = () => {
    return { type: CLEAR_SEASON }
}

export const startGetSeasons = () => {
    console.log('Start startGetSeason');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getSeasonRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/seasons?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startGetSeasons: ", response.data);
                    dispatch(getSeasonSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getSeasonFailure(error));
            });
    };
};

export const putSeasonRequest = () => {
    return { type: PUT_SEASON_REQUEST };
};

export const putSeasonSuccess = (season) => {
    return { type: PUT_SEASON_SUCCESS, season: season };
};

export const putSeasonFailure = (error) => {
    return { type: PUT_SEASON_FAILURE, error: error };
};

export const startPutSeason = (season) => {
    console.log('Start startPutSeason');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(putSeasonRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/seasons/update/${season.guid}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                },
                data: JSON.stringify({
                    description: season.name,
                    external_code: season.external_code,
                    active: season.active
                })
            }
        ).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log("startPutSeason: ", response);
                dispatch(putSeasonSuccess(response.data));
            }
        }).catch(error => {
            console.log(error);
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(putSeasonFailure(error));
        });
    };
};


export const postSeasonRequest = () => {
    return { type: POST_SEASON_REQUEST };
};

export const postSeasonSuccess = (season) => {
    return { type: POST_SEASON_SUCCESS, season: season };
};

export const postSeasonFailure = (error) => {
    return { type: POST_SEASON_FAILURE, error: error };
};

export const startPostSeason = (season) => {
    console.log('Start startPostSeason');
    let token = localStorageService.getAccessToken();
    console.log('INFOS: ', season);
    return (dispatch) => {
        dispatch(postSeasonRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/seasons?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                },
                data: JSON.stringify({
                    code: season.code,
                    description: season.name,
                    external_code: season.external_code
                })
            }
        ).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log("Seasons ", response);
                dispatch(postSeasonSuccess(response.data));
            }
        }).catch(error => {
            console.log(error);
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postSeasonFailure(error));
        });
    };
};
import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";
import {ACTION_REQUEST} from "../../../utils/constant";

export const POST_SEND_EMAIL_REQUEST = 'POST_SEND_EMAIL_REQUEST';
export const POST_SEND_EMAIL_SUCCESS = 'POST_SEND_EMAIL_SUCCESS';
export const POST_SEND_EMAIL_FAILURE = 'POST_SEND_EMAIL_FAILURE';

const localStorageService = LocalStorage.getService();

export const sendEmailRequest = () => {
    return { type: POST_SEND_EMAIL_REQUEST }
};

export const sendEmailSuccess = (response) => {
    return { type: POST_SEND_EMAIL_SUCCESS, response }
};

export const sendEmailFailure = (error) => {
    return { type: POST_SEND_EMAIL_FAILURE, error }
};

export const startSendEmail = (body) => {
    console.log('Start sending request email');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(sendEmailRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/postprod_mail?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: ACTION_REQUEST.post,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body),
            }
        ).then((response) => {
            console.log(response);
            if (response.status === 201) {
                dispatch(sendEmailSuccess('Email has been scheduled successfully.'));
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
                dispatch(loggedOut());
            }
            dispatch(sendEmailFailure(error));
        });
    };
};


export const POST_FETCH_ASSETS_REQUEST = 'POST_FETCH_ASSETS_REQUEST';
export const POST_FETCH_ASSETS_SUCCESS = 'POST_FETCH_ASSETS_SUCCESS';
export const POST_FETCH_ASSETS_FAILURE = 'POST_FETCH_ASSETS_FAILURE';
export const POST_FETCH_ASSETS_WRONG_STATUS = 'POST_FETCH_ASSETS_WRONG_STATUS';

export const fetchAssetsRequest = () => {
    return { type: POST_FETCH_ASSETS_REQUEST }
};

export const fetchAssetsSuccess = (response) => {
    return { type: POST_FETCH_ASSETS_SUCCESS, response }
};

export const fetchAssetsFailure = (error) => {
    return { type: POST_FETCH_ASSETS_FAILURE, error }
};

export const fetchAssetsWrongStatusFailure = (error) => {
    return { type: POST_FETCH_ASSETS_WRONG_STATUS, error }
};

export const startFetchPostProductionAssets = (assetsGuidList) => {
    let token = localStorageService.getAccessToken();
    console.log('Calling startFetchPostProductionAssets with assetsGuidList: ', assetsGuidList);
    return (dispatch) => {
        dispatch(fetchAssetsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/postprod_info?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: ACTION_REQUEST.post,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({ assets: assetsGuidList })
            }
        ).then((response) => {
            console.log(response);
            if (response.status === 200 && response.data !== null) {
                dispatch(fetchAssetsSuccess(response.data));
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
                dispatch(loggedOut());
            } else if (error.response.status === 409) {
                dispatch(fetchAssetsWrongStatusFailure(error))
            } else {
                dispatch(fetchAssetsFailure(error));
            }
        });
    };
};


export const CLEAR_POST_PRODUCTION = 'CLEAR_POST_PRODUCTION';

export const clearPostProduction = () => {
    return { type: CLEAR_POST_PRODUCTION }
};
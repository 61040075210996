import axios from "axios";
import {ACTION_REQUEST} from "../../utils/constant";
import {loggedOut} from "./auth";
import LocalStorage from "../localStorage";

export const POST_FETCH_ASSET_IMAGE_HD_REQUEST = 'POST_FETCH_ASSET_IMAGE_HD_REQUEST';
export const POST_FETCH_ASSETS_IMAGE_HD_SUCCESS = 'POST_FETCH_ASSETS_IMAGE_HD_SUCCESS';
export const POST_FETCH_ASSETS_IMAGE_HD_FAILURE = 'POST_FETCH_ASSETS_IMAGE_HD_FAILURE';
export const CLEAR_IMAGE_HD = 'CLEAR_IMAGE_HD';

const localStorageService = LocalStorage.getService();

export const fetchAssetImageHdRequest = () => {
    return { type: POST_FETCH_ASSET_IMAGE_HD_REQUEST };
};

export const fetchAssetImageHdSuccess = (imgUrlHD) => {
    return { type: POST_FETCH_ASSETS_IMAGE_HD_SUCCESS, imgUrlHD };
};

export const fetchAssetImageHdFailure = (error) => {
    return { type: POST_FETCH_ASSETS_IMAGE_HD_FAILURE, error };
};

export const clearImageHD = () => {
    return { type: CLEAR_IMAGE_HD };
};

export const startFetchAssetImageHd = (assetsGuid) => {
    let token = localStorageService.getAccessToken();
    console.log('Calling startFetchAssetImageHd for assetsGuid: ', assetsGuid);
    return (dispatch) => {
        dispatch(fetchAssetImageHdRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/getAssetHDImgUrl?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: ACTION_REQUEST.post,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({ asset: assetsGuid })
            }
        ).then((response) => {
            console.log('Response of startFetchAssetImageHd: ', response);
            if (response.status === 200 && response.data !== null) {
                dispatch(fetchAssetImageHdSuccess(response.data.img_url));
            }
        }).catch((error) => {
            console.log(error);
            dispatch(fetchAssetImageHdFailure(error))
            if (error.response.status === 401) dispatch(loggedOut());
        });
    };
};
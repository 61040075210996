import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import UpperBarComponent from "../components/UpperBarComponent/UpperBarComponent";
import {startLogout} from "../store/actions/auth";
import StyleHOC from "../utils/StyleHOC";

const PrivateRoute = ({
                          isUserAuthenticated, auth,
                          component: Component,
                          path,
                          pathLabel,
                          pathLabel2,
                          pathLabel3,
                          ...rest
                      }) => {
    // console.log('PRIVATE ROUTE!')
    
    return (
        <Route
            {...rest}
            path={path}
            component={props =>
                isUserAuthenticated && auth ? (
                    <>
                        <UpperBarComponent path={path} user={auth} handleLogout={rest.logout} pathLabel={pathLabel}
                                           pathLabel2={pathLabel2} pathLabel3={pathLabel3}/>
                        <StyleHOC {...props}>
                            <Component/>
                        </StyleHOC>
                    </>
                ) : (
                    <Redirect to="/"/>
                )
            }
        />
    );
}

const mapStateToProps = state => ({
    isUserAuthenticated: state.authReducer.isUserAuthenticated,
    auth: state.authReducer.auth
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(startLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PrivateRoute));

import {GET_PIM_TARGET_FAILURE, GET_PIM_TARGET_REQUEST, GET_PIM_TARGET_SUCCESS} from "../actions/pim_target";

const initialState = {pimTarget: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PIM_TARGET_REQUEST:
            return {
                loadingPimTarget: true
            };
        case GET_PIM_TARGET_SUCCESS:
            return {
                ...state,
                loadingPimTarget: false,
                pimTarget: action.pimTarget,
            };
        case GET_PIM_TARGET_FAILURE:
            return {
                ...state,
                loadingPimTarget: false,
                error: action.error
            };
        default:
            return state;
    }
}
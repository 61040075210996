import {
    CLEAR_LOOKS,
    GET_LOOKS_FAILURE,
    GET_LOOKS_REQUEST,
    GET_LOOKS_SUCCESS,
    CLEAR_LOOKS_HD,
    GET_LOOKS_HD_FAILURE,
    GET_LOOKS_HD_REQUEST,
    GET_LOOKS_HD_SUCCESS
} from "../../../actions/showroom/composition/looks";

const initialState = {
    looks: [], 
    loadingLooks: false, 
    errorLooks: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LOOKS_REQUEST:
        case GET_LOOKS_HD_REQUEST:
            return {
                ...state,
                loadingLooks: true
            };
        case GET_LOOKS_SUCCESS:
        case GET_LOOKS_HD_SUCCESS:
            return {
                ...state,
                loadingLooks: false,
                looks: action.looks,
            };
        case GET_LOOKS_FAILURE:
        case GET_LOOKS_HD_FAILURE:
            return {
                ...state,
                loadingLooks: false,
                error: action.error
            };
        case CLEAR_LOOKS:
        case CLEAR_LOOKS_HD:
            return initialState;
        default:
            return state;
    }
}
import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_SERVICE_REQUEST = 'GET_SERVICE_REQUEST';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAILURE = 'GET_SERVICE_FAILURE';

const localStorageService = LocalStorage.getService();

export const getServiceRequest = () => {
    return {type: GET_SERVICE_REQUEST}
};

export const getServiceSuccess = (service_description = {}) => {
    return {type: GET_SERVICE_SUCCESS, service_description}
};

export const getServiceFailure = (error) => {
    return {type: GET_SERVICE_FAILURE, error}
};

export const startGetService = () => {
    console.log('Start startGetService');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getServiceRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/service/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response service search ", response.data);
                    dispatch(getServiceSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getServiceFailure(error));
            });
    };
};
export const GET_SERVICE_BY_RICHIEDENTE_REQUEST = 'GET_SERVICE_BY_RICHIEDENTE_REQUEST';
export const GET_SERVICE_BY_RICHIEDENTE_SUCCESS = 'GET_SERVICE_BY_RICHIEDENTE_SUCCESS';
export const GET_SERVICE_BY_RICHIEDENTE_FAILURE = 'GET_SERVICE_BY_RICHIEDENTE_FAILURE';
export const CLEAR_SERVICES = 'CLEAR_SERVICES';

export const clearServices = () => {
    return {type: CLEAR_SERVICES }
};

export const getServiceByRichiedenteRequest = () => {
    return {type: GET_SERVICE_BY_RICHIEDENTE_REQUEST}
};

export const getServiceByRichiedenteSuccess = (services = {}) => {
    return {type: GET_SERVICE_BY_RICHIEDENTE_SUCCESS, services}
};

export const getServiceByRichiedenteFailure = (errorServices) => {
    return {type: GET_SERVICE_BY_RICHIEDENTE_FAILURE, errorServices}
};

export const startGetServiceByRichiedente = (richiedente) => {
    console.log('Start startGetServiceByRichiedente with richiedente', richiedente);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getServiceByRichiedenteRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/service/${richiedente}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&photolab_request`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response service by richiedente ", response.data);
                    dispatch(getServiceByRichiedenteSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getServiceByRichiedenteFailure(error));
            });
    };
};

export const startGetServiceByRichiedenteFull = (richiedente) => {
    console.log('Start startGetServiceByRichiedenteFull with richiedente', richiedente);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getServiceByRichiedenteRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/service/full/${richiedente}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response service by richiedente ", response.data);
                    dispatch(getServiceByRichiedenteSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getServiceByRichiedenteFailure(error));
            });
    };
};

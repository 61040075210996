import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_FABRIC_REQUEST = 'GET_FABRIC_REQUEST';
export const GET_FABRIC_SUCCESS = 'GET_FABRIC_SUCCESS';
export const GET_FABRIC_FAILURE = 'GET_FABRIC_FAILURE';
export const CLEAR_FABRIC_BY_STYLE = 'CLEAR_FABRIC_BY_STYLE';

const localStorageService = LocalStorage.getService();

export const getFabricRequest = () => {
    return { type: GET_FABRIC_REQUEST }
};

export const getFabricSuccess = (fabric = {}) => {
    return {type: GET_FABRIC_SUCCESS, fabric}
};

export const getFabricFailure = (error) => {
    return {type: GET_FABRIC_FAILURE, error}
};

export const startClearFabricByStyle = () => {
    return {type: CLEAR_FABRIC_BY_STYLE}
};

export const startGetFabricByStyle = (style) => {
    console.log('Start startGetFabricByStyle: ', style);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getFabricRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/fabric/${style}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response fabric ", response.data);
                    dispatch(getFabricSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getFabricFailure(error));
            });
    };
};

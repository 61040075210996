import axios from "axios";
import {loggedOut} from "./auth";
import LocalStorage from "../localStorage";

const localStorageService = LocalStorage.getService();

export const GET_DOCS_PER_ROLE_REQUEST = 'GET_DOCS_PER_ROLE_REQUEST';
export const GET_DOCS_PER_ROLE_SUCCESS = 'GET_DOCS_PER_ROLE_SUCCESS';
export const GET_DOCS_PER_ROLE_FAILURE = 'GET_DOCS_PER_ROLE_FAILURE';
export const CLEAR_DOCS_PER_ROLE = 'CLEAR_DOCS_PER_ROLE';

export const getDocsPerRoleRequest = () => {
    return { type: GET_DOCS_PER_ROLE_REQUEST }
};

export const getDocsPerRoleSuccess = (availableDocs) => {
    return { type: GET_DOCS_PER_ROLE_SUCCESS, availableDocs }
};

export const getDocsPerRoleFailure = (error) => {
    return { type: GET_DOCS_PER_ROLE_FAILURE, error }
};

export const clearDocsPerRole = () => {
    return { type: CLEAR_DOCS_PER_ROLE }
};

export const startGetDocsPerRole = () => {
    console.log('Start startGetDocsPerRole');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getDocsPerRoleRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/roleDocs?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get docs per role url ", response.data);
                    dispatch(getDocsPerRoleSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getDocsPerRoleFailure(error));
            });
    };
};

export const GET_DOCS_REQUEST = 'GET_DOCS_REQUEST';
export const GET_DOCS_SUCCESS = 'GET_DOCS_SUCCESS';
export const GET_DOCS_FAILURE = 'GET_DOCS_FAILURE';
export const CLEAR_DOCS = 'CLEAR_DOCS';

export const getDocsRequest = () => {
    return { type: GET_DOCS_REQUEST }
};

export const getDocsSuccess = (downloadableDocs) => {
    return { type: GET_DOCS_SUCCESS, downloadableDocs }
};

export const getDocsFailure = (error) => {
    return { type: GET_DOCS_FAILURE, error }
};

export const clearDocs = () => {
    return { type: CLEAR_DOCS }
};

export const startGetDocs = () => {
    console.log('Start startGetDocs');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getDocsRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/globalDoc?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get global doc url ", response.data);
                    dispatch(getDocsSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getDocsFailure(error));
            });
    };
};

export const GET_PRODUCTS_REPORTS_REQUEST = 'GET_PRODUCTS_REPORTS_REQUEST';
export const GET_PRODUCTS_REPORTS_SUCCESS = 'GET_PRODUCTS_REPORTS_SUCCESS';
export const GET_PRODUCTS_REPORTS_FAILURE = 'GET_PRODUCTS_REPORTS_FAILURE';
export const CLEAR_PRODUCTS_REPORTS = 'CLEAR_PRODUCTS_REPORTS';

export const getProductsReportsRequest = () => {
    return { type: GET_PRODUCTS_REPORTS_REQUEST }
};

export const getProductsReportsSuccess = (downloadableDocs) => {
    return { type: GET_PRODUCTS_REPORTS_SUCCESS, downloadableDocs }
};

export const getProductsReportsFailure = (error) => {
    return { type: GET_PRODUCTS_REPORTS_FAILURE, error }
};

export const clearProductsReports = () => {
    return { type: CLEAR_PRODUCTS_REPORTS }
};

export const startGetProductsReports = () => {
    console.log('Start startGetProductsReports');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getDocsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/productsReportsDoc?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get global doc url ", response.data);
                    dispatch(getDocsSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getDocsFailure(error));
            });
    };
};

export const GET_ASSETS_REPORTS_REQUEST = 'GET_ASSETS_REPORTS_REQUEST';
export const GET_ASSETS_REPORTS_SUCCESS = 'GET_ASSETS_REPORTS_SUCCESS';
export const GET_ASSETS_REPORTS_FAILURE = 'GET_ASSETS_REPORTS_FAILURE';
export const CLEAR_ASSETS_REPORTS = 'CLEAR_ASSETS_REPORTS';

export const getAssetsReportsRequest = () => {
    return { type: GET_ASSETS_REPORTS_REQUEST }
};

export const getAssetsReportsSuccess = (downloadableDocs) => {
    return { type: GET_ASSETS_REPORTS_SUCCESS, downloadableDocs }
};

export const getAssetsReportsFailure = (error) => {
    return { type: GET_ASSETS_REPORTS_FAILURE, error }
};

export const clearAssetsReports = () => {
    return { type: CLEAR_ASSETS_REPORTS }
};

export const startGetAssetsReports = () => {
    console.log('Start startGetAssetsReports');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getDocsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/assetsReportsDoc?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get global doc url ", response.data);
                    dispatch(getDocsSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getDocsFailure(error));
            });
    };
};

export const GET_VIDEOS_REQUEST = 'GET_VIDEOS_REQUEST';
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';
export const GET_VIDEOS_FAILURE = 'GET_VIDEOS_FAILURE';
export const CLEAR_VIDEOS = 'CLEAR_VIDEOS';

export const getVideosRequest = () => {
    return { type: GET_VIDEOS_REQUEST }
};

export const getVideosSuccess = (downloadableDocs) => {
    return { type: GET_VIDEOS_SUCCESS, downloadableDocs }
};

export const getVideosFailure = (error) => {
    return { type: GET_VIDEOS_FAILURE, error }
};

export const clearVideos = () => {
    return { type: CLEAR_VIDEOS }
};

export const startGetVideos = () => {
    console.log('Start startGetVideos');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getDocsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/videos?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get global doc url ", response.data);
                    dispatch(getDocsSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getDocsFailure(error));
            });
    };
};

import {
    CLEAR_SEARCH_INFO,
    GET_SEARCH_VIEW_MONITOR_FAILURE,
    GET_SEARCH_VIEW_MONITOR_REQUEST,
    GET_SEARCH_VIEW_MONITOR_SUCCESS,
    POST_GET_IMAGE_FAILURE,
    POST_GET_IMAGE_REQUEST,
    POST_GET_IMAGE_SUCCESS
} from "../../actions/search/dialogInfo";
import {SEARCH_ACTION_REQUEST} from "../../../utils/constant";

const initialState = { loading: false, action: null, vmInfo: {}, comparingInfo: {}, error: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SEARCH_VIEW_MONITOR_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.getVM
            };
        case POST_GET_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.getCompareInfo
            };
        case GET_SEARCH_VIEW_MONITOR_SUCCESS:
            return {
                ...state,
                loading: false,
                vmInfo: action.vmInfo
            };
        case POST_GET_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                comparingInfo: action.comparingInfo
            };
        case GET_SEARCH_VIEW_MONITOR_FAILURE:
        case POST_GET_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_SEARCH_INFO:
            return initialState;
        default:
            return state;
    }
}
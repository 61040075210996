import {GET_VIEW_FAILURE, GET_VIEW_REQUEST, GET_VIEW_SUCCESS} from "../actions/view";

const initialState = {view: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VIEW_REQUEST:
            return {
                loadingView: true
            };
        case GET_VIEW_SUCCESS:
            return {
                ...state,
                loadingView: false,
                view: action.view,
            };
        case GET_VIEW_FAILURE:
            return {
                ...state,
                loadingView: false,
                error: action.error
            }
        default:
            return state;
    }
}
import React from "react";
import {useIntl} from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import {useStyles} from "./IconButtonElementStyle";


const IconButtonElement = props => {

    const intl = useIntl();
    const classes = useStyles();
    const { icon, onClick, disabled, labelId, label, type } = props;
    let buttonClass = classes.button;

    if (type === "delete") buttonClass = classes.delete;
    else if (type === "approve") buttonClass = classes.approve;

    if (labelId && label) {

        return (

            <Tooltip placement="top" title={intl.formatMessage({ 'id': labelId, 'defaultMessage': label })}>
            <span>
                <IconButton
                    className={classes.root}
                    size="small"
                    onClick={onClick}
                    disabled={disabled}>
                    <Icon className={`${buttonClass}`}>{icon}</Icon>
                </IconButton>
            </span>
            </Tooltip>

        )
    } else {
        return (
            <IconButton
                className={classes.root}
                size="small"
                onClick={onClick}
                disabled={disabled}>
                <Icon className={`${buttonClass}`}>{icon}</Icon>
            </IconButton>
        )
    }
}

export default IconButtonElement;
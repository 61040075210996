import {
    GET_PIM_STATUS_FAILURE,
    GET_PIM_STATUS_REQUEST,
    GET_PIM_STATUS_SUCCESS
} from "../../actions/search/pim_status";

const initialState = {pim_status: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PIM_STATUS_REQUEST:
            return {
                loadingPimStatus: true
            };
        case GET_PIM_STATUS_SUCCESS:
            return {
                ...state,
                loadingPimStatus: false,
                pim_status: action.pim_status,
            };
        case GET_PIM_STATUS_FAILURE:
            return {
                ...state,
                loadingPimStatus: false,
                error: action.error
            }
        default:
            return state;
    }
}
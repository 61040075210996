import React from 'react';
import {useIntl} from "react-intl";
import {useStyles} from "./PaperHomeElementStyle";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Icon from '@material-ui/core/Icon';
import Typography from "@material-ui/core/Typography";



export const PaperHomeElement = (props) => {

    const classes = useStyles();
    const intl = useIntl();
    //console.log("icon", props.icon);

    return (
        <Paper className={classes.paper}>
            <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="center"
                className={classes.gridPaper}
            >
                <Grid item xs>
                    <Icon fontSize='large'>{props.icon}</Icon>
                </Grid>
                <Grid item xs>
            <Typography className={classes.button} variant="button" align="center">
                {intl.formatMessage({
                    'id': props.translateKey,
                    'defaultMessage': props.defaultText
                })}</Typography>
                </Grid>
            </Grid>
        </Paper>

    );
};
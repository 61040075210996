import {createMuiTheme} from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        // type: 'dark',
        primary: { main: "#181818", contrastText: "#dfdbd9", text: "#dfdbd9" }, // tbc
        secondary: { main: "#262626" }, // tbc
        background: {
            main: "#000000", // tm
            paper: "#101010",
            default: "#262626",
            hover: "#344755",
        },
        text: {
            main: "#dfdbd9", // tm
            primary: "#dfdbd9",
            secondary: "#aaaaaa",
            disabled: "#5e5c5b",
            placeholder: "#5e5c5b", // tm
        },
        action: {
            active: "#dfdbd9",
            hover: "#344755", // ok, tc
            selected: "#515151", // tbc
            disabled: "#6f6f6f",
            disabledBackground: "#494949",
        },
        icon: { main: "#c9750f" },
        button: { main: "#3A4E5F", edit: "#1b5e20", deactive: "#641519" },
        error: { main: "#5B3235" },
        success: { main: "#1C920E" },
        focus: { main: "#51cbee" },
        headerTable: { main: "#101010" },
        hoverTable: { main: "#4f4f52" },
        shoot: { main: "Snow" },
        toBePostProducted: { main: "yellow" },
        newPostProducted: { main: "#79a6d2" },
        postProductedReject: { main: "#d2797a" },
        postProductedApprove: { main: "green" },
        isDraft: { main: "yellow" },
        new: { main: "#3bd119" },
        deleted: { main: "#ff0000" },
        warning: { main: "#f2ee0a" },
        complete: { main: "#181818" },
        notValid: { main: "#ff0000" },
        wrongContext: { main: "#c9750f"},
        placeholder: { main: "#f2ee0a"},
        placeholderFromBarcode: { main: "#51cbee"}
    },
    overrides: {
        MuiButton: {
            textPrimary: {
                color: "#aaaaaa"
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "0.9em"
            }
        }
    }
});

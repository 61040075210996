import {
    CLEAR_ERRORS,
    CLEAR_PRODUCT_INFO,
    CREATE_INSERT_PRODUCT_INFO_FAILURE,
    CREATE_INSERT_PRODUCT_INFO_REQUEST,
    CREATE_INSERT_PRODUCT_INFO_SUCCESS,
    INSERT_PRODUCT_INFO_BY_XSLX_FAILURE,
    INSERT_PRODUCT_INFO_BY_XSLX_SUCCESS,
    SET_DISTRIBUTION_DATE_FAILURE,
    SET_DISTRIBUTION_DATE_REQUEST,
    SET_DISTRIBUTION_DATE_SUCCESS
} from "../../actions/masterdata/masterdata";

const initialState = {filedata: [], productInfo: [], loadingProductInsert: false, loadingSetDate: false};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_INSERT_PRODUCT_INFO_REQUEST:
            return {
                loadingProductInsert: true
            };
        case SET_DISTRIBUTION_DATE_REQUEST:
            return {
                loadingSetDate: true
            };
        case SET_DISTRIBUTION_DATE_SUCCESS:
            return {
                loadingSetDate: false,
                response: action.response && action.response.results
            };
        case SET_DISTRIBUTION_DATE_FAILURE:
            return {
                loadingSetDate: false,
                errorDate: action.errorDate
            }
        case CREATE_INSERT_PRODUCT_INFO_SUCCESS:
            return {
                ...state,
                filedata: action.filedata,
            };
        case CREATE_INSERT_PRODUCT_INFO_FAILURE:
            return {
                ...state,
                loadingProductInsert: false,
                fileError: action.error
            }
        case INSERT_PRODUCT_INFO_BY_XSLX_SUCCESS:
            return {
                ...state,
                loadingProductInsert: false,
                productInfo: action.productInfo.Results,
            };
        case INSERT_PRODUCT_INFO_BY_XSLX_FAILURE:
            return {
                ...state,
                loadingProductInsert: false,
                error: action.error
            }
        case CLEAR_PRODUCT_INFO:
            return {
                ...state,
                productInfo: []
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: undefined,
                fileError: undefined,
                errorDate: undefined
            }
        default:
            return state;
    }
}
import { CircularProgress } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import React from "react";
import { useStyles } from "./BackdropElementStyle";
import Typography from "@material-ui/core/Typography";

const BackdropElement = (props) => {
	const { loading, label } = props;
	const classes = useStyles();

	return (
		<Backdrop open={loading || false} className={classes.backdrop}>
			<div className={classes.progress}>
				<CircularProgress color="inherit" />
				{label ? <Typography>{label}</Typography> : ""}
			</div>
		</Backdrop>
	);
};

export default BackdropElement;

import {
    CLEAR_POST_PRODUCTION,
    POST_FETCH_ASSETS_FAILURE,
    POST_FETCH_ASSETS_REQUEST,
    POST_FETCH_ASSETS_SUCCESS,
    POST_FETCH_ASSETS_WRONG_STATUS,
    POST_SEND_EMAIL_FAILURE,
    POST_SEND_EMAIL_REQUEST,
    POST_SEND_EMAIL_SUCCESS
} from "../../actions/postProduction/postProduction";
import {POST_PRODUCTION_ACTIONS_REQUEST} from "../../../utils/constant";

const initialState = { loading: false, action: null, error: null, email: {}, assetsData: {}, wrongAssetsStatus: false };

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_FETCH_ASSETS_REQUEST:
            return {
                ...state,
                loading: true,
                action: POST_PRODUCTION_ACTIONS_REQUEST.getAssets
            };
        case POST_SEND_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                action: POST_PRODUCTION_ACTIONS_REQUEST.sendEmail
            };
        case POST_FETCH_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false,
                assetsData: {
                    assets: action.response.assets,
                    discarded: action.response.discarded
                }
            };
        case POST_SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                email: {
                    sent: true,
                    responseText: action.response
                }
            };
        case POST_FETCH_ASSETS_FAILURE:
        case POST_SEND_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case POST_FETCH_ASSETS_WRONG_STATUS:
            return {
                ...state,
                loading: false,
                wrongAssetsStatus: true,
                error: action.error
            };
        case CLEAR_POST_PRODUCTION:
            return initialState;
        default:
            return state;
    }
}
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import {useStyles} from "./ModalFullScreenStyle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useIntl} from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalFullScreen = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const { openDialog, dialogTitle, closeHandler, saveHandler, footerActions } = props;
    let titleText;

    if (dialogTitle && dialogTitle.translateKey) {
        titleText = intl.formatMessage({ id: dialogTitle.translateKey, defaultMessage: dialogTitle.defaultText })
    } else if (dialogTitle) {
        titleText = dialogTitle;
    } else {
        titleText = null;
    }

    const actions = footerActions ? footerActions.map(action => action) : null;

    return (
        <Dialog
            fullScreen
            open={openDialog}
            onClose={closeHandler}
            TransitionComponent={Transition}
            className={classes.dialog}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={closeHandler} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {titleText}
                    </Typography>
                    {saveHandler &&
                    <Button autoFocus color="inherit" onClick={saveHandler}>
                        SAVE
                    </Button>
                    }
                </Toolbar>
            </AppBar>
            <DialogContent>
                {props.children}
            </DialogContent>
            {footerActions &&
            <DialogActions>
                <Toolbar>
                    {actions}
                </Toolbar>
            </DialogActions>}
        </Dialog>
    );
};

export default ModalFullScreen;
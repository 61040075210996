import {
    CLEAR_USERS,
    GET_ROLES_SUCCESS,
    GET_USER_INFO_FAILURE,
    GET_USER_INFO_REQUEST,
    GET_USER_INFO_SUCCESS,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    RESET_SNACKBAR,
    UPDATE_USERS,
} from '../actions/user';

const initialState = {
    user: [],
    users: [],
    roles: [],
    updateUsers: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                loadingUserInfo: false,
                userInfo: action.user
            };
        case GET_USER_INFO_REQUEST:
            return {
                ...state,
                loadingUserInfo: true
            };
        case GET_USER_INFO_FAILURE:
            return {
                ...state,
                loadingUserInfo: false,
                loadingUsers: false,
                error: action.error
            };
        case GET_USERS_REQUEST: 
            return {
                ...state,
                loadingUsers: true
            }
        case GET_USERS_SUCCESS:
            return {
                ...state,
                loadingUsers: false,
                updateUsers: null,
                error: null,
                users: action.users
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                loadingUserInfo: false,
                roles: action.roles
            };
        case UPDATE_USERS:
            return {
                ...state,
                updateUsers: action.data
            }
        case RESET_SNACKBAR:
            return {
                ...state,
                updateUsers: null,
                error: null
            }
        case CLEAR_USERS:
            return {
                user: [],
                users: [],
                roles: [],
                updateUsers: null
            };
        default:
            return state;
    }
}
import React from "react";
import {Typography} from "@material-ui/core";

export const video_columns = [
    {
        title: { id: "product.asset.video_name", default: "Video Name" },
        field: 'name',
        render: rowData => <Typography>{rowData.name}</Typography>
    },    
    {
        title: { id: "doc.link", default: "Link" },
        field: 'url'
    }
];
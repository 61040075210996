import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    rootDialog: {
        '& .MuiDialog-paperWidthSm': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.main,
            width: '700px',
            maxWidth: '100%'
        }
    },
    bigRootDialog: {
        '& .MuiDialog-paperWidthSm': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.main,
            width: '1000px',
            maxWidth: '100%'
        }
    },
    rootDialogContent: {
        padding: theme.spacing(0, 3, 3.6, 3),
        flexGrow: 1
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: '1.1rem !important',
        marginLeft: '.5rem'
    }
}));
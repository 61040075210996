import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";


export const CLEAR_OUTPUT_TYPES = 'CLEAR_OUTPUT_TYPES';
export const GET_OUTPUT_TYPES_REQUEST = 'GET_OUTPUT_TYPES_REQUEST';
export const GET_OUTPUT_TYPES_SUCCESS = 'GET_OUTPUT_TYPES_SUCCESS';
export const GET_OUTPUT_TYPES_FAILURE = 'GET_OUTPUT_TYPES_FAILURE';

const localStorageService = LocalStorage.getService();

export const clearOutputTypes = () => {
    return { type: CLEAR_OUTPUT_TYPES }
};

export const getOutputTypesRequest = () => {
    return { type: GET_OUTPUT_TYPES_REQUEST }
};

export const getOutputTypesSuccess = (output_types = []) => {
    return { type: GET_OUTPUT_TYPES_SUCCESS, output_types }
};

export const getOutputTypesFailure = (error) => {
    return { type: GET_OUTPUT_TYPES_FAILURE, error }
};

export const startGetOutputTypes = () => {
    console.log('Start startGetOutputTypes');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getOutputTypesRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/output_types?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response outputTypes: ", response.data);
                    dispatch(getOutputTypesSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getOutputTypesFailure(error));
            });
    };
};

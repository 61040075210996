import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.main,
        backgroundColor: theme.palette.primary.main,
        minHeight: "50vh",
        position: "relative",
    },
    bottomActions: {
        // position: "absolute",
        // bottom: theme.spacing(2),
        // right: theme.spacing(2),
        // left: theme.spacing(2)
        marginTop: theme.spacing(2)
    },
    divSpace: {
        minHeight: "40px"
    },
    text: {
        textAlign: "left",
        fontWeight: "700"
    },
    boxTitle: {
        marginTop: theme.spacing(2)
    }
}));
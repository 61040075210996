import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const GET_POST_PRODUCERS_REQUEST = 'GET_POST_PRODUCERS_REQUEST';
export const GET_POST_PRODUCERS_SUCCESS = 'GET_POST_PRODUCERS_SUCCESS';
export const GET_POST_PRODUCERS_FAILURE = 'GET_POST_PRODUCERS_FAILURE';

const localStorageService = LocalStorage.getService();

export const postProducersRequest = () => {
    return {type: GET_POST_PRODUCERS_REQUEST}
};

export const postProducersSuccess = (data) => {
    return {type: GET_POST_PRODUCERS_SUCCESS, data}
};

export const postProducersFailure = (error) => {
    return {type: GET_POST_PRODUCERS_FAILURE, error}
};

export const startGetPostProducers = () => {
    console.log('Start getPostProducers');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(postProducersRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/user/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&role=postproducer`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        ).then((response) => {
            console.log(response);
            if (response.status === 200 && response.data !== null) {
                dispatch(postProducersSuccess(response.data));
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
                dispatch(loggedOut());
            }
            dispatch(postProducersFailure(error));
        });
    };
};
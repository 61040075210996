import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    imgContainer: {
        display: 'flex',
        margin: '0 37px'
    },
    item: {
        margin: theme.spacing(2),
        position: "relative",
        textAlign: "center"
    },
    deleteIcon: {
        '& .MuiButtonBase-root': {
            position: 'absolute',
            zIndex: 2,
            top: 0,
            left: 0,
            marginLeft: '2px',
            marginTop: '5px',
            color: theme.palette.button.deactive
        }
    },
    selectCheckbox: {
        '& .MuiButtonBase-root': {
            position: 'absolute',
            zIndex: 2,
            top: 0,
            right: 0
        }
    },
    bookmarkIcon: {
        '& .MuiSvgIcon-root': {
            position: 'absolute',
            zIndex: 2,
            bottom: 0,
            right: 0,
            color: theme.palette.button.edit,
            marginRight: '6px',
            marginBottom: '6px'
        }
    },
    bookmarkIconWithLabel: {
        '& .MuiSvgIcon-root': {
            position: 'absolute',
            zIndex: 2,
            bottom: 0,
            right: 0,
            color: theme.palette.button.edit,
            marginRight: '6px',
            marginBottom: '31px'
        }
    },
    draftImgStyle: {
        border: '3px solid '.concat(theme.palette.isDraft.main),
        position: "relative"
    },
    approvedLookImageStyle: {
        border: '3px solid '.concat(theme.palette.button.edit),
        position: "relative"
    },
    movieFrameContainer: {
        display: 'flex',
        width: '100%'
    },
    movieIcon: {
        position: 'absolute',
        bottom: 0,
        marginLeft: '5px',
        marginBottom: '6px',
        color: theme.palette.button.deactive
    },
    movieIconWithLabel: {
        position: 'absolute',
        bottom: 0,
        marginLeft: '5px',
        marginBottom: '31px',
        color: theme.palette.button.deactive
    }
}));
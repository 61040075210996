import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "sticky",
		"& .MuiToolbar-root": {
			backgroundColor: theme.palette.background.main,
			color: theme.palette.text.main,
		},
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	dialog: {
		"& .MuiPaper-root": {
			color: theme.palette.text.main,
			backgroundColor: theme.palette.primary.main,
		},
		"& .MuiDialogActions-root": {
			display: 'flex',
			padding: '0px',
			alignItems: 'inherit',
			justifyContent: 'inherit',
			"& .MuiToolbar-root": {
				backgroundColor: theme.palette.background.main,
				color: theme.palette.text.main,
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
				width: '100%'
			},
		}
	}
}));

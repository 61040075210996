import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";
import {SEARCH_APPLICATION} from "../../utils/constant";

export const GET_FAVOURITES_REQUEST = 'GET_FAVOURITES_REQUEST';
export const GET_FAVOURITES_SUCCESS = 'GET_FAVOURITES_SUCCESS';
export const GET_FAVOURITES_FAILURE = 'GET_FAVOURITES_FAILURE';
export const GET_FAVOURITE_BY_GUID_REQUEST = "GET_FAVOURITE_BY_GUID_REQUEST";
export const GET_FAVOURITE_BY_GUID_SUCCESS = "GET_FAVOURITE_BY_GUID_SUCCESS";
export const GET_FAVOURITE_BY_GUID_FAILURE = "GET_FAVOURITE_BY_GUID_FAILURE";
export const FAVOURITE_SETTINGS_REQUEST = "FAVOURITE_SETTINGS_REQUEST";
export const GET_FAVOURITE_SETTINGS_SUCCESS = "GET_FAVOURITE_SETTINGS_SUCCESS";
export const FAVOURITE_SETTINGS_FAILURE = "FAVOURITE_SETTINGS_FAILURE";
export const CREATE_FAVOURITE_REQUEST = "CREATE_FAVOURITE_REQUEST";
export const CREATE_FAVOURITE_SUCCESS = "CREATE_FAVOURITE_SUCCESS";
export const UPDATE_FAVOURITE_SUCCESS = "UPDATE_FAVOURITE_SUCCESS";
export const CREATE_FAVOURITE_FAILURE = "CREATE_FAVOURITE_FAILURE";
export const CLEAR_FAVOURITE_DETAILS = "CLEAR_FAVOURITE_DETAILS";
export const DELETE_FAVOURITE_REQUEST = "DELETE_FAVOURITE_REQUEST";
export const DELETE_FAVOURITE_SUCCESS = "DELETE_FAVOURITE_SUCCESS";
export const DELETE_FAVOURITE_FAILURE = "DELETE_FAVOURITE_FAILURE";
export const UPDATE_FAVOURITE_SETTINGS_REQUEST = "UPDATE_FAVOURITE_SETTINGS_REQUEST";
export const UPDATE_FAVOURITE_SETTINGS_SUCCESS = "UPDATE_FAVOURITE_SETTINGS_SUCCESS";

const localStorageService = LocalStorage.getService();

export const getFavouritesRequest = () => {
    return { type: GET_FAVOURITES_REQUEST }
};

export const getFavouritesSuccess = (favourites) => {
    return { type: GET_FAVOURITES_SUCCESS, favourites }
};

export const getFavouritesFailure = (error) => {
    return { type: GET_FAVOURITES_FAILURE, error }
};

export const startGetFavourites = (application) => {
    console.log('Start startGetFavourites ', application);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getFavouritesRequest());
        const url = application === SEARCH_APPLICATION.productShowroom ?
            `${process.env.REACT_APP_API_ROOTURL}/favourite/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&source=pg` :
            `${process.env.REACT_APP_API_ROOTURL}/favourite/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&source=search`
        return axios({
                url: url,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get favourites ", response.data);
                    dispatch(getFavouritesSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getFavouritesFailure(error));
            });
    };
};

export const getFavouriteByGuidRequest = () => {
    return { type: GET_FAVOURITE_BY_GUID_REQUEST }
};

export const getFavouriteByGuidSuccess = (favDetails = {}) => {
    return { type: GET_FAVOURITE_BY_GUID_SUCCESS, favDetails }
};

export const getFavouriteByGuidFailure = (fav_error) => {
    return { type: GET_FAVOURITE_BY_GUID_FAILURE, fav_error }
};

export const clearFavouriteDetails = () => {
    return { type: CLEAR_FAVOURITE_DETAILS }
}

export const startGetFavouriteByGuid = (guid) => {
    console.log('Start startGetFavouriteByGuid with guid: ', guid);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getFavouriteByGuidRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/favourite?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&guid=${guid}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get favourite by guid ", response.data);
                    dispatch(getFavouriteByGuidSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getFavouriteByGuidFailure(error));
            });
    };
};

export const createFavouriteRequest = () => {
    return { type: CREATE_FAVOURITE_REQUEST }
};

export const createFavouriteSuccess = (post_favourite = {}) => {
    return { type: CREATE_FAVOURITE_SUCCESS, post_favourite }
};

export const createFavouriteFailure = (post_error) => {
    return { type: CREATE_FAVOURITE_FAILURE, post_error }
};

export const startCreateFavourite = (favName, payload, application) => {
    console.log('Start startCreateFavourite');
    let token = localStorageService.getAccessToken();
    let bodyData = {
        source: application === SEARCH_APPLICATION.productShowroom ? "pg" : "search",
        name: favName,
        payload: payload,
        is_user: 1
    }
    return (dispatch) => {
        dispatch(createFavouriteRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/favourite?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(bodyData),
            }
        )
            .then(function (response) {
                console.log("Response create favourite ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get favourites ", response.data);
                    dispatch(createFavouriteSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(createFavouriteFailure(error));
            });
    };
};

export const deleteFavouriteRequest = () => {
    return { type: DELETE_FAVOURITE_REQUEST }
};

export const deleteFavouriteSuccess = (delete_favourite = {}) => {
    return { type: DELETE_FAVOURITE_SUCCESS, delete_favourite }
};

export const deleteFavouriteFailure = (delete_error) => {
    return { type: DELETE_FAVOURITE_FAILURE, delete_error }
};

export const startDeleteFavourite = (guid) => {
    console.log('Start startDeleteFavourite with guid: ', guid);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(deleteFavouriteRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/favourite?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&guid=${guid}`,
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response delete favourite ", response.data);
                    dispatch(deleteFavouriteSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(deleteFavouriteFailure(error));
            });
    };
};

export const updateFavouriteSuccess = (update_favourite) => {
    return { type: UPDATE_FAVOURITE_SUCCESS, update_favourite }
};

export const startUpdateFavourite = (guid, payload) => {
    console.log('Start startUpdateFavourite with ', guid, payload);
    let token = localStorageService.getAccessToken();
    let bodyData = {
        guid: guid,
        payload: payload,
        is_user: 1
    }
    return (dispatch) => {
        dispatch(createFavouriteRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/favourite?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(bodyData),
            }
        )
            .then(function (response) {
                console.log("Response create favourite ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get favourites ", response.data);
                    dispatch(updateFavouriteSuccess(guid));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(createFavouriteFailure(error));
            });
    };
};

export const startRenameFavourite = (favName, guid) => {
    console.log('Start startRenameFavourite with ', favName, guid);
    let token = localStorageService.getAccessToken();
    let bodyData = {
        guid: guid,
        name: favName,
        is_user: 1
    }
    return (dispatch) => {
        dispatch(createFavouriteRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/favourite?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(bodyData),
            }
        )
            .then(function (response) {
                console.log("Response rename favourite ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("Response rename favourite ", response.data);
                    dispatch(updateFavouriteSuccess(guid));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(createFavouriteFailure(error));
            });
    };
};


/* ****************************** SETTINGS API CALLS ************************************* */
export const favouriteSettingsRequest = () => {
    return { type: FAVOURITE_SETTINGS_REQUEST }
};

export const getFavouriteSettingsSuccess = (settings = {}) => {
    return { type: GET_FAVOURITE_SETTINGS_SUCCESS, settings }
};

export const favouriteSettingsFailure = (settings_error) => {
    return { type: FAVOURITE_SETTINGS_FAILURE, settings_error }
};

export const startGetFavouriteSettings = () => {
    console.log('Start startGetFavouriteSettings ');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(favouriteSettingsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/favourite?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&type=settings`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response get favourite settings ", response.data);
                    dispatch(getFavouriteSettingsSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(favouriteSettingsFailure(error));
            });
    };
};

export const updateFavouriteSettingsSuccess = (response = {}) => {
    return { type: UPDATE_FAVOURITE_SETTINGS_SUCCESS, response }
};

export const startUpdateFavouriteSettings = (settings) => {
    console.log('Start startUpdateFavouriteSettings with ', settings);
    let token = localStorageService.getAccessToken();
    let bodyData = {
        type: "settings",
        payload: settings
    }
    return (dispatch) => {
        dispatch(favouriteSettingsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/favourite?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(bodyData)
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response update favourite settings ", response.data);
                    dispatch(updateFavouriteSettingsSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(favouriteSettingsFailure(error));
            });
    };
};
import {makeStyles} from "@material-ui/core/styles";

// TODO: manca il cambio colore sull'hover della riga
export const useStyles = makeStyles((theme) => ({
    cellTitle: {
        color: theme.palette.text.main,        
        borderBottom: '2px solid '.concat(theme.palette.background.main),
        width: '20%'
    },
    cell: {
        borderBottom: '2px solid '.concat(theme.palette.background.main),
        color: theme.palette.text.main,
        '&:first-of-type':{
            width: '20%'
        },
        '&:last-of-type': {
            width: '5%'
        }
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.secondary.main
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.primary.main
        }
    },
    hover: {
        "&$hover:hover": {
            backgroundColor: theme.palette.hoverTable.main
        }
    }
}));
import {
    CLEAR_DELETE_ASSETS,
    POST_DELETE_ASSETS_FAILURE,
    POST_DELETE_ASSETS_REQUEST,
    POST_DELETE_ASSETS_SUCCESS
} from "../../actions/search/assets";

const initialState = { actionType: null, loading: null, error: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_DELETE_ASSETS_REQUEST:
            return {
                loading: true,
                actionType: 'POST'
            };
        case POST_DELETE_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_DELETE_ASSETS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case CLEAR_DELETE_ASSETS:
            return initialState;
        default:
            return state;
    }
};
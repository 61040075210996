import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_AUDITS_REQUEST = 'GET_AUDITS_REQUEST';
export const GET_AUDITS_SUCCESS = 'GET_AUDITS_SUCCESS';
export const GET_AUDITS_FAILURE = 'GET_AUDITS_FAILURE';

const localStorageService = LocalStorage.getService();

export const getAuditsRequest = () => {
    return {type: GET_AUDITS_REQUEST}
};

export const getAuditsFailure = (error) => {
    return {type: GET_AUDITS_FAILURE, error}
};

export function getAuditsSuccess(audits = []) {
    return {type: GET_AUDITS_SUCCESS, audits};
}

export const startGetAudits = () => {
    console.log('Start startGetAudits');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getAuditsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/audit?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Audits ", response);
                    dispatch(getAuditsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getAuditsFailure(error));
            });
    };
};

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {app_logs_columns} from "../../assets/resources/admin_app_logs_columns";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import MaterialSimpleTable from "../../components/TableElement/MaterialSimpleTable/MaterialSimpleTable";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import {validateColumnsMaterialSimpleTable} from "../../utils/CommonFunction";

const AdminAppLogsContainer = (props) => {
    // const classes = useStyles();
    const intl = useIntl();

    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState({
        open: false,
        title: intl.formatMessage({
            id: "admin.app_logs.modal.title",
            defaultMessage: "Application Logs Details",
        }),
        modalRows: {}
    });
    useEffect(() => {
        // setup the columns
        const columns = validateColumnsMaterialSimpleTable(intl, app_logs_columns);
        setColumns(columns);
    }, []);

    useEffect(() => {
        console.log("useEffect errors", props.app_logs);
        if (props.app_logs !== undefined) {
            // if logs is updated, update data, accordingly
            setTableData(props.app_logs);
        }
    }, [props.app_logs]);

    const onClickRow = (event, row) => {
        console.log("ROW CLICKED", row);
        const newState = { ...showDetailModal };
        newState.open = true;
        newState.modalRows = row;
        setShowDetailModal(newState);
    };


    return (
        <div>
            <BackdropElement loading={props.loadingAppLogs || false}/>
            <MaterialSimpleTable
                data={tableData}
                columns={columns}
                onRowClick={onClickRow}
                pageSize={10}
            />
            {/* view detail modal */}
            <ReusableModal
                reusableModal={showDetailModal}
                closeReusableModal={() => setShowDetailModal({ ...showDetailModal, open: false })}>
                <Grid container
                      direction="row" spacing={1}>
                    {
                        columns.map(column =>
                            <Grid container item key={column.title} justify={'center'} xs={12} spacing={2}>
                                <Grid item xs={3}>
                                    {column.title}
                                </Grid>
                                <Grid item xs={5}>
                                    {showDetailModal.modalRows[column.field]}
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </ReusableModal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    app_logs: state.appLogsReducer.app_logs,
    loadingAppLogs: state.appLogsReducer.loadingAppLogs,
});

const mapDispatchToProps = (dispatch) => {
    return {
        //   loadingLog: () => dispatch(loadingLog()),
        // getLogs: () => dispatch(startGetLogs()),
        //   getRoles: () => dispatch(startGetRoles()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AdminAppLogsContainer));
import React from "react";
import {useStyles} from "./PaginationFooterStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";
import DropDownElement from "../../../DropDownElement/DropDownElement";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const PaginationFooter = props => {
    const { paginationValues, pageSizeHandler, pageHandler, totalElements } = props;
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Grid container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={4}>
            <Grid item>
                <Grid container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      spacing={3}>
                    <Grid item>
                        <Typography>{intl.formatMessage({id: "tmp", defaultMessage: "Image per page:"})}</Typography>
                    </Grid>
                    <Grid item>
                        <DropDownElement name="pagination-select"
                                         value={paginationValues.pageSize}
                                         onChangeHandler={pageSizeHandler}
                                         options={paginationValues.pageSizeOptions}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography>{paginationValues.startIndex} - {paginationValues.endIndex} of {totalElements}</Typography>
            </Grid>
            <Grid item>
                <Typography>{intl.formatMessage({
                    id: "tmp",
                    defaultMessage: "Page number:"
                })} {paginationValues.pageNumber}</Typography>
            </Grid>
            <Grid item className={classes.button}>
                <IconButton onClick={() => pageHandler(false)}
                            disabled={paginationValues.startIndex === 1}>
                    <KeyboardArrowLeft/>
                </IconButton>
                <IconButton onClick={() => pageHandler(true)}
                            disabled={totalElements === 0 || paginationValues.endIndex === totalElements}>
                    <KeyboardArrowRight/>
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default PaginationFooter;
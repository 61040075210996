import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_FAILURE = 'GET_BRAND_FAILURE';

const localStorageService = LocalStorage.getService();

export const getBrandRequest = () => {
    return {type: GET_BRAND_REQUEST}
};

export const getBrandSuccess = (brand = {}) => {
    return {type: GET_BRAND_SUCCESS, brand}
};

export const getBrandFailure = (error) => {
    return {type: GET_BRAND_FAILURE, error}
};

export const startGetBrand = () => {
    console.log('Start startGetBrand');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getBrandRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/brand/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response brand search ", response.data);
                    dispatch(getBrandSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getBrandFailure(error));
            });
    };
};

export const app_logs_columns = [
    {
        title: {id: "admin.app_logs.type", default: "Type"},
        field: 'type'
    },
    {
        title: {id: "admin.app_logs.app", default: "Application"},
        field: 'application'
    },
    {
        title: {id: "admin.app_logs.message", default: "Message"},
        field: 'message'
    },
    {
        title: { id: "admin.app_logs.timestamp", default: "Timestamp" },
        field: 'timestamp'
    }
];

import {
    CLEAR_DOWNLOAD,
    GET_DOWNLOAD_INFO_REQUEST,
    GET_DOWNLOAD_INFO_SUCCESS,
    GET_DOWNLOAD_INFO_FAILED,
    POST_DOWNLOAD_EXCEL_FAILED,
    POST_DOWNLOAD_EXCEL_REQUEST,
    POST_DOWNLOAD_EXCEL_SUCCESS,
    POST_DOWNLOAD_ZIP_FAILED,
    POST_DOWNLOAD_ZIP_REQUEST,
    POST_DOWNLOAD_ZIP_SUCCESS
} from "../../actions/search/download";
import {SEARCH_ACTION_REQUEST} from "../../../utils/constant";

const initialState = {
    loading: false,
    xlsxUrl: null,
    zipUrl: null,
    sentEmail: null,
    emailReceiver: null,
    downloadInfo: null,
    action: null,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_DOWNLOAD_EXCEL_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.postDownloadExcel
            };
        case GET_DOWNLOAD_INFO_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.postDownloadInfo
            };
        case POST_DOWNLOAD_ZIP_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.postDownloadZip
            };
        case POST_DOWNLOAD_EXCEL_SUCCESS:
            return {
                ...state,
                loading: false,
                xlsxUrl: action.xlsxUrl,
                sentEmail: action.sentEmail,
                emailReceiver: action.receiver
            };
        case POST_DOWNLOAD_ZIP_SUCCESS:
            return {
                ...state,
                loading: false,
                zipUrl: action.zipUrl,
                sentEmail: action.sentEmail,
                emailReceiver: action.receiver
            };
        case GET_DOWNLOAD_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadInfo: action.fileMap
            }
        case POST_DOWNLOAD_ZIP_FAILED:
        case GET_DOWNLOAD_INFO_FAILED:
        case POST_DOWNLOAD_EXCEL_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_DOWNLOAD:
            return initialState;
        default:
            return state;
    }
}
import {
    CLEAR_OUTPUT_TYPES,
    GET_OUTPUT_TYPES_FAILURE,
    GET_OUTPUT_TYPES_REQUEST,
    GET_OUTPUT_TYPES_SUCCESS
} from "../../../actions/showroom/composition/output_type";

const initialState = { outputTypes: [], loadingOutputTypes: false };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_OUTPUT_TYPES_REQUEST:
            return {
                loadingOutputTypes: true
            };
        case GET_OUTPUT_TYPES_SUCCESS:
            return {
                ...state,
                loadingOutputTypes: false,
                outputTypes: action.output_types,
            };
        case GET_OUTPUT_TYPES_FAILURE:
            return {
                ...state,
                loadingOutputTypes: false,
                error: action.error
            };
        case CLEAR_OUTPUT_TYPES:
            return initialState;
        default:
            return state;
    }
}
import React from "react";
import {Typography} from "@material-ui/core";

export const doc_columns = [
    {
        title: { id: "doc.name", default: "Document Name" },
        field: 'name',
        render: rowData => <Typography>{rowData.name}</Typography>
    },
    {
        title: { id: "doc.release_date", default: "Release Date" },
        field: 'release_date',
        render: rowData => <Typography>{rowData.release_date}</Typography>
    },
    {
        title: { id: "doc.link", default: "Link" },
        field: 'url'
    }
];
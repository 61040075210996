import React, {useContext, useEffect} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {connect} from "react-redux";
import LoginContainer from '../containers/LoginContainer';
import HomeContainer from '../containers/HomeContainer';
import NotFoundContainer from '../containers/NotFoundContainer';
import PublicRoute from "./PublicRoute";
import {makeStyles} from "@material-ui/core/styles";
import {IntlContext} from "../utils/IntlContext";
import Container from "@material-ui/core/Container";
import SearchContainer from '../containers/SearchContainer';
import PrivateRoute from "./PrivateRoute";
import SearchResultsContainer from "../containers/SearchResultsContainer";
import MasterdataLoadingContainer from "../containers/MasterdataLoadingContainer";
import UploadDigitalAssetsContainer from "../containers/UploadDigitalAssetsContainer";
import PostProductionContainer from "../containers/PostProductionContainer";
import SessioniOrfaneContainer from "../containers/SessioniOrfaneContainer";
import AdminPageContainer from '../containers/Admin/AdminPageContainer';
import CompositionShowroomContainer from "../containers/CompositionShowrooom/CompositionShowroomContainer";
import ProductShowroomContainer from "../containers/ProductShowroom/ProductShowroomContainer";
import ViewManagementContainer from "../containers/ViewManagementContainer";
import ShootingContainer from "../containers/ShootingContainer";
import DocumentationContainer from "../containers/DocumentationContainer";
import {useIntl} from "react-intl";
import SortingProductAreasContainer from "../containers/ProductShowroom/SortingProductAreasContainer";
import ProductShowroomGalleryContainer from "../containers/ProductShowroom/ProductShowroomGalleryContainer";

export const history = createBrowserHistory();

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.main,
        color: theme.palette.text.main,
        height: "100vh",
        overflow: "auto",
        position: "relative"
    }
}));

const AppRouter = props => {
    const classes = useStyles();
    const { switchToEnglish, switchToItaliano } = useContext(IntlContext);
    const intl = useIntl();

    useEffect(() => {
        if (props.auth && props.auth.language) {
            //  !== undefined) {
            console.log('Setting language: ', props.auth.language);
            if (props.auth.language.toUpperCase() === 'IT') {
                switchToItaliano();
            } else switchToEnglish();
            // The default lang
        } else switchToItaliano();
    }, [props.auth]);

    return (
        <Router history={history}>
            <div className={classes.root}>
                <Container maxWidth="xl">
                    <Switch>
                        <PublicRoute path="/" component={LoginContainer} exact={true}/>
                        <PrivateRoute path="/admin" component={AdminPageContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.admin",
                                          defaultMessage: "Admin Page"
                                      })}/>
                        <PrivateRoute path="/home" component={HomeContainer} exact={true}/>
                        <PrivateRoute path="/search" component={SearchContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.search",
                                          defaultMessage: "Search Product"
                                      })}/>
                        <PrivateRoute path="/search/results" component={SearchResultsContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.search",
                                          defaultMessage: "Search Product"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "common.label.results",
                                          defaultMessage: "Results"
                                      })}/>
                        <PrivateRoute path="/search/post-production" component={PostProductionContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.search",
                                          defaultMessage: "Search Product"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "home.page.search.post.production",
                                          defaultMessage: "Post Production"
                                      })}/>
                        <PrivateRoute path="/master-data" component={MasterdataLoadingContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.load.master.data",
                                          defaultMessage: "Load Master Data"
                                      })}/>
                        <PrivateRoute path="/sessioni-orfane" component={SearchContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.sessioni.orfane",
                                          defaultMessage: "Not recognized photo session"
                                      })}/>
                        <PrivateRoute path="/sessioni-orfane/results" component={SessioniOrfaneContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.sessioni.orfane",
                                          defaultMessage: "Not recognized photo session"
                                      })} pathLabel2={intl.formatMessage({
                            id: "common.label.results",
                            defaultMessage: "Results"
                        })}/>
                        <PrivateRoute path="/upload-digital-assets" component={UploadDigitalAssetsContainer}
                                      exact={true} pathLabel={intl.formatMessage({
                            id: "home.page.upload",
                            defaultMessage: "Upload Digital Assets"
                        })}/>
                        <PrivateRoute path="/view-monitor" component={ViewManagementContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.view",
                                          defaultMessage: "View Monitor"
                                      })}/>
                        <PrivateRoute path="/showroom-composition" component={CompositionShowroomContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.composition",
                                          defaultMessage: "Composition Showroom"
                                      })}/>
                        <PrivateRoute path="/showroom-product" component={ProductShowroomContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.product",
                                          defaultMessage: "Product Showroom"
                                      })}/>
                        <PrivateRoute path="/showroom-product/new-gallery" component={SearchContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.product",
                                          defaultMessage: "Product Showroom"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "home.page.showroom.product.new.gallery",
                                          defaultMessage: "New Gallery"
                                      })}/>
                        <PrivateRoute path="/showroom-product/edit-gallery" component={SearchContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.product",
                                          defaultMessage: "Product Showroom"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "home.page.showroom.product.edit.gallery",
                                          defaultMessage: "Edit Gallery"
                                      })}/>
                        <PrivateRoute path="/showroom-product/edit-gallery/order" component={SortingProductAreasContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.product",
                                          defaultMessage: "Product Showroom"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "home.page.showroom.product.edit.gallery",
                                          defaultMessage: "Edit Gallery"
                                      })}
                                      pathLabel3={intl.formatMessage({
                                        id: "home.page.showroom.product.new.gallery.order",
                                        defaultMessage: "Order"
                                      })}/>
                        <PrivateRoute path="/showroom-product/new-gallery/order"
                                      component={SortingProductAreasContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.product",
                                          defaultMessage: "Product Showroom"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "home.page.showroom.product.new.gallery",
                                          defaultMessage: "New Gallery"
                                      })}
                                      pathLabel3={intl.formatMessage({
                                          id: "home.page.showroom.product.new.gallery.order",
                                          defaultMessage: "Order"
                                      })}/>
                        <PrivateRoute path="/showroom-product/update-gallery-order"
                                      component={SortingProductAreasContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.product",
                                          defaultMessage: "Product Showroom"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "home.page.showroom.product.update.gallery.order",
                                          defaultMessage: "Update Gallery Order"
                                      })}/>
                        <PrivateRoute path="/showroom-product/gallery" component={ProductShowroomGalleryContainer}
                                      exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.showroom.product",
                                          defaultMessage: "Product Showroom"
                                      })}
                                      pathLabel2={intl.formatMessage({
                                          id: "home.page.showroom.product.gallery",
                                          defaultMessage: "Gallery"
                                      })}/>
                        <PrivateRoute path="/shooting" component={ShootingContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.shooting",
                                          defaultMessage: "Ph.ARM Shooting"
                                      })}/>
                        <PrivateRoute path="/documentation" component={DocumentationContainer} exact={true}
                                      pathLabel={intl.formatMessage({
                                          id: "home.page.documentation",
                                          defaultMessage: "Documentation"
                                      })}/>
                        <Route component={NotFoundContainer}/>
                    </Switch>
                </Container>
            </div>
        </Router>
    );
};

const mapStateToProps = state => ({
    loading: state.authReducer.loading,
    auth: state.authReducer.auth
});

export default connect(mapStateToProps)(AppRouter);

import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Grid} from "@material-ui/core";
import {useStyles} from "../../AccordionElementStyle";

const AccordionElementLookShowroom = props => {
    const classes = useStyles();
    const { id, header, subHeader, icons, onChange, open } = props;

    const customIconsHeader = icons ?
        icons.map((icon, index) => {
            return (
                <Grid item key={index}>
                    {icon}
                </Grid>
            );
        }) :
        null;

    return <ExpansionPanel expanded={open}
                           onChange={(event) => onChange(event, id)}
                           className={open ? classes.accordionOpened : ''}>
        <ExpansionPanelSummary
            id={id}
            expandIcon={<ExpandMoreIcon/>}>
            <Grid container direction={"row"} alignItems="center" justify="flex-start">
                <Grid item>
                    <Typography variant={"h6"}>{header}</Typography>
                </Grid>
                <Grid item>
                    {subHeader && <Typography className={classes.secondaryHeading}>{subHeader}</Typography>}
                </Grid>
            </Grid>
            {customIconsHeader &&
            <Grid container direction="row" justify="flex-end" alignItems="center">{customIconsHeader}</Grid>}
        </ExpansionPanelSummary>
        {open && <ExpansionPanelDetails>{props.children}</ExpansionPanelDetails>}
    </ExpansionPanel>
};

export default AccordionElementLookShowroom;
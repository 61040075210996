import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const POST_DELETE_ASSETS_REQUEST = 'POST_DELETE_ASSETS_REQUEST';
export const POST_DELETE_ASSETS_SUCCESS = 'POST_DELETE_ASSETS_SUCCESS';
export const POST_DELETE_ASSETS_FAILURE = 'POST_DELETE_ASSETS_FAILURE';
export const CLEAR_DELETE_ASSETS = 'CLEAR_DELETE_ASSETS';

const localStorageService = LocalStorage.getService();

export const postDeleteAssetsRequest = () => {
    return { type: POST_DELETE_ASSETS_REQUEST };
};

export const postDeleteAssetsSuccess = () => {
    return { type: POST_DELETE_ASSETS_SUCCESS };
};

export const postDeleteAssetsFailure = (error) => {
    return { type: POST_DELETE_ASSETS_FAILURE, error };
};

export const clearDeleteAssets = () => {
    return { type: CLEAR_DELETE_ASSETS };
};

export const startDeleteAssets = (assetGuidList) => {
    console.log('Start startDeleteAssets');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(postDeleteAssetsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/deleteAssets?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({ assets: assetGuidList }),
            }
        )
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response startDeleteAssets: ", response.data);
                    dispatch(postDeleteAssetsSuccess());
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postDeleteAssetsFailure(error));
            });
    };
};

export const search_types_mapping =
    {
            SINGLE_INPUT: 1,
            MULTIPLE_INPUT: 2,
            FILE: 3,
            CHECKBOX_AS_DROPDOWN: 4,
            SINGLE_AUTOCOMPLETE: 5,
            MULTIPLE_AUTOCOMPLETE: 6,
            RANGE_DATE: 7,
    };

import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import InputTextElement from "../components/InputTextElement/InputTextElement";
import {makeStyles} from "@material-ui/core/styles";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import {connect} from 'react-redux';
import {startLogin} from "../store/actions/auth";
import logo from "../assets/images/logo.png";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import logo_dev from "../assets/images/logo_dev.png";
import logo_qas from "../assets/images/logo_qas.png";

const useStyles = makeStyles(theme => ({
    loginRoot: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    logoImg: {
        width: "380px"
    },
    loginBox: {
        width: "380px",
        height: "200px",
        padding: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
    },
    inputs: {
        marginBottom: theme.spacing(2),
    }
}));

const LoginContainer = props => {
    const classes = useStyles();
    const [loginValues, setLoginValues] = useState({user: "", password: ""});
    const [snackBarError, setSnackBarError] = useState(false);

    useEffect(() => {
        if (props.wrong_credential === true)
            setSnackBarError(true);
    }, [props.auth]);

    const handleChange = event => {
        let fieldName = event.target.name;
        let value = event.target.value;
        // console.log(fieldName, value);
        setLoginValues({
            ...loginValues,
            [fieldName]: value
        });
    };

    const onSubmit = event => {
        event.preventDefault();
        props.startLogin(loginValues.user, loginValues.password);
    };

    return (
        <div className={classes.loginRoot}>
            <BackdropElement loading={props.loading}/>
            <SnackbarElement
                open={snackBarError}
                type={"error"}
                label={"Wrong credential"}
                labelId={"login.label.warning.wrong.credentials"}
                handleClose={() => setSnackBarError(false)}
            />
            <img src={`${process.env.REACT_APP_ENV}` === "development" ? logo_dev : (`${process.env.REACT_APP_ENV}` === "qas" ? logo_qas : logo)} className={classes.logoImg} alt=""/>
            <form className={classes.loginBox} onSubmit={onSubmit} data-cy="login-form">
                <div className={classes.inputs}>
                    <InputTextElement labelId={"login.label.user"} label={"User"} name={"user"} value={loginValues.user}
                                      handleChange={handleChange} autoFocus={true} />
                    <InputTextElement
                        labelId={"login.label.password"}
                        label={"Password"}
                        name={"password"}
                        value={loginValues.password}
                        handleChange={handleChange}
                        type={"password"}
                    /></div>
                <ButtonElement labelId={"login.button.login"} label={"Login"} loading={props.loading}
                               onSubmit={onSubmit} type={"submit"}
                               disabled={loginValues.user === "" || loginValues.password === ""}/>
            </form>
        </div>
    );
};

const mapStateToProps = state => ({
    loading: state.authReducer.loading,
    auth: state.authReducer.auth,
    wrong_credential: state.authReducer.wrong_credential
});

const mapDispatchToProps = dispatch => ({
    startLogin: (username, password) => dispatch(startLogin(username, password)),
});

export default connect(mapStateToProps,
    mapDispatchToProps) (injectIntl(LoginContainer));
const LocalStorage = (function(){
    let _service;
    function _getService() {
        if(!_service) {
            _service = this;
            return _service
        }
        return _service
    }
    function _setToken(tokenObj) {
        localStorage.setItem('access_token', tokenObj.access_token);
    }
    function _getAccessToken() {
        return localStorage.getItem('access_token') || "";
    }

    function _clearToken() {
        localStorage.removeItem('access_token');
    }

    function _setCurrentSearch(currentSearch) {
        localStorage.setItem('current_search', JSON.stringify(currentSearch));
    }

    function _getCurrentSearch() {
        return JSON.parse(localStorage.getItem('current_search')) || null;
    }

    function _clearCurrentSearch() {
        localStorage.removeItem('current_search');
    }

    /**
     * @param context: {"richiedente":"3fc746bc6bd5243824e37413e595c9da","service":"f3de79cd1bf738246128bb877d0bf40b","richiedenteService":"8abe8ed93d6f1efe3cbe08fd37dc67ca","season":"b62e8ddace1aa52b91cbcf0a2ab02075","brand":"0786c86d554bea5b43c7930676b73fa1","gender":"2d63c683b338826872a07fed64334141","event":1}
     * @private
     */
    function _setProductShowroomContext(context) {
        localStorage.setItem('product_showroom_context', JSON.stringify(context));
    }

    function _getProductShowroomContext() {
        // if the key is not set return null
        return JSON.parse(localStorage.getItem('product_showroom_context'));
    }

    function _clearProductShowroomContext() {
        localStorage.removeItem('product_showroom_context');
    }

    /**
     * @param gallery: {id: 1, name: 'test', context_id: 1}
     * @private
     */
    function _setProductGallery(gallery) {
        localStorage.setItem('product_gallery', JSON.stringify(gallery));
    }

    function _getProductGallery() {
        // if the key is not set return null
        return JSON.parse(localStorage.getItem('product_gallery'));
    }

    function _clearProductGallery() {
        localStorage.removeItem('product_gallery');
    }


    return {
        getService: _getService,
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        clearToken: _clearToken,
        setCurrentSearch: _setCurrentSearch,
        getCurrentSearch: _getCurrentSearch,
        clearCurrentSearch: _clearCurrentSearch,
        setProductShowroomContext: _setProductShowroomContext,
        getProductShowroomContext: _getProductShowroomContext,
        clearProductShowroomContext: _clearProductShowroomContext,
        setProductGallery: _setProductGallery,
        getProductGallery: _getProductGallery,
        clearProductGallery: _clearProductGallery
    }
})();

export default LocalStorage;
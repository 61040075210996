import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    imgContainer: {
        display: 'flex'
    },
    item: {
        margin: theme.spacing(2),
        position: "relative",
        textAlign: "center"
    },
    deleteIcon: {
        '& .MuiButtonBase-root': {
            position: 'absolute',
            zIndex: 2,
            top: 0,
            left: 0,
            marginLeft: '2px',
            marginTop: '5px',
            color: theme.palette.icon.main
        }
    },
    detailIcon: {
        '& .MuiButtonBase-root': {
            position: 'absolute',
            zIndex: 2,
            top: 0,
            right: 0,
            marginRight: '2px',
            marginTop: '5px',
            color: theme.palette.icon.main
        }
    },
    productLabel: {
        marginTop: theme.spacing(1)
    },
}));
import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";
import {startGetShowroomGalleries} from "./composition/galleries";


export const GET_CONTEXT_REQUEST = 'GET_CONTEXT_REQUEST';
export const GET_CONTEXT_SUCCESS = 'GET_CONTEXT_SUCCESS';
export const GET_CONTEXT_FAILURE = 'GET_CONTEXT_FAILURE';
export const CLEAR_CONTEXT = 'CLEAR_CONTEXT';

export const GET_DEFAULT_CONTEXT_REQUEST = 'GET_DEFAULT_CONTEXT_REQUEST';
export const GET_DEFAULT_CONTEXT_SUCCESS = 'GET_DEFAULT_CONTEXT_SUCCESS';
export const GET_DEFAULT_CONTEXT_FAILURE = 'GET_DEFAULT_CONTEXT_FAILURE';

const localStorageService = LocalStorage.getService();

export const getContextRequest = () => {
    return { type: GET_CONTEXT_REQUEST }
};

export const getContextSuccess = (showroomContext = {}) => {
    return { type: GET_CONTEXT_SUCCESS, showroomContext }
};

export const getContextFailure = (error) => {
    return { type: GET_CONTEXT_FAILURE, error }
};

export const clearContext = () => {
    return { type: CLEAR_CONTEXT };
}

export const getDefaultContextRequest = () => {
    return { type: GET_DEFAULT_CONTEXT_REQUEST }
};

export const getDefaultContextSuccess = (defaultContext = {}) => {
    return { type: GET_DEFAULT_CONTEXT_SUCCESS, defaultContext }
};

export const getDefaultContextFailure = (error) => {
    return { type: GET_DEFAULT_CONTEXT_FAILURE, error }
};

export const startGetShowroomDefaultContext = () => {
    console.log('Start startGetShowroomDefaultContext');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getDefaultContextRequest());
        return axios({
                // .../pg/context
                url: `${process.env.REACT_APP_API_ROOTURL}/context?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&type=default`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startGetShowroomDefaultContext: ", response.data);
                    dispatch(getDefaultContextSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getDefaultContextFailure(error));
            });
    };
}

export const updateShowroomDefaultContext = (context) => {
    console.log('Start updateShowroomDefaultContext');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&type=update`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({
                    brand: context.brand,
                    event: context.event,
                    gender: context.gender,
                    richiedente_service: context.richiedenteService,
                    season: context.season
                })
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response updateShowroomDefaultContext: ", response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
            });
    };
}


export const startGetProductShowroomDefaultContext = () => {
    console.log('Start startGetProductShowroomDefaultContext');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getDefaultContextRequest());
        return axios({
                // .../pg/context
                url: `${process.env.REACT_APP_API_ROOTURL}/pg/context?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&type=default`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startGetProductShowroomDefaultContext: ", response.data);
                    dispatch(getDefaultContextSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getDefaultContextFailure(error));
            });
    };
}

export const updateProductShowroomDefaultContext = (context) => {
    console.log('Start updateProductShowroomDefaultContext');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/pg/context?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&type=update`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({
                    brand: context.brand,
                    event: context.event,
                    gender: context.gender,
                    richiedente_service: context.richiedenteService,
                    season: context.season
                })
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response updateProductShowroomDefaultContext: ", response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
            });
    };
}

export const startGetCompositionContext = (richiedente_service, season, brand, gender, event) => {
    console.log('Start startGetCompositionContext');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getContextRequest());
        return axios({
                // .../pg/context
                url: `${process.env.REACT_APP_API_ROOTURL}/context?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&richiedente_service=${richiedente_service}&season=${season}&brand=${brand}&gender=${gender}&event=${event}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startGetCompositionContext: ", response.data);
                    dispatch(getContextSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getContextFailure(error));
            });
    };
};

// TODO Duplicate because probably will be a new params for product: now it's TBD.
export const startGetProductContext = (richiedente_service, season, brand, gender, event) => {
    console.log('Start startGetProductContext');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getContextRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/pg/context?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&richiedente_service=${richiedente_service}&season=${season}&brand=${brand}&gender=${gender}&event=${event}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startGetProductContext: ", response.data);
                    dispatch(getContextSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getContextFailure(error));
            });
    };
};


export const PUT_UPDATE_ASSET_REQUEST = 'PUT_UPDATE_ASSET_REQUEST';
export const PUT_UPDATE_ASSET_SUCCESS = 'PUT_UPDATE_ASSET_SUCCESS';
export const PUT_UPDATE_ASSET_FAILURE = 'PUT_UPDATE_ASSET_FAILURE';
export const CLEAR_ASSET_UPDATE = 'CLEAR_ASSET_UPDATE';

export const putUpdateAssetRequest = () => {
    return { type: PUT_UPDATE_ASSET_REQUEST }
};

export const putUpdateAssetSuccess = (assetInfos = {}) => {
    return { type: PUT_UPDATE_ASSET_SUCCESS, assetInfos }
};

export const putUpdateAssetFailure = (error) => {
    return { type: PUT_UPDATE_ASSET_FAILURE, error }
};

export const clearUpdateAsset = () => {
    return { type: CLEAR_ASSET_UPDATE }
};

export const startUpdateAssetInfos = (contextId, digitalAssetId, body, filter) => {
    console.log('Start startUpdateAssetInfos: ', '\n- contextId: ', contextId, '\n- digitalAssetId: ', digitalAssetId, '\n- body: ', body);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(putUpdateAssetRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/digital_assets/${digitalAssetId}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startUpdateAssetInfos: ", response.data);
                    dispatch(putUpdateAssetSuccess(response.data));

                    dispatch(startGetShowroomGalleries(contextId, filter));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(putUpdateAssetFailure(error));
            });
    };
};

export const DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';

export const deleteAssetRequest = () => {
    return { type: DELETE_ASSET_REQUEST }
};

export const deleteAssetSuccess = () => {
    return { type: DELETE_ASSET_SUCCESS }
};

export const deleteAssetFailure = (error) => {
    return { type: DELETE_ASSET_FAILURE, error }
};

export const startDeleteAsset = (contextId, assetId, filter) => {
    console.log('Start startDeleteAsset: ', '\n- contextId: ', contextId, '\n- assetId: ', assetId);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(deleteAssetRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/digital_assets/${assetId}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            }
        )
            .then(function (response) {
                if (response.status === 204) {
                    console.log("Response startDeleteAsset: success!");
                    dispatch(deleteAssetSuccess());

                    dispatch(startGetShowroomGalleries(contextId, filter));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(deleteAssetFailure(error));
            });
    };

};
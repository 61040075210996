import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    rootDialog: {
        '& .MuiDialog-paperWidthSm': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.main,
            width: '700px',
            maxWidth: '100%'
        }
    },
    rootDialogContent: {
        padding: theme.spacing(3),
        flexGrow: 1
    }
}));
import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_RICHIEDENTE_REQUEST = 'GET_RICHIEDENTE_REQUEST';
export const GET_RICHIEDENTE_SUCCESS = 'GET_RICHIEDENTE_SUCCESS';
export const GET_RICHIEDENTE_FAILURE = 'GET_RICHIEDENTE_FAILURE';

const localStorageService = LocalStorage.getService();

export const getRichiedenteRequest = () => {
    return {type: GET_RICHIEDENTE_REQUEST}
};

export const getRichiedenteSuccess = (richiedente = {}) => {
    return {type: GET_RICHIEDENTE_SUCCESS, richiedente}
};

export const getRichiedenteFailure = (error) => {
    return {type: GET_RICHIEDENTE_FAILURE, error}
};

export const startGetRichiedente = () => {
    console.log('Start startGetRichiedente');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getRichiedenteRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/richiedente/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response richiedente search ", response.data);
                    dispatch(getRichiedenteSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getRichiedenteFailure(error));
            });
    };
};

import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_EXPORT_STATUS_REQUEST = 'GET_EXPORT_STATUS_REQUEST';
export const GET_EXPORT_STATUS_SUCCESS = 'GET_EXPORT_STATUS_SUCCESS';
export const GET_EXPORT_STATUS_FAILURE = 'GET_EXPORT_STATUS_FAILURE';

const localStorageService = LocalStorage.getService();

export const getExportStatusRequest = () => {
    return { type: GET_EXPORT_STATUS_REQUEST }
};

export const getExportStatusSuccess = (exportStatusList = []) => {
    return { type: GET_EXPORT_STATUS_SUCCESS, exportStatusList }
};

export const getExportStatusFailure = (error) => {
    return { type: GET_EXPORT_STATUS_FAILURE, error }
};

export const startGetExportStatus = () => {
    console.log('Start startGetExportStatus');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getExportStatusRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/ynapExportStatus/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response richiedente search ", response.data);
                    dispatch(getExportStatusSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getExportStatusFailure(error));
            });
    };
};

import {
    CLEAR_SESSIONI_ORFANE,
    DELETE_DRAFTS_REQUEST,
    DELETE_DRAFTS_FAILURE,
    DELETE_DRAFTS_SUCCESS,
    SEARCH_SESSIONI_ORFANE_FAILED,
    SEARCH_SESSIONI_ORFANE_REQUEST,
    SEARCH_SESSIONI_ORFANE_SUCCESS,
    UPDATE_DRAFT_PARING_BY_XSLX_FAILURE,
    UPDATE_DRAFT_PARING_BY_XSLX_SUCCESS,
    UPDATE_SESSIONI_ORFANE_FAILED,
    UPDATE_SESSIONI_ORFANE_REQUEST,
    UPDATE_SESSIONI_ORFANE_SUCCESS,
    UPLOAD_XSLX_FILE_FAILURE,
    UPLOAD_XSLX_FILE_REQUEST
} from "../../actions/sessioniOrfane/sessioniOrfane";

const initialState = {
    results: [], 
    updatedResults: [], 
    uploadFileResults: [],
    loadingSearch: false,
    loadingUpdate: false,
    loadingUploadByFile: false,
    loadingDelete: false,
    uploadError: null,
    deleteError: null,
    deleteSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DELETE_DRAFTS_REQUEST:
            return {
                ...state,
                loadingDelete: true,
                deleteSuccess: false,
                deleteError: null
            };
        case DELETE_DRAFTS_SUCCESS:
            return {
                ...state,
                loadingDelete: false,
                deleteSuccess: true
            }
        case DELETE_DRAFTS_FAILURE:
            return {
                ...state,
                loadingDelete: false,
                deleteError: action.error_msg
            }
        case SEARCH_SESSIONI_ORFANE_REQUEST:
            return {
                ...state,
                loadingSearch: true
            };
        case UPDATE_SESSIONI_ORFANE_REQUEST:
            return {
                ...state,
                loadingUpdate: true
            };
        case UPLOAD_XSLX_FILE_REQUEST:
            return {
                ...state,
                loadingUploadByFile: true
            };
        case SEARCH_SESSIONI_ORFANE_SUCCESS:
            return {
                ...state,
                loadingSearch: false,
                results: action.response['Products']
            };
        case UPDATE_SESSIONI_ORFANE_SUCCESS:
            return {
                ...state,
                loadingUpdate: false,
                updatedResults: action.updateResponse
            };
        case UPDATE_DRAFT_PARING_BY_XSLX_SUCCESS:
            return {
                ...state,
                loadingUploadByFile: false,
                uploadFileResults: action.draftList
            };
        case SEARCH_SESSIONI_ORFANE_FAILED:
            return {
                ...state,
                loadingSearch: false,
                updateError: action.updateError
            };
        case UPDATE_SESSIONI_ORFANE_FAILED:
            return {
                ...state,
                loadingUpdate: false,
                uploadError: action.error_msg
            };
        case UPLOAD_XSLX_FILE_FAILURE:
            return {
                ...state,
                loadingUploadByFile: false,
                uploadError: action.error_msg
            };
        case UPDATE_DRAFT_PARING_BY_XSLX_FAILURE:
            return {
                ...state,
                loadingUploadByFile: false,
                uploadError: action.error_msg
            };
        case CLEAR_SESSIONI_ORFANE:
            return initialState;
        default:
            return state;
    }
};
import {
    GET_SERVICE_BY_RICHIEDENTE_FAILURE,
    GET_SERVICE_BY_RICHIEDENTE_REQUEST, GET_SERVICE_BY_RICHIEDENTE_SUCCESS,
    GET_SERVICE_FAILURE,
    GET_SERVICE_REQUEST,
    GET_SERVICE_SUCCESS,
    CLEAR_SERVICES
} from "../actions/service";

const initialState = {service: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_REQUEST:
            return {
                loadingService: true
            };
        case GET_SERVICE_BY_RICHIEDENTE_REQUEST:
            return {
                loadingServices: true
            };
        case GET_SERVICE_SUCCESS:
            return {
                ...state,
                loadingService: false,
                service: action.service_description,
            };
        case GET_SERVICE_BY_RICHIEDENTE_SUCCESS:
            return {
                ...state,
                loadingServices: false,
                services: action.services.services
            }
        case GET_SERVICE_FAILURE:
            return {
                ...state,
                loadingService: false,
                error: action.error
            }
        case GET_SERVICE_BY_RICHIEDENTE_FAILURE:
            return {
                ...state,
                loadingServices: false,
                errorServices: action.errorServices
            }
        case CLEAR_SERVICES:
            return initialState;
        default:
            return state;
    }
}
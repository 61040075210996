import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    paperImage: {
        '& .MuiPaper-root': {
            position: 'relative',
            height: '140px',
            width: '100px'
        },
        '& .MuiSvgIcon-root': {
            position: 'absolute',
            zIndex: 2,
            marginTop: '4px',
            marginLeft: '4px',
            color: theme.palette.text.placeholder
        }
    },
    unchecked: {
        color: theme.palette.secondary.main
    },
    checked: {
        color: theme.palette.secondary.main
    },
    label: {
        fontSize: "small",
        textAlign: "center"
    },
    textBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100px',
        height: '32px',
        fontSize: '2rem !important',
        fontWeight: '500 !important',
        fontStyle: 'italic',
        color: theme.palette.text.main
    }
}));
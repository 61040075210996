import {
    CLEAR_PRODUCT,
    GET_PRODUCT_FAILED,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS
} from "../../../actions/showroom/product/product";

const initialState = {
    loading: false,
    product: {},
    action: null,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_PRODUCT:
            return initialState;
        case GET_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                action: 'GET'
            };
        case GET_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                product: action.product
            };
        case GET_PRODUCT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: 1400,
        color: '#fff',
    },
    progress: {
        width: "100px"
    }
}));
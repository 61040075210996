import {
	GET_PHOTOLAB_BY_GUID_REQUEST,
	GET_PHOTOLAB_BY_GUID_SUCCESS,
	GET_PHOTOLAB_FAILURE,
	GET_PHOTOLAB_REQUEST,
	GET_PHOTOLAB_SUCCESS,
	HIDE_PHOTOLAB_SNACKBAR,
	MACADDRESS_ERROR,
	UPDATE_PHOTOLABS,
	UPDATE_PHOTOSETS
} from "../actions/photolab";

const initialState = {
	photolab: [],
	updatePhotolabs: null,
	updatePhotosets: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PHOTOLAB_REQUEST:
			return {
				loadingPhotolab: true,
			};
		case GET_PHOTOLAB_SUCCESS:
			return {
				...state,
				loadingPhotolab: false,
				photolab: action.photolab,
			};
		case GET_PHOTOLAB_FAILURE:
			return {
				...state,
				loadingPhotolab: false,
				loadingPhotolabSet: false,
				error: action.error,
			};
		case GET_PHOTOLAB_BY_GUID_REQUEST:
			return {
				loadingPhotolabSet: true,
			};
		case GET_PHOTOLAB_BY_GUID_SUCCESS:
			return {
				...state,
				loadingPhotolabSet: false,
				photolab_set: action.photolab_set,
			};
		case UPDATE_PHOTOLABS:
			return {
				...state,
				updatePhotolabs: true
			}
		case UPDATE_PHOTOSETS:
			return {
				...state,
				updatePhotosets: true
			}
		case HIDE_PHOTOLAB_SNACKBAR:
			return {
				...state,
				updatePhotosets: false,
				updatePhotolabs: false,
				macaddressError: null,
				error: null
			}
		case MACADDRESS_ERROR:
			return {
				...state,
				success: false,
				macaddressError: action.error
			}
		default:
			return state;
	}
};

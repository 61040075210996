export const seasons_columns = [
    {
        title: {id: "product.season_code", default: "Season Code"},
        field: 'code'
    },
    {
        title: { id: "admin.season.description", default: "Description" },
        field: 'name'
    },
    {
        title: { id: "admin.season.external_code", default: "External Code" },
        field: 'external_code'
    },
    {
        title: { id: "admin.season.status", default: "Status" },
        field: 'status'
    }
];

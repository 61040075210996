import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    droppableContainer: {
        border: "1px solid ".concat(theme.palette.background.hover),
        borderRadius: "16px",
        backgroundColor: theme.palette.background.paper
    },
    gridItem: {
        margin: theme.spacing(1)
    },
    droppableTitle: {
        marginBottom: theme.spacing(3)
    },
    item: {
        display: "flex",
        border: "1px solid ".concat(theme.palette.primary.text),
        borderRadius: "16px",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1)
    },
    icon: {
        marginRight: "5px"
    },
    draggableContainer: {
        minHeight: "50px",
        maxHeight: "660px",
        overflowY: "scroll"
    }
}));


import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(.5),
        height: theme.spacing(4.5),
        width: "100%",
        maxWidth: '25ch',
        color: theme.palette.text.main,
        '&:hover': {
            color: theme.palette.primary.main,
        },
        '&:disabled': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
    },
    submit: {
        backgroundColor: theme.palette.button.main,
    },
    cancel: {
        backgroundColor: theme.palette.secondary.main,
    },
    delete: {
        backgroundColor: theme.palette.error.main,
    },
    edit: {
        backgroundColor: theme.palette.button.edit,
    },
    deactive: {
        backgroundColor: theme.palette.button.deactive,
    }
}));
import React from "react";
import DrawerElement from "../DrawerElement";
import Grid from "@material-ui/core/Grid";
import IconButtonElement from "../../IconButtonElement/IconButtonElement";
import {getCompositionShowroomImageBorderColor, onErrorSrc} from "../../../utils/CommonFunction";
import {useStyles} from "./DrawerElementShowroomStyle";
import InputTextElement from "../../InputTextElement/InputTextElement";
import AutocompleteMultivalueElement from "../../AutocompleteMultivalueElement/AutocompleteMultivalueElement";
import DropDownElement from "../../DropDownElement/DropDownElement";
import PreserveImageRatio from "../../PreserveImageRatio/PreserveImageRatio";
import MovieIcon from "@material-ui/icons/Movie";
import Typography from '@material-ui/core/Typography';

const DrawerElementShowroom = props => {
    const { isOpen, closeHandler, data, handlePrevClick, handleNextClick, currentInputState, handleCurrentInputState, handleChangeAutocomplete, callOptionList, optionList, loadings } = props;
    const classes = useStyles();

    return (
        <div className={classes.drawerRoot}>
            <DrawerElement open={isOpen} handleClose={closeHandler} anchor={"bottom"}>
                <Grid container
                      direction='row'
                      justify='space-around'
                      alignItems='center'
                >
                    <Grid item xs={2} className={classes.itemCenter}>
                        <IconButtonElement
                            icon='arrow_back_ios_icon'
                            labelId='common.previous'
                            label='Prev'
                            onClick={handlePrevClick}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container
                              direction='row'
                              justify='space-around'
                              alignItems='center'>
                            <Grid item xs={12} className={classes.itemCenter}>
                                {
                                    data.type === 'image' ?
                                        <div className={classes.movieFrameContainer}>
                                            <PreserveImageRatio src={data.url}
                                                    alt={"Image not found"}
                                                    height={560}
                                                    width={400}
                                                    className={getCompositionShowroomImageBorderColor(classes, data.is_polaroid, data.look ? data.look.is_approved : false)}
                                                    onError={(event) => onErrorSrc(event)}/>
                                        </div>:
                                        <div className={classes.movieFrameContainer}>
                                            <MovieIcon className={classes.movieIcon}/>
                                            <PreserveImageRatio
                                                src={data.frame_url}
                                                height={560}
                                                width={400}
                                                className={getCompositionShowroomImageBorderColor(classes, data.is_polaroid, data.look ? data.look.is_approved : false)}
                                                isVideo
                                            />
                                        </div>
                                }
                                <Typography variant="caption" display="block" className={classes.captionStyle}>
                                    {data.display_filename}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        {/*<Paper>*/}
                        <Grid container
                              direction='row'
                              justify='center'
                              alignItems='center'
                              spacing={2}>
                            <Grid item xs={12}>
                                <InputTextElement
                                    name={"label"}
                                    value={currentInputState.label}
                                    handleChange={handleCurrentInputState}
                                    type="text"
                                    labelId={"product.label"}
                                    label={"Label"}
                                    disabled={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DropDownElement
                                    upLabel={{ id: "showroom.dropdown.must_buy", defaultMessage: "Must buy" }}
                                    name={"must_buy"}
                                    value={currentInputState.must_buy}
                                    onChangeHandler={handleCurrentInputState}
                                    options={[{ label: "True", value: true }, { label: "False", value: false }]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DropDownElement
                                    upLabel={{ id: "showroom.dropdown.look", defaultMessage: "Look" }}
                                    name={"look"}
                                    value={currentInputState.look}
                                    onChangeHandler={handleCurrentInputState}
                                    //label={{ translateKey: "showroom.dropdown.look", defaultText: "Look" }}
                                    options={optionList["looks"]}
                                    loading={loadings["looks"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteMultivalueElement
                                    upLabel={{ id: "showroom.dropdown.output_types", defaultMessage: "Output Types" }}
                                    handleOnChange={(event, autocompleteValues) =>
                                        handleChangeAutocomplete(event, "output_types", autocompleteValues)}
                                    callOptionList={callOptionList}
                                    name={"output_types"}
                                    optionList={optionList["output_types"] || []}
                                    value={currentInputState.output_types || []}
                                    loading={loadings["output_types"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteMultivalueElement
                                    upLabel={{ id: "showroom.tab.all", defaultMessage: "Galleries" }}
                                    handleOnChange={(event, autocompleteValues) =>
                                        handleChangeAutocomplete(event, "galleries", autocompleteValues)}
                                    callOptionList={callOptionList}
                                    name={"galleries"}
                                    optionList={optionList["galleries"] || []}
                                    value={currentInputState.galleries || []}
                                    loading={loadings["galleries"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className={classes.itemCenter}>
                        <IconButtonElement
                            icon='arrow_forward_ios_icon'
                            labelId='common.next'
                            label='Next'
                            onClick={handleNextClick}
                        />
                    </Grid>
                </Grid>
            </DrawerElement>
        </div>
    );
}

export default DrawerElementShowroom;
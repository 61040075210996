import {loggedOut} from "./auth";
import LocalStorage from "../localStorage";
import axios from "axios";

export const GET_FAMILY_NAME_REQUEST = 'GET_FAMILY_NAME_REQUEST';
export const GET_FAMILY_NAME_SUCCESS = 'GET_FAMILY_NAME_SUCCESS';
export const GET_FAMILY_NAME_FAILURE = 'GET_FAMILY_NAME_FAILURE';

const localStorageService = LocalStorage.getService();

export const getFamilyNameRequest = () => {
    return {type: GET_FAMILY_NAME_REQUEST}
};

export const getFamilyNameSuccess = (pg_family_name = {}) => {
    return {type: GET_FAMILY_NAME_SUCCESS, pg_family_name}
};

export const getFamilyNameFailure = (error) => {
    return {type: GET_FAMILY_NAME_FAILURE, error}
};

export const startGetFamilyName = () => {
    console.log('Start startGetFamilyName');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getFamilyNameRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/family_name/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response family name search ", response.data);
                    dispatch(getFamilyNameSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getFamilyNameFailure(error));
            });
    };
};
import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const POST_APPROVE_ASSETS_REQUEST = 'POST_APPROVE_ASSETS_REQUEST';
export const POST_APPROVE_ASSETS_SUCCESS = 'POST_APPROVE_ASSETS_SUCCESS';
export const POST_APPROVE_ASSETS_FAILURE = 'POST_APPROVE_ASSETS_FAILURE';

export const POST_REJECT_ASSETS_REQUEST = 'POST_REJECT_ASSETS_REQUEST';
export const POST_REJECT_ASSETS_SUCCESS = 'POST_REJECT_ASSETS_SUCCESS';
export const POST_REJECT_ASSETS_FAILURE = 'POST_REJECT_ASSETS_FAILURE';

export const POST_RESET_ASSETS_REQUEST = 'POST_RESET_ASSETS_REQUEST';
export const POST_RESET_ASSETS_SUCCESS = 'POST_RESET_ASSETS_SUCCESS';
export const POST_RESET_ASSETS_FAILURE = 'POST_RESET_ASSETS_FAILURE';

export const POST_MIGRATE_ASSETS_REQUEST = 'POST_MIGRATE_ASSETS_REQUEST';
export const POST_MIGRATE_ASSETS_SUCCESS = 'POST_MIGRATE_ASSETS_SUCCESS';
export const POST_MIGRATE_ASSETS_FAILURE = 'POST_MIGRATE_ASSETS_FAILURE';

export const WRONG_ASSETS_STATUS = 'WRONG_ASSETS_STATUS';
export const CLEAR_ERROR_STATUS = 'CLEAR_ERROR_STATUS';
export const CLEAR_APPROVAL_ASSETS = 'CLEAR_APPROVAL_ASSETS';

const localStorageService = LocalStorage.getService();

export const clearErrorStatus = () => {
    return { type: CLEAR_ERROR_STATUS }
}

export const approveAssetsRequest = () => {
    return { type: POST_APPROVE_ASSETS_REQUEST }
};

export const approveAssetsSuccess = (response) => {
    return { type: POST_APPROVE_ASSETS_SUCCESS, response }
};

export const approveAssetsFailure = (error) => {
    return {type: POST_APPROVE_ASSETS_FAILURE, error}
};

export const rejectAssetsRequest = () => {
    return {type: POST_REJECT_ASSETS_REQUEST}
};

export const rejectAssetsSuccess = (response) => {
    return {type: POST_REJECT_ASSETS_SUCCESS, response}
};

export const rejectAssetsFailure = (error) => {
    return { type: POST_REJECT_ASSETS_FAILURE, error }
};

export const resetAssetsRequest = () => {
    return { type: POST_RESET_ASSETS_REQUEST }
};

export const resetAssetsSuccess = (response) => {
    return { type: POST_RESET_ASSETS_SUCCESS, response }
};

export const resetAssetsFailure = (error) => {
    return { type: POST_RESET_ASSETS_FAILURE, error }
};

export const migrateAssetsRequest = () => {
    return { type: POST_MIGRATE_ASSETS_REQUEST }
};

export const migrateAssetsSuccess = (response) => {
    return { type: POST_MIGRATE_ASSETS_SUCCESS, response }
};

export const migrateAssetsFailure = (error) => {
    return { type: POST_MIGRATE_ASSETS_FAILURE, error }
};

export const wrongAssetsStatus = () => {
    return { type: WRONG_ASSETS_STATUS }
};

export const clearAssets = () => {
    return { type: CLEAR_APPROVAL_ASSETS };
};

export const startApproveAssets = (body) => {
    let token = localStorageService.getAccessToken();
    console.log("Start calling startApproveAssets with body: ", body);
    return (dispatch) => {
        dispatch(approveAssetsRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/approve?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                console.log("Response startApproveAssets: ", response.data);
                if (response.status === 200 && response.data !== null) {
                    dispatch(approveAssetsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                else if (error.response.status === 409) dispatch(wrongAssetsStatus());
                else if (error.response.status === 417) {
                    dispatch(approveAssetsFailure(error.response.data.error));
                }
                else dispatch(approveAssetsFailure(error));
            });
    };
};

export const startRejectAssets = (body) => {
    let token = localStorageService.getAccessToken();
    console.log("Start calling startRejectAssets with body: ", body);
    return (dispatch) => {
        dispatch(rejectAssetsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/reject?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                console.log("Response getImage: ", response);
                console.log("Response getImage: ", response.data);
                if (response.status === 200 && response.data !== null) {
                    dispatch(rejectAssetsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                else if (error.response.status === 409) dispatch(wrongAssetsStatus());
                else if (error.response.status === 417) {
                    dispatch(rejectAssetsFailure(error.response.data.error));
                }
                else dispatch(rejectAssetsFailure(error));
            });
    };
};

export const startResetAssets = (body) => {
    let token = localStorageService.getAccessToken();
    console.log("Start calling startResetAssets with body: ", body);
    return (dispatch) => {
        dispatch(resetAssetsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/reset?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                console.log("Response startResetAssets: ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(resetAssetsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                else if (error.response.status === 409) dispatch(wrongAssetsStatus());
                else if (error.response.status === 417) {
                    dispatch(resetAssetsFailure(error.response.data.error));
                }
                else dispatch(resetAssetsFailure(error));
            });
    };
};

export const startMigrateAssets = (body) => {
    let token = localStorageService.getAccessToken();
    console.log("Start calling startMigrateAssets with body: ", body);
    return (dispatch) => {
        dispatch(migrateAssetsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/migrate_axcom_armanicom?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                console.log("Response startResetAssets: ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(migrateAssetsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                else if (error.response.status === 409) dispatch(wrongAssetsStatus());
                else if (error.response.status === 417) {
                    dispatch(migrateAssetsFailure(error.response.data.error));
                }
                else dispatch(migrateAssetsFailure(error));
            });
    };
};
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.main,
        backgroundColor: theme.palette.secondary.main,
        width: "111px",
        height: "111px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            textDecoration: 'none',
            color: theme.palette.focus.main,
            boxShadow: '0 0 5px '.concat(theme.palette.focus.main),
        }
    },
    gridPaper:{
        textAlign: 'center'
    },
    button : {
        fontSize: '.8rem'
    },
    img: {
        "&:hover": {
            color: theme.palette.button.main
        }
    }
}));
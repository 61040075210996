import React, {useEffect} from "react";
import {PaperHomeElement} from "../components/PaperHomeElement/PaperHomeElement";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {NavLink} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {home_pages} from "../assets/resources/home_pages";
import {connect} from "react-redux";
import {checkApplication} from "../utils/CommonFunction";
import LocalStorage from "../store/localStorage";
import {clearResponseSearch} from "../store/actions/search/search";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3, 3),
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "992px"
    }
}));

const localStorageService = LocalStorage.getService();


const HomeContainer = (props) => {
    const classes = useStyles();

    useEffect(() => {
        localStorageService.clearCurrentSearch();
        localStorageService.clearProductShowroomContext();
        props.clearResponseSearch();
    }, []);

    const tiles_elements = home_pages
        .filter((element) => checkApplication(element.applicationTag, props.auth.applications))
        .map((element, index) => (
            <Grid key={index} item xs={4} md={3} lg={2} component={NavLink} to={element.link}>
                <PaperHomeElement 
                    translateKey={element.titleId}
                    defaultText={element.title}
                    icon={element.icon}
                />
            </Grid>
        ));

    return (
        <div className={classes.root} data-cy="home-container">
            {checkApplication('home', props.auth.applications) &&
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}>
                {tiles_elements}
            </Grid>
            }
        </div>
    );
};


const mapStateToProps = state => ({
    auth: state.authReducer.auth
});

const mapDispatchToProps = dispatch => ({
    clearResponseSearch: () => dispatch(clearResponseSearch())
});


export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

const localStorageService = LocalStorage.getService();

export const CREATE_INSERT_PRODUCT_INFO_REQUEST = "CREATE_INSERT_PRODUCT_INFO_REQUEST";
export const CREATE_INSERT_PRODUCT_INFO_SUCCESS = "CREATE_INSERT_PRODUCT_INFO_SUCCESS";
export const CREATE_INSERT_PRODUCT_INFO_FAILURE = "CREATE_INSERT_PRODUCT_INFO_FAILURE";
export const INSERT_PRODUCT_INFO_BY_XSLX_SUCCESS = "INSERT_PRODUCT_INFO_BY_XSLX_SUCCESS";
export const INSERT_PRODUCT_INFO_BY_XSLX_FAILURE = "INSERT_PRODUCT_INFO_BY_XSLX_FAILURE";
export const SET_DISTRIBUTION_DATE_REQUEST = "SET_DISTRIBUTION_DATE_REQUEST";
export const SET_DISTRIBUTION_DATE_SUCCESS = "SET_DISTRIBUTION_DATE_SUCCESS";
export const SET_DISTRIBUTION_DATE_FAILURE = "SET_DISTRIBUTION_DATE_FAILURE";
export const CLEAR_PRODUCT_INFO = "CLEAR_PRODUCT_INFO";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const createInsertProductInfoRequest = () => {
    return {type: CREATE_INSERT_PRODUCT_INFO_REQUEST};

};
export const createInsertProductInfoSuccess = (filedata) => {
    return {type: CREATE_INSERT_PRODUCT_INFO_SUCCESS, filedata};
};

export const createInsertProductInfoFailure = (error) => {
    return {type: CREATE_INSERT_PRODUCT_INFO_FAILURE, error};
};

export const insertProductInfoByXslxSuccess = (productInfo) => {
    return {type: INSERT_PRODUCT_INFO_BY_XSLX_SUCCESS, productInfo};
}

export const insertProductInfoByXslxFailure = (error) => {
    return { type: INSERT_PRODUCT_INFO_BY_XSLX_FAILURE, error };
}

export const startClearProductInfo = () => {
    return { type: CLEAR_PRODUCT_INFO };
}

export const startClearErrors = () => {
    return { type: CLEAR_ERRORS };
}

export const startInsertProductInfo = (file, guid, context, isForcing) => {
    console.log("CALLING startInsertProductInfo with ", file, context, guid)
    let token = localStorageService.getAccessToken();
    let bodyFormData = new FormData();
    bodyFormData.append("file", file[0]);
    return (dispatch) => {
        dispatch(createInsertProductInfoRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/file/upload?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&guid=` + guid + `&label=xslx_upload&folder=master_data_upload`,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: bodyFormData,
        })
            .then(function (response) {
                console.log("Response InsertProductInfo ", response);
                if (response.status === 200 && response.data !== null) {
                    let filedata = response.data;
                    if (isForcing) {
                        dispatch(startInsertProductForcing(filedata.bucket, filedata.file_name, context))
                    } else {
                        dispatch(startInsertProductInfoByXslx(filedata.bucket, filedata.file_name, context));
                    }
                    dispatch(createInsertProductInfoSuccess(filedata));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(createInsertProductInfoFailure(error));
            })

    };
};

export const startInsertProductForcing = (bucket, file_name, context) => {
    console.log("CALLING startInsertProductForcing with ", bucket, file_name, context);
    let bodyData = {
        richiedente_service: context.richiedente_service,
        season: context.season,
        bucket_name: bucket,
        file_name: file_name
    };
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/force_insert?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify(bodyData),
        })
            .then(function (response) {
                console.log("Response startInsertProductForcing ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(insertProductInfoByXslxSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response.data);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(insertProductInfoByXslxFailure(error.response.data.Error));
            })
    }
};


export const startInsertProductInfoByXslx = (bucket, file_name, context) => {
    console.log("CALLING startInsertProductInfoByXslx with ", bucket, file_name, context);
    let bodyData = {
        richiedente_service: context.richiedente_service,
        season: context.season,
        bucket_name: bucket,
        file_name: file_name
    }
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/insertProductInfoByXslx?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify(bodyData),
        })
            .then(function (response) {
                console.log("Response startInsertProductInfoByXslx ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(insertProductInfoByXslxSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response.data);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(insertProductInfoByXslxFailure(error.response.data.Error));
            })
    }
};

export const startUpdateProductInfoXslx = (products, context) => {
    console.log("CALLING startInsertProductInfoByXslx with ", products, context);
    let bodyData = {
        richiedente_service: context.richiedente_service,
        season: context.season,
        products: products,
    }
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/insertProductInfoByXslx?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify(bodyData),
        })
            .then(function (response) {
                console.log("Response startInsertProductInfoByXslx ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(insertProductInfoByXslxSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(insertProductInfoByXslxFailure(error));
            })
    }
};

export const setDistributionDateRequest = () => {
    return {type: SET_DISTRIBUTION_DATE_REQUEST};
}

export const setDistributionDateSuccess = (response) => {
    return {type: SET_DISTRIBUTION_DATE_SUCCESS, response};
}

export const setDistributionDateFailure = (errorDate) => {
    return {type: SET_DISTRIBUTION_DATE_FAILURE, errorDate};
}

export const startSetDistributionDate = (context, start_date, expired_date) => {
    console.log("start startSetDistributionDate with ", context, start_date, expired_date);
    let bodyData = {
        richiedente_service: context.richiedente_service,
        season: context.season,
        start_date: start_date,
        expired_date: expired_date
    }
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(setDistributionDateRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/setDistributionDate?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify(bodyData),
        })
            .then(function (response) {
                console.log("Response startSetDistributionDate ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(setDistributionDateSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(setDistributionDateFailure(error));
            })
    }
};
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useIntl} from "react-intl";
import _ from "lodash";
import {validateColumnsMaterialSimpleTable} from "../utils/CommonFunction";
import {doc_columns} from "../assets/resources/documentation_columns";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import Link from "@material-ui/core/Link";
import {Typography} from "@material-ui/core";
import {clearDocs, startGetDocs,
        clearProductsReports, startGetProductsReports,
        clearAssetsReports, startGetAssetsReports,
        clearVideos, startGetVideos,
        startGetDocsPerRole
} from "../store/actions/documentation";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import AccordionElement from "../components/AccordionElement/AccordionElement";
import {DOCUMENTATION_RESOURCE_TYPE} from "../utils/constant";
import DropDownElement from "../components/DropDownElement/DropDownElement";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(12, 0, 0, 0),
        flexGrow: 1
    },
    link: {
        color: theme.palette.text.main
    },
    table: {
        overflow: "auto",
        "& .MuiButton-label": {
            "&:hover": {
                color: theme.palette.background.main
            }
        },
        '& .MuiTable-root': {
            color: theme.palette.text.main,
            backgroundColor: theme.palette.background.main
        },
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.background.main,
            color: theme.palette.text.main
        },
        '& .MuiButtonBase-root': {
            color: theme.palette.text.main
        },
        '& .MuiInputBase-root': {
            color: theme.palette.text.main
        },
        '& .MuiTableCell-head': {
            color: theme.palette.text.main,
            textTransform: 'uppercase',
            backgroundColor: theme.palette.headerTable.main.concat(' !important')
        },
        '& .MuiTableCell-head *': {
            color: theme.palette.text.main
        },
        '& .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.main
            },
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.main
            },
            '&:hover': {
                backgroundColor: theme.palette.hoverTable.main,
                color: theme.palette.text.main
            }
        },
        '& .MuiTablePagination-root': {
            backgroundColor: theme.palette.background.main.concat(' !important')
        },
        '& .MuiTableRow-footer': {
            '&:hover': {}
        },
        '& .MuiTableCell-root': {
            color: theme.palette.text.main,
            whiteSpace: 'nowrap',
            border: '2px solid '.concat(theme.palette.background.main)
        },
        '& .MuiTableCell-paddingCheckbox': {
            backgroundColor: 'inherit'
        }
    }
}));

const DocumentationContainer = (props) => {
    const classes = useStyles();
    const intl = useIntl();

    const [columnsStatus, setColumnsStatus] = useState([]);
    const [snackBarStatus, setSnackbarStatus] = useState({
        show: false,
        message: '',
        type: '',
        messageAbout: ''
    });
    const [resourcesAccordionOpenState, setResourcesAccordionOpenState] = useState(true);
    const [resourcesState, setResourcesState] = useState(DOCUMENTATION_RESOURCE_TYPE.docs);
    const [resourcesPerRoleState, setResourcesPerRoleState] = useState({retrieved: false})
    const [optionsState, setOptionsState] = useState({
        options: [
            {
                value: DOCUMENTATION_RESOURCE_TYPE.docs,
                label: intl.formatMessage({
                    id: "doc.resource.type.doc",
                    defaultMessage: "Doc",
                }),
            }
        ]
    });


    /* ************************************************ RESOURCES ***************************************************** */
    const resourcesAccordionOnChangeHandler = () => {
        setResourcesAccordionOpenState(prevState => !prevState);
    };

    /*useEffect used to load the set of resources that a user can download based on its role*/
    useEffect(() => {
        if(!resourcesPerRoleState.retrieved) {
            props.getDocsPerRole();
            setResourcesPerRoleState({
                retrieved: true
                });
        }
    }, [resourcesPerRoleState])

    /*useEffect used to add , if available, certain resources to the array of resources visibles by the user based on its role*/
    useEffect(() => {
        if(!props.documentsState.loading && !props.documentsState.error && props.documentsState.availableDocs !== null)
        {
            
            const resPerRole = props.documentsState.availableDocs ;
            let opt = _.cloneDeep(optionsState);

            if(resPerRole.includes(DOCUMENTATION_RESOURCE_TYPE.docs) &&
                !opt.options.some(e => e.value === DOCUMENTATION_RESOURCE_TYPE.docs)){

                opt.options.push({
                    value: DOCUMENTATION_RESOURCE_TYPE.docs,
                    label: intl.formatMessage({
                        id: "doc.resource.type.doc",
                        defaultMessage: "Doc",
                    }),
                });

                console.log("GlobalDoc added to the available resources");
            }

            if(resPerRole.includes(DOCUMENTATION_RESOURCE_TYPE.videos) &&
                !opt.options.some(e => e.value === DOCUMENTATION_RESOURCE_TYPE.videos)){

                opt.options.push({
                    value: DOCUMENTATION_RESOURCE_TYPE.videos,
                    label: intl.formatMessage({
                        id: "doc.resource.type.videos",
                        defaultMessage: "Videos",
                    }),
                });

                console.log("Videos added to the available resources");
            }

            if(resPerRole.includes(DOCUMENTATION_RESOURCE_TYPE.products_reports) &&
                !opt.options.some(e => e.value === DOCUMENTATION_RESOURCE_TYPE.products_reports)){

                opt.options.push({
                    value: DOCUMENTATION_RESOURCE_TYPE.products_reports,
                    label: intl.formatMessage({
                        id: "doc.resource.type.products_reports",
                        defaultMessage: "Products Reports",
                    }),
                });

                console.log("ProductsReports added to the available resources");
            }

            if(resPerRole.includes(DOCUMENTATION_RESOURCE_TYPE.assets_reports) &&
                !opt.options.some(e => e.value === DOCUMENTATION_RESOURCE_TYPE.assets_reports)){

                    opt.options.push({
                        value: DOCUMENTATION_RESOURCE_TYPE.assets_reports,
                        label: intl.formatMessage({
                            id: "doc.resource.type.assets_reports",
                            defaultMessage: "Assets Reports",
                        })
                    });

                    console.log("AssetsReports added to the available resources");
            }
            setOptionsState(opt);
        }
    }, [props.documentsState.availableDocs]);

    /*useEffect used to retrieve resources based on the value of the dropdown choosen by the user*/
    useEffect(() => {

        let tmpResourcesStatus = _.cloneDeep(resourcesState);

        //Selecting which resources to retrieve from BE
        switch (tmpResourcesStatus){
            case DOCUMENTATION_RESOURCE_TYPE.assets_reports:
                props.getAssetsReports();
                break;
            case DOCUMENTATION_RESOURCE_TYPE.products_reports:
                props.getProductsReports();
                break;
            case DOCUMENTATION_RESOURCE_TYPE.videos:
                props.getVideos();
                break;
            case DOCUMENTATION_RESOURCE_TYPE.docs:
            default:
                props.getDocs();
                break;

        }

        // Adding resources link to the table that contains its data
        const columns = validateColumnsMaterialSimpleTable(intl, doc_columns);
        columns.forEach(column => {
            if (column.field === 'url') {
                column.render = rowData =>
                    <Typography>
                        <Link href={rowData.url}
                              target="_blank"
                              rel="noreferrer"
                              variant="inherit"
                              data-cy="doc-link"
                              className={classes.link}>
                            {tmpResourcesStatus === DOCUMENTATION_RESOURCE_TYPE.videos ? "Play" : "Download"}
                        </Link>
                    </Typography>
            }
        });

        console.log(" columns ",columns)
        setColumnsStatus(columns);

        // Container unmount
        return (() => {
            switch(props.resourceType) {
                case DOCUMENTATION_RESOURCE_TYPE.assets_reports:
                    props.clearAssetsReports();
                    break;
                case DOCUMENTATION_RESOURCE_TYPE.products_reports:
                    props.clearProductsReports();
                    break;
                case DOCUMENTATION_RESOURCE_TYPE.videos:
                    props.clearVideos();
                    break;
                case DOCUMENTATION_RESOURCE_TYPE.docs:
                default:
                    props.clearDocs();
                    break;
            }
        })
    }, [resourcesState]);

    // useEffect used in case of errors in retrieving the required resources
    useEffect(() => {
        console.log("props.documentsState.downloadableDocs", props.documentsState.downloadableDocs)
        if (!props.documentsState.loading && props.documentsState.error) {
            console.log("error during the loading of the document")
            setSnackbarStatus(prevState => ({
                ...prevState,
                show: true,
                message: intl.formatMessage({
                    id: 'doc.loading.error',
                    defaultMessage: 'Cannot load infos documentation.'
                }),
                type: 'error',
                messageAbout: 'errorDocumentation'
            }))
        }
    }, [props.documentsState]);

    // Method used by the dropdown to select which resource to ask to the BE
    const onChangeResourcesDropdownHandler = event => {
        let tmpResourcesStatus = null;

        console.log("Selected resource type: " + event.target.value);

        switch(event.target.value) {
            case DOCUMENTATION_RESOURCE_TYPE.assets_reports:
                console.log("assets reports selected via accordion");
                tmpResourcesStatus = DOCUMENTATION_RESOURCE_TYPE.assets_reports;
                break;
            case DOCUMENTATION_RESOURCE_TYPE.products_reports:
                console.log("products reports selected via accordion");
                tmpResourcesStatus = DOCUMENTATION_RESOURCE_TYPE.products_reports;
                break;
            case DOCUMENTATION_RESOURCE_TYPE.docs:
                console.log("docs selected via accordion");
                tmpResourcesStatus = DOCUMENTATION_RESOURCE_TYPE.docs;
                break;
            case DOCUMENTATION_RESOURCE_TYPE.videos:
                console.log("videos selected via accordion");
                tmpResourcesStatus = DOCUMENTATION_RESOURCE_TYPE.videos;
                break;
            default:
                console.log("Unknown choice");
                tmpResourcesStatus = DOCUMENTATION_RESOURCE_TYPE.docs;
                break;
        }

        setResourcesState(tmpResourcesStatus);
    };

    /**
     * Handler to close snackbar message and clear redux state to prevent the rendering of the same message.
     */
    const closeSnackbar = () => {
        switch (snackBarStatus.messageAbout) {
            case 'errorDocumentation':
                switch(props.resourceType) {
                    case DOCUMENTATION_RESOURCE_TYPE.assets_reports:
                        props.clearAssetsReports();
                        break;
                    case DOCUMENTATION_RESOURCE_TYPE.products_reports:
                        props.clearProductsReports();
                        break;
                    case DOCUMENTATION_RESOURCE_TYPE.videos:
                        props.clearVideos();
                        break;
                    case DOCUMENTATION_RESOURCE_TYPE.docs:
                    default:
                        props.clearDocs();
                        break;
                }
                break;
            default:
                break;
        }

        setSnackbarStatus(prevState => ({
            ...prevState,
            show: false,
            message: '',
            type: '',
            messageAbout: ''
        }))
    };

    return (
        <div className={classes.root}>
            <BackdropElement loading={props.documentsState.loading}/>
            {snackBarStatus.show &&
            <SnackbarElement open={snackBarStatus.show}
                             type={snackBarStatus.type}
                             label={snackBarStatus.message}
                             labelParams={snackBarStatus.values}
                             handleClose={() => closeSnackbar()}
            />}
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center">
                <Grid item xs={12}>
                    <AccordionElement id={'documentation-resources-menu'}
                                    header={intl.formatMessage({
                                        id: "doc.resource.types",
                                        defaultMessage: "Resources",
                                    })}
                                    expanded={resourcesAccordionOpenState}
                                     onChangeHandler={resourcesAccordionOnChangeHandler}>
                        <Grid container
                              direction='row'
                              alignItems="flex-start"
                              justify="flex-start">
                            <Grid item xs={3} key="resources">
                                <DropDownElement
                                    key="resources"
                                    name="resources"
                                    value={resourcesState}
                                    onChangeHandler={onChangeResourcesDropdownHandler}
                                    icon="person"
                                    label={ {translateKey: "doc.resource.types", defaultText: "Resources"} }
                                    options={optionsState.options}/>
                            </Grid>
                        </Grid>
                    </AccordionElement>
                </Grid>
                {!_.isEmpty(columnsStatus) && !props.documentsState.loading && props.documentsState.downloadableDocs &&
                <Grid item xs={12} className={classes.table}>
                    <MaterialTable title={''}
                                   columns={columnsStatus}
                                   data={props.documentsState.downloadableDocs}
                                   options={{
                                       search: false,
                                       paging: false,
                                       sorting: false,
                                       pageSize: 10
                                   }}
                                   paging={false}/>
                </Grid>
                }
            </Grid>
        </div>
    )
};

const mapStateToProps = (state) => ({
    documentsState: state.documentsReducer  // loading, availableDocs, downloadableDocs, error
});

const mapDispatchToProps = (dispatch) => ({
    getDocsPerRole: () => dispatch(startGetDocsPerRole()),
    clearDocsPerRole: () => dispatch(startGetDocsPerRole()),
    getDocs: () => dispatch(startGetDocs()),
    clearDocs: () => dispatch(clearDocs()),
    getProductsReports: () => dispatch(startGetProductsReports()),
    clearProductsReports: () => dispatch(clearProductsReports()),
    getAssetsReports: () => dispatch(startGetAssetsReports()),
    clearAssetsReports: () => dispatch(clearAssetsReports()),
    getVideos: () => dispatch(startGetVideos()),
    clearVideos: () => dispatch(clearVideos())
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentationContainer);
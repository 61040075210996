import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";

const localStorageService = LocalStorage.getService();

export const CLEAR_GALLERY = 'CLEAR_GALLERY';

export const CLEAR_NEW_GALLERY = 'CLEAR_NEW_GALLERY';
export const POST_GALLERY_REQUEST = 'POST_GALLERY_REQUEST';
export const POST_GALLERY_SUCCESS = 'POST_GALLERY_SUCCESS';
export const POST_GALLERY_FAILED = 'POST_GALLERY_FAILED';

export const clearProductShowroomGallery = () => {
    return { type: CLEAR_GALLERY }
};

export const clearNewGallery = () => {
    return { type: CLEAR_NEW_GALLERY }
};

export const postGalleryRequest = () => {
    return { type: POST_GALLERY_REQUEST }
};

export const postGallerySuccess = (gallery) => {
    return { type: POST_GALLERY_SUCCESS, gallery }
};

export const postGalleryFailed = (error) => {
    return { type: POST_GALLERY_FAILED, error }
};

/**
 * Create a new product gallery
 *
 * @param contextId {number}: id of context into gallery will be created.
 * @param galleryName {string}: name of the gallery.
 * @param onSuccess {function}: callback to redirect to another route.
 */
export const startPostProductShowroomGallery = (contextId, galleryName, onSuccess) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startPostProductShowroomGallery with: contextId=', contextId, ' galleryName=', galleryName);
    return (dispatch) => {
        dispatch(postGalleryRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/context/${contextId}/galleries?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify({ name: galleryName })
        })
            .then(function (response) {
                if (response.status === 201 && response.data !== null) {
                    console.log('Response startPostProductShowroomGallery: ', response.data);
                    dispatch(postGallerySuccess(response.data));
                    // Set localstorage value
                    localStorageService.setProductGallery(response.data);
                    onSuccess();
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startPostProductShowroomGallery: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postGalleryFailed(error));
            });
    }
};


export const GET_GALLERY_REQUEST = 'GET_GALLERY_REQUEST';
export const GET_GALLERY_SUCCESS = 'GET_GALLERY_SUCCESS';
export const GET_GALLERY_FAILED = 'GET_GALLERY_FAILED';

export const getGalleryRequest = () => {
    return { type: GET_GALLERY_REQUEST }
};

export const getGallerySuccess = (response) => {
    return { type: GET_GALLERY_SUCCESS, gallery: response.gallery, products: response.products }
};

export const getGalleryFailed = (error) => {
    return { type: GET_GALLERY_FAILED, error }
};

/**
 * Getting gallery info: gallery products with related information.
 * @param galleryId {number}: unique identifier of gallery.
 */
export const startGetProductsShowroomGallery = (galleryId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetProductsShowroomGallery with: galleryId=', galleryId);
    return (dispatch) => {
        dispatch(getGalleryRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/products?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetProductsShowroomGallery: ', response.data);
                    dispatch(getGallerySuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startGetProductsShowroomGallery: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getGalleryFailed(error));
            });
    }
};

export const CLEAR_DELETE_GALLERY = 'CLEAR_DELETE_GALLERY';
export const DELETE_GALLERY_REQUEST = 'DELETE_GALLERY_REQUEST';
export const DELETE_GALLERY_SUCCESS = 'DELETE_GALLERY_SUCCESS';
export const DELETE_GALLERY_FAILED = 'DELETE_GALLERY_FAILED';

export const clearDeleteGallery = () => {
    return { type: CLEAR_DELETE_GALLERY }
};

export const deleteGalleryRequest = () => {
    return { type: DELETE_GALLERY_REQUEST }
};

export const deleteGallerySuccess = () => {
    return { type: DELETE_GALLERY_SUCCESS }
};

export const deleteGalleryFailed = (error) => {
    return { type: DELETE_GALLERY_FAILED, error }
};

/**
 * delete gallery info: request to remove a specific gallery ,identified by the gallery_id, to the backend
 * @param galleryId {number}: unique identifier of gallery.
 */
export const deleteProductShowroomGallery = (galleryId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling deleteProductShowroomGallery with: galleryId=', galleryId);

    return (dispatch) => {
        dispatch(deleteGalleryRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
        })
            .then(function (response) {
                if (response.status === 204) {
                    console.log('Response deleteProductShowroomGallery: success!');
                    dispatch(deleteGallerySuccess());
                    // dispatch(startGetProductsShowroomGallery(galleryId));
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error deleteProductShowroomGallery: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(deleteGalleryFailed(error));
            });
    }

}

export const SET_CURRENT_GALLERY = 'SET_CURRENT_GALLERY';

export const setCurrentProductShowroomGallery = (gallery) => {
    localStorageService.setProductGallery(gallery);
    
    return { type: SET_CURRENT_GALLERY, gallery }
}

export const GET_GALLERY_SEARCH_REQUEST = 'GET_GALLERY_SEARCH_REQUEST';
export const GET_GALLERY_SEARCH_SUCCESS = 'GET_GALLERY_SEARCH_SUCCESS';
export const GET_GALLERY_SEARCH_FAILED = 'GET_GALLERY_SEARCH_FAILED';

export const getGallerySearchRequest = () => {
    return { type: GET_GALLERY_SEARCH_REQUEST };
}

export const getGallerySearchSuccess = (gallerySearch) => {
    return { type: GET_GALLERY_SEARCH_SUCCESS, gallerySearch }
};

export const getGallerySearchFailure = (error) => {
    return { type: GET_GALLERY_SEARCH_FAILED, error }
};

/**
 * get gallery search: request to get a specific gallery search,identified by the gallery_id, to the backend
 * @param galleryId {number}: unique identifier of gallery.
 */
export const startGetGallerySearch = (galleryId) => {
    console.log('Start startGetGallerySearch with id: ', galleryId);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getGallerySearchRequest());

        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/stored_search?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response startGetGallerySearch ", response.data);
                    dispatch(getGallerySearchSuccess(response.data));
                }
                else dispatch(getGallerySearchFailure('Search payload not found'));
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getGallerySearchFailure(error));
            });
    };
}

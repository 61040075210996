import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";
import {SEARCH_APPLICATION} from "../../../utils/constant";

export const GET_SEARCH_CONTEXT_REQUEST = 'GET_SEARCH_CONTEXT_REQUEST';
export const GET_SEARCH_CONTEXT_SUCCESS = 'GET_SEARCH_CONTEXT_SUCCESS';
export const GET_SEARCH_CONTEST_FAILURE = 'GET_SEARCH_CONTEST_FAILURE';

const localStorageService = LocalStorage.getService();

export const getSearchContextRequest = () => {
    return { type: GET_SEARCH_CONTEXT_REQUEST }
};

export const getSearchContextSuccess = (searchContext = {}) => {
    return { type: GET_SEARCH_CONTEXT_SUCCESS, searchContext }
};

export const getSearchContextFailure = (error) => {
    return { type: GET_SEARCH_CONTEST_FAILURE, error }
};

export const startGetContextSearch = (application) => {
    console.log('Start startGetContextSearch');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getSearchContextRequest());

        // The legacy API was a POST & the new one is finally a GET
        const method = application === SEARCH_APPLICATION.productShowroom ? 'GET' : 'POST';
        const url = application === SEARCH_APPLICATION.productShowroom ?
            `${process.env.REACT_APP_API_ROOTURL}/pg/search/context/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}` :
            `${process.env.REACT_APP_API_ROOTURL}/search/context/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`;

        return axios({
                url: url,
                method: method,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response context search ", response.data);
                    dispatch(getSearchContextSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getSearchContextFailure(error));
            });
    };
};

import {
    CLEAR_DELETE_GALLERY,
    CLEAR_GALLERY,
    CLEAR_NEW_GALLERY,
    DELETE_GALLERY_FAILED,
    DELETE_GALLERY_REQUEST,
    DELETE_GALLERY_SUCCESS,
    GET_GALLERY_FAILED,
    GET_GALLERY_REQUEST,
    GET_GALLERY_SUCCESS,
    GET_GALLERY_SEARCH_REQUEST,
    GET_GALLERY_SEARCH_SUCCESS,
    GET_GALLERY_SEARCH_FAILED,
    POST_GALLERY_FAILED,
    POST_GALLERY_REQUEST,
    POST_GALLERY_SUCCESS,
    SET_CURRENT_GALLERY
} from "../../../actions/showroom/product/gallery";

const initialState = {
    loading: false,
    gallery: {},
    products: [],
    action: null,
    error: null,
    search: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_GALLERY:
            return initialState;
        case CLEAR_NEW_GALLERY:
            return {
                ...state,
                action: null,
                error: null,
                loading: false,
                gallery: {}
            };
        case CLEAR_DELETE_GALLERY:
            return {
                ...state,
                action: null,
                error: null,
                loading: false
            };
        case POST_GALLERY_REQUEST:
            return {
                ...state,
                loading: true,
                action: 'POST'
            };
        case GET_GALLERY_REQUEST:
            return {
                ...state,
                loading: true,
                action: 'GET'
            };
        case DELETE_GALLERY_REQUEST:
            return {
                ...state,
                loading: true,
                action: 'DELETE'
            };
        case POST_GALLERY_SUCCESS:
            return {
                ...state,
                gallery: action.gallery, // {id: 1, context_id: 1, name: 'Gallery Test'}
                loading: false
            };
        case GET_GALLERY_SUCCESS:
            return {
                ...state,
                gallery: action.gallery,
                products: action.products,
                loading: false
            };
        case DELETE_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_GALLERY_FAILED:
        case GET_GALLERY_FAILED:
        case DELETE_GALLERY_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case SET_CURRENT_GALLERY:
            return {
                ...state,
                gallery: action.gallery,
                action: 'EDIT'
            }
        case GET_GALLERY_SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_GALLERY_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                search: action.gallerySearch
            };
        case GET_GALLERY_SEARCH_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";


export const GET_LOOKS_REQUEST = 'GET_LOOKS_REQUEST';
export const GET_LOOKS_SUCCESS = 'GET_LOOKS_SUCCESS';
export const GET_LOOKS_FAILURE = 'GET_LOOKS_FAILURE';
export const CLEAR_LOOKS = 'CLEAR_LOOKS';

const localStorageService = LocalStorage.getService();

export const getLooksRequest = () => {
    return { type: GET_LOOKS_REQUEST }
};

export const getLooksSuccess = (looks = []) => {
    return { type: GET_LOOKS_SUCCESS, looks }
};

export const getLooksFailure = (error) => {
    return { type: GET_LOOKS_FAILURE, error }
};

export const clearLooks = () => {
    return { type: CLEAR_LOOKS }
};

export const startGetLooks = (contextId) => {
    console.log('Start startGetLooks with ', contextId);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getLooksRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response looks: ", response.data);
                    dispatch(getLooksSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getLooksFailure(error));
            });
    };
};

export const GET_LOOKS_HD_REQUEST = 'GET_LOOKS_HD_REQUEST';
export const GET_LOOKS_HD_SUCCESS = 'GET_LOOKS_HD_SUCCESS';
export const GET_LOOKS_HD_FAILURE = 'GET_LOOKS_HD_FAILURE';
export const CLEAR_LOOKS_HD = 'CLEAR_LOOKS_HD';

export const getLooksHDRequest = () => {
    return { type: GET_LOOKS_HD_REQUEST }
};

export const getLooksHDSuccess = (looks = []) => {
    return { type: GET_LOOKS_HD_SUCCESS, looks }
};

export const getLooksHDFailure = (error) => {
    return { type: GET_LOOKS_HD_FAILURE, error }
};

export const clearLooksHD = () => {
    return { type: CLEAR_LOOKS_HD }
};

export const startGetLooksHD = (contextId) => {
    console.log('Start startGetLooksHD with ', contextId);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getLooksHDRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/looks/hd?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response looks: ", response.data);
                    dispatch(getLooksHDSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getLooksHDFailure(error));
            });
    };
};



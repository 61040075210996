import LocalStorage from "../../localStorage";
import axios from "axios";
import {loggedOut} from "../auth";

export const POST_SELECT_ALL_REQUEST = 'POST_SELECT_ALL_REQUEST';
export const POST_SELECT_ALL_ASSETS_SUCCESS = 'POST_SELECT_ALL_ASSETS_SUCCESS';
export const POST_SELECT_ALL_PRODUCTS_SUCCESS = 'POST_SELECT_ALL_PRODUCTS_SUCCESS';
export const POST_SELECT_ALL_FAILED = 'POST_SELECT_ALL_FAILED';
export const CLEAR_SELECT_ALL = "CLEAR_SELECT_ALL";

const localStorageService = LocalStorage.getService();

export const postSelectAllRequest = () => {
    return { type: POST_SELECT_ALL_REQUEST }
};

export const postSelectAllAssetsSuccess = (assets, count) => {
    return { type: POST_SELECT_ALL_ASSETS_SUCCESS, assets, count }
};

export const postSelectAllProductsSuccess = (products, count) => {
    return { type: POST_SELECT_ALL_PRODUCTS_SUCCESS, products, count }
};

export const postSelectAllFailed = (error) => {
    return { type: POST_SELECT_ALL_FAILED, error }
};

export const clearSelectAll = () => {
    return { type: CLEAR_SELECT_ALL }
}

/**
 * This function is used to invoke search api and get as result the products or assets list that has been selected.
 *
 * @param body {Object}: {"filter_list": [{ "search_type_guid":"7",
 *                                          "name":"export_date",
 *                                          "search_operator_guid":"5",
 *                                          "and_or":"-",
 *                                          "value": {
 *                                              "date_start":"1-11-2020",
 *                                              "date_end":"30-11-2020"
 *                                              }
 *                                          }],
 *                        "asset": true,
 *                        "order": [{
 *                                      "name": "style",
 *                                      "order_type": "ASC"
 *                                    },
 *                                    {
 *                                      "name": "fabric",
 *                                      "order_type": "DESC"
 *                                     }
 *                                   ],
 *                         "filters_table": [{
 *                                              "name": "column_name",
 *                                              "value": "selected_value"
 *                                             }]
 *                         }
 */
export const startPostSelectAll = (body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(postSelectAllRequest());
        console.log('Calling startPostSelectAll with:\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/search_paginated/select_all?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(body)
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startPostSelectAll response: ', response.data);
                if (body.asset) {
                    dispatch(postSelectAllAssetsSuccess(response.data.Assets, response.data.count));
                } else {
                    dispatch(postSelectAllProductsSuccess(response.data.Products, response.data.count));
                }
            }
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postSelectAllFailed(error));
        })
    }
};
import {
    GET_POST_PRODUCERS_FAILURE,
    GET_POST_PRODUCERS_REQUEST,
    GET_POST_PRODUCERS_SUCCESS
} from "../../actions/postProduction/postProducer";
import {ACTION_REQUEST} from "../../../utils/constant";

const initialState = {loading: false, action: null, error: null, postProducers: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_POST_PRODUCERS_REQUEST:
            return {
                loading: true,
                action: ACTION_REQUEST.get
            };
        case GET_POST_PRODUCERS_SUCCESS:
            return {
                ...state,
                loading: false,
                postProducers: action.data
            };
        case GET_POST_PRODUCERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
import axios from "axios";
import {loggedOut} from "../../auth";
import LocalStorage from "../../../localStorage";

const localStorageService = LocalStorage.getService();

export const POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_REQUEST = 'POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_REQUEST';
export const POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_SUCCESS = 'POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_SUCCESS';
export const POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_FAILED = 'POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_FAILED';
export const CLEAR_PRODUCTS_GALLERY_LOOKCAST_EXPORT = 'CLEAR_PRODUCTS_GALLERY_LOOKCAST_EXPORT';

export const postProductsGalleryExportRequest = () => {
    return { type: POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_REQUEST }
};

export const postProductsGalleryExportSuccess = () => {
    return { type: POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_SUCCESS }
};

export const postProductsGalleryExportFailed = (error) => {
    return { type: POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_FAILED, error }
};

export const clearProductsGalleryLookcastExport = () => {
    return { type: CLEAR_PRODUCTS_GALLERY_LOOKCAST_EXPORT }
};

export const startProductGalleryLookcastExport = (galleryId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startProductGalleryLookcastExport with: \n- galleryId: ', galleryId);
    return (dispatch) => {
        dispatch(postProductsGalleryExportRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/export?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            }
        })
            .then(function (response) {
                if (response.status === 201) {
                    console.log('Response startProductGalleryLookcastExport: ', response.data);
                    dispatch(postProductsGalleryExportSuccess());
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startProductGalleryLookcastExport: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postProductsGalleryExportFailed(error));
            });
    }
};
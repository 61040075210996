import React from "react";
import DatePickerCustomizable from "../../../../Pickers/Date/DatePickerCustomizable";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AutocompleteElement from "../../../../AutocompleteElement/AutocompleteElement";
import {search_types_mapping} from "../../../../../assets/resources/search_types_mapping";
import InputTextElement from "../../../../InputTextElement/InputTextElement";
import Grid from "@material-ui/core/Grid";
import DropDownElement from "../../../../DropDownElement/DropDownElement";
import ButtonElement from "../../../../ButtonElement/ButtonElement";
import AutocompleteMultivalueElement from "../../../../AutocompleteMultivalueElement/AutocompleteMultivalueElement";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {useStyles} from "./SearchFieldCellContentStyle";
import _ from "lodash";


const SearchFieldCellContent = (props) => {
    const classes = useStyles();
    const { cellContentType, searchCellChangeHandler, value, rowIndex, rowData, loadings, options, callOptionList} = props;

    console.log("TYPE: ", cellContentType);
    console.log("VALUE: ", value);
    console.log("VALUE TYPE: ", typeof value);
    console.log("ROW INDEX: ", rowIndex);
    console.log("PROPS: " , props);

    // This value is used to remove input field element when selected operator is isNull;
    const operator = _.find(rowData.operators, (operator) => operator.search_operator_guid === rowData.search_operator_guid);

    console.log("OPERATOR: ", operator);

    const operatorIsNullOrNotNull = operator && (operator.search_operator_type === '6' || operator.search_operator_type === '7') ? true : false;

    if (!operatorIsNullOrNotNull) {
        switch (cellContentType) {
            case search_types_mapping.SINGLE_INPUT:
                console.log("SINGLE_INPUT");
                return (
                    <InputTextElement
                        placeholderId={rowData.external_name}
                        placeholder={rowData.external_name}
                        name={rowData.name}
                        handleChange={(event) => searchCellChangeHandler(event, rowIndex, cellContentType)}
                        value={value}
                    />
                );
            case search_types_mapping.MULTIPLE_INPUT:
                console.log("MULTIPLE_INPUT");
                return (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}>
                        {!_.isEmpty(rowData.value.text.value) && rowData.value.separator &&
                        <Grid item className={classes.icon}>
                            <CheckRoundedIcon fontSize={"large"}/>
                        </Grid>
                        }
                        <Grid item className="contButton">
                            <ButtonElement label='...'
                                           loading={false}
                                           type={"submit"}
                                           variant={"outlined"}
                                           onSubmit={(event) => searchCellChangeHandler(event, rowIndex, cellContentType)}/>
                        </Grid>

                    </Grid>
                );
            case search_types_mapping.FILE:
                break;
            case search_types_mapping.CHECKBOX_AS_DROPDOWN:
                console.log("CHECKBOX_AS_DROPDOWN");
                const dropdownOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
                return (
                    <DropDownElement name={rowData.search_type.toString()}
                                     value={rowData.value}
                                     icon='filter_list'
                                     type='dense'
                                     label={{
                                         translateKey: 'search.dropdown.as.checkbox.label.input.value.table',
                                         defaultText: 'Value'
                                     }}
                                     options={dropdownOptions}
                                     onChangeHandler={(event) => searchCellChangeHandler(event, rowIndex, cellContentType)}/>
                );
            case search_types_mapping.SINGLE_AUTOCOMPLETE :
                console.log("SINGLE_AUTOCOMPLETE");
                return (
                    <AutocompleteElement
                        handleOnChange={(event, autocompleteValue) => searchCellChangeHandler(event, rowIndex, rowData.search_type, autocompleteValue)}
                        loading={loadings[rowData.name]}
                        labelId={rowData.external_name}
                        label={rowData.external_name}
                        callOptionList={callOptionList}
                        optionList={options[rowData.name]}
                        name={rowData.name}
                    />
                );
            case search_types_mapping.MULTIPLE_AUTOCOMPLETE :
                console.log("MULTIPLE_AUTOCOMPLETE");
                console.log("label: ", rowData.external_name);
                console.log("name: ", rowData.name);
                console.log("value: ", rowData.value);
                return (
                    <AutocompleteMultivalueElement
                        handleOnChange={(event, autocompleteValues) => searchCellChangeHandler(event, rowIndex, rowData.search_type, autocompleteValues)}
                        loading={loadings[rowData.name]}
                        labelId={rowData.external_name}
                        label={rowData.external_name}
                        callOptionList={callOptionList}
                        optionList={options[rowData.name]}
                        name={rowData.name}
                        value={rowData.value}
                    />
                );
            case search_types_mapping.RANGE_DATE:
                console.log("RANGE_DATE");
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid container
                              direction='row'
                              justify={"space-evenly"}
                              alignItems={"center"}>
                            <Grid item xs={7} lg={4}>
                                <DatePickerCustomizable keyBoardInput
                                                        clearable
                                                        showTodayButton
                                                        labelText='From'
                                                        value={rowData.value.from}
                                                        onChangeHandler={(data) => searchCellChangeHandler(data, rowIndex, rowData.search_type, false)}/>
                            </Grid>
                            <Grid item xs={7} lg={4}>
                                <DatePickerCustomizable keyBoardInput
                                                        clearable
                                                        showTodayButton
                                                        labelText='To'
                                                        minValue={rowData.value.from}
                                                        value={rowData.value.to}
                                                        onChangeHandler={(data) => searchCellChangeHandler(data, rowIndex, rowData.search_type, true)}/>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                );
            default:
                return null;
        }
    } else {
        return null;
    }
};

export default SearchFieldCellContent;
import {
    GET_L2_PRODUCT_GROUPING_FAILURE,
    GET_L2_PRODUCT_GROUPING_REQUEST,
    GET_L2_PRODUCT_GROUPING_SUCCESS
} from "../../actions/search/l2_product_grouping";

const initialState = {l2_product_grouping: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_L2_PRODUCT_GROUPING_REQUEST:
            return {
                loadingL2ProductGrouping: true
            };
        case GET_L2_PRODUCT_GROUPING_SUCCESS:
            return {
                ...state,
                loadingL2ProductGrouping: false,
                l2_product_grouping: action.l2_product_grouping,
            };
        case GET_L2_PRODUCT_GROUPING_FAILURE:
            return {
                ...state,
                loadingL2ProductGrouping: false,
                error: action.error
            }
        default:
            return state;
    }
}
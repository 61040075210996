import {ACTION_REQUEST} from "../../../utils/constant";
import {
    CLEAR_SELECT_ALL,
    POST_SELECT_ALL_ASSETS_SUCCESS,
    POST_SELECT_ALL_FAILED,
    POST_SELECT_ALL_PRODUCTS_SUCCESS,
    POST_SELECT_ALL_REQUEST
} from "../../actions/search/selectAll";

const initialState = {
    loading: false,
    action: null,
    error: null,
    assets: [],
    assetsCount: 0,
    products: [],
    productsCount: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_SELECT_ALL_REQUEST:
            return {
                ...state,
                loading: true,
                action: ACTION_REQUEST.post
            };
        case POST_SELECT_ALL_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false,
                assets: action.assets,
                assetsCount: action.count
            };
        case POST_SELECT_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.products,
                productsCount: action.count
            };
        case POST_SELECT_ALL_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_SELECT_ALL:
            return initialState;
        default:
            return state;
    }
};
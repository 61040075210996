import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";


export const GET_EXCEL_URL_REQUEST = 'GET_EXCEL_URL_REQUEST';
export const GET_EXCEL_URL_SUCCESS = 'GET_EXCEL_URL_SUCCESS';
export const GET_EXCEL_URL_FAILURE = 'GET_EXCEL_URL_FAILURE';
export const GET_EXCEL_GALLERIES_REQUEST = 'GET_EXCEL_GALLERIES_REQUEST';
export const GET_EXCEL_GALLERIES_SUCCESS = 'GET_EXCEL_GALLERIES_SUCCESS';
export const GET_EXCEL_GALLERIES_FAILURE = 'GET_EXCEL_GALLERIES_FAILURE';
export const CLEAR_GALLERY_DOWNLOAD_INFO = 'CLEAR_GALLERY_DOWNLOAD_INFO';

const localStorageService = LocalStorage.getService();

export const getExcelUrlRequest = () => {
    return { type: GET_EXCEL_URL_REQUEST };
};

export const getExcelUrlSuccess = (excelInfos = {}) => {
    return { type: GET_EXCEL_URL_SUCCESS, excelInfos };
};

export const getExcelUrlFailure = (error) => {
    return { type: GET_EXCEL_URL_FAILURE, error };
};

export const getExcelGalleriesRequest = () => {
    return { type: GET_EXCEL_GALLERIES_REQUEST };
};

export const getExcelGalleriesSuccess = (excelInfos = {}) => {
    return { type: GET_EXCEL_GALLERIES_SUCCESS, excelInfos };
};

export const getExcelGalleriesFailure = (error) => {
    return { type: GET_EXCEL_GALLERIES_FAILURE, error };
};

export const clearGalleryDownloadInfo = () => {
    return { type: CLEAR_GALLERY_DOWNLOAD_INFO };
}

export const startGetExcelInfos = (contextId, galleryId) => {
    console.log('Start startGetExcelInfos');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getExcelUrlRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/gallery/${galleryId}/xlsx?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: null,
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response event: ", response.data);
                    dispatch(getExcelUrlSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getExcelUrlFailure(error));
            });
    };
};

export const startGetExcelGalleriesInfos = (contextId) => {
    console.log('Start startGetExcelInfos');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getExcelGalleriesRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/galleries/xlsx?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: null,
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response event: ", response.data);
                    dispatch(getExcelGalleriesSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getExcelGalleriesFailure(error));
            });
    };
};

export const GALLERY_ASSETS_DOWNLOAD_REQUEST = 'GALLERY_ASSETS_DOWNLOAD_REQUEST';
export const GALLERY_ASSETS_DOWNLOAD_SUCCESS = 'GALLERY_ASSETS_DOWNLOAD_SUCCESS';
export const GALLERY_ASSETS_DOWNLOAD_FAILURE = 'GALLERY_ASSETS_DOWNLOAD_FAILURE';

export const galleryDownloadAssetsRequest = () => {
    return { type: GALLERY_ASSETS_DOWNLOAD_REQUEST };
};

export const galleryDownloadAssetsSuccess = (assetsInfo = {}) => {
    return { type: GALLERY_ASSETS_DOWNLOAD_SUCCESS, assetsInfo };
};

export const galleryDownloadAssetsFailure = (error) => {
    return { type: GALLERY_ASSETS_DOWNLOAD_FAILURE, error };
};

export const startGalleryDownloadAssets = (contextId, galleryId, assets) => {
    console.log('Start startGalleryDownloadAssets:', assets);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(galleryDownloadAssetsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/gallery/${galleryId}/digital_assets/zip?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: { photo_id: assets }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response event: ", response.data);
                    dispatch(galleryDownloadAssetsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(galleryDownloadAssetsFailure(error));
            });
    };
}
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTable-root': {
            color: theme.palette.text.main,
            backgroundColor: theme.palette.background.main
        },
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.background.main,
            color: theme.palette.text.main
        },
        '& .MuiButtonBase-root': {
            color: theme.palette.text.main
        },
        '& .MuiInputBase-root': {
            color: theme.palette.text.main
        },
        '& .MuiTableCell-head': {
            color: theme.palette.text.main,
            backgroundColor: theme.palette.secondary.main.concat(' !important')
        },
        '& .MuiTableCell-head *': {
            color: theme.palette.text.main
        },
        '& .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.main
            },
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.main
            },
            '&:hover': {
                backgroundColor: theme.palette.hoverTable.main,
                color: theme.palette.text.main
            }
        },
        '& .MuiTablePagination-root': {
            backgroundColor: theme.palette.background.main.concat(' !important')
        },
        '& .MuiTableRow-footer': {
            '&:hover': {}
        },
        '& .MuiTableCell-root': {
            color: theme.palette.text.main,
            whiteSpace: 'nowrap',
            border: '2px solid '.concat(theme.palette.background.main)
            // border: 'none'
        },
        '& .MuiTableCell-paddingCheckbox': {
            backgroundColor: 'inherit'
        }
    }
}));
import React from "react";
import {useIntl} from "react-intl";
import {DropzoneDialog} from "material-ui-dropzone";
import {useStyles} from "./DropzoneDialogElementStyle";
import Clear from "@material-ui/icons/Clear";

const DropzoneDialogElement = (props) => {
	const intl = useIntl();
	const classes = useStyles();
	const {
		acceptedFiles,
		filesLimit,
		cancelButtonText,
		submitButtonText,
		maxFileSize,
		dropzoneDialogOpen,
		setDropzoneDialogOpen,
		saveFileHandler,
		dialogTitle,
		chipPreview,
		onDropHandler,
		showPreviews,
		previewText,
	} = props;

	//console.log("LOADING?" ,loading);

	return (
		<DropzoneDialog
			acceptedFiles={acceptedFiles}
			dialogTitle={
				dialogTitle &&
				intl.formatMessage({
					id: dialogTitle.labelId,
					defaultMessage: dialogTitle.label,
				})
			}
			filesLimit={filesLimit}
			cancelButtonText={
				cancelButtonText &&
				intl.formatMessage({
					id: cancelButtonText.labelId,
					defaultMessage: cancelButtonText.label,
				})
			}
			submitButtonText={intl.formatMessage({
				id: submitButtonText.labelId,
				defaultMessage: submitButtonText.label,
			})}
			maxFileSize={maxFileSize}
			open={dropzoneDialogOpen}
			onClose={() => setDropzoneDialogOpen(false)}
			onSave={(files) => {
				console.log("Files:", files);
				saveFileHandler(files);
			}}
			onDrop={onDropHandler}
			dialogProps={{
				className: classes.dropzoneRoot,
				PaperProps: classes.paper,
			}}
			showAlerts={false}
			useChipsForPreview={!!chipPreview}
			showPreviews={showPreviews}
			showFileNamesInPreview={true}
			previewText={previewText}
			previewChipProps={{
				// component: "ChipElement"
				size: "small",
				className: classes.chips,
				variant: "default",
				deleteIcon: <Clear/>,
			}}
			dropzoneClass={classes.dropzoneMain}
			dropzoneParagraphClass={classes.dropzoneParagraph}
			//previewGridProps={{container: {alignContent: "center", alignItems: "center"}, item: true}}
		/>
	);
};

export default DropzoneDialogElement;

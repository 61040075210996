import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_APP_LOGS_REQUEST = 'GET_APP_LOGS_REQUEST';
export const GET_APP_LOGS_SUCCESS = 'GET_APP_LOGS_SUCCESS';
export const GET_APP_LOGS_FAILURE = 'GET_APP_LOGS_FAILURE';

const localStorageService = LocalStorage.getService();

export const getAppLogsRequest = () => {
    return {type: GET_APP_LOGS_REQUEST}
};

export const getAppLogsFailure = (error) => {
    return {type: GET_APP_LOGS_FAILURE, error}
};

export function getAppLogsSuccess(app_logs = []) {
    return {type: GET_APP_LOGS_SUCCESS, app_logs};
}

export const startGetAppLogs = () => {
    console.log('Start startGetAppLogs');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getAppLogsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/appLogs?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("AppLogs ", response);
                    dispatch(getAppLogsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getAppLogsFailure(error));
            });
    };
};

import {
    CLEAR_FAVOURITE_DETAILS,
    CREATE_FAVOURITE_FAILURE,
    CREATE_FAVOURITE_REQUEST,
    CREATE_FAVOURITE_SUCCESS,
    DELETE_FAVOURITE_FAILURE,
    DELETE_FAVOURITE_REQUEST,
    DELETE_FAVOURITE_SUCCESS,
    FAVOURITE_SETTINGS_FAILURE,
    FAVOURITE_SETTINGS_REQUEST,
    GET_FAVOURITE_BY_GUID_FAILURE,
    GET_FAVOURITE_BY_GUID_REQUEST,
    GET_FAVOURITE_BY_GUID_SUCCESS,
    GET_FAVOURITE_SETTINGS_SUCCESS,
    GET_FAVOURITES_FAILURE,
    GET_FAVOURITES_REQUEST,
    GET_FAVOURITES_SUCCESS,
    UPDATE_FAVOURITE_SETTINGS_SUCCESS,
    UPDATE_FAVOURITE_SUCCESS
} from "../actions/favourites";

const initialState = { favourites: [], favourite: [], favDetails: [], settings: {} };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_FAVOURITES_REQUEST:
            return {
                loadingFavourites: true
            };
        case CREATE_FAVOURITE_REQUEST:
            return {
                loadingFavouriteCreation: true
            };
        case GET_FAVOURITE_BY_GUID_REQUEST:
            return {
                loadingFavourite: true
            };
        case DELETE_FAVOURITE_REQUEST:
            return {
                loadingFavouriteDelete: true
            }
        case FAVOURITE_SETTINGS_REQUEST:
            return {
                loadingFavouriteSettings: true
            };
        case GET_FAVOURITES_SUCCESS:
            return {
                ...state,
                loadingFavourites: false,
                favourites: action.favourites["Favourites"],
            };
        case CREATE_FAVOURITE_SUCCESS:
            return {
                ...state,
                loadingFavouriteCreation: false,
                favourite: action.post_favourite["Favourite"]
            };
        case GET_FAVOURITE_BY_GUID_SUCCESS:
            return {
                ...state,
                loadingFavourite: false,
                favDetails: action.favDetails,
            };
        case DELETE_FAVOURITE_SUCCESS:
            return {
                ...state,
                loadingFavouriteDelete: false,
                delete_favourite: action.delete_favourite["Status"]
            }
        case UPDATE_FAVOURITE_SUCCESS:
            return {
                ...state,
                loadingFavouriteCreation: false,
                update_favourite: action.update_favourite
            }
        case UPDATE_FAVOURITE_SETTINGS_SUCCESS:
            return {
                ...state,
                loadingFavouriteSettings: false,
                response: action.response
            }
        case GET_FAVOURITE_SETTINGS_SUCCESS:
            return {
                ...state,
                loadingFavouriteSettings: false,
                settings: action.settings && action.settings.payload
            }
        case CLEAR_FAVOURITE_DETAILS:
            //console.log("this IS HAP P E N I NG!!!!!!!!!")
            return {
                ...state,
                favDetails: []
            }
        case GET_FAVOURITES_FAILURE:
            return {
                ...state,
                loadingFavourites: false,
                error: action.error
            };
        case GET_FAVOURITE_BY_GUID_FAILURE:
            return {
               ...state,
               loadingFavourite: false,
               fav_error: action.fav_error
            };
        case CREATE_FAVOURITE_FAILURE:
            return {
                ...state,
                loadingFavouriteCreation: false,
                post_error: action.post_error
            };
        case DELETE_FAVOURITE_FAILURE:
            return {
                ...state,
                loadingFavouriteDelete: false,
                delete_error: action.delete_error
            };
        case FAVOURITE_SETTINGS_FAILURE:
            return {
                ...state,
                loadingFavouriteSettings: false,
                settings_error: action.settings_error
            }
        default:
            return state;
    }
}
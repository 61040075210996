import {
    CLEAR_PRODUCTS_GALLERY_LOOKCAST_EXPORT,
    POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_FAILED,
    POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_REQUEST,
    POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_SUCCESS
} from "../../../actions/showroom/product/exportToLookcast";
import {ACTION_REQUEST} from "../../../../utils/constant";


const initialState = { loading: false, error: null, action: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_REQUEST:
            return {
                ...state,
                loading: true,
                action: ACTION_REQUEST.post
            };
        case POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_PRODUCTS_GALLERY_LOOKCAST_EXPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_PRODUCTS_GALLERY_LOOKCAST_EXPORT:
            return initialState;
        default:
            return state;
    }
}
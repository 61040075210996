import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    title: {
        margin: theme.spacing(0, 0, 1.5, 0),
        textTransform: 'uppercase',
        fontSize: '1rem',
        // textAlign: 'left'
    }
}));
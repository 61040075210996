import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE';

const localStorageService = LocalStorage.getService();

export const getTransactionsRequest = () => {
    return {type: GET_TRANSACTIONS_REQUEST}
};

export const getTransactionsSuccess = (transactions = {}) => {
    return {type: GET_TRANSACTIONS_SUCCESS, transactions}
};

export const getTransactionsFailure = (error) => {
    return {type: GET_TRANSACTIONS_FAILURE, error}
};


export const startGetTransactions = () => {
    console.log('Start startGetTransactions');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getTransactionsRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/transactions?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Transactions ", response);
                    dispatch(getTransactionsSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getTransactionsFailure(error));
            });
    };
};

export const GET_TRANSACTIONS_EXCEL_REQUEST = 'GET_TRANSACTIONS_EXCEL_REQUEST';
export const GET_TRANSACTIONS_EXCEL_SUCCESS = 'GET_TRANSACTIONS_EXCEL_SUCCESS';
export const GET_TRANSACTIONS_EXCEL_FAILURE = 'GET_TRANSACTIONS_EXCEL_FAILURE';

export const getTransactionsExcelRequest = () => {
    return {type: GET_TRANSACTIONS_EXCEL_REQUEST}
};

export const getTransactionsExcelSuccess = (transactionsExcel = {}) => {
    return {type: GET_TRANSACTIONS_EXCEL_SUCCESS, transactionsExcel}
};

export const getTransactionsExcelFailure = (excelError) => {
    return {type: GET_TRANSACTIONS_EXCEL_FAILURE, excelError}
};


export const startGetTransactionsExcel = () => {
    console.log('Start startGetTransactionsExcel');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getTransactionsExcelRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/transactions/excel?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Transactions excel", response);
                    dispatch(getTransactionsExcelSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getTransactionsExcelFailure(error));
            });
    };
};

export const GET_TRANSACTIONS_CSV_REQUEST = 'GET_TRANSACTIONS_CSV_REQUEST';
export const GET_TRANSACTIONS_CSV_SUCCESS = 'GET_TRANSACTIONS_CSV_SUCCESS';
export const GET_TRANSACTIONS_CSV_FAILURE = 'GET_TRANSACTIONS_CSV_FAILURE';

export const getTransactionsCSVRequest = () => {
    return {type: GET_TRANSACTIONS_CSV_REQUEST}
};

export const getTransactionsCSVSuccess = (transactionsCsv = {}) => {
    return {type: GET_TRANSACTIONS_CSV_SUCCESS, transactionsCsv}
};

export const getTransactionsCSVFailure = (csvError) => {
    return {type: GET_TRANSACTIONS_CSV_FAILURE, csvError}
};


export const startGetTransactionsCSV = () => {
    console.log('Start startGetTransactionsCSV');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getTransactionsCSVRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/transactions/csv?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Transactions csv", response);
                    dispatch(getTransactionsCSVSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getTransactionsCSVFailure(error));
            });
    };
};




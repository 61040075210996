import React from "react";
import {useStyles} from "./DndKeyValueProductShowroomElementStyle";
import {Draggable, Droppable} from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReorderIcon from '@material-ui/icons/Reorder';

const DndKeyValueProductShowroomElement = props => {
    const classes = useStyles();
    const { droppableId, draggableElements, title } = props;
    // TODO: finire sistemando il css e definendo la funzione di dnd

    return (
        <div className={classes.droppableContainer}>
            <Droppable droppableId={droppableId}>
                {(provided, snapshot) => (
                    <Grid container
                          direction={"row"}
                          alignItems={"flex-start"}
                          justify={"center"}
                          className={classes.draggableContainer}
                          ref={provided.innerRef}
                          {...provided.droppableDrops}>
                        <Grid item xs={12} className={`${classes.gridItem} ${classes.droppableTitle}`}>
                            <Typography variant={"h5"}>{title}</Typography>
                        </Grid>
                        {
                            draggableElements && draggableElements.map((stringName, index) => (
                                <Draggable draggableId={stringName}
                                           index={index}
                                           key={stringName}>
                                    {(provided, snapshot) => (
                                        <Grid item xs={12}
                                              className={classes.gridItem}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              ref={provided.innerRef}>
                                            <div className={classes.item}>
                                                <ReorderIcon className={classes.icon}/>
                                                <Typography variant={"button"}>{stringName}</Typography>
                                            </div>
                                        </Grid>
                                    )}
                                </Draggable>
                            ))
                        }
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </div>
    )
};

export default DndKeyValueProductShowroomElement;


/*
    return (
        <div className={classes.droppableContainer}>
            <Grid container
                  direction={"column"}
                  alignItems={'center'}
                  justify={'center'}>
                <Grid item className={`${classes.gridItem} ${classes.droppableTitle}`}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Grid>
            </Grid>
            <Droppable droppableId={droppableId}>
                {(provided, snapshot) => (
                    <Grid container
                          direction={"row"}
                          alignItems={'flex-start'}
                          justify={'flex-start'}
                          className={classes.draggableContainer}
                          ref={provided.innerRef}
                          {...provided.droppableDrops}>
                        {elements && elements.map((element, index) => (
                            <Draggable draggableId={element.name} index={index} key={element.name}>
                                {(provided, snapshot) => (
                                    <Grid item xs={12}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                          className={classes.gridItem}>
                                        <div className={classes.item}>
                                            <ReorderIcon className={classes.icon}/>
                                            <Typography variant={"button"}>{element.name}</Typography>
                                        </div>
                                    </Grid>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </div>
    )
};
*/

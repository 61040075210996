import * as jwt from "jsonwebtoken";
import axios from "axios";
import LocalStorage from "../localStorage";

const localStorageService = LocalStorage.getService();
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export function loggedIn(auth = {}) {
    return {type: LOGGED_IN, auth};
}

export function loggedOut() {
    return {type: LOGGED_OUT};
}

/**
 * This State usage is mainly spinner preview
 */
export function requestLogin() {
    return {type: LOGIN_REQUEST};
}

export function loginError() {
    return {type: LOGIN_ERROR};
}

export function requestLogout() {
    return {type: LOGOUT_REQUEST};
}

/**
 * Login Action Call
 * @param {String} email
 * @param {String} password
 */
export function startLogin(email, password) {
    console.log("startLogin with ", email, password);
    return (dispatch) => {
        dispatch(requestLogin());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/login?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*'
                },
                data: JSON.stringify({
                    email,
                    password,
                }),
            }
        )
            .then(function (response) {
                console.log("Response login ", response);
                if (response.status === 200 && response.data !== null) {
                    let jwt_user = jwt.decode(response.data.access_token, {complete: true});
                    localStorageService.setToken(response.data);
                    console.log("User :", jwt_user.payload);
                    dispatch(loggedIn(jwt_user.payload));

                }
            })
            .catch(function (error) {
                console.log(error, error.response.status);
                if (error.response.status === 401 || error.response.status === 400) dispatch(loginError());
                localStorageService.clearToken();
                //dispatch(loggedOut());
            });
    };
}


/**
 * Logout action call
 */
export function startLogout() {
    return (dispatch) => {
        console.log('logging out');
        dispatch(requestLogout());
        let token = localStorageService.getAccessToken();
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/logout?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify({}),
        }).then(function (response) {
            console.log("Response logout ", response);
            if (response.status === 200 && response.data !== null) {
                localStorageService.clearToken();
                dispatch(loggedOut());
                dispatch({type: 'LOGGED_OUT'});
            }
        }).catch(function (error) {
            if(error.response.status === 401) dispatch(loggedOut());
            console.log(error);
        });
    };
}

import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
	info: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.main,
		borderRadius: theme.spacing(1),
		margin: theme.spacing(.5),
	},
	deletable: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.main,
		borderRadius: theme.spacing(1),
		margin: theme.spacing(.5),
		'&:focus': {
			backgroundColor: 'black'
		}
	},
	transparent: {
		backgroundColor: 'transparent',
		color: theme.palette.text.main,
		margin: 0,
		height: 'unset'
	},
	'&:focus': {
		backgroundColor: 'black'
	},
	lightGrey: {
		backgroundColor: theme.palette.text.disabled,
		color: theme.palette.text.main,
		borderRadius: theme.spacing(1),
		margin: theme.spacing(.5),
		'&:focus': {
			backgroundColor: 'black'
		}
	},

}));
import {
    CLEAR_PRODUCT_ALL,
    GET_PRODUCTALL_FAILURE,
    GET_PRODUCTALL_REQUEST,
    GET_PRODUCTALL_SUCCESS
} from "../../actions/viewManagement/productAll";

const initialState = {productAll: undefined};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTALL_REQUEST:
            return {
                loadingProductAll: true
            };
        case GET_PRODUCTALL_SUCCESS:
            return {
                ...state,
                loadingProductAll: false,
                productAll: action.productAll,
                color: action.productAll && action.productAll.color,
                fabric: action.productAll && action.productAll.fabric,
                style: action.productAll && action.productAll.style,
                status: action.productAll && action.productAll.status,
                productInfo: action.productAll && action.productAll.productinfo,
                contextInfo: action.productAll && action.productAll.contextinfo,
            };
        case GET_PRODUCTALL_FAILURE:
            return {
                ...state,
                loadingProductAll: false,
                error: action.error
            }
        case CLEAR_PRODUCT_ALL:
            return {
                ...state,
                productAll: undefined,
                color: undefined,
                fabric: undefined,
                style: undefined,
                status: undefined,
                productInfo: undefined,
                contextInfo: undefined,
                error: undefined
            }
        default:
            return state;
    }
}
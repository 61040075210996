import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const GET_L3_PRODUCT_REQUEST = 'GET_L3_PRODUCT_REQUEST';
export const GET_L3_PRODUCT_SUCCESS = 'GET_L3_PRODUCT_SUCCESS';
export const GET_L3_PRODUCT_FAILURE = 'GET_L3_PRODUCT_FAILURE';

const localStorageService = LocalStorage.getService();

export const getL3ProductRequest = () => {
    return {type: GET_L3_PRODUCT_REQUEST}
};

export const getL3ProductSuccess = (l3_product = {}) => {
    return {type: GET_L3_PRODUCT_SUCCESS, l3_product}
};

export const getL3ProductFailure = (error) => {
    return {type: GET_L3_PRODUCT_FAILURE, error}
};

export const startGetL3Product = () => {
    console.log('Start startGetL3Product');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getL3ProductRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/l3_product/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response l3_product search ", response.data);
                    dispatch(getL3ProductSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getL3ProductFailure(error));
            });
    };
};

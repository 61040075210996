import {
    GET_DRESS_ORIGIN_FAILURE,
    GET_DRESS_ORIGIN_REQUEST,
    GET_DRESS_ORIGIN_SUCCESS
} from "../../actions/search/dress_origin";

const initialState = {dress_origin: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DRESS_ORIGIN_REQUEST:
            return {
                loadingDressOrigin: true
            };
        case GET_DRESS_ORIGIN_SUCCESS:
            return {
                ...state,
                loadingDressOrigin: false,
                dress_origin: action.dress_origin,
            };
        case GET_DRESS_ORIGIN_FAILURE:
            return {
                ...state,
                loadingDressOrigin: false,
                error: action.error
            }
        default:
            return state;
    }
}
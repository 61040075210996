import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    appbar: {
        backgroundColor: theme.palette.background.main,
        padding: theme.spacing(0.5),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    accountButton: {
        color: theme.palette.text.main
    },
    logo: {
        maxWidth: "350px",
        flexGrow: 1,
        display: 'block',
        marginLeft: theme.spacing(3),

    },
    link: {
        color: theme.palette.text.main
    },
    icon: {
        position: 'relative',
        top: '3px'
    },
    logoutText: {
        color: theme.palette.text.main,
        marginLeft: theme.spacing(0.5),
    },
    menuItem: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.main,
        "&:hover": {
            backgroundColor: theme.palette.button.main
        },
    },
    paperMenu: {
        backgroundColor: theme.palette.background.main,
        "& ul": {
            backgroundColor: theme.palette.background.main,
            padding: '0'
        },
    }
}));
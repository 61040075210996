import React from "react";
import {useStyles} from "./TextElementStyle";
import {useIntl} from "react-intl";
import Typography from "@material-ui/core/Typography";

const TextElement = (props) => {
    const classes = useStyles();
    const intl = useIntl();

    const {text} = props;

    return (
        <Typography variant="h6" className={classes.title}>
            {intl.formatMessage({id: text.translateKey, defaultMessage: text.defaultText})}
        </Typography>
    );
};

export default TextElement;
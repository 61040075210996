import {
    CLEAR_LOOK,
    CLEAR_LOOK_PRODUCT,
    CLEAR_LOOK_DETAILS,
    DELETE_LOOK_FAILURE,
    DELETE_LOOK_REQUEST,
    DELETE_LOOK_SUCCESS,
    DELETE_PRODUCT_FROM_LOOK_FAILURE,
    DELETE_PRODUCT_FROM_LOOK_REQUEST,
    DELETE_PRODUCT_FROM_LOOK_SUCCESS,
    GET_LOOK_DETAILS_REQUEST,
    GET_LOOK_DETAILS_SUCCESS,
    GET_LOOK_DETAILS_FAILURE,
    POST_LOOK_FAILURE,
    POST_LOOK_REQUEST,
    POST_LOOK_SUCCESS,
    POST_PRODUCT_TO_LOOK_FAILURE,
    POST_PRODUCT_TO_LOOK_REQUEST,
    POST_PRODUCT_TO_LOOK_PLACEHOLDER,
    POST_PRODUCT_TO_LOOK_SUCCESS,
    PUT_LOOK_FAILURE,
    PUT_LOOK_PRODUCT_FAILURE,
    PUT_LOOK_PRODUCT_REQUEST,
    PUT_LOOK_PRODUCT_SUCCESS,
    PUT_LOOK_PRODUCTS_ORDER_FAILURE,
    PUT_LOOK_PRODUCTS_ORDER_REQUEST,
    PUT_LOOK_PRODUCTS_ORDER_SUCCESS,
    PUT_LOOK_REQUEST,
    PUT_LOOK_SUCCESS
} from "../../../actions/showroom/composition/look";

const initialState = {
    look: {}, 
    type: null, 
    loadingLook: false, 
    errorLook: null,
    loadingLookProduct: false, 
    productLook: {}, 
    errorLookProduct: null, 
    typeLookProduct: null,
    loadingCurrentLookDetails: false,
    currentLookProducts: [],
    currentLookAssets: [],
    errorCurrentLookDetails: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PUT_LOOK_REQUEST:
            return {
                ...state,
                loadingLook: true,
                type: 'PUT'
            };
        case POST_LOOK_REQUEST:
            return {
                ...state,
                loadingLook: true,
                type: 'POST'
            };
        case DELETE_LOOK_REQUEST:
            return {
                ...state,
                loadingLook: true,
                type: 'DELETE'
            };
        case PUT_LOOK_PRODUCTS_ORDER_REQUEST:
            return {
                ...state,
                loadingLookProduct: true,
                typeLookProduct: 'PUT-ORDER'
            };
        case DELETE_PRODUCT_FROM_LOOK_REQUEST:
            return {
                ...state,
                loadingLookProduct: true,
                typeLookProduct: 'DELETE'
            };
        case POST_PRODUCT_TO_LOOK_REQUEST:
            return {
                ...state,
                loadingLookProduct: true,
                typeLookProduct: 'POST'
            };
        case PUT_LOOK_PRODUCT_REQUEST:
            return {
                ...state,
                loadingLookProduct: true,
                typeLookProduct: 'PUT'
            };
        case PUT_LOOK_SUCCESS:
            return {
                ...state,
                look: action.look,
                loadingLook: false
            };
        case POST_LOOK_SUCCESS:
            return {
                ...state,
                loadingLook: false,
                look: action.look
            };
        case DELETE_LOOK_SUCCESS:
            return {
                ...state,
                loadingLook: false
            };
        case PUT_LOOK_PRODUCTS_ORDER_SUCCESS:
        case DELETE_PRODUCT_FROM_LOOK_SUCCESS:
        case PUT_LOOK_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingLookProduct: false,
                productLook: action.look,
                productLookPlaceholder: null,
                currentLookProducts: action.look.products,
                currentLookAssets: action.look.digital_assets
            };
        case POST_PRODUCT_TO_LOOK_SUCCESS:
            return {
                ...state,
                loadingLookProduct: false,
                productLook: action.look,
                productLookPlaceholder: null
            };
        case POST_PRODUCT_TO_LOOK_PLACEHOLDER:
            return {
                ...state,
                loadingLookProduct: false,
                productLookPlaceholder: action.placeholder
            }
        case PUT_LOOK_FAILURE:
            return {
                ...state,
                loadingLook: false,
                errorLook: action.error
            };
        case POST_LOOK_FAILURE:
            return {
                ...state,
                loadingLook: false,
                errorLook: action.error
            };
        case DELETE_LOOK_FAILURE:
            return {
                ...state,
                loadingLook: false,
                errorLook: action.error
            };
        case PUT_LOOK_PRODUCTS_ORDER_FAILURE:
        case DELETE_PRODUCT_FROM_LOOK_FAILURE:
        case POST_PRODUCT_TO_LOOK_FAILURE:
        case PUT_LOOK_PRODUCT_FAILURE:
            return {
                ...state,
                loadingLookProduct: false,
                errorLookProduct: action.error
            };
        case GET_LOOK_DETAILS_REQUEST:
            return {
                ...state,
                loadingCurrentLookDetails: true
            };
        case GET_LOOK_DETAILS_SUCCESS:
            return {
                ...state,
                loadingCurrentLookDetails: false,
                currentLookProducts: action.lookDetails.products,
                currentLookAssets: action.lookDetails.digital_assets
            };
        case GET_LOOK_DETAILS_FAILURE:
            return {
                ...state,
                loadingCurrentLookDetails: false,
                errorCurrentLookDetails: action.error
            };
        case CLEAR_LOOK_DETAILS:
            return {
                ...state,
                loadingCurrentLookDetails: false,
                errorCurrentLookDetails: null,
                currentLookProducts: [],
                currentLookAssets: []
            };
        case CLEAR_LOOK:
            return {
                ...state,
                loadingLook: false,
                errorLook: null,
                type: null,
                look: {}
            };
        case CLEAR_LOOK_PRODUCT:
            return {
                ...state,
                loadingLookProduct: false,
                productLook: {},
                productLookPlaceholder: null,
                errorLookProduct: null,
                typeLookProduct: null
            }
        default:
            return state;
    }
}
import MaterialTable from "material-table";
import React from "react";
import _ from "lodash";
import {useStyles} from "./EditableTableStyle";

const EditableTable = (props) => {

    const classes = useStyles();
    const { data, columns, setData, selection, addable, title, search, setSelectedData, handleValidation, deletable, pageSize } = props;
    //console.log("PROPS ", data, columns, addable, pageSize);

    return (
        <div className={classes.root}>
            <MaterialTable
                title={title || ""}
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: addable ?
                        (newData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    newData.updated = true;
                                    newData.id = _.last(data).id + 1;
                                    setData([...data, newData]);
                                    resolve();
                                }, 1000)
                            }) : null,
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                console.log(newData, oldData);
                                if (handleValidation(newData)) {
                                    reject();
                                } else {
                                    newData.updated = true;
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setData(dataUpdate);
                                    resolve();
                                }
                            }, 1000)
                        }),
                    onRowDelete: deletable ? oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                dataDelete.map((d, index) => d.id = index + 1);
                                setData([...dataDelete]);

                                resolve()
                            }, 1000)
                        }) : null,
                }}
                options={{
                    search: search,
                    addRowPosition: 'first',
                    selection: selection,
                    pageSize: pageSize,
                    emptyRowsWhenPaging: false
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}'
                    },
                    header: {
                        actions: ''
                    }
                }}
                onSelectionChange={(rows) => setSelectedData(rows)}
            />
        </div>);
};

export default EditableTable;
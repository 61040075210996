import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    tabs: {
        '& .MuiTab-textColorInherit': {
            color: theme.palette.text.main
        },
        '& .MuiTab-root': {
            backgroundColor: theme.palette.secondary.main,
            fontSize: "1rem"
        }
    }
}));
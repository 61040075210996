import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useStyles} from "./SwitchElementStyle";
import {useIntl} from "react-intl";

const SwitchElement = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const {
        label,
        edge,
        name,
        onChange,
        checked,
        inputProps
    } = props;

    return (
        <FormControlLabel
            control={
                <Switch edge={edge}
                        name={name}
                        classes={{
                            switchBase: classes.switchBase,
                            track: classes.track,
                            checked: classes.checked,
                        }}
                        onChange={onChange}
                        checked={checked}
                        inputProps={inputProps}/>
            }
            label={label ? intl.formatMessage({ id: label.translateKey, defaultMessage: label.defaultText }) : ''}
            labelPlacement={label ? label.position : 'end'} // top, bottom, start (left), end (right)
            className={classes.label}
        />
    );
};

export default SwitchElement;

import React from "react";
import {useStyles} from "./ReusableModalStyle";
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonElement from "../../ButtonElement/ButtonElement";


const ReusableModal = props => {
    const classes = useStyles();
    const intl = useIntl();
    const { reusableModal, submitClick, closeReusableModal, greenButtonClick, greenButtonText, redButtonClick, redButtonText, submitDisabled } = props;

    // console.log("DISABLED", submitDisabled, reusableModal);
    return (
        <Dialog
            open={reusableModal.open}
            onClose={closeReusableModal}
            className={reusableModal.big ? classes.bigRootDialog : classes.rootDialog}>

            <DialogTitle style={{ cursor: 'move' }} className={classes.title} disableTypography={true}>
                {reusableModal.title}
            </DialogTitle>

            <DialogContent className={classes.rootDialogContent}>
                {props.children}
            </DialogContent>

            <DialogActions>
                {redButtonClick ?
                    <ButtonElement cssType={'deactive'} label={redButtonText ? redButtonText : "Delete"}
                                   onSubmit={redButtonClick}/> : ''
                }
                {greenButtonClick ?
                    <ButtonElement cssType={'edit'} label={greenButtonText ? greenButtonText : "OK"}
                                   onSubmit={greenButtonClick}/> : ''
                }
                {submitClick ?
                    <ButtonElement
                        label={intl.formatMessage({ id: "common.button.label.submit", defaultMessage: "Submit" })}
                        disabled={submitDisabled} onSubmit={submitClick}/> : ''
                }
                <ButtonElement
                    label={intl.formatMessage({ id: "common.button.label.cancel", defaultMessage: "Cancel" })}
                    onSubmit={closeReusableModal}/>
            </DialogActions>
        </Dialog>
    )
};

export default ReusableModal;
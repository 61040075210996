import React from "react";
import {useStyles} from "./LinkElementBoxStyle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Icon} from "@material-ui/core";

const LinkElementBox = (props) => {
    const classes = useStyles();
    const {
        links,
        icon,
        selectedIndex,
        onClickHandler,
    } = props;
    //console.log(links, icon, onClickHandler, deleteLabel, renameLabel, deleteAction, renameAction)

    const linkBox = links.map((element, index) => {
        return (
            <ListItem
                key={index}
                button

                onClick={(id) => onClickHandler(element.guid)}
                selected={selectedIndex === element.guid}
            >
                {icon && <Icon fontSize="small" className={classes.icon}>
                    {icon}
                </Icon>}
                <ListItemText primary={element.favourite_name}
                              primaryTypographyProps={{ variant: 'body1' }}/>

            </ListItem>
        );
    });

    return (
        <List dense className={classes.root}>
            {linkBox}
        </List>
    );
};

export default LinkElementBox;

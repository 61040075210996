import {GET_APP_LOGS_FAILURE, GET_APP_LOGS_REQUEST, GET_APP_LOGS_SUCCESS} from "../actions/appLogs";

const initialState = {app_logs: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_APP_LOGS_REQUEST:
            return {
                loadingAppLogs: true
            };
        case GET_APP_LOGS_SUCCESS:
            return {
                ...state,
                loadingAppLogs: false,
                app_logs: action.app_logs
            };
        case GET_APP_LOGS_FAILURE:
            return {
                ...state,
                loadingAppLogs: false,
                error: action.error
            }
        default:
            return state;
    }
}
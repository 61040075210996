import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";

const localStorageService = LocalStorage.getService();

export const CLEAR_GALLERY_SORTING_ACTION = 'CLEAR_GALLERY_SORTING_ACTION';

export const clearGallerySortingAction = () => {
    return { type: CLEAR_GALLERY_SORTING_ACTION }
};

export const GET_GALLERIES_MACRO_AREAS_REQUEST = 'GET_GALLERIES_MACRO_AREAS_REQUEST';
export const GET_GALLERIES_MACRO_AREAS_SUCCESS = 'GET_GALLERIES_MACRO_AREAS_SUCCESS';
export const GET_GALLERIES_MACRO_AREAS_FAILED = 'GET_GALLERIES_MACRO_AREAS_FAILED';

export const getGalleriesMacroAreasRequest = () => {
    return { type: GET_GALLERIES_MACRO_AREAS_REQUEST };
};

export const getGalleriesMacroAreasSuccess = (macroAreas) => {
    return { type: GET_GALLERIES_MACRO_AREAS_SUCCESS, macroAreas };
};

export const getGalleriesMacroAreasFailed = (error) => {
    return { type: GET_GALLERIES_MACRO_AREAS_FAILED, error };
};

/**
 * Get information for sorting phase of creation/edit of a product gallery of macroAreas.
 *
 * @param galleryId {number}: id of gallery which user is going to define the automatic sorting rules.
 */
export const startGetProductShowroomGalleriesMacroAreas = (galleryId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetProductShowroomGalleriesMacroAreas with: galleryId=', galleryId);
    return (dispatch) => {
        dispatch(getGalleriesMacroAreasRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/search/orderableList?gallery=${galleryId}&key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetProductShowroomGalleriesMacroAreas: ', response.data);
                    dispatch(getGalleriesMacroAreasSuccess(response.data.grouping_keys));
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startGetProductShowroomGalleriesMacroAreas: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getGalleriesMacroAreasFailed(error));
            });
    }
};


export const CLEAR_SAVING_ORDER = 'CLEAR_SAVING_ORDER';
export const POST_GALLERIES_MACRO_AREAS_REQUEST = 'POST_GALLERIES_MACRO_AREAS_REQUEST';
export const POST_GALLERIES_MACRO_AREAS_SUCCESS = 'POST_GALLERIES_MACRO_AREAS_SUCCESS';
export const POST_GALLERIES_MACRO_AREAS_FAILED = 'POST_GALLERIES_MACRO_AREAS_FAILED';

export const clearSavingOrder = () => {
    return { type: CLEAR_SAVING_ORDER };
};

export const postGalleriesMacroAreasRequest = () => {
    return { type: POST_GALLERIES_MACRO_AREAS_REQUEST }
};

export const postGalleriesMacroAreasSuccess = () => {
    return { type: POST_GALLERIES_MACRO_AREAS_SUCCESS }
};

export const postGalleriesMacroAreasFailed = (error) => {
    return { type: POST_GALLERIES_MACRO_AREAS_FAILED, error }
};

/**
 * Define the filters key to extract the product list for the current gallery and the areas that will be use to order
 * automatically the gallery's product.
 *
 * @param galleryId {number}: id of gallery which user is going to define the automatic sorting rules.
 * @param filtersList {Array}: list of search filters to define the possible usable product list at the end of the step
 * of gallery creation.
 * @param macroAreasKey {Array}: list of grouping keys that the user have to define insight order.
 */
export const startPostProductShowroomGalleriesMacroAreas = (galleryId, editGallery, filtersList, macroAreasKey) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startPostProductShowroomGalleriesMacroAreas with: galleryId=', galleryId, ' editGallery=', editGallery, 'filtersList=', filtersList, ' macroAreasKey=', macroAreasKey);
    return (dispatch) => {
        dispatch(postGalleriesMacroAreasRequest());

        let body;
        if (filtersList) {
            body = JSON.stringify({ filter_list: filtersList, grouping_keys: macroAreasKey, edit: editGallery })
        } else {
            body = JSON.stringify({ grouping_keys: macroAreasKey, edit: editGallery })
        }

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/search?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: body
        })
            .then(function (response) {
                if (response.status === 201) {
                    console.log('Response startPostProductShowroomGalleriesMacroAreas: success');
                    dispatch(postGalleriesMacroAreasSuccess());
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startPostProductShowroomGalleriesMacroAreas: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postGalleriesMacroAreasFailed(error));
            });
    }
};

export const GET_GALLERY_MACRO_AREAS_VALUES_REQUEST = 'GET_GALLERY_MACRO_AREAS_VALUES_REQUEST';
export const GET_GALLERY_MACRO_AREAS_VALUES_SUCCESS = 'GET_GALLERY_MACRO_AREAS_VALUES_SUCCESS';
export const GET_GALLERY_MACRO_AREAS_VALUES_FAILED = 'GET_GALLERY_MACRO_AREAS_VALUES_FAILED';

export const getGalleryMacroAreasValuesRequest = () => {
    return { type: GET_GALLERY_MACRO_AREAS_VALUES_REQUEST }
};

export const getGalleryMacroAreasValuesSuccess = (macroAreasValues) => {
    return { type: GET_GALLERY_MACRO_AREAS_VALUES_SUCCESS, macroAreasValues }
};

export const getGalleryMacroAreasValuesFailed = (error) => {
    return { type: GET_GALLERY_MACRO_AREAS_VALUES_FAILED, error }
};

/**
 * Get information for sorting phase of creation/edit of a product gallery.
 *
 * @param galleryId {number}: id of gallery which user is going to define the automatic sorting rules.
 */
export const startGetProductShowroomGalleryMacroAreasValues = (galleryId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetProductShowroomGalleryMacroAreasValues with: galleryId=', galleryId);
    return (dispatch) => {
        dispatch(getGalleryMacroAreasValuesRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/search?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetProductShowroomGalleryMacroAreasValues: ', response.data);
                    dispatch(getGalleryMacroAreasValuesSuccess(response.data));
                } else if (response.status === 204) {
                    console.log('Response startGetProductShowroomGalleryMacroAreasValues: success but no values!');
                    dispatch(getGalleryMacroAreasValuesSuccess([]));
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startGetProductShowroomGalleryMacroAreasValues: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getGalleryMacroAreasValuesFailed(error));
            });
    }
};


export const POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_REQUEST = 'POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_REQUEST';
export const POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_SUCCESS = 'POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_SUCCESS';
export const POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_FAILED = 'POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_FAILED';

export const postGalleriesMacroAreasValuesOrderRequest = () => {
    return { type: POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_REQUEST }
};

export const postGalleriesMacroAreasValuesOrderSuccess = () => {
    return { type: POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_SUCCESS }
};

export const postGalleriesMacroAreasValuesOrderFailed = (error) => {
    return { type: POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_FAILED, error }
};


/**
 * Define the order of values relates to filters key to order automatically the gallery's product.
 *
 * @param searchUUID {string}: identifier of search to apply to the current gallery.
 * @param macroAreas {Array}: list of macro areas object with ordered values.
 */
export const startPostProductShowroomGalleriesMacroAreasValuesOrder = (searchUUID, macroAreas) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startPostProductShowroomGalleriesMacroAreas with: searchUUID=', searchUUID, ' macroAreas=', macroAreas);
    return (dispatch) => {
        dispatch(postGalleriesMacroAreasValuesOrderRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/search/${searchUUID}/order?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify({ grouping_keys: macroAreas })
        })
            .then(function (response) {
                if (response.status === 204) {
                    console.log('Response startPostProductShowroomGalleriesMacroAreas: success!');
                    dispatch(postGalleriesMacroAreasValuesOrderSuccess());
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startPostProductShowroomGalleriesMacroAreas: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postGalleriesMacroAreasValuesOrderFailed(error));
            });
    }
};


export const POST_GALLERY_PRODUCTS_ORDER_REQUEST = 'POST_GALLERY_PRODUCTS_ORDER_REQUEST';
export const POST_GALLERY_PRODUCTS_ORDER_SUCCESS = 'POST_GALLERY_PRODUCTS_ORDER_SUCCESS';
export const POST_GALLERY_PRODUCTS_ORDER_FAILED = 'POST_GALLERY_PRODUCTS_ORDER_FAILED';

export const postGalleryProductsOrderRequest = () => {
    return { type: POST_GALLERY_PRODUCTS_ORDER_REQUEST }
};

export const postGalleryProductsOrderSuccess = () => {
    return { type: POST_GALLERY_PRODUCTS_ORDER_SUCCESS }
};

export const postGalleryProductsOrderFailed = (error) => {
    return { type: POST_GALLERY_PRODUCTS_ORDER_FAILED, error }
};

/**
 * This function is used to persist the new products order.
 * @param galleryId {number}: gallery identifier.
 * @param productGuidList {Array}: list of product identifiers.
 */
export const startPostProductsShowroomGalleryOrder = (galleryId, productGuidList) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startPostProductsShowroomGalleryOrder with: productGuidList -->', productGuidList, ' galleryId -->', galleryId);
    return (dispatch) => {
        dispatch(postGalleryProductsOrderRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/products/order?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify({ products_order: productGuidList })
        })
            .then(function (response) {
                if (response.status === 204) {
                    console.log('Response startPostProductsShowroomGalleryOrder: success!');
                    dispatch(postGalleryProductsOrderSuccess());
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startPostProductsShowroomGalleryOrder: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postGalleryProductsOrderFailed(error));
            });
    }
};


export const POST_SWITCH_GALLERY_ORDER_REQUEST = 'POST_SWITCH_GALLERY_ORDER_REQUEST';
export const POST_SWITCH_GALLERY_ORDER_SUCCESS = 'POST_SWITCH_GALLERY_ORDER_SUCCESS';
export const POST_SWITCH_GALLERY_ORDER_FAILED = 'POST_SWITCH_GALLERY_ORDER_FAILED';

export const postSwitchGalleryOrderRequest = () => {
    return { type: POST_SWITCH_GALLERY_ORDER_REQUEST }
};

export const postSwitchGalleryOrderSuccess = () => {
    return { type: POST_SWITCH_GALLERY_ORDER_SUCCESS }
};

export const postSwitchGalleryOrderFailed = (error) => {
    return { type: POST_SWITCH_GALLERY_ORDER_FAILED, error }
};

/**
 * This function is used to persist the new products order.
 * @param galleryId {number}: gallery identifier.
 */
export const startPostSwitchGalleryOrder = (galleryId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startPostSwitchGalleryOrder with: galleryId -->', galleryId);
    return (dispatch) => {
        dispatch(postSwitchGalleryOrderRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/products/order/toggle?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 204) {
                    console.log('Response startPostSwitchGalleryOrder: success!');
                    dispatch(postSwitchGalleryOrderSuccess());
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startPostSwitchGalleryOrder: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postSwitchGalleryOrderFailed(error));
            });
    }
};


export const CLEAR_EXCEL_INFO = 'CLEAR_EXCEL_INFO';
export const GET_EXCEL_INFO_REQUEST = 'GET_EXCEL_INFO_REQUEST';
export const GET_EXCEL_INFO_SUCCESS = 'GET_EXCEL_INFO_SUCCESS';
export const GET_EXCEL_INFO_FAILED = 'GET_EXCEL_INFO_FAILED';

export const clearExcelInfos = () => {
    return { type: CLEAR_EXCEL_INFO }
};

export const getExcelInfoRequest = () => {
    return { type: GET_EXCEL_INFO_REQUEST }
};

export const getExcelInfoSuccess = (xlsxUrl) => {
    return { type: GET_EXCEL_INFO_SUCCESS, xlsxUrl }
};

export const getExcelInfoFailed = (error) => {
    return { type: GET_EXCEL_INFO_FAILED, error }
};

/**
 * This function is used to get the url of excel to download.
 * @param galleryId {number}: gallery identifier.
 */
export const startGetExcelInfo = (galleryId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetExcelInfo with: galleryId -->', galleryId);
    return (dispatch) => {
        dispatch(getExcelInfoRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/excel?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetExcelInfo: ', response.data);
                    dispatch(getExcelInfoSuccess(response.data.xlsx_url));
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startGetExcelInfo: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getExcelInfoFailed(error));
            });
    }
};

import {
    CLEAR_EXCEL_INFO,
    CLEAR_GALLERY_SORTING_ACTION,
    CLEAR_SAVING_ORDER,
    GET_EXCEL_INFO_FAILED,
    GET_EXCEL_INFO_REQUEST,
    GET_EXCEL_INFO_SUCCESS,
    GET_GALLERIES_MACRO_AREAS_FAILED,
    GET_GALLERIES_MACRO_AREAS_REQUEST,
    GET_GALLERIES_MACRO_AREAS_SUCCESS,
    GET_GALLERY_MACRO_AREAS_VALUES_FAILED,
    GET_GALLERY_MACRO_AREAS_VALUES_REQUEST,
    GET_GALLERY_MACRO_AREAS_VALUES_SUCCESS,
    POST_GALLERIES_MACRO_AREAS_FAILED,
    POST_GALLERIES_MACRO_AREAS_REQUEST,
    POST_GALLERIES_MACRO_AREAS_SUCCESS,
    POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_FAILED,
    POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_REQUEST,
    POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_SUCCESS,
    POST_GALLERY_PRODUCTS_ORDER_FAILED,
    POST_GALLERY_PRODUCTS_ORDER_REQUEST,
    POST_GALLERY_PRODUCTS_ORDER_SUCCESS,
    POST_SWITCH_GALLERY_ORDER_FAILED,
    POST_SWITCH_GALLERY_ORDER_REQUEST,
    POST_SWITCH_GALLERY_ORDER_SUCCESS
} from "../../../actions/showroom/product/galleryActions";
import {PRODUCT_SHOWROOM_ACTION_REQUEST} from "../../../../utils/constant";

const initialState = {
    loading: false,
    macroAreas: [],
    macroAreasValues: [],
    excelUrl: null,
    action: null,
    error: null
};


export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_GALLERY_SORTING_ACTION:
            return initialState;
        case CLEAR_SAVING_ORDER:
            return {
                ...state,
                loading: false,
                action: null,
                error: null
            };
        case CLEAR_EXCEL_INFO:
            return {
                ...state,
                loading: false,
                action: action === PRODUCT_SHOWROOM_ACTION_REQUEST.getExcelUrl ? null : action,
                excelUrl: null
            };
        case POST_GALLERIES_MACRO_AREAS_REQUEST:
            return {
                ...state,
                loading: true,
                action: PRODUCT_SHOWROOM_ACTION_REQUEST.postMacroAreas
            };
        case POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                action: PRODUCT_SHOWROOM_ACTION_REQUEST.postOrderValues
            };
        case GET_GALLERIES_MACRO_AREAS_REQUEST:
            return {
                ...state,
                loading: true,
                action: PRODUCT_SHOWROOM_ACTION_REQUEST.getMacroAreas
            };
        case GET_GALLERY_MACRO_AREAS_VALUES_REQUEST:
            return {
                ...state,
                loading: true,
                action: PRODUCT_SHOWROOM_ACTION_REQUEST.getMacroAreasValues
            };
        case POST_GALLERY_PRODUCTS_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                action: PRODUCT_SHOWROOM_ACTION_REQUEST.postProductOrder
            };
        case POST_SWITCH_GALLERY_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                action: PRODUCT_SHOWROOM_ACTION_REQUEST.postSwitchGalleryOrder
            };
        case GET_EXCEL_INFO_REQUEST:
            return {
                ...state,
                action: PRODUCT_SHOWROOM_ACTION_REQUEST.getExcelUrl,
                loading: true
            };
        case POST_GALLERIES_MACRO_AREAS_SUCCESS:
        case POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_SUCCESS:
        case POST_GALLERY_PRODUCTS_ORDER_SUCCESS:
        case POST_SWITCH_GALLERY_ORDER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case GET_GALLERIES_MACRO_AREAS_SUCCESS:
            return {
                ...state,
                loading: false,
                macroAreas: action.macroAreas
            }
        case GET_GALLERY_MACRO_AREAS_VALUES_SUCCESS:
            return {
                ...state,
                loading: false,
                macroAreasValues: action.macroAreasValues
            };
        case GET_EXCEL_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                excelUrl: action.xlsxUrl
            };
        case POST_GALLERIES_MACRO_AREAS_FAILED:
        case POST_GALLERIES_MACRO_AREAS_VALUES_ORDER_FAILED:
        case GET_GALLERIES_MACRO_AREAS_FAILED:
        case GET_GALLERY_MACRO_AREAS_VALUES_FAILED:
        case POST_GALLERY_PRODUCTS_ORDER_FAILED:
        case POST_SWITCH_GALLERY_ORDER_FAILED:
        case GET_EXCEL_INFO_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import {connect} from 'react-redux';
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import EditableTable from "../components/TableElement/EditableTable/EditableTable";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {onErrorSrc, renderTableResult, createSearchBodyRequest} from "../utils/CommonFunction";
import JSZip from "jszip";
import FileSaver, {saveAs} from "file-saver";
import * as Excel from "exceljs";
import DropzoneDialogElement from "../components/DropzoneDialogElement/DropzoneDialogElement";
import DropDownElement from "../components/DropDownElement/DropDownElement";
import {
    clearSessioniOrfane,
    startUpdateSessioniOrfane,
    startUploadXlsxFile,
    startDeleteDrafts,
    startSearchSessioniOrfane
} from "../store/actions/sessioniOrfane/sessioniOrfane";
import { SEARCH_ASSETS_ACTIONS, DROPDOWN_UNRECOGNIZED_ACTIONS } from "../utils/constant";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import ReusableModal from "../components/Modal/ReusableModal/ReusableModal";
import LocalStorage from "../store/localStorage";

const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(12, 0, 0, 0),
        flexGrow: 1,
        // '& .MuiPaper-root': {
        //     backgroundColor: 'inherit',
        //     color: theme.palette.text.main
        // }
    },
    header: {
        backgroundColor: theme.palette.secondary.main
    },
    imgTable: {
        height: "140px",
        width: "100px",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    },
    success: {
        color: theme.palette.success.main
    }
}));

const localStorageService = LocalStorage.getService();

const SessioniOrfaneContainer = props => {
    const classes = useStyles();
    const intl = useIntl();

    const [columnsData, setColumnsData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [snackBar, setSnackBar] = useState({open: false, type: "error", label: "", labelId: ""});
    const [imageBlobs, setImageBlobs] = useState([]);
    const [dropzoneDialogOpen, setDropzoneDialogOpen] = useState(false);
    const [actionSelected, setActionSelected] = useState("");
    const [deleteDraftsModalStatus, setDeleteDraftsModalStatus] = useState({
        open: false,
        title: intl.formatMessage({ id: 'sessioni.orfane.dialog.title.delete.drafts', defaultMessage: 'Delete drafts' })
    });

    const columns = [{
        title: intl.formatMessage({'id': 'sessioni.orfane.table.column.file.name', 'defaultMessage': 'File Name'}),
        field: 'img_url',
        editable: 'never',
        render: rowData => (
            <img src={rowData.img_url ? rowData.img_url : 'missing url'}
                 alt={"Image not found"}
                 className={classes.imgTable}
                 onError={(event) => onErrorSrc(event)}/>
        ),
    },
        {
            title: intl.formatMessage({
                'id': 'sessioni.orfane.table.column.richiedente',
                'defaultMessage': 'Richiedente'
            }),
            field: 'richiedente',
            editable: 'never'
        },
        {
            title: intl.formatMessage({'id': 'sessioni.orfane.table.column.season', 'defaultMessage': 'Season'}),
            field: 'season_description',
            editable: 'never'
        },
        {
            title: intl.formatMessage({'id': 'sessioni.orfane.table.column.service', 'defaultMessage': 'Service'}),
            field: 'service',
            editable: 'never'
        },
        {
            title: intl.formatMessage({
                'id': 'sessioni.orfane.table.column.draft.name',
                'defaultMessage': 'Draft Name'
            }),
            field: 'draft_name',
            editable: 'never'
        },
        {
            title: intl.formatMessage({
                'id': 'load.master.data.table.column.product.code',
                'defaultMessage': 'Product Code'
            }), field: 'style_fabric_color',
        },
        {
            title: intl.formatMessage({'id': 'sessioni.orfane.table.column.result', 'defaultMessage': 'Result'}),
            field: 'result',
            editable: 'never',
            render: (rowData) => renderTableResult(rowData, classes)
        }];

    const excelKeys = [
        {
            title: intl.formatMessage({
                id: 'sessioni.orfane.excel.product_guid',
                defaultMessage: 'Product GUID'
            }),
            field: 'product_guid'
        },
        {
            title: intl.formatMessage({
                id: 'sessioni.orfane.excel.richiedente',
                defaultMessage: 'Richiedente'
            }),
            field: 'richiedente'
        },
        {
            title: intl.formatMessage({id: 'sessioni.orfane.excel.service', defaultMessage: 'Service'}),
            field: 'service',
        },
        {
            title: intl.formatMessage({id: 'sessioni.orfane.excel.season', defaultMessage: 'Season'}),
            field: 'season_description'
        },
        {
            title: intl.formatMessage({
                id: 'sessioni.orfane.excel.season_code',
                defaultMessage: 'Season Code'
            }),
            field: 'season_code'
        },
        {
            title: intl.formatMessage({
                id: 'sessioni.orfane.excel.draft.name',
                defaultMessage: 'Draft Name'
            }),
            field: 'draft_name'
        },
        {
            title: intl.formatMessage({
                id: 'sessioni.orfane.excel.unique_product_code',
                defaultMessage: 'Unique Product Code'
            }),
            field: 'unique_style_fabric_color'
        },
        {
            title: intl.formatMessage({
                'id': 'sessioni.orfane.excel.product_code',
                'defaultMessage': 'Product Code'
            }),
            field: 'style_fabric_color',
        }];


    useEffect(() => {
        if (props.sessioniOrfaneResults && props.sessioniOrfaneResults.length > 0) {
            console.log("Eccoci qui: ", props.sessioniOrfaneResults, props.location.state);
            let indexedData = _.cloneDeep(props.sessioniOrfaneResults);
            indexedData.map((d, index) => d.id = index + 1);
            setColumnsData(indexedData);
        } else if (!props.updatedResults || props.updatedResults.length === 0) {
            console.log("why ??? , ", props.sessioniOrfaneResults, props.updatedResults);
            props.history.push({pathname: "/sessioni-orfane"});
        }
    }, [props.sessioniOrfaneResults]);

    useEffect(() => {
        if (props.updatedResults && props.updatedResults.length > 0) {
            console.log("Eccoci qui AGAIN: ", props.updatedResults);
            let indexedData = _.cloneDeep(props.updatedResults);
            indexedData.map((d, index) => d.id = index + 1);
            setColumnsData(indexedData);
        }
    }, [props.updatedResults]);

    useEffect(() => {
        if ( props.uploadError ) {
            console.log("There was an error during upload:", props.uploadError);
            
            setSnackBar({
                open: true,
                type: "error",
                label: props.uploadError.Error,
                labelId: ""
            });
        }
    }, [props.uploadError]);

    useEffect(() => {
        if (props.uploadFileResults && props.uploadFileResults.length > 0) {
            let indexedData = _.cloneDeep(props.uploadFileResults);
            indexedData.map((d, index) => d.id = index + 1);
            setColumnsData(indexedData);
        }
    }, [props.uploadFileResults])

    useEffect(() => {
        console.log("IT'S CHANGEDDDDDDDDDD ", columnsData, _.some(columnsData, el => el.updated));
        let data = columnsData.filter(el => el.updated && el.style_fabric_color !== "");
        console.log("RIGHT??? ", data);
        setSelectedData(data);
    }, [columnsData])

    useEffect(() => {
        // console.log(imageBlobs);
        if (imageBlobs.length === selectedData.length && imageBlobs.length > 0) {
            console.log(imageBlobs);
            let zip = new JSZip();
            let img = zip.folder("images");
            imageBlobs.map(i => i.size !== 0 && img.file(i.name, i, {base64: true}));
            zip.generateAsync({type: "blob"}).then(function (content) {
                FileSaver.saveAs(content, "selectedImagesOrfani.zip");
            });
        }
    }, [imageBlobs]);

    const downloadImages = () => {
        let selectedImages = selectedData.map(d => {
            console.log("Selected image to be exported: ", d);
            return {
                url: d.img_url,
                name: d.style_fabric_color === null || d.style_fabric_color.length === 0 ? d.draft_name : d.style_fabric_color,
            };
        });
        let blobs = [];
        selectedImages.map(
            elem => fetch(elem.url)
                .then(function (response) {
                    return response.blob()
                })
                .then(function (blob) {
                    let imageBlob = new File([blob], elem.name + ".jpg", {type: "image/jpg"});
                    blobs.push(imageBlob);
                    if (blobs.length === selectedData.length)
                        setImageBlobs(blobs);
                    console.log(imageBlob, blobs);
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    };

    /**
     * [DOWNLOAD] Used this function to download data selected on web application table. The result is an excel file.
     */
    const downloadExcel = () => {
        const filename = 'sessioniOrfane.xlsx';
        const workbook = new Excel.Workbook();
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        const worksheet = workbook.addWorksheet('Sheet 1');
        worksheet.columns = excelKeys.map(excelKey => ({
            header: excelKey.title,
            key: excelKey.field
        }));

        selectedData.forEach(index => {
            worksheet.addRow(index);
        });
        
        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {type: blobType});
            saveAs(blob, filename);
        });
    };
    
    /*
     * [DELETE] This function shows the popup to confirm the deletion of selected drafts.
     */
    const deleteSelectedDraftsHandler = () => {
        setDeleteDraftsModalStatus(prevState => ({
            ...prevState,
            open: true
        }));
    };
    
    const confirmDeleteAssets = () => {
        setDeleteDraftsModalStatus(prevState => ({
            ...prevState,
            open: false
        }));
        props.deleteAssets(selectedData.map(data => data.product_guid));
    };

    useEffect(() => {
        if ( props.deleteError ) {
            console.log("There was an error during delete:", props.deleteError);
            
            setSnackBar({
                open: true,
                type: "error",
                label: props.deleteError.Error || props.deleteError,
                labelId: ""
            });
        }
    }, [props.deleteError]);

    useEffect(() => {
        if ( props.deleteSuccess ) {
            console.log("Drafts deleted successfully.");
            setSnackBar({
                open: true,
                type: "success",
                label: "Operation completed successfully.",
                labelId: "common.operation.success"
            })
            
            const currentSearch = localStorageService.getCurrentSearch();
            
            const bodyRequest = { filter_list: createSearchBodyRequest(currentSearch) };
            props.searchSessioniOrfane(bodyRequest);
        }
    }, [props.deleteSuccess]);

    const sendUpdatedData = () => {
        console.log("sending updated data", selectedData);
        let updatedData = _.cloneDeep(selectedData);
        console.log("ciaooo", updatedData);
        props.updateSessioniOrfane(updatedData);
    }

    const handleValidation = (row) => {
        console.log("VALIDATION: ", row);
        if (row.style_fabric_color === "") {
            setSnackBar({
                open: true,
                type: "error",
                label: "The input cannot be empty",
                labelId: "common.table.edit.error.label"
            })
            return true;
        } else {
            setSnackBar({open: false, type: "error", label: "", labelId: ""});
            return false;
        }
    }

    /**
     * This function is used to set new action selected by user but also activate/deactivate the button based on action
     * type and number of assets selected into table by user.
     */
    const selectActionHandler = (event) => {
        switch (event.target.value) {
            case SEARCH_ASSETS_ACTIONS.downloadExcel:
            case SEARCH_ASSETS_ACTIONS.downloadImages:
            case SEARCH_ASSETS_ACTIONS.deleteAssets:
                setActionSelected(event.target.value);
                break;
            default:
                setSnackBar({
                    open: true,
                    type: "error",
                    label: "Action selected is unknown.",
                    labelId: "search.action.error"
                });
                break;
        }
    };

    /**
     * This function is used to call the right API based on the current action selected by user.
     */
        const confirmActionHandler = (event) => {
        switch (actionSelected) {
            case SEARCH_ASSETS_ACTIONS.downloadExcel:
                downloadExcel();
                break;
            case SEARCH_ASSETS_ACTIONS.downloadImages:
                downloadImages();
                break;
            case SEARCH_ASSETS_ACTIONS.deleteAssets:
                deleteSelectedDraftsHandler();
                break;
            default:
                setSnackBar({
                    open: true,
                    type: "error",
                    label: "Action selected is unknown.",
                    labelId: "search.action.error"
                });
                break;
        }
    };

    const onCancelOrfaniSearch = () => {
        props.clearSessioniOrfane();
        props.history.push({pathname: "/sessioni-orfane"})
    }

    const uploadDraftsByXlsx = (file) => {
        console.log('alura? ', props.auth.user_id);
        let guid = props.auth.user_id || "";
        props.updateXlsxFile(file, guid);
        setDropzoneDialogOpen(false);
    };


    return (
        <div className={classes.root}>
            <SnackbarElement
                open={snackBar.open}
                type={snackBar.type}
                label={snackBar.label}
                labelId={snackBar.labelId}
                handleClose={() => setSnackBar((prevState) => ({ ...prevState, open: false}))}
            />
            <BackdropElement loading={props.loadingUpdate || props.loadingUploadByFile || props.loadingSearch || props.loadingDelete}/>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={4}>
                <Grid item xs={12}>
                    <EditableTable
                        data={columnsData}
                        setData={setColumnsData}
                        columns={columns}
                        selection={true}
                        addable={false}
                        pageSize={10}
                        search={true}
                        title={intl.formatMessage({
                            'id': 'sessioni.orfane.table.title.drafts.list',
                            'defaultMessage': 'Drafts List'
                        })}
                        setSelectedData={setSelectedData}
                        handleValidation={handleValidation}/>
                </Grid>
                <Grid container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                    <Grid item xs={2}>
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "common.button.label.cancel",
                                defaultMessage: 'Cancel'
                            })}
                            cssType={"cancel"}
                            onSubmit={onCancelOrfaniSearch}
                            disabled={false}/>
                    </Grid>
                    <Grid item xs={3} lg={2}>
                                <DropDownElement
                                    name="actions"
                                    value={actionSelected}
                                    label={{
                                        translateKey: "search.button.actions",
                                        defaultText: "Actions",
                                    }}
                                    onChangeHandler={selectActionHandler}
                                    options={DROPDOWN_UNRECOGNIZED_ACTIONS.map(dropdownAction => ({
                                        value: dropdownAction.actionValue,
                                        label: intl.formatMessage({
                                            id: dropdownAction.actionLabelId,
                                            defaultMessage: dropdownAction.actionLabelDefault
                                        })
                                    }))}
                                />
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "common.confirm",
                                defaultMessage: "Confirm",
                            })}
                            onSubmit={confirmActionHandler}
                            disabled={selectedData.length === 0 || _.isEmpty(actionSelected)}/>
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "common.button.label.upload.file",
                                defaultMessage: 'Upload File'
                            })}
                            onSubmit={() => setDropzoneDialogOpen(true)}
                            disabled={false}/>
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "common.button.label.submit",
                                defaultMessage: 'Submit'
                            })}
                            onSubmit={sendUpdatedData}
                            loading={props.loadingUpdate}
                            disabled={columnsData.filter(el => el.updated && el.style_fabric_color === "").length > 0 || !_.some(columnsData, el => el.updated)}/>
                    </Grid>
                </Grid>
                <DropzoneDialogElement
                    acceptedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    dialogTitle={{labelId: "sessioni.orfane.dropzone.dialog.title", label: "Upload Drafts List"}}
                    filesLimit={1}
                    cancelButtonText={{labelId: "common.button.label.cancel", label: "Cancel"}}
                    submitButtonText={{labelId: "common.button.label.submit", label: "Submit"}}
                    maxFileSize={50000000}
                    dropzoneDialogOpen={dropzoneDialogOpen}
                    setDropzoneDialogOpen={setDropzoneDialogOpen}
                    saveFileHandler={uploadDraftsByXlsx}
                    chipPreview={true}
                    showPreviews={true}/>
                {
                    deleteDraftsModalStatus.open &&
                    <ReusableModal reusableModal={deleteDraftsModalStatus}
                                   greenButtonClick={() => confirmDeleteAssets()}
                                   greenButtonText={intl.formatMessage({
                                       id: 'common.confirm',
                                       defaultMessage: 'Confirm'
                                   })}
                                   closeReusableModal={() => setDeleteDraftsModalStatus((prevState => ({
                                       ...prevState,
                                       open: false
                                   })))}>
                        <Typography>
                            {
                                intl.formatMessage({
                                    id: 'sessioni.orfane.dialog.body.delete.drafts',
                                    defaultMessage: 'Delete permanently selected drafts?'
                                })
                            }
                        </Typography>
                    </ReusableModal>
                }
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    auth: state.authReducer.auth,
    sessioniOrfaneResults: state.sessioniOrfaneReducer.results,
    updatedResults: state.sessioniOrfaneReducer.updatedResults,
    loadingUpdate: state.sessioniOrfaneReducer.loadingUpdate,
    loadingUploadByFile: state.sessioniOrfaneReducer.loadingUploadByFile,
    loadingDelete: state.sessioniOrfaneReducer.loadingDelete,
    loadingSearch: state.sessioniOrfaneReducer.loadingSearch,
    deleteError: state.sessioniOrfaneReducer.deleteError,
    deleteSuccess: state.sessioniOrfaneReducer.deleteSuccess,
    uploadFileResults: state.sessioniOrfaneReducer.uploadFileResults,
    uploadError: state.sessioniOrfaneReducer.uploadError
});

const mapDispatchToProps = dispatch => ({
    updateSessioniOrfane: (data) => dispatch(startUpdateSessioniOrfane(data)),
    clearSessioniOrfane: () => dispatch(clearSessioniOrfane()),
    updateXlsxFile: (file, guid) => dispatch(startUploadXlsxFile(file, guid)),
    deleteAssets: (data) => dispatch(startDeleteDrafts(data)),
    searchSessioniOrfane: (body) => dispatch(startSearchSessioniOrfane(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(SessioniOrfaneContainer);
import axios from "axios";
import {loggedOut} from "../../auth";
import {startGetShowroomGalleries} from "./galleries";
import LocalStorage from "../../../localStorage";

const localStorageService = LocalStorage.getService();

export const POST_LOOKCAST_EXPORT_REQUEST = 'POST_LOOKCAST_EXPORT_REQUEST';
export const POST_LOOKCAST_EXPORT_SUCCESS = 'POST_LOOKCAST_EXPORT_SUCCESS';
export const POST_LOOKCAST_EXPORT_FAILED = 'POST_LOOKCAST_EXPORT_FAILED';
export const CLEAR_LOOKCAST_EXPORT = 'CLEAR_LOOKCAST_EXPORT';

export const postExportRequest = () => {
    return { type: POST_LOOKCAST_EXPORT_REQUEST }
};

export const postExportSuccess = (result) => {
    return { type: POST_LOOKCAST_EXPORT_SUCCESS, result }
};

export const postExportFailed = (error) => {
    return { type: POST_LOOKCAST_EXPORT_FAILED, error }
};

export const clearLookcastExport = () => {
    return { type: CLEAR_LOOKCAST_EXPORT }
};

export const startLookcastExport = (contextId, galleryId, filter) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startLookcastExport with: \n- contextId: ', contextId, '\n- galleryId: ', galleryId);
    return (dispatch) => {
        dispatch(postExportRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/galleries/${galleryId}/export?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            }
        })
            .then(function (response) {
                if (response.status === 201) {
                    console.log('Response startLookcastExport: ', response.data);
                    dispatch(postExportSuccess(response.data));
                    dispatch(startGetShowroomGalleries(contextId, filter));
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startLookcastExport: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postExportFailed(error));
            });
    }
};
import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const GET_SEASONALITY_REQUEST = 'GET_SEASONALITY_REQUEST';
export const GET_SEASONALITY_SUCCESS = 'GET_SEASONALITY_SUCCESS';
export const GET_SEASONALITY_FAILURE = 'GET_SEASONALITY_FAILURE';

const localStorageService = LocalStorage.getService();

export const getSeasonalityRequest = () => {
    return {type: GET_SEASONALITY_REQUEST}
};

export const getSeasonalitySuccess = (seasonality = {}) => {
    return {type: GET_SEASONALITY_SUCCESS, seasonality}
};

export const getSeasonalityFailure = (error) => {
    return {type: GET_SEASONALITY_FAILURE, error}
};

export const startGetSeasonality = () => {
    console.log('Start startGetSeasonality');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getSeasonalityRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/seasonality/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({
                }),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response seasonality search ", response.data);
                    dispatch(getSeasonalitySuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getSeasonalityFailure(error));
            });
    };
};

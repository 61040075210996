import {GET_SEARCH_CONTEXT_SUCCESS, GET_SEARCH_CONTEXT_REQUEST} from "../../actions/search/search_context";

const initialState = {searchContext: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SEARCH_CONTEXT_REQUEST:
            return {
                loadingSearchContext: true
            };
        case GET_SEARCH_CONTEXT_SUCCESS:
            return {
                ...state,
                loadingSearchContext: false,
                searchContext: action.searchContext,
            };
        default:
            return state;
    }
}
import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {LeftSideBarUpper} from "../components/LeftSideBarUpper/LeftSideBarUpper";
import {connect} from "react-redux";
import {startGetContextSearch} from "../store/actions/search/search_context";
import _ from "lodash";
import SearchFieldTable from "../components/TableElement/SearchFieldTable/SearchFieldTable";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage, useIntl} from "react-intl";
import {search_types_mapping} from "../assets/resources/search_types_mapping";
import {startGetSeasonality} from "../store/actions/search/seasonality";
import {startGetDressOrigin} from "../store/actions/search/dress_origin";
import {startGetRichiedente} from "../store/actions/richiedente";
import {startGetBrand} from "../store/actions/brand";
import {startGetL1Category} from "../store/actions/search/l1_category";
import {startGetGaoDescription} from "../store/actions/search/gao_description";
import {startGetPimStatus} from "../store/actions/search/pim_status";
import {startGetGender} from "../store/actions/gender";
import {startGetLabel} from "../store/actions/search/label";
import {startGetL2ProductGrouping} from "../store/actions/search/l2_product_grouping";
import {startGetL3Product} from "../store/actions/search/l3_product";
import {startGetAssetType} from "../store/actions/search/asset_type"
import {startGetSeasons} from "../store/actions/season";
import {startGetService,clearServices} from "../store/actions/service";
import {startGetPhotolab} from "../store/actions/photolab";
import {startGetSet} from "../store/actions/search/set";
import {startGetStatus} from "../store/actions/search/status";
import {startGetShootType} from "../store/actions/search/shoot_type";
import {startGetDimension} from "../store/actions/search/dimension";
import {startGetView} from "../store/actions/view";
import {startGetLinelist} from "../store/actions/linelist";
import {startGetFamilyName} from "../store/actions/family_name";
import SearchMultiLineInputModal from "../components/Modal/SearchMultiLineInputModal/SearchMultiLineInputModal";
import ReusableModal from "../components/Modal/ReusableModal/ReusableModal";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import {clearResponseSearch, startPostSearchWithCountRequest,} from "../store/actions/search/search";
import {withRouter} from "react-router-dom";
import InputTextElement from "../components/InputTextElement/InputTextElement";
import Typography from "@material-ui/core/Typography";
import RadioButtonGroup from "../components/RadioButtonGroup/RadioButtonGroup";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import {
    clearFavouriteDetails,
    startCreateFavourite,
    startDeleteFavourite,
    startGetFavouriteByGuid,
    startGetFavourites,
    startRenameFavourite,
    startUpdateFavourite,
} from "../store/actions/favourites";
import {
    startGetGallerySearch
} from "../store/actions/showroom/product/gallery.js";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import {clearSessioniOrfane, startSearchSessioniOrfane,} from "../store/actions/sessioniOrfane/sessioniOrfane";
import {createSearchBodyRequest, filterActiveSeasons} from "../utils/CommonFunction";
import LocalStorage from "../store/localStorage";
import {ACTION_REQUEST, SEARCH_APPLICATION, SEARCH_VIEWS} from "../utils/constant";
import {startGetExportStatus} from "../store/actions/exportStatus";
import {clearPostProduction} from "../store/actions/postProduction/postProduction";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(12),
        flexGrow: 1,
    },
    searchTableRoot: {
        height: "65vh",
    },
    tallButton: {
        '& .MuiButton-root': {
            height: 'unset !important',
            minHeight: theme.spacing(4.5),
        }
    }
}));

const localStorageService = LocalStorage.getService();

const SearchContainer = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    
    const [currentContext, setCurrentContext] = useState({
        contextType: "",
        contextKeys: [],
    });
    const [contextOptions, setContextOptions] = useState([]);
    const [allContexts, setAllContexts] = useState([]);
    /**
     * const [contextFilters, setContextFilters] = useState([{search_type_guid: "", name: "", operators: [], search_type: "", search_operator_guid: "", operator: "", and_or: "", value: stringa, lista, booleano}]);
     */
    const [contextFilters, setContextFilters] = useState([]);
    const [view, setView] = useState("");
    const [favouritesLinks, setFavouritesLinks] = useState([]);
    const [editGuid, setEditGuid] = useState("");
    const [multipleInputDialog, setMultipleInputDialog] = useState({
        rowIndex: null,
        open: false,
        error: false,
        errorText: null,
        title: intl.formatMessage({
            id: "search.dialog.title",
            defaultMessage: "Insert Multiple Values",
        }),
    });
    const [reusableModal, setReusableModal] = useState({
        favName: "",
        open: false,
        title: "",
        type: "add",
    });
    const [snackBar, setSnackBar] = useState({
        open: false,
        type: "error",
        label: "",
        labelId: "",
    });
    const [applicationStatus, setApplicationStatus] = useState('');
    const [buttonDisabledStatus, setButtonDisabledStatus] = useState(true);


    const dividerList = [
        { value: "\n", label: "NewLine" },
        { value: ";", label: "Semi Colon" },
    ];

    /**
     * This is the first effect used to initialize state about which application we are using and get favourites.
     * During unmount some redux status are cleared.
     */
    useEffect(() => {
        // TODO: probabilmente avrebbe più senso pulire gli stati quando si torna sulla homepage se e soltanto se questi
        //  due stati sono popolati.
        props.clearSessioniOrfane();
        props.clearResponseSearch();
        props.clearServices();

        if (props.location.pathname === "/sessioni-orfane") {
            setApplicationStatus(SEARCH_APPLICATION.sessioniOrfane);
            props.getContextSearch();
        } 
        else if (props.location.pathname === "/showroom-product/new-gallery") {
            setApplicationStatus(SEARCH_APPLICATION.productShowroom);
            props.getFavourites(SEARCH_APPLICATION.productShowroom);
            props.getContextSearch(SEARCH_APPLICATION.productShowroom);
        } 
        else if (props.location.pathname.startsWith("/showroom-product/edit-gallery")) {
            setApplicationStatus(SEARCH_APPLICATION.editGallery);    
            props.getFavourites(SEARCH_APPLICATION.search);
            props.getContextSearch(SEARCH_APPLICATION.search); 
            props.getGallerySearch(props.gallery.gallery.id);       
        }
        else {
            setApplicationStatus(SEARCH_APPLICATION.search);
            props.getFavourites(SEARCH_APPLICATION.search);
            props.getContextSearch(SEARCH_APPLICATION.search);
            props.clearPostProduction();
        }


        return () => {
            console.log('CLEARING!!! ', props.location.pathname);
            props.clearServices();

            if (props.location.pathname === "/sessioni-orfane") {

            } else if (props.location.pathname === "/showroom-product/new-gallery") {
                props.clearFavourite();
            } else {
                props.clearFavourite();
            }
        }
    }, []);


    useEffect(() => {
        let currentSearch = localStorageService.getCurrentSearch();
        if ((props.searchContext && currentSearch === "") || (props.searchContext && allContexts.length === 0)) {
            setAllContexts(Array.from(props.searchContext));
            // console.log(allContexts);
        } else if (props.searchContext && currentSearch) {
            // let lastSearch = JSON.parse(currentSearch);
            console.log(currentSearch);
            let filters = _.cloneDeep(currentSearch);
            let contextsArr = _.cloneDeep(props.searchContext);
            setContextFilters(filters);
            filters.forEach((f) => {
                contextsArr.forEach(function (c) {
                    if (c.search_area_guid === f.search_area) {
                        c["keys"] = c["keys"].filter((k) => k.name !== f.name);
                        console.log(c["keys"]);
                    }
                });
            });
            setAllContexts(contextsArr);
            console.log(
                "YOUR LAST SEARCH WAS: ",
                filters,
                "AND ALL CONTEXTS NOW IS: ",
                contextsArr
            );
        }
    }, [props.searchContext]);

    /**
     * This effect is used to set a new clear search (clean context and new values for context and relatives keys) or
     * eventually, if necessary, clear localstorage.
     */
    useEffect(() => {
        if (contextFilters.length === 0 && props.searchContext) {
            setCurrentContext({ contextType: "", contextKeys: [] });
            setAllContexts(_.cloneDeep(props.searchContext));
        } else if (contextFilters.length > 0 && props.searchContext) {
            localStorageService.clearCurrentSearch();
            console.log("BYE ");
        }
    }, [contextFilters]);

    useEffect(() => {
        //every time the contextkeys change the contextValues need to change too
        if (allContexts && currentContext.contextType !== "") {
            let keys = allContexts.filter(
                (c) => c.search_area_guid === currentContext.contextType
            )[0].keys;
            let contextsKeysValues = _.toArray(
                _.mapValues(keys, function (o) {
                    return {
                        label: intl.formatMessage({
                            id: o.external_name,
                            defaultMessage: "Context Not Found",
                        }),
                        value: o.name,
                    };
                })
            );
            console.log("im here!!!, ", contextsKeysValues);
            setContextOptions(contextsKeysValues);
        }
    }, [currentContext.contextKeys]);

    /**
     * This effect is used to handler products/assets search response.
     */
    useEffect(() => {
        if (props.searchResults.searchExecuted && !props.searchResults.loadingSearchRequest 
        && !props.searchResults.loadingCountRequest && props.searchResults.action === ACTION_REQUEST.post) {
            if (props.searchResults.error) {
                setSnackBar({
                    open: true,
                    label: "There was an error with the search. Try Again.",
                    labelId: "search.error.general",
                    type: "error",
                });
                props.clearResponseSearch();
            } else {
                if (((!_.isEmpty(props.searchResults.products) && props.searchResults.productsCount > 0) ||
                    (!_.isEmpty(props.searchResults.assets) && props.searchResults.assetsCount > 0))) {
                    if (contextFilters.length > 0) localStorageService.setCurrentSearch(contextFilters);

                    props.history.push({
                        pathname: "/search/results",
                        state: { view: view }
                    });
                } else {
                    setSnackBar({
                        open: true,
                        label: "No results found",
                        labelId: "common.missing_results",
                        type: "error",
                    });
                }
            }
        } else if (props.searchResults.searchExecuted && !props.searchResults.loadingSearchRequest && !props.searchResults.loadingCountRequest) {
            setSnackBar({
                open: true,
                label: "No results found",
                labelId: "common.missing_results",
                type: "error",
            });
        }
    }, [props.searchResults]);

    useEffect(() => {
        console.log("SESSIONI ORFANE: ", props.sessioniOrfaneResults);
        if (
            props.sessioniOrfaneResults &&
            props.sessioniOrfaneResults.length > 0 &&
            (props.location.pathname === "/sessioni-orfane" ||
                props.location.pathname === "/sessioni-orfane/")
        ) {
            const filterKey = contextFilters.map((filter) => {
                return filter.name;
            });
            if (contextFilters.length > 0) localStorageService.setCurrentSearch(contextFilters);
            props.history.push({
                pathname: "/sessioni-orfane/results",
                state: { view: view, filterKey: filterKey },
            });
        } else {
            if (
                contextFilters.length > 0 &&
                props.sessioniOrfaneResults &&
                props.sessioniOrfaneResults.length === 0
            ) {
                setSnackBar({
                    open: true,
                    label: "Sorry, no matching records found.",
                    labelId: "common.missing_results",
                    type: "error",
                });
            }
        }
    }, [props.sessioniOrfaneResults]);

    /**
     * This effect is triggered when user create a new fav to update fav list.
     */
    useEffect(() => {
        if (
            props.favourite &&
            props.favourite.payload &&
            props.favourite.payload.length > 0
        ) {
            props.getFavourites(applicationStatus);
        }
    }, [props.favourite]);

    /**
     * This effect is used to set fav links when the list of fav change (after add or delete).
     */
    useEffect(() => {
        if (props.favourites && props.favourites.length > 0) {
            console.log("YOUR FAVOURITE RESEARCHES ARE: ", props.favourites);
            setFavouritesLinks(props.favourites);
        }
    }, [props.favourites]);

    /**
     * This effect is used to populate search row when a single fav has been selected.
     */
    useEffect(() => {
        if (
            props.favDetails &&
            props.favDetails["payload"] &&
            props.favDetails["payload"].length > 0
        ) {
            let filters = _.cloneDeep(props.favDetails["payload"]);
            let contextsArr = _.cloneDeep(props.searchContext);
            setContextFilters(filters);
            filters.forEach((f) => {
                contextsArr.forEach(function (c) {
                    if (c.search_area_guid === f.search_area) {
                        c["keys"] = c["keys"].filter((k) => k.name !== f.name);
                        console.log(c["keys"]);
                    }
                });
            });
            setAllContexts(contextsArr);
            console.log(
                "YOU CLICKED ON: ",
                filters,
                "AND ALL CONTEXTS NOW IS: ",
                contextsArr
            );
        }
    }, [props.favDetails]);

    useEffect(() => {
        if (
            props.gallery.search &&
            props.gallery.search["payload"] &&
            props.gallery.search["payload"].length > 0 &&
            props.searchContext
        ) {
            let filters = _.cloneDeep(props.gallery.search["payload"]);
            let contextsArr = _.cloneDeep(props.searchContext);
            setContextFilters(filters);
            filters.forEach((f) => {
                contextsArr.forEach(function (c) {
                    if (c.search_area_guid === f.search_area) {
                        c["keys"] = c["keys"].filter((k) => k.name !== f.name);
                        console.log(c["keys"]);
                    }
                });
            });
            setAllContexts(contextsArr);            
            console.log(
                "YOU RECEIVE THIS FILTERS: ",
                filters,
                "AND ALL CONTEXTS NOW IS: ",
                contextsArr
            );
        }
    }, [props.gallery.search, props.searchContext]);

    useEffect(() => {
        if (props.delete_favourite === "OK")
            props.getFavourites(applicationStatus);
        setContextFilters([]);
        props.clearFavourite();
    }, [props.delete_favourite]);

    useEffect(() => {
        if (props.update_favourite) {
            setSnackBar({
                open: true,
                type: "success",
                label: "Favourite successfully updated",
                labelId: "search.favourite.snackbar.update.success",
            });
            props.getFavouriteByGuid(props.update_favourite);
            props.getFavourites(applicationStatus);
        }
    }, [props.update_favourite]);

    /* ****************************** MULTI LINE DIALOG HANDLERS ************************************* */
    const openReusableModal = (title, name, id) => {
        console.log("Open fav dialog with ", title, name, id);
        const newState = { ...reusableModal };
        if (id) {
            setEditGuid(id);
            newState.type = "edit";
        } else {
            newState.type = "add";
        }
        newState.open = true;
        newState.title = title;
        newState.favName = name;
        setReusableModal(newState);
    };

    const closeReusableModal = () => {
        console.log("Close fav dialog");
        const newState = { ...reusableModal };
        newState.open = false;
        newState.favName = "";
        setReusableModal(newState);
        setEditGuid("");
    };

    const onSubmitFav = () => {
        if (reusableModal.type === "add")
            props.createFavourite(reusableModal.favName, contextFilters, applicationStatus);
        else
            props.renameFavourite(reusableModal.favName, editGuid);
        const newState = { ...reusableModal };
        newState.open = false;
        newState.favName = "";
        setReusableModal(newState);
        setEditGuid("");
    };

    const handleChangeFav = (event) => {
        const newState = { ...reusableModal };
        newState.favName = event.target.value;
        setReusableModal(newState);
    };

    const onChangeContextTypeHandler = (event) => {
        console.log(event.target.value, event.target.name);
        let fieldName = event.target.name;
        let value = event.target.value;
        let keys = allContexts.filter((c) => c.search_area_guid === value)[0].keys;
        setCurrentContext({
            [fieldName]: value,
            contextKeys: keys,
        });
        console.log("KEYS ", keys);
    };

    /**
     * This function is used to initialize value attributes of contextFilters state basing on filter selected, and then
     * on its input type attribute.
     *
     * @param {number} type [input type]
     */
    const rowValuesMapping = (type) => {
        console.log(type);
        switch (type) {
            case search_types_mapping.SINGLE_INPUT:
                console.log("SINGLE_INPUT ");
                return "";
            case search_types_mapping.MULTIPLE_INPUT:
                console.log("MULTIPLE_INPUT ");
                return {
                    text: {
                        value: "",
                        stringList: [],
                    },
                    separator: "\n", //null
                };
            case search_types_mapping.FILE:
                console.log("FILE INPUT ");
                return "";
            case search_types_mapping.CHECKBOX_AS_DROPDOWN:
                console.log("SINGLE_CHECKBOX input ");
                return "";
            case search_types_mapping.SINGLE_AUTOCOMPLETE:
                console.log("SINGLE_AUTOCOMPLETE input ");
                return "";
            case search_types_mapping.MULTIPLE_AUTOCOMPLETE:
                console.log("MULTIPLE_AUTOCOMPLETE input ");
                return [];
            case search_types_mapping.RANGE_DATE:
                console.log("RANGE_DATE input ");
                return { from: null, to: null };
            default:
                console.log("I don't know this search type");
        }
    };

    /**
     * This function is used to create a new search row when an element is selected from context dropdown. In this
     * function is handled the default operator for elements.
     *
     * @param event
     */
    const onChangeContextHandler = (event) => {
        let value = event.target.value;

        console.log("currentContext: ", currentContext);
        console.log("contextKeys: ", currentContext.contextKeys);
        console.log("value: ", value);

        let label = currentContext.contextKeys.filter((c) => c.name === value)[0].external_name;
        let search_type_guid = currentContext.contextKeys.filter((c) => c.name === value)[0].search_type_guid;
        let search_type = currentContext.contextKeys.filter((c) => c.name === value)[0].search_type;

        /* Here there was a problem of two different keys: operators & operator. Backend sent to us operator but then
        we used operator with a different concept so we lost all operators values for list because the first time it
        was a list and after deletion it was a string. Now backend send operators like a list.
        Everything seems to be ok.
         */
        let operators = currentContext.contextKeys.filter((c) => c.name === value)[0].operators;
        let currentFilters = {
            name: value,
            external_name: label,
            search_type_guid: search_type_guid,
            search_type: search_type,
            operators: operators,
            search_area: currentContext.contextType,
            value: rowValuesMapping(search_type)
        };

        let defaultOperatorFound;
        console.log('operators: ', operators);
        console.log('currentFilters: ', currentFilters);
        // I totally disagree with this solution but it's the unique possible: you will find that! If you don't agree
        // with that please contact armani support (denise & lorenzo)
        if (value === 'submitter') {
            console.log('Changing the default first operator for field "submitter" from "=" to "substr"')
            defaultOperatorFound = _.find(_.cloneDeep(operators), (operator) => operator.search_operator_type === '4');
        } else {
            // Changing the default
            if (value === 'draft_name'){
                console.log('Changing the default first operator for field "draft_name" from "=" to "is not null"')
                defaultOperatorFound = _.find(_.cloneDeep(operators), (operator) => operator.search_operator_type === '7');
            } else {
                defaultOperatorFound = _.find(operators, (operator) => operator.search_operator_type === '1' ||
                    operator.search_operator_type === '5');
            }
        }

        // If there is = or <..> operator set it as default
        if (defaultOperatorFound) {
            console.log('DefaultOperationFound: ', defaultOperatorFound);
            currentFilters.search_operator_guid = defaultOperatorFound.search_operator_guid;
            currentFilters.operator = defaultOperatorFound.search_operator_description;
            currentFilters.and_or = defaultOperatorFound.and_or;
        }

        //removing the element from all contexts
        let contextsArr = _.cloneDeep(allContexts);
        //let contextsArr = JSON.parse(JSON.stringify(allContexts));
        contextsArr.forEach(function (c) {
            if (c.search_area_guid === currentContext.contextType) {
                c["keys"] = c["keys"].filter((k) => k.name !== value);
                console.log(c["keys"]);
            }
        });

        //removing the element from current keys context
        let keysCopy = Array.from(currentContext.contextKeys);
        let newKeys = keysCopy.filter((k) => k.name !== value);
        // console.log("updatedKEYSSSSS ", contextsArr, newKeys);
        //push a new element in contextFilters
        let arrCopy = Array.from(contextFilters);
        arrCopy.push(currentFilters);
        // console.log('ALE: ', arrCopy);
        setContextFilters(arrCopy);
        setAllContexts(contextsArr);
        setCurrentContext({
            ...currentContext,
            contextKeys: newKeys,
        });
        console.log(
            event.target.value,
            currentContext,
            arrCopy,
            props.searchContext
        );
    };

    // const [selectedIndex, setSelectedIndex] = useState();

    const favOnClickHandler = (guid) => {
        console.log("favOnClickHandler GUID: ", guid);
        setContextFilters([]);
        props.getFavouriteByGuid(guid);
        // setSelectedIndex(guid);
    };

    const deleteFavOnClick = (guid) => {
        console.log("deleteFavOnClick GUID: ", guid);
        props.deleteFavourite(guid);
    };

    const ContextTypeDropdown = {
        type: "dropdown",
        name: "contextType",
        value: currentContext["contextType"],
        onChangeHandler: onChangeContextTypeHandler,
        icon: "search",
        label: {
            translateKey: "search.dropdown.label.contextType",
            defaultText: "Context Type",
        },
        options: props.contextTypes,
        loading: props.loadingSearchContext,
    };
    const ContextDropdown = {
        type: "dropdown",
        name: "context",
        value: "",
        onChangeHandler: onChangeContextHandler,
        icon: "search",
        label: {
            translateKey: "search.dropdown.label.context",
            defaultText: "Context",
        },
        options: contextOptions,
        disabled: currentContext.contextType === "",
    };

    const FavoriteSearchLinks = applicationStatus === SEARCH_APPLICATION.sessioniOrfane
        ? <div/>
        : {
            type: "linkBox",
            title: {
                translateKey: "search.label.favourites",
                defaultText: "Favourites",
            },
            links: favouritesLinks,
            icon: 'star',
            loading: props.loadingFavourites,
            onClickHandler: favOnClickHandler,
        };

    const groupElements = [
        ContextTypeDropdown,
        ContextDropdown,
        "space",
        FavoriteSearchLinks,
    ];

    const operatorHandler = (event, rowId) => {
        const operator_id = event.target.value;

        let newContextFilters = Array.from(contextFilters);
        let newObj = { ...newContextFilters[rowId] };

        const operators = newObj.operators;
        const operator = _.find(operators, function (o) {
            return o.search_operator_guid === operator_id;
        });

        newObj.search_operator_guid = event.target.value;
        newObj.operator = operator.search_operator_description;
        newObj.and_or = operator.and_or;

        newContextFilters[rowId] = newObj;
        setContextFilters([...newContextFilters]);
    };

    const deleteContextHandler = (event, context) => {
        let copyContext = _.cloneDeep(context);
        if (copyContext.and_or) {
            delete copyContext.and_or;
        }
        if (copyContext.search_operator_guid) {
            delete copyContext.search_operator_guid;
        }
        if (copyContext.operator) {
            delete copyContext.operator;
        }

        let contextName = copyContext.name;
        let contextArea = copyContext.search_area;
        console.log("DELETE ", contextName, contextArea, copyContext, contextFilters);
        //remove element from contextFilters
        let newFilters = Array.from(contextFilters);
        _.remove(
            newFilters,
            (f) => f.name === contextName && f.search_area === contextArea
        );
        setContextFilters(newFilters);
        //adding the element back to all contexts
        let contextsArr = Array.from(allContexts);
        contextsArr.forEach(function (c) {
            if (c.search_area_guid === contextArea) {
                c["keys"].push(copyContext);
                console.log(c["keys"]);
            }
        });
        setAllContexts(contextsArr);
        //adding the element back to current keys context if we are in the same search area
        if (currentContext.contextType === contextArea) {
            let keysCopy = Array.from(currentContext.contextKeys);
            keysCopy.push(copyContext);
            setCurrentContext({
                ...currentContext,
                contextKeys: keysCopy,
            });
            console.log("updatedKEYSSSSS ", contextsArr, keysCopy);
        }
    };

    const deleteAllContextHandler = () => {
        console.log("DELETE ALL FILTERS");
        props.clearFavourite();
        setContextFilters([]);
    };

    const handleChangeInput = (event, index) => {
        console.log("SEARCH TYPE INPUT", event.target.value, index);
        let newContextFilters = Array.from(contextFilters);
        let newObj = { ...newContextFilters[index] };
        newObj.value = event.target.value;
        newContextFilters[index] = newObj;
        setContextFilters([...newContextFilters]);
    };

    /**
     * Handler to set the value for date-picker. The structure of value included, for each row,  into contextFilters
     * state is: {from: valueFrom, to: valueTo}.
     *
     * @param {Moment} data [selected date]
     * @param {number} index [index used to identify the row, and then the object into the state, that is going to be
     *                          update. The value is an integer.]
     * @param {boolean} params [identifies which date-picker is: true (to) / false (from)]
     */
    const handleChangeDateRange = (data, index, params) => {
        let newContextFilters = Array.from(contextFilters);
        let newObj = { ...newContextFilters[index] };

        if (params) {
            newObj.value.to = data;
        } else {
            newObj.value.from = data;
        }

        newContextFilters[index] = newObj;
        setContextFilters([...newContextFilters]);
    };

    const handleChangeCheckboxDropdown = (event, index) => {
        let newContextFilters = Array.from(contextFilters);
        let newObj = { ...newContextFilters[index] };

        console.log("VALOREEEE: ", event.target.value);
        newObj.value = !newObj.value;

        newContextFilters[index] = newObj;
        setContextFilters([...newContextFilters]);
    };

    const handleChangeAutocomplete = (event, selectedValue, index) => {
        console.log("SEARCH TYPE AUTOCOMPLETE", event, index, selectedValue);
        let newContextFilters = Array.from(contextFilters);
        let newObj = { ...newContextFilters[index] };
        if (selectedValue.value) newObj.value = selectedValue.value;
        else {
            newObj.value = Array.from(selectedValue).map(v => ({
                ...v,
                elem: v.value,
                selected: true
            }));
        }
        newContextFilters[index] = newObj;
        setContextFilters([...newContextFilters]);
    };

    /* ****************************** MULTI LINE DIALOG HANDLERS ************************************* */
    const openMultipleInputDialog = (event, rowIndex) => {
        console.log("Open dialog");
        const title = multipleInputDialog.title;
        setMultipleInputDialog({
            rowIndex: rowIndex,
            open: true,
            error: false,
            errorText: null,
            title: title,
        });
    };

    const closeMultipleInputDialog = (event) => {
        console.log("Close Dialog");
        const title = multipleInputDialog.title;
        setMultipleInputDialog({
            rowIndex: null,
            open: false,
            error: false,
            errorText: null,
            title: title,
        });
    };

    const separatorRadioGroup = (event, index) => {
        console.log("Setting separator value");
        console.log("VALUE: ", event.target.value);
        console.log("ROW INDEX: ", index);
        let newContextFilters = _.cloneDeep(contextFilters);
        let newObj = { ...newContextFilters[index] };
        newObj.value.separator = event.target.value;

        if (newObj.value.text.value.length > 0) {
            newObj.value.text.stringList = cleanInput(
                newObj.value.text.value,
                event.target.value
            );
        }
        console.log("NEW OBJ: ", newObj);
        newContextFilters[index] = newObj;

        setContextFilters([...newContextFilters]);
    };

    const inputTextMultipleValue = (event, index) => {
        console.log("Setting multiple input value");
        console.log("VALUE: ", event.target.value);
        console.log("ROW INDEX: ", index);

        let newContextFilters = _.cloneDeep(contextFilters);
        let newObj = { ...newContextFilters[index] };

        newObj.value.text.value = event.target.value;
        console.log("SEPARATOR BEFORE SPLIT: ", newObj.value.separator);

        if (newObj.value.separator !== null) {
            newObj.value.text.stringList = cleanInput(
                event.target.value,
                newObj.value.separator
            );
        }

        console.log("NEW OBJ: ", newObj);
        newContextFilters[index] = newObj;

        setContextFilters([...newContextFilters]);
    };

    /**
     * This function is used to clean input and prepare the structure for backend.
     * @param {string} inputText [Input inserted by user]
     * @param {string} separator [Separator selected by user]
     * @returns {{elem: *}[]}
     */
    const cleanInput = (inputText, separator) => {
        //console.log('cleanInput: ', inputText, separator);
        let resultStringList = [];
        const stringList = inputText.split(separator);
        stringList.forEach((str) => {
            if (str.length > 0) {
                resultStringList.push({ elem: str.trim(" ") });
            }
        });
        return resultStringList;
    };
    /* ************************************************************************************************************* */

    /* ******************************************* BUTTONS HANDLER ************************************************* */
    const searchOnClickHandler = (application, view) => {
        console.log("Params searchOnClickHandler: ", view, contextFilters);
        setView(view);

        let filterClone = _.cloneDeep(contextFilters);
        const filterList = createSearchBodyRequest(filterClone);


        if (application !== SEARCH_APPLICATION.sessioniOrfane) {
            const bodyRequest = {
                filter_list: filterList,
                size: 10,
                start: 0,
                order: [],
                filters_table: [],
                asset: view === SEARCH_VIEWS.assets
            };
            props.searchWithCount(bodyRequest);
        } else {
            const bodyRequest = { filter_list: filterList };
            props.searchSessioniOrfane(bodyRequest);
        }
    };

    /**
     * This effect is used to enable/disable search buttons.
     * NOTE: If isNull operator is selected the buttons will be enable also without any values.
     */
    useEffect(() => {
        if (contextFilters.length === 0) {
            setButtonDisabledStatus(true);
        } else {
            let disabled = false;

            for (let i = 0; i < contextFilters.length; i++) {
                if (disabled) {
                    break;
                }

                if (
                    contextFilters[i].search_operator_guid &&
                    contextFilters[i].name &&
                    contextFilters[i].search_type &&
                    contextFilters[i].search_operator_guid !== '6' &&
                    contextFilters[i].search_operator_guid !== '7'
                ) {
                    if (
                        (typeof contextFilters[i].value === "string" ||
                            contextFilters[i].value.constructor === Array) &&
                        contextFilters[i].value.length !== 0 &&
                        disabled === false
                    ) {
                        // single input, single autocomplete, multi autocomplete
                        //console.log('Check value for: \n- single input \n- single autocomplete \n- multiple autocomplete \n- checkbox as drop');
                        disabled = false;
                    } else if (
                        contextFilters[i].value.constructor === Object &&
                        "from" in contextFilters[i].value &&
                        "to" in contextFilters[i].value &&
                        disabled === false
                    ) {
                        // date range
                        //console.log('Check value for date range');
                        disabled = false;
                    } else if (
                        contextFilters[i].value.constructor === Object &&
                        "text" in contextFilters[i].value &&
                        "separator" in contextFilters[i].value &&
                        contextFilters[i].value.text.value.length !== 0 &&
                        contextFilters[i].value.separator !== null &&
                        disabled === false
                    ) {
                        //console.log("Check value for multiple input");
                        disabled = false;
                    } else if (contextFilters[i].value.constructor === Boolean && disabled === false) {
                        // checkbox as dropdown (yes or no)
                        disabled = false;
                    } else {
                        disabled = true;
                    }
                } else if (
                    contextFilters[i].search_operator_guid &&
                    contextFilters[i].name &&
                    contextFilters[i].search_type &&
                    contextFilters[i].search_operator_guid === '6'
                ) {
                    disabled = false;
                } else if (
                    contextFilters[i].search_operator_guid &&
                    contextFilters[i].name &&
                    contextFilters[i].search_type &&
                    contextFilters[i].search_operator_guid === '7'
                ) {
                    disabled = false;
                } else {
                    disabled = true;
                }
            }
            console.log("Disable value: ", disabled);
            setButtonDisabledStatus(disabled);
        }
    }, [contextFilters]);
    /* ************************************************************************************************************* */
    /**
     * This is the controller handler function to handle the different input possibility in the third cell of the table.
     *
     * @param event [event that fired the onChange method.]
     * @param {number} rowIndex [index used to identify the row, and then the object into the state, that is going to be
     *                             update. The value is an integer.]
     * @param {number} type [input type]
     * @param {Object|boolean|string|number} params [contains all additional information that need based on input type]
     */
    const searchCellChangeHandler = (event, rowIndex, type, params) => {
        console.log(rowIndex, type);
        switch (type) {
            case search_types_mapping.SINGLE_INPUT:
                handleChangeInput(event, rowIndex);
                break;
            case search_types_mapping.MULTIPLE_INPUT:
                console.log("MULTIPLE INPUT");
                openMultipleInputDialog(event, rowIndex);
                break;
            case search_types_mapping.SINGLE_AUTOCOMPLETE:
                handleChangeAutocomplete(event, params, rowIndex);
                console.log("autocomplete RIGA ", rowIndex);
                break;
            case search_types_mapping.FILE:
                console.log("file input ");
                break;
            case search_types_mapping.CHECKBOX_AS_DROPDOWN:
                handleChangeCheckboxDropdown(event, rowIndex);
                break;
            case search_types_mapping.MULTIPLE_AUTOCOMPLETE:
                handleChangeAutocomplete(event, params, rowIndex);
                console.log("MULTIPLE_AUTOCOMPLETE input ");
                break;
            case search_types_mapping.RANGE_DATE:
                handleChangeDateRange(event, rowIndex, params);
                break;
            default:
                console.log("WHAAAAAT ? RIGA ", rowIndex);
        }
    };

    /**
     * This function is used when we are into Product Showroom application context (path: /showroom-product/new-gallery).
     * Redirect application to a new path: /showroom-product/new-gallery/order passing some information.
     */
    const sortingMacroAreasRedirect = event => {
        localStorageService.setCurrentSearch(contextFilters);

        props.history.push({
            pathname: '/showroom-product/new-gallery/order',
            filters: contextFilters
        });
    }

    /**
     * This function is used when we are into Product Showroom application context.
     * Redirect application to a new path: /showroom-product/edit-gallery/{galleryId}/order passing some information.
     */
    const sortingMacroAreasRedirectEdit = () => {
        localStorageService.setCurrentSearch(contextFilters);

        props.history.push({
            pathname: `/showroom-product/edit-gallery/order`,
            filters: contextFilters
        });
    }

    return (
        <div className={classes.root}>
            <BackdropElement
                loading={
                    props.loadingFavouriteCreation ||
                    props.loadingFavourite ||
                    props.loadingFavourites ||
                    props.loadingFavouriteDelete ||
                    props.loadingSearchOrfani ||
                    props.searchResults.loadingSearchRequest ||
                    props.searchResults.loadingCountRequest
                }
            />
            <Grid container direction="row" justify="space-between" spacing={3}>
                <Grid item xs={3}>
                    <LeftSideBarUpper groupElements={groupElements}/>
                </Grid>
                {contextFilters.length > 0 && (
                    <Grid item xs={9}>
                        <Grid container direction="row" justify="space-between" spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.searchTableRoot}>
                                    <SearchFieldTable
                                        rowsRaw={contextFilters}
                                        onChangeOperator={operatorHandler}
                                        deleteContextHandler={deleteContextHandler}
                                        deleteAllContextHandler={deleteAllContextHandler}
                                        searchCellChangeHandler={searchCellChangeHandler}
                                        options={props.options}
                                        deleteLabel={{
                                            label: "Delete Favourite",
                                            labelId: "search.tooltip.delete.favourite",
                                        }}
                                        renameLabel={{
                                            label: "Rename Favourite",
                                            labelId: "search.tooltip.rename.favourite",
                                        }}
                                        deleteAction={deleteFavOnClick}
                                        renameAction={openReusableModal}
                                        renameTitle={intl.formatMessage({
                                            id: "search.favourite.dialog.title.edit",
                                            defaultMessage: "Edit Favourite Name",
                                        })}
                                        loadings={props.loadings}
                                        callOptionList={props.callOptionList}
                                        favDetails={props.favDetails}
                                        title={props.favDetails && props.favDetails["favourite_name"] ? props.favDetails["favourite_name"] : ""}/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">
                                    {applicationStatus === SEARCH_APPLICATION.sessioniOrfane &&
                                    <>
                                        <Grid item xs={3}/>
                                        <Grid item xs={3}/>
                                        <Grid item xs={3}/>
                                        <Grid item xs={6} lg={3} className={classes.tallButton}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.sessioni.orfane",
                                                    defaultMessage: "Search Sessioni Orfane",
                                                })}

                                                onSubmit={() => searchOnClickHandler(SEARCH_APPLICATION.sessioniOrfane, null)}
                                                loading={props.loadingSearchOrfani}
                                                disabled={buttonDisabledStatus}
                                            />
                                        </Grid>
                                    </>
                                    }
                                    {applicationStatus === SEARCH_APPLICATION.search &&
                                    <>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.add.favourite",
                                                    defaultMessage: "Add Favourite",
                                                })}
                                                onSubmit={() =>
                                                    openReusableModal(
                                                        intl.formatMessage({
                                                            id: "search.favourite.dialog.title.new",
                                                            defaultMessage: "Insert Favourite Name",
                                                        }),
                                                        ""
                                                    )
                                                }
                                                disabled={contextFilters.length === 0}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.update.favourite",
                                                    defaultMessage: "Update Favourite",
                                                })}
                                                onSubmit={() =>
                                                    props.updateFavourite(
                                                        props.favDetails["guid"],
                                                        contextFilters
                                                    )
                                                }
                                                disabled={
                                                    !props.favDetails ||
                                                    !props.favDetails["payload"] ||
                                                    props.favDetails["payload"].length === 0
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.products",
                                                    defaultMessage: "Search Product",
                                                })}
                                                onSubmit={() => searchOnClickHandler(SEARCH_APPLICATION.search, SEARCH_VIEWS.products)}
                                                // loading={props.loadingSearchRequest && view === SEARCH_VIEWS.products}
                                                disabled={buttonDisabledStatus}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.assets",
                                                    defaultMessage: "Search Assets",
                                                })}
                                                onSubmit={() => searchOnClickHandler(SEARCH_APPLICATION.search, SEARCH_VIEWS.assets)}
                                                // loading={props.loadingSearchRequest && view === SEARCH_VIEWS.assets}
                                                disabled={buttonDisabledStatus}
                                            />
                                        </Grid>
                                    </>
                                    }
                                    {applicationStatus === SEARCH_APPLICATION.productShowroom &&
                                    <>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.add.favourite",
                                                    defaultMessage: "Add Favourite",
                                                })}
                                                onSubmit={() =>
                                                    openReusableModal(
                                                        intl.formatMessage({
                                                            id: "search.favourite.dialog.title.new",
                                                            defaultMessage: "Insert Favourite Name",
                                                        }),
                                                        ""
                                                    )
                                                }
                                                disabled={contextFilters.length === 0}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.update.favourite",
                                                    defaultMessage: "Update Favourite",
                                                })}
                                                onSubmit={() =>
                                                    props.updateFavourite(
                                                        props.favDetails["guid"],
                                                        contextFilters
                                                    )
                                                }
                                                disabled={
                                                    !props.favDetails ||
                                                    !props.favDetails["payload"] ||
                                                    props.favDetails["payload"].length === 0
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "showroom_product.label.sorting.macro.areas",
                                                    defaultMessage: "Macro Areas Sorting",
                                                })}
                                                onSubmit={() => sortingMacroAreasRedirect()}
                                                disabled={buttonDisabledStatus}
                                            />
                                        </Grid>
                                        {/*<Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "showroom_product.label.sorting.single.product",
                                                    defaultMessage: "Single Product Sorting",
                                                })}
                                                onSubmit={null} // TODO: devo chiamare subito la ricerca e poi mostrare la schermata di ordinamento del singolo prodotto
                                                disabled={buttonDisabledStatus}
                                            />
                                        </Grid>*/}
                                    </>
                                    }
                                    {applicationStatus === SEARCH_APPLICATION.editGallery &&
                                    <>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.add.favourite",
                                                    defaultMessage: "Add Favourite",
                                                })}
                                                onSubmit={() =>
                                                    openReusableModal(
                                                        intl.formatMessage({
                                                            id: "search.favourite.dialog.title.new",
                                                            defaultMessage: "Insert Favourite Name",
                                                        }),
                                                        ""
                                                    )
                                                }
                                                disabled={contextFilters.length === 0}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "search.button.update.favourite",
                                                    defaultMessage: "Update Favourite",
                                                })}
                                                onSubmit={() =>
                                                    props.updateFavourite(
                                                        props.favDetails["guid"],
                                                        contextFilters
                                                    )
                                                }
                                                disabled={
                                                    !props.favDetails ||
                                                    !props.favDetails["payload"] ||
                                                    props.favDetails["payload"].length === 0
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <ButtonElement
                                                label={intl.formatMessage({
                                                    id: "showroom_product.label.update.search",
                                                    defaultMessage: "Update Filters",
                                                })}
                                                onSubmit={() => sortingMacroAreasRedirectEdit()}
                                                disabled={buttonDisabledStatus}
                                            />
                                        </Grid>
                                    </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SnackbarElement
                        open={snackBar.open}
                        type={snackBar.type}
                        label={snackBar.label}
                        labelId={snackBar.labelId}
                        handleClose={() =>
                            setSnackBar({
                                open: false,
                                label: "",
                                labelId: "",
                                type: "error",
                            })
                        }
                    />
                </Grid>
                <SearchMultiLineInputModal
                    multipleInputDialog={multipleInputDialog}
                    closeMultipleInputDialog={closeMultipleInputDialog}>
                    <Grid container direction="row" justify="flex-start" spacing={3}>
                        <Grid item xs={10}>
                            <InputTextElement
                                name={"multiline-text"}
                                value={
                                    contextFilters[multipleInputDialog.rowIndex]
                                        ? contextFilters[multipleInputDialog.rowIndex].value.text
                                            .value
                                        : ""
                                }
                                handleChange={(event) =>
                                    inputTextMultipleValue(event, multipleInputDialog.rowIndex)
                                }
                                type="text"
                                placeholder={"Insert text here..."}
                                placeholderId={"search.dialog.input.placeholder"}
                                fullWidth
                                multiline
                                rowsNumber={10}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container direction="column" justify="space-between">
                                <Grid item>
                                    <Typography variant="h6">
                                        {intl.formatMessage({
                                            id: "search.separator.dialog",
                                            defaultMessage: "Select divider:",
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <RadioButtonGroup
                                        groupName={"separator"}
                                        radioButtons={dividerList}
                                        value={
                                            contextFilters[multipleInputDialog.rowIndex]
                                                ? contextFilters[multipleInputDialog.rowIndex].value
                                                    .separator
                                                : null
                                        }
                                        handleChange={(event) =>
                                            separatorRadioGroup(event, multipleInputDialog.rowIndex)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SearchMultiLineInputModal>
                <ReusableModal
                    reusableModal={reusableModal}
                    submitClick={onSubmitFav}
                    closeReusableModal={closeReusableModal}>
                    <Grid container direction="row" justify="center">
                        <Grid item xs={8}>
                            <InputTextElement
                                name={"favourite-name"}
                                value={reusableModal.favName}
                                handleChange={handleChangeFav}
                                type="text"
                                placeholder={"Insert text here..."}
                                placeholderId={"search.dialog.input.placeholder"}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </ReusableModal>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    tooManyResultsError: state.searchReducer.tooManyResultsError,
    searchError: state.searchReducer.error,
    loadingSearchContext: state.searchContextReducer.loadingSearchContext,
    loadingFavourite: state.favouritesReducer.loadingFavourite,
    loadingFavourites: state.favouritesReducer.loadingFavourites,
    loadingFavouriteCreation: state.favouritesReducer.loadingFavouriteCreation,
    loadingFavouriteDelete: state.favouritesReducer.loadingFavouriteDelete,
    favourite: state.favouritesReducer.favourite,
    favourites: state.favouritesReducer.favourites,
    favDetails: state.favouritesReducer.favDetails,
    delete_favourite: state.favouritesReducer.delete_favourite,
    update_favourite: state.favouritesReducer.update_favourite,
    searchContext: state.searchContextReducer.searchContext,
    gallery: state.galleryProductShowroomReducer,
    contextTypes: _.toArray(
        _.mapValues(state.searchContextReducer.searchContext, function (o) {
            return {
                label: <FormattedMessage id={o.search_area_description}/>,
                value: o.search_area_guid,
            };
        })
    ),
    options: {
        seasonality: state.seasonalityReducer.seasonality,
        dress_origin: state.dressOriginReducer.dress_origin,
        richiedente: state.richiedenteReducer.richiedente,
        brand: state.brandReducer.brand,
        l1_category: state.l1CategoryReducer.l1_category,
        gao_description: state.gaoDescriptionReducer.gao_description,
        pim_status: state.pimStatusReducer.pim_status,
        gender: state.genderReducer.gender,
        label: state.labelReducer.label,
        l2_product_grouping: state.l2ProductGroupingReducer.l2_product_grouping,
        l3_product: state.l3ProductReducer.l3_product,
        asset_type: state.assetTypeReducer.asset_type,
        season_description: filterActiveSeasons(state.seasonReducer.seasons),
        service: state.serviceReducer.service,
        photolab: state.photolabReducer.photolab,
        set_name: state.setReducer.set,
        status: state.statusReducer.status,
        shoot_type: state.shootTypeReducer.shoot_type,
        dimension: state.dimensionReducer.dimension,
        view: state.viewReducer.view,
        status_report: state.exportStatusReducer.exportStatusList,
        // target: state.targetReducer.target,
        // pim_target: state.pimTargetReducer.pimTarget,
        pg_linelist: state.linelistReducer.pg_linelist,
        pg_family_name: state.familyNameReducer.pg_family_name,
    },
    loadings: {
        seasonality: state.seasonalityReducer.loadingSeasonality,
        dress_origin: state.dressOriginReducer.loadingDressOrigin,
        richiedente: state.richiedenteReducer.loadingRichiedente,
        brand: state.brandReducer.loadingBrand,
        l1_category: state.l1CategoryReducer.loadingL1category,
        gao_description: state.gaoDescriptionReducer.loadingGaoDescription,
        pim_status: state.pimStatusReducer.loadingPimStatus,
        gender: state.genderReducer.loadingGender,
        label: state.labelReducer.loadingLabel,
        l2_product_grouping: state.l2ProductGroupingReducer.loadingL2ProductGrouping,
        l3_product: state.l3ProductReducer.loadingL3Product,
        asset_type: state.assetTypeReducer.loadingAssetType,
        season_description: state.seasonReducer.loadingSeason,
        service: state.serviceReducer.loadingService,
        photolab: state.photolabReducer.loadingPhotolab,
        set_name: state.setReducer.loadingSet,
        status: state.statusReducer.loadingStatus,
        shoot_type: state.shootTypeReducer.loadingShootType,
        dimension: state.dimensionReducer.loadingDimension,
        view: state.viewReducer.loadingView,
        status_report: state.exportStatusReducer.loading,
        // target: state.targetReducer.loadingTarget,
        // pim_target: state.pimTargetReducer.loadingPimTarget,
        pg_linelist: state.linelistReducer.loadingLinelist,
        pg_family_name: state.familyNameReducer.loadingFamilyName
    },
    searchResults: state.searchReducer,
    sessioniOrfaneResults: state.sessioniOrfaneReducer.results,
    loadingSearchOrfani: state.sessioniOrfaneReducer.loadingSearch,
});

const mapDispatchToProps = (dispatch) => ({
    getContextSearch: (application) => dispatch(startGetContextSearch(application)),
    getFavourites: (application) => dispatch(startGetFavourites(application)),
    createFavourite: (favName, payload, application) =>
        dispatch(startCreateFavourite(favName, payload, application)),
    updateFavourite: (guid, payload) =>
        dispatch(startUpdateFavourite(guid, payload)),
    getFavouriteByGuid: (guid) => dispatch(startGetFavouriteByGuid(guid)),
    getGallerySearch: (galleryId) => dispatch(startGetGallerySearch(galleryId)),
    deleteFavourite: (guid) => dispatch(startDeleteFavourite(guid)),
    renameFavourite: (favName, guid) =>
        dispatch(startRenameFavourite(favName, guid)),
    clearFavourite: () => dispatch(clearFavouriteDetails()),
    clearSessioniOrfane: () => dispatch(clearSessioniOrfane()),
    clearResponseSearch: () => dispatch(clearResponseSearch()),
    clearServices: () => dispatch(clearServices()),
    callOptionList: {
        seasonality: () => dispatch(startGetSeasonality()),
        dress_origin: () => dispatch(startGetDressOrigin()),
        richiedente: () => dispatch(startGetRichiedente()),
        brand: () => dispatch(startGetBrand()),
        l1_category: () => dispatch(startGetL1Category()),
        gao_description: () => dispatch(startGetGaoDescription()),
        pim_status: () => dispatch(startGetPimStatus()),
        gender: () => dispatch(startGetGender()),
        label: () => dispatch(startGetLabel()),
        l2_product_grouping: () => dispatch(startGetL2ProductGrouping()),
        l3_product: () => dispatch(startGetL3Product()),
        asset_type: () => dispatch(startGetAssetType()),
        season_description: () => dispatch(startGetSeasons()),
        service: () => dispatch(startGetService()),
        photolab: () => dispatch(startGetPhotolab()),
        set_name: () => dispatch(startGetSet()),
        status: () => dispatch(startGetStatus()),
        shoot_type: () => dispatch(startGetShootType()),
        dimension: () => dispatch(startGetDimension()),
        view: () => dispatch(startGetView()),
        status_report: () => dispatch(startGetExportStatus()),
        // target: () => dispatch(startGetTarget()),
        // pim_target: () => dispatch(startGetPimTarget()),
        pg_linelist: () => dispatch(startGetLinelist()),
        pg_family_name: () => dispatch(startGetFamilyName())
    },
    searchSessioniOrfane: (body) => dispatch(startSearchSessioniOrfane(body)),
    searchWithCount: (filters) => dispatch(startPostSearchWithCountRequest(filters)),
    clearPostProduction: () => dispatch(clearPostProduction())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SearchContainer));

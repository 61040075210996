import {
    CLEAR_ASSET_UPDATE,
    CLEAR_CONTEXT,
    DELETE_ASSET_FAILURE,
    DELETE_ASSET_REQUEST,
    DELETE_ASSET_SUCCESS,
    GET_CONTEXT_FAILURE,
    GET_CONTEXT_REQUEST,
    GET_CONTEXT_SUCCESS,
    GET_DEFAULT_CONTEXT_FAILURE,
    GET_DEFAULT_CONTEXT_REQUEST,
    GET_DEFAULT_CONTEXT_SUCCESS,
    PUT_UPDATE_ASSET_FAILURE,
    PUT_UPDATE_ASSET_REQUEST,
    PUT_UPDATE_ASSET_SUCCESS
} from "../../actions/showroom/context";


const initialState = {
    showroomContext: {},
    defaultContext: null,
    loadingContext: false,
    error: null,
    loadingAsset: false,
    errorAsset: null,
    typeActionAsset: null,
    updatedAsset: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTEXT_REQUEST:
            return {
                ...state,
                loadingContext: true
            };
        case GET_DEFAULT_CONTEXT_FAILURE:
        case GET_DEFAULT_CONTEXT_REQUEST: 
            return {
                ...state,
                defaultContext: null
            };
        case GET_DEFAULT_CONTEXT_SUCCESS:
            return {
                ...state,
                defaultContext: action.defaultContext
            };
        case PUT_UPDATE_ASSET_REQUEST:
            return {
                ...state,
                loadingAsset: true,
                typeActionAsset: 'PUT'
            };
        case DELETE_ASSET_REQUEST:
            return {
                ...state,
                loadingAsset: true,
                typeActionAsset: 'DELETE'
            }
        case GET_CONTEXT_SUCCESS:
            return {
                ...state,
                loadingContext: false,
                showroomContext: action.showroomContext
            };
        case PUT_UPDATE_ASSET_SUCCESS:
            return {
                ...state,
                loadingAsset: false,
                updatedAsset: action.assetInfos
            };
        case DELETE_ASSET_SUCCESS:
            return {
                ...state,
                loadingAsset: false
            }
        case GET_CONTEXT_FAILURE:
            return {
                ...state,
                loadingContext: false,
                error: action.error
            };
        case PUT_UPDATE_ASSET_FAILURE:
            return {
                ...state,
                loadingAsset: false,
                errorAsset: action.error
            };
        case DELETE_ASSET_FAILURE:
            return {
                ...state,
                loadingAsset: false,
                errorAsset: action.error
            }
        case CLEAR_CONTEXT:
            return { ...state, showroomContext: {}, loadingContext: false, error: null };
        case CLEAR_ASSET_UPDATE:
            return {
                ...state,
                loadingAsset: false,
                errorAsset: null,
                typeActionAsset: null,
                updatedAsset: {}
            };
        default:
            return state;
    }
}
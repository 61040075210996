import {
    CLEAR_COLUMNS_SETTINGS,
    GET_COLUMNS_SETTINGS_FAILURE,
    GET_COLUMNS_SETTINGS_REQUEST,
    GET_COLUMNS_SETTINGS_SUCCESS
} from "../../actions/search/columns_settings";
import {ACTION_REQUEST, SEARCH_VIEWS} from "../../../utils/constant";

const initialState = {
    actionType: null,
    loading: null,
    error: null,
    productColumns: null,
    assetColumns: null,
    assetSmColumns: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COLUMNS_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true,
                actionType: ACTION_REQUEST.get
            };
        case GET_COLUMNS_SETTINGS_SUCCESS:
            if (action.view === SEARCH_VIEWS.products) {
                return {
                    ...state,
                    loading: false,
                    productColumns: action.settings.product
                };
            } else if (action.view === SEARCH_VIEWS.assets) {
                return {
                    ...state,
                    loading: false,
                    assetColumns: action.settings.asset
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    assetSmColumns: action.settings['asset-sm']
                };
            }

        case GET_COLUMNS_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case CLEAR_COLUMNS_SETTINGS:
            return initialState;
        default:
            return state;
    }
};
import {GET_STATUS_FAILURE, GET_STATUS_REQUEST, GET_STATUS_SUCCESS} from "../../actions/search/status";

const initialState = {status: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STATUS_REQUEST:
            return {
                loadingStatus: true
            };
        case GET_STATUS_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                status: action.status,
            };
        case GET_STATUS_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                error: action.error
            }
        default:
            return state;
    }
}
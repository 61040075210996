import React from "react";
import {useIntl} from "react-intl";
import IMG_FROZEN from '../../assets/icons/status-frozen.svg';
import IMG_IN_EXPORT from '../../assets/icons/status-in-export.svg';
import IMG_KO from '../../assets/icons/status-ko.svg';
import IMG_OK from '../../assets/icons/status-ok.svg';
import IMG_TO_EXPORT from '../../assets/icons/icons8-clock.svg';
import Tooltip from "@material-ui/core/Tooltip";
import {useStyles} from "./IconExportStyle";


const IconExport = props => {

    const intl = useIntl();
    const classes = useStyles();
    const { exportStatus } = props;
    let icon;
    let label = "";
    let labelId = "";

    switch (exportStatus) {
        case 'frozen':
            icon = IMG_FROZEN;
            label = 'Frozen - NO LDD';
            labelId = 'tooltip.label.export.frozen.noldd';
            break;
        case 'in_export':
            icon = IMG_IN_EXPORT;
            label = 'In Export'
            labelId = 'tooltip.label.export.in.export';
            break;
        case 'KO':
            icon = IMG_KO;
            label = 'Sent KO';
            labelId = 'tooltip.label.export.sent.ko';
            break;
        case 'OK':
            icon = IMG_OK;
            label = 'Sent OK';
            labelId = 'tooltip.label.export.sent.oK';
            break;
        case "to_export":
            icon = IMG_TO_EXPORT;
            label = "Waiting for export";
            labelId = "tooltip.label.export.waiting.for.export";
            break;
        default:
            break;

    }

    if (icon && label && labelId) {
        return (
            <Tooltip className={classes.root} placement="top"
                     title={intl.formatMessage({ 'id': labelId, 'defaultMessage': label })}>
                <div className={classes.background}>
                    <img alt="" src={icon} className={classes.icon}/>
                </div>
            </Tooltip>
        );
    } else {
        return null;
    }


};

export default IconExport;
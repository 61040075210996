import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: "8px",
        right: "8px",
    },
    background: {
        borderRadius: 30,
        backgroundColor: theme.palette.background.paper,
        opacity: ".7",
        width: "34px",
        height: "34px",
    },
    icon: {
        margin: '7px',
        opacity: "1",
        width: "20px",
        height: "20px",
    }
}));
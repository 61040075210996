import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";


export const EXPORT_TO_YNAP_REQUEST = 'EXPORT_TO_YNAP_REQUEST';
export const EXPORT_TO_YNAP_SUCCESS = 'EXPORT_TO_YNAP_SUCCESS';
export const EXPORT_TO_YNAP_FAILURE = 'EXPORT_TO_YNAP_FAILURE';
export const CLEAR_EXPORT_TO_YNAP = 'CLEAR_EXPORT_TO_YNAP';

export const errorType = {
    0: 'Exported',
    1: 'Exported with errors',
    2: 'Nothing exported',
    3: 'Wrong body content',
    4: 'Internal server error'
}

const localStorageService = LocalStorage.getService();

export const postExportToYnapRequest = () => {
    return { type: EXPORT_TO_YNAP_REQUEST }
};

export const postExportToYnapSuccess = (response = {}) => {
    if (response.Errors.length > 0) {
        return { type: EXPORT_TO_YNAP_SUCCESS, productsInError: response.Errors, error: errorType["1"] }
    } else {
        return { type: EXPORT_TO_YNAP_SUCCESS, productsInError: [], error: errorType["0"] }
    }

};

export const postExportToYnapFailure = (errorType) => {
    return { type: EXPORT_TO_YNAP_FAILURE, error: errorType }
};

export const clearExportToYnap = () => {
    return { type: CLEAR_EXPORT_TO_YNAP };
}

export const startExportToYnap = (productsGuidList) => {
    console.log('Start startExportToYnap');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(postExportToYnapRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/ynapExport?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({ products: productsGuidList }),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    // Quando viene esportato qualcosa/c'è qualcosa in errore
                    console.log("Response startGetContext: ", response.data);
                    dispatch(postExportToYnapSuccess(response.data));

                } else if (response.status === 204) {
                    // Non va niente in export
                    dispatch(postExportToYnapFailure(errorType["2"]));
                }
            })
            .catch(function (error) {

                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                else if (error.response.status === 400) dispatch(postExportToYnapFailure(errorType["3"]));

                dispatch(postExportToYnapFailure(errorType["4"]));
            });
    };
};
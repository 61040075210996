import {GET_AUDITS_FAILURE, GET_AUDITS_REQUEST, GET_AUDITS_SUCCESS} from "../actions/audit";

const initialState = {audits: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_AUDITS_REQUEST:
            return {
                loadingAudits: true
            };
        case GET_AUDITS_SUCCESS:
            return {
                ...state,
                loadingAudits: false,
                audits: action.audits,
            };
        case GET_AUDITS_FAILURE:
            return {
                ...state,
                loadingAudits: false,
                error: action.error
            }
        default:
            return state;
    }
}
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiToolbar-root": {
			backgroundColor: theme.palette.background.main,
			color: theme.palette.text.main,
		},
		"& .MuiToolbar-title": {
			textAlign: "initial",
		},
		"& .MuiButtonBase-root": {
			color: theme.palette.text.main,
			"&:hover": {
				color: theme.palette.focus.main,
				backgroundColor: theme.palette.primary.main,
			},
		},
		"& .MuiFormControl-root": {
			backgroundColor: theme.palette.primary.main,
			borderRadius: theme.spacing(0.5),
			padding: theme.spacing(1, 2)
		},
		"& .MuiInputBase-root": {
			color: theme.palette.text.main
		},
		"& .MuiTableCell-head": {
			color: theme.palette.text.main,
			backgroundColor: theme.palette.secondary.main.concat(" !important")
		},
		"& .MuiTableCell-head *": {
			color: theme.palette.text.main,
			alignItems: 'center'
		},
		"& .MuiTableRow-root": {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.primary.main,
			},
			"&:nth-of-type(even)": {
				backgroundColor: theme.palette.secondary.main,
			},
			"&:hover": {
				backgroundColor: theme.palette.hoverTable.main,
			},
		},
		/*"& .MuiTablePagination-root": {
			backgroundColor: theme.palette.background.main.concat(" !important"),
		},*/
		"& .MuiTableRow-footer": {
			backgroundColor: theme.palette.background.main.concat(" !important"),
			"&:hover": {},
		},
		"& .MuiTableCell-root": {
			color: theme.palette.text.main,
			whiteSpace: "nowrap",
			border: "2px solid ".concat(theme.palette.background.main),
			// border: 'none'
		},
		"& .MuiTableCell-paddingCheckbox": {
			backgroundColor: "inherit",
        },
        '& .MuiPopover-root': {
            backgroundColor: theme.palette.primary.main,
            "& .MuiFormControl-root": {
            backgroundColor: theme.palette.primary.main
            }
        }
	},
}));
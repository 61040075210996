import {makeStyles} from "@material-ui/core/styles";

// TODO: manca la linea su cui poggia il cursore dell'input bianca
export const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiPickersCalendarHeader-dayLabel': { color: theme.palette.text.secondary }
    },
    datePicker: {

        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.main,
        borderRadius: theme.spacing(0.5),
        '& .MuiInputLabel-outlined ': {
            transform: 'translate(14px, 14px) scale(1)'
        },
        '& .MuiInputLabel-root.Mui-focused ': {
            color: theme.palette.text.secondary
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
            fontWeight: '700'
        },
        '& .MuiInputBase-adornedEnd': {
            paddingRight: 0,
            zIndex: 3
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0
        },
        '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: theme.spacing(5),
            zIndex: 2,
            backgroundColor: theme.palette.background.main,
            margin: 1,
            padding: theme.spacing(1, 1.5, 0.5, 1.5),
            borderBottomLeftRadius: theme.spacing(0.5),
            borderBottomRightRadius: theme.spacing(0.5),
            width: '-webkit-fill-available'
        },
        '& .MuiInputBase-input': {
            width: '90px',
            color: theme.palette.text.main,
            marginTop: 0
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.placeholder
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-disabled fieldset": {
                borderColor: "transparent",
                boxShadow: "none !important",
            },
            "& fieldset": {
                borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
                borderColor: "transparent",
                boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
            },
            "&:hover fieldset": {
                borderColor: "transparent",
                boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
            },
            "&.Mui-error fieldset": {
                borderColor: theme.palette.error.main,
                boxShadow: "0 0 5px ".concat(theme.palette.error.main),
            },
        },
    },
    upLabel: {
        fontSize: "small",
        textAlign: "left",
        marginTop: theme.spacing(1)
    },
    // datePicker: {
    //     headerColor: theme.palette.text.secondary
    // }
}));
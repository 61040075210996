import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	textField: {
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			top: theme.spacing(5),
			zIndex: 2,
			backgroundColor: theme.palette.background.main,
			margin: 1,
			padding: theme.spacing(1, 1.5, 0.5, 1.5),
			borderBottomLeftRadius: theme.spacing(0.5),
			borderBottomRightRadius: theme.spacing(0.5),
			width: '-webkit-fill-available'
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-disabled fieldset": {
				borderColor: "transparent",
				boxShadow: "none !important",
			},
			"& fieldset": {
				borderColor: "transparent",
			},
			"&.Mui-focused fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&:hover fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&.Mui-error fieldset": {
				borderColor: theme.palette.error.main,
				boxShadow: "0 0 5px ".concat(theme.palette.error.main),
			},
		},
		width: "25ch",
		backgroundColor: theme.palette.background.main,
		borderRadius: theme.spacing(0.5),
	},
	textFieldFullWidth: {
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			top: theme.spacing(5),
			zIndex: 2,
			backgroundColor: theme.palette.background.main,
			margin: 1,
			padding: theme.spacing(1, 1.5, 0.5, 1.5),
			borderBottomLeftRadius: theme.spacing(0.5),
			borderBottomRightRadius: theme.spacing(0.5),
			width: '-webkit-fill-available'
		},
		backgroundColor: theme.palette.background.main,
		borderRadius: theme.spacing(0.5),
		"& .MuiOutlinedInput-root": {
			"&.Mui-disabled fieldset": {
				borderColor: "transparent",
				boxShadow: "none !important",
			},
			"& fieldset": {
				borderColor: "transparent",
			},
			"&.Mui-focused fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&:hover fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&.Mui-error fieldset": {
				borderColor: theme.palette.error.main,
				boxShadow: "0 0 5px ".concat(theme.palette.error.main),
			},
		},
	},
	inputField: {
		color: theme.palette.text.main,
		"&>*:-webkit-autofill": {
			boxShadow: "0 0 0 1000px black inset!important",
			"-webkit-text-fill-color": "white !important",
			"-webkit-text-size-adjust": "none !important",
		},
	},
	label: {
		fontSize: "small",
		textAlign: "left",
		marginTop: theme.spacing(1),
	},
	icon: {color: theme.palette.text.placeholder}
}));

import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    paperImage: {
        '& .MuiPaper-root': {
            position: 'relative',
            height: '280px',
            width: '200px'
        },
        '& .MuiButtonBase-root': {
            position: 'absolute',
            zIndex: 2,
            margin: 0,
            paddingTop: '4px',
            paddingLeft: '4px'
        }
    },
    unchecked: {
        color: theme.palette.secondary.main
    },
    checked: {
        color: theme.palette.secondary.main
    },
    label: {
        fontSize: "small",
        textAlign: "center"
    },
    textBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '200px',
        height: '32px',
        fontSize: '2rem !important',
        fontWeight: '500 !important',
        fontStyle: 'italic'
    },
    movieFrameContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    movieIcon: {
        position: 'relative',
        left: 170,
        top: 2,
        marginRight: -24,    
        color: theme.palette.button.deactive
    },
    movieCheckbox: {
        position: 'relative',
        left: 4
    }
}));
import React from "react";
import {useStyles} from "./FiltersHeaderStyle";
import Grid from "@material-ui/core/Grid";
import ChipElement from "../../../ChipElement/ChipElement";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {Check} from "@material-ui/icons";
import {useIntl} from "react-intl";

const FiltersHeader = props => {
    const { filtersApplied, selectAllHandler, selectInPage, unselectAllHandler, unselectInPage } = props;
    const classes = useStyles();
    const intl = useIntl();

    const chipList = filtersApplied.map(key => {
        return <ChipElement key={key} label={key} cssType={'info'}/>;
    });

    return (
        <Grid container
              direction='row'
              justify="flex-start"
              alignItems="center"
              spacing={4}>
            <Grid item xs={7} className={classes.filterChip} data-cy="search-chip-list">
                <Grid container
                      direction='row'
                      justify="flex-start"
                      alignItems="center">
                    <Grid item>
                        {chipList}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Grid container
                      direction='row'
                      justify="flex-end"
                      alignItems="center"
                      data-cy="search-table-actions-list"
                >
                    <Grid item xs={3} className={classes.button}>
                        <IconButton onClick={selectAllHandler}>
                            <Check/><Typography>{intl.formatMessage({
                            id: "common.select.all",
                            defaultMessage: 'Select All'
                        })}</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3} className={classes.button}>
                        <IconButton onClick={unselectAllHandler}>
                            <Check/><Typography>{intl.formatMessage({
                            id: "common.unselect.all",
                            defaultMessage: 'Unselect All'
                        })}</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3} className={classes.button}>
                        <IconButton onClick={selectInPage}>
                            <Check/><Typography>{intl.formatMessage({
                            id: "common.select.in.page",
                            defaultMessage: 'Select in Page'
                        })}</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={3} className={classes.button}>
                        <IconButton onClick={unselectInPage}>
                            <Check/><Typography>{intl.formatMessage({
                            id: "common.unselect.in.page",
                            defaultMessage: 'Unselect in Page'
                        })}</Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default FiltersHeader;
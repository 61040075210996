import {GET_RICHIEDENTE_FAILURE, GET_RICHIEDENTE_REQUEST, GET_RICHIEDENTE_SUCCESS} from "../actions/richiedente";

const initialState = {richiedente: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RICHIEDENTE_REQUEST:
            return {
                loadingRichiedente: true
            };
        case GET_RICHIEDENTE_SUCCESS:
            return {
                ...state,
                loadingRichiedente: false,
                richiedente: action.richiedente,
            };
        case GET_RICHIEDENTE_FAILURE:
            return {
                ...state,
                loadingRichiedente: false,
                error: action.error
            }
        default:
            return state;
    }
}
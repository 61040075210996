import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	dropzoneRoot: {
		"& .MuiDialog-paper": {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.text.main,
		},
		"& .MuiButton-root.Mui-disabled": {
			color: theme.palette.text.placeholder,
		},
		"& .MuiButton-root": {
			color: theme.palette.text.main,
		},
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& .MuiDialogContent-root": {
			maxHeight: "400px"
		},
	},
	dropzoneMain: {
		backgroundColor: theme.palette.background.main,
		minHeight: "150px",
		maxWidth: "400px",
		"& .MuiDropzoneArea-icon": {
			color: theme.palette.text.placeholder,
		},
	},
	dropzoneParagraph: {
		color: theme.palette.text.placeholder,
		padding: theme.spacing(0, 3),
		maxWidth: '400px',
		fontSize: '1rem',
		fontWeight: 500,
		textTransform: 'uppercase'
	},
	chips: {
		backgroundColor: 'transparent',
		color: theme.palette.text.main,
		borderRadius: theme.spacing(1),
		'&:focus': {
			backgroundColor: 'black'
		},
		"& .MuiChip-deleteIcon": {
			fill: theme.palette.text.placeholder,
		}
	}
}));

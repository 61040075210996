import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import {useStyles} from "./DrawerElementStyle";
import useTheme from "@material-ui/core/styles/useTheme";


const DrawerElement = props => {

    const classes = useStyles();
    const theme = useTheme();
    console.log(props);
    const { open, handleClose, anchor } = props;



    return (
            <Drawer
                className={anchor === "right" ? classes.drawer : classes.drawerBottom}
                variant="persistent"
                anchor={anchor}
                open={open}
                classes={{
                    paper: anchor === "right" ? classes.drawerPaper : classes.drawerPaperBottom,
                }}
            >
                <div className={classes.drawerHeader}>
                    {anchor === "right" && <IconButton onClick={handleClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>}
                    {anchor === "bottom" && <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>}
                </div>
                <Divider/>
                {props.children}
            </Drawer>
    )
};

export default DrawerElement;

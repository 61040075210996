import {
    CLEAR_EXPORT_TO_YNAP,
    EXPORT_TO_YNAP_FAILURE,
    EXPORT_TO_YNAP_REQUEST,
    EXPORT_TO_YNAP_SUCCESS
} from "../../actions/search/exportYNAP";


const initialState = { loading: false, productsInError: [], actionType: null, errorType: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case EXPORT_TO_YNAP_REQUEST:
            return {
                loading: true,
                actionType: 'POST'
            };
        case EXPORT_TO_YNAP_SUCCESS:
            return {
                ...state,
                loading: false,
                errorType: action.error,
                productsInError: action.productsInError,
            };
        case EXPORT_TO_YNAP_FAILURE:
            return {
                ...state,
                loading: false,
                errorType: action.error
            };
        case CLEAR_EXPORT_TO_YNAP:
            return initialState;
        default:
            return state;
    }
};
import React from "react";
import {useIntl} from "react-intl";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";


const SnackbarElement = props => {

    const intl = useIntl();
    const { open, handleClose, labelId, label, type, labelParams, showDuration } = props;

    let text;

    if (labelId && label) {
        text = intl.formatMessage({ 'id': labelId, 'defaultMessage': label }, labelParams);
    } else if (label && !labelId) {
        text = label;
    } else {
        text = '';
    }

    return (
        <Snackbar data-cy="snackbar-alert" open={open} autoHideDuration={showDuration ? showDuration : 3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type}>
                {text}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarElement;
import {combineReducers} from 'redux';
import authReducer from '../store/reducers/auth';
import searchContextReducer from './reducers/search/search_context';
import seasonalityReducer from "./reducers/search/seasonality";
import dressOriginReducer from "./reducers/search/dress_origin";
import richiedenteReducer from "./reducers/richiedente";
import brandReducer from "./reducers/brand";
import targetReducer from "./reducers/target";
import pimTargetReducer from "./reducers/pim_target";
import linelistReducer from "./reducers/linelist";
import familyNameReducer from "./reducers/family_name";
import l1CategoryReducer from "./reducers/search/l1_category";
import gaoDescriptionReducer from "./reducers/search/gao_description";
import pimStatusReducer from "./reducers/search/pim_status";
import genderReducer from "./reducers/gender";
import labelReducer from "./reducers/search/label";
import l2ProductGroupingReducer from "./reducers/search/l2_product_grouping";
import l3ProductReducer from "./reducers/search/l3_product";
import assetTypeReducer from "./reducers/search/asset_type";
import seasonReducer from "./reducers/season";
import serviceReducer from "./reducers/service";
import photolabReducer from "./reducers/photolab";
import setReducer from "./reducers/search/set";
import statusReducer from "./reducers/search/status";
import shootTypeReducer from "./reducers/search/shoot_type";
import dimensionReducer from "./reducers/search/dimension";
import viewReducer from "./reducers/view";
import userReducer from '../store/reducers/user';
import searchReducer from '../store/reducers/search/search';
import searchSelectionReducer from "./reducers/search/selectAll";
import searchDownloadReducer from "./reducers/search/download";
import masterDataReducer from '../store/reducers/masterdata/masterdata';
import digitalAssetsReducer from './reducers/uploadDigitalAssets/digitalAssets';
import postProductionReducer from './reducers/postProduction/postProduction';
import postProducersReducer from './reducers/postProduction/postProducer';
import favouritesReducer from "./reducers/favourites";
import searchDialogInfo from "./reducers/search/dialogInfo";
import approvalReducer from "./reducers/approval/approval";
import sessioniOrfaneReducer from "./reducers/sessioniOrfane/sessioniOrfane";
import productTypeReducer from "./reducers/uploadDigitalAssets/productType";
import auditReducer from "./reducers/audit";
import transactionsReducer from "./reducers/transactions";
import fabricReducer from "./reducers/fabric";
import colorReducer from "./reducers/color";
import productAllReducer from "./reducers/viewManagement/productAll";
import shootingDownloadReducer from "./reducers/shooting/shootingDownload";
import exportToYnapReducer from "./reducers/search/exportYNAP";
import assetsReducer from "./reducers/search/assets";
import documentsReducer from "./reducers/documentation";
import columnsSettingsReducer from "./reducers/search/columns_settings";
import eventsReducer from "./reducers/showroom/event";
import outputTypesReducer from "./reducers/showroom/composition/output_type";
import contextShowroomReducer from "./reducers/showroom/context";
import looksReducer from "./reducers/showroom/composition/looks";
import lookReducer from "./reducers/showroom/composition/look";
import uploadAssetsCompositionShowroomReducer from "./reducers/showroom/composition/uploadAssets";
import galleriesCompositionShowroomReducer from "./reducers/showroom/composition/galleries";
import lookcastExportReducer from "./reducers/showroom/composition/exportToLookcast";
import showroomDownloadsReducer from "./reducers/showroom/composition/downloads";
import galleryProductShowroomReducer from "./reducers/showroom/product/gallery";
import galleriesProductShowroomReducer from "./reducers/showroom/product/galleries";
import galleryActionsProductShowroomReducer from "./reducers/showroom/product/galleryActions";
import productsGalleryProductShowroomReducer from "./reducers/showroom/product/products";
import productGalleryProductShowroomReducer from "./reducers/showroom/product/product";
import lookcastExportProductShowroomReducer from "./reducers/showroom/product/exportToLookcast";
import exportStatusReducer from "./reducers/exportStatus";
import imageHdReducer from "./reducers/imageHd";
import appLogsReducer from "./reducers/appLogs"

const appReducer = combineReducers({
    authReducer,
    searchContextReducer,
    seasonalityReducer,
    dressOriginReducer,
    richiedenteReducer,
    brandReducer,
    l1CategoryReducer,
    gaoDescriptionReducer,
    pimStatusReducer,
    genderReducer,
    labelReducer,
    l2ProductGroupingReducer,
    l3ProductReducer,
    assetTypeReducer,
    seasonReducer,
    serviceReducer,
    photolabReducer,
    setReducer,
    statusReducer,
    exportStatusReducer,
    shootTypeReducer,
    dimensionReducer,
    viewReducer,
    userReducer,
    searchReducer,
    searchSelectionReducer,
    searchDownloadReducer,
    masterDataReducer,
    digitalAssetsReducer,
    postProductionReducer,
    postProducersReducer,
    favouritesReducer,
    searchDialogInfo,
    approvalReducer,
    sessioniOrfaneReducer,
    productTypeReducer,
    auditReducer,
    transactionsReducer,
    fabricReducer,
    colorReducer,
    productAllReducer,
    eventsReducer,
    outputTypesReducer,
    contextShowroomReducer,
    looksReducer,
    lookReducer,
    uploadAssetsCompositionShowroomReducer,
    galleriesCompositionShowroomReducer,
    lookcastExportReducer,
    shootingDownloadReducer,
    exportToYnapReducer,
    assetsReducer,
    documentsReducer,
    columnsSettingsReducer,
    showroomDownloadsReducer,
    galleryProductShowroomReducer,
    galleryActionsProductShowroomReducer,
    galleriesProductShowroomReducer,
    productsGalleryProductShowroomReducer,
    productGalleryProductShowroomReducer,
    lookcastExportProductShowroomReducer,
    imageHdReducer,
    targetReducer,
    pimTargetReducer,
    linelistReducer,
    familyNameReducer,
    appLogsReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGGED_OUT') {
        state = undefined
    }

    return appReducer (state, action);
};

export default rootReducer;

import {
    CLEAR_GALLERIES,
    CLEAR_GALLERY_NAMES,
    CLEAR_NEW_GALLERY,
    GET_GALLERIES_DRAFT_FAILED,
    GET_GALLERIES_DRAFT_SUCCESS,
    GET_GALLERIES_FAILED,
    GET_GALLERIES_FINAL_FAILED,
    GET_GALLERIES_FINAL_SUCCESS,
    GET_GALLERIES_REQUEST,
    GET_GALLERIES_SUCCESS,
    GET_GALLERY_NAMES_FAILURE,
    GET_GALLERY_NAMES_REQUEST,
    GET_GALLERY_NAMES_SUCCESS,
    POST_NEW_GALLERY_FAILED,
    POST_NEW_GALLERY_REQUEST,
    POST_NEW_GALLERY_SUCCESS,
    UPDATE_ASSETS_ORDER_GALLERIES_FAILED,
    UPDATE_ASSETS_ORDER_GALLERIES_REQUEST,
    UPDATE_ASSETS_ORDER_GALLERIES_SUCCESS
} from "../../../actions/showroom/composition/galleries";

const initialState = {
    galleries: [],
    galleriesDraft: [],
    galleriesFinal: [],
    loadingShowroomGalleries: false,
    errorGalleries: false,
    errorDraft: false,
    errorFinal: false,
    statusUpdateOrder: '',
    loadingNewGallery: false,
    errorNewGallery: null,
    loadingAssetsOrder: false,
    loadingGalleryNames: false,
    galleryNames: [],
    allGalleryNames: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_GALLERIES:
            return initialState;
        case GET_GALLERIES_REQUEST:
            return {
                ...state,
                loadingShowroomGalleries: true
            };
        case UPDATE_ASSETS_ORDER_GALLERIES_REQUEST:
            return {
                ...state,
                loadingAssetsOrder: true
            };
        case POST_NEW_GALLERY_REQUEST:
            return {
                ...state,
                loadingNewGallery: true
            };
        case GET_GALLERIES_SUCCESS:
            return {
                ...state,
                galleries: action.galleries,
                loadingShowroomGalleries: false
            };
        case GET_GALLERIES_DRAFT_SUCCESS:
            return {
                ...state,
                galleriesDraft: action.galleriesDraft,
                loadingShowroomGalleries: false
            };
        case GET_GALLERIES_FINAL_SUCCESS:
            return {
                ...state,
                galleriesFinal: action.galleriesFinal,
                loadingShowroomGalleries: false
            };
        case POST_NEW_GALLERY_SUCCESS:
            return {
                ...state,
                loadingNewGallery: false,
                errorNewGallery: false
            };
        case GET_GALLERIES_FAILED:
            return {
                ...state,
                errorGalleries: true,
                loadingShowroomGalleries: false
            };
        case GET_GALLERIES_DRAFT_FAILED:
            return {
                ...state,
                errorDraft: true,
                loadingShowroomGalleries: false
            };
        case GET_GALLERIES_FINAL_FAILED:
            return {
                ...state,
                errorFinal: true,
                loadingShowroomGalleries: false
            };
        case POST_NEW_GALLERY_FAILED:
            return {
                ...state,
                loadingNewGallery: false,
                errorNewGallery: true
            };
        case UPDATE_ASSETS_ORDER_GALLERIES_SUCCESS:
            return {
                ...state,
                loadingAssetsOrder: false,
                statusUpdateOrder: 'OK'
            };
        case UPDATE_ASSETS_ORDER_GALLERIES_FAILED:
            return {
                ...state,
                loadingAssetsOrder: false,
                statusUpdateOrder: 'KO'
            };
        case GET_GALLERY_NAMES_REQUEST:
            return {
                ...state,
                loadingGalleryNames: true
            };
        case GET_GALLERY_NAMES_SUCCESS:
            return {
                ...state,
                loadingGalleryNames: false,
                galleryNames: action.galleryNames,
                allGalleryNames: state.allGalleryNames.concat(action.galleryNames)
            };
        case GET_GALLERY_NAMES_FAILURE:
            return {
                ...state,
                loadingGalleryNames: false,
                errorGalleries: action.errorNames
            };
        case CLEAR_GALLERY_NAMES:
            return {
                ...state,
                galleryNames: [],
                allGalleryNames: []
            };
        case CLEAR_NEW_GALLERY:
            return {
                ...state,
                loadingNewGallery: false,
                errorNewGallery: null
            };
        default:
            return state;
    }
}
import {GET_EVENT_FAILURE, GET_EVENT_REQUEST, GET_EVENT_SUCCESS} from "../../actions/showroom/event";

const initialState = { events: [] };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_REQUEST:
            return {
                loadingEvents: true
            };
        case GET_EVENT_SUCCESS:
            return {
                ...state,
                loadingEvents: false,
                events: action.events,
            };
        case GET_EVENT_FAILURE:
            return {
                ...state,
                loadingEvents: false,
                error: action.error
            };
        default:
            return state;
    }
}
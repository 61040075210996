import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";

const localStorageService = LocalStorage.getService();

export const CLEAR_GALLERIES = 'CLEAR_GALLERIES';
export const CLEAR_GALLERY_NAMES = 'CLEAR_GALLERY_NAMES';

export const GET_GALLERIES_REQUEST = 'GET_GALLERIES_REQUEST';
export const GET_GALLERIES_SUCCESS = 'GET_GALLERIES_SUCCESS';
export const GET_GALLERIES_FAILED = 'GET_GALLERIES_FAILED';

export const GET_GALLERIES_DRAFT_SUCCESS = 'GET_GALLERIES_DRAFT_SUCCESS';
export const GET_GALLERIES_DRAFT_FAILED = 'GET_GALLERIES_DRAFT_FAILED';

export const GET_GALLERIES_FINAL_SUCCESS = 'GET_GALLERIES_FINAL_SUCCESS';
export const GET_GALLERIES_FINAL_FAILED = 'GET_GALLERIES_FINAL_FAILED';

export const GET_GALLERY_NAMES_REQUEST = 'GET_GALLERY_NAMES_REQUEST';
export const GET_GALLERY_NAMES_SUCCESS = 'GET_GALLERY_NAMES_SUCCESS';
export const GET_GALLERY_NAMES_FAILURE = 'GET_GALLERY_NAMES_FAILURE';

export const clearShowroomGalleries = () => {
    return { type: CLEAR_GALLERIES }
};

export const getGalleriesRequest = () => {
    return { type: GET_GALLERIES_REQUEST }
};

export const getGalleriesSuccess = (photoType, galleries) => {
    if (photoType === 'final') {
        return { type: GET_GALLERIES_FINAL_SUCCESS, galleriesFinal: galleries }
    } else if (photoType === 'polaroid') {
        return { type: GET_GALLERIES_DRAFT_SUCCESS, galleriesDraft: galleries }
    } else {
        return { type: GET_GALLERIES_SUCCESS, galleries }
    }
};

export const getGalleriesFailed = (photoType, error) => {
    if (photoType === 'final') {
        return { type: GET_GALLERIES_FINAL_FAILED, error }
    } else if (photoType === 'polaroid') {
        return { type: GET_GALLERIES_DRAFT_FAILED, error }
    } else {
        return { type: GET_GALLERIES_FAILED, error }
    }
};


export const startGetShowroomGalleries = (contextId, filter) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetShowroomGalleries with: contextId=', contextId, ' filter=', filter);
    return (dispatch) => {
        dispatch(getGalleriesRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/galleries/digital_assets?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&filter_assets=${filter}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetShowroomGalleries, filter: ', filter, ' - ', response.data);
                    dispatch(getGalleriesSuccess(filter, response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getGalleriesFailed(filter, error));
            });
    }
};

export const getGalleryNamesRequest = () => {
    return {type: GET_GALLERY_NAMES_REQUEST}
}

export const getGalleryNamesSuccess = (galleryNames) => {
    return {type: GET_GALLERY_NAMES_SUCCESS, galleryNames}
}

export const getGalleryNamesFailure = (errorNames) => {
    return {type: GET_GALLERY_NAMES_FAILURE, errorNames}
}

export const startClearGalleryNames = () => {
    return {type: CLEAR_GALLERY_NAMES}
}

export const startGetGalleryNamesFromOutputTypes = (outputTypeId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetGalleryNamesFromOutputTypes with outputTypeId=', outputTypeId);
    return (dispatch) => {
        dispatch(getGalleryNamesRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/output_types/${outputTypeId}/galleries?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetGalleryNamesFromOutputTypes: ', response.data);
                    dispatch(getGalleryNamesSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getGalleryNamesFailure(error));
            });
    }
};


export const UPDATE_ASSETS_ORDER_GALLERIES_REQUEST = 'UPDATE_ASSETS_ORDER_GALLERIES_REQUEST';
export const UPDATE_ASSETS_ORDER_GALLERIES_SUCCESS = 'UPDATE_ASSETS_ORDER_GALLERIES_SUCCESS';
export const UPDATE_ASSETS_ORDER_GALLERIES_FAILED = 'UPDATE_ASSETS_ORDER_GALLERIES_FAILED';

export const putGalleriesAssetsOrderRequest = () => {
    return { type: UPDATE_ASSETS_ORDER_GALLERIES_REQUEST }
};

export const putGalleriesAssetsOrderSuccess = () => {
    return { type: UPDATE_ASSETS_ORDER_GALLERIES_SUCCESS }
};

export const putGalleriesAsstesOrderFailed = (error) => {
    return { type: UPDATE_ASSETS_ORDER_GALLERIES_FAILED, error }
};

export const startUpdateAssetsOrderGallery = (contextId, galleryId, assetsOrder, filter) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startUpdateAssetsOrderGallery with: \ncontextId=', contextId, ' \ngalleryId=', galleryId, ' \nphotosOrder=', assetsOrder);
    return (dispatch) => {
        dispatch(putGalleriesAssetsOrderRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/context/${contextId}/galleries/${galleryId}/assets_order?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify({ digital_assets: assetsOrder })
        })
            .then(function (response) {
                if (response.status === 204) {
                    console.log('Success Response startGetShowroomGalleries');
                    dispatch(putGalleriesAssetsOrderSuccess());
                    dispatch(startGetShowroomGalleries(contextId, filter));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(putGalleriesAsstesOrderFailed(error));
            });
    }
};


export const POST_NEW_GALLERY_REQUEST = 'POST_NEW_GALLERY';
export const POST_NEW_GALLERY_SUCCESS = 'POST_NEW_GALLERY_SUCCESS';
export const POST_NEW_GALLERY_FAILED = 'POST_NEW_GALLERY_FAILED';
export const CLEAR_NEW_GALLERY = 'CLEAR_NEW_GALLERY';

export const postNewGalleryRequest = () => {
    return { type: POST_NEW_GALLERY_REQUEST }
};

export const postNewGallerySuccess = (newGalleryInfo) => {
    return { type: POST_NEW_GALLERY_SUCCESS, newGalleryInfo }
};

export const postNewGalleryFailed = (error) => {
    return { type: POST_NEW_GALLERY_FAILED, error }
};

export const clearNewGallery = () => {
    return { type: CLEAR_NEW_GALLERY }
};

export const startCreateNewGallery = (outputType, galleryName) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startCreateNewGallery with: \n- outputType: ', outputType, '\n- galleryName: ', galleryName);
    return (dispatch) => {
        dispatch(postNewGalleryRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/output_types/${outputType}/galleries?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify({ name: galleryName })
        })
            .then(function (response) {
                if (response.status === 201) {
                    console.log('Response startCreateNewGallery: ', response.data);
                    dispatch(postNewGallerySuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(postNewGalleryFailed(error));
            });
    }
};
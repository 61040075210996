import {
    CLEAR_APPROVAL_ASSETS,
    CLEAR_ERROR_STATUS,
    POST_APPROVE_ASSETS_FAILURE,
    POST_APPROVE_ASSETS_REQUEST,
    POST_APPROVE_ASSETS_SUCCESS,
    POST_MIGRATE_ASSETS_FAILURE,
    POST_MIGRATE_ASSETS_REQUEST,
    POST_MIGRATE_ASSETS_SUCCESS,
    POST_REJECT_ASSETS_FAILURE,
    POST_REJECT_ASSETS_REQUEST,
    POST_REJECT_ASSETS_SUCCESS,
    POST_RESET_ASSETS_FAILURE,
    POST_RESET_ASSETS_REQUEST,
    POST_RESET_ASSETS_SUCCESS,
    WRONG_ASSETS_STATUS
} from "../../actions/approval/approval";
import {SEARCH_ACTION_REQUEST} from "../../../utils/constant";


const initialState = { action: null, loading: false, assets: [], error: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_APPROVE_ASSETS_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.postApprove,
                assets: []
            }
        case POST_REJECT_ASSETS_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.postReject,
                assets: []
            }
        case POST_RESET_ASSETS_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.postReset,
                assets: []
            }
        case POST_MIGRATE_ASSETS_REQUEST:
            return {
                ...state,
                loading: true,
                action: SEARCH_ACTION_REQUEST.postMigrate,
                assets: []
            }
        case POST_APPROVE_ASSETS_SUCCESS:
        case POST_REJECT_ASSETS_SUCCESS:
        case POST_RESET_ASSETS_SUCCESS:
        case POST_MIGRATE_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false,
                assets: action.response.assets
            }
        case POST_APPROVE_ASSETS_FAILURE:
        case POST_RESET_ASSETS_FAILURE:
        case POST_REJECT_ASSETS_FAILURE:
        case POST_MIGRATE_ASSETS_FAILURE:
            return {
                ...state,
                loading: false,
                assets: [],
                error: action.error
            }
        case WRONG_ASSETS_STATUS:
            return {
                ...state,
                loading: false,
                assets: [],
                error: true
            }
        case CLEAR_ERROR_STATUS:
            return {
                ...state,
                error: false
            }
        case CLEAR_APPROVAL_ASSETS:
            return initialState;
        default:
            return state;
    }
}

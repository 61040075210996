import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";

const localStorageService = LocalStorage.getService();

export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

export const clearProduct = () => {
    return { type: CLEAR_PRODUCT }
};

export const getProductRequest = () => {
    return { type: GET_PRODUCT_REQUEST }
};

export const getProductSuccess = (product) => {
    return { type: GET_PRODUCT_SUCCESS, product }
};

export const getProductFailed = (error) => {
    return { type: GET_PRODUCT_FAILED, error }
};

/**
 * Fetching product info.
 *
 * @param galleryId {number}: id of gallery which user is going to define the automatic sorting rules.
 * @param productGuid {string}: product identifier.
 */
export const startGetProduct = (galleryId, productGuid) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetProduct with: galleryID --> ', galleryId, ' productGuid --> ', productGuid);
    return (dispatch) => {
        dispatch(getProductRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/products/${productGuid}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            }
        })
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log('Response startGetProduct: ', response.data);
                    dispatch(getProductSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startGetProduct: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getProductFailed(error));
            });
    }
};
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    switchBase: {
        color: theme.palette.action.disabled,
        '&$checked': {
            color: theme.palette.button.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.button.main,
        },
    },
    checked: {},
    track: {
        backgroundColor: theme.palette.action.disabled,
    }
}));
import {GET_FAMILY_NAME_FAILURE, GET_FAMILY_NAME_REQUEST, GET_FAMILY_NAME_SUCCESS} from "../actions/family_name";

const initialState = {pg_family_name: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_FAMILY_NAME_REQUEST:
            return {
                loadingFamilyName: true
            };
        case GET_FAMILY_NAME_SUCCESS:
            return {
                ...state,
                loadingFamilyName: false,
                pg_family_name: action.pg_family_name,
            };
        case GET_FAMILY_NAME_FAILURE:
            return {
                ...state,
                loadingFamilyName: false,
                error: action.error
            };
        default:
            return state;
    }
}
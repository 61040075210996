import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

const localStorageService = LocalStorage.getService();

export const GET_UPLOAD_TYPE_REQUEST = "GET_UPLOAD_TYPE_REQUEST";
export const GET_UPLOAD_TYPE_SUCCESS = "GET_UPLOAD_TYPE_SUCCESS";
export const GET_UPLOAD_TYPE_FAILURE = "GET_UPLOAD_TYPE_FAILURE";

export const getUploadTypeRequest = () => {
    return { type: GET_UPLOAD_TYPE_REQUEST };
}

export const getUploadTypeSuccess = (upload_type) => {
    return { type: GET_UPLOAD_TYPE_SUCCESS, upload_type }
}

export const getUploadTypeFailure = (uploadTypeError) => {
    return { type: GET_UPLOAD_TYPE_FAILURE, uploadTypeError }
}

export const startGetUploadType = () => {
    console.log('Start startGetUploadType');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getUploadTypeRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/upload_type/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response upload_type ", response.data);
                    dispatch(getUploadTypeSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getUploadTypeFailure(error));
            });
    };
};


export const UPLOAD_DIGITAL_ASSETS_REQUEST = "UPLOAD_DIGITAL_ASSETS_REQUEST";
export const UPLOAD_DIGITAL_ASSETS_SUCCESS = "UPLOAD_DIGITAL_ASSETS_SUCCESS";
export const UPLOAD_DIGITAL_ASSETS_FAILURE = "UPLOAD_DIGITAL_ASSETS_FAILURE";
export const CLEAR_UPLOADED_IMAGES = "CLEAR_UPLOADED_IMAGES";
export const CLEAR_RESPONSE_ASSETS = "CLEAR_RESPONSE_ASSETS";

export const uploadDigitalAssetsRequest = () => {
    return { type: UPLOAD_DIGITAL_ASSETS_REQUEST };
};

export const uploadDigitalAssetsSuccess = (response) => {
    return { type: UPLOAD_DIGITAL_ASSETS_SUCCESS, response };
};

export const uploadDigitalAssetsFailure = (error) => {
    return { type: UPLOAD_DIGITAL_ASSETS_FAILURE, error };
};

export const startClearUploadedImages = () => {
    return { type: CLEAR_UPLOADED_IMAGES };
};

export const startClearResponseAssets = () => {
    return { type: CLEAR_RESPONSE_ASSETS };
};


/*export const GET_SIGNED_URL_IMAGES_SUCCESS = "GET_SIGNED_URL_IMAGES_SUCCESS";
export const GET_SIGNED_URL_IMAGES_FAILURE = "GET_SIGNED_URL_IMAGES_FAILURE";

export const getSignedUrlImagesSuccess = (signedUrl) => {
    return { type: GET_SIGNED_URL_IMAGES_SUCCESS, signedUrl };
};

export const getSignedUrlImagesFailure = (signedUrlError, signedUrlImg) => {
    return { type: GET_SIGNED_URL_IMAGES_FAILURE, signedUrlError, signedUrlImg };
};*/


export const startGetSignedUrlImages = (images) => {
    let token = localStorageService.getAccessToken();
    console.log("CALLING startGetSignedUrlImages with ", images);
    return (dispatch) => {
        dispatch(uploadDigitalAssetsRequest());
        
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/getSignedUrl?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: {
                files: images.map(i => ({ name: i.path, operation: 'PUT' }))
            }
        })
            .then(function (response) {
                console.log("Response startGetSignedUrlImages ", response);
                if (response.status === 200 && response.data !== null) {
                    Object.keys(response.data.urls).forEach(imgKey => {
                        let imgObj = images.find(i => i.path === imgKey);

                        dispatch(uploadSingleImage(response.data.urls[imgKey], imgObj));
                    })
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                // TODO: probably error should be handled. It's quite impossible that google is not enable to
                //  generate a signed url but maybeeee.....
                // dispatch(getSignedUrlImagesFailure(error, i.path));
            })
    }
};

export const UPLOAD_SINGLE_IMG_SUCCESS = "UPLOAD_SINGLE_IMG_SUCCESS";
export const UPLOAD_SINGLE_IMG_FAILURE = "UPLOAD_SINGLE_IMG_FAILURE";

export const uploadSingleImgSuccess = (uploadedImg) => {
    return { type: UPLOAD_SINGLE_IMG_SUCCESS, uploadedImg };
};

export const uploadSingleImgFailure = (uploadedImgError, errorImg) => {
    return { type: UPLOAD_SINGLE_IMG_FAILURE, uploadedImgError, errorImg };
};

export const uploadSingleImage = (url, img) => {
    console.log("CALLING uploadSingleImage with ", url, img, typeof img);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        return axios({
            url: url,
            method: 'PUT',
            headers: {
                'Content-Type': img.type,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: img,
        })
            .then(function (response) {
                console.log("Response uploadSingleImage ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log(response.data);
                    dispatch(uploadSingleImgSuccess(img.path));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                dispatch(uploadSingleImgFailure(error, img.path));
            })
    }
};

export const startUploadDigitalAssets = (files, context, upload_type) => {
    console.log("Calling startUploadDigitalAssets with ", files, context, upload_type);
    let token = localStorageService.getAccessToken();
    let bodyData = {
        richiedente_service: context.richiedente_service,
        season: context.season,
        shoot_type: "1",
        product_type: "1",
        upload_type: upload_type,
        files: files
    }
    return (dispatch) => {
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/finalizeUpload?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify(bodyData),
        })
            .then(function (response) {
                console.log("Response startUploadDigitalAssets ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(uploadDigitalAssetsSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(uploadDigitalAssetsFailure(error));
            })
    };
};


export const CHECK_DELETED_ASSETS_REQUEST = "CHECK_DELETED_ASSETS_REQUEST";
export const CHECK_DELETED_ASSETS_SUCCESS = "CHECK_DELETED_ASSETS_SUCCESS";
export const CHECK_DELETED_ASSETS_FAILURE = "CHECK_DELETED_ASSETS_FAILURE";
export const CLEAR_DELETED_ASSETS = "CLEAR_DELETED_ASSETS";

export const checkDeletedAssetsRequest = () => {
    return { type: CHECK_DELETED_ASSETS_REQUEST };
};

export const checkDeletedAssetsSuccess = (response) => {
    return { type: CHECK_DELETED_ASSETS_SUCCESS, assets: response.assets };
};

export const checkDeletedAssetsFailure = () => {
    return { type: CHECK_DELETED_ASSETS_FAILURE };
};

export const clearDeletedAssets = () => {
    return { type: CLEAR_DELETED_ASSETS };
};


export const startCheckDeletedAssets = (context, assetsList) => {
    console.log("Calling startCheckDeletedAssets with ", context, assetsList);
    let token = localStorageService.getAccessToken();
    let bodyData = {
        richiedente_guid: context.richiedente,
        service_guid: context.service,
        season_guid: context.season,
        assets_list: assetsList
    }
    return (dispatch) => {
        dispatch(checkDeletedAssetsRequest);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/checkDeletedAssets?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify(bodyData),
        })
            .then(function (response) {
                console.log("Response startCheckDeletedAssets ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(checkDeletedAssetsSuccess(response.data));
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(checkDeletedAssetsFailure(error));
            })
    };
};

export const CLEAR_UPLOAD_ASSETS = "CLEAR_UPLOAD_ASSETS";

export const clearUploadAssets = () => {
    return { type: CLEAR_UPLOAD_ASSETS };
};
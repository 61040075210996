import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {useIntl} from "react-intl";
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import {useStyles} from "./InputTextElementStyle";

const InputTextElement = props => {

    const {
        label,
        name,
        labelId,
        value,
        handleChange,
        handleKeyPress,
        autoFocus,
        type,
        error,
        disabled,
        icon,
        placeholder,
        placeholderId,
        helperText,
        fullWidth,
        multiline,
        rowsNumber
    } = props;
    const intl = useIntl();
    const classes = useStyles();

    // console.log("VALUE STYLE FABRIC COLOR: ", value);

    return (
        <FormControl fullWidth={fullWidth}>
            {labelId && label &&
            <div className={classes.label}>
                {intl.formatMessage({ 'id': labelId, 'defaultMessage': label })}
            </div>}
            <TextField
                id={name}
                disabled={disabled}
                placeholder={placeholder && placeholderId && intl.formatMessage({
                    'id': placeholderId,
                    'defaultMessage': placeholder
                })}
                name={name}
                autoFocus={autoFocus}
                fullWidth={fullWidth}
                multiline={multiline}
                rows={rowsNumber}
                value={value}
                type={type}
                variant="outlined"
                margin="dense"
                color="secondary"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                className={fullWidth ? classes.textFieldFullWidth : classes.textField}
                error={error}
                helperText={helperText}
                InputProps={icon ? {
                    autoComplete: 'off',
                    className: classes.inputField,
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon className={classes.icon}>{icon}</Icon>
                        </InputAdornment>
                    ),
                } : { autoComplete: 'off', className: classes.inputField }}
            />
        </FormControl>
    )

};

export default InputTextElement;
import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import {useStyles} from "./GridCompactViewStyle";
import PreserveImageRatio from "../../../PreserveImageRatio/PreserveImageRatio";
import {backgroundColorUsingStatus, onErrorSrc} from "../../../../utils/CommonFunction";
import MovieIcon from "@material-ui/icons/Movie";

const GridCompactView = props => {
    const { data, selectHandler, openComparingDialogHandler, genericStyle } = props;
    const classes = useStyles();

    let items = null;

    if (data) {
        items = data.map((obj, index) => {
            const statusCssClass = backgroundColorUsingStatus(obj.status, genericStyle);

            return (
                <Grid item
                      key={index}
                      xs={6} md={3} lg={2}>
                    <Grid container
                          direction="column"
                          justify="center"
                          alignItems="center">
                        <Grid item xs={12}>
                            <div className={classes.textBox}>
                                <Typography>{obj.asset_name}...</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.paperImage}>
                            <Paper elevation={0} square>
                                <Checkbox icon={<Icon className={classes.unchecked}>{'radio_button_unchecked'}</Icon>}
                                          checkedIcon={
                                              <Icon
                                                  className={classes.checked}>{'radio_button_checked'}
                                              </Icon>
                                          }
                                          name={obj.style_fabric_color}
                                          size="medium"    // size: small, medium
                                          checked={obj.selected}
                                          onChange={(event) => selectHandler(event)}
                                          value={index}
                                          className={obj.asset_type === "VIDEO" ? classes.movieCheckbox : ''}/>
                                          { obj.asset_type === "VIDEO" ? 
                                            <div className={classes.movieFrameContainer}>
                                                <MovieIcon className={classes.movieIcon}/>
                                                <PreserveImageRatio
                                                    src={obj.asset_img_url}
                                                    height={280}
                                                    width={200}
                                                    isVideo
                                                />
                                            </div>
                                            :
                                            <PreserveImageRatio
                                                src={obj.asset_img_url}
                                                alt={"Image not found"}
                                                height={280}
                                                width={200}
                                                onClick={() => openComparingDialogHandler(obj.asset_guid, obj.status)}
                                                onError={(event => onErrorSrc(event))}/>
                                        }
                            </Paper>
                            <div className={`${classes.textBox} ${statusCssClass}`}>
                                <Typography>{obj.view}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )
        });
    }

    return (
        <Grid container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={5}>
            {items}
        </Grid>
    );
};

export default GridCompactView;
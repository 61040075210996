import {connect} from "react-redux";
import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {startGetDownloadDmg} from "../store/actions/shooting/shootingDownload";
import download_img from "../assets/images/download.png";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3, 3),
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "900px"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.main,
        width: "300px",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    gridPaper:{
        textAlign: 'center'
    },
    fields: {
        textAlign: "left",
        '& .MuiTypography-colorPrimary': {
            color: "#5e5c5b",
        }
    },
    titles: {
        textAlign: "left",
        fontWeight: "bold"
    },
    linkField: {
        marginLeft: theme.spacing(1),
        '& .MuiTypography-colorPrimary': {
            color: "#5e5c5b",
        }
    },
    downloadButtons: {
        marginLeft: theme.spacing(8),
    }
}));

const ShootingContainer = (props) => {
    const classes = useStyles();

    const intl = useIntl();

    useEffect(() => {
        props.getDownloadDmg();
    }, []);

    useEffect(() => {
        if(props.downloadUrl)
            console.log("ok here it is: ", props.downloadUrl)
    }, [props.downloadUrl])

    const downloadDoc = (e) => {
        e.preventDefault();
        window.open(props.downloadUrl.doc_url);
    }

    return (
        <div className={classes.root}>
            <BackdropElement loading={props.loadingDownloadUrl} />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className={classes.gridPaper}
            >
                <Grid item xs={5}>
            <Paper className={classes.paper}>

                        <img src={download_img}
                             alt={"Image not found"}
                             height={250}
                             width={250}/>
            </Paper>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={4}
                        >
                        <Grid item>
                        <Typography className={classes.titles}>Download</Typography>
                        <Typography className={classes.fields}>
                        {props.downloadUrl ? props.downloadUrl.name : ""}</Typography>
                        </Grid>
                        <Grid item>
                        <Typography className={classes.titles}>{intl.formatMessage({
                            id: "shooting.label.current.version",
                            defaultMessage: "Current Version:",
                        })}</Typography>
                            <Typography className={classes.fields}>   {props.downloadUrl ? props.downloadUrl.version : ""}</Typography>
                        </Grid>
                            <Grid item>
                                <Typography className={classes.titles}>{intl.formatMessage({
                                    id: "shooting.label.release.date",
                                    defaultMessage: "Release Date:",
                                })}</Typography>
                                <Typography className={classes.fields}>{props.downloadUrl ? props.downloadUrl.date : ""}</Typography>
                            </Grid>
                            <Grid item className={classes.downloadButtons}>
                                {props.downloadUrl &&
                                    <>
                                <ButtonElement
                                label={intl.formatMessage({
                                    id: "common.button.label.download",
                                    defaultMessage: "Download",
                                })}
                                onSubmit={() => window.open(props.downloadUrl.url)}
                                disabled={!props.downloadUrl}/>
                                <Typography className={classes.linkField}>
                                    <Link href="#" onClick={downloadDoc}>
                                        {intl.formatMessage({
                                            id: "shooting.label.installation.help",
                                            defaultMessage: "Installation Help",
                                        })}
                                    </Link>
                                </Typography>
                                {/*<LinkElement disabled={!props.downloadUrl.doc_url} defaultText={"Installation Help"} onClickHandler={()=> window.open(props.downloadUrl.doc_url)}/>*/}
                                </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </div>
    )
}

const mapStateToProps = state => ({
    downloadUrl: state.shootingDownloadReducer.downloadUrl,
    loadingDownloadUrl: state.shootingDownloadReducer.loadingDownloadUrl
});

const mapDispatchToProps = dispatch => ({
    getDownloadDmg: () => dispatch(startGetDownloadDmg())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShootingContainer);
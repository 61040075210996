import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";
import {ACTION_REQUEST} from "../../../utils/constant";

export const POST_GET_IMAGE_REQUEST = 'POST_GET_IMAGE_REQUEST';
export const POST_GET_IMAGE_SUCCESS = 'POST_GET_IMAGE_SUCCESS';
export const POST_GET_IMAGE_FAILURE = 'POST_GET_IMAGE_FAILURE';

const localStorageService = LocalStorage.getService();

export const getImageRequest = () => {
    return { type: POST_GET_IMAGE_REQUEST };
};

export const getImageSuccess = (comparingInfo) => {
    return { type: POST_GET_IMAGE_SUCCESS, comparingInfo };
};

export const getImageFailure = (error) => {
    return { type: POST_GET_IMAGE_FAILURE, error };
};

export const startGetImagesToCompare = (body) => {
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getImageRequest());
        console.log("Calling startGetImagesToCompare with body: ", body);
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/getAssetUrl?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: ACTION_REQUEST.post,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify(body)
            }
        )
            .then(function (response) {
                console.log("Response startGetImagesToCompare: ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(getImageSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getImageFailure(error));
            });
    };
};

export const GET_SEARCH_VIEW_MONITOR_REQUEST = 'GET_SEARCH_VIEW_MONITOR_REQUEST';
export const GET_SEARCH_VIEW_MONITOR_SUCCESS = 'GET_SEARCH_VIEW_MONITOR_SUCCESS';
export const GET_SEARCH_VIEW_MONITOR_FAILURE = 'GET_SEARCH_VIEW_MONITOR_FAILURE';

export const getSearchViewMonitorRequest = () => {
    return { type: GET_SEARCH_VIEW_MONITOR_REQUEST }
};

export const getSearchViewMonitorSuccess = (vmInfo) => {
    return { type: GET_SEARCH_VIEW_MONITOR_SUCCESS, vmInfo }
};

export const getSearchViewMonitorFailure = (error) => {
    return { type: GET_SEARCH_VIEW_MONITOR_FAILURE, error }
};


export const startGetViewMonitorInfo = (digitalCardGuid) => {
    let token = localStorageService.getAccessToken();
    console.log('Start calling startGetViewMonitorInfo.');
    console.table({ dcGuid: digitalCardGuid });
    return (dispatch) => {
        dispatch(getSearchViewMonitorRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/search/drill_down?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&dc_guid=${digitalCardGuid}`,
                method: ACTION_REQUEST.get,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            }
        )
            .then(function (response) {
                console.log("Response startGetViewMonitorInfo: ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(getSearchViewMonitorSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getSearchViewMonitorFailure(error));
            });
    };
};

export const CLEAR_SEARCH_INFO = 'CLEAR_SEARCH_INFO';

export const clearSearchInfo = () => {
    return { type: CLEAR_SEARCH_INFO }
};
import React, {useEffect, useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import {useStyles} from "./AutocompleteElementStyle";

const AutocompleteElement = (props) => {

    const {label, labelId, optionList, loading, callOptionList, name, handleOnChange} = props;
    const classes = useStyles();
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    console.log(props);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        } else if (callOptionList[name]) {
            callOptionList[name]();
        }
    }, [open])

    useEffect(() => {
        console.log("optionList WAAAAAAAAAA", optionList)
        if (optionList && optionList.length > 0) {
            let dropdownOptions = _.toArray(_.mapValues(optionList, function (opt) {
                return ({
                    label: opt.name,
                    value: opt.name
                })
            }));
            console.log(dropdownOptions)
            setOptions(dropdownOptions);
        }
    }, [optionList])


    return (
        <form className={classes.root}>
        <Autocomplete
            classes={{option: classes.option, listbox: classes.listbox, paper: classes.paper}}
            loading={loading}
            disableClearable
            autoHighlight
            options={options}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={handleOnChange}
            //getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => (
                <TextField
                    {...params}
                    // label={intl.formatMessage({'id': labelId, 'defaultMessage': label})}
                    placeholder={intl.formatMessage({'id': labelId, 'defaultMessage': label})}
                    variant="outlined"
                    margin="dense"
                    name={name}
                    color="secondary"
                    className={classes.autocomplete}
                    InputLabelProps={{classes: {root: classes.inputLabelRoot}}}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress className={classes.loader} size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />

    </form>);

}

export default AutocompleteElement;
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import {useStyles} from "./DropDownElementStyle";
import {useIntl} from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";


const DropDownElement = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const {name, value, onChangeHandler, icon, label, disabled, options, loading, upLabel, open} = props;

    // console.log("OPEN ", open, value);

    const optionItems = options.map((opt, index) => (
        <MenuItem key={index} value={opt.value} className={classes.menuItem}>
            {opt.label}
        </MenuItem>
    ));

    const iconElement = props.icon ? <Icon className={classes.icon}>{icon}</Icon> : null;

    return (
        <form className={classes.root}>
            {upLabel &&
            <div className={classes.upLabel}>
                {intl.formatMessage({'id': upLabel.id, 'defaultMessage': upLabel.defaultMessage})}
            </div>}
            <TextField
                id={name}
                select
                fullWidth
                name={name}
                value={value}
                onChange={onChangeHandler}
                className={classes.select}
                variant={props.variant ? props.variant : "outlined"}
                margin="dense"
                label={label ?
                    <span className={classes.cssLabel}>
                    {iconElement}
                        <div>
                            {intl.formatMessage({id: label.translateKey, defaultMessage: label.defaultText})}
                        </div>
                  </span> : null
                }
                SelectProps={{MenuProps: {classes: {paper: classes.paperMenu}}, open: open}}
                disabled={disabled}>
                {loading ? <div className={classes.cssLabel}><CircularProgress className={classes.loader} size={14}/>
                </div> : optionItems}
            </TextField>
        </form>);
};

export default DropDownElement;

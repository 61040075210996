import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    button: {
        '& .MuiIconButton-root': {
            color: theme.palette.text.main
        },
        '& .MuiTypography-root': {
            marginLeft: theme.spacing(1)
        }
    },
    filterChip: {
        '& .MuiChip-root': {
            margin: theme.spacing(0, 1),
            color: theme.palette.text.main,
            backgroundColor: theme.palette.secondary.main
        }
    }
}));
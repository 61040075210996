import LocalStorage from "../../localStorage";
import axios from "axios";
import {loggedOut} from "../auth";

export const SEARCH_SESSIONI_ORFANE_REQUEST = 'SEARCH_SESSIONI_ORFANE_REQUEST';
export const SEARCH_SESSIONI_ORFANE_SUCCESS = 'SEARCH_SESSIONI_ORFANE_SUCCESS';
export const SEARCH_SESSIONI_ORFANE_FAILED = 'SEARCH_SESSIONI_ORFANE_FAILED';
export const UPDATE_SESSIONI_ORFANE_REQUEST = 'UPDATE_SESSIONI_ORFANE_REQUEST';
export const UPDATE_SESSIONI_ORFANE_SUCCESS = 'UPDATE_SESSIONI_ORFANE_SUCCESS';
export const UPDATE_SESSIONI_ORFANE_FAILED = 'UPDATE_SESSIONI_ORFANE_FAILED';
export const CLEAR_SESSIONI_ORFANE = "CLEAR_SESSIONI_ORFANE";

export const UPLOAD_XSLX_FILE_REQUEST = "UPLOAD_XSLX_FILE_REQUEST";
export const UPLOAD_XSLX_FILE_SUCCESS = "UPLOAD_XSLX_FILE_SUCCESS";
export const UPLOAD_XSLX_FILE_FAILURE = "UPLOAD_XSLX_FILE_FAILURE";
export const UPDATE_DRAFT_PARING_BY_XSLX_SUCCESS = "UPDATE_DRAFT_PARING_BY_XSLX_SUCCESS";
export const UPDATE_DRAFT_PARING_BY_XSLX_FAILURE = "UPDATE_DRAFT_PARING_BY_XSLX_FAILURE";

export const DELETE_DRAFTS_REQUEST = 'DELETE_DRAFTS_REQUEST';
export const DELETE_DRAFTS_FAILURE = 'DELETE_DRAFTS_FAILURE';
export const DELETE_DRAFTS_SUCCESS = 'DELETE_DRAFT_SUCCESS';

const localStorageService = LocalStorage.getService();


export const searchSessioniOrfaneRequest = () => {
    return {type: SEARCH_SESSIONI_ORFANE_REQUEST}
};

export const searchSessioniOrfaneSuccess = (response) => {
    return {type: SEARCH_SESSIONI_ORFANE_SUCCESS, response}
};

export const searchSessioniOrfaneFailed = () => {
    return {type: SEARCH_SESSIONI_ORFANE_FAILED}
};

export const clearSessioniOrfane = () => {
    return {type: CLEAR_SESSIONI_ORFANE}
};

export const startSearchSessioniOrfane = (body) => {
    console.log('Start searching Sessioni orfane with params... ', body);
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(searchSessioniOrfaneRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/search?type=draft&key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(body)
        }).then(response => {
            console.log("response: ", response);
            dispatch(searchSessioniOrfaneSuccess(response.data));
        }).catch(error => {
            console.log('ERROR: ', error);
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(searchSessioniOrfaneFailed(error));
        })
    }
};

export const updateSessioniOrfaneRequest = () => {
    return {type: UPDATE_SESSIONI_ORFANE_REQUEST}
};

export const updateSessioniOrfaneSuccess = (updateResponse) => {
    return {type: UPDATE_SESSIONI_ORFANE_SUCCESS, updateResponse}
};

export const updateSessioniOrfaneFailed = (updateError) => {
    return {type: UPDATE_SESSIONI_ORFANE_FAILED, updateError}
};

export const startUpdateSessioniOrfane = (body) => {
    console.log('Start update Sessioni orfane with params... ', body);
    let token = localStorageService.getAccessToken();
    let updatedBody = {Products: body};
    return (dispatch) => {
        dispatch(updateSessioniOrfaneRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/fixDraft?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(updatedBody)
        }).then(response => {
            console.log("response update : ", response);
            dispatch(updateSessioniOrfaneSuccess(response.data));
        }).catch(error => {
            console.log('ERROR: ', error);
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(updateSessioniOrfaneFailed(error.response.data));
        })
    }
};


// export const CLEAR_PRODUCT_INFO = "CLEAR_PRODUCT_INFO";

export const uploadXlsxFileRequest = () => {
    return {type: UPLOAD_XSLX_FILE_REQUEST};
};

export const uploadXlsxFileFailure = (error_msg) => {
    return {type: UPLOAD_XSLX_FILE_FAILURE, error_msg};
};

export const updateDraftParingSuccess = (draftList) => {
    return {type: UPDATE_DRAFT_PARING_BY_XSLX_SUCCESS, draftList};
};

export const updateDraftParingFailure = (error_msg) => {
    return {type: UPDATE_DRAFT_PARING_BY_XSLX_FAILURE, error_msg};
};


export const startUploadXlsxFile = (file, guid) => {
    console.log("CALLING startUploadXlsxFile with ", file, guid)
    let token = localStorageService.getAccessToken();
    let bodyFormData = new FormData();
    bodyFormData.append("file", file[0]);
    return (dispatch) => {
        dispatch(uploadXlsxFileRequest());
        return axios({
            // TODO: chiedere i path parameter a Fra
            url: `${process.env.REACT_APP_API_ROOTURL}/file/upload?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&guid=${guid}&label=draft_xslx_upload&folder=draft_data_upload`,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: bodyFormData,
        })
            .then(function (response) {
                console.log("Response uploadXslxFile: ", response);
                if (response.status === 200 && response.data !== null) {
                    dispatch(startPairingDraftsByXslx(response.data.bucket, response.data.file_name))
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Errore');
                console.log(error.response.data);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(uploadXlsxFileFailure(error.response.data));
            })
    };
};


export const startPairingDraftsByXslx = (bucket, filename) => {
    console.log("CALLING startPairingDraftsByXslx with ", bucket, filename)
    let token = localStorageService.getAccessToken();
    const body = {
        bucket_name: bucket,
        file_name: filename
    }

    return (dispatch) => {
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/fixDraft?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}&from_file=1`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(body)
        }).then(response => {
            console.log("response update: ", response);
            dispatch(updateDraftParingSuccess(response.data));
        }).catch(error => {
            console.log('Errore');
            console.log(error.response.data);
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(updateDraftParingFailure(error.response.data));
        })
    }
};

// Deleting drafts

export const deleteDraftsRequest = () => {
    return {type: DELETE_DRAFTS_REQUEST};
};

export const deleteDraftsFailure = (error_msg) => {
    return {type: DELETE_DRAFTS_FAILURE, error_msg};
};

export const deleteDraftsSuccess = () => {
    return {type: DELETE_DRAFTS_SUCCESS};
};

export const startDeleteDrafts = (body) => {
    console.log("CALLING startDeleteDrafts with ", body);
    let token = localStorageService.getAccessToken();
    let updatedBody = {Products: body};

    return (dispatch) => {
        dispatch(deleteDraftsRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/drafts?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(updatedBody)
        }).then(response => {
            console.log("response update: ", response);
            dispatch(deleteDraftsSuccess());
        }).catch(error => {
            console.log('Errore');
            console.log(error.response.data);
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(deleteDraftsFailure(error.response.data));
        })
    }
}
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {LeftSideBarUpper} from "../components/LeftSideBarUpper/LeftSideBarUpper";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import {useIntl} from "react-intl";
import {startGetRichiedente} from "../store/actions/richiedente";
import _ from "lodash";
import {startGetServiceByRichiedente} from "../store/actions/service";
import {startGetSeasons} from "../store/actions/season";
import DropzoneDialogElement from "../components/DropzoneDialogElement/DropzoneDialogElement";
import {
    startClearErrors,
    startClearProductInfo,
    startInsertProductInfo,
    startSetDistributionDate,
    startUpdateProductInfoXslx
} from "../store/actions/masterdata/masterdata";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import EditableTable from "../components/TableElement/EditableTable/EditableTable";
import moment from "moment";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import {
    checkAction,
    filterActiveSeasons,
    getFileName,
    getResultMessage,
    renderTableResult
} from "../utils/CommonFunction";
import ReusableModal from "../components/Modal/ReusableModal/ReusableModal";
import DatePickerCustomizable from "../components/Pickers/Date/DatePickerCustomizable";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(12, 0, 0, 0),
        flexGrow: 1
    },
    success: {
        color: theme.palette.success.main
    }
}));

const MasterdataLoadingContainer = props => {
    const classes = useStyles();
    const intl = useIntl();
    const [currentContext, setCurrentContext] = useState({
        richiedente: "",
        service: "",
        richiedente_service: "",
        season: ""
    });
    const [dropzoneDialogOpen, setDropzoneDialogOpen] = useState(false);
    const [forcingUploadState, setForcingUploadState] = useState(null);
    const [snackBar, setSnackBar] = useState({ open: false, type: "error", label: "", labelId: "" });
    const [reusableModal, setReusableModal] = useState({
        open: false,
        title: intl.formatMessage({
            id: "load.master.data.label.set.distribution.date",
            defaultMessage: "Set Distribution Date",
        }),
        type: "add",
        start_date: null,
        expired_date: null
    });
    const columns = [
        {
            title: intl.formatMessage({ 'id': 'load.master.data.table.column.number', 'defaultMessage': '#' }),
            field: 'id',
            editable: 'never',
            order: 0
        },
        {
            title: intl.formatMessage({
                'id': 'load.master.data.table.column.product.code',
                'defaultMessage': 'Product Code'
            }), field: 'style_fabric_color',
            order: 1
        },
        {
            title: intl.formatMessage({
                'id': 'load.master.data.table.column.price.range',
                'defaultMessage': 'Price Range'
            }), field: 'price_range', type: 'numeric', order: 2
        },
        {
            title: intl.formatMessage({
                'id': 'load.master.data.table.column.starting.date',
                'defaultMessage': 'Starting Date'
            }), field: 'start_date', order: 3
        },
        {
            title: intl.formatMessage({
                'id': 'load.master.data.table.column.expiring.date',
                'defaultMessage': 'Expiring Date'
            }), field: 'expired_date', order: 4
        },
        {
            title: intl.formatMessage({ 'id': 'load.master.data.table.column.outcome', 'defaultMessage': 'Outcome' }),
            field: 'message',
            editable: 'never',
            render: (rowData) => renderTableResult(rowData, classes), order: 5
        }];
    const [columnsData, setColumnsData] = useState([]);

    const onChangeContextHandler = event => {
        console.log(event.target.value, event.target.name);
        let fieldName = event.target.name;
        let value = event.target.value;
        if (fieldName === "richiedente")
            props.getServicesByRichiedente(value);
        if (fieldName === "service") {
            let result = props.service.find(s => s.value === value).richiedente_service;
            console.log("RICHIEDENTE SERVICE GUID", result);
            setCurrentContext({
                ...currentContext,
                [fieldName]: value,
                richiedente_service: result
            });
        } else {
            setCurrentContext({
                ...currentContext,
                [fieldName]: value
            });
        }
    }

    const onFileUpload = (event, forcing) => {
        setDropzoneDialogOpen(true);
        setForcingUploadState(forcing);
    }

    const onSetDate = () => {
        setReusableModal({ ...reusableModal, open: true })
    }

    const handleValidation = (row) => {
        console.log("VALIDATION: ", row);
        if (row.style_fabric_color === "" || row.price_range === "") {
            setSnackBar({
                open: true,
                type: "error",
                label: "The input cannot be empty",
                labelId: "common.table.edit.error.label"
            })
            return true;
        } else if (parseInt(row.price_range) <= 0) {
            setSnackBar({
                open: true,
                type: "error",
                label: "The price range must be a positive integer",
                labelId: "common.table.edit.error.label.integer"
            })
            return true;
        } else {
            setSnackBar({ open: false, type: "error", label: "", labelId: "" });
            return false;
        }
    }

    const richiedenteDropdown = {
        type: "dropdown",
        name: "richiedente",
        value: currentContext["richiedente"],
        onChangeHandler: onChangeContextHandler,
        icon: "person",
        label: { translateKey: "common.dropdown.label.richiedente", defaultText: "Richiedente" },
        options: props.richiedente || [],
        loading: props.loadingRichiedente,
    };

    const serviceDropdown = {
        type: "dropdown",
        name: "service",
        value: currentContext["service"],
        onChangeHandler: onChangeContextHandler,
        icon: "settings",
        label: { translateKey: "common.dropdown.label.service", defaultText: "Service" },
        options: props.service || [],
        loading: props.loadingService,
        disabled: currentContext.richiedente === ""
    };

    const seasonDropdown = {
        type: "dropdown",
        name: "season",
        value: currentContext["season"],
        onChangeHandler: onChangeContextHandler,
        icon: "miscellaneous_services",
        label: { translateKey: "common.dropdown.label.season", defaultText: "Season" },
        options: props.seasons || [],
        loading: props.loadingSeason
    };

    const uploadFileButton = {
        type: "button",
        position: "bottom",
        labelId: "common.button.label.upload.file",
        label: "Upload File",
        onSubmit: (event) => onFileUpload(event, false),
        loading: false,
        disabled: _.some(currentContext, el => el === "")
    };

    const forceUploadFileButton = checkAction('masterdata.force_upload_file', props.auth.actions) ?
        {
            type: "button",
            position: "bottom",
            labelId: "load.master.data.button.label.force.upload",
            label: "Force Upload File",
            onSubmit: (event) => onFileUpload(event, true),
            loading: false,
            disabled: currentContext.richiedente === ''
        } : null;

    const setDateButton = {
        type: "button",
        position: "bottom",
        labelId: "load.master.data.button.label.set.date",
        label: "Set Date",
        onSubmit: onSetDate,
        loading: false,
        disabled: _.some(currentContext, el => el === "")
    };
    const groupElements = [richiedenteDropdown, serviceDropdown, seasonDropdown, "space", uploadFileButton, "space", setDateButton];

    if (forceUploadFileButton) groupElements.splice(5, 0, forceUploadFileButton);

    useEffect(() => {
        props.getRichiedente();
        props.getSeason();
        props.clearProductInfo();
        props.clearErrors();
        console.log(snackBar);
    }, []);

    useEffect(() => {
        if (props.fileError)
            setSnackBar({
                open: true,
                type: "error",
                label: "There was an error uploading your file. Please try again",
                labelId: "load.master.data.label.warning.upload.file.error"
            });
        else
            setSnackBar({ open: false, type: "error", label: "", labelId: "" });
    }, [props.fileError])

    useEffect(() => {
        if (props.error)
            setSnackBar({
                open: true,
                type: "error",
                label: "There was an error with the master data loading process. Please Try again",
                labelId: "load.master.data.label.warning.master.data.loading.error"
            });
        else
            setSnackBar({ open: false, type: "error", label: "", labelId: "" });
    }, [props.error])

    useEffect(() => {
        console.log('Bellaaaa: ', props.productInfo);
        if (props.productInfo && props.productInfo.length > 0) {
            let indexedData = _.cloneDeep(props.productInfo);
            indexedData.map((d, index) => d.id = index + 1);
            setColumnsData(indexedData);
        }
    }, [props.productInfo]);

    // useEffect(() => {
    //     console.log("IT'S CHANGEDDDDDDDDDD ", columnsData, _.some(columnsData, el => el.updated));
    // }, [columnsData])

    useEffect(() => {
        if (props.responseDate !== undefined) {
            closeReusableModal();
            console.log("I'm here", props.responseDate);
            if (props.responseDate === 0) {
                setSnackBar({
                    open: true,
                    type: "warning",
                    label: "There are no products to update",
                    labelId: "load.master.data.label.warning.no.products.to.update"
                });
            } else if (props.responseDate !== 0) {
                setSnackBar({
                    open: true,
                    type: "success",
                    label: "Updated products: " + props.responseDate,
                    labelId: "load.master.data.label.success.updated.products" + props.responseDate
                });
            }
        }
    }, [props.responseDate])

    useEffect(() => {
        if (props.error)
            setSnackBar({
                open: true,
                type: "error",
                label: "There was an error setting the distribution date. Please try again.",
                labelId: "load.master.data.label.error.update.date"
            });
        else
            setSnackBar({ open: false, type: "error", label: "", labelId: "" });
    }, [props.errorDate])

    const sendXlsFile = (files) => {
        let guid = props.auth.user_id || "";
        props.insertProductInfo(files, guid, currentContext, forcingUploadState);
        setDropzoneDialogOpen(false);
        setForcingUploadState(null);
    };

    const sendUpdatedData = () => {
        console.log("sending updated data", columnsData);
        let updatedData = _.cloneDeep(columnsData);
        updatedData.forEach(element => {
            console.log(typeof element.expired_date);
            if (element.updated && typeof element.expired_date !== "string") {
                element.expired_date = moment(element.expired_date).format("YYYY-MM-DD").toString() + " 00:00:00";
            }
            if (element.updated && typeof element.price_range === "string") {
                element.price_range = parseInt(element.price_range);
            }
        });
        console.log("ciaooo", updatedData);
        props.updateProductInfo(updatedData, currentContext);
    };

    /**
     * [DOWNLOAD] Used this function to download data selected on web application table. The result is an excel file.
     */
    const downloadExcel = () => {
        //const filename = 'masterdata.xlsx';
        const filename = getFileName('resultTableMasterDataExcel');
        const workbook = new Excel.Workbook();
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        const worksheet = workbook.addWorksheet('Sheet 1');
        const worksheetColumns = [];

        columns.forEach(column => {
            if (column.field !== 'id') {
                worksheetColumns.push({ header: column.title, key: column.field, order: column.order });
            }
        });
        worksheet.columns = _.orderBy(worksheetColumns, ['order'], ['asc']);
        console.log("COLUMNS ", worksheet.columns, worksheetColumns, _.orderBy(worksheetColumns, ['order'], ['asc']));

        columnsData.forEach(rowData => {
            rowData.message = getResultMessage(rowData);
            worksheet.addRow(rowData);
        });

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: blobType });
            saveAs(blob, filename);
        });
    };

    const closeReusableModal = () => {
        setReusableModal({ ...reusableModal, open: false, start_date: null, expired_date: null })
    };

    const onSubmitDate = () => {
        console.log("submitting");
        let start_date = null;
        let expired_date = null;
        if (reusableModal.start_date !== null) start_date = reusableModal.start_date.format("YYYY-MM-DD").toString();
        if (reusableModal.expired_date !== null) expired_date = reusableModal.expired_date.format("YYYY-MM-DD").toString();
        props.setDistributionDate(currentContext, start_date, expired_date);
    };

    return (
        <div className={classes.root}>
            <BackdropElement loading={props.loadingProductInsert || props.loadingSetDate}/>
            <SnackbarElement
                open={snackBar.open}
                type={snackBar.type}
                label={snackBar.label}
                labelId={snackBar.labelId}
                handleClose={() => setSnackBar({ open: false, type: "error", label: "", labelId: "" })}
            />
            <Grid container
                  direction='row'
                  justify='space-between'
                  spacing={3}>
                <Grid item xs={3}>
                    <LeftSideBarUpper groupElements={groupElements}/>
                </Grid>
                {props.productInfo && props.productInfo.length > 0 && (
                    <Grid item xs={9}>
                        <Grid container
                              direction='row'
                              justify='space-between'
                              spacing={3}>
                            <Grid item xs={12}>
                                <EditableTable
                                    data={columnsData}
                                    setData={setColumnsData}
                                    columns={columns}
                                    search={false}
                                    addable={true}
                                    handleValidation={handleValidation}
                                    pageSize={20}
                                    deletable
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container
                                      direction='row'
                                      justify="flex-end"
                                      alignItems="center">
                                    <Grid item xs={3}/>
                                    <Grid item xs={3}/>
                                    <Grid item xs={3}>
                                        <ButtonElement labelId={'common.button.label.download.excel'}
                                                       label={'Download Excel'}
                                                       onSubmit={downloadExcel}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ButtonElement labelId={'common.button.label.submit'}
                                                       label={'Submit'}
                                                       onSubmit={sendUpdatedData}
                                                       loading={props.loadingProductInsert}
                                                       disabled={!_.some(columnsData, el => el.updated)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
                <DropzoneDialogElement
                    acceptedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    dialogTitle={{ labelId: "load.master.data.dropzone.dialog.title", label: "Upload Product Info" }}
                    filesLimit={1}
                    cancelButtonText={{ labelId: "common.button.label.cancel", label: "Cancel" }}
                    submitButtonText={{ labelId: "common.button.label.submit", label: "Submit" }}
                    maxFileSize={50000000}
                    dropzoneDialogOpen={dropzoneDialogOpen}
                    setDropzoneDialogOpen={setDropzoneDialogOpen}
                    saveFileHandler={sendXlsFile}
                    chipPreview={true}
                    showPreviews={true}
                />
                <ReusableModal
                    reusableModal={reusableModal}
                    submitClick={onSubmitDate}
                    submitDisabled={reusableModal.start_date === null && reusableModal.expired_date === null}
                    closeReusableModal={closeReusableModal}
                >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid container spacing={3} justify="space-evenly">
                            <Grid item xs>
                                <DatePickerCustomizable keyBoardInput
                                                        clearable
                                                        showTodayButton
                                                        labelText='Start Date'
                                                        value={reusableModal.start_date}
                                                        onChangeHandler={(date) => setReusableModal({
                                                            ...reusableModal,
                                                            start_date: date
                                                        })}/>
                            </Grid>
                            <Grid item xs>
                                <DatePickerCustomizable keyBoardInput
                                                        clearable
                                                        showTodayButton
                                                        labelText='Expired date'
                                                        minValue={reusableModal.start_date}
                                                        value={reusableModal.expired_date}
                                                        onChangeHandler={(date) => setReusableModal({
                                                            ...reusableModal,
                                                            expired_date: date
                                                        })}/>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </ReusableModal>
            </Grid>

        </div>)
}

const mapStateToProps = state => ({
    auth: state.authReducer.auth,
    loadingSetDate: state.masterDataReducer.loadingSetDate,
    loadingRichiedente: state.richiedenteReducer.loadingRichiedente,
    loadingService: state.serviceReducer.loadingServices,
    loadingSeason: state.seasonReducer.loadingSeason,
    loadingProductInsert: state.masterDataReducer.loadingProductInsert,
    productInfo: state.masterDataReducer.productInfo,
    fileError: state.masterDataReducer.fileError,
    error: state.masterDataReducer.error,
    errorDate: state.masterDataReducer.errorDate,
    responseDate: state.masterDataReducer.response,
    richiedente: _.toArray(_.mapValues(state.richiedenteReducer.richiedente, function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    service: _.toArray(_.mapValues(state.serviceReducer.services, function (o) {
        return {
            label: o.service,
            value: o.service_guid,
            richiedente_service: o.richiedente_service_guid
        };
    })),
    seasons: _.toArray(_.mapValues(filterActiveSeasons(state.seasonReducer.seasons), function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
});

const mapDispatchToProps = dispatch => ({
    getRichiedente: () => dispatch(startGetRichiedente()),
    getServicesByRichiedente: (richiedente) => dispatch(startGetServiceByRichiedente(richiedente)),
    getSeason: () => dispatch(startGetSeasons()),
    insertProductInfo: (file, guid, context, isForcing) => dispatch(startInsertProductInfo(file, guid, context, isForcing)),
    updateProductInfo: (products, context) => dispatch(startUpdateProductInfoXslx(products, context)),
    clearProductInfo: () => dispatch(startClearProductInfo()),
    clearErrors: () => dispatch(startClearErrors()),
    setDistributionDate: (context, start_date, expired_date) => dispatch(startSetDistributionDate(context, start_date, expired_date))
});

export default connect(mapStateToProps,
    mapDispatchToProps)(withRouter(MasterdataLoadingContainer));
import {CLEAR_COLOR_FROM_SF, GET_COLOR_FAILURE, GET_COLOR_REQUEST, GET_COLOR_SUCCESS} from "../actions/color";

const initialState = { color: undefined };

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_COLOR_FROM_SF:
            return {
                color: undefined,
                error: undefined
            }
        case GET_COLOR_REQUEST:
            return {
                loadingColor: true
            };
        case GET_COLOR_SUCCESS:
            return {
                ...state,
                loadingColor: false,
                color: action.color,
            };
        case GET_COLOR_FAILURE:
            return {
                ...state,
                loadingColor: false,
                error: action.error
            }
        default:
            return state;
    }
}
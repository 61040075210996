import {
    GET_ASSET_TYPE_FAILURE,
    GET_ASSET_TYPE_REQUEST,
    GET_ASSET_TYPE_SUCCESS
} from "../../actions/search/asset_type";

const initialState = {asset_type: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ASSET_TYPE_REQUEST:
            return {
                loadingAssetType: true
            };
        case GET_ASSET_TYPE_SUCCESS:
            return {
                ...state,
                loadingAssetType: false,
                asset_type: action.asset_type,
            };
        case GET_ASSET_TYPE_FAILURE:
            return {
                ...state,
                loadingAssetType: false,
                error: action.error
            }
        default:
            return state;
    }
}
import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_COLOR_REQUEST = 'GET_COLOR_REQUEST';
export const GET_COLOR_SUCCESS = 'GET_COLOR_SUCCESS';
export const GET_COLOR_FAILURE = 'GET_COLOR_FAILURE';
export const CLEAR_COLOR_FROM_SF = 'CLEAR_COLOR_FROM_SF';

const localStorageService = LocalStorage.getService();

export const getColorRequest = () => {
    return { type: GET_COLOR_REQUEST }
};

export const getColorSuccess = (color = {}) => {
    return {type: GET_COLOR_SUCCESS, color}
};

export const getColorFailure = (error) => {
    return {type: GET_COLOR_FAILURE, error}
};

export const startClearColorFromSF = () => {
    return {type: CLEAR_COLOR_FROM_SF}
}

export const startGetColorsFromSF = (style, fabric) => {
    console.log('Start startGetColorsFromSF: ', style, fabric);
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getColorRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/color/${style}/${fabric}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response color ", response.data);
                    dispatch(getColorSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getColorFailure(error));
            });
    };
};

import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const GET_L2_PRODUCT_GROUPING_REQUEST = 'GET_L2_PRODUCT_GROUPING_REQUEST';
export const GET_L2_PRODUCT_GROUPING_SUCCESS = 'GET_L2_PRODUCT_GROUPING_SUCCESS';
export const GET_L2_PRODUCT_GROUPING_FAILURE = 'GET_L2_PRODUCT_GROUPING_FAILURE';

const localStorageService = LocalStorage.getService();

export const getL2ProductGroupingRequest = () => {
    return {type: GET_L2_PRODUCT_GROUPING_REQUEST}
};

export const getL2ProductGroupingSuccess = (l2_product_grouping = {}) => {
    return {type: GET_L2_PRODUCT_GROUPING_SUCCESS, l2_product_grouping}
};

export const getL2ProductGroupingFailure = (error) => {
    return {type: GET_L2_PRODUCT_GROUPING_FAILURE, error}
};

export const startGetL2ProductGrouping = () => {
    console.log('Start startGetL2ProductGrouping');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getL2ProductGroupingRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/l2_product_grouping/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response l2_product_grouping search ", response.data);
                    dispatch(getL2ProductGroupingSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getL2ProductGroupingFailure(error));
            });
    };
};

import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";

import MenuTabsElement from "../../components/MenuTabsElement/MenuTabsElement";
import {withRouter} from "react-router-dom";
import {startGetUsers} from "../../store/actions/user";
import {startGetPhotolabs} from "../../store/actions/photolab";
import {startGetSeasons} from "../../store/actions/season";
import {startGetAudits} from "../../store/actions/audit";
import {startGetTransactions} from "../../store/actions/transactions";
import AdminUsersContainer from "./AdminUsersContainer";
import AdminSeasonsContainer from "./AdminSeasonsContainer";
import AdminLogsContainer from "./AdminLogsContainer";
import AdminTransactionsContainer from "./AdminTransactionsContainer";
import AdminPhotolabContainer from "./AdminPhotolabContainer";
import {startGetAppLogs} from "../../store/actions/appLogs";
import AdminAppLogsContainer from "./AdminAppLogsContainer";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(12),
        flexGrow: 1,
    },
}));

const AdminPageContainer = (props) => {
    const classes = useStyles();

    const [whichTable, setWhichTable] = useState(0);

    /**
     * [HANDLER] [VIEW: all] [TABS] Function used to handle the selection of each table.
     * @param {event} event [click event]
     * @param {number} whichTable [integer value of current view selected]
     */
    // DA SISTEMARE
    const viewTablesHandler = (event, whichTable) => {
            switch (whichTable) {
                case 0: // Users
                    props.getUsers();
                    break;
                case 1: // Photolab
                    props.getPhotolabs();
                    break;
                case 2: // Seasons
                    props.getSeasons();
                    break;
                case 3: // Logs
                    props.getAudits();
                    break;
                case 4: // Transactions
                    props.getTransactions();
                    break;
                case 5: // Application (Beckend) logs
                    props.getAppLogs();
                    break;
                default:
                    break;
            }
            setWhichTable(whichTable);
            console.log("which table", whichTable)
        };

    // default table = Users
    useEffect((event) => viewTablesHandler(event, 0), []);

    const menuTabs = [
        { translateKey: "admin.page.users", default: "Users" }, // 0
        { translateKey: "product.photolab", default: "Photolab" }, // 1
        { translateKey: "admin.page.seasons", default: "Seasons" }, // 2
        { translateKey: "admin.page.logs", default: "Logs" }, // 3
        { translateKey: "admin.page.transactions", default: "Transactions" }, // 4
        { translateKey: "admin.page.app_log", default: "Application Logs" }, // 5
    ];

    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="space-between" spacing={3}>
                <Grid item xs={12}>
                    <MenuTabsElement
                        value={whichTable}
                        onChangeHandler={viewTablesHandler}
                        tabs={menuTabs}
                    />
                </Grid>

                <Grid item xs={12} className={classes.tableBox}>
                    {whichTable === 0 ? (
                        <AdminUsersContainer/>
                    ) : whichTable === 1 ? (
                        <AdminPhotolabContainer/>
                    ) : whichTable === 2 ? (
                        <AdminSeasonsContainer/>
                    ) : whichTable === 3 ? (
                        <AdminLogsContainer/>
                    ) : whichTable === 4 ? (
                        <AdminTransactionsContainer/>
                    ) : whichTable === 5 ? (
                        <AdminAppLogsContainer/>
                    ) : (
                        <div/>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    users: state.userReducer.users
});

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(startGetUsers()),
        getPhotolabs: () => dispatch(startGetPhotolabs()),
        getSeasons: () => dispatch(startGetSeasons()),
        getAudits: () => dispatch(startGetAudits()),
        getTransactions: () => dispatch(startGetTransactions()),
        getAppLogs: () =>  dispatch(startGetAppLogs())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AdminPageContainer));

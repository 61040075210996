import React from "react";
import {DatePicker, KeyboardDatePicker} from "@material-ui/pickers";
import {useStyles} from "./DatePickerCustomizableStyle";
import {useIntl} from "react-intl";
import moment from "moment";

// https://material-ui-pickers.dev/api/DatePicker
// https://material-ui-pickers.dev/api/KeyboardDatePicker
const DatePickerCustomizable = props => {
    const {
        keyBoardInput,
        autoConfirm,
        disabled,
        notFuture,
        notPast,
        notToolbar,
        labelText,
        dateFormat,
        inputVariant,
        orientation,
        variant,
        placeholder,
        clearable,
        showTodayButton,
        minValue,
        maxValue,
        onChangeHandler,
        value,
        upLabel
    } = props;
    const classes = useStyles();
    const intl = useIntl();
    let datePicker;

    if (keyBoardInput) {
        console.log(props);
        datePicker = (
            <div>
                {upLabel && (
                    <div className={classes.upLabel}>
                        {intl.formatMessage({
                            id: upLabel.id,
                            defaultMessage: upLabel.defaultMessage,
                        })}
                    </div>
                )}
                <KeyboardDatePicker className={classes.datePicker}
                                    autoOk={autoConfirm ? autoConfirm : true}
                                    disabled={disabled ? disabled : false}
                                    disableFuture={notFuture ? notFuture : false}
                                    disablePast={notPast ? notPast : false}
                                    disableToolbar={notToolbar ? notToolbar : false}
                                    label={labelText}
                                    format={dateFormat ? dateFormat : "DD-MM-YYYY"}
                                    InputProps={{ disableunderline: "true", margin: "dense" }}
                                    inputVariant={inputVariant ? inputVariant : 'outlined'} // standard, outlined, filled
                                    orientation={orientation ? orientation : 'portrait'}   // portrait, landscape
                                    variant={variant ? variant : 'dialog'}   // dialog, inline, static

                                    minDate={minValue || minValue !== null ? minValue : moment('01-01-1900')}
                                    maxDate={maxValue || maxValue !== null ? maxValue : moment('01-01-2100')}
                                    onChange={onChangeHandler}
                                    value={value}
                    // Attribute of dialog modal
                                    clearable={clearable}    // only for variant dialog;
                                    DialogProps={{ className: classes.dialog }}
                                    showTodayButton={showTodayButton}  // only for variant dialog;
                />
            </div>
        )
    } else {
        datePicker = (
            <DatePicker className={classes.datePicker}
                        autoOk={autoConfirm ? autoConfirm : true}
                        disabled={disabled ? disabled : false}
                        disableFuture={notFuture ? notFuture : false}
                        disablePast={notPast ? notPast : false}
                        disableToolbar={notToolbar ? notToolbar : false}
                        label={labelText}
                        format={dateFormat ? dateFormat : "DD-MM-YYYY"}
                        inputVariant={inputVariant ? inputVariant : 'standard'} // standard, outlined, filled
                        orientation={orientation ? orientation : 'portrait'}   // portrait, landscape
                        variant={variant ? variant : 'dialog'}   // dialog, inline, static

                        minDate={minValue || minValue !== null ? minValue : moment('01-01-1900')}
                        maxDate={maxValue || maxValue !== null ? maxValue : moment('01-01-2100')}
                        onChange={onChangeHandler}
                        value={value}
                // Attribute of dialog modal
                        clearable={clearable}    // only for variant dialog;
                        showTodayButton={showTodayButton}  // only for variant dialog;
            />
        )
    }

    return datePicker;
};

export default DatePickerCustomizable;
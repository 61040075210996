import axios from "axios";
import LocalStorage from "../../../localStorage";
import {loggedOut} from "../../auth";

const localStorageService = LocalStorage.getService();

export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const PUT_PRODUCTS_REQUEST = 'PUT_PRODUCTS_REQUEST';
export const PUT_PRODUCTS_SUCCESS = 'PUT_PRODUCTS_SUCCESS';
export const PUT_PRODUCTS_FAILED = 'PUT_PRODUCTS_FAILED';

export const clearProducts = () => {
    return { type: CLEAR_PRODUCTS }
};

export const putProductsRequest = () => {
    return { type: PUT_PRODUCTS_REQUEST }
};

export const putProductsSuccess = () => {
    return { type: PUT_PRODUCTS_SUCCESS }
};

export const putProductsFailed = (error) => {
    return { type: PUT_PRODUCTS_FAILED, error }
};

/**
 * Update products information about gallery.
 *
 * @param galleryId {number}: id of gallery which user is going to define the automatic sorting rules.
 * @param products {Array}: list of products to be update.
 */
export const startPutProducts = (galleryId, products) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startPutProducts with: galleryID --> ', galleryId, ' products --> ', products);
    return (dispatch) => {
        dispatch(putProductsRequest());

        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/pg/galleries/${galleryId}/products/select?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token,
            },
            data: JSON.stringify(products)
        })
            .then(function (response) {
                if (response.status === 204) {
                    console.log('Response startPutProducts: success');
                    dispatch(putProductsSuccess());
                }
            })
            .catch(function (error) {
                // handle error
                console.log('Error startPutProducts: ', error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(putProductsFailed(error));
            });
    }
};
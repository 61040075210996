import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useIntl} from "react-intl";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import {users_columns} from "../../assets/resources/admin_users_columns";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import MaterialSimpleTable from "../../components/TableElement/MaterialSimpleTable/MaterialSimpleTable";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import ButtonElement from "../../components/ButtonElement/ButtonElement";
import UserAddForm from "../../components/Admin/Users/UserAddForm";
import {
	clearUsers,
	hideUsersSnackBar,
	startDeleteUser,
	startGetRoles,
	startGetUsers,
	startPostUser,
	startPutUser
} from "../../store/actions/user";
import moment from "moment";
import {validateColumnsMaterialSimpleTable} from "../../utils/CommonFunction";

const regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regex_telephone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


const AdminUsersContainer = (props) => {
	// const classes = useStyles();
	const intl = useIntl();

	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);

	const [snackBarState, setSnackBarState] = useState({
		open: false,
		level: "error",
		label: { translateKey: "tmp", defaultText: "" },
	});
	const [showDetailModal, setShowDetailModal] = useState({
		modalRows: {},
		open: false,
		title: intl.formatMessage({
			id: "admin.users.modal.title",
			defaultMessage: "User Details",
		})
	});
	const [editReusableModal, setEditReusableModal] = useState({
		data: {},
		open: false,
		title: ""
	});
	const [confirmModal, setConfirmModal] = useState({
		open: false,
		title: ""
	});


	useEffect(() => {
		// setup the columns
		const columns = validateColumnsMaterialSimpleTable(intl, users_columns);
		setColumns(columns);

		// During unmount of users container
		return (() => {
			console.log('Unmounting admin users container.')
			props.clearUsers();
			setColumns([]);
			setTableData([]);
			setSnackBarState({
				open: false,
				level: "error",
				label: { translateKey: "tmp", defaultText: "" }
			});
			setShowDetailModal({
				modalRows: {},
				open: false,
				title: intl.formatMessage({
					id: "admin.users.modal.title",
					defaultMessage: "User Details",
				})
			});
			setEditReusableModal({ data: {}, open: false, title: "" });
			setConfirmModal({ open: false, title: "" });
		});
	}, []);

	useEffect(() => {
		console.log("useEffect users", props.users);

		if (props.users.length > 0) {
			const users = _.cloneDeep(props.users);

			users.map((user) => {
				if (user.roles.length > 0) {
					let rolesToString = '';

					user.roles.map((role) => {
						rolesToString = rolesToString.concat(role.role, ', ');
						return role;
					});
					user.roles_to_string = rolesToString.substring(0, rolesToString.length - 2); // To remove last common and whitespace.
				} else {
					user.roles_to_string = "None";
				}

				if (user.language) {
					user.language = user.language.toUpperCase();
				}

				return user;
			});

			console.log(users[0], props.users[0]);
			setTableData(users);

		}
	}, [props.users]);

	useEffect(() => {
		console.log("TO UPDATE", props.updateUsers)
		if (props.updateUsers && props.updateUsers.Status === "OK") {
			const newSuccess = {
				open: true,
				level: "success",
				label: { translateKey: "admin.users.success", defaultText: "Operation executed with success." },
			}
			setSnackBarState(newSuccess)
			props.getUsers();
			props.hideSnackBar()
		}

	}, [props.updateUsers])

	useEffect(() => {
		console.log("ERROR: ", props.crudError)
		if (props.crudError) {
			const newError = {
				open: true,
				level: "error",
				label: { translateKey: "admin.users.error", defaultText: "Operation not executed." },
			}
			setSnackBarState(newError)
			props.hideSnackBar()
		}
	}, [props.crudError])

	const onClickRow = (event, row) => {
		console.log("ROW CLICKED", row);
		const newState = { ...showDetailModal };
		newState.open = true;
		newState.modalRows = _.cloneDeep(row);

		newState.modalRows.roles.map((role) => {
			role.elem = role.role;
			role.label = role.role;
			role.value = role.role;
			role.selected = true;

			return role;
		});

		setShowDetailModal(newState);
	};

	const onCloseShowModal = () => {
		console.log("Close show modal");
		const newState = { ...showDetailModal };
		newState.open = false;
		newState.modalRows = {};
		setShowDetailModal(newState);
	};

	const onCloseConfirmModal = () => {
		console.log("Close confirm modal");
		const newState = { ...confirmModal };
		newState.open = false;
		setConfirmModal(newState);
	};

	const openNewEditModal = (isNew) => {
		console.log("Open edit modal");
		const newState = { ...editReusableModal };
		newState.open = true;
		if (!isNew) {
			newState.isNew = false;
			newState.title = intl.formatMessage({
				id: "admin.users.modal.title",
				defaultMessage: "Edit User Details",
			});
			newState.data = { ...showDetailModal.modalRows };
		} else {
			newState.isNew = true;
			newState.title = intl.formatMessage({
				id: "admin.users.modal_create.title",
				defaultMessage: "Insert User Details",
			});
		}
		console.log(newState);
		setEditReusableModal(newState);
	};

	// edit or create user
	const editUser = () => {
		const userData = editReusableModal.data;
		// checks if the required fields are not empty
		if (userData.email
			&& userData.first_name && userData.last_name
			&& userData.roles && userData.roles.length !== 0
			&& (userData.password || !editReusableModal.isNew)
			&& !userData.email_error && !userData.telephone_error) {
			const guid = userData.guid
			// generates the body for the request
			const user = {
				email: userData.email, // required
				password: userData.password ? userData.password : null, // if in create mode, then required
				roles_list: userData.roles.map(role => role.guid), // required
				first_name: userData.first_name, // required
				last_name: userData.last_name, // required
				notes: userData.notes ? userData.notes : null,
				expiration_date: userData.expiration_date ? userData.expiration_date : null,
				telephone: userData.telephone ? userData.telephone : null,
				language: userData.language ? userData.language : null,
				nickname: userData.nickname ? userData.nickname : null

			}
			console.log("USER ", editReusableModal.data)
			if (editReusableModal.isNew) {
				console.log("CREATING USER", user);
				props.createUser(user);
			} else {
				console.log("EDIT USER with guid", user, guid);
				props.editUser(user, guid)
			}
			let newState = { ...editReusableModal };
			newState.open = false;
			newState.isNew = false;
			newState.title = "";
			newState.data = {};
			setEditReusableModal(newState);
			newState = { ...showDetailModal };
			newState.open = false;
			setShowDetailModal(newState);
		} else {
			const newState = { ...editReusableModal };
			const required = intl.formatMessage({
				id: "admin.users.required",
				defaultMessage: "Required Field",
			});
			if (!userData.email) newState.data.email_error = required;
			if (!userData.roles || userData.roles.length === 0) newState.data.roles_error = required;
			if (!userData.first_name) newState.data.firstname_error = required;
			if (!userData.last_name) newState.data.lastname_error = required;
			if (editReusableModal.isNew && !userData.password) newState.data.password_error = required;
			setEditReusableModal(newState);
		}
	};

	const deleteUser = () => {
		// props.deleteUser(showDetailModal.modalRows.guid);
		const userData = { ...showDetailModal.modalRows };
		userData.expiration_date = moment().subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss");
		const user = {
			email: userData.email, // required
			password: userData.password ? userData.password : null, // if in create mode, then required
			roles_list: userData.roles.map(role => role.guid), // required
			first_name: userData.first_name, // required
			last_name: userData.last_name, // required
			notes: userData.notes ? userData.notes : null,
			expiration_date: userData.expiration_date ? userData.expiration_date : null,
			telephone: userData.telephone ? userData.telephone : null,
			language: userData.language ? userData.language : null,
			nickname: userData.nickname ? userData.nickname : null
		}
		props.editUser(user, userData.guid);

		const newState = { ...showDetailModal };
		newState.open = false;
		newState.title = "";
		newState.modalRows = {};
		setShowDetailModal(newState);
		setConfirmModal({
			...confirmModal,
			open: false,
		});
	};

	const onCloseEditModal = () => {
		console.log("Close edit modal");
		const newState = { ...editReusableModal };
		newState.open = false;
		newState.title = "";
		newState.data = {};

		setEditReusableModal(newState);
	};

	// handle change on create and edit user modal
	const editChangeHandler = (event, type, selectedValues) => {
		const newState = { ...editReusableModal };
		newState.data.roles_error = null;
		newState.data.email_error = null;
		newState.data.telephone_error = null;
		newState.data.firstname_error = null;
		newState.data.lastname_error = null;
		newState.data.password_error = null;
		switch (type) {
			case 'roles':
				console.log("multiselect handle change", event, selectedValues);
				let newArray = Array.from(selectedValues);
				newArray.forEach((elem) => {
					elem.selected = true;
				});
				newState.data.roles = newArray;
				setEditReusableModal(newState);
				break;
			case "email":
				if (!regex_email.test(String(event.target.value).toLowerCase())) {
					console.log("email error");
					newState.data = {
						...newState.data,
						email: event.target.value,
						email_error: intl.formatMessage({
							id: "admin.users.email_error",
							defaultMessage: "Invalid Email",
						}),
					};
					console.log("email error", newState.data.email_error);
					setEditReusableModal(newState);
				} else {
					newState.data = {
						...newState.data,
						email: event.target.value,
						email_error: false,
					};
					console.log("email error", newState.data.email_error);
					setEditReusableModal(newState);
				}
				break;
			case "telephone":
				if (!regex_telephone.test(String(event.target.value).toLowerCase()) && event.target.value !== "") {
					newState.data = {
						...newState.data,
						telephone: event.target.value,
						telephone_error: intl.formatMessage({
							id: "admin.users.telephone_error",
							defaultMessage: "Invalid telephone number",
						}),
					};
					console.log("telephone error", newState.data.telephone_error);
					setEditReusableModal(newState);
				} else {
					newState.data = {
						...newState.data,
						telephone: event.target.value,
						telephone_error: false,
					};
					console.log("telephone error", newState.data.telephone_error);
					setEditReusableModal(newState);
				}
				break;
			case "expiration_date":
				console.log("date picker handle change", event);
				newState.data = {
					...newState.data,
					expiration_date: event ? event.format("YYYY-MM-DD HH:mm:ss") : null,
				};
				setEditReusableModal(newState);
				break;
			default:
				console.log("any other input handle change", event);
				newState.data = {
					...newState.data,
					[type]: event.target.value,
				};
				setEditReusableModal(newState);
				break;
		}
	};

	const downloadExcel = () => {
		const filename = 'users.xlsx';
		const workbook = new Excel.Workbook();
		workbook.created = new Date();
		workbook.modified = new Date();
		workbook.lastPrinted = new Date();

		const worksheet = workbook.addWorksheet('Sheet 1');
		const worksheetColumns = []

		columns.forEach(column => {
			if (column.field !== 'id') {
				worksheetColumns.push({ header: column.title, key: column.field });
			}
		});
		worksheet.columns = worksheetColumns;

		tableData.forEach(index => {
			worksheet.addRow(index);
		});

		workbook.xlsx.writeBuffer().then(data => {
			const blob = new Blob([data], { type: blobType });
			saveAs(blob, filename);
		});
	};

	return (
		<div>
			<BackdropElement loading={props.loadingUsers || false}/>
			<Grid item xs={12}>
				<SnackbarElement
					open={snackBarState.open}
					type={snackBarState.level}
					label={snackBarState.label.defaultText}
					labelId={snackBarState.label.translateKey}
					handleClose={
						() => setSnackBarState({
							...snackBarState,
							open: false,
						})
					}
				/>
			</Grid>
			<MaterialSimpleTable
				data={tableData}
				columns={columns}
				onRowClick={onClickRow}
				components={{
					Action: (tableProps) => {
						return (
							<ButtonElement
								label={intl.formatMessage({
									id: "admin.users.add.user",
									defaultMessage: "Add New User",
								})}
								onSubmit={() => openNewEditModal(true)}
							/>
						);
					},
				}}
				actions={[
					{
						icon: "add",
						tooltip: "Add new user",
						onClick: () => {
							// open new user modal
						},
						isFreeAction: true,
					},
				]}
				pageSize={10}
			/>
			<Grid container
				  direction='row'
				  justify="flex-end">
				<ButtonElement labelId={'common.button.label.download.excel'}
							   label={'Download Excel'}
							   onSubmit={downloadExcel}
				/>
			</Grid>
			{/* Modale di visualizzazione riga tabella */}
			<ReusableModal
				reusableModal={showDetailModal}
				greenButtonClick={() => openNewEditModal(false)}
				greenButtonText={intl.formatMessage({ id: "common.button.label.edit", defaultMessage: "Edit" })}
				redButtonClick={() => {
					setConfirmModal({
						...confirmModal,
						open: true,
					});
				}}
				redButtonText={intl.formatMessage({
					id: "common.button.label.deactivate",
					defaultMessage: "Deactivate"
				})}
				closeReusableModal={onCloseShowModal}
			>
				<Grid container
					  direction="row" spacing={1}>
					{
						columns.map(column =>
							<Grid container item key={column.title} justify={'center'} xs={12} spacing={2}>
								<Grid item xs={3}>
									{column.title}
								</Grid>
								<Grid item xs={5}>
									{showDetailModal.modalRows[column.field]}
								</Grid>
							</Grid>
						)
					}
				</Grid>
			</ReusableModal>
			<UserAddForm
				reusableModal={editReusableModal}
				okReusableModal={editUser}
				closeReusableModal={onCloseEditModal}
				callOptionList={props.callOptionList}
				nameOptionList={"getRoles"}
				handleOnChange={(event, type, autocompleteValues) =>
					editChangeHandler(event, type, autocompleteValues)
				}
				roles={props.roles}
				user={editReusableModal.data}
			/>
			<ReusableModal
				reusableModal={confirmModal}
				greenButtonClick={deleteUser}
				greenButtonText={intl.formatMessage({ id: "common.confirm", defaultMessage: "Confirm" })}
				closeReusableModal={onCloseConfirmModal}>
				{intl.formatMessage({
					id: "admin.users.confirm",
					defaultMessage: "Are you sure you want to deactivate this user?"
				})}
			</ReusableModal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	users: state.userReducer.users,
	loadingUsers: state.userReducer.loadingUsers,
	roles: state.userReducer.roles,
	updateUsers: state.userReducer.updateUsers,
	crudError: state.userReducer.error
});

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: () => dispatch(startGetUsers()),
		callOptionList: {
			getRoles: () => dispatch(startGetRoles()),
		},
		createUser: (newUser) => dispatch(startPostUser(newUser)),
		editUser: (user, guid) => dispatch(startPutUser(user, guid)),
		deleteUser: (guid) => dispatch(startDeleteUser(guid)),
		hideSnackBar: () => dispatch(hideUsersSnackBar()),
		clearUsers: () => dispatch(clearUsers())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AdminUsersContainer));

import axios from "axios";
import LocalStorage from "../../localStorage";
import {loggedOut} from "../auth";

export const GET_L1_CATEGORY_REQUEST = 'GET_L1_CATEGORY_REQUEST';
export const GET_L1_CATEGORY_SUCCESS = 'GET_L1_CATEGORY_SUCCESS';
export const GET_L1_CATEGORY_FAILURE = 'GET_L1_CATEGORY_FAILURE';

const localStorageService = LocalStorage.getService();

export const getL1CategoryRequest = () => {
    return {type: GET_L1_CATEGORY_REQUEST}
};

export const getL1CategorySuccess = (l1_category = {}) => {
    return {type: GET_L1_CATEGORY_SUCCESS, l1_category}
};

export const getL1CategoryFailure = (error) => {
    return {type: GET_L1_CATEGORY_FAILURE, error}
};

export const startGetL1Category = () => {
    console.log('Start startGetL1Category');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getL1CategoryRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/l1_category/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response l1_category search ", response.data);
                    dispatch(getL1CategorySuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getL1CategoryFailure(error));
            });
    };
};

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import FilterableTable from "../components/TableElement/FilterableTable/FilterableTable";
import {useIntl} from "react-intl";
import FileSaver from "file-saver";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import Typography from "@material-ui/core/Typography";
import MenuTabsElement from "../components/MenuTabsElement/MenuTabsElement";
import GridDetailedView from "../components/GridViewElement/SearchResults/GridDetailedView/GridDetailedView";
import FiltersHeader from "../components/GridViewElement/SearchResults/FiltersHeader/FiltersHeader";
import PaginationFooter from "../components/GridViewElement/SearchResults/PaginationFooter/PaginationFooter";
import GridCompactView from "../components/GridViewElement/SearchResults/GridCompactView/GridCompactView";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import ModalFullScreen from "../components/Modal/FullScreen/ModalFullScreen";
import PreserveImageRatio from "../components/PreserveImageRatio/PreserveImageRatio";
import {clearSearchInfo, startGetImagesToCompare, startGetViewMonitorInfo} from "../store/actions/search/dialogInfo";
import {
    backgroundColorUsingStatus,
    checkAction,
    checkApplication,
    createSearchBodyRequest,
    getFileName,
    getSingleViewData,
    GetTooltipStatusColorComponent,
    onErrorSrc,
} from "../utils/CommonFunction";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import {
    clearAssets,
    clearErrorStatus,
    startApproveAssets,
    startRejectAssets,
    startResetAssets,
    startMigrateAssets
} from "../store/actions/approval/approval";
import DropDownElement from "../components/DropDownElement/DropDownElement";
import {
    clearFilterTableCount,
    clearResponseSearch,
    clearSearchFlag,
    startPostSearchRequest,
    startPostSearchWithCountRequest,
    startPostSearchWithCountsRequest,
    startPostSearchWithFilterTableCountRequest
} from "../store/actions/search/search";
import {clearPostProduction, startFetchPostProductionAssets} from "../store/actions/postProduction/postProduction";
import {clearExportToYnap, errorType, startExportToYnap} from "../store/actions/search/exportYNAP";
import {clearDeleteAssets, startDeleteAssets} from "../store/actions/search/assets";
import {clearColumnsSettings, startGetColumnsSettings} from "../store/actions/search/columns_settings";
import Paper from "@material-ui/core/Paper";
import LocalStorage from "../store/localStorage";
import {
    ACTION_REQUEST,
    ASSET_STATUS,
    COMPARING_DIALOG_ASSET_STATUS,
    DROPDOWN_SEARCH_ACTIONS,
    KEYS_DETAILED_VIEW,
    MAP_VIEWS_WITH_TABLE,
    MENU_TABS,
    POST_PRODUCTION_ACTIONS_REQUEST,
    PRODUCT_COLUMNS_NAME_TO_TRANSLATE,
    SEARCH_ACTION_REQUEST,
    SEARCH_ASSETS_ACTIONS,
    SEARCH_VIEWS,
    SNACKBAR_LEVEL,
    TABLE_ROWS_PER_PAGE,
    VIEWS_WITH_TABLE,
    VIEWS_WITHOUT_TABLE
} from "../utils/constant";
import {startPostSelectAll} from "../store/actions/search/selectAll";
import {clearDownload, startPostDownloadExcel, startPostDownloadZip, startGetDownloadInfo}  from "../store/actions/search/download";
import ReusableModal from "../components/Modal/ReusableModal/ReusableModal";
import Button from "@material-ui/core/Button";
import ChipElement from "../components/ChipElement/ChipElement";
import IconExport from "../components/IconExport/IconExport";
import MovieIcon from "@material-ui/icons/Movie";
import { DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(12, 0, 0, 0),
        flexGrow: 1,
        "& .MuiPaper-root": {
            backgroundColor: "inherit",
            color: theme.palette.text.main,
        },
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
        paddingRight: theme.spacing(3),
    },
    squareStatusTable: {
        width: "21px",
        height: "21px",
        borderRadius: "3px",
    },
    containerComparingDialog: {
        textAlign: "center",
        padding: theme.spacing(4),
        marginRight: theme.spacing(-2),
    },
    noteComparingDialog: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    viewMonitorDialogContainer: {
        padding: theme.spacing(2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },
    viewMonitorImgContainer: {
        position: 'relative',
        width: 200,
        height: 312,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.background.main,
        marginLeft: "auto",
    },
    viewMonitorImg: {
        width: 200,
        height: 280,
        margin: "0 0 0 auto",
    },
    subtitle: {
        fontWeight: "700",
    },
    movieFrameContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    movieIcon: {
        position: 'relative',
        left: 4,
        top: 2,
        marginRight: -24,
        color: theme.palette.button.deactive
    }
}));

const localStorageService = LocalStorage.getService();

const SearchResultsContainer = (props) => {
        const classes = useStyles();
        const { genericStyle } = props;
        const intl = useIntl();

        const [actionsState, setActionsState] = useState([]);
        const [data, setData] = useState([]);
        const [filters, setFilters] = useState([]);
        const [columnsTable, setColumnsTable] = useState([]);
        const [searchBodyRequest, setSearchBodyRequest] = useState({
            filter_list: [],
            size: 10,
            page: 0,
            start: 0,
            order: [],
            filters_table: [],
            asset: null
        });
        const [tableSortingConfigState, setTableSortingConfigState] = useState({}); // {name: "column name", direction: "asc/desc"}
        const [totalState, setTotalState] = useState(0);    // Total elements === length of data
        const [totalTableState, setTotalTableState] = useState(null);   // Total elements with table filter applied.
        const [userRowsSelected, setUserRowsSelected] = useState([]);   // list of product's or asset's guid selected by user
        const [tableRowSelectedState, setTableRowSelectedState] = useState([]); // list of row id for table views
        const [pagination, setPagination] = useState({
            pageSize: 0,
            pageSizeOptions: [],
            pageNumber: 1,
            startIndex: 0,
            endIndex: 0
        });
        const [actionSelected, setActionSelected] = useState("");
        const [actionButtonDisabledState, setActionButtonDisabledState] = useState(true);
        const [viewType, setViewType] = useState();
        const [changedViewState, setChangedViewState] = useState(false);
        const [deleteAssetsModalStatus, setDeleteAssetsModalStatus] = useState({
            open: false,
            title: intl.formatMessage({ id: 'search.dialog.title.delete.assets', defaultMessage: 'Delete assets' })
        });
        const [viewMonitorModalState, setViewMonitorModalState] = useState({
            open: false,
            title: null,
            assets: [],
            characteristic: [],
            header: []
        });
        const [showDownloadModal, setShowDownloadModal] = useState({
            open: false,
            totals: [],
            images: [],
            videos: []
        });
        const [comparingDialogState, setComparingDialogState] = useState({
            open: false,
            content: {
                assetName: null,
                toBePostProduceImageUrl: null,
                postProducedImageUrl: null,
                notesForPost: null,
                notesPost: null,
                assetType: ''
            },
        });
        const [backdropState, setBackdropState] = useState({ loading: false, label: '' });
        const [loadingData, setLoadingData] = useState(false);
        const [snackBarState, setSnackBarState] = useState({
            open: false,
            level: "error",
            messageAbout: null,
            label: { translateKey: "tmp", defaultText: "", values: {} },
        });
        const [clickedConfirm,setClickedConfirm] = useState(false);


        /** [VIEW: 2, 3, 4] [TABLE] */
        let options = {
            filterType: "dropdown",
            serverSide: true,
            responsive: "vertical",
            pagination: true,
            confirmFilters: true,
            rowHover: true,
            selectToolbarPlacement: "none",
            print: false,
            search: false,
            download: false,
            rowsPerPage: searchBodyRequest.size,
            rowsPerPageOptions: TABLE_ROWS_PER_PAGE,
            sortOrder: tableSortingConfigState,
            onChangeRowsPerPage: (numberOfRows) => {
                let bodyRequest = _.cloneDeep(searchBodyRequest);
                bodyRequest.size = numberOfRows;
                bodyRequest.start = bodyRequest.page * numberOfRows;
                setSearchBodyRequest(bodyRequest);
                setTableRowSelectedState([]);
                props.searchRequest(bodyRequest);
            },
            onChangePage: (currentPage) => {
                let bodyRequest = _.cloneDeep(searchBodyRequest);
                bodyRequest.page = currentPage;
                bodyRequest.start = currentPage * bodyRequest.size;
                setSearchBodyRequest(bodyRequest);
                setTableRowSelectedState([]);
                props.searchRequest(bodyRequest);
            },
            page: searchBodyRequest.page,
            rowsSelected: tableRowSelectedState,
            count: totalTableState ? totalTableState : totalState,
            selectableRowsHeader: false,
            onRowSelectionChange: (
                _currentRowsSelected,
                _allRowsSelected,
                rowsSelected
            ) => {
                let allSelectedRows = _.cloneDeep(userRowsSelected);

                const guidList = data.map(row => viewType === 2 || viewType === 3 ? row.asset_guid : row.digital_card_guid);
                if (_.isEmpty(rowsSelected)) {
                    // Getting all guid elements (assets or products) on the current page and removing them from list of selected elements.
                    const unselectedElements = tableRowSelectedState.map(indexRow => guidList[indexRow]);
                    allSelectedRows = allSelectedRows.filter(el => !unselectedElements.includes(el));
                } else {
                    // Getting added elements
                    const selectedElements = rowsSelected.filter(indexRow => !tableRowSelectedState.includes(indexRow));
                    // Getting index of removed elements
                    const unselectedIndexElements = tableRowSelectedState.filter(indexRow => !rowsSelected.includes(indexRow));
                    // Getting removed elements
                    const unselectedElements = unselectedIndexElements.map(indexElement => guidList[indexElement]);

                    if (!_.isEmpty(unselectedElements)) allSelectedRows = allSelectedRows.filter(el => !unselectedElements.includes(el));
                    if (!_.isEmpty(selectedElements)) selectedElements.forEach(selectedIndex => {
                        allSelectedRows.push(guidList[selectedIndex]);
                    });
                }
                setTableRowSelectedState(rowsSelected);
                setUserRowsSelected(allSelectedRows);
                allSelectedRows.length > 0 ? setActionButtonDisabledState(false) : setActionButtonDisabledState(true);
            },
            onViewColumnsChange: (changedColumn, action) => {
                let tmpColumns = _.cloneDeep(columnsTable);
                tmpColumns.forEach((columnObj) => {
                    if (columnObj.name === changedColumn) {
                        columnObj.options.display = action === "add"
                    }
                });
                setColumnsTable(tmpColumns);
            },
            customFilterDialogFooter: (_currentFilterList, applyNewFilters) => {
                return (
                    <Grid container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          style={{ marginTop: '40px' }}>
                        <Grid item>
                            <Button variant="contained"
                                    onClick={() => searchWithNewFilters(applyNewFilters())}>
                                Apply Filters
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
            onFilterChipClose: (_index, _removedFilter, filterList) => {
                searchWithNewFilters(filterList);
            },
            onColumnSortChange: (changedColumn, direction) => {
                const bodyRequest = _.cloneDeep(searchBodyRequest);
                bodyRequest.order = [{ name: changedColumn, order_type: direction.toUpperCase() }];

                direction === 'none' ? setTableSortingConfigState({}) : setTableSortingConfigState({
                    name: changedColumn,
                    direction: direction
                });
                setSearchBodyRequest(bodyRequest);
                props.searchRequest(bodyRequest);
            },
            textLabels: {
                body: {
                    noMatch: intl.formatMessage({
                        id: "common.missing_results",
                        defaultMessage: "Sorry, no matching records found",
                    }),
                },
            },
        };

        /**
         * This function is used to handle the confirmation of new filters that has to be applied to the first results.
         *
         * @param newFilters {Array[]}: contains list of current filters (update after interaction of user).
         */
        const searchWithNewFilters = (newFilters) => {
            // const newFilters = applyNewFilters();
            let newColumnsSettings = _.cloneDeep(columnsTable);
            let newBodyRequest = _.cloneDeep(searchBodyRequest);
            let newFiltersTable = [];

            newColumnsSettings.forEach((columnSettings, index) => {
                const valueList = newFilters[index];
                columnSettings.options.filterList = valueList;

                if (valueList.length > 0) newFiltersTable.push({ name: columnSettings.name, value: valueList[0] })
            });

            newBodyRequest.filters_table = newFiltersTable;
            newBodyRequest.page = 0;
            newBodyRequest.start = 0;

            setColumnsTable(newColumnsSettings);
            setSearchBodyRequest(newBodyRequest);

            if (_.isEmpty(newBodyRequest.filters_table)) {
                props.clearFilterTableCount();
                props.searchRequest(newBodyRequest);
            } else {
                props.searchWithFilterTableCount(newBodyRequest)
            }
        };

        // To open comparingDialog or drilldownDialog
        if (viewType === 2 || viewType === 3) {
            options.onCellClick = (colData, cellMeta) => onClickOpenComparingDialog(data[cellMeta.dataIndex].asset_guid, data[cellMeta.dataIndex].status, cellMeta.colIndex);
        } else if (viewType === 4) {
            options.onCellClick = (colData, cellMeta) => onClickOpenVM(data[cellMeta.dataIndex].digital_card_guid, cellMeta.colIndex);
        }

        /**
         * This is the first effect.
         */

        useEffect(() => {console.log("showDownloadModal ", showDownloadModal)},[showDownloadModal])

        useEffect(() => {
            props.clearSearchFlag();

            return () => {
                props.clearColumnsSettings();
                props.clearDeleteAssets();
                props.clearApprovalAssets();
                props.clearExportYnap();
                props.clearAssetDetails();
                props.clearResponseSearch();
            }
        }, []);

        /**
         * This effect is used at the first rendering, because props.location.state.view change only when
         * SearchContainer redirect routing on this container, to lead back the workflow of the effect below we are
         * setting the viewState here.
         */
        useEffect(() => {
            const currentSearch = localStorageService.getCurrentSearch();

            let view;
            let bodyRequest = {
                filter_list: createSearchBodyRequest(currentSearch),
                size: 10,
                page: 0,
                start: 0,
                order: [],
                filters_table: [],
                asset: null
            };

            if (props.location.state.view === SEARCH_VIEWS.products) {
                view = 4;
                bodyRequest.asset = false;
            } else {
                view = 2;
                bodyRequest.asset = true;
            }

            // props.location.state.view === SEARCH_VIEWS.products or SEARCH_VIEWS.assets
            props.getColumns(bodyRequest, props.location.state.view);

            const filterKey = currentSearch.map((filter) => {
                return filter.name;
            });

            const dropdownActions = getDropdownActions(view);

            setActionsState(dropdownActions);
            setViewType(view);
            setFilters(filterKey);
            setChangedViewState(true);
            setSearchBodyRequest(bodyRequest);
        }, [props.location.state.view]);

        /**
         * This effect is used to set the data, and if necessary column information, about the tab selected by user.
         * With this effect we are handling all changes that can change the data paginated backend side. We are
         * handling also the first rendering.
         */
        useEffect(() => {
            if ( props.searchResults.searchExecuted ) {
                props.clearSearchFlag();
            }

            if (viewType && (VIEWS_WITH_TABLE.includes(viewType) &&
                props.columnsSettings.loading !== null && !props.columnsSettings.loading) ||
                VIEWS_WITHOUT_TABLE.includes(viewType)) {
                let tmpColumns;
                let tmpDataTable;
                let totElements;
                let tableRowSelected = [];
                let pagination;

                if (!props.searchResults.loadingSearchRequest && !props.searchResults.loadingCountRequest
                  && props.searchResults.action === ACTION_REQUEST.post) {
                    if ((viewType === 4 && props.columnsSettings.productColumns) ||
                        ((viewType === 3 && props.columnsSettings.assetSmColumns) ||
                            (viewType === 2 && props.columnsSettings.assetColumns) ||
                            VIEWS_WITHOUT_TABLE.includes(viewType) )) {
                        if (viewType === 4) {
                            tmpDataTable = _.cloneDeep(props.searchResults.products);

                            tableRowSelected = getProductRowSelected(tmpDataTable, userRowsSelected);

                            tmpColumns = validateColumns(_.cloneDeep(props.columnsSettings.productColumns));
                            totElements = props.searchResults.productsCount;
                            setTotalTableState(props.searchResults.filtersTableCount);

                            addImageColumn(tmpColumns, viewType);
                            translateCellsContent(tmpColumns, PRODUCT_COLUMNS_NAME_TO_TRANSLATE);
                        } else if (viewType === 3) {
                            tmpDataTable = _.cloneDeep(props.searchResults.assets);

                            tableRowSelected = getAssetRowSelected(tmpDataTable, userRowsSelected);

                            tmpColumns = validateColumns(_.cloneDeep(props.columnsSettings.assetSmColumns));
                            totElements = props.searchResults.assetsCount;
                            setTotalTableState(props.searchResults.filtersTableCount);

                            addImageColumn(tmpColumns, viewType)
                            addCheckboxStatusColumn(tmpColumns);
                        } else if (viewType === 2) {
                            tmpDataTable = _.cloneDeep(props.searchResults.assets);

                            tableRowSelected = getAssetRowSelected(tmpDataTable, userRowsSelected);

                            tmpColumns = validateColumns(_.cloneDeep(props.columnsSettings.assetColumns));
                            totElements = props.searchResults.assetsCount;
                            setTotalTableState(props.searchResults.filtersTableCount)

                            addImageColumn(tmpColumns, viewType)
                            addCheckboxStatusColumn(tmpColumns);
                        } else if (VIEWS_WITHOUT_TABLE.includes(viewType)) {
                            tmpDataTable = _.cloneDeep(props.searchResults.assets);
                            totElements = props.searchResults.assetsCount;

                            if (totalTableState) setTotalTableState(null);

                            tmpDataTable.forEach(asset => {
                                asset.selected = userRowsSelected.includes(asset.asset_guid);
                            });

                            pagination = viewType === 0 ? {
                                pageSize: 30,
                                pageSizeOptions: [
                                    { value: 30, label: 30 },
                                    { value: 40, label: 40 },
                                    { value: 50, label: 50 },
                                ],
                                pageNumber: 1,
                                startIndex: 1,
                                endIndex: 30 > totElements ? totElements : 30
                            } : {
                                pageSize: 12,
                                pageSizeOptions: [
                                    { value: 12, label: 12 },
                                    { value: 16, label: 16 },
                                    { value: 20, label: 20 },
                                ],
                                pageNumber: 1,
                                startIndex: 1,
                                endIndex: 12 > totElements ? totElements : 12
                            };
                        }

                        if (changedViewState) {
                            if (VIEWS_WITH_TABLE.includes(viewType)) {
                                setTableRowSelectedState([]);
                                setColumnsTable(tmpColumns);
                            } else if (VIEWS_WITHOUT_TABLE.includes(viewType)) {
                                setPagination(pagination);
                            }

                            setChangedViewState(false);
                        } else if (!changedViewState && VIEWS_WITH_TABLE.includes(viewType)) {
                            setTableRowSelectedState([...new Set(tableRowSelected)]);
                        }

                        setTotalState(totElements);
                        setBackdropState(prevState => ({
                            ...prevState,
                            loading: false
                        }));
                        setData(tmpDataTable);
                        setLoadingData(false);
                    } else {
                        setBackdropState(prevState => ({
                            ...prevState,
                            loading: false
                        }));
                        setLoadingData(false);
                    }
                } else if (!props.searchResults.loadingSearchRequest && !props.searchResults.loadingCountRequest) {
                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                    setLoadingData(false);
                    if (props.searchResults.error) {
                        setSnackBarState(prevState => ({
                            ...prevState,
                            open: true,
                            level: "error",
                            label: {
                                translateKey: "common.generic.error",
                                defaultText: "Something failed!.",
                                values: {}
                            },
                        }));
                    }
                } else {
                    setLoadingData(true);
                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: true
                    }));
                }
            } else if (VIEWS_WITH_TABLE.includes(viewType) && props.columnsSettings.loading !== null &&
                !props.columnsSettings.loading && props.columnsSettings.error) { // && !props.columnsSettings.productColumns && !props.columnsSettings.assetColumns) {
                setLoadingData(false);
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: false
                }));


                let tableName;

                if (viewType === MAP_VIEWS_WITH_TABLE.products) {
                    tableName = "products";
                } else if (viewType === MAP_VIEWS_WITH_TABLE.assets_sm) {
                    tableName = "assets-sm";
                } else {
                    tableName = "assets";
                }

                setSnackBarState(prevState => ({
                    ...prevState,
                    open: true,
                    level: "error",
                    messageAbout: 'columnsSettings',
                    label: {
                        translateKey: "search.error.loading.columns.settings",
                        defaultText: "Cannot load columns settings for {tableName} table.",
                        values: { tableName: tableName }
                    },
                }));
            } else {
                setLoadingData(true);
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: true
                }));
            }
        }, [props.searchResults, props.columnsSettings, viewType]);

        /**
         * This function is used to move to post production container if all assets, selected by user, are in the
         * correct state.
         */
        const assignHandler = () => {
            props.getAssetsInfoForPost(userRowsSelected);
        };

        useEffect(() => {
            const { loading, action, error, wrongAssetsStatus } = props.postProduction;
            if (action === POST_PRODUCTION_ACTIONS_REQUEST.getAssets) {
                if (!loading) {
                    if (error && !wrongAssetsStatus) {
                        setSnackBarState(prevState => ({
                            ...prevState,
                            open: true,
                            level: "error",
                            label: {
                                translateKey: "post_production.fetching.assets.failure",
                                defaultText: "Cannot fetch assets data.",
                            }
                        }));
                        props.clearPostProduction();
                    } else if (error && wrongAssetsStatus) {
                        setSnackBarState(prevState => ({
                            ...prevState,
                            open: true,
                            level: "error",
                            label: {
                                translateKey: "search.warning.wrong.images.status.assign",
                                defaultText: "Cannot select assets that are not in \"To be post producted\" status."
                            }
                        }));
                        props.clearPostProduction();
                    } else {
                        props.clearResponseSearch();
                        props.history.push({ pathname: "/search/post-production" });
                    }

                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                } else {
                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: true
                    }));
                }
            }
        }, [props.postProduction]);

        /**
         * Function used to handle the selection of each view. For each view type there are some values that have to be
         * reset and other that have to be set:
         *      - pagination: set in case of compact or detailed view;
         *      - rowSelected: set every time that view change;
         *      - paginateDate: set every time that view is compact or detailed;
         *      - data: array of data fetch by server and used to handle compact and detailed view. Set every time that view change;
         *      - view: set every time that view type change;
         *
         * @param {event} event [click event]
         * @param {number} newValue [integer value of current view selected]
         */
        const viewTabsHandler = (event, newValue) => {
            let tmpPagination = {
                pageSize: 0,
                pageSizeOptions: [],
                pageNumber: 1,
                startIndex: 0,
                endIndex: 0
            };

            let bodyRequest = _.cloneDeep(searchBodyRequest);

            switch (newValue) {
                case 0: // Compact
                    bodyRequest.size = 30;
                    bodyRequest.page = 0;
                    bodyRequest.start = 0;
                    bodyRequest.order = [];
                    bodyRequest.filters_table = [];
                    bodyRequest.asset = true;

                    tmpPagination = {
                        pageSize: 30,
                        pageSizeOptions: [
                            { value: 30, label: 30 },
                            { value: 40, label: 40 },
                            { value: 50, label: 50 },
                        ],
                        pageNumber: 1,
                        startIndex: 1,
                        endIndex: 30
                    };

                    tmpPagination = {
                        pageSize: 12,
                        pageSizeOptions: [
                            { value: 12, label: 12 },
                            { value: 16, label: 16 },
                            { value: 20, label: 20 },
                        ],
                        pageNumber: 1,
                        startIndex: 1,
                        endIndex: 12
                    };

                    setSearchBodyRequest(bodyRequest);
                    props.searchRequest(bodyRequest);
                    setActionSelected("");
                    setPagination(tmpPagination);
                    break;
                case 1: // Detailed
                    bodyRequest.size = 12;
                    bodyRequest.page = 0;
                    bodyRequest.start = 0;
                    bodyRequest.order = [];
                    bodyRequest.filters_table = [];
                    bodyRequest.asset = true;

                    tmpPagination = {
                        pageSize: 12,
                        pageSizeOptions: [
                            { value: 12, label: 12 },
                            { value: 16, label: 16 },
                            { value: 20, label: 20 },
                        ],
                        pageNumber: 1,
                        startIndex: 1,
                        endIndex: 12
                    };

                    setSearchBodyRequest(bodyRequest);
                    props.searchRequest(bodyRequest);
                    setActionSelected("");
                    setPagination(tmpPagination);
                    break;
                case 2: // Assets Table
                    bodyRequest.size = 10;
                    bodyRequest.page = 0;
                    bodyRequest.start = 0;
                    bodyRequest.order = [];
                    bodyRequest.filters_table = [];
                    bodyRequest.asset = true;
                    setSearchBodyRequest(bodyRequest);

                    props.searchRequest(bodyRequest);
                    if (!props.columnsSettings.assetColumns ||
                        _.isEmpty(props.columnsSettings.assetColumns)) props.getColumns(bodyRequest, SEARCH_VIEWS.assets);
                    setActionSelected("");
                    break;
                case 3: // Assets Table SM
                    bodyRequest.size = 10;
                    bodyRequest.page = 0;
                    bodyRequest.start = 0;
                    bodyRequest.order = [];
                    bodyRequest.filters_table = [];
                    bodyRequest.asset = true;
                    setSearchBodyRequest(bodyRequest);

                    props.searchRequest(bodyRequest);
                    if (!props.columnsSettings.assetSmColumns ||
                        _.isEmpty(props.columnsSettings.assetSmColumns)) props.getColumns(bodyRequest, SEARCH_VIEWS.assets_sm);
                    setActionSelected("");
                    break;
                case 4: // Product Table
                    if (bodyRequest.asset === true || bodyRequest.asset === null) {
                        bodyRequest.size = 10;
                        bodyRequest.page = 0;
                        bodyRequest.start = 0;
                        bodyRequest.order = [];
                        bodyRequest.filters_table = [];
                        bodyRequest.asset = false;
                        setSearchBodyRequest(bodyRequest);
                    }


                    props.searchRequest(bodyRequest);
                    if (!props.columnsSettings.productColumns ||
                        _.isEmpty(props.columnsSettings.productColumns)) props.getColumns(bodyRequest, SEARCH_VIEWS.products);
                    break;
                default:
                    break;
            }
            props.clearFilterTableCount();
            const footerDropdownActions = getDropdownActions(newValue);

            setActionsState(footerDropdownActions);
            setUserRowsSelected([]);
            setChangedViewState(true);
            setViewType(newValue);
        };

        /**
         * Function used to handle the number of elements per page for Compact and Detailed Views.
         */
        const pageSizeHandler = (event) => {
            let newPagination = _.cloneDeep(pagination);
            let bodyRequest = _.cloneDeep(searchBodyRequest);

            newPagination.pageSize = event.target.value;
            bodyRequest.size = event.target.value;

            const { realStartIndex, userStartIndex, userEndIndex } = getPaginationValues(newPagination);

            newPagination.startIndex = userStartIndex;
            newPagination.endIndex = userEndIndex;
            bodyRequest.start = realStartIndex;

            props.searchRequest(bodyRequest);
            setPagination(newPagination);
            setSearchBodyRequest(bodyRequest);
        };

        /**
         * Function used to handle pageNumber for Compact and Detailed Views.
         *
         * @param next {boolean}: next or prev action page.
         */
        const pageHandler = (next) => {
            let newPagination = _.cloneDeep(pagination);
            let bodyRequest = _.cloneDeep(searchBodyRequest);

            if (next) {
                newPagination.pageNumber += 1;
            } else {
                newPagination.pageNumber -= 1;
            }

            const { realStartIndex, userStartIndex, userEndIndex } = getPaginationValues(newPagination);

            newPagination.startIndex = userStartIndex;
            newPagination.endIndex = userEndIndex;
            bodyRequest.page = newPagination.pageNumber;
            bodyRequest.start = realStartIndex;

            props.searchRequest(bodyRequest);
            setPagination(newPagination);
            setSearchBodyRequest(bodyRequest);
        };

        /**
         * This function is used to calculate different pagination index for each time that size or current page number
         * chance. Used just for Compact or Detailed view.
         * @param currentUserPagination {{pageSize: number, pageSizeOptions: Array, pageNumber: number, startIndex: number, endIndex: number}}: pagination with size or current page changes applied.
         *
         * @returns {{userStartIndex: number, userEndIndex: number, realStartIndex: number}}
         */
        const getPaginationValues = (currentUserPagination) => {
            const startIndex = (currentUserPagination.pageNumber - 1) * currentUserPagination.pageSize + 1;
            let endIndex = currentUserPagination.pageNumber * currentUserPagination.pageSize;
            if (endIndex > totalState) {
                endIndex = totalState;
            }

            return {
                realStartIndex: startIndex - 1,
                userStartIndex: startIndex,
                userEndIndex: endIndex
            };
        };

        /* ********************************************** ACTIONS *************************************************** */
        /**
         * This function is used to set new action selected by user but also activate/deactivate the button based on action
         * type and number of assets selected into table by user.
         */
        const selectActionHandler = (event) => {
            switch (event.target.value) {
                case SEARCH_ASSETS_ACTIONS.downloadExcel:
                case SEARCH_ASSETS_ACTIONS.downloadExcelWithImages:
                case SEARCH_ASSETS_ACTIONS.downloadImages:
                case SEARCH_ASSETS_ACTIONS.deleteAssets:
                case SEARCH_ASSETS_ACTIONS.approve:
                case SEARCH_ASSETS_ACTIONS.reject:
                case SEARCH_ASSETS_ACTIONS.reset:
                case SEARCH_ASSETS_ACTIONS.migrateAssets:
                    break;
                default:
                    setSnackBarState({
                        open: true,
                        level: "error",
                        label: {
                            translateKey: "search.action.error",
                            defaultText: "Action selected is unknown.",
                        },
                    });
                    break;
            }

            setActionSelected(event.target.value);
        };

        /**
         * This effect is used to handle the state used to enable or disable confirm action button in footer of view 0, 1, 2, 3.
         */
        useEffect(() => {
            if (userRowsSelected.length > 0 && actionSelected !== "") {
                if (actionButtonDisabledState) setActionButtonDisabledState(false);
            } else {
                setActionButtonDisabledState(true);
            }
        }, [userRowsSelected, actionSelected]);

        /**
         * This function is used to handle the selection of a single asset for Compact/Detailed views.
         */
        const selectImageHandler = (event) => {
            let newData = _.cloneDeep(data);
            let newUserRowSelected = _.cloneDeep(userRowsSelected);
            const assetIndex = event.target.value;
            const assetGuid = newData[assetIndex].asset_guid;

            if (event.target.checked) {
                newData[assetIndex].selected = true;
                newUserRowSelected.push(assetGuid);
            } else {
                newData[assetIndex].selected = false;
                _.remove(newUserRowSelected, (assetId) => {
                    return assetGuid === assetId;
                });
            }

            setData(newData);
            setUserRowsSelected(newUserRowSelected);
        };

        /**
         * This function is used to select all elements.
         */
        const selectAllHandler = () => {
            props.selectAll(searchBodyRequest);
        };

        /**
         * This function is used to select all elements of the current page.
         */
        const selectInPage = () => {
            const dataInPage = _.cloneDeep(data);
            const guidRowsSelected = _.cloneDeep(userRowsSelected);

            if (VIEWS_WITHOUT_TABLE.includes(viewType)) {
                console.log("View without table");
                dataInPage.forEach(asset => {
                    asset.selected = true;
                    guidRowsSelected.push(asset.asset_guid);
                });
                setData(dataInPage);
            } else {
                const currentPageRowsSelected = [];

                dataInPage.forEach((row, index) => {
                    currentPageRowsSelected.push(index);
                    viewType === 2 || viewType === 3 ?
                        guidRowsSelected.push(row.asset_guid) :
                        guidRowsSelected.push(row.digital_card_guid);
                });

                setTableRowSelectedState(currentPageRowsSelected);
            }
            setUserRowsSelected([...new Set(guidRowsSelected)]);
        };

        /**
         * This function is used to unselect all elements of the current page.
         */
        const unselectInPage = () => {
            const dataInPage = _.cloneDeep(data);
            const guidRowsSelected = _.cloneDeep(userRowsSelected);
            const unselectedElements = [];

            if (VIEWS_WITHOUT_TABLE.includes(viewType)) {
                dataInPage.forEach(asset => {
                    asset.selected = false;
                    unselectedElements.push(asset.asset_guid);
                });
                setData(dataInPage);
            } else {
                dataInPage.forEach((row) => {
                    viewType === 2 || viewType === 3 ?
                        unselectedElements.push(row.asset_guid) :
                        unselectedElements.push(row.digital_card_guid);
                });

                setTableRowSelectedState([]);
            }

            const newUserRowsSelected = guidRowsSelected.filter(el => !unselectedElements.includes(el));

            setUserRowsSelected(newUserRowsSelected);
        };

        /**
         * This function is used to unselect all elements.
         */
        const unselectAllHandler = () => {
            setUserRowsSelected([]);
            setTableRowSelectedState([]);

            if (VIEWS_WITHOUT_TABLE.includes(viewType)) {
                let newData = _.cloneDeep(data).map(asset => ({
                    ...asset,
                    selected: true
                }));

                setData(newData);
            }
        };

        /**
         * This function is used to call the right API based on the current action selected by user.
         */
        const confirmActionHandler = (event) => {

            setClickedConfirm(true)

            switch (actionSelected) {
                case SEARCH_ASSETS_ACTIONS.downloadExcel:
                    downloadExcel(false);
                    break;
                case SEARCH_ASSETS_ACTIONS.downloadExcelWithImages:
                    downloadExcel(true);
                    break;
                case SEARCH_ASSETS_ACTIONS.downloadImages:
                    props.getDownloadInfo(userRowsSelected)
                    break;
                case SEARCH_ASSETS_ACTIONS.deleteAssets:
                    deleteSelectedAssetsHandler();
                    break;
                case SEARCH_ASSETS_ACTIONS.approve:
                    approveAction();
                    break;
                case SEARCH_ASSETS_ACTIONS.reject:
                    rejectAction()
                    break;
                case SEARCH_ASSETS_ACTIONS.reset:
                    resetAction()
                    break;
                case SEARCH_ASSETS_ACTIONS.migrateAssets:
                    migrateAction()
                    break;
                default:
                    setSnackBarState({
                        open: true,
                        level: "error",
                        label: {
                            translateKey: "search.action.error",
                            defaultText: "Action selected is unknown.",
                        },
                    });
                    break;
            }

        };

        /**
         * This effect is used to handle the response to select all products/assets.
         */
        useEffect(() => {
            if (!props.selection.loading) {
                if (props.selection.action === ACTION_REQUEST.post) {
                    if (props.selection.error) {
                        setSnackBarState({
                            open: true,
                            level: "error",
                            label: {
                                translateKey: "search.error.selection",
                                defaultText: "Something failed during the selection of elements.",
                            },
                        });
                    } else {
                        if (viewType === 0 || viewType === 1) {
                            let newData = _.cloneDeep(data).map(asset => ({
                                ...asset,
                                selected: props.selection.assets.includes(asset.asset_guid)
                            }));

                            setData(newData);
                            setUserRowsSelected(props.selection.assets);
                            if (actionSelected !== "") setActionButtonDisabledState(false);
                        } else if (viewType === 2 || viewType === 3) {
                            const tableRowSelected = getAssetRowSelected(data, props.selection.assets);
                            setUserRowsSelected(props.selection.assets);

                            setTableRowSelectedState(tableRowSelected);
                            if (actionSelected !== "") setActionButtonDisabledState(false);
                        } else if (viewType === 4) {
                            const tableRowSelected = getProductRowSelected(data, props.selection.products);
                            setUserRowsSelected(props.selection.products);

                            setTableRowSelectedState(tableRowSelected);
                        }
                    }
                }
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: false
                }));
            } else {
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: true
                }));
            }
        }, [props.selection]);


        /**
         * This function is used to download data selected by user: just in viewType 2, 3, 4. Excel will be generate by
         * be and save somewhere on CloudStorage. We will use a link to download it.
         */
        const downloadExcel = (withImages) => {
            let body;
            let excelColumns = [];

            switch (viewType) {
                case 0:
                    excelColumns.push({
                        key: "filename",
                        name: intl.formatMessage({
                            id: "product.asset.asset_name",
                            defaultMessage: "Asset Name",
                        })
                    });

                    break;
                case 1:
                    KEYS_DETAILED_VIEW.forEach((keyDetailedView) => {
                        excelColumns.push({
                            key: keyDetailedView.key,
                            name: intl.formatMessage({
                                id: keyDetailedView.translateKey,
                                defaultMessage: keyDetailedView.default
                            })
                        });
                    });

                    break;
                case 2:
                case 3:
                case 4:
                    columnsTable.forEach((column) => {
                        if ((column.key !== "img_url" || column.key !== "asset_img_url") && column.options.display) {
                            excelColumns.push({
                                key: column.name,
                                name: column.label
                            });
                        }
                    });

                    break;
                default:
                    break;
            }

            if (viewType === 4) {
                body = {
                    Products: userRowsSelected,
                    columns: excelColumns
                };
            } else {
                body = {
                    Assets: userRowsSelected,
                    columns: excelColumns
                };
            }

            const currentSearch = localStorageService.getCurrentSearch();

            let barcodeGIS = currentSearch.find(filter => filter.name === 'barcode_gis');
            if ( barcodeGIS ) {
                console.log("Search with GIS barcode:", barcodeGIS);
                body.barcodeGIS = barcodeGIS.value.text.stringList.map(code => code.elem);
            }
            props.downloadXlsxUrl(withImages, body);
        };

        /**
         * This function is used to download zip folder containing selected images. If the number of image is too high
         * an email with attachment will be sent.
         */
        const downloadImages = () => {
            const body = viewType === 4 ? { dc_guid: userRowsSelected } : { asset_guid: userRowsSelected };

            props.downloadZipUrl(body);
        };

        const downloadAssets = (guidList) => {
            const body = viewType === 4 ? { dc_guid: guidList } : { asset_guid: guidList };

            props.downloadZipUrl(body);
        }

        /**
         * This is the effect use to handle the response to download excel/images.
         */
        useEffect(() => {
            if (props.downloader.loading) {
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: true
                }));
            } else {
                if (props.downloader.action === SEARCH_ACTION_REQUEST.postDownloadExcel) {
                    if (props.downloader.error) {
                        setSnackBarState({
                            open: true,
                            level: "error",
                            label: {
                                translateKey: "search.error.creation.xlsx",
                                defaultText: "Impossible create excel file.",
                            },
                        });
                    } else {
                        let filename;

                        switch (viewType) {
                            case 0:
                                filename = getFileName('searchTableCompactAssetsExcel');
                                break;
                            case 1:
                                filename = getFileName('searchTableDetailedAssetsExcel');
                                break;
                            case 2:
                            case 3:
                            case 4:
                                filename = viewType === 4 ? getFileName('searchTableProductsExcel') : getFileName('searchTableAssetsExcel');
                                break;
                            default:
                                filename = 'excel.xlsx';
                                break;
                        }

                        if ( props.downloader.xlsxUrl ) {
                            fetch(props.downloader.xlsxUrl)
                                .then(r => r.blob())
                                .then(blob => {
                                    FileSaver.saveAs(blob, filename);
                                })
                                .catch(error => {
                                    console.log(error);
                                    setSnackBarState(prevState => ({
                                        ...prevState,
                                        open: true,
                                        level: "error",
                                        label: {
                                            translateKey: "search.error.download.xlsx",
                                            defaultText: "Impossible export excel file.",
                                        }
                                    }));
                                });
                        }
                        else if ( props.downloader.sentEmail ) {
                            setSnackBarState({
                                open: true,
                                level: "success",
                                label: {
                                    translateKey: "search.download.excel.sentEmail",
                                    defaultText: "An email with the excel is going to be sent to {receiver}.",
                                    values: { receiver: props.downloader.emailReceiver }
                                },
                            });
                        }
                        else {
                            setSnackBarState(prevState => ({
                                ...prevState,
                                open: true,
                                level: "error",
                                label: {
                                    translateKey: "search.error.download.xlsx",
                                    defaultText: "Impossible export excel file.",
                                }
                            }));
                        }
                    }
                    props.clearDownloader();
                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                    setActionSelected("");
                } else if (props.downloader.action === SEARCH_ACTION_REQUEST.postDownloadZip) {
                    if (props.downloader.error) {
                        setSnackBarState({
                            open: true,
                            level: "error",
                            label: {
                                translateKey: "search.error.creation.zip",
                                defaultText: "Impossible create zip folder.",
                            },
                        });
                    } else {
                        if (props.downloader.sentEmail) {
                            setSnackBarState({
                                open: true,
                                level: "success",
                                label: {
                                    translateKey: "search.download.zip.sentEmail",
                                    defaultText: "An email with zip folder is going to be sent to {receiver}.",
                                    values: { receiver: props.downloader.emailReceiver }
                                },
                            });
                        } else {
                            fetch(props.downloader.zipUrl)
                                .then(r => r.blob())
                                .then(blob => {
                                    FileSaver.saveAs(blob, getFileName('searchTableAssetsImageZip'));
                                })
                                .catch(error => {
                                    console.log(error);
                                    setSnackBarState(prevState => ({
                                        ...prevState,
                                        open: true,
                                        level: "error",
                                        label: {
                                            translateKey: "search.error.download.zip",
                                            defaultText: "Impossible download zip folder.",
                                        }
                                    }));
                                });
                        }
                    }
                    props.clearDownloader();

                    setShowDownloadModal({ open: false, totals: [], videos: [], images: [] });
                    setClickedConfirm(false)
                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                    setActionSelected("");
                }
                else if (props.downloader.action === SEARCH_ACTION_REQUEST.postDownloadInfo) {
                    if (props.downloader.error) {
                        setSnackBarState({
                            open: true,
                            level: "error",
                            label: {
                                translateKey: "search.error.download.info",
                                defaultText: "Impossible to get download info.",
                            },
                        });
                    }
                    else {

                        if(clickedConfirm){
                            setShowDownloadModal({
                                open: true,
                                totals: Object.keys(props.downloader.downloadInfo),
                                images: Object.keys(props.downloader.downloadInfo).filter(key => props.downloader.downloadInfo[key] === "IMAGE"),
                                videos: Object.keys(props.downloader.downloadInfo).filter(key => props.downloader.downloadInfo[key] === "VIDEO")
                            });
                        }

                    }

                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                    setActionSelected("");
                }
            }
        }, [props.downloader]);

        const approveAction = () => {
            props.approveAssets({ assets: userRowsSelected });
        };

        const rejectAction = () => {
            props.rejectAssets({ assets: userRowsSelected });
        };

        const resetAction = () => {
            props.resetAssets({ assets: userRowsSelected });
        };

        const migrateAction = () => {
            props.migrateAssets({ assets: userRowsSelected });
        }

        /**
         * This effect is used to handle the response of Approval App (approve, reject, reset actions) showing and error
         * message or refreshing data after a success response.
         */
        useEffect(() => {
            if (!props.approvalAssets.loading &&
                [ SEARCH_ACTION_REQUEST.postApprove, SEARCH_ACTION_REQUEST.postMigrate,
                  SEARCH_ACTION_REQUEST.postReject, SEARCH_ACTION_REQUEST.postReset].includes(props.approvalAssets.action)
            ) {
                if (!props.approvalAssets.error && props.approvalAssets.assets.length > 0) {
                    props.clearApprovalAssets();
                    setUserRowsSelected([]);

                    if (_.isEmpty(searchBodyRequest.filters_table)) {
                        props.searchWithCount(searchBodyRequest);
                    } else {
                        props.searchWithCounts(searchBodyRequest);
                    }
                } else if (props.approvalAssets.error) {
                    if ( typeof props.approvalAssets.error === "string" ) {
                        setSnackBarState({
                            open: true,
                            level: SNACKBAR_LEVEL.error,
                            label: {
                                translateKey: props.approvalAssets.error,
                                defaultText: props.approvalAssets.error
                            }
                        });
                    }
                    else {
                        switch (props.approvalAssets.action) {
                            case SEARCH_ACTION_REQUEST.postApprove:
                                setSnackBarState({
                                    open: true,
                                    level: SNACKBAR_LEVEL.error,
                                    label: {
                                        translateKey: "search.warning.wrong.images.status.approve",
                                        defaultText: "To approve an image the status should be \"Post producted\": check status of selected images."
                                    }
                                });
                                break;
                            case SEARCH_ACTION_REQUEST.postReject:
                                setSnackBarState({
                                    open: true,
                                    level: SNACKBAR_LEVEL.error,
                                    label: {
                                        translateKey: "search.warning.wrong.images.status.reject",
                                        defaultText: "To reject an image the status should be \"Post producted\": check status of selected images."
                                    }
                                });
                                break;
                            case SEARCH_ACTION_REQUEST.postReset:
                                setSnackBarState({
                                    open: true,
                                    level: SNACKBAR_LEVEL.error,
                                    label: {
                                        translateKey: "search.warning.wrong.images.status.reset",
                                        defaultText: "To reset an image the status should be \"Post producted approved\" or \"Post producted rejected\": check status of selected images."
                                    }
                                });
                                break;
                            case SEARCH_ACTION_REQUEST.postMigrate:
                                    setSnackBarState({
                                        open: true,
                                        level: SNACKBAR_LEVEL.error,
                                        label: {
                                            translateKey: "search.warning.cannot_migrate",
                                            defaultText: "Error while exporting image."
                                        }
                                    });
                                    break;
                            default:
                                break;
                        }
                    }
                }
            }
        }, [props.approvalAssets]);

        const exportToYnapOnClickHandler = event => {
            props.exportToYnap(userRowsSelected);
        };

        const deleteSelectedAssetsHandler = () => {
            setDeleteAssetsModalStatus(prevState => ({
                ...prevState,
                open: true
            }));
        };

        const confirmDeleteAssets = () => {
            props.deleteAssets(userRowsSelected);
            setBackdropState(prevState => ({
                ...prevState,
                loading: true
            }));
        };

        /**
         * This effect is used to communicate to user the ending of deletion of assets.
         */
        useEffect(() => {
            if (!props.loadingDeleteAssets && props.actionTypeDeleteAssets === 'POST') {
                if (props.errorDeleteAssets) {
                    setSnackBarState(prevState => ({
                        ...prevState,
                        open: true,
                        level: "error",
                        messageAbout: 'deleteAssets',
                        label: {
                            translateKey: "search.warning.delete.assets",
                            defaultText: "Cannot delete selected assets."
                        }
                    }));
                } else {
                    setDeleteAssetsModalStatus(prevState => ({
                        ...prevState,
                        open: false
                    }));
                    setSnackBarState(prevState => ({
                        ...prevState,
                        open: true,
                        level: "success",
                        messageAbout: 'deleteAssets',
                        label: { translateKey: "search.success.delete.assets", defaultText: "Assets deleted." }
                    }));
                    setUserRowsSelected([]);
                    setTableRowSelectedState([]);

                    if (_.isEmpty(searchBodyRequest.filters_table)) {
                        props.searchWithCount(searchBodyRequest);
                    } else {
                        props.searchWithCounts(searchBodyRequest);
                    }
                }
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: false
                }));
            }
        }, [props.actionTypeDeleteAssets, props.loadingDeleteAssets, props.errorDeleteAssets]);

        /**
         * This effect is used to communicate to user the ending of export of products to YNAP message.
         */
        useEffect(() => {
            if (!props.loadingExportYnap) {
                if (props.actionTypeExportYnap === ACTION_REQUEST.post) {
                    switch (props.errorTypeExportYnap) {
                        case errorType["0"]:    //exported
                            console.log('Exported');
                            setSnackBarState(prevState => (
                                {
                                    ...prevState,
                                    open: true,
                                    level: "success",
                                    label: {
                                        translateKey: "search.success.products.export.ynap",
                                        defaultText: "Export to YNAP has been complete."
                                    }
                                })
                            );
                            break;
                        case errorType["1"]:    //exported with errors
                            console.log('Exported with errors');
                            setSnackBarState(prevState => (
                                {
                                    ...prevState,
                                    open: true,
                                    level: "warning",
                                    label: {
                                        translateKey: "search.warning.products.export.ynap",
                                        defaultText: "Export to YNAP has been complete: {quantity} products are in error.",
                                        values: { quantity: props.productExportedInError.length }
                                    }
                                })
                            );
                            break;
                        case errorType["2"]:    //nothing exported
                            console.log('Nothing exported');
                            setSnackBarState(prevState => (
                                {
                                    ...prevState,
                                    open: true,
                                    level: "error",
                                    label: {
                                        translateKey: "search.error.products.export.ynap.nothing_exported",
                                        defaultText: "Nothing has been exported to YNAP."
                                    }
                                })
                            );
                            break;
                        case errorType["3"]:    //wrong body content
                            console.log('Wrong body content');
                            setSnackBarState(prevState => (
                                {
                                    ...prevState,
                                    open: true,
                                    level: "error",
                                    label: {
                                        translateKey: "search.error.products.export.ynap.wrong_body",
                                        defaultText: "Communication mismatch with server."
                                    }
                                })
                            );
                            break;
                        case errorType["4"]:    //internal server error
                            console.log('Internal server error');
                            setSnackBarState(prevState => (
                                {
                                    ...prevState,
                                    open: true,
                                    level: "warning",
                                    label: {
                                        translateKey: "search.error.products.export.ynap.internal_server_error",
                                        defaultText: "Error during export to YNAP."
                                    }
                                })
                            );
                            break;
                        default:
                            break;
                    }
                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                }
            } else {
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: true
                }));
            }
        }, [
            props.loadingExportYnap,
            props.productExportedInError,
            props.actionTypeExportYnap,
            props.errorTypeExportYnap
        ]);
        /* ********************************************************************************************************** */

        /* ***************************************** DIALOG LIFECICLE *********************************************** */
        /**
         * This function is used to start the process to open the VM dialog.
         * @param digitalCardGuid {string}: unique identifier for a product.
         * @param cellIndex {number}: cell index clicked.
         */
        const onClickOpenVM = (digitalCardGuid, cellIndex) => {
            if (cellIndex === 0) {
                props.getVmInfo(digitalCardGuid);
            }
        };

        const closeViewMonitorDialog = () => {
            props.clearAssetDetails();
            setViewMonitorModalState(prevState => ({
                ...prevState,
                open: false,
                title: null,
                assets: [],
                characteristic: [],
                header: []
            }));
        };

        /**
         * This function is used to fetch data to show comparing modal.
         * @param assetGuid {string}: unique asset identifier.
         * @param statusAsset {string}: asset status.
         * @param colIndex {number}: index of column clicked.
         */
        const onClickOpenComparingDialog = (assetGuid, statusAsset, colIndex) => {
            if (colIndex === 1) {
                console.log(assetGuid, statusAsset, colIndex);
                if (statusAsset === ASSET_STATUS.postProducedNew ||
                    statusAsset === ASSET_STATUS.postProducedApproved ||
                    statusAsset === ASSET_STATUS.postProducedRejected) {
                    const body = {
                        asset_guid: assetGuid,
                        status_list: [COMPARING_DIALOG_ASSET_STATUS.toPostProduce, COMPARING_DIALOG_ASSET_STATUS.postProduceNew]
                    };
                    props.getImagesUrl(body);
                }
            }
        };

        const closeComparingDialog = () => {
            setComparingDialogState({
                open: false,
                content: {
                    assetName: null,
                    toBePostProduceImageUrl: null,
                    postProducedImageUrl: null,
                    notesPost: null,
                    notesForPost: null,
                    assetType: ''
                }
            });
            props.clearAssetDetails();
        };

        /**
         * This effect is used to show the comparingDialog or vmDialog based on response that populate the reducer.
         */
        useEffect(() => {
            const { loading, action, vmInfo, comparingInfo, error } = props.dialogInfo;

            if (action === SEARCH_ACTION_REQUEST.getVM || action === SEARCH_ACTION_REQUEST.getCompareInfo) {
                if (loading) {
                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: true
                    }));
                } else {
                    if (error) {
                        if (action === SEARCH_ACTION_REQUEST.getVM) {
                            setSnackBarState(prevState => ({
                                ...prevState,
                                open: true,
                                level: SNACKBAR_LEVEL.error,
                                // messageAbout: "searchVM",
                                label: {
                                    translateKey: "search.error.view.monitor",
                                    defaultText: "Impossible show VM because cannot fetch information.",
                                    values: {}
                                }
                            }));
                        } else if (action === SEARCH_ACTION_REQUEST.getCompareInfo) {
                            setSnackBarState(prevState => ({
                                ...prevState,
                                open: true,
                                level: SNACKBAR_LEVEL.error,
                                // messageAbout: "comparingInfo",
                                label: {
                                    translateKey: "search.error.compare.asset",
                                    defaultText: "Impossible show comparing dialog because cannot fetch information.",
                                    values: {}
                                }
                            }));
                        }
                        props.clearAssetDetails();
                    } else {
                        if (action === SEARCH_ACTION_REQUEST.getVM) {
                            const newViewMonitorModalState = _.cloneDeep(viewMonitorModalState);

                            const searchVmCharacteristicKeys = ["brand", "gender", "label", "l1_category", "l2_product_grouping", "l3_product", "product_description", "color_description", "seasonality", "range_name_description", "gao_description"];
                            const searchVmHeaderKeys = [
                                { translateKey: null, defaultText: "Ph in", key: "date_in" },
                                { translateKey: null, defaultText: "Ph out", key: "date_out" },
                                { translateKey: "search.keys.source", defaultText: "Source", key: "dress_origin" },
                                { translateKey: "search.keys.requester", defaultText: "Requester", key: "richiedente" },
                                {
                                    translateKey: "search.keys.start.custom.delivery",
                                    defaultText: "Start Custom Delivery",
                                    key: "start_custom_delivery"
                                },
                                {
                                    translateKey: "search.keys.end.custom.delivery",
                                    defaultText: "End Custom Delivery",
                                    key: "end_custom_delivery"
                                }
                            ];
                            const characteristics = [];     // ["value1", "value2", "value3", ...]
                            const headerItems = [];         // [{label: "", value: ""}, ...]

                            searchVmCharacteristicKeys.forEach(key => {
                                if (vmInfo[key]) characteristics.push(vmInfo[key]);
                            });

                            searchVmHeaderKeys.map(headerKeys => headerItems.push({
                                label: headerKeys.translateKey ?
                                    intl.formatMessage({
                                        id: headerKeys.translateKey,
                                        defaultMessage: headerKeys.defaultText
                                    }) :
                                    headerKeys.defaultText,
                                value: vmInfo[headerKeys.key]
                            }));

                            newViewMonitorModalState.open = true;
                            newViewMonitorModalState.title = "VIEW MONITOR";
                            newViewMonitorModalState.characteristic = characteristics;
                            newViewMonitorModalState.header = headerItems;
                            newViewMonitorModalState.assets = vmInfo.assets;

                            setViewMonitorModalState(newViewMonitorModalState);
                        } else if (action === SEARCH_ACTION_REQUEST.getCompareInfo) {
                            setComparingDialogState(prevState => ({
                                ...prevState,
                                open: true,
                                content: {
                                    assetName: comparingInfo.asset_name,
                                    toBePostProduceImageUrl: comparingInfo.dapostprodurre_url,
                                    postProducedImageUrl: comparingInfo.postprodottanuova_url,
                                    notesPost: comparingInfo.notes_post,
                                    notesForPost: comparingInfo.notes_for_post,
                                    assetType: comparingInfo.asset_type
                                }
                            }))
                        }
                    }

                    setBackdropState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                }
            }
        }, [props.dialogInfo]);
        /* ********************************************************************************************************** */

        /**
         * Function used to adapt column table setting.
         *
         * @param {Array} columnList [list of column options.]
         * @returns {Array} [list of column options adapt to current language.]
         */
        const validateColumns = (columnList) => {
            return columnList.map((column) => {
                let columnSettings = {
                    name: column.name,
                    label: intl.formatMessage({
                        id: column.label.id,
                        defaultMessage: column.label.default,
                    }),
                    options: column.options
                };

                columnSettings.options.customFilterListOptions = { render: v => `${columnSettings.label}: ${v}` };

                return columnSettings;
            });
        };

        /**
         * This function is used to select the product data shown into current table page based on usersSelection.
         * @param dataTable {Array}: list of object of current table page.
         * @param rowsSelectedByUser {Array}: list of all selected assets guid (string).
         */
        const getProductRowSelected = (dataTable, rowsSelectedByUser) => {
            let rowSelected = [];

            dataTable.forEach((row, index) => {
                if (rowsSelectedByUser.includes(row.digital_card_guid))
                    rowSelected.push(index);
            });

            return rowSelected;
        };

        /**
         * This function is used to select the asset data shown into current table page based on usersSelection.
         * @param dataTable {Array}: list of object of current table page.
         * @param rowsSelectedByUser {Array}: list of all selected assets guid (string).
         */
        const getAssetRowSelected = (dataTable, rowsSelectedByUser) => {
            let rowSelected = [];
            const guidList = dataTable.map(row => row.asset_guid);
            guidList.forEach((assetGuid, index) => {
                // console.log('Includes ', assetGuid, ' in ', userRowsSelected, '? ', userRowsSelected.includes(assetGuid));
                if (rowsSelectedByUser.includes(assetGuid)) rowSelected.push(index);
            });

            return rowSelected;
        };

        /**
         * This function is used to define the actions of dropdown actions into the footer.
         *
         * @param currentView {number}: current tab;
         * @returns {Array}
         */
        const getDropdownActions = (currentView) => {
            let actions = [];

            DROPDOWN_SEARCH_ACTIONS.forEach(dropdownAction => {
                if (dropdownAction.isAction && checkAction(dropdownAction.actionKey, props.auth.actions) &&
                    (dropdownAction.viewsAllowed.includes(currentView) || dropdownAction.viewsAllowed.includes("*"))) {
                    actions.push({
                        value: dropdownAction.actionValue,
                        label: intl.formatMessage({
                            id: dropdownAction.actionLabelId,
                            defaultMessage: dropdownAction.actionLabelDefault
                        })
                    });
                } else {
                    if (checkApplication(dropdownAction.applicationKey, props.auth.applications) &&
                        (dropdownAction.viewsAllowed.includes(currentView) || dropdownAction.viewsAllowed.includes("*"))) {
                        actions.push({
                            value: dropdownAction.applicationValue,
                            label: intl.formatMessage({
                                id: dropdownAction.applicationLabelId,
                                defaultMessage: dropdownAction.applicationLabelDefault
                            })
                        });
                    }
                }
            });

            return actions;
        }

        const addImageColumn = (columnList, viewType) => {
            if (viewType === 4) {
                columnList.splice(0, 0, {
                    name: "img_url",
                    label: intl.formatMessage({
                        id: "product.img_url",
                        defaultMessage: "Preview",
                    }),
                    options: {
                        display: true,
                        filter: false,
                        filterList: [],
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Paper elevation={0} square>
                                    <PreserveImageRatio src={value}
                                         alt={"Image not found"}
                                         height={125}
                                         width={89}
                                         onError={(event) => onErrorSrc(event)}/>
                                </Paper>
                            );
                        }
                    }
                });
            } else {
                columnList.splice(0, 0, {
                    name: "asset_img_url",
                    label: intl.formatMessage({
                        id: "product.asset.asset_img_url",
                        defaultMessage: "Preview",
                    }),
                    options: {
                        display: true,
                        filter: false,
                        filterList: [],
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const assetType = tableMeta.tableData[tableMeta.rowIndex].asset_type;

                            return (
                                <Paper elevation={0} square>
                                    { assetType === "VIDEO" ?
                                        <div className={classes.movieFrameContainer}>
                                            <MovieIcon className={classes.movieIcon}/>
                                            <PreserveImageRatio
                                                src={value}
                                                height={125}
                                                width={89}
                                                isVideo
                                            />
                                        </div>
                                        :
                                        <div className={classes.movieFrameContainer}>
                                            <PreserveImageRatio src={value}
                                                alt={"Image not found"}
                                                height={125}
                                                width={89}
                                                onError={(event) => onErrorSrc(event)}
                                            />
                                        </div>
                                    }
                                </Paper>
                            );
                        }
                    }
                });
            }
        };

        const addCheckboxStatusColumn = (columnList) => {
            columnList.splice(0, 0, {
                name: "status",
                label: intl.formatMessage({
                    id: "common.empty.value",
                    defaultMessage: " ",
                }),
                options: {
                    display: true,
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let statusCssClass = backgroundColorUsingStatus(value, genericStyle);
                        const formCssClass = classes.squareStatusTable;

                        return <div className={`${formCssClass} ${statusCssClass}`}/>;
                    },
                },
            });
        };

        /**
         * This function is used to apply a custom body render for each columns that needs to translate its content.
         *
         * @param columnSettings {[Object]}: list of column options.
         * @param columnsName {[String]}}: columns name.
         */
        const translateCellsContent = (columnSettings, columnsName) => {
            columnsName.forEach(columnName => {
                const columnSettingIndex = _.findIndex(columnSettings, column => column.name === columnName);
                columnSettings[columnSettingIndex].options.customBodyRender = (value, tableMeta, updateValue) =>
                    <p>
                        {intl.formatMessage({ id: value.external_name, defaultMessage: value.name })}
                    </p>;
            });
        };

        const closeSnackbar = () => {
            props.clearErrorStatus();

            switch (snackBarState.messageAbout) {
                case 'deleteAssets':
                    props.clearDeleteAssets();
                    break;
                case 'columnsSettings':
                    props.clearColumnsSettings();
                    break;
                /*case 'searchVM':
                case 'comparingInfo':
                    props.clearAssetDetails();
                    break;*/
                default: break;
            }

            setSnackBarState(prevState => ({
                ...prevState,
                open: false,
                level: "error",
                messageAbout: null,
                label: { translateKey: "tmp", defaultText: "", values: {} }
            }));
        };


        return (
            <div className={classes.root}>
                <BackdropElement loading={backdropState.loading} label={backdropState.label}/>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                      spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.header}>
                            <Grid container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center">
                                {(viewType || viewType === 0) && (
                                    <Grid item>
                                        <MenuTabsElement value={viewType}
                                                         onChangeHandler={viewTabsHandler}
                                                         tabs={MENU_TABS}/>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Grid container
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="center"
                                          spacing={3}>
                                        <Grid item>
                                            {totalTableState > 0 ?
                                                <Typography>
                                                    {intl.formatMessage({
                                                        id: "search.header.count.with.filter.table",
                                                        defaultMessage: "{rowsSelected} Selected | {totalRows} Total (with filters: {totalFiltersRows})"
                                                    }, {
                                                        rowsSelected: userRowsSelected.length,
                                                        totalRows: totalState,
                                                        totalFiltersRows: totalTableState
                                                    })}
                                                </Typography> :
                                                <Typography>
                                                    {intl.formatMessage({
                                                        id: "search.header.count",
                                                        defaultMessage: "{rowsSelected} Selected | {totalRows} Total"
                                                    }, {
                                                        rowsSelected: userRowsSelected.length,
                                                        totalRows: totalState
                                                    })}
                                                </Typography>}
                                        </Grid>
                                        {viewType !== 4 && (
                                            <Grid item>
                                                {GetTooltipStatusColorComponent('bottom', genericStyle)}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <FiltersHeader
                            filtersApplied={filters}
                            selectAllHandler={selectAllHandler}
                            selectInPage={selectInPage}
                            unselectAllHandler={unselectAllHandler}
                            unselectInPage={unselectInPage}/>
                    </Grid>
                    {viewType === 0 &&
                    !loadingData && (
                        <Grid item xs={12}>
                            <GridCompactView
                                data={data}
                                selectHandler={selectImageHandler}
                                openComparingDialogHandler={onClickOpenComparingDialog}
                                genericStyle={genericStyle}
                            />
                        </Grid>
                    )}
                    {viewType === 1 &&
                    !loadingData && (
                        <Grid item xs={12}>
                            <GridDetailedView
                                data={data}
                                selectHandler={selectImageHandler}
                                keys={KEYS_DETAILED_VIEW}
                                openComparingDialogHandler={onClickOpenComparingDialog}
                                genericStyle={genericStyle}
                            />
                        </Grid>
                    )}
                    {(VIEWS_WITH_TABLE.includes(viewType)) &&
                    !loadingData && (
                        <Grid item xs={12}>
                            <FilterableTable
                                title={{ id: "search.results", default: "Search Results" }}
                                rowsData={data}
                                columns={columnsTable}
                                options={options}
                            />
                        </Grid>
                    )}
                    {VIEWS_WITHOUT_TABLE.includes(viewType) &&
                    pagination.pageSize > 0 && (
                        <Grid item xs={12}>
                            <PaginationFooter
                                paginationValues={pagination}
                                pageSizeHandler={pageSizeHandler}
                                pageHandler={pageHandler}
                                totalElements={totalState}
                            />
                        </Grid>
                    )}
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        {viewType !== 4 && !_.isEmpty(actionsState) && (
                            <Grid item xs={3} lg={2}>
                                <DropDownElement
                                    name="actions"
                                    value={actionSelected}
                                    label={{
                                        translateKey: "search.button.actions",
                                        defaultText: "Actions",
                                    }}
                                    onChangeHandler={selectActionHandler}
                                    options={actionsState}
                                />
                            </Grid>
                        )}
                        {viewType !== 4 && (
                            <Grid item xs={4} lg={2}>
                                <ButtonElement
                                    label={intl.formatMessage({
                                        id: "common.confirm",
                                        defaultMessage: "Confirm",
                                    })}
                                    onSubmit={confirmActionHandler}
                                    disabled={actionButtonDisabledState}/>
                            </Grid>
                        )}
                        {viewType !== 4 &&
                        checkApplication("post_production", props.auth.applications) && (
                            <Grid item xs={4} lg={2}>
                                <ButtonElement
                                    label={intl.formatMessage({
                                        id: "search.button.assign",
                                        defaultMessage: "Assign",
                                    })}
                                    onSubmit={assignHandler}
                                    disabled={userRowsSelected.length === 0}/>
                            </Grid>
                        )}
                        {viewType === 4 &&
                        checkAction("search.export_ynap", props.auth.actions) &&
                        <Grid item xs={4} lg={2}>
                            <ButtonElement
                                label={intl.formatMessage({
                                    id: "search.button.exportYNAP",
                                    defaultMessage: "Force export YNAP",
                                })}
                                onSubmit={exportToYnapOnClickHandler}
                                disabled={userRowsSelected.length === 0}/>
                        </Grid>
                        }
                        {viewType === 4 &&
                        checkAction("search.download_zip", props.auth.actions) && (
                            <Grid item xs={4} lg={2}>
                                <ButtonElement
                                    label={intl.formatMessage({
                                        id: "search.button.label.download.images",
                                        defaultMessage: "Download assets",
                                    })}
                                    onSubmit={downloadImages}
                                    disabled={userRowsSelected.length === 0}/>
                            </Grid>
                        )}
                        {viewType === 4 &&
                        checkAction("search.download_xlsx", props.auth.actions) && (
                            <Grid item xs={4} lg={2}>
                                <ButtonElement
                                    label={intl.formatMessage({
                                        id: "search.button.label.download.excel",
                                        defaultMessage: "Export Excel",
                                    })}
                                    onSubmit={() => downloadExcel(false)}
                                    disabled={userRowsSelected.length === 0}/>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SnackbarElement
                                open={snackBarState.open}
                                type={snackBarState.level}
                                label={snackBarState.label.defaultText}
                                labelId={snackBarState.label.translateKey}
                                labelParams={snackBarState.label.values}
                                handleClose={() => closeSnackbar()}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ModalFullScreen openDialog={viewMonitorModalState.open}
                                             dialogTitle={viewMonitorModalState.title}
                                             closeHandler={closeViewMonitorDialog}
                                             spacing={2}>
                                <Grid container
                                      direction="row"
                                      alignItems="center"
                                      justify="center"
                                      spacing={2}
                                      className={classes.viewMonitorDialogContainer}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.subtitle}>
                                                    {intl.formatMessage({
                                                        id: "product.characteristic.data",
                                                        defaultMessage: "Characteristic data of the product",
                                                    })}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {
                                                    viewMonitorModalState.characteristic.map((characteristic, index) =>
                                                        <ChipElement
                                                            key={index}
                                                            cssType={"info"}
                                                            label={characteristic}/>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container
                                                      justify="flex-start"
                                                      alignItems="center">
                                                    {
                                                        viewMonitorModalState.header.map((headerItem, index) =>
                                                            <Grid item
                                                                  xs={2}
                                                                  key={index}>
                                                                <Typography className={classes.subtitle}>
                                                                    {headerItem.label}
                                                                </Typography>
                                                                <Typography>
                                                                    {headerItem.value ? headerItem.value : "-"}
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    {
                                        viewMonitorModalState.assets.map((asset, index) =>
                                            <Grid key={index}
                                                  item
                                                  xs={6}>
                                                <Grid container
                                                      direction="row"
                                                      justify="center"
                                                      alignItems="flex-start"
                                                      spacing={3}>
                                                    <Grid item xs={6}>
                                                        <div
                                                            className={`${classes.viewMonitorImgContainer} ${backgroundColorUsingStatus(asset.status, genericStyle)}`}>
                                                            <IconExport exportStatus={asset.export_status}/>
                                                            {asset.asset_type === "VIDEO" ?
                                                                <div className={classes.movieFrameContainer}>
                                                                    <MovieIcon className={classes.movieIcon}/>
                                                                    <PreserveImageRatio
                                                                        src={asset.asset_img_url}
                                                                        height={280}
                                                                        width={200}
                                                                        isVideo
                                                                    />
                                                                </div>
                                                                :
                                                                <div className={classes.movieFrameContainer}>
                                                                    <PreserveImageRatio
                                                                        src={asset.asset_img_url}
                                                                        className={classes.viewMonitorImg}
                                                                        alt={"Image not found"}
                                                                        height={280}
                                                                        width={200}
                                                                        onError={(event) => onErrorSrc(event)}/>
                                                                </div>
                                                            }
                                                            <Typography style={{ marginBottom: 4 }}>
                                                                {asset.view}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {getSingleViewData(asset, genericStyle, intl, "search")}
                                                    </Grid>
                                                </Grid>
                                            </Grid>)
                                    }
                                </Grid>
                            </ModalFullScreen>
                            <ModalFullScreen
                                openDialog={comparingDialogState.open}
                                dialogTitle={comparingDialogState.content.assetName}
                                closeHandler={closeComparingDialog}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    spacing={2}
                                    className={classes.containerComparingDialog}>
                                    <Grid item xs={6}>
                                        <Typography variant={'h5'}>Shoot</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant={'h5'}>Post Produced</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.movieFrameContainer}>
                                            <PreserveImageRatio
                                                src={comparingDialogState.content.toBePostProduceImageUrl}
                                                alt={"Image not found"}
                                                height={560}
                                                width={400}
                                                onError={(event) => onErrorSrc(event)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {comparingDialogState.content.assetType === "VIDEO" ?
                                            <div className={classes.movieFrameContainer}>
                                                <MovieIcon className={classes.movieIcon}/>
                                                <PreserveImageRatio
                                                    src={comparingDialogState.content.postProducedImageUrl}
                                                    height={560}
                                                    width={400}
                                                    isVideo
                                                />
                                            </div>
                                            :
                                            <div className={classes.movieFrameContainer}>
                                                <PreserveImageRatio
                                                    src={comparingDialogState.content.postProducedImageUrl}
                                                    alt={"Image not found"}
                                                    height={560}
                                                    width={400}
                                                    onError={(event) => onErrorSrc(event)}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.noteComparingDialog}>
                                            {comparingDialogState.content.notesForPost}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.noteComparingDialog}>
                                            {comparingDialogState.content.notesPost}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ModalFullScreen>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={showDownloadModal.open}
                    onClose={() => {

                        setShowDownloadModal({ open: false, totals: [], images: [], videos: [] })
                        setClickedConfirm(false)
                    }
                    }
                    aria-labelledby="download-info-dialog"
                >
                    <DialogTitle>{
                        intl.formatMessage({
                            id: 'search.dialog.title.download.assets',
                            defaultMessage: 'Download selected assets'
                        })
                    }</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="download-info-dialog-description">
                        {
                            intl.formatMessage({
                                id: 'search.dialog.body.download.assets'
                            }, {
                                total: showDownloadModal.totals.length,
                                videos: showDownloadModal.videos.length,
                                images: showDownloadModal.images.length
                            })
                        }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "search.button.label.download.all",
                                defaultMessage: "Download All",
                            })}
                            onSubmit={() => downloadAssets(showDownloadModal.totals)}
                            disabled={!showDownloadModal.totals.length}
                        />
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "search.button.label.download.only_images",
                                defaultMessage: "Download Images",
                            })}
                            onSubmit={() => downloadAssets(showDownloadModal.images)}
                            disabled={!showDownloadModal.images.length}
                        />
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "search.button.label.download.only_videos",
                                defaultMessage: "Download Videos",
                            })}
                            onSubmit={() => downloadAssets(showDownloadModal.videos)}
                            disabled={!showDownloadModal.videos.length}
                        />
                    </DialogActions>
                </Dialog>
                {
                    deleteAssetsModalStatus.open &&
                    <ReusableModal reusableModal={deleteAssetsModalStatus}
                                   greenButtonClick={() => confirmDeleteAssets()}
                                   greenButtonText={intl.formatMessage({
                                       id: 'common.confirm',
                                       defaultMessage: 'Confirm'
                                   })}
                                   closeReusableModal={() => setDeleteAssetsModalStatus((prevState => ({
                                       ...prevState,
                                       open: false
                                   })))}>
                        <Typography>
                            {
                                intl.formatMessage({
                                    id: 'search.dialog.body.delete.assets',
                                    defaultMessage: 'Delete assets:'
                                })
                            }
                        </Typography>
                    </ReusableModal>
                }
            </div>
        );
    }
;
const mapStateToProps = (state) => ({
    auth: state.authReducer.auth,
    searchResults: state.searchReducer,
    dialogInfo: state.searchDialogInfo,
    approvalAssets: state.approvalReducer,
    loadingExportYnap: state.exportToYnapReducer.loading,
    productExportedInError: state.exportToYnapReducer.productsInError,
    actionTypeExportYnap: state.exportToYnapReducer.actionType,
    errorTypeExportYnap: state.exportToYnapReducer.errorType,
    actionTypeDeleteAssets: state.assetsReducer.actionType,
    loadingDeleteAssets: state.assetsReducer.loading,
    errorDeleteAssets: state.assetsReducer.error,
    columnsSettings: state.columnsSettingsReducer,
    selection: state.searchSelectionReducer,
    downloader: state.searchDownloadReducer,
    postProduction: state.postProductionReducer
});

const mapDispatchToProps = (dispatch) => ({
    getImagesUrl: (body) => dispatch(startGetImagesToCompare(body)),
    approveAssets: (body) => dispatch(startApproveAssets(body)),
    rejectAssets: (body) => dispatch(startRejectAssets(body)),
    resetAssets: (body) => dispatch(startResetAssets(body)),
    migrateAssets: (body) => dispatch(startMigrateAssets(body)),
    searchRequest: (filters) => dispatch(startPostSearchRequest(filters)),
    searchWithCount: (filters) => dispatch(startPostSearchWithCountRequest(filters)),
    searchWithCounts: (filters) => dispatch(startPostSearchWithCountsRequest(filters)),
    searchWithFilterTableCount: (filters) => dispatch(startPostSearchWithFilterTableCountRequest(filters)),
    clearFilterTableCount: () => dispatch(clearFilterTableCount()),
    clearResponseSearch: () => dispatch(clearResponseSearch()),
    clearSearchFlag: () => dispatch(clearSearchFlag()),
    clearErrorStatus: () => dispatch(clearErrorStatus()),
    // startClearEmail: () => dispatch(clearEmail()),
    clearApprovalAssets: () => dispatch(clearAssets()),
    exportToYnap: (body) => dispatch(startExportToYnap(body)),
    deleteAssets: (assetsList) => dispatch(startDeleteAssets(assetsList)),
    clearDeleteAssets: () => dispatch(clearDeleteAssets()),
    getDownloadInfo: (body) => dispatch(startGetDownloadInfo(body)),
    getColumns: (body, tabView) => dispatch(startGetColumnsSettings(body, tabView)),
    clearColumnsSettings: () => dispatch(clearColumnsSettings()),
    clearExportYnap: () => dispatch(clearExportToYnap()),
    selectAll: (body) => dispatch(startPostSelectAll(body)),
    downloadXlsxUrl: (withImages, body) => dispatch(startPostDownloadExcel(withImages, body)),
    downloadZipUrl: (body) => dispatch(startPostDownloadZip(body)),
    clearDownloader: () => dispatch(clearDownload()),
    getVmInfo: (digitalCardGuid) => dispatch(startGetViewMonitorInfo(digitalCardGuid)),
    clearAssetDetails: () => dispatch(clearSearchInfo()),
    getAssetsInfoForPost: (assetsGuidList) => dispatch(startFetchPostProductionAssets(assetsGuidList)),
    clearPostProduction: () => dispatch(clearPostProduction())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchResultsContainer);

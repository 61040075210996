import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import InputTextElement from "../../../../components/InputTextElement/InputTextElement";
import ButtonElement from "../../../../components/ButtonElement/ButtonElement";
import {Typography} from "@material-ui/core";
import DropDownElement from "../../../../components/DropDownElement/DropDownElement";
import IconButtonElement from "../../../../components/IconButtonElement/IconButtonElement";


const useStyles = makeStyles((theme) => ({
    optionsMenuElements: {
        marginTop: '20px'
    },
    styleElement: {
        marginTop: '40px'
    },
    styleFabricColorInputElement: {
        marginTop: '40px'
    },
    buttonElement: {
        marginTop: '40px'
    }
}));

const NewProduct = props => {
    const classes = useStyles();
    const intl = useIntl();
    const {
        barcodeOnChangeHandler,
        styleOnKeyPressHandler,
        styleOnChangeHandler,
        fabricOnChangeHandler,
        colorOnChangeHandler,
        fakeProductCodeOnChangeHandler,
        confirmOnKeyPressHandler,
        addNewProductOnClickHandler,
        clearAll,
        values,
        loadingFabrics,
        loadingColors,
        fabricOptions,
        colorOptions,
        dropdownsState
    } = props;

    return (
        <Grid container
              direction="row"
              justify="space-evenly"
              alignItems="flex-start">
            <Grid item xs={12}>
                <Grid container
                      direction={'row'}
                      justify="space-between"
                      alignItems="flex-start">
                    <Grid item>
                        <Typography variant={'h6'}>
                            {intl.formatMessage({ id: 'showroom.look.product.new', defaultMessage: 'Add new product' })}:
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        {(values.barcode !== '' ||
                            values.style !== '' ||
                            values.styleFabricColor !== '' ||
                            values.fakeProduct !== '') &&
                        <IconButtonElement icon={'cancel'}
                                           onClick={clearAll}
                                           disabled={
                                               values.barcode === '' &&
                                               values.style === '' &&
                                               values.fabric === '' &&
                                               values.color === '' &&
                                               values.styleFabricColor === '' &&
                                               values.fakeProduct === ''
                                           }
                                           labelId={'common.clear'}
                                           label={'Clear'}
                                           type={'delete'}/>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container
                      direction={'column'}
                      justify="center"
                      alignItems="stretch" className={classes.optionsMenuElements}>
                    <Grid item>
                        {/*TODO: bisognerà aggiungere l'input per il barcode, ma al momento non è gestito e quindi balza.*/}
                        <InputTextElement placeholderId={"showroom.look.product.productIdOrCode"}
                                          placeholder={"Product ID or Code"}
                                          name={"barcode"}
                                          icon={"local_offer"}
                                          handleChange={barcodeOnChangeHandler}
                                          handleKeyPress={confirmOnKeyPressHandler}
                                          value={values.barcode}
                                          type={'text'}
                                          fullWidth
                                          disabled={(values.styleFabricColor !== '' || values.fakeProduct !== '' || values.style !== '') && (values.whatChange === 'styleFabricColor' || values.whatChange === 'fakeStyleFabricColor' || values.whatChange === 'style')}/>
                    </Grid>
                    <Grid item className={classes.styleElement}>
                        <InputTextElement placeholderId={"showroom.look.product.style"}
                                          placeholder={"Style"}
                                          name={"style"}
                                          icon={"style"}
                                          handleChange={styleOnChangeHandler}
                                          handleKeyPress={styleOnKeyPressHandler}
                                          value={values.style}
                                          type={'text'}
                                          disabled={(values.styleFabricColor !== '' || values.fakeProduct !== '' || values.barcode !== '') && (values.whatChange === 'styleFabricColor' || values.whatChange === 'fakeStyleFabricColor' || values.whatChange === 'barcode')}
                                          fullWidth/>
                    </Grid>
                    <Grid item>
                        {
                            values.styleFabricColor !== '' && values.whatChange === 'styleFabricColor' ?
                                (<InputTextElement placeholderId={"showroom.look.product.fabric"}
                                                   placeholder={"Fabric"}
                                                   name={"fabric"}
                                                   icon={"push_pin"}
                                                   value={values.fabric}
                                                   type={'text'}
                                                   disabled={values.styleFabricColor !== '' || values.barcode !== ''}
                                                   fullWidth/>) :
                                (<DropDownElement name={'fabric'}
                                                  value={values.fabric}
                                                  label={{
                                                      translateKey: 'showroom.look.product.fabric',
                                                      defaultText: 'Fabric'
                                                  }}
                                                  onChangeHandler={fabricOnChangeHandler}
                                                  options={fabricOptions || []}
                                                  icon={"push_pin"}
                                                  disabled={values.style === '' || values.barcode !== '' || !fabricOptions || values.whatChange === 'fakeStyleFabricColor' || values.whatChange === 'barcode'}
                                                  loading={loadingFabrics}
                                                  open={dropdownsState.fabric}/>)
                        }
                    </Grid>
                    <Grid item>
                        {
                            values.styleFabricColor !== '' && values.whatChange === 'styleFabricColor' ?
                                (<InputTextElement placeholderId={"showroom.look.product.color"}
                                                   placeholder={"Color"}
                                                   name={"color"}
                                                   icon={"palette"}
                                                   value={values.color}
                                                   type={'text'}
                                                   disabled={values.styleFabricColor !== '' || values.barcode !== ''}
                                                   fullWidth/>) :
                                (<DropDownElement name={'color'}
                                                  value={values.color}
                                                  label={{
                                                      translateKey: 'showroom.look.product.color',
                                                      defaultText: 'Color'
                                                  }}
                                                  onChangeHandler={colorOnChangeHandler}
                                                  options={colorOptions || []}
                                                  icon={"palette"}
                                                  disabled={values.fabric === '' || values.barcode !== '' || !colorOptions || values.whatChange === 'fakeStyleFabricColor' || values.whatChange === 'barcode'}
                                                  loading={loadingColors}
                                                  open={dropdownsState.color}/>)
                        }
                    </Grid>
                    <Grid item className={classes.styleFabricColorInputElement}>
                        <InputTextElement placeholderId={"showroom.look.product.productCode.fake"}
                                          placeholder={"Product Code Placeholder"}
                                          name={"style_fabric_color_fake"}
                                          icon={"file_copy"}
                                          handleChange={fakeProductCodeOnChangeHandler}
                                          handleKeyPress={confirmOnKeyPressHandler}
                                          value={values.fakeProduct}
                                          type={'text'}
                                          fullWidth
                                          disabled={(values.style !== '' || values.styleFabricColor !== '' || values.barcode !== '') && (values.whatChange === 'styleFabricColor' || values.whatChange === 'style' || values.whatChange === 'barcode')}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonElement}>
                <ButtonElement labelId={'showroom.look.product.button.add.product'}
                               label={'Add product'}
                               onSubmit={addNewProductOnClickHandler}
                               disabled={values.styleFabricColor === '' && values.style === '' && values.fakeProduct === '' && values.barcode === ''}/>
            </Grid>
        </Grid>
    )
};

export default NewProduct;
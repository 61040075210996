import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "./DigitalAssetsItemStyle";
import IconButtonElement from "../../../IconButtonElement/IconButtonElement";
import PreserveImageRatio from "../../../PreserveImageRatio/PreserveImageRatio";

const DigitalAssetsItem = props => {
    const classes = useStyles();
    const { items, iconButton, iconAction, labelIcon } = props;
    const [elements, setElements] = useState();

    console.log("WHAAAAAAT ", items);

    useEffect(() => {
        const gridElements = items.map((item, index) => {
            return (
                <Grid item xs={2} key={index}>
                    <Grid container
                          direction="row"
                          justify="space-around"
                          alignItems="center">
                        <Grid item className={classes.paperImage}>
                            <div className={classes.label}>
                                {item.label}
                            </div>
                            <Paper elevation={0} square className={classes.paper}>
                                {iconButton && <div className={classes.icon} style={{ zIndex: 1000 }}><IconButtonElement icon={iconButton}
                                                                                                onClick={() => iconAction(item)}
                                                                                                labelId={labelIcon.labelId}
                                                                                                label={labelIcon.label}
                                                                                                type={"delete"}/></div>}
                                {item.type.startsWith("video/") ?
                                  <video
                                    className={item.deleted ? classes.img : null}
                                    width={100}
                                    height={140}
                                  >
                                    <source src={item.img_url} type="video/mp4" />
                                  </video>
                                  :
                                  <PreserveImageRatio
                                    className={item.deleted ? classes.img : null}
                                    src={item.img_url}
                                    alt={item.name}
                                    height={140} width={100}
                                  />
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            )
        });
        setElements(gridElements);
    }, [items]);


    return (
        <Card raised>
            <Grid container
                  direction="row"
                  className={classes.gridRoot}
            >
                {elements}
            </Grid>
        </Card>
    )
};

export default DigitalAssetsItem;
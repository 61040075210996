import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import "./index.css";
import App from "./App";
import {IntlProviderWrapper} from "./utils/IntlContext";
import * as serviceWorker from './serviceWorker';
import {store} from "./store/configureStore";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (process.env.REACT_APP_ENV !== "development") {
    console.log = () => ({});
}

Sentry.init({
    dsn: "https://5b0f378c039341568fd60aaa2d89b2ad@o733109.ingest.sentry.io/5821875",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const jsx = (
    <Provider store={store}>
        <IntlProviderWrapper>
            <App/>
        </IntlProviderWrapper>
    </Provider>
);

ReactDOM.render(jsx, document.getElementById("root"));

serviceWorker.unregister();
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import ButtonElement from "../../components/ButtonElement/ButtonElement";
import {seasons_columns} from "../../assets/resources/admin_seasons_columns";
import MaterialSimpleTable from "../../components/TableElement/MaterialSimpleTable/MaterialSimpleTable";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import SeasonForm from "../../components/Admin/Season/SeasonForm";
import {clearSeasons, startGetSeasons, startPostSeason, startPutSeason} from "../../store/actions/season";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";
import {filterActiveSeasons, validateColumnsMaterialSimpleTable} from "../../utils/CommonFunction";


const AdminSeasonsContainer = (props) => {
    const intl = useIntl();

    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState({
        modalRows: {},
        open: false,
        title: intl.formatMessage({
            id: "admin.season.modal.title",
            defaultMessage: "Season Details",
        })
    });
    const [editReusableModal, setEditReusableModal] = useState({
        data: {},
        open: false,
        title: ""
    });
    const [confirmModal, setConfirmModal] = useState({
        open: false,
        title: ""
    });
    const [snackBarState, setSnackBarState] = useState({
        open: false,
        level: "error",
        label: { translateKey: "tmp", defaultText: "" },
    });

    useEffect(() => {
        // Clear state
        props.clearSeasonState();
        // setup the columns
        const columns = validateColumnsMaterialSimpleTable(intl, seasons_columns);
        setColumns(columns);
    }, []);

    useEffect(() => {
        console.log("useEffect seasons", props.seasons);
        if (props.seasons) {
            props.seasons.forEach((season) => {
                season.status =
                    season.active === 1
                        ? intl.formatMessage({
                            id: "admin.season.active",
                            defaultMessage: "Active",
                        })
                        : intl.formatMessage({
                            id: "admin.season.not.active",
                            defaultMessage: "Not Active",
                        });
            });
            // if seasons is updated, update data, accordingly
            setTableData(props.seasons);
        }
    }, [props.seasons]);

    useEffect(() => {
        console.log("TO UPDATE", props.seasonEdited);
        if (!_.isEmpty(props.seasonEdited)) {
            const newSuccess = {
                open: true,
                level: "success",
                label: { translateKey: "admin.season.success", defaultText: "Operation executed with success." }
            };
            setSnackBarState(newSuccess);
            props.getSeasons();
        }
    }, [props.seasonEdited])


    useEffect(() => {
        console.log("TO UPDATE", props.error)
        if (props.error) {
            const newError = {
                open: true,
                level: "error",
                label: { translateKey: "admin.season.error", defaultText: "Operation not executed." },
            }
            setSnackBarState(newError)
            props.getSeasons();
        }
    }, [props.error]);

    const onClickRow = (event, row) => {
        console.log("ROW CLICKED", row);
        const newState = { ...showDetailModal };
        newState.open = true;
        newState.modalRows = row;
        setShowDetailModal(newState);
    };

    const openNewEditModal = (isNew) => {
        console.log("Open edit modal");
        const newState = _.cloneDeep(editReusableModal);
        newState.open = true;
        newState.data.isNew = isNew;

        if (isNew) {
            newState.title = intl.formatMessage({
                id: "admin.season.modal.title",
                defaultMessage: "Insert Season Details",
            });
            newState.data.code = '';
            newState.data.external_code = '';
            newState.data.guid = '';
            newState.data.name = '';
            newState.data.timestamp = null;
        } else {
            newState.title = intl.formatMessage({
                id: "admin.season.modal.title",
                defaultMessage: "Edit Season Details",
            });
            newState.data = _.cloneDeep(showDetailModal.modalRows);
        }

        console.log('Stato modale edit: ', newState);
        setEditReusableModal(newState);
    };

    const editSeason = () => {
        const seasonData = editReusableModal.data;

        if (editReusableModal.data.isNew) {
            console.log("CREATING NEW SEASON", seasonData);
            props.createSeason(seasonData);
        } else {
            console.log("EDIT SEASON with guid", seasonData, seasonData.guid);
            props.editSeason(seasonData);
        }

        let newEditModalState = _.cloneDeep(editReusableModal);
        newEditModalState.open = false;
        newEditModalState.isNew = false;
        newEditModalState.title = "";
        newEditModalState.data = {};

        let newDetailModalState = _.cloneDeep(showDetailModal);
        newDetailModalState.open = false;

        setShowDetailModal(newDetailModalState);
        setEditReusableModal(newEditModalState);
    };

    const deactivateSeason = () => {
        const seasonData = _.cloneDeep(showDetailModal.modalRows);
        const newState = _.cloneDeep(showDetailModal);

        seasonData.active = false;

        newState.open = false;
        newState.title = "";
        newState.modalRows = {};

        props.editSeason(seasonData);

        setShowDetailModal(newState);
        setConfirmModal({
            ...confirmModal,
            open: false,
        });
    };

    const editChangeHandler = (event) => {
        const newEditReusableModal = _.cloneDeep(editReusableModal);
        console.log('Name: ', event.target.name);
        switch (event.target.name) {
            case 'code':
                newEditReusableModal.data.code = event.target.value;
                break;
            case 'description':
                newEditReusableModal.data.name = event.target.value;
                break;
            case 'external_code':
                newEditReusableModal.data.external_code = event.target.value;
                break;
            case 'status':
                console.log('Status: ', event.target.checked);
                newEditReusableModal.data.active = event.target.checked;
                break;
            default:
                console.log("Unknown input case!");
                break;
        }
        console.log(newEditReusableModal)
        setEditReusableModal(newEditReusableModal);
    };

    const onCloseShowModal = () => {
        console.log("Close show modal");
        const newState = _.cloneDeep(showDetailModal);
        newState.open = false;
        newState.modalRows = {};
        setShowDetailModal(newState);
    };

    const onCloseEditModal = () => {
        console.log("Close edit modal");
        const newState = _.cloneDeep(editReusableModal);
        newState.open = false;
        newState.title = "";
        newState.data = {};

        setEditReusableModal(newState);
    };

    const onCloseConfirmModal = () => {
        console.log("Close confirm modal");
        const newState = _.cloneDeep(confirmModal);
        newState.open = false;
        setConfirmModal(newState);
    };


    return (
        <div>
            <BackdropElement loading={props.loadingSeason || false}/>
            <SnackbarElement
                open={snackBarState.open}
                type={snackBarState.level}
                label={snackBarState.label.defaultText}
                labelId={snackBarState.label.translateKey}
                handleClose={() =>
                    setSnackBarState({
                        open: false,
                        level: "error",
                        label: { translateKey: "tmp", defaultText: "" },
                    })
                }/>
            <MaterialSimpleTable
                data={tableData}
                columns={columns}
                onRowClick={onClickRow}
                components={{
                    Action: (tableProps) => {
                        return (
                            <ButtonElement
                                label={intl.formatMessage({
                                    id: "admin.season.add.season",
                                    defaultMessage: "Add New Season",
                                })}
                                onSubmit={() => openNewEditModal(true)}
                            />
                        );
                    },
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Add new season",
                        onClick: () => {
                            // open new user modal
                        },
                        isFreeAction: true,
                    },
                ]}
                pageSize={10}/>

            {/* Modal to show season details */}
            <ReusableModal
                reusableModal={showDetailModal}
                greenButtonClick={() => openNewEditModal(false)}
                greenButtonText={intl.formatMessage({ id: "common.button.label.edit", defaultMessage: "Edit" })}
                closeReusableModal={onCloseShowModal}>
                <Grid container
                      direction="row"
                      alignItems={"flex-start"}
                      justify={"flex-start"}
                      spacing={1}>
                    {
                        columns.map(column =>
                            <Grid container item key={column.title} justify={'center'} xs={12} spacing={2}>
                                <Grid item xs={3}>
                                    {column.title}
                                </Grid>
                                <Grid item xs={5}>
                                    {showDetailModal.modalRows[column.field]}
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </ReusableModal>

            {/* Modal to create a new season or edit an existing one */}
            <SeasonForm reusableModal={editReusableModal}
                        okReusableModal={editSeason}
                        closeReusableModal={onCloseEditModal}
                        handleOnChange={editChangeHandler}
                        season={editReusableModal.data}/>

            {/* Modal to deactivate a new season */}
            <ReusableModal
                reusableModal={confirmModal}
                greenButtonClick={deactivateSeason}
                greenButtonText={intl.formatMessage({ id: "common.confirm", defaultMessage: "Confirm" })}
                closeReusableModal={onCloseConfirmModal}>
                {intl.formatMessage({
                    id: "admin.season.confirm",
                    defaultMessage: "Are you sure you want to deactivate this season?"
                })}
            </ReusableModal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    seasons: filterActiveSeasons(state.seasonReducer.seasons),
    seasonEdited: state.seasonReducer.seasonEdited,
    loadingSeason: state.seasonReducer.loadingSeason,
    error: state.seasonReducer.error
});

const mapDispatchToProps = (dispatch) => {
    return {
        editSeason: (season) => dispatch(startPutSeason(season)),
        getSeasons: () => dispatch(startGetSeasons()),
        createSeason: (season) => dispatch(startPostSeason(season)),
        clearSeasonState: () => dispatch(clearSeasons())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AdminSeasonsContainer));

import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

const localStorageService = LocalStorage.getService();

export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const UPDATE_USERS = "UPDATE_USERS";
export const RESET_SNACKBAR = "RESET_SNACKBAR";
export const CLEAR_USERS = "CLEAR_USERS";

export function getUserInfoRequest() {
    return { type: GET_USER_INFO_REQUEST };
}

export function getUserInfoSuccess(user = {}) {
    return { type: GET_USER_INFO_SUCCESS, user };
}

export function getUserInfoFailure(error) {
    return { type: GET_USER_INFO_FAILURE, error };
}

export function getUsersRequest() {
    return { type: GET_USERS_REQUEST };
}

export function postUserRequest() {
    return { type: POST_USER_REQUEST };
}

export function getUsersSuccess(users = []) {
    return { type: GET_USERS_SUCCESS, users };
}

export function getRolesSuccess(roles = []) {
    return { type: GET_ROLES_SUCCESS, roles };
}

export function updateUsers(data) {
    return { type: UPDATE_USERS, data }
}

export function resetSnackBar() {
    return { type: RESET_SNACKBAR };
}

export function clearUsers() {
    return { type: CLEAR_USERS };
}

export function startPostUser(newUser) {
    let token = localStorageService.getAccessToken();
    console.log("startPostUser with", token);
    return (dispatch) => {
        dispatch(getUsersRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/user/create?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                Authorization: "Bearer " + token,
            },
            data: JSON.stringify(newUser),
        })
            .then(function (response) {
                console.log("Response post user", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("User :", response);
                    dispatch(updateUsers(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getUserInfoFailure(error.response.data));
            });
    };
}

export function startPutUser(user, guid) {
    let token = localStorageService.getAccessToken();
    console.log("startPutUser with", token);
    return (dispatch) => {
        dispatch(getUsersRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/user/${guid}?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                Authorization: "Bearer " + token,
            },
            data: JSON.stringify(user),
        })
            .then(function (response) {
                console.log("Response post user", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("User :", response);
                    dispatch(updateUsers(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getUserInfoFailure(error.response.data));
            });
    };
}

// DA NON USARE PER LA DISATTIVAZIONE UTENTE
export function startDeleteUser(guid) {
    let token = localStorageService.getAccessToken();
    console.log("startDeleteUser with", token);
    return (dispatch) => {
        dispatch(getUsersRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/user/delete?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                Authorization: "Bearer " + token,
            },
            data: JSON.stringify({ "guid": guid }),
        })
            .then(function (response) {
                console.log("Response delete user", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("User :", response);
                    dispatch(updateUsers(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getUserInfoFailure(error.response.data));
            });
    };
}

export function startGetUserInfo() {
    let token = localStorageService.getAccessToken();
    console.log("startGetUserInfo with", token);
    return (dispatch) => {
        dispatch(getUserInfoRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/user?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                Authorization: "Bearer " + token,
            },
            data: JSON.stringify({}),
        })
            .then(function (response) {
                console.log("Response user info ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("User :", response);
                    dispatch(getUserInfoSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getUserInfoFailure(error.response.data));
            });
    };
}

export function startGetUsers() {
    let token = localStorageService.getAccessToken();
    console.log("startGetUsers with", token);
    return (dispatch) => {
        dispatch(getUsersRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/users?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then(function (response) {
                console.log("Response users ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("Users :", response);
                    dispatch(getUsersSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getUserInfoFailure(error.response.data));
            });
    };
}

export function startGetRoles() {
    let token = localStorageService.getAccessToken();
    console.log("startGetRoles");
    return (dispatch) => {
        dispatch(getUserInfoRequest());
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/roles?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then(function (response) {
                console.log("Response roles ", response);
                if (response.status === 200 && response.data !== null) {
                    console.log("Roles :", response);
                    dispatch(getRolesSuccess(response.data));
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) dispatch(loggedOut());
                dispatch(getUserInfoFailure(error.response.data));
            });
    };
}

export function hideUsersSnackBar() {
    return (dispatch) => {
        dispatch(resetSnackBar());
    };
}

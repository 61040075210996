import React from "react";
import {connect} from "react-redux";
import {useIntl} from "react-intl";
import IconButtonElement from "../../../../components/IconButtonElement/IconButtonElement";
import AccordionElementLookShowroom
    from "../../../../components/AccordionElement/Showroom/Look/AccordionElementLookShowroom";
import PreserveImageRatio from "../../../../components/PreserveImageRatio/PreserveImageRatio";
import {Grid, Icon} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {getCompositionShowroomImageBorderColor, onErrorSrc, checkAction} from "../../../../utils/CommonFunction";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MovieIcon from '@material-ui/icons/Movie';
import Tooltip from "@material-ui/core/Tooltip";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles((theme) => ({
    approvedLookImageStyle: {
        border: '3px solid '.concat(theme.palette.button.edit),
        position: "relative"
    },
    draftImgStyle: {
        border: '3px solid '.concat(theme.palette.isDraft.main),
        position: "relative"
    },
    movieFrameContainer: {
        display: 'flex',
        width: '100%'
    },
    movieIcon: {
        position: 'absolute',
        color: theme.palette.button.deactive,
        zIndex: 2,
        marginTop: '3px',
        marginLeft: '3px'
    },
    warningPlaceholderIcon: {
        color: 'yellow'
    },
    tooltipPhotoPreview: {
        maxWidth: 'none'
    }
}));

const Look = props => {
    const intl = useIntl();
    const classes = useStyles();
    const {
        look,
        digitalAssets,
        onClickAccordion,
        expanded,
        approveLookHandler,
        resetLookHandler,
        editLookHandler,
        deleteLookHandler
    } = props;

    console.log('LOOK: ', look);
    console.log('LOOK - PHOTOS: ', digitalAssets);

    const icons = [
        checkAction("composition.reset_look", props.auth.actions) && 
        <IconButtonElement icon={'rotate_right_icon'}
                           onClick={event => resetLookHandler(event, look.id)}
                           onFocus={event => event.stopPropagation()}
                           label={'Reset'}
                           labelId={'common.label.reset'}
                           disabled={digitalAssets.length === 0 || look.isApproved === false}/>,
        checkAction("composition.approve_look", props.auth.actions) && 
        <IconButtonElement icon={'check_circle_icon'}
                           onClick={event => approveLookHandler(event, look.id)}
                           onFocus={event => event.stopPropagation()}
                           label={'Approve'}
                           labelId={'common.label.approve'}
                           disabled={digitalAssets.length === 0 || look.isApproved === true}
                           type={look.isApproved ? 'approve' : null}/>,
        checkAction("composition.edit_look", props.auth.actions) && 
        <IconButtonElement icon={'edit_icon'}
                           onClick={event => editLookHandler(event, false, look)}
                           onFocus={event => event.stopPropagation()}
                           label={'Edit'}
                           labelId={'common.label.edit'}/>,
        checkAction("composition.delete_look", props.auth.actions) && 
        <IconButtonElement icon={'cancel_icon'}
                           onClick={event => deleteLookHandler(event, look.id)}
                           onFocus={event => event.stopPropagation()}
                           label={'Delete'}
                           labelId={'common.label.delete'}
                           type={'delete'}
                           disabled={digitalAssets.length > 0}/>
    ];

    // Adding icon about look note
    if (look.note) {
        icons.splice(3, 0, <Tooltip placement="top" title={look.note}>
            <span>
                <Icon onClick={event => event.stopPropagation()}>info_icon</Icon>
            </span>
        </Tooltip>);
    } else {
        icons.splice(3, 0, <Icon onClick={event => event.stopPropagation()} color="disabled">info_icon</Icon>);
    }

    // Adding warning icon about products placeholder
    if (look.warning) {
        icons.splice(0, 0, <Tooltip title={intl.formatMessage({
            id: 'showroom.warning.look.product.placeholder',
            defaultMessage: 'There is, at least, a placeholder product associate to this look.'
        })}>
            <WarningRoundedIcon onClick={event => event.stopPropagation()} className={classes.warningPlaceholderIcon}>
                warning_rounded_icon
            </WarningRoundedIcon>
        </Tooltip>);
    } else {
        icons.splice(0, 0, <WarningRoundedIcon onClick={event => event.stopPropagation()}
                                               color="disabled">warning_rounded_icon</WarningRoundedIcon>);
    }

    return (
        <Grid item xs={12}>
            <AccordionElementLookShowroom id={look.id}
                                          header={look.name}
                                          icons={icons}
                                          onChange={onClickAccordion}
                                          open={expanded}>
                <Grid container
                      direction={"column"}
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={2}>
                    {
                        digitalAssets.map(digitalAsset =>
                            <Grid item key={digitalAsset.id}>
                                <Grid container direction={"row"}
                                      justify="flex-start"
                                      alignItems="flex-start"
                                      spacing={4}>
                                    <Grid item>
                                        {
                                            digitalAsset.type === 'image' ?
                                                <Tooltip placement={"right"}
                                                         title={
                                                             <PreserveImageRatio src={digitalAsset.url}
                                                                  height={560}
                                                                  width={400}
                                                                  onError={(event) => onErrorSrc(event)}
                                                                  alt="Image not found."/>
                                                         } classes={{ tooltip: classes.tooltipPhotoPreview }}>
                                                    <div className={classes.movieFrameContainer}>
                                                        <PreserveImageRatio src={digitalAsset.url ? digitalAsset.url : 'Image not found.'}
                                                             height={140}
                                                             width={100}
                                                             onError={(event) => onErrorSrc(event)}
                                                             className={getCompositionShowroomImageBorderColor(classes, digitalAsset.is_polaroid, digitalAsset.look ? digitalAsset.look.is_approved : false)}
                                                             alt="Image not found."/>
                                                    </div>
                                                </Tooltip> :
                                                <Tooltip placement={"right"}
                                                         title={
                                                             <PreserveImageRatio
                                                                src={digitalAsset.frame_url}
                                                                height={560}
                                                                width={400}
                                                                isVideo
                                                            />
                                                         } classes={{ tooltip: classes.tooltipPhotoPreview }}>
                                                    <div className={classes.movieFrameContainer}>
                                                        <MovieIcon className={classes.movieIcon}/>
                                                        <PreserveImageRatio
                                                            src={digitalAsset.frame_url}
                                                            height={140}
                                                            width={100}
                                                            className={getCompositionShowroomImageBorderColor(classes, digitalAsset.is_polaroid, digitalAsset.look ? digitalAsset.look.is_approved : false)}
                                                            isVideo
                                                        />
                                                    </div>
                                                </Tooltip>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                              direction={"column"}
                                              justify="flex-start"
                                              alignItems="flex-start"
                                        >
                                            <Grid item>
                                                <Typography>{intl.formatMessage({
                                                    id: 'showroom.look.detail.label',
                                                    defaultMessage: 'Label'
                                                })}: {digitalAsset.label}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>{intl.formatMessage({
                                                    id: 'showroom.look.detail.must_buy',
                                                    defaultMessage: 'Must Buy'
                                                })}: {digitalAsset.must_buy ? 'Yes' : 'No'}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>{intl.formatMessage({
                                                    id: 'showroom.look.detail.status',
                                                    defaultMessage: 'Status'
                                                })}: {digitalAsset.look.is_approved ? 'Yes' : 'No'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </AccordionElementLookShowroom>
        </Grid>
    )

};

const mapStateToProps = (state) => ({
    auth: state.authReducer.auth
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Look);
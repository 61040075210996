import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1, 0),
		},
		"& .MuiSvgIcon-root": {
			fill: theme.palette.text.placeholder,
		},
		"& .MuiInputBase-input": {
			color: theme.palette.text.main,
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-disabled fieldset": {
				borderColor: "transparent",
				boxShadow: "none !important",
			},
			"& fieldset": {
				borderColor: "transparent",
			},
			"&.Mui-focused fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&:hover fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&.Mui-error fieldset": {
				borderColor: theme.palette.error.main,
				boxShadow: "0 0 5px ".concat(theme.palette.error.main),
			},
		},
	},
	autocomplete: {
		backgroundColor: theme.palette.background.main,
		borderRadius: theme.spacing(0.5),
		color: theme.palette.text.main,
		width: "25ch",
		"& label.Mui-focused": {
			color: theme.palette.text.main,
		},
	},
	loader: {
		color: theme.palette.text.main,
	},
	paper: {
		backgroundColor: theme.palette.headerTable.main,
	},
	inputLabelRoot: {
		color: theme.palette.text.main,
		"&.focused": {
			color: theme.palette.text.main,
		},
	},
	option: {
		color: theme.palette.text.main,
		fontSize: '1rem',
		"&:hover": {
			backgroundColor: theme.palette.background.hover,
		},
	},
	listbox: {
		padding: 0,
		fontSize: '1rem'
	},
}));

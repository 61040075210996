import {
    GET_DOWNLOAD_DMG_REQUEST, GET_DOWNLOAD_DMG_SUCCESS, GET_DOWNLOAD_DMG_FAILURE
} from "../../actions/shooting/shootingDownload";

const initialState = {downloadUrl: undefined}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DOWNLOAD_DMG_REQUEST:
            return {
                ...state,
                loadingDownloadUrl: true
            };
        case GET_DOWNLOAD_DMG_SUCCESS:
            return {
                ...state,
                loadingDownloadUrl: false,
                downloadUrl: action.downloadUrl
            };
        case GET_DOWNLOAD_DMG_FAILURE:
            return {
                ...state,
                loadingDownloadUrl: false,
                error: action.error
            }
        default:
            return state;
    }
}
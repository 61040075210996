import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {logs_columns} from "../../assets/resources/admin_logs_columns";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import MaterialSimpleTable from "../../components/TableElement/MaterialSimpleTable/MaterialSimpleTable";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import {validateColumnsMaterialSimpleTable} from "../../utils/CommonFunction";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginTop: theme.spacing(12),
//         flexGrow: 1,
//     }
// }));

const AdminLogsContainer = (props) => {
	// const classes = useStyles();
	const intl = useIntl();

	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [showDetailModal, setShowDetailModal] = useState({
		open: false,
		title: intl.formatMessage({
			id: "admin.logs.modal.title",
			defaultMessage: "Log Details",
		}),
		modalRows: {}
	});
	useEffect(() => {
		// setup the columns
		const columns = validateColumnsMaterialSimpleTable(intl, logs_columns);
		setColumns(columns);
	}, []);

	useEffect(() => {
		console.log("useEffect logs", props.audits);
		if (props.audits !== undefined) {
			// if logs is updated, update data, accordingly
			setTableData(props.audits);
		}
	}, [props.audits]);

	const onClickRow = (event, row) => {
		console.log("ROW CLICKED", row);
		const newState = { ...showDetailModal };
		newState.open = true;
		newState.modalRows = row;
		setShowDetailModal(newState);
	};


	return (
		<div>
			<BackdropElement loading={props.loadingLogs || false}/>
			<MaterialSimpleTable
				data={tableData}
				columns={columns}
				onRowClick={onClickRow}
				pageSize={10}
			/>
			{/* view detail modal */}
			<ReusableModal
				reusableModal={showDetailModal}
				closeReusableModal={() => setShowDetailModal({ ...showDetailModal, open: false })}>
				<Grid container
					  direction="row" spacing={1}>
					{
						columns.map(column =>
							<Grid container item key={column.title} justify={'center'} xs={12} spacing={2}>
								<Grid item xs={3}>
									{column.title}
								</Grid>
								<Grid item xs={5}>
									{showDetailModal.modalRows[column.field]}
								</Grid>
							</Grid>
						)
					}
				</Grid>
			</ReusableModal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	audits: state.auditReducer.audits,
	loadingLogs: state.auditReducer.loadingAudits,
});

const mapDispatchToProps = (dispatch) => {
	return {
		//   loadingLog: () => dispatch(loadingLog()),
		// getLogs: () => dispatch(startGetLogs()),
		//   getRoles: () => dispatch(startGetRoles()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AdminLogsContainer));

import {GET_LABEL_FAILURE, GET_LABEL_REQUEST, GET_LABEL_SUCCESS} from "../../actions/search/label";

const initialState = {label: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LABEL_REQUEST:
            return {
                loadingLabel: true
            };
        case GET_LABEL_SUCCESS:
            return {
                ...state,
                loadingLabel: false,
                label: action.label,
            };
        case GET_LABEL_FAILURE:
            return {
                ...state,
                loadingLabel: false,
                error: action.error
            }
        default:
            return state;
    }
}
import React from "react";
import Chip from "@material-ui/core/Chip";
import Clear from '@material-ui/icons/Clear';
import {useStyles} from "./ChipElementStyle";

const ChipElement = (props) => {
	const classes = useStyles();
	const {
		label,
		cssType,
		onDelete
	} = props;

	let chipClass = classes.info;

	if (cssType) {
		switch (cssType) {
			case "info":
				chipClass = classes.info;
				break;
			case "deletable":
				chipClass = classes.deletable;
				break;
			case "transparent":
				chipClass = classes.transparent;
				break
			case "lightGrey":
				chipClass = classes.lightGrey;
				break;
			default:
				chipClass = classes.deletable;
				break;
		}
	}

	return (
		<Chip
			label={label}
			className={`${chipClass}`}
			// if onDelete is not specified, the chip is not deletable
			onDelete={onDelete}
			deleteIcon={<Clear/>}
		/>
	);
};

export default ChipElement;

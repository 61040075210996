import {
    CLEAR_GALLERIES,
    GET_GALLERIES_FAILED,
    GET_GALLERIES_SUCCESS,
    GET_GALLERIES_REQUEST
} from "../../../actions/showroom/product/galleries";

const initalState = {
    loading: false,
    galleries: null,
    action: null,
    error: null
}

export default (state = initalState, action) => {
    switch(action.type){
        case CLEAR_GALLERIES:
            return initalState;
        case GET_GALLERIES_REQUEST:
            return {
                ...state,
                loading: true,
                action: 'GET'
            };
        case GET_GALLERIES_SUCCESS:
            return {
                ...state,
                galleries: action.galleries,
                loading: false
            };
        case GET_GALLERIES_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        default:
            return state;
    }
}
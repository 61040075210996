import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {useStyles} from "./DndCompositionGalleryShowroomDetailElementStyle";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import IconButtonElement from "../../IconButtonElement/IconButtonElement";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import MovieIcon from "@material-ui/icons/Movie";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Typography from "@material-ui/core/Typography";
import {getCompositionShowroomImageBorderColor, onErrorSrc, checkAction} from "../../../utils/CommonFunction";
import PreserveImageRatio from "../../../components/PreserveImageRatio/PreserveImageRatio";

const DndCompositionGalleryShowroomDetailElement = props => {
    const { gallery, chunkList, onDragEnd, selectHandler, deleteHandler } = props;
    const classes = useStyles();

    console.log('DndCompositionGalleryShowroomDetailElement \n- gallery: ', gallery, ' \n- digitalAssets: ', chunkList);

    const DraggableHOC = ({ draggableId, children, key, index }) => (
        checkAction("composition.sort_images_gallery", props.auth.actions) ?
            <Draggable 
                draggableId={draggableId}
                index={index}
                key={key}
            >
                {(provided) => (
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classes.imgContainer}
                    >
                        {children}
                    </div>
                )}
            </Draggable>
            :
            <div className={classes.imgContainer}>
                {children}
            </div>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {
                chunkList.map((assetsPerRow, rowIndex) => {
                    console.log('ASSETS: ', assetsPerRow);
                    console.log('INDEX: ', rowIndex);

                    if (assetsPerRow) {
                        return <Droppable droppableId={rowIndex.toString()}
                                          type={'gallery-digitalAssets'}
                                          direction={'horizontal'}
                                          key={rowIndex}>
                            {(provided) => (
                                <div ref={provided.innerRef}
                                     {...provided.droppableProps}
                                     className={classes.rowContainer}>
                                    {
                                        assetsPerRow.map((asset, assetIndex) => (
                                            <DraggableHOC 
                                                draggableId={asset.id.toString()}
                                                index={assetIndex}
                                                key={asset.id}
                                            >
                                                <div className={classes.item}>
                                                    <div className={classes.deleteIcon}>
                                                        {checkAction("composition.delete_image_gallery", props.auth.actions) &&
                                                            <IconButtonElement
                                                                fontSize="small"
                                                                labelId={'showroom.remove.image.gallery'}
                                                                label={'Remove image from gallery.'}
                                                                icon={"delete"}
                                                                onClick={(event) => deleteHandler(event, asset)}
                                                            />
                                                        }
                                                    </div>
                                                    <div className={classes.selectCheckbox}>
                                                        <Checkbox
                                                            icon={<Icon
                                                                className={classes.unchecked}>{'radio_button_unchecked'}</Icon>}
                                                            checkedIcon={<Icon
                                                                className={classes.checked}>{'radio_button_checked'}</Icon>}
                                                            name={rowIndex.toString()}
                                                            size="medium"    // size: small, medium
                                                            checked={asset.checked}
                                                            onChange={(event) => selectHandler(event, rowIndex, assetIndex)}
                                                        />
                                                    </div>
                                                    {
                                                        asset.type === 'image' ?
                                                            <>
                                                                <Tooltip title={asset.display_filename}>
                                                                    <img src={asset.url}
                                                                            alt={"Image not found"}
                                                                            height={280}
                                                                            width={200}
                                                                            className={getCompositionShowroomImageBorderColor(classes, asset.is_polaroid, asset.look ? asset.look.is_approved : false)}
                                                                            onError={(event) => onErrorSrc(event)}/>
                                                                </Tooltip>
                                                                <Typography>{asset.label && asset.label.length > 12 ? asset.label.substr(0, 9).concat('...') : asset.label}</Typography>
                                                            </>
                                                            :
                                                            <>
                                                                <Tooltip title={asset.display_filename}>
                                                                    <div
                                                                        className={classes.movieFrameContainer}>
                                                                        <MovieIcon
                                                                            className={asset.label ? classes.movieIconWithLabel : classes.movieIcon}/>
                                                                        <PreserveImageRatio
                                                                            src={asset.frame_url}
                                                                            height={280}
                                                                            width={200}
                                                                            className={getCompositionShowroomImageBorderColor(classes, asset.is_polaroid, asset.look ? asset.look.is_approved : false)}
                                                                            isVideo
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                                <Typography>{asset.label && asset.label.length > 12 ? asset.label.substr(0, 9).concat('...') : asset.label}</Typography>
                                                            </>

                                                    }
                                                    {!_.isEmpty(asset.look) &&
                                                    <div
                                                        className={asset.label ? classes.bookmarkIconWithLabel : classes.bookmarkIcon}>
                                                        <BookmarkIcon/>
                                                    </div>}
                                                </div>
                                            </DraggableHOC>
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    } else {
                        return null;
                    }
                })
            }
        </DragDropContext>
    );
};

const mapStateToProps = (state) => ({
    auth: state.authReducer.auth
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DndCompositionGalleryShowroomDetailElement);
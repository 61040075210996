import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "./MenuTabsElementStyle";
import {useIntl} from "react-intl";


const MenuTabsElement = props => {
    const classes = useStyles();
    const intl = useIntl();

    const { value, onChangeHandler, tabs } = props;

    const tabsList = tabs.map(tab => {
        return <Tab key={tab.default}
                    label={tab.translateKey ? intl.formatMessage({
                        id: tab.translateKey,
                        defaultMessage: tab.default
                    }) : tab.default}
                    disabled={tab.disabled || false}/>
    });

    return (
        <Paper square>
            <Tabs
                data-cy="menu-tabs"
                value={value}
                onChange={onChangeHandler}
                className={classes.tabs}>
                {tabsList}
            </Tabs>
        </Paper>
    );
};

export default MenuTabsElement;
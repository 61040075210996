import React, {useEffect, useState} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DndProductGalleryShowroomDetailElement
    from "../../../components/DragNDrop/DndProductGalleryShowroomDetailElement/DndProductGalleryShowroomDetailElement";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReusableModal from "../../../components/Modal/ReusableModal/ReusableModal";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import ButtonElement from "../../../components/ButtonElement/ButtonElement";
import {startPostProductsShowroomGalleryOrder} from "../../../store/actions/showroom/product/galleryActions";
import {startGetProductsShowroomGallery} from "../../../store/actions/showroom/product/gallery";
import {startProductGalleryLookcastExport} from "../../../store/actions/showroom/product/exportToLookcast";
import {
    ACTION_REQUEST,
    PRODUCT_SHOWROOM_ACTION_REQUEST,
    SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM,
    SNACKBAR_LEVEL,
    PRODUCT_STATUS_SHOWROOM
} from "../../../utils/constant";
import {clearProducts, startPutProducts} from "../../../store/actions/showroom/product/products";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3)
    },
    footerGrid: {
        marginTop: theme.spacing(2)
    },
    content: {
        marginTop: theme.spacing(3),
        height: '760px',
        overflowY: 'scroll'
    }
}));

const ManageGalleryTabContainer = props => {
    const classes = useStyles();
    const intl = useIntl();

    const {
        genericStyle,
        changesState,
        setChangesState,
        setBackdrop,
        setSnackbar,
        productsPerRow,
        getProductDetails
    } = props;

    const [productsGalleryState, setProductsGalleryState] = useState([]);
    const [warningSwitchingOrderModalState, setWarningSwitchingOrderModalState] = useState({
        open: false,
        title: intl.formatMessage({ id: 'common.warning', defaultMessage: 'Warning!!!' }),
        message: '',
        sortingChange: false
    });
    const [warningChangesNotSaveModalState, setWarningChangesNotSaveModalState] = useState({
        open: false,
        title: intl.formatMessage({ id: 'common.warning', defaultMessage: 'Warning!!!' }),
        message: intl.formatMessage({
            id: 'showroom_product.warning.pending.change',
            defaultMessage: 'There are some pending changes, if you confirm you will lost them. Are you sure?'
        }),
        removingProductGuid: null
    });

    /**
     * This effect is triggered when the gallery's products list change.
     */
    useEffect(() => {
        if (!_.isEmpty(props.galleryInfo.products) || !_.isEmpty(productsGalleryState)) {
            let rawProducts = _.cloneDeep(props.galleryInfo.products)
                .filter(product => props.showDeleted ? true : product.status !== PRODUCT_STATUS_SHOWROOM.deleted);

            setProductsGalleryState(prevState => {
                let products = [];
                let rowChunk = [];

                rawProducts.forEach((product, index) => {
                    if (product.selected) rowChunk.push(_.cloneDeep(product));
                    if ((rowChunk.length === productsPerRow || rawProducts.length === index + 1) &&
                        rowChunk.length > 0) {
                        products.push(rowChunk);
                        rowChunk = [];
                    }
                });

                return products;
            });
        }
    }, [props.galleryInfo.products, productsPerRow, props.showDeleted]);


    const onDragEndHandler = (result) => {
        const { source, destination } = result;

        if (source && destination) {
            const startIndex = (productsPerRow * parseInt(source.droppableId)) + source.index;
            const endIndex = (productsPerRow * parseInt(destination.droppableId)) + destination.index;

            let products = [];

            // Creating a single array of digital assets: merging different chunks.
            productsGalleryState.forEach(chunk => {
                products = _.concat(products, chunk);
            });

            // Positioning items in the correct position
            const productObj = products.splice(startIndex, 1);
            products.splice(endIndex, 0, productObj[0]);

            // Splitting again into chuck to renderer elements in the right way
            let tmpProductChunk = [];
            let chunksProduct = [];

            products.forEach((product, index) => {
                tmpProductChunk.push(product);

                if ((tmpProductChunk.length === productsPerRow || products.length === index + 1) &&
                    tmpProductChunk.length > 0) {
                    chunksProduct.push(tmpProductChunk);
                    tmpProductChunk = [];
                }
            });

            setProductsGalleryState(chunksProduct);
            if (!changesState) setChangesState(true);
            if (!props.galleryInfo.gallery.is_manual_order) setWarningSwitchingOrderModalState(prevState => ({
                ...prevState,
                message: intl.formatMessage({
                    id: 'showroom_product.warning.switching.gallery.sorting',
                    defaultMessage: 'You are going to override the sorting method for this gallery moving from {initialSorting} to {finalSorting}. Are you sure?'
                }, { initialSorting: 'from an automatic', finalSorting: 'a manual sorting' }),
                sortingChange: true
            }));
        }
    };


    /**
     * This is the function reused to call backend and persist new products order.
     */
    const persistSortingChanges = () => {
        let products = [];
        // Creating a single array of products guid merging different chunks.
        productsGalleryState.forEach(chunk => {
            products = _.concat(products, chunk.map(product => {
                return product.product_guid;
            }));
        });
        props.setNewProductsOrder(props.galleryInfo.gallery.id, products);
    };


    /**
     * This function is used to save sorting changes applied to products gallery. If sorting change a confirmation
     * modal will be opened.
     */
    const saveChanges = () => {
        // Check if order type has been override. (From manual to automatic or viceversa)
        if (warningSwitchingOrderModalState.sortingChange) {
            setWarningSwitchingOrderModalState(prevState => ({
                ...prevState,
                open: true
            }));
        } else {
            persistSortingChanges();
        }
    };


    /**
     * This is the function call on submit of warning modal.
     */
    const confirmChangesModalHandler = () => {
        persistSortingChanges();
    };


    /**
     * This effect is used to handle the response of setting the new products order.
     */
    useEffect(() => {
        if (props.sortingAction.action === PRODUCT_SHOWROOM_ACTION_REQUEST.postProductOrder) {
            if (props.sortingAction.loading) {
                setBackdrop((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.sortingAction.error) {
                    setSnackbar({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.update.products.gallery',
                            defaultMessage: 'Cannot update products sort.'
                        }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.updateProductsOrder,
                        type: SNACKBAR_LEVEL.error,
                        showDuration: 5000
                    });
                } else {
                    setSnackbar({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.success.update.products.gallery',
                            defaultMessage: 'Products order updated successfully.'
                        }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.updateProductsOrder,
                        type: SNACKBAR_LEVEL.success,
                        showDuration: 5000
                    });

                    if (warningSwitchingOrderModalState.open) setWarningSwitchingOrderModalState(prevState => ({
                        ...prevState,
                        open: false,
                        title: intl.formatMessage({ id: 'common.warning', defaultMessage: 'Warning!!!' }),
                        message: '',
                        sortingChange: false
                    }));
                    setChangesState(false);
                    props.getProductGallery(props.galleryInfo.gallery.id);
                }


                setBackdrop((prevState => ({
                    ...prevState,
                    loading: false
                })));
            }
        }
    }, [props.sortingAction]);

    /**
     * This effect is used to handle the response of export to lookcast.
     */
    useEffect(() => {
        if (props.exportInfo.action === ACTION_REQUEST.post) {
            if (props.exportInfo.loading) {
                setBackdrop((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.exportInfo.error) {
                    setSnackbar({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.export.lookcast',
                            defaultMessage: 'Cannot export product gallery {galleryName} to Lookcast.'
                        }, { galleryName: props.galleryInfo.gallery.name }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.exportProductGallery,
                        type: SNACKBAR_LEVEL.error,
                        showDuration: 5000
                    });
                    setBackdrop((prevState => ({
                        ...prevState,
                        loading: false
                    })));
                } else {
                    props.history.push('/showroom-product');
                }
            }
        }
    }, [props.exportInfo]);

    /**
     * This function is used to remove single product from managing gallery tab. If there are some changes order not
     * save a warning modal will be open.
     * @param event
     * @param productGuid {string}: unique product identifier.
     */
    const removeProductFromGalleryHandler = (event, productGuid) => {
        if (props.productsInfo.action === ACTION_REQUEST.put) {
            props.cleanProducts();
        }

        if (changesState) {
            setWarningChangesNotSaveModalState(prevState => ({
                ...prevState,
                open: true,
                removingProductGuid: productGuid
            }));
        } else {
            props.saveProducts(props.galleryInfo.gallery.id, [{ product_guid: productGuid, selected: false }]);
        }
    };

    /**
     * This is the function when user confirm modal warning: delete product and close modal.
     */
    const confirmDeleteWithoutSavingHandler = () => {
        props.saveProducts(props.galleryInfo.gallery.id, [{
            product_guid: warningChangesNotSaveModalState.removingProductGuid,
            selected: false
        }]);

        setWarningChangesNotSaveModalState(prevState => ({
            ...prevState,
            open: false,
            removingProductGuid: null
        }))
    }


    return (
        <div className={classes.container}>
            {
                productsGalleryState &&
                <div className={classes.content}>
                    <DndProductGalleryShowroomDetailElement chunkList={productsGalleryState}
                                                            getProductDetails={getProductDetails}
                                                            deleteProduct={removeProductFromGalleryHandler}
                                                            onDragEnd={onDragEndHandler}
                                                            productsPerRow={productsPerRow}
                                                            genericStyle={genericStyle}/>
                </div>
            }
            <Grid container
                  direction={"row"}
                  justify={"flex-end"}
                  alignItems={"center"}
                  className={classes.footerGrid}>
                <Grid item xs={2}>
                    {
                        productsGalleryState.length > 0 &&
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "showroom_product.save.changes",
                                defaultMessage: "Save Changes",
                            })}
                            onSubmit={() => saveChanges()}
                            disabled={!changesState}/>
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        <ButtonElement
                            label={intl.formatMessage({
                                id: "showroom_product.key.button.label.export.lookcast",
                                defaultMessage: "Export Lookcast",
                            })}
                            onSubmit={() => props.exportToLookcast(props.galleryInfo.gallery.id)}
                            disabled={changesState}/>
                    }
                </Grid>
            </Grid>
            {
                warningSwitchingOrderModalState.open &&
                <ReusableModal reusableModal={warningSwitchingOrderModalState}
                               greenButtonClick={() => confirmChangesModalHandler()}
                               greenButtonText={intl.formatMessage({ id: 'common.confirm', defaultMessage: 'Confirm' })}
                               closeReusableModal={() => setWarningSwitchingOrderModalState((prevState => ({
                                   ...prevState,
                                   open: false
                               })))}>
                    <Typography variant={"body1"}>
                        {warningSwitchingOrderModalState.message}
                    </Typography>
                </ReusableModal>
            }
            {
                warningChangesNotSaveModalState.open &&
                <ReusableModal reusableModal={warningChangesNotSaveModalState}
                               greenButtonClick={() => confirmDeleteWithoutSavingHandler()}
                               greenButtonText={intl.formatMessage({ id: 'common.confirm', defaultMessage: 'Confirm' })}
                               closeReusableModal={() => setWarningChangesNotSaveModalState((prevState => ({
                                   ...prevState,
                                   open: false,
                                   removingProductGuid: null
                               })))}>
                    <Typography variant={"body1"}>
                        {warningChangesNotSaveModalState.message}
                    </Typography>
                </ReusableModal>
            }
        </div>
    )
};


const mapStateToProps = state => ({
    galleryInfo: state.galleryProductShowroomReducer,
    sortingAction: state.galleryActionsProductShowroomReducer,
    exportInfo: state.lookcastExportProductShowroomReducer,
    productsInfo: state.productsGalleryProductShowroomReducer
});

const mapDispatchToProps = dispatch => ({
    saveProducts: (galleryId, productsList) => dispatch(startPutProducts(galleryId, productsList)),
    setNewProductsOrder: (galleryId, productGuidList) => dispatch(startPostProductsShowroomGalleryOrder(galleryId, productGuidList)),
    getProductGallery: (galleryId) => dispatch(startGetProductsShowroomGallery(galleryId)),
    exportToLookcast: (galleryId) => dispatch(startProductGalleryLookcastExport(galleryId)),
    cleanProducts: () => dispatch(clearProducts())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ManageGalleryTabContainer));
import {CLEAR_DOCS, GET_DOCS_FAILURE, GET_DOCS_REQUEST, GET_DOCS_SUCCESS,
        GET_PRODUCTS_REPORTS_REQUEST, GET_PRODUCTS_REPORTS_SUCCESS, GET_PRODUCTS_REPORTS_FAILURE, CLEAR_PRODUCTS_REPORTS,
        GET_ASSETS_REPORTS_REQUEST, GET_ASSETS_REPORTS_SUCCESS, GET_ASSETS_REPORTS_FAILURE, CLEAR_ASSETS_REPORTS,
        GET_VIDEOS_REQUEST, GET_VIDEOS_SUCCESS, GET_VIDEOS_FAILURE, CLEAR_VIDEOS,
        GET_DOCS_PER_ROLE_REQUEST, GET_DOCS_PER_ROLE_SUCCESS, GET_DOCS_PER_ROLE_FAILURE, CLEAR_DOCS_PER_ROLE
} from "../actions/documentation";
import {DOCUMENTATION_RESOURCE_TYPE} from "../../utils/constant";

const initialState = { availableDocs: null, resourceType: DOCUMENTATION_RESOURCE_TYPE.docs, downloadableDocs: null, error: null, loading: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCS_PER_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ASSETS_REPORTS_REQUEST:
            return {
                ...state,
                resourceType: DOCUMENTATION_RESOURCE_TYPE.assets_reports,
                loading: true
            };
        case GET_PRODUCTS_REPORTS_REQUEST:
            return {
                ...state,
                resourceType: DOCUMENTATION_RESOURCE_TYPE.products_reports,
                loading: true
            };
        case GET_VIDEOS_REQUEST:
            return {
                ...state,
                resourceType: DOCUMENTATION_RESOURCE_TYPE.videos,
                loading: true
            };
        case GET_DOCS_REQUEST:
            return {
                ...state,
                resourceType: DOCUMENTATION_RESOURCE_TYPE.docs,
                loading: true
            };
        case GET_DOCS_PER_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                availableDocs: action.availableDocs["available_docs_per_role"]
            }
        case GET_ASSETS_REPORTS_SUCCESS:
        case GET_PRODUCTS_REPORTS_SUCCESS:
        case GET_VIDEOS_SUCCESS:
        case GET_DOCS_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadableDocs: action.downloadableDocs,
            };
        case GET_DOCS_PER_ROLE_FAILURE:
        case GET_ASSETS_REPORTS_FAILURE:
        case GET_PRODUCTS_REPORTS_FAILURE:
        case GET_VIDEOS_FAILURE:
        case GET_DOCS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_DOCS_PER_ROLE:
            return initialState;
        case CLEAR_ASSETS_REPORTS:
        case CLEAR_PRODUCTS_REPORTS:
        case CLEAR_VIDEOS:
        case CLEAR_DOCS:
            return {
                ...state,
                resourceType: null,
                downloadableDocs: null,
                error: null,
                loading: null
            };
        default:
            return state;
    }
};
import MaterialTable from "material-table";
import React from "react";
import {useStyles} from "./MaterialSimpleTableStyle";

const MaterialSimpleTable = (props) => {
	const classes = useStyles();
	const { data, title, columns, onRowClick, actions, components, loading } = props;
	//console.log("PROPS ", data, columns);

	return (
		<div className={classes.root}>
			<MaterialTable
				title={title ? title : ""}
				columns={columns}
				data={data}
				onRowClick={onRowClick}
				isLoading={loading}
				options={{
					search: true,
					paging: true,
					sorting: true,
					pageSize: 20,
					emptyRowsWhenPaging: false
				}}
				localization={{
					header: {
						actions: "",
					},
				}}
				paging={true}
				actions={actions} // must be an array of objects
				components={components}
			/>
		</div>
	);
};

export default MaterialSimpleTable;

import React from "react";
import {useStyles} from "./DndProductGalleryShowroomDetailElementStyle";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import IconButtonElement from "../../IconButtonElement/IconButtonElement";
import {getProductImageOpacity, getProductShowroomImageBorderColor, onErrorSrc} from "../../../utils/CommonFunction";
import {Typography} from "@material-ui/core";
import PreserveImageRatio from "../../PreserveImageRatio/PreserveImageRatio";

const DndProductGalleryShowroomDetailElement = props => {
    const { genericStyle, chunkList, getProductDetails, deleteProduct, onDragEnd, productsPerRow } = props;
    const classes = useStyles();

    const justify = productsPerRow === 4 ? 'center' : 'flex-start';

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {
                chunkList.map((productsPerRow, rowIndex) => (
                    <Droppable droppableId={rowIndex.toString()}
                               type={'productsGalleryProduct'}
                               direction={'horizontal'}
                                   key={rowIndex}>
                            {(provided) => (
                                <Grid container
                                      direction={"row"}
                                      justify={justify}
                                      alignItems="center"
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}>
                                    {
                                        productsPerRow.map((product, assetIndex) => (
                                            <Draggable draggableId={product.product_guid}
                                                       index={assetIndex}
                                                       key={product.product_guid}>
                                                {
                                                    (provided) => (
                                                        <Grid item
                                                              ref={provided.innerRef}
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              className={classes.imgContainer}>
                                                            <div className={classes.item}>
                                                                <div className={classes.detailIcon}>
                                                                    <IconButtonElement
                                                                        fontSize="small"
                                                                        icon={"search_icon"}
                                                                        onClick={(event) => getProductDetails(event, product.product_guid)}
                                                                    />
                                                                </div>
                                                                <div className={classes.deleteIcon}>
                                                                    <IconButtonElement
                                                                        fontSize="small"
                                                                        labelId={'showroom.remove.image.gallery'}
                                                                        label={'Remove image from gallery.'}
                                                                        icon={"delete"}
                                                                        onClick={(event) => deleteProduct(event, product.product_guid)}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={getProductShowroomImageBorderColor(genericStyle, product.status)}/>
                                                                <PreserveImageRatio src={product.img_url}
                                                                     alt={"Image not found"}
                                                                     height={280}
                                                                     width={200}
                                                                     onError={(event) => onErrorSrc(event)}
                                                                     className={getProductImageOpacity(genericStyle, product.status)}/>
                                                                <div className={classes.productLabel}>
                                                                    <Typography
                                                                        variant={'body2'}>{product.style_fabric_color}</Typography>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    )
                                                }
                                            </Draggable>)
                                        )
                                    }
                                </Grid>
                            )}
                        </Droppable>
                    ))
                }
            </DragDropContext>
        );
    }
;

export default DndProductGalleryShowroomDetailElement;
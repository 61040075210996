import {GET_SHOOT_TYPE_FAILURE, GET_SHOOT_TYPE_REQUEST, GET_SHOOT_TYPE_SUCCESS} from "../../actions/search/shoot_type";

const initialState = {shoot_type: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SHOOT_TYPE_REQUEST:
            return {
                loadingShootType: true
            };
        case GET_SHOOT_TYPE_SUCCESS:
            return {
                ...state,
                loadingShootType: false,
                shoot_type: action.shoot_type,
            };
        case GET_SHOOT_TYPE_FAILURE:
            return {
                ...state,
                loadingShootType: false,
                error: action.error
            }
        default:
            return state;
    }
}
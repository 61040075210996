import {GET_DIMENSION_FAILURE, GET_DIMENSION_REQUEST, GET_DIMENSION_SUCCESS} from "../../actions/search/dimension";

const initialState = {dimension: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DIMENSION_REQUEST:
            return {
                loadingDimension: true
            };
        case GET_DIMENSION_SUCCESS:
            return {
                ...state,
                loadingDimension: false,
                dimension: action.dimension,
            };
        case GET_DIMENSION_FAILURE:
            return {
                ...state,
                loadingDimension: false,
                error: action.error
            }
        default:
            return state;
    }
}
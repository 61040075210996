export const users_columns = [
    {
        title: {id: "admin.users.first_name", default: "First Name"},
        field: 'first_name'
    },
    {
        title: {id: "admin.users.last_name", default: "Last Name"},
        field: 'last_name'
    },
    {
        title: {id: "admin.users.nickname", default: "Nickname"},
        field: 'nickname'
    },
    {
        title: {id: "admin.users.email", default: "Email"},
        field: 'email'
    },
    {
        title: {id: "admin.users.telephone", default: "Telephone"},
        field: 'telephone'
    },
    {
        title: {id: "admin.users.expiration_date", default: "Expiration Date"},
        field: 'expiration_date'
    },
    {
        title: {id: "admin.users.language", default: "Language"},
        field: 'language'
    },
    {
        title: {id: "admin.users.roles", default: "Roles"},
        field: 'roles_to_string'
    },
    {
        title: {id: "admin.users.notes", default: "Notes"},
        field: 'notes'
    },

];
export const photolab_columns = [
    {
        title: {id: "search.keys.name", default: "Name"},
        field: 'name'
    },
    {
        title: {id: "admin.photolab.macaddress", default: "MAC Address"},
        field: 'MAC'
    },
    {
        title: {id: "admin.photolab.source_folder", default: "Source Folder"},
        field: 'listened_folder'
    },
    {
        title: {id: "admin.photolab.backup_folder", default: "Backup Folder"},
        field: 'backup_folder'
    }
];

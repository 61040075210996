import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    drawer: {
        width: 450,
        flexShrink: 0,
    },
    drawerBottom: {
        height: 700
    },
    drawerPaper: {
        width: 450,
    },
    drawerPaperBottom: {
        height: 700,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
}));
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useIntl} from "react-intl";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import ButtonElement from "../../components/ButtonElement/ButtonElement";
import {photolab_columns} from "../../assets/resources/admin_photolab_columns";
import InputTextElement from "../../components/InputTextElement/InputTextElement";
import {
	getPhotolabSuccess,
	hidePhotolabSnackBar,
	startDeletePhotolab,
	startDeletePhotoset,
	startGetPhotolabByGuid,
	startGetPhotolabs,
	startPostPhotolab,
	startPostPhotoset,
	startPutPhotoset
} from "../../store/actions/photolab";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import {LeftSideBarUpper} from "../../components/LeftSideBarUpper/LeftSideBarUpper";
import MaterialSimpleTable from "../../components/TableElement/MaterialSimpleTable/MaterialSimpleTable";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import DropDownElement from "../../components/DropDownElement/DropDownElement";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";
import {validateColumnsMaterialSimpleTable} from "../../utils/CommonFunction";

// const useStyles = makeStyles((theme) => ({

// }));

const AdminPhotolabContainer = (props) => {

	const intl = useIntl();
	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [photolabList, setPhotolabList] = useState([]);
	const [loading, setLoading] = useState([]);
	const [showDetailModal, setShowDetailModal] = useState({
		modalRows: {},
		open: false,
		title: intl.formatMessage({
			id: "admin.photoset.modal.title",
			defaultMessage: "Photoset Details",
		})
	});
	const [selectedIndex, setSelectedIndex] = useState();
	const [formModal, setFormModal] = useState({
		inputField: [],
		data: {},
		open: false,
		title: "",
		type: ""
	});

	const [deletePhotosetModal, setDeletePhotosetModal] = useState({
		open: false,
		title: ""
	});

	const [deletePhotolabModal, setDeletePhotolabModal] = useState({
		open: false,
		title: "",
		textId: "admin.photolab.delete.warning",
		text: ""
	});

	const [snackBarState, setSnackBarState] = useState({
		open: false,
		level: "error",
		label: { translateKey: "tmp", defaultText: "" },
	});

	useEffect(() => {
		// setup the columns
		const columns = validateColumnsMaterialSimpleTable(intl, photolab_columns);
		setColumns(columns);
		setLoading(true);
	}, []);

	useEffect(() => {
		setLoading(props.loadingPhotolab);
	}, [props.loadingPhotolab]);

	useEffect(() => {
		console.log("useEffect photolabs", props.photolabs);
		if (props.photolabs !== undefined) {
			const list = [];
			props.photolabs.forEach((photolab) => {
				list.push({
					guid: photolab.guid,
					favourite_name: photolab.name,
					key: photolab.guid,
					label: photolab.name,
					value: photolab.name
				});
			});

			// if photolabs is updated, update the list, accordingly
			setPhotolabList(list);

			// set the first photolab in the list as default view
			setSelectedIndexStatus(list);
			/*if (!selectedIndex && list.length > 0) {
				setSelectedIndex(list[0].key);
				props.getPhotolabSet(list[0].guid);
			} else if (selectedIndex && list.length > 0) {
				setSelectedIndex(selectedIndex);
				props.getPhotolabSet(selectedIndex);
			}*/
		}
	}, [props.photolabs]);

	useEffect(() => {
		console.log("TO UPDATE PHOTOLABS", props.updatePhotolabs)
		if (props.updatePhotolabs) {
			const newSuccess = {
				open: true,
				level: "success",
				label: { translateKey: "admin.users.success", defaultText: "Operation executed with success." },
			}
			setSnackBarState(newSuccess)
			props.getPhotolabs();
		}
		console.log("SNACKBAR", snackBarState)
	}, [props.updatePhotolabs])

	useEffect(() => {
		console.log("TO UPDATE PHOTOSETS", props.updatePhotolabs)

		if (props.updatePhotosets) {
			const newSuccess = {
				open: true,
				level: "success",
				label: { translateKey: "admin.users.success", defaultText: "Operation executed with success." },
			}
			setSnackBarState(newSuccess)
			setSelectedIndexStatus(photolabList);
			/*if (!selectedIndex) {
				setSelectedIndex(photolabList[0].key);
				props.getPhotolabSet(photolabList[0].guid);
			} else {
				setSelectedIndex(selectedIndex);
				props.getPhotolabSet(selectedIndex);
			}*/
		}
		console.log("SNACKBAR", snackBarState)
	}, [props.updatePhotosets])

	useEffect(() => {
		if (props.crudError) {
			const newError = {
				open: true,
				level: "error",
				label: { translateKey: "admin.users.error", defaultText: "Operation not executed." },
			}
			setSnackBarState(newError)
		}
		console.log("SNACKBAR", snackBarState)
	}, [props.crudError])

	useEffect(() => {
		if (props.macaddressError) {
			const newError = {
				open: true,
				level: "error",
				label: {
					translateKey: "admin.photoset.error",
					defaultText: "This MAC address is already in use for another Photo Set."
				},
			}
			setSnackBarState(newError)
		}
		console.log("SNACKBAR", snackBarState)
	}, [props.macaddressError])

	useEffect(() => {
		if (props.photolab_set) {
			console.log("-----------infooo", props.photolab_set)
			setTableData(props.photolab_set);
		}
	}, [props.photolab_set]);

	const getPhotolabGuidByName = (name) => {
		if (photolabList) {
			return photolabList.filter(photolab => photolab.value === name)[0].guid;
		}
	}

	const getPhotolabNameByGuid = () => {
		if (photolabList && selectedIndex) {
			return photolabList.filter(photolab => photolab.guid === selectedIndex)[0].value;
		}
	}

	const onClickRow = (event, row) => {
		console.log("ROW CLICKED", row);
		const newState = { ...showDetailModal };
		newState.open = true;
		newState.modalRows = row;
		setShowDetailModal(newState);
	};

	const openFormModal = (type) => {
		console.log("Open new form modal with type", type);
		const newState = { ...formModal };
		newState.open = true;
		switch (type) {
			case "add_photolab":
				newState.title = intl.formatMessage({
					id: "admin.photolab.modal.add",
					defaultMessage: "Add New Photolab",
				});
				newState.type = "add_photolab";
				newState.dropdown = false;
				newState.inputField = [{
					placeholder: "Photolab Name",
					placeholderId: "search.keys.photolab.name",
					value: null
				}];
				break;
			case "add_photoset":
				newState.type = "add_photoset";
				newState.title = intl.formatMessage({
					id: "admin.photoset.modal.title",
					defaultMessage: "Photo Set Details",
				});
				newState.dropdown = getPhotolabNameByGuid(selectedIndex);
				newState.inputField = [
					{
						name: "photoset_name",
						placeholder: "Photo Set Name",
						placeholderId: "admin.photolab.photoset.name",
						value: null
					},
					{
						name: "mac",
						placeholder: "MAC address",
						placeholderId: "admin.photolab.macaddress",
						value: null
					},
					{
						name: "source_folder",
						placeholder: "Source Folder",
						placeholderId: "admin.photolab.source_folder",
						value: null
					},
					{
						name: "backup_folder",
						placeholder: "Backup Folder",
						placeholderId: "admin.photolab.backup_folder",
						value: null
					}
				];
				break;
			case "edit_photoset":
				newState.type = "edit_photoset";
				newState.title = intl.formatMessage({
					id: "admin.photoset.modal.title",
					defaultMessage: "Photo Set Details",
				});
				newState.dropdown = false;
				newState.inputField = [
					{
						name: "photoset_name",
						placeholder: "Photo Set Name",
						placeholderId: "admin.photolab.photoset.name",
						value: showDetailModal.modalRows.name
					},
					{
						name: "mac",
						placeholder: "MAC address",
						placeholderId: "admin.photolab.macaddress",
						value: showDetailModal.modalRows.MAC
					},
					{
						name: "source_folder",
						placeholder: "Source Folder",
						placeholderId: "admin.photolab.source_folder",
						value: showDetailModal.modalRows.listened_folder
					},
					{
						name: "backup_folder",
						placeholder: "Backup Folder",
						placeholderId: "admin.photolab.backup_folder",
						value: showDetailModal.modalRows.backup_folder
					}
				];
				break;
			default:
				console.warn("Unknown action");
				break;
		}
		console.log(newState);
		setFormModal(newState);
	};

	const onCloseShowModal = () => {
		console.log("Close show modal");
		const newState = { ...showDetailModal };
		newState.open = false;
		newState.modalRows = {};
		setShowDetailModal(newState);
	};

	const onCloseEditModal = (row) => {
		console.log("Close edit modal");
		const newState = { ...formModal };
		newState.open = false;
		newState.title = "";
		newState.data = {};

		setFormModal(newState);
	};

	const photolabOnClickHandler = (guid) => {
		setSelectedIndex(guid);
		props.getPhotolabSet(guid);
	};

	const formChangeHandler = (event, type) => {
		const newState = { ...formModal };
		switch (type) {
			case 'photolab_list':
				console.log("photolab dropdown change", event)
				newState.dropdown = event.target.value;
				// select the respective photolab table
				setSelectedIndex(getPhotolabGuidByName(newState.dropdown));
				break;
			default:
				console.log("text input change", event);
				let index = newState.inputField.findIndex(el => el.name === type)
				newState.inputField[index].value = event.target.value;
				break;
		}
		setFormModal(newState);
	};

	const formSubmit = () => {
		const newState = { ...formModal };
		const required = intl.formatMessage({
			id: "admin.users.required",
			defaultMessage: "Required Field",
		});
		switch (formModal.type) {
			case "add_photolab":
				// checks if the name field is empty
				if (formModal.inputField[0].value) {
					const newPhotolab = formModal.inputField[0].value;
					// look for duplicates
					if (photolabList.filter(photolab => photolab.value === newPhotolab).length === 0) {
						// post new photolab
						props.postPhotolab({ name: newPhotolab })
						// close modal
						newState.data.photolab_error = null;
						newState.open = false;
					} else {
						newState.data.photolab_error = intl.formatMessage({
							id: "admin.photolab.duplicate",
							defaultMessage: "A Photolab with this name already exists.",
						});
					}

				} else {
					newState.data.photolab_error = required;
				}
				setFormModal(newState);
				break;
			case "add_photoset":
				// checks required fields (name and photolab name)
				if (formModal.inputField[0].value && formModal.dropdown) {
					// get photolab guid
					const guid = getPhotolabGuidByName(formModal.dropdown);
					// post new photoset
					const newPhotoset = {
						name: formModal.inputField[0].value, // required
						MAC: formModal.inputField[1].value ? formModal.inputField[1].value : null,
						backup_folder: formModal.inputField[3].value ? formModal.inputField[3].value : null,
						listened_folder: formModal.inputField[2].value ? formModal.inputField[2].value : null,
						photolab_guid: guid
					}
					props.postPhotoset(newPhotoset);
					// close modal
					newState.data.photolab_error = null;
					newState.open = false;
				} else {
					newState.data.photolab_error = required;
				}

				setFormModal(newState);
				break;
			case "edit_photoset":
				// checks required fields (name)
				if (formModal.inputField[0].value) {
					// send edited photoset
					const newPhotoset = {
						name: formModal.inputField[0].value, // required
						MAC: formModal.inputField[1].value ? formModal.inputField[1].value : null,
						backup_folder: formModal.inputField[3].value ? formModal.inputField[3].value : null,
						listened_folder: formModal.inputField[2].value ? formModal.inputField[2].value : null,
						photolab_guid: showDetailModal.modalRows.photolab_guid ? showDetailModal.modalRows.photolab_guid : null,
						active: showDetailModal.modalRows.active ? showDetailModal.modalRows.active : null
					}
					props.editPhotoset(showDetailModal.modalRows.guid, newPhotoset);
					// close modals
					newState.open = false;
					newState.data.photolab_error = null;
					setShowDetailModal({ ...showDetailModal, open: false });
				} else {
					newState.data.photolab_error = required;
				}
				setFormModal(newState);
				break;
			default:
				break;
		}
	};

	const deletePhotoset = () => {
		const setId = showDetailModal.modalRows.guid;
		props.deletePhotoset(setId)
		// close modals
		setDeletePhotosetModal({ ...deletePhotosetModal, open: false });
		setShowDetailModal({ ...showDetailModal, open: false })
	}

	const openDeletePhotolabModal = () => {
		const newState = { ...deletePhotolabModal };
		// if the photolab contains sets can't be deleted
		if (tableData && tableData.length !== 0) {
			newState.textId = "admin.photolab.delete.warning";
			newState.text = "You can't delete this PhotoLab.";
			newState.deletable = false;
			newState.open = true;
		} else {
			newState.textId = "admin.photolab.delete.confirm";
			newState.text = "Are you sure you want to delete this PhotoLab?";
			newState.deletable = true;
			newState.open = true;
		}
		setDeletePhotolabModal(newState);
	}

	const deletePhotolab = () => {
		props.deletePhotolab(selectedIndex);
		setSelectedIndexStatus(photolabList);
		setDeletePhotolabModal({ ...deletePhotolabModal, open: false });
	};

	const setSelectedIndexStatus = (list) => {
		if ((!selectedIndex || _.find(list, selectedIndex) === undefined) && list.length > 0) {
			setSelectedIndex(list[0].key);
			props.getPhotolabSet(list[0].guid);
		} else if (!selectedIndex && list.length > 0) {
			props.getPhotolabSet(selectedIndex);
		}
	};


	return (
		<div>
			<BackdropElement loading={props.loadingPhotolab || false}/>
			<Grid item xs={12}>
				<SnackbarElement
					open={snackBarState.open}
					type={snackBarState.level}
					label={snackBarState.label.defaultText}
					labelId={snackBarState.label.translateKey}
					handleClose={() => {
						setSnackBarState({
							...snackBarState,
							open: false,
						})
						props.hideSnackBar()
					}
					}
				/>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={3}>
					<LeftSideBarUpper
						groupElements={[
							{
								type: "linkBox",
								title: {
									translateKey: "product.photolab",
									defaultText: "Photolab",
								},
								icon: "camera",
								selectedIndex: selectedIndex,
								loading: loading,
								links: photolabList,
								// loading: props.loadingFavourites,
								onClickHandler: photolabOnClickHandler,
							},
						]}
					/>
				</Grid>
				<Grid item container xs={9}>
					<Grid item container justify="flex-end" xs={12}>
						<ButtonElement
							labelId={"admin.photolab.delete"}
							label={"Delete Photolab"}
							cssType={"deactive"}
							onSubmit={openDeletePhotolabModal}/>
						<ButtonElement
							labelId={"admin.photolab.add"}
							label={"Add Photolab"}
							cssType={"edit"}
							onSubmit={() => openFormModal("add_photolab")}/>
					</Grid>
					<Grid item xs={12}>
						<MaterialSimpleTable
							title={getPhotolabNameByGuid()}
							data={tableData}
							columns={columns}
							onRowClick={onClickRow}
							loading={props.loadingPhotolabSet}
							components={{
								Action: (tableProps) => {
									return (
										<ButtonElement
											label={intl.formatMessage({
												id: "admin.photolab.add.photoset",
												defaultMessage: "Add New Photo Set",
											})}
											onSubmit={() => openFormModal("add_photoset")}
										/>
									);
								},
							}}
							actions={[
								{
									icon: "add",
									tooltip: "Add new user",
									onClick: () => {
										// open new user modal
									},
									isFreeAction: true,
								},
							]}
							pageSize={10}
						/>
					</Grid>
				</Grid>

				{/* view detail modal */}
				<ReusableModal
					reusableModal={showDetailModal}
					greenButtonClick={() => openFormModal("edit_photoset")}
					greenButtonText={intl.formatMessage({ id: "common.button.label.edit", defaultMessage: "Edit" })}
					redButtonClick={() => {
						setDeletePhotosetModal({
							...deletePhotosetModal,
							open: true,
						});
					}}
					redButtonText={intl.formatMessage({ id: "common.button.label.delete", defaultMessage: "Delete" })}
					closeReusableModal={onCloseShowModal}
				>
					<Grid container
						  direction="row" spacing={1}>
						{
							columns.map(column =>
								<Grid container item key={column.title} justify={'center'} xs={12} spacing={2}>
									<Grid item xs={3}>
										{column.title}
									</Grid>
									<Grid item xs={5}>
										{showDetailModal.modalRows[column.field]}
									</Grid>
								</Grid>
							)
						}
					</Grid>
				</ReusableModal>

				{/* edit/create modal  */}
				<ReusableModal
					reusableModal={formModal}
					submitClick={formSubmit}
					closeReusableModal={onCloseEditModal}
				>
					<Grid container direction="row" justify="center" spacing={1}>
						{formModal.dropdown &&
						<Grid item xs={7}>
							<DropDownElement
								fullWidth={true}
								value={formModal.dropdown || ""}
								upLabel={{
									id: "product.photolab",
									defaultMessage: "Photolab",
								}}
								onChangeHandler={(data) => formChangeHandler(data, "photolab_list")}
								options={photolabList}/>
						</Grid>}
						{formModal.inputField.map((field, key) => {
							return (
								<Grid item xs={6} key={key}>
									<InputTextElement
										fullWidth={true}
										value={field.value || ""}
										error={(formModal.data.photolab_error && key === 0) || false}
										helperText={formModal.data.photolab_error && key === 0 ? formModal.data.photolab_error : null}
										label={field.placeholder}
										labelId={field.placeholderId} //TODO translations
										handleChange={(event) => formChangeHandler(event, field.name)}
										autoFocus={true}
									/>
								</Grid>
							);
						})}
					</Grid>
				</ReusableModal>

				{/* delete photoset modal */}
				<ReusableModal
					reusableModal={deletePhotosetModal}
					greenButtonClick={deletePhotoset}
					greenButtonText={intl.formatMessage({ id: "common.confirm", defaultMessage: "Confirm" })}
					closeReusableModal={() => setDeletePhotosetModal({ ...deletePhotosetModal, open: false })}>
					{intl.formatMessage({
						id: "admin.photoset.confirm",
						defaultMessage: "Are you sure you want to delete this photo set?"
					})}
				</ReusableModal>

				{/* delete photolab modal */}
				<ReusableModal
					reusableModal={deletePhotolabModal}
					greenButtonClick={deletePhotolabModal.deletable ? deletePhotolab : null}
					greenButtonText={intl.formatMessage({ id: "common.confirm", defaultMessage: "Confirm" })}
					closeReusableModal={() => setDeletePhotolabModal({ ...deletePhotolabModal, open: false })}>
					{intl.formatMessage({ id: deletePhotolabModal.textId, defaultMessage: deletePhotolabModal.text })}
				</ReusableModal>
			</Grid>
		</div>
	);
};

const mapStateToProps = (state) => ({
	photolabs: state.photolabReducer.photolab,
	photolab_set: state.photolabReducer.photolab_set,
	loadingPhotolab: state.photolabReducer.loadingPhotolab,
	loadingPhotolabSet: state.photolabReducer.loadingPhotolabSet,
	updatePhotolabs: state.photolabReducer.updatePhotolabs,
	updatePhotosets: state.photolabReducer.updatePhotosets,
	crudError: state.photolabReducer.error,
	macaddressError: state.photolabReducer.macaddressError
});

const mapDispatchToProps = (dispatch) => {
	return {
		getPhotolabSet: (guid) => dispatch(startGetPhotolabByGuid(guid)),
		postPhotolab: (newPhotolab) => dispatch(startPostPhotolab(newPhotolab)),
		deletePhotolab: (guid) => dispatch(startDeletePhotolab(guid)),
		getNewPhotolab: () => dispatch(getPhotolabSuccess()),
		getPhotolabs: () => dispatch(startGetPhotolabs()),
		postPhotoset: (newPhotoset) => dispatch(startPostPhotoset(newPhotoset)),
		editPhotoset: (guid, photoset) => dispatch(startPutPhotoset(guid, photoset)),
		deletePhotoset: (guid) => dispatch(startDeletePhotoset(guid)),
		hideSnackBar: () => dispatch(hidePhotolabSnackBar())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AdminPhotolabContainer));

import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_PIM_TARGET_REQUEST = 'GET_PIM_TARGET_REQUEST';
export const GET_PIM_TARGET_SUCCESS = 'GET_PIM_TARGET_SUCCESS';
export const GET_PIM_TARGET_FAILURE = 'GET_PIM_TARGET_FAILURE';

const localStorageService = LocalStorage.getService();

export const getPimTargetRequest = () => {
    return {type: GET_PIM_TARGET_REQUEST}
};

export const getPimTargetSuccess = (pimTarget = {}) => {
    return {type: GET_PIM_TARGET_SUCCESS, pimTarget}
};

export const getPimTargetFailure = (error) => {
    return {type: GET_PIM_TARGET_FAILURE, error}
};

export const startGetPimTarget = () => {
    console.log('Start startGetPimTarget');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getPimTargetRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/pim_target/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response pim target search ", response.data);
                    dispatch(getPimTargetSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getPimTargetFailure(error));
            });
    };
};

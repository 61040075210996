import {
    GET_SEASONALITY_SUCCESS,
    GET_SEASONALITY_REQUEST,
    GET_SEASONALITY_FAILURE
} from "../../actions/search/seasonality";

const initialState = {seasonality: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SEASONALITY_REQUEST:
            return {
                loadingSeasonality: true
            };
        case GET_SEASONALITY_SUCCESS:
            return {
                ...state,
                loadingSeasonality: false,
                seasonality: action.seasonality,
            };
        case GET_SEASONALITY_FAILURE:
            return {
                ...state,
                loadingSeasonality: false,
                error: action.error
            }
        default:
            return state;
    }
}
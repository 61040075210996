import {
    CLEAR_PRODUCTS,
    PUT_PRODUCTS_FAILED,
    PUT_PRODUCTS_REQUEST,
    PUT_PRODUCTS_SUCCESS
} from "../../../actions/showroom/product/products";

const initialState = {
    loading: false,
    action: null,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_PRODUCTS:
            return initialState;
        case PUT_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
                action: 'PUT'
            };
        case PUT_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case PUT_PRODUCTS_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
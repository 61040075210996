import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        maxHeight: "66vh",
        backgroundColor: theme.palette.background.main
    },

    headerCell: {
        backgroundColor: theme.palette.background.main,
        color: theme.palette.text.main,
        position: "sticky",
        top: 0,
        zIndex: 10,
        borderBottom: '2px solid '.concat(theme.palette.background.main),
        fontSize: '1.025rem',
        fontWeight: '500',
        textTransform: 'uppercase',
        padding: theme.spacing(2)
    }
}));
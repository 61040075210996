import React from "react";
import ReusableModal from "../../Modal/ReusableModal/ReusableModal";
import {Grid} from "@material-ui/core";
import InputTextElement from "../../InputTextElement/InputTextElement";
import SwitchElement from "../../SwitchElement/SwitchElement";

const SeasonForm = props => {
    const { reusableModal, okReusableModal, closeReusableModal, handleOnChange, season } = props;

    return (
        <ReusableModal
            reusableModal={reusableModal}
            submitClick={okReusableModal}
            closeReusableModal={closeReusableModal}>
            <Grid container
                  direction="row"
                  justify="center"
                  spacing={3}>
                <Grid item xs={4}>
                    <InputTextElement
                        disabled={!season.isNew}
                        fullWidth={true}
                        name={"code"}
                        value={season.code || ""}
                        placeholder={"Code"}
                        placeholderId={"admin.season.code"}
                        label={"Code"}
                        labelId={"admin.season.code"}
                        handleChange={handleOnChange}
                        autoFocus={true}/>
                </Grid>
                <Grid item xs={4}>
                    <InputTextElement
                        fullWidth={true}
                        name={"description"}
                        value={season.name || ""}
                        placeholder={"Description"}
                        placeholderId={"admin.season.description"}
                        label={"Description"}
                        labelId={"admin.season.description"}
                        handleChange={handleOnChange}/>
                </Grid>
                <Grid item xs={4}>
                    <InputTextElement
                        fullWidth={true}
                        name={"external_code"}
                        value={season.external_code || ""}
                        placeholder={"External Code"}
                        placeholderId={"admin.season.external_code"}
                        label={"External Code"}
                        labelId={"admin.season.external_code"}
                        handleChange={handleOnChange}/>
                </Grid>
                {!season.isNew &&
                <Grid item xs={4}>
                    <SwitchElement
                        name={"status"}
                        label={{ translateKey: 'admin.season.status', defaultText: 'Status:', position: 'start' }}
                        onChange={handleOnChange}
                        checked={!!season.active || false}
                        // checkbox={{
                        //     name: 'status',
                        //     size: 'medium',
                        //     checked: season.active,
                        //     icon: 'check_box_outline_blank_icon',
                        //     checkedIcon: 'check_box_icon'
                        // }}
                    />
                </Grid>
                }
            </Grid>
        </ReusableModal>
    )
}

export default SeasonForm;
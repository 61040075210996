import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({

	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1, 0)
		},
		"& .MuiSvgIcon-root": {
			fill: theme.palette.text.placeholder,
		},
		"& .MuiInputBase-input": {
			color: theme.palette.text.main,
			"&.Mui-focused fieldset": {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&.Mui-focused": {

				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
		},
		"& .MuiInputLabel-shrink": {
			display: "none",
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-disabled fieldset": {
				borderColor: "transparent",
				boxShadow: "none !important",
			},
			"& fieldset": {
				borderColor: "transparent",
			},
			'&.Mui-focused fieldset': {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			'&:hover fieldset': {
				borderColor: "transparent",
				boxShadow: "0 0 5px ".concat(theme.palette.focus.main),
			},
			"&.Mui-error fieldset": {
				borderColor: theme.palette.error.main,
				boxShadow: "0 0 5px ".concat(theme.palette.error.main),
			},
		},
	},
	select: {
		backgroundColor: theme.palette.background.main,
		borderRadius: theme.spacing(0.5),
		color: theme.palette.text.main
	},
	cssLabel: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: theme.palette.text.placeholder,
		fontWeight: 500,
		position: "relative",
		top: "-3px",
	},
	input: {
		color: theme.palette.text.main,
	},
	icon: {
		marginRight: theme.spacing(1),
	},
	loader: {
		color: theme.palette.text.main,
	},
	paperMenu: {
		maxHeight: 300,
		backgroundColor: theme.palette.headerTable.main,
		"& ul": {
			backgroundColor: theme.palette.headerTable.main,
		},
		"& li": {
			fontSize: "1rem",
			color: theme.palette.text.main,
		},
	},
	menuItem: {
		color: theme.palette.text.main,
		display: "flex !important",
		// width: '100%',
		"&:hover": {
			backgroundColor: theme.palette.background.hover,
		},
		"& selected": {
			backgroundColor: theme.palette.background.hover,
		},
	},
	upLabel: {
		fontSize: "small",
		textAlign: "left",
		marginTop: theme.spacing(1)
	}
}));

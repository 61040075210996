import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {useStyles} from "./RadioButtonGroupStyle";

const RadioButtonGroup = props => {
    const {formLabel, groupName, radioButtons, value, handleChange} = props;
    const classes = useStyles();

    const radioButtonElements = radioButtons.map(radio => {
        return (<FormControlLabel key={radio.value}
                                  value={radio.value}
                                  control={<Radio/>}
                                  label={radio.label}
        />)
    });

    return (
        <FormControl component="fieldset">
            {formLabel ? <FormLabel component="legend">{formLabel}</FormLabel> : null}
            <RadioGroup aria-label={groupName}
                        name={groupName}
                        value={value}
                        onChange={handleChange}
                        className={classes.radio}>
                {radioButtonElements}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonGroup;
import {
    GET_GAO_DESCRIPTION_FAILURE,
    GET_GAO_DESCRIPTION_REQUEST,
    GET_GAO_DESCRIPTION_SUCCESS
} from "../../actions/search/gao_description";

const initialState = {gao_description: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GAO_DESCRIPTION_REQUEST:
            return {
                loadingGaoDescription: true
            };
        case GET_GAO_DESCRIPTION_SUCCESS:
            return {
                ...state,
                loadingGaoDescription: false,
                gao_description: action.gao_description,
            };
        case GET_GAO_DESCRIPTION_FAILURE:
            return {
                ...state,
                loadingGaoDescription: false,
                error: action.error
            }
        default:
            return state;
    }
}
export const home_pages = [
    {
        titleId: "home.page.load.master.data",
        title: "Load Master data",
        link: "/master-data",
        icon: "cloud_upload",
        applicationTag: "upload_master_data"
    },
    {
        titleId: "home.page.search",
        title: "Search Product",
        link: "/search",
        icon: "search",
        applicationTag: "search"
    },
    {
        titleId: "home.page.view",
        title: "View Monitor",
        link: "/view-monitor",
        icon: "view_carousel",
        applicationTag: "view_management"
    },
    {
        titleId: "home.page.upload",
        title: "Upload Digital Assets",
        link: "/upload-digital-assets",
        icon: "publish",
        applicationTag: "upload_digital_assets"
    },
    {
        titleId: "home.page.sessioni.orfane",
        title: "Sessioni orfane",
        link: "/sessioni-orfane",
        icon: "assignment_turned_in",
        applicationTag: "sessioni_orfane"
    },
    {
        titleId: "home.page.showroom.composition",
        title: "Composition Showroom",
        link: "/showroom-composition",
        icon: "photo_album",
        applicationTag: "showroom-composition"
    },
    {
        titleId: "home.page.showroom.product",
        title: "Product Showroom",
        link: "/showroom-product",
        icon: "photo_album",
        applicationTag: "showroom-product"
    },
    {
        titleId: "home.page.documentation",
        title: "Documentation",
        link: "/documentation",
        icon: "description",
        applicationTag: "documentation"
    },
    {
        titleId: "home.page.shooting", //id della traduzione
        title: "phARM Shooting", //default della traduzione
        link: "/shooting", //path della pagina
        icon: "camera", //path dell'icona,
        applicationTag: "shooting"
    },
    {
        titleId: "home.page.admin",
        title: "Admin Page",
        link: "/admin",
        icon: "tune",
        applicationTag: "admin"
    }
    // {
    //     titleId: "home.page.tracking",
    //     title: "Material Tracking",
    //     link: "/tracking",
    //     icon: "account_tree",
    //     applicationTag: "tracking"
    // },
    // {
    //     titleId: "home.page.reassignment",
    //     title: "View Reassignment",
    //     link: "/reassignment",
    //     icon: "loop",
    //     applicationTag: "view_reassignment"
    // },
];
import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import CarouselElement from "../components/CarouselElement/CarouselElement";
import _ from "lodash";
import InputTextElement from "../components/InputTextElement/InputTextElement";
import DropDownElement from "../components/DropDownElement/DropDownElement";
import ChipElement from "../components/ChipElement/ChipElement";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import {connect} from "react-redux";
import {clearPostProduction, startSendEmail} from "../store/actions/postProduction/postProduction";
import ModalFullScreen from "../components/Modal/FullScreen/ModalFullScreen";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import {startGetPostProducers} from "../store/actions/postProduction/postProducer";
import {onSecondErrorSrc} from "../utils/CommonFunction";
import IconButtonElement from "../components/IconButtonElement/IconButtonElement";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";
import {
    ACTION_REQUEST,
    CAROUSEL_ITEMS_PER_PAGE,
    POST_PRODUCTION_ACTIONS_REQUEST,
    SNACKBAR_LEVEL
} from "../utils/constant";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import {clearResponseSearch} from "../store/actions/search/search";
import {clearImageHD, startFetchAssetImageHd} from "../store/actions/imageHd";
import PreserveImageRatio from "../components/PreserveImageRatio/PreserveImageRatio";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(12, 0, 0, 0),
        flexGrow: 1
    },
    containerInfo: {
        backgroundColor: theme.palette.primary.main
    },
    textElementContainer: {
        maxWidth: '600px',
        padding: theme.spacing(2)
    },
    dropDownElement: {
        maxWidth: '350px',
    },
    chipsContainer: {
        height: '200px',
        overflowY: 'auto'
    },
    dialogStyle: {
        marginTop: theme.spacing(4)
    },
    itemCenter: {
        textAlign: 'center'
    },
    detailKey: {
        fontWeight: "bold"
    },
    textElementDetailNote: {
        paddingRight: theme.spacing(2)
    }
}));

const PostProductionContainer = props => {
    const classes = useStyles();
    const intl = useIntl();
    const maxNotesLength = 200;

    const [changeNotesState, setChangeNotesState] = useState(false);
    const [dataAssets, setDataAssets] = useState([]);
    const [carouselDataAssets, setCarouselDataAssets] = useState([]);
    const [emailContent, setEmailContent] = useState("");
    const [structureNoteState, setStructureNoteState] = useState("");
    const [postProducer, setPostProducer] = useState("");
    const [postProducerOptions, setPostProducerOptions] = useState([]);
    const [emailReceiver, setEmailReceiver] = useState("");
    const [mailingList, setMailingList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [dialogStatus, setDialogStatus] = useState({ open: false, asset: {}, notesChange: false });
    const [snackBarStatus, setSnackBarStatus] = useState({ open: false, severity: 'error', label: {} });
    const [backdropState, setBackdropState] = useState({ loading: false, label: '' });


    const keysDetailedView = [
        { translateKey: 'product.richiedente', default: 'Richiedente', key: 'richiedente' },
        { translateKey: 'product.service', default: 'Service', key: 'service' },
        { translateKey: 'product.asset.resolution', default: 'Resolution', key: 'resolution' },
        { translateKey: 'product.season_description', default: 'Competence', key: 'season_description' },
        { translateKey: 'product.asset.status', default: 'Status', key: 'status' },
        { translateKey: 'product.asset.extension', default: 'Extension', key: 'extension' },
        { translateKey: 'product.asset.date_hour_shoot', default: 'Shoot date', key: 'shooting_date' },
        { translateKey: 'product.asset.color_space', default: 'Color space', key: 'color_space' },
        { translateKey: 'product.asset.submitter', default: 'Submitter', key: 'submitter' },
        { translateKey: 'product.brand', default: 'Brand', key: 'brand' },
        { translateKey: 'product.gender', default: 'Gender', key: 'gender' },
        { translateKey: 'product.l3_product', default: 'L3 Product', key: 'L3' },
        { translateKey: 'product.asset.shoot_notes', default: 'Shoot notes', key: 'shoot_notes' }
    ];

    /**
     * This effect is used to populate data structure to rendering page for the first time.
     */
    useEffect(() => {
        const { loading, action, assetsData } = props.postProduction;

        if (!loading && action === POST_PRODUCTION_ACTIONS_REQUEST.getAssets) {
            if (assetsData.discarded) {
                setSnackBarStatus({
                    open: true,
                    severity: 'warning',
                    label: {
                        translateKey: 'search.warning.wrong.images',
                        defaultText: 'Some images have been already sent to post producer. They has been removed from selection.'
                    }
                });
            }

            if (_.isEmpty(assetsData.assets)) {
                props.clearResponseSearch();
                setTimeout(() => {
                    props.history.push({ pathname: "/search/" });
                }, 2000);
            } else {
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: true
                }));
                props.getPostProducers();
                const tmpCarouselAssets = [];
                let tmpTwelveItems = [];
                assetsData.assets.forEach((asset, index) => {
                    asset.index = index;

                    tmpTwelveItems.push({
                        asset_name: asset.asset_name,
                        view: asset.view,
                        asset_img_url: asset.img_url,
                        asset_img_url_hd: asset.img_url_hd,
                        index: asset.index,
                        status: asset.status,
                        notes: asset.notes_for_post,
                        asset_guid: asset.asset_guid
                    });
                    if (tmpTwelveItems.length === CAROUSEL_ITEMS_PER_PAGE || index === assetsData.assets.length - 1) {
                        tmpCarouselAssets.push(tmpTwelveItems);
                        tmpTwelveItems = [];
                    }
                });
                setCarouselDataAssets(tmpCarouselAssets);
                setDataAssets(assetsData.assets);
            }
        } else if (assetsData && _.isEmpty(assetsData)) {
            props.clearResponseSearch();
            props.history.push({ pathname: "/search/" });
        }
    }, [props.postProduction]);

    /**
     * This effect is used to handle the request's response for send email to post producers.
     */
    useEffect(() => {
        const { loading, action, email } = props.postProduction;

        if (!loading) {
            if (action === POST_PRODUCTION_ACTIONS_REQUEST.sendEmail) {
                if (email.sent) {
                    setSnackBarStatus({
                        open: true,
                        severity: SNACKBAR_LEVEL.success,
                        label: {
                            translateKey: 'post_production.email.success',
                            defaultText: email.responseText
                        }
                    });

                    props.clearResponseSearch();
                    setTimeout(() => {
                        props.history.push({ pathname: "/search/" });
                    }, 2000);
                } else {
                    setSnackBarStatus({
                        open: true,
                        severity: SNACKBAR_LEVEL.error,
                        label: {
                            translateKey: 'post_production.email.failure',
                            defaultText: email.responseText
                        }
                    });
                }
            }
        }
    }, [props.postProduction]);

    /**
     * This effect is used to handle the request's response about postProducers list.
     */
    useEffect(() => {
        const { loading, action, error, postProducers } = props.postProducersReducer;
        if (!loading) {
            if (action === ACTION_REQUEST.get) {
                if (error) {
                    setSnackBarStatus(prevState => ({
                        ...prevState,
                        open: true,
                        severity: 'error',
                        label: {
                            translateKey: 'post_production.fetching.postProducers.failure',
                            defaultText: 'Cannot fetch post producers list.'
                        }
                    }));
                } else {
                    let tmpPostProducers = [];

                    if (!_.isEmpty(postProducers)) {
                        tmpPostProducers = postProducers.map(postProducer => {
                            return {
                                label: postProducer.first_name.concat(' ', postProducer.last_name),
                                value: postProducer.email
                            }
                        });
                    }

                    setPostProducerOptions(tmpPostProducers);
                }
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: false
                }));
            }
        } else {
            setBackdropState(prevState => ({
                ...prevState,
                loading: true
            }));
        }
    }, [props.postProducersReducer]);


    const postProducerHandler = (event) => {
        if (!mailingList.includes(event.target.value)) {
            const tmpMailingList = _.cloneDeep(mailingList);

            tmpMailingList.push(event.target.value);

            setMailingList(tmpMailingList);
        }
        setPostProducer(event.target.value);
    };


    const emailReceiverHandler = (event) => {
        setEmailReceiver(event.target.value)
    };


    const emailReceiverEnterHandler = (event) => {
        if (event.key === 'Enter' && !mailingList.includes(event.target.value)) {
            const tmpMailingList = _.cloneDeep(mailingList);
            tmpMailingList.push(event.target.value);

            setEmailReceiver("");
            setMailingList(tmpMailingList);
        }
    };


    const deleteMailHandler = (chip) => () => {
        const tmpMailingList = _.cloneDeep(mailingList);
        _.remove(tmpMailingList, (elem) => {
            return elem === chip;
        })

        setMailingList(tmpMailingList);
    };


    const emailContentHandler = event => {
        setEmailContent(event.target.value.substr(0, maxNotesLength));
    };


    const structureNoteOnChangeHandler = event => {
        setStructureNoteState(event.target.value.substr(0, maxNotesLength));
    };


    const openDialogDetailsHandler = (event, indexItem) => {
        setCurrentIndex(indexItem);

        props.getHdImage(dataAssets[indexItem].asset_guid);
    };

    const previousHandler = () => {
        const newIndex = dialogStatus.asset.index - 1;

        setCurrentIndex(newIndex);

        if (newIndex >= 0) {
            props.getHdImage(dataAssets[newIndex].asset_guid);
        }
    };

    const nextHandler = () => {
        const newIndex = dialogStatus.asset.index + 1;

        setCurrentIndex(newIndex);

        if (newIndex <= dataAssets.length - 1) {
            props.getHdImage(dataAssets[newIndex].asset_guid);
        }
    };


    /**
     * This effect is used
     */
    useEffect(() => {
        const { action, loading, error, imgUrlHD } = props.imageHdReducer;

        if (action === ACTION_REQUEST.post) {
            if (loading) {
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: true
                }));
            } else {
                if (error) {
                    setSnackBarStatus(prevState => ({
                        ...prevState,
                        open: true,
                        severity: SNACKBAR_LEVEL.error,
                        label: {
                            translateKey: 'TODO',
                            defaultText: 'Impossible load image with full quality.'
                        }
                    }));
                } else {
                    const newDataAssets = _.cloneDeep(dataAssets);
                    const newAsset = _.cloneDeep(dataAssets[currentIndex]);

                    newAsset.img_url_hd = imgUrlHD;
                    newDataAssets[currentIndex] = newAsset;

                    dialogStatus.open ?
                        setDialogStatus(prevState => ({
                            ...prevState,
                            asset: newDataAssets[currentIndex]
                        })) :
                        setDialogStatus({ open: true, asset: newDataAssets[currentIndex] });
                    setDataAssets(newDataAssets);
                }
                setBackdropState(prevState => ({
                    ...prevState,
                    loading: false
                }));
            }
        }
    }, [props.imageHdReducer]);


    /**
     * This effect is used to update carousel dataset when user update post production assets notes (at least one
     * changes).
     */
    useEffect(() => {
        if (changeNotesState) {
            const tmpCarouselAssets = [];
            let tmpTwelveItems = [];
            let tmpDataAssets = _.cloneDeep(dataAssets);
            tmpDataAssets.forEach((asset, index) => {
                asset.index = index;

                tmpTwelveItems.push({
                    asset_name: asset.asset_name,
                    view: asset.view,
                    asset_img_url: asset.img_url,
                    index: asset.index,
                    status: asset.status,
                    notes: asset.notes_for_post,
                    asset_guid: asset.asset_guid
                });
                if (tmpTwelveItems.length === CAROUSEL_ITEMS_PER_PAGE || index === dataAssets.length - 1) {
                    tmpCarouselAssets.push(tmpTwelveItems);
                    tmpTwelveItems = [];
                }
            });

            setCarouselDataAssets(tmpCarouselAssets);
            setChangeNotesState(false);
        }
    }, [changeNotesState]);


    const postProductionNoteAssetDetailHandler = (event) => {
        const tmpDataAssets = _.cloneDeep(dataAssets);
        const tmpDialogStatus = _.cloneDeep(dialogStatus);
        const tmpAsset = _.cloneDeep(dataAssets[currentIndex]);

        tmpAsset.notes_for_post = event.target.value.substr(0, maxNotesLength);
        tmpDataAssets[currentIndex] = _.cloneDeep(tmpAsset);
        tmpDialogStatus.asset = _.cloneDeep(tmpAsset);
        tmpDialogStatus.notesChange = true;

        setDataAssets(tmpDataAssets);
        setDialogStatus(tmpDialogStatus);
    };


    const closeDialogDetailsHandler = () => {
        let dialogTmp = _.cloneDeep(dialogStatus);

        if (dialogTmp.notesChange) {
            setChangeNotesState(true);
        }

        dialogTmp.open = false;
        setDialogStatus(dialogTmp);
        props.clearHdImage();
    };


    const sendEmails = () => {
        let body;

        setBackdropState(prevState => ({
            ...prevState,
            loading: true
        }));

        const assets = dataAssets.map(asset => ({
            richiedente: asset.richiedente,
            service: asset.service,
            brand: asset.brand,
            gender: asset.gender,
            season_description: asset.season_description,
            shoot_type: asset.shoot_type,
            filename: asset.filename,
            filename_draft: asset.filename_draft,
            shoot_notes: asset.shoot_notes,
            notes_for_post: asset.notes_for_post,
            asset_name: asset.asset_name,
            asset_guid: asset.asset_guid
        }))

        body = {
            post_producer: postProducerOptions.find(producer => {
                return producer.value === postProducer;
            })?.label,
            text: emailContent,
            receivers: mailingList,
            structure_note: structureNoteState,
            assets: assets
        };
        props.sendPostProducerEmail(body);
    };


    const chipList = mailingList.map(key => {
        return <ChipElement key={key} label={key} onDelete={deleteMailHandler(key)} cssType={'deletable'}/>;
    });


    return (
        <div className={classes.root}>
            <BackdropElement loading={backdropState.loading} label={backdropState.label}/>
            <Grid container
                  direction='row'
                  justify='space-between'
                  spacing={5}>
                <Grid item xs={12}>
                    {carouselDataAssets.length > 0 &&
                    <CarouselElement dataItems={carouselDataAssets}
                                     itemType={{ type: 'postProduction', onClickHandler: openDialogDetailsHandler }}/>
                    }
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.containerInfo}>
                        <Grid container
                              direction="row"
                              justify="space-evenly"
                              alignItems="center" spacing={4}>
                            <Grid item xs={4}>
                                <div className={classes.textElementContainer}>
                                    <InputTextElement
                                        labelId={"common.note"}
                                        label={"Note"}
                                        name={"email_body"}
                                        handleChange={emailContentHandler}
                                        value={emailContent}
                                        multiline
                                        type='text'
                                        rowsNumber={5}
                                        fullWidth/>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container
                                      direction="column"
                                      justify="center"
                                      alignItems="center">
                                    <Grid item xs={12}>
                                        <div className={classes.dropDownElement}>
                                            <DropDownElement name={'postProducer'}
                                                             value={postProducer}
                                                             label={{
                                                                 translateKey: 'search.keys.post.producer',
                                                                 defaultText: 'Post Producer'
                                                             }}
                                                             onChangeHandler={(event) => postProducerHandler(event)}
                                                             options={postProducerOptions}
                                                             loading={props.postProducersReducer.loading}/>
                                            <InputTextElement
                                                labelId={"post_production.insert_receiver"}
                                                label={"Add receiver email"}
                                                name={"receiver_email"}
                                                handleChange={emailReceiverHandler}
                                                handleKeyPress={emailReceiverEnterHandler}
                                                value={emailReceiver}
                                                type='text'
                                                fullWidth/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.chipsContainer}>
                                    {chipList}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.textElementContainer}>
                                    <InputTextElement
                                        labelId={"post_production.structure_note"}
                                        label={"Structure note"}
                                        name={"structure_note"}
                                        handleChange={structureNoteOnChangeHandler}
                                        value={structureNoteState}
                                        multiline
                                        type='text'
                                        rowsNumber={5}
                                        fullWidth/>
                                </div>
                            </Grid>
                            <Grid item xs={4}/>
                            <Grid item xs={4}/>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                          direction='row'
                          justify='flex-end'
                          alignItems='center'>
                        <Grid item xs={2}>
                            <ButtonElement labelId='common.send'
                                           label='Send'
                                           onSubmit={sendEmails}
                                           type='submit'
                                           disabled={mailingList.length === 0 || props.postProduction.email.sent}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SnackbarElement
                        open={snackBarStatus.open}
                        type={snackBarStatus.severity}
                        label={snackBarStatus.label.defaultText}
                        labelId={snackBarStatus.label.translateKey}
                        handleClose={() => setSnackBarStatus({ open: false, severity: 'error', label: {} })}
                    />
                </Grid>
            </Grid>
            <ModalFullScreen openDialog={dialogStatus.open} dialogTitle={dialogStatus.asset.asset_name}
                             closeHandler={closeDialogDetailsHandler}>
                <Grid container
                      direction='row'
                      justify='flex-start'
                      alignItems='flex-start'
                      spacing={3}
                      className={classes.dialogStyle}>
                    <Grid item xs={8}>
                        <Grid container
                              direction='row'
                              justify='space-around'
                              alignItems='center'>
                            <Grid item xs={2} className={classes.itemCenter}>
                                <IconButtonElement
                                    icon='arrow_back_ios_icon'
                                    labelId='common.previous'
                                    label='Prev'
                                    onClick={previousHandler}
                                    disabled={dialogStatus.asset.index === 0}/>
                            </Grid>
                            <Grid item xs={8} className={classes.itemCenter}>
                                <PreserveImageRatio
                                     src={dialogStatus.asset.img_url_hd}
                                     alt={"Image not found"}
                                     height={700}
                                     width={500}
                                     onError={(event) => onSecondErrorSrc(event, dialogStatus.asset.img_url)}/>
                            </Grid>
                            <Grid item xs={2} className={classes.itemCenter}>
                                <IconButtonElement
                                    icon='arrow_forward_ios_icon'
                                    labelId='common.next'
                                    label='Next'
                                    onClick={nextHandler}
                                    disabled={dialogStatus.asset.index === dataAssets.length - 1}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container
                              direction='row'
                              justify='space-evenly'
                              alignItems='flex-start' spacing={4}>
                            {keysDetailedView.map(objKey => {
                                return (
                                    <Grid item xs={4} key={objKey.key}>
                                        <Typography className={classes.detailKey}>
                                            {intl.formatMessage({
                                                id: objKey.translateKey,
                                                defaultMessage: objKey.default
                                            })}
                                        </Typography>
                                        <Typography>
                                            {dialogStatus.asset[objKey.key]}
                                        </Typography>
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12}>
                                <div className={classes.textElementDetailNote}>
                                    {
                                        dataAssets.length > 0 ?
                                            <InputTextElement
                                                labelId={"common.note"}
                                                label={"Note"}
                                                placeholderId={'post_production.insert_note'}
                                                placeholder={'Insert note for post production...'}
                                                name={"post_note_detailed"}
                                                handleChange={postProductionNoteAssetDetailHandler}
                                                value={dataAssets[currentIndex].notes_for_post || ""}
                                                multiline
                                                type='text'
                                                rowsNumber={10}
                                                fullWidth/> :
                                            ""
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ModalFullScreen>
        </div>
    );
}

const mapStateToProps = state => ({
    postProduction: state.postProductionReducer,
    postProducersReducer: state.postProducersReducer,
    imageHdReducer: state.imageHdReducer
});

const mapDispatchToProps = dispatch => ({
    sendPostProducerEmail: (body) => dispatch(startSendEmail(body)),
    getPostProducers: () => dispatch(startGetPostProducers()),
    clearPostProduction: () => dispatch(clearPostProduction()),
    clearResponseSearch: () => dispatch(clearResponseSearch()),
    getHdImage: (assetGuid) => dispatch(startFetchAssetImageHd(assetGuid)),
    clearHdImage: () => dispatch(clearImageHD()),
});

export default connect(mapStateToProps,
    mapDispatchToProps)(PostProductionContainer);
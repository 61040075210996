import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {LeftSideBarUpper} from "../components/LeftSideBarUpper/LeftSideBarUpper";
import {useIntl} from "react-intl";
import {startGetRichiedente} from "../store/actions/richiedente";
import _ from "lodash";
import {saveAs} from "file-saver";
import {startGetServiceByRichiedente} from "../store/actions/service";
import {startGetSeasons} from "../store/actions/season";
import {startGetShootType} from "../store/actions/search/shoot_type";
import DropzoneDialogElement from "../components/DropzoneDialogElement/DropzoneDialogElement";
import ButtonElement from "../components/ButtonElement/ButtonElement";
import IconButtonElement from "../components/IconButtonElement/IconButtonElement";
import {
    clearDeletedAssets,
    clearUploadAssets,
    startCheckDeletedAssets,
    startClearResponseAssets,
    startClearUploadedImages,
    startGetSignedUrlImages,
    startGetUploadType,
    startUploadDigitalAssets,
} from "../store/actions/uploadDigitalAssets/digitalAssets";
import SnackbarElement from "../components/SnackbarElement/SnackbarElement";
import MenuTabsElement from "../components/MenuTabsElement/MenuTabsElement";
import DropDownElement from "../components/DropDownElement/DropDownElement";
import MaterialSimpleTable from "../components/TableElement/MaterialSimpleTable/MaterialSimpleTable";
import * as Excel from "exceljs";
import BackdropElement from "../components/BackdropElement/BackdropElement";
import Typography from "@material-ui/core/Typography";
import CarouselElement from "../components/CarouselElement/CarouselElement";
import {startGetProductType} from "../store/actions/uploadDigitalAssets/productType";
import {filterActiveSeasons, renderTableResult} from "../utils/CommonFunction";
import ReusableModal from "../components/Modal/ReusableModal/ReusableModal";
import ChipElement from "../components/ChipElement/ChipElement";
import {ASSET_STATUS} from "../utils/constant";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(12, 0, 0, 0),
        flexGrow: 1
    },
    uploadRow: {
        backgroundColor: theme.palette.secondary.main,
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
        paddingRight: theme.spacing(2)
    },
    /*tableBox: {
        // maxHeight: "500px"
    },*/
    success: {
        color: theme.palette.success.main
    }
}));

const UploadDigitalAssetsContainer = props => {
    const classes = useStyles();
    const intl = useIntl();

    const [currentContext, setCurrentContext] = useState({
        richiedente: "",
        richiedente_name: "",
        service: "",
        richiedente_service: "",
        season: "",
        product_type: "",
        product_type_name: ""
    });
    const [uploadType, setUploadType] = useState({ guid: "", name: "" });
    const [shootType, setShootType] = useState("");
    const [dropzoneDialogOpen, setDropzoneDialogOpen] = useState(false);
    const [fileObjects, setFileObjects] = useState({ allFiles: [], notDeletedFiles: [] });
    const [carouselData, setCarouselData] = useState([]);
    const [viewType, setViewType] = useState(0);
    const [columnsData, setColumnsData] = useState([]);
    const [backdropState, setBackdropState] = useState({ loading: false, label: '' });
    const [snackbarState, setSnackbarState] = useState({
        show: false,
        message: '',
        type: '',
        messageAbout: ''
    });
    const [confirmModalState, setConfirmModalState] = useState({
        open: false,
        title: intl.formatMessage({ id: 'upload.digital.assets.title.modal', defaultMessage: 'Upload' }),
        text: intl.formatMessage({
            id: 'upload.digital.assets.text.modal',
            defaultMessage: 'There are some assets deleted. Do you want to upload them?'
        }),
        assetsDeleted: []
    });
    const [loadingAllFilesState, setLoadingAllFilesState] = useState(null);

    const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


    const onChangeContextHandler = event => {
        console.log(event.target.value, event.target.name);
        let fieldName = event.target.name;
        let value = event.target.value;
        if (fieldName === "richiedente") {
            let richiedenteLabel = props.richiedente.find(r => r.value === value).label;
            let productTypeId = "";
            if (richiedenteLabel === "E-commerce") productTypeId = props.product_type.find(p => p.label === "Prodotto Finito").value;
            console.log("Result, ", richiedenteLabel, productTypeId);
            setCurrentContext({
                ...currentContext,
                [fieldName]: value,
                richiedente_name: richiedenteLabel,
                service: "",
                product_type: productTypeId,
                product_type_name: "Prodotto Finito"
            });
            props.getServicesByRichiedente(value);
        } else if (fieldName === "service") {
            let result = props.service.find(s => s.value === value).richiedente_service;
            console.log("RICHIEDENTE SERVICE GUID", result);
            setCurrentContext({
                ...currentContext,
                [fieldName]: value,
                richiedente_service: result
            });
        } else if (fieldName === "upload_type") {
            let uploadTypeName = props.upload_type.find(u => u.value === value).label;
            if (uploadTypeName === ASSET_STATUS.postProducedNew) setShootType("");
            setUploadType({ guid: value, name: uploadTypeName });
        } else if (fieldName === "shoot_type") {
            setShootType(value);
        } else {
            setCurrentContext({
                ...currentContext,
                [fieldName]: value
            });
        }
    }

    const onFileUpload = () => {
        setDropzoneDialogOpen(true);
    }

    const columns = [
        {
            title: intl.formatMessage({ 'id': 'load.master.data.table.column.number', 'defaultMessage': '#' }),
            field: 'id',
            editable: 'never'
        },
        {
            title: intl.formatMessage({
                'id': 'load.master.data.table.column.product.code',
                'defaultMessage': 'Product Code'
            }), field: 'style_fabric_color', editable: 'never'
        },
        {
            title: intl.formatMessage({
                'id': 'upload.digital.assets.table.column.file.name',
                'defaultMessage': 'File Name'
            }), field: 'filename', editable: 'never'
        },
        {
            title: intl.formatMessage({ 'id': 'load.master.data.table.column.outcome', 'defaultMessage': 'Outcome' }),
            field: 'outcome',
            editable: 'never',
            render: (rowData) => renderTableResult(rowData, classes)
        }]

    const richiedenteDropdown = {
        type: "dropdown",
        name: "richiedente",
        value: currentContext["richiedente"],
        onChangeHandler: onChangeContextHandler,
        icon: "person",
        label: { translateKey: "common.dropdown.label.richiedente", defaultText: "Richiedente" },
        options: props.richiedente || [],
        loading: props.loadingRichiedente,
    };
    const serviceDropdown = {
        type: "dropdown",
        name: "service",
        value: currentContext["service"],
        onChangeHandler: onChangeContextHandler,
        icon: "settings",
        label: { translateKey: "common.dropdown.label.service", defaultText: "Service" },
        options: props.service || [],
        loading: props.loadingService,
        disabled: currentContext.richiedente === ""
    };
    const seasonDropdown = {
        type: "dropdown",
        name: "season",
        value: currentContext["season"],
        onChangeHandler: onChangeContextHandler,
        icon: "miscellaneous_services",
        label: { translateKey: "common.dropdown.label.season", defaultText: "Season" },
        options: props.seasons || [],
        loading: props.loadingSeason
    };
    const productTypeDropdown = {
        type: currentContext["richiedente_name"] === "E-commerce" ? "input" : "dropdown",
        name: "product_type",
        value: currentContext["richiedente_name"] === "E-commerce" ? currentContext["product_type_name"] : currentContext["product_type"],
        onChangeHandler: onChangeContextHandler,
        icon: "category",
        label: { translateKey: "upload.digital.assets.dropdown.label.product.type", defaultText: "Product Type" },
        options: props.product_type || [],
        loading: props.loadingProductType,
        disabled: currentContext["richiedente_name"] === "E-commerce"
    };
    const shootTypeDropdown = {
        type: "dropdown",
        name: "shoot_type",
        value: shootType,
        onChangeHandler: onChangeContextHandler,
        icon: "category",
        label: { translateKey: "product.asset.shoot_type", defaultText: "Shoot Type" },
        options: props.shoot_type || [],
        loading: props.loadingShootType,
        disabled: uploadType.name === "" || uploadType.name === ASSET_STATUS.postProducedNew
    };
    const uploadFileButton = {
        type: "button",
        position: "bottom",
        labelId: "common.button.label.upload.file",
        label: "Upload File",
        onSubmit: onFileUpload,
        loading: false,
        disabled: _.some(currentContext, el => el === "")
    };

    const groupElements = [richiedenteDropdown, serviceDropdown, seasonDropdown, "space", productTypeDropdown, shootTypeDropdown, uploadFileButton];

    const menuTabs = [
        { translateKey: "upload.digital.assets.tab.assets.view", default: "Assets View" },
        {
            translateKey: "upload.digital.assets.tab.outcome.view",
            default: "Outcome View",
            disabled: !props.uploadResponse || props.uploadResponse.length === 0
        }
    ];

    //let carousel = null;

    useEffect(() => {
        props.getRichiedente();
        props.getSeason();
        props.getShootType();
        props.getUploadType();
        props.getProductType();

        // Component willUnmount
        return () => {
            props.clearUploadedImages();
            props.clearResponseAssets();
            props.clearCheckDeletedAssets();
        }
    }, []);

    useEffect(() => {
        if ((loadingAllFilesState && props.uploadedImgError && props.uploadedImages.length > 0 && props.uploadedImages.length < fileObjects.allFiles.length) ||
            (!loadingAllFilesState && props.uploadedImgError && props.uploadedImages.length > 0 && props.uploadedImages.length < fileObjects.notDeletedFiles.length)) {
            console.log("It's changing and there is an error", props.uploadedImages, fileObjects, fileObjects.allFiles.find(f => f.path === props.errorImg));
            removeImage(fileObjects.allFiles.find(f => f.path === props.errorImg));
        }
        if (loadingAllFilesState && props.uploadedImages.length > 0 && props.uploadedImages.length === fileObjects.allFiles.length) {
            console.log("YEEEEEEEEEEEEEE UPLOADING DONE", props.uploadedImages);
            props.uploadDigitalAssets(props.uploadedImages, currentContext, uploadType.guid);
            setLoadingAllFilesState(null);
        } else if (!loadingAllFilesState && props.uploadedImages.length > 0 && props.uploadedImages.length === fileObjects.notDeletedFiles.length) {
            console.log("YEEEEEEEEEEEEEE UPLOADING DONE", props.uploadedImages);
            props.uploadDigitalAssets(props.uploadedImages, currentContext, uploadType.guid);
            setLoadingAllFilesState(null);
        }
    }, [props.uploadedImages]);

    /**
     * This effect is used to create table results after success response of backend.
     */
    useEffect(() => {
        if (props.uploadResponse && props.uploadResponse.length > 0) {
            console.log("ECCO LA RESPONSE", props.uploadResponse);
            props.clearUploadedImages();
            let indexedData = _.cloneDeep(props.uploadResponse).map((d, index) => ({
                ...d,
                id: index + 1,
                outcome: d.messages.join(', ')
            }));

            setConfirmModalState(prevState => ({
                ...prevState,
                open: false
            }))
            setColumnsData(indexedData);
            setViewType(1);
        }
    }, [props.uploadResponse]);

    useEffect(() => {
        if (columnsData.length > 0 && props.uploadResponse.length > 0)
            downloadExcel();
    }, [columnsData]);

    /**
     * This effect is used to show an error when some files cannot be processed from backend functions:
     * props.getSignedUrlImages(...) || props.uploadDigitalAssets(...)
     */
    useEffect(() => {
        if (props.uploadedImgError || props.responseError) {
            console.log("ERROR ", props.uploadedImgError, props.responseError);
            props.clearAllUploadedAssets();
            setSnackbarState(prevState => {
                const message = props.uploadedImgError || props.responseError ?
                    intl.formatMessage({
                        id: 'upload.digital.assets.label.warning.upload.file.not.found',
                        defaultMessage: 'There was an error uploading an image. File not found.'
                    }) :
                    intl.formatMessage({
                        id: 'upload.digital.assets.label.warning.upload.digital.assets.error',
                        defaultMessage: 'There was an error uploading digital assets. Please try again.'
                    });

                return ({
                    ...prevState,
                    show: true,
                    message: message,
                    type: 'error',
                    messageAbout: ''
                });
            });
        }
    }, [props.uploadedImgError, props.responseError])

    /**
     * This effect is used to set different state used for different interaction:
     *  - create the data structure used to create the carousel --> [[1, 2, 3, 4, 5, 6], [7, 8, 9, 10, 11, 12], ...];
     *  - creating chips element used into confirm modal to show which are the assets deleted;
     *  - add property deleted to single asset element to show the red border or not;
     *  - create the list of not deleted assets used to send the right request to be --> {allFile: [...], notDeletedFiles: [...]}
     */
    useEffect(() => {
        if (!_.isEmpty(props.deletedAssets) && !_.isEmpty(fileObjects.allFiles)) {
            const tmpCarouselData = [];
            let tmpSixItems = [];
            let tmpItems = _.cloneDeep(fileObjects.allFiles);
            let deletedAssets = [];
            let notDeletedFiles = [];

            tmpItems.forEach((asset, index) => {
                // asset.deleted = props.deletedAssets[asset.label] ? props.deletedAssets[asset.label] : false;
                if (props.deletedAssets[asset.label]) {
                    deletedAssets.push(<ChipElement key={asset.label} label={asset.label} cssType={'lightGrey'}/>);
                    asset.deleted = true;
                } else {
                    asset.deleted = false;
                    notDeletedFiles.push(asset)
                }

                tmpSixItems.push(asset);
                if (tmpSixItems.length === 12 || index === fileObjects.allFiles.length - 1) {
                    tmpCarouselData.push(tmpSixItems);
                    tmpSixItems = [];
                }
            });
            console.log("right??? ", tmpCarouselData);
            setCarouselData(tmpCarouselData);
            setConfirmModalState(prevState => ({
                ...prevState,
                assetsDeleted: deletedAssets
            }));
            setFileObjects(prevState => ({
                ...prevState,
                notDeletedFiles: notDeletedFiles
            }));
        }
    }, [props.deletedAssets, fileObjects.allFiles]);

    const saveImagesLocally = (files) => {
        // setSnackBarFiles(true);
        setSnackbarState(prevState => {
            const message = intl.formatMessage({
                id: 'upload.digital.assets.label',
                defaultMessage: '{totalFiles} files selected.'
            }, { totalFiles: files.length });


            return ({
                ...prevState,
                show: true,
                message: message,
                type: 'success',
                messageAbout: ''
            });
        });
        // setDroppedFiles(files.length);
        let newFiles = _.cloneDeep(files);
        newFiles.forEach(f => {
            //f.src = URL.createObjectURL(f);
            f.label = f.name.split('.').slice(0, -1).join('.');
            let reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = () => {
                //console.log("base64", reader.result);
                f.img_url = reader.result;
            }
        });
        console.log("FILEs ", newFiles);
    };

    const onSubmitImages = (files) => {
        console.log(files);

        setFileObjects(prevState => {
            let updatedImages = _.cloneDeep(prevState.allFiles);
            let assetToChecked = [];

            files.forEach(i => {
                assetToChecked.push(i.label);
                let toDelete = prevState.allFiles.find(f => f.name === i.name)
                if (toDelete) {
                    console.log("Esiste!! ", toDelete);
                    _.pull(updatedImages, toDelete);
                }
            });

            if (updatedImages.length > 0) updatedImages.forEach(file => assetToChecked.push(file.label));

            props.checkDeletedAssets(currentContext, assetToChecked);

            const updatedAllFiles = _.cloneDeep([...updatedImages, ...files]);

            console.log('updatedAllFiles: ', updatedAllFiles);
            return {
                ...prevState,
                allFiles: updatedAllFiles
            };
        });
        setDropzoneDialogOpen(false);
        setViewType(0);
    };

    const removeAllFiles = () => {
        setFileObjects(prevState => ({
            ...prevState,
            allFiles: [],
            notDeletedFiles: []
        }));
        setCarouselData(prevState => []);
        setConfirmModalState(prevState => ({
            ...prevState,
            open: false,
            assetsDeleted: []
        }));
        setColumnsData(prevState => []);
        setBackdropState(prevState => ({ loading: false, label: '' }));
        setSnackbarState(prevState => ({ show: false, messageAbout: '', message: '', type: '' }));
        setLoadingAllFilesState(null);
        setUploadType({ guid: "", name: "" });
        props.clearAllUploadedAssets();
        setViewType(0);
    };

    const removeImage = (image) => {
        console.log(image);
        let allFiles = _.cloneDeep(fileObjects.allFiles);
        let notDeletedFiles = _.cloneDeep(fileObjects.notDeletedFiles);

        _.pull(allFiles, image);
        _.pull(notDeletedFiles, image);

        setFileObjects(prevState => ({
            ...prevState,
            allFiles: allFiles,
            notDeletedFiles: notDeletedFiles
        }));
    };

    const viewTabsHandler = (event, newValue) => {
        console.log("TAB CHANGING, ", event, newValue)
        setViewType(newValue);
    };

    const resetSession = () => {
        setCurrentContext({
            richiedente: "",
            richiedente_name: "",
            service: "",
            richiedente_service: "",
            season: "",
            product_type: "",
            product_type_name: ""
        })
        setFileObjects(prevState => ({
            ...prevState,
            allFiles: [],
            notDeletedFiles: []
        }));
        setCarouselData(prevState => []);
        setConfirmModalState(prevState => ({
            ...prevState,
            open: false,
            assetsDeleted: []
        }));
        setColumnsData(prevState => []);
        setBackdropState(prevState => ({ loading: false, label: '' }));
        setSnackbarState(prevState => ({ show: false, messageAbout: '', message: '', type: '' }));
        setLoadingAllFilesState(null);
        setUploadType({ guid: "", name: "" });
        props.clearAllUploadedAssets();
        setViewType(0);
    };

    /**
     * [DOWNLOAD] Used this function to download data selected on web application table. The result is an excel file.
     */
    const downloadExcel = () => {
        const filename = 'digitalAssets.xlsx';
        const workbook = new Excel.Workbook();
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        const worksheet = workbook.addWorksheet('Sheet 1');
        const worksheetColumns = [];

        columns.forEach(column => {
            if (column.field !== 'id') {
                worksheetColumns.push({ header: column.title, key: column.field });
            }
        });
        worksheet.columns = worksheetColumns;

        columnsData.forEach(index => {
            worksheet.addRow(index);
        });

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: blobType });
            saveAs(blob, filename);
        });
    };

    /**
     * This function is used to given the choice to the user to upload all file or just NOT deleted files.
     */
    const uploadAssets = () => {
        _.isEmpty(confirmModalState.assetsDeleted) ?
            props.getSignedUrlImages(fileObjects.allFiles) :
            setConfirmModalState(prevState => ({
                ...prevState,
                open: true
            }));
    };

    const uploadAllFiles = () => {
        props.getSignedUrlImages(fileObjects.allFiles);
        setLoadingAllFilesState(true);
    };

    const uploadNotDeletedFiles = () => {
        props.getSignedUrlImages(fileObjects.notDeletedFiles);
        setLoadingAllFilesState(false);
    };

    /**
     * This effect is used to handle backdrop state.
     */
    useEffect(() => {
        if (props.loadingUpload && props.uploadedImages) {
            setBackdropState(prevState => {
                const label = intl.formatMessage({
                    id: 'upload.digital.assets.label.uploading',
                    defaultMessage: '{uploadedAssets} of {totalAssets} uploaded.'
                }, {
                    uploadedAssets: props.uploadedImages.length,
                    totalAssets: loadingAllFilesState ? fileObjects.allFiles.length : fileObjects.notDeletedFiles.length
                });

                return {
                    ...prevState,
                    loading: true,
                    label: label
                };
            });
        } else if (props.loadingDeleteAssets) {
            setBackdropState(prevState => ({
                ...prevState,
                loading: true,
                label: ''
            }));
        } else {
            setBackdropState(prevState => ({
                ...prevState,
                loading: false,
                label: ''
            }));
        }
    }, [props.loadingUpload, props.uploadedImages, props.loadingDeleteAssets]);

    /**
     * This function is used to close snackbar and when necessary clear redux's or component's state.
     */
    const closeSnackbar = () => {
        switch (snackbarState.messageAbout) {
            default:
                break;
        }

        setSnackbarState(prevState => ({
            ...prevState,
            show: false,
            message: '',
            type: '',
            messageAbout: ''
        }));
    };


    return (
        <div className={classes.root}>
            <BackdropElement loading={backdropState.loading} label={backdropState.label}/>
            {snackbarState.show &&
            <SnackbarElement
                open={snackbarState.show}
                type={snackbarState.type}
                label={snackbarState.message}
                handleClose={() => closeSnackbar()}
            />
            }
            <Grid container
                  direction='row'
                  justify='space-between'
                  spacing={3}>
                <Grid item xs={3}>
                    <LeftSideBarUpper groupElements={groupElements}/>
                </Grid>
                {fileObjects.allFiles && fileObjects.allFiles.length > 0 && (
                    <Grid item xs={9}>
                        <Grid container
                              direction='row'
                              justify='space-between'
                              spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.header}>
                                    <Grid container
                                          direction="row"
                                          justify="space-between"
                                          alignItems="center">
                                        <Grid item>
                                            <MenuTabsElement value={viewType}
                                                             onChangeHandler={viewTabsHandler}
                                                             tabs={menuTabs}/>
                                        </Grid>
                                        <Grid item>
                                            {viewType === 0 ?
                                                <Typography>{fileObjects.allFiles.length} {intl.formatMessage({
                                                    id: "upload.digital.assets.label.assets",
                                                    defaultMessage: "Assets"
                                                })}</Typography> :
                                                <Typography>{props.uploadResponse.length} {intl.formatMessage({
                                                    id: "common.label.results",
                                                    defaultMessage: "Results"
                                                })}</Typography>}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container
                                      direction='row'
                                      justify="flex-end"
                                      alignItems="center">
                                    <Grid item>
                                        <IconButtonElement icon={"add_box"} onClick={resetSession}
                                                           labelId={'common.button.label.new.session'}
                                                           label={'New Session'}/>
                                        {viewType === 0 &&
                                        <IconButtonElement icon={"delete"}
                                            /*onClick={() => setFileObjects(prevState => ({
                                                ...prevState,
                                                allFiles: [],
                                                notDeletedFiles: []
                                            }))}*/
                                                           onClick={removeAllFiles}
                                                           labelId={"common.tooltip.label.delete.all"}
                                                           label={"Delete All"} type={"delete"}/>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {viewType === 0 && carouselData.length > 0 &&
                            <>
                                <Grid item xs={12}>
                                    <CarouselElement dataItems={carouselData} itemType={{
                                        type: 'digitalAssets',
                                        iconButton: 'delete',
                                        iconAction: removeImage,
                                        labelIcon: { labelId: "common.tooltip.label.delete", label: "Delete" }
                                    }}/>
                                </Grid>
                                <Grid item xs={12} className={classes.uploadRow}>
                                    <DropDownElement name={"upload_type"} value={uploadType.guid}
                                                     onChangeHandler={onChangeContextHandler} label={{
                                        translateKey: "upload.digital.assets.dropdown.label.upload.type",
                                        defaultText: "Upload Type"
                                    }} options={props.upload_type} loading={props.loadingUploadType}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container
                                          direction='row'
                                          justify="flex-end"
                                          alignItems="center">
                                        <Grid item xs={3}/>
                                        <Grid item xs={3}/>
                                        <Grid item xs={3}/>
                                        <Grid item xs={3}>
                                            <ButtonElement labelId={'common.button.label.load'}
                                                           label={'Load'}
                                                           loading={props.loadingUpload}
                                                //onSubmit={() => props.getSignedUrlImages(fileObjects)}
                                                           onSubmit={() => uploadAssets()}
                                                           disabled={uploadType.name === "" || uploadType.guid === ""}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid></>}
                            {viewType === 1 && props.uploadResponse && props.uploadResponse.length > 0 && (
                                <Grid item xs={12}>
                                    <Grid container
                                          direction='row'
                                          justify='space-between'
                                          spacing={3}>
                                        <Grid item xs={12}>
                                            <MaterialSimpleTable data={columnsData} columns={columns}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container
                                                  direction='row'
                                                  justify="flex-end"
                                                  alignItems="center">
                                                <Grid item xs={3}/>
                                                <Grid item xs={3}/>
                                                <Grid item xs={3}/>
                                                <Grid item xs={3}>
                                                    <ButtonElement labelId={'common.button.label.download.excel'}
                                                                   label={'Download Excel'}
                                                                   onSubmit={downloadExcel}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>)}
                        </Grid>
                    </Grid>)}
                <ReusableModal
                    reusableModal={confirmModalState}
                    redButtonClick={fileObjects.notDeletedFiles.length > 0 ? () => uploadNotDeletedFiles() : null}
                    greenButtonClick={() => uploadAllFiles()}
                    redButtonText={intl.formatMessage({
                        id: "upload.digital.assets.button.upload.not.deleted.label",
                        defaultMessage: "Upload Not Deleted"
                    })}
                    greenButtonText={intl.formatMessage({
                        id: "upload.digital.assets.button.upload.all.label",
                        defaultMessage: "Upload All"
                    })}
                    closeReusableModal={() => setConfirmModalState(prevState => ({
                        ...prevState,
                        open: false
                    }))}>
                    <Grid container direction={"row"} alignItems={"flex-start"} justify={"flex-start"} spacing={3}>
                        <Grid item xs={12}>
                            {confirmModalState.text}
                        </Grid>
                        <Grid item>
                            {confirmModalState.assetsDeleted}
                        </Grid>
                    </Grid>
                </ReusableModal>
                <DropzoneDialogElement
                    acceptedFiles={['image/*', 'video/*']}
                    dialogTitle={{
                        labelId: "upload.digital.assets.dropzone.dialog.title",
                        label: "Upload Digital Assets or Video (MP4, MOV)"
                    }}
                    filesLimit={300}
                    maxFileSize={50000000000}
                    saveFileHandler={onSubmitImages}
                    onDropHandler={saveImagesLocally}
                    dropzoneDialogOpen={dropzoneDialogOpen}
                    setDropzoneDialogOpen={setDropzoneDialogOpen}
                    cancelButtonText={{ labelId: "common.button.label.cancel", label: "Cancel" }}
                    submitButtonText={{ labelId: "common.button.label.submit", label: "Submit" }}
                    showPreviews={true}
                    chipPreview={true}
                />
            </Grid>

        </div>)
}

const mapStateToProps = state => ({
    loadingRichiedente: state.richiedenteReducer.loadingRichiedente,
    loadingService: state.serviceReducer.loadingServices,
    loadingSeason: state.seasonReducer.loadingSeason,
    loadingShootType: state.shootTypeReducer.loadingShootType,
    loadingUploadType: state.digitalAssetsReducer.loadingUploadType,
    loadingProductType: state.productTypeReducer.loadingProductType,
    uploadedImages: state.digitalAssetsReducer.uploadedImages,
    uploadResponse: state.digitalAssetsReducer.response,
    loadingUpload: state.digitalAssetsReducer.loadingUploadAssets,
    responseError: state.digitalAssetsReducer.error,
    uploadedImgError: state.digitalAssetsReducer.uploadedImgError,
    errorImg: state.digitalAssetsReducer.errorImg,
    richiedente: _.toArray(_.mapValues(state.richiedenteReducer.richiedente, function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    service: _.toArray(_.mapValues(state.serviceReducer.services, function (o) {
        return {
            label: o.service,
            value: o.service_guid,
            richiedente_service: o.richiedente_service_guid
        };
    })),
    seasons: _.toArray(_.mapValues(filterActiveSeasons(state.seasonReducer.seasons), function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    shoot_type: _.toArray(_.mapValues(state.shootTypeReducer.shoot_type, function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    product_type: _.toArray(_.mapValues(state.productTypeReducer.product_type, function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    upload_type: _.toArray(_.mapValues(state.digitalAssetsReducer.upload_type, function (o) {
        return {
            label: o.name,
            value: o.guid,
        };
    })),
    loadingCheckAssets: state.digitalAssetsReducer.loadingCheckAssets,
    digitalAssetsAction: state.digitalAssetsReducer.action,
    deletedAssets: state.digitalAssetsReducer.deletedAssets,
    errorCheckDeletedAssets: state.digitalAssetsReducer.errorCheck
});

const mapDispatchToProps = dispatch => ({
    getRichiedente: () => dispatch(startGetRichiedente()),
    getServicesByRichiedente: (richiedente) => dispatch(startGetServiceByRichiedente(richiedente)),
    getSeason: () => dispatch(startGetSeasons()),
    getShootType: () => dispatch(startGetShootType()),
    getUploadType: () => dispatch(startGetUploadType()),
    getProductType: () => dispatch(startGetProductType()),
    getSignedUrlImages: (images) => dispatch(startGetSignedUrlImages(images)),
    uploadDigitalAssets: (images, context, upload_type) => dispatch(startUploadDigitalAssets(images, context, upload_type)),
    clearUploadedImages: () => dispatch(startClearUploadedImages()),
    clearResponseAssets: () => dispatch(startClearResponseAssets()),
    checkDeletedAssets: (context, assetsList) => dispatch(startCheckDeletedAssets(context, assetsList)),
    clearCheckDeletedAssets: () => dispatch(clearDeletedAssets()),
    clearAllUploadedAssets: () => dispatch(clearUploadAssets())
});

export default connect(mapStateToProps,
    mapDispatchToProps)(withRouter(UploadDigitalAssetsContainer));
import {
    CHECK_DELETED_ASSETS_FAILURE,
    CHECK_DELETED_ASSETS_REQUEST,
    CHECK_DELETED_ASSETS_SUCCESS,
    CLEAR_DELETED_ASSETS,
    CLEAR_RESPONSE_ASSETS,
    CLEAR_UPLOAD_ASSETS,
    CLEAR_UPLOADED_IMAGES,
    GET_UPLOAD_TYPE_FAILURE,
    GET_UPLOAD_TYPE_REQUEST,
    GET_UPLOAD_TYPE_SUCCESS,
    UPLOAD_DIGITAL_ASSETS_FAILURE,
    UPLOAD_DIGITAL_ASSETS_REQUEST,
    UPLOAD_DIGITAL_ASSETS_SUCCESS,
    UPLOAD_SINGLE_IMG_FAILURE,
    UPLOAD_SINGLE_IMG_SUCCESS
} from "../../actions/uploadDigitalAssets/digitalAssets";

const initialState = {
    action: null,
    response: [],
    loadingUploadAssets: false,
    upload_type: [],
    loadingUploadType: false,
    uploadedImages: [],
    uploadedImg: "",
    loadingCheckAssets: false,
    deletedAssets: {},
    errorCheck: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_UPLOAD_TYPE_REQUEST:
            return {
                ...state,
                loadingUploadType: true,
                action: 'GET_UPLOAD_TYPES'
            };
        case GET_UPLOAD_TYPE_SUCCESS:
            return {
                ...state,
                loadingUploadType: false,
                upload_type: action.upload_type
            };
        case GET_UPLOAD_TYPE_FAILURE:
            return {
                ...state,
                loadingUploadType: false,
                uploadTypeError: action.uploadTypeError
            };
        case UPLOAD_DIGITAL_ASSETS_REQUEST:
            return {
                ...state,
                loadingUploadAssets: true,
                action: 'LOAD_SINGLE_IMAGE'
            };
        case UPLOAD_DIGITAL_ASSETS_SUCCESS:
            return {
                ...state,
                response: action.response.Results,
                loadingUploadAssets: false
            };
        case UPLOAD_DIGITAL_ASSETS_FAILURE:
            return {
                ...state,
                loadingUploadAssets: false,
                error: action.error
            };
        case CLEAR_RESPONSE_ASSETS:
            return {
                ...state,
                response: [],
                action: null
            };
        case UPLOAD_SINGLE_IMG_SUCCESS:
            return {
                ...state,
                uploadedImg: action.uploadedImg,
                uploadedImages: [
                    ...state.uploadedImages,
                    action.uploadedImg
                ]
            };
        case UPLOAD_SINGLE_IMG_FAILURE:
            return {
                ...state,
                uploadedImgError: action.uploadedImgError,
                errorImg: action.errorImg,
                loadingUploadAssets: false,
            };
        case CLEAR_UPLOADED_IMAGES:
            return {
                ...state,
                uploadedImages: [],
                uploadedImg: "",
                action: null
            };
        case CHECK_DELETED_ASSETS_REQUEST:
            return {
                ...state,
                loadingCheckAssets: true,
                action: 'CHECK_DELETED_ASSETS'
            };
        case CHECK_DELETED_ASSETS_SUCCESS:
            return {
                ...state,
                loadingCheckAssets: false,
                deletedAssets: action.assets
            };
        case CHECK_DELETED_ASSETS_FAILURE:
            return {
                ...state,
                loadingCheckAssets: false,
                errorCheck: ''
            };
        case CLEAR_DELETED_ASSETS:
            return {
                ...state,
                action: null,
                loadingCheckAssets: false,
                deletedAssets: [],
                errorCheck: null
            };
        case CLEAR_UPLOAD_ASSETS:
            return {
                ...state,
                action: null,
                response: [],
                loadingUploadAssets: false,
                uploadedImages: [],
                errorImg: null,
                error: null,
                uploadedImg: "",
                loadingCheckAssets: false,
                deletedAssets: {},
                errorCheck: null
            };
        default:
            return state;
    }
}
import {
    CLEAR_SEASON,
    GET_SEASON_FAILURE,
    GET_SEASON_REQUEST,
    GET_SEASON_SUCCESS,
    POST_SEASON_FAILURE,
    POST_SEASON_REQUEST,
    POST_SEASON_SUCCESS,
    PUT_SEASON_FAILURE,
    PUT_SEASON_REQUEST,
    PUT_SEASON_SUCCESS
} from "../actions/season";

const initialState = { seasons: [], loadingSeason: false, error: null };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SEASON_REQUEST:
            return {
                loadingSeason: true
            };
        case GET_SEASON_SUCCESS:
            return {
                ...state,
                loadingSeason: false,
                seasons: action.season,
            };
        case GET_SEASON_FAILURE:
            return {
                ...state,
                loadingSeason: false,
                error: action.error
            };
        case PUT_SEASON_REQUEST:
            return {
                loadingSeason: true,
            };
        case PUT_SEASON_SUCCESS:
            return {
                ...state,
                loadingSeason: false,
                seasonEdited: action.season
            }
        case PUT_SEASON_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case POST_SEASON_REQUEST:
            return {
                loadingSeason: true,
            };
        case POST_SEASON_SUCCESS:
            return {
                ...state,
                loadingSeason: false,
                seasonEdited: action.season
            }
        case POST_SEASON_FAILURE:
            return {
                ...state,
                loadingSeason: false,
                error: action.error
            };
        case CLEAR_SEASON:
            return initialState
        default:
            return state;
    }
}
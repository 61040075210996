import LocalStorage from "../../localStorage";
import axios from "axios";
import {loggedOut} from "../auth";
import {ACTION_REQUEST} from "../../../utils/constant";

export const POST_DOWNLOAD_EXCEL_REQUEST = 'POST_DOWNLOAD_EXCEL_REQUEST';
export const POST_DOWNLOAD_EXCEL_SUCCESS = 'POST_DOWNLOAD_EXCEL_SUCCESS';
export const POST_DOWNLOAD_EXCEL_FAILED = 'POST_DOWNLOAD_EXCEL_FAILED';
export const CLEAR_DOWNLOAD = "CLEAR_DOWNLOAD";

const localStorageService = LocalStorage.getService();

export const postDownloadExcelRequest = () => {
    return { type: POST_DOWNLOAD_EXCEL_REQUEST }
};

export const postDownloadExcelSuccess = (xlsxUrl, receiver, sentEmail) => {
    return { type: POST_DOWNLOAD_EXCEL_SUCCESS, xlsxUrl, receiver, sentEmail }
};

export const postDownloadExcelFailed = (error) => {
    return { type: POST_DOWNLOAD_EXCEL_FAILED, error }
};

export const clearDownload = () => {
    return { type: CLEAR_DOWNLOAD }
};

/**
 * This function is used to get search excel url to download it.
 *
 * @param body {Object}: {"Products/Assets": [
                                      "00b78fe540d32eae7c31f5c6ad59d9b8",
                                      "00ca8bd9a2f9edde8ad74647c0e70e03",
                                      "00cfaf67cfdb820cada54709ef22933c",
                                      "00e29a83410441b444f07292fffe600b",
                                      "016c5725b9e500e276a67969ca9791d1",
                                      "017f532c57bf56410c01a3e87548ae66",
                                      "01a18a3747918dfabd63de731569412a",
                                      "01c79ea876848b9197c8c8b36fbca199",
                                      "024e116180dff284d2cd75b40387f3fc"
                                    ],
                          "columns": [{
                            "key": "",
                            "name": ""
                          },
                          {
                            "key": "",
                            "name": ""
                          }]
                        }
 */
export const startPostDownloadExcel = (withImages, body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(postDownloadExcelRequest());
        console.log('Calling startPostDownloadExcel with:\ninclude images?', withImages, '\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/search_paginated/xlsx_export?with_images=${withImages}&key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(body)
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startPostDownloadExcel response: ', response.data);
                dispatch(postDownloadExcelSuccess(response.data.xlsx_url, response.data.receiver_mail, response.data.sent_mail));
            }
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postDownloadExcelFailed(error));
        })
    }
};


export const POST_DOWNLOAD_ZIP_REQUEST = 'POST_DOWNLOAD_ZIP_REQUEST';
export const POST_DOWNLOAD_ZIP_SUCCESS = 'POST_DOWNLOAD_ZIP_SUCCESS';
export const POST_DOWNLOAD_ZIP_FAILED = 'POST_DOWNLOAD_ZIP_FAILED';
export const CLEAR_DOWNLOAD_ZIP = 'CLEAR_DOWNLOAD_ZIP';

export const postDownloadZipRequest = () => {
    return { type: POST_DOWNLOAD_ZIP_REQUEST }
};

export const postDownloadZipSuccess = (zipUrl, receiver, sentEmail) => {
    return { type: POST_DOWNLOAD_ZIP_SUCCESS, zipUrl, receiver, sentEmail }
};

export const postDownloadZipFailed = (error) => {
    return { type: POST_DOWNLOAD_ZIP_FAILED, error }
};

export const clearDownloadZip = () => {
    return { type: CLEAR_DOWNLOAD_ZIP }
};

export const startPostDownloadZip = (body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(postDownloadZipRequest());
        console.log('Calling startPostDownloadZip with:\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/export_zip?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: ACTION_REQUEST.post,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(body)
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startPostDownloadZip response: ', response.data);
                dispatch(postDownloadZipSuccess(response.data.zip_url, response.data.receiver_mail, response.data.sent_mail));
            } /*else if (response.status === 202 && response.data !== null) {
                console.log('startPostDownloadZip response: ', response.data);
                dispatch(postDownloadZipSuccess(null, response.data.receiver, response.data.sent_email));
            }*/
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postDownloadZipFailed(error));
        })
    }
};

export const GET_DOWNLOAD_INFO_REQUEST = 'GET_DOWNLOAD_INFO_REQUEST';
export const GET_DOWNLOAD_INFO_SUCCESS = 'GET_DOWNLOAD_INFO_SUCCESS';
export const GET_DOWNLOAD_INFO_FAILED = 'GET_DOWNLOAD_INFO_FAILED';

export const getDownloadInfoRequest = () => {
    return { type: GET_DOWNLOAD_INFO_REQUEST }
};

export const getDownloadInfoSuccess = (fileMap) => {
    return { type: GET_DOWNLOAD_INFO_SUCCESS, fileMap }
};

export const getDownloadInfoFailed = (error) => {
    return { type: GET_DOWNLOAD_INFO_FAILED, error }
};

export const startGetDownloadInfo = (body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(getDownloadInfoRequest());
        console.log('Calling startGetDownloadInfo with:\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/getAssetsType?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: ACTION_REQUEST.post,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify({ assets: body })
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startGetDownloadInfo response: ', response.data);
                dispatch(getDownloadInfoSuccess(response.data.Results));
            }
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(getDownloadInfoFailed(error));
        })
    }
}
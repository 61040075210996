import axios from "axios";
import LocalStorage from "../../../localStorage";

const localStorageService = LocalStorage.getService();

export const CLEAR_GALLERIES = "CLEAR_GALLERIES";

export const GET_GALLERIES_REQUEST = "GET_GALLERIES_REQUEST";
export const GET_GALLERIES_SUCCESS = "GET_GALLERIES_SUCCESS";
export const GET_GALLERIES_FAILED = "GET_GALLERIES_FAILED";

export const clearGalleries = () => {
    return {type: CLEAR_GALLERIES}
};

export const getGalleriesRequest = () => {
    return {type: GET_GALLERIES_REQUEST}
};

export const getGalleriesSuccess = (galleries) => {
    return {type: GET_GALLERIES_SUCCESS, galleries}
};

export const getGalleriesFailed = (error) => {
    return {type: GET_GALLERIES_FAILED, error}
};

/**
 * Get list of galleries available in Product Showroom inside a specific context
 */
export const startGetProductShowroomGalleries = (contextId) => {
    let token = localStorageService.getAccessToken();

    console.log('Calling startGetProductShowroomGalleries with: contextId=', contextId);

    return(dispatch) => {
            dispatch(getGalleriesRequest());

            return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/pg/context/${contextId}/galleries?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                }
            })
                .then(function (response) {
                    if (response.status === 200 && response.data !== null) {
                        console.log('Response startGetProductShowroomGalleries: ', response.data);
                        dispatch(getGalleriesSuccess(response.data));
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log('Error startGetProductShowroomGalleries: ', error);
                    if (error.response.status === 400 || error.response.status === 500)
                        dispatch(getGalleriesFailed(error));
                });
    }
}

import React from "react";
import {useStyles} from "./SearchMultiLineInputModalStyle";
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonElement from "../../ButtonElement/ButtonElement";


const SearchMultiLineInputModal = props => {
    const classes = useStyles();
    const intl = useIntl();
    const {multipleInputDialog, closeMultipleInputDialog} = props;

    return (
        <Dialog open={multipleInputDialog.open}
                onClose={closeMultipleInputDialog}
                className={classes.rootDialog}>

            <DialogTitle style={{cursor: 'move'}}>
                {multipleInputDialog.title}
            </DialogTitle>

            <DialogContent className={classes.rootDialogContent}>
                {props.children}
            </DialogContent>

            <DialogActions>
                <ButtonElement label={intl.formatMessage({id: "search.dialog.close", defaultMessage: "Close"})}
                               onSubmit={closeMultipleInputDialog}/>
            </DialogActions>
        </Dialog>
    )
};

export default SearchMultiLineInputModal;
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useIntl} from "react-intl";
import LocalStorage from "../../store/localStorage";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GalleryTabContainer from "./TabViewContainer/GalleryTabContainer";
import ManageGalleryTabContainer from "./TabViewContainer/ManageGalleryTabContainer";
import ReusableModal from "../../components/Modal/ReusableModal/ReusableModal";
import MenuTabsElement from "../../components/MenuTabsElement/MenuTabsElement";
import SnackbarElement from "../../components/SnackbarElement/SnackbarElement";
import BackdropElement from "../../components/BackdropElement/BackdropElement";
import {
    clearProductShowroomGallery,
    startGetProductsShowroomGallery
} from "../../store/actions/showroom/product/gallery";
import {
    clearExcelInfos,
    clearGallerySortingAction,
    startPostSwitchGalleryOrder
} from "../../store/actions/showroom/product/galleryActions";
import {clearProducts} from "../../store/actions/showroom/product/products";
import {clearProduct, startGetProduct} from "../../store/actions/showroom/product/product";
import {onErrorSrc} from "../../utils/CommonFunction";
import {
    ACTION_REQUEST,
    PRODUCT_SHOWROOM_ACTION_REQUEST,
    SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM,
    SNACKBAR_LEVEL
} from "../../utils/constant";
import {clearProductsGalleryLookcastExport} from "../../store/actions/showroom/product/exportToLookcast";
import SwitchElement from "../../components/SwitchElement/SwitchElement";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import PreserveImageRatio from "../../components/PreserveImageRatio/PreserveImageRatio";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(12)
    },
    boldText: {
        fontWeight: 600,
        marginRight: theme.spacing(1)
    },
    keyValueDetails: {
        display: 'flex'
    }
}));


const ProductShowroomGalleryContainer = props => {
    const classes = useStyles();
    const intl = useIntl();
    const { genericStyle } = props;

    const [backdropState, setBackdropState] = useState({ loading: false, label: '' });
    const [snackBarStatus, setSnackBarStatus] = useState({
        show: false,
        message: '',
        type: '',
        messageAbout: '',
        values: null,
        showDuration: 7200000
    });
    const [viewTypeState, setViewTypeState] = useState(0);
    const [showAllProducts, setShowAllProducts] = useState(true);
    const [pendingChangesState, setPendingChangesState] = useState(false);
    const [warningPendingChangesModalState, setWarningPendingChangesModalState] = useState({
        open: false,
        title: intl.formatMessage({ id: 'common.warning', defaultMessage: 'Warning!!!' }),
        message: intl.formatMessage({
            id: 'showroom_product.warning.changing.not.save',
            defaultMessage: 'There are some changes not save. Are you sure to leave the tab without saving?'
        }),
        destination: null
    });
    const [productDetailsModalState, setProductDetailsModalState] = useState({
        open: false,
        big: true,
        title: intl.formatMessage({
            id: 'showroom_product.title.modal.product.details',
            defaultMessage: 'Product Details'
        }),
        keysValue: [],
        imgUrl: null
    });
    const [productsPerRowState, setProductsPerRowState] = useState(8);

    /**
     * First side effect used to clear redux status and getting products gallery.
     */
    useEffect(() => {
        // To prevent eventually side effect if a new gallery will be created
        if (!_.isEmpty(props.sortingAction.macroAreas)) {
            props.clearGalleryActions();
        }

        // Getting gallery from localstorage
        const gallery = LocalStorage.getProductGallery();
        props.getProductsGallery(gallery.id);

        // willUnmount
        return () => {
            props.clearGallery();
            props.clearProductDetails();
            props.clearProducts();
            props.clearGalleryActions();
            props.clearProductGalleryLookcastExport();
            props.clearExcelUrl();
        }
    }, []);

    /* ****************************************** HANDLE TABS VIEW ************************************************** */
    const menuTabs = [
        { translateKey: 'showroom_product.tab.gallery', default: 'Gallery' },
        { translateKey: 'showroom_product.tab.manage.gallery', default: 'Manage Gallery' }
    ];

    const viewTabsHandler = (event, newValue) => {
        if (pendingChangesState) {
            setWarningPendingChangesModalState(prevState => ({
                ...prevState,
                open: true,
                destination: newValue
            }));
        } else {
            setViewTypeState(newValue);
        }
    };

    const confirmTabChangeWithoutSavingHandler = () => {
        setViewTypeState(warningPendingChangesModalState.destination);
        setWarningPendingChangesModalState(prevState => ({
            ...prevState,
            open: false,
            destination: null
        }));
        setPendingChangesState(false);
    };
    /* ************************************************************************************************************** */

    /* ******************************************** PRODUCT DETAIL ************************************************** */
    /**
     * This function is used to fetch product detail.
     * @param event
     * @param productGuid {string}: product identifier.
     */
    const getProductDetails = (event, productGuid) => {
        props.getProductDetails(props.galleryInfo.gallery.id, productGuid);
    };

    /**
     * This effect is used to handle the response of backend about product details.
     */
    useEffect(() => {
        if (props.productDetail.action === ACTION_REQUEST.get) {
            if (props.productDetail.loading) {
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.productDetail.error) {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.fetching.product.details',
                            defaultMessage: 'Cannot fetch product details.'
                        }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getProductDetails,
                        type: SNACKBAR_LEVEL.error,
                        showDuration: 5000
                    });
                } else {
                    const product = _.cloneDeep(props.productDetail.product)

                    setProductDetailsModalState(prevState => ({
                        ...prevState,
                        open: true,
                        keysValue: [
                            {
                                key: 'gender',
                                value: product.gender,
                                translateKey: 'showroom_product.key.product.details.gender'
                            },
                            {
                                key: 'brand',
                                value: product.brand,
                                translateKey: 'showroom_product.key.product.details.brand'
                            },
                            {
                                key: 'styleFabricColor',
                                value: product.style_fabric_color,
                                translateKey: 'showroom_product.key.product.details.styleFabricColor'
                            },
                            {
                                key: 'productCodeDraft',
                                value: product.product_code_draft,
                                translateKey: 'showroom_product.key.product.details.productCodeDraft'
                            },
                            {
                                key: 'productTypeDraft',
                                value: product.product_type_draft,
                                translateKey: 'showroom_product.key.product.details.productTypeDraft'
                            },
                            {
                                key: 'familyName',
                                value: product.family_name,
                                translateKey: 'showroom_product.key.product.details.familyName'
                            },
                            {
                                key: 'lineList',
                                value: product.line_list,
                                translateKey: 'showroom_product.key.product.details.lineList'
                            },
                            {
                                key: 'event',
                                value: product.event,
                                translateKey: 'showroom_product.key.product.details.event'
                            },
                            {
                                key: 'delivery',
                                value: product.delivery,
                                translateKey: 'showroom_product.key.product.details.delivery'
                            },
                            {
                                key: 'multipleDelivery',
                                value: product.multiple_delivery,
                                translateKey: 'showroom_product.key.product.details.multipleDelivery'
                            },
                        ],
                        imgUrl: product.img_url ? product.img_url : 'no foto'
                    }));
                    setBackdropState((prevState => ({
                        ...prevState,
                        loading: false
                    })));
                }
            }
        }
    }, [props.productDetail]);
    /* ************************************************************************************************************** */

    /* ****************************************** HANDLER SNACKBAR ************************************************** */
    /**
     * This effect is used to populate products gallery handling error .
     */
    useEffect(() => {
        if (props.galleryInfo.action === ACTION_REQUEST.get) {
            if (props.galleryInfo.loading) {
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.galleryInfo.error) {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.fetch.gallery',
                            defaultMessage: 'Failed fetching gallery.'
                        }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getProductsGallery,
                        type: SNACKBAR_LEVEL.error,
                        showDuration: 5000
                    });
                }
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: false
                })));
            }
        }
    }, [props.galleryInfo]);


    /**
     * Handler to close snackbar message and clear redux state to prevent the rendering of the same message.
     */
    const closeSnackbar = () => {
        switch (snackBarStatus.messageAbout) {
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getProductsGallery:
                props.clearGallery();
                break;
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getProductDetails:
                props.clearProductDetails();
                break;
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.exportProductGallery:
                props.clearProductGalleryLookcastExport();
                break;
            case SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.getExcelUrl:
                props.clearExcelUrl();
                break;
            default:
                break;
        }

        setSnackBarStatus({
            show: false,
            message: '',
            type: '',
            messageAbout: '',
            values: null,
            showDuration: 7200000
        });
    };
    /* ************************************************************************************************************** */

    /* ******************************************** SWITCHING ORDER ************************************************* */
    /**
     * This effect is used to handle the sorting switcher order of gallery response.
     */
    useEffect(() => {
        if (props.sortingAction.action === PRODUCT_SHOWROOM_ACTION_REQUEST.postSwitchGalleryOrder) {
            if (props.sortingAction.loading) {
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.sortingAction.error) {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.key.error.switch.gallery.order',
                            defaultMessage: 'Cannot change gallery order.'
                        }),
                        messageAbout: SNACKBAR_ACTIONS_PRODUCTS_SHOWROOM.switchSortingGallery,
                        type: SNACKBAR_LEVEL.error,
                        showDuration: 5000
                    });
                } else {
                    props.getProductsGallery(props.galleryInfo.gallery.id);
                }
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: false
                })));
            }
        }
    }, [props.sortingAction]);
    /* ************************************************************************************************************** */

    /**
     * This function is used to set the number of products show into manage gallery tab.
     * @param event
     * @param newProductsPerRow {number}: number of products show into manage gallery tab.
     */
    const productsPerRowHandleChange = (event, newProductsPerRow) => {
        setProductsPerRowState(newProductsPerRow);
    };


    /**
     * This effect is used to notify the result of saving products added/removed from gallery.
     */
    useEffect(() => {
        if (props.productsInfo.action === ACTION_REQUEST.put) {
            if (props.productsInfo.loading) {
                setBackdropState((prevState => ({
                    ...prevState,
                    loading: true
                })));
            } else {
                if (props.productsInfo.error) {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.error.fetch.products',
                            defaultMessage: 'Failed fetching products.'
                        }),
                        type: SNACKBAR_LEVEL.error,
                        showDuration: 5000
                    });
                } else {
                    setSnackBarStatus({
                        show: true,
                        message: intl.formatMessage({
                            id: 'showroom_product.success.fetch.products',
                            defaultMessage: 'Products updated.'
                        }),
                        type: SNACKBAR_LEVEL.success,
                        showDuration: 5000
                    });
                    setPendingChangesState(false);
                    props.getProductsGallery(props.galleryInfo.gallery.id);
                }

                setBackdropState((prevState => ({
                    ...prevState,
                    loading: false
                })));
            }
        }
    }, [props.productsInfo]);

    return (
        <div className={classes.root}>
            <BackdropElement loading={backdropState.loading} label={backdropState.label}/>
            <Grid container
                  direction={"row"}
                  justify={"flex-start"}
                  alignItems={"center"}>
                <Grid item xs={12}>
                    <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                        <Grid item>
                            <MenuTabsElement
                                value={viewTypeState}
                                onChangeHandler={viewTabsHandler}
                                tabs={menuTabs}/>
                        </Grid>
                        <Grid item>
                            <Grid container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center"
                                  spacing={3}>
                                <Grid item>
                                    {
                                        viewTypeState === 1 &&
                                        (props.galleryInfo.gallery.is_manual_order === false || props.galleryInfo.gallery.is_manual_order) &&
                                        <SwitchElement label={
                                            props.galleryInfo.gallery.is_manual_order ?
                                                {
                                                    position: 'end',
                                                    translateKey: 'showroom_product.key.switch.label.order.manual',
                                                    defaultText: 'Manual Order'
                                                } :
                                                {
                                                    position: 'start',
                                                    translateKey: 'showroom_product.key.switch.label.order.automatic',
                                                    defaultText: 'Automatic Order'
                                                }}
                                                       onChange={() => props.switchGalleryOrder(props.galleryInfo.gallery.id)}
                                                       checked={props.galleryInfo.gallery.is_manual_order}/>
                                    }
                                    <SwitchElement label={
                                        showAllProducts ?
                                        {
                                            position: 'start',
                                            translateKey: 'showroom_product.key.switch.label.show.all',
                                            defaultText: 'Show All'
                                        } :
                                        {
                                            position: 'start',
                                            translateKey: 'showroom_product.key.switch.label.show.not_deleted',
                                            defaultText: 'Hide Deleted'
                                        }}
                                        onChange={() => setShowAllProducts(!showAllProducts)}
                                        checked={showAllProducts}
                                    />
                                </Grid>
                                <Grid item>
                                    {
                                        viewTypeState === 1 &&
                                        <ToggleButtonGroup size={'small'} value={productsPerRowState} exclusive
                                                           onChange={productsPerRowHandleChange}>
                                            <ToggleButton value={4}>
                                                <ViewListIcon/>
                                            </ToggleButton>
                                            <ToggleButton value={8}>
                                                <ViewModuleIcon/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container
                  direction={"row"}
                  justify={"center"}
                  alignItems={"flex-start"}>
                {
                    viewTypeState === 0 &&
                    props.galleryInfo.products &&
                    <Grid item xs={12}>
                        <GalleryTabContainer changesState={pendingChangesState}
                                             setChangesState={setPendingChangesState}
                                             setBackdrop={setBackdropState}
                                             setSnackbar={setSnackBarStatus}
                                             getProductDetails={getProductDetails}
                                             genericStyle={genericStyle}
                                             showDeleted={showAllProducts} />
                    </Grid>
                }
                {
                    viewTypeState === 1 &&
                    props.galleryInfo.products &&
                    <Grid item xs={12}>
                        <ManageGalleryTabContainer changesState={pendingChangesState}
                                                   setBackdrop={setBackdropState}
                                                   setSnackbar={setSnackBarStatus}
                                                   setChangesState={setPendingChangesState}
                                                   productsPerRow={productsPerRowState}
                                                   getProductDetails={getProductDetails}
                                                   genericStyle={genericStyle}
                                                   showDeleted={showAllProducts} />
                    </Grid>
                }
            </Grid>
            {
                warningPendingChangesModalState.open &&
                <ReusableModal reusableModal={warningPendingChangesModalState}
                               greenButtonClick={() => confirmTabChangeWithoutSavingHandler()}
                               greenButtonText={intl.formatMessage({ id: 'common.confirm', defaultMessage: 'Confirm' })}
                               closeReusableModal={() => setWarningPendingChangesModalState((prevState => ({
                                   ...prevState,
                                   open: false,
                                   destination: null
                               })))}>
                    <Typography variant={"body1"}>
                        {warningPendingChangesModalState.message}
                    </Typography>
                </ReusableModal>
            }
            {
                productDetailsModalState.open &&
                <ReusableModal reusableModal={productDetailsModalState}
                               closeReusableModal={() => setProductDetailsModalState((prevState => ({
                                   ...prevState,
                                   open: false,
                                   keysValue: [],
                                   imgUrl: null
                               })))}>
                    <Grid container
                          direction={"row"}
                          justify="center"
                          alignItems="flex-start">
                        <Grid item xs={6}>
                            <PreserveImageRatio
                                 src={productDetailsModalState.imgUrl}
                                 alt={"Image not found"}
                                 height={560}
                                 width={400}
                                 onError={(event => onErrorSrc(event))}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container
                                  direction={"row"}
                                  justify="center"
                                  alignItems="flex-start"
                                  spacing={1}>
                                {productDetailsModalState.keysValue.map(keyValue => (
                                    <Grid item xs={12} className={classes.keyValueDetails} key={keyValue.key}>
                                        <Typography variant={"body1"} className={classes.boldText}>
                                            {intl.formatMessage({
                                                id: keyValue.translateKey,
                                                defaultMessage: keyValue.key
                                            })}:
                                        </Typography>
                                        <Typography variant={"body1"}>
                                            {keyValue.value}
                                        </Typography>

                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </ReusableModal>
            }
            {snackBarStatus.show &&
            <SnackbarElement open={snackBarStatus.show}
                             type={snackBarStatus.type}
                             label={snackBarStatus.message}
                             handleClose={() => closeSnackbar()}
                             showDuration={snackBarStatus.showDuration}
            />}
        </div>
    )
};


const mapStateToProps = state => ({
    sortingAction: state.galleryActionsProductShowroomReducer,
    galleryInfo: state.galleryProductShowroomReducer,
    productsInfo: state.productsGalleryProductShowroomReducer,
    productDetail: state.productGalleryProductShowroomReducer
});


const mapDispatchToProps = dispatch => ({
    clearGalleryActions: () => dispatch(clearGallerySortingAction()),
    clearGallery: () => dispatch(clearProductShowroomGallery()),
    clearProducts: () => dispatch(clearProducts()),
    getProductsGallery: (galleryId) => dispatch(startGetProductsShowroomGallery(galleryId)),
    getProductDetails: (galleryId, productGuid) => dispatch(startGetProduct(galleryId, productGuid)),
    clearProductDetails: () => dispatch(clearProduct()),
    clearProductGalleryLookcastExport: () => dispatch(clearProductsGalleryLookcastExport()),
    switchGalleryOrder: (galleryId) => dispatch(startPostSwitchGalleryOrder(galleryId)),
    clearExcelUrl: () => dispatch(clearExcelInfos())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProductShowroomGalleryContainer));
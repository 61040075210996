import {GET_TRANSACTIONS_FAILURE, GET_TRANSACTIONS_REQUEST, GET_TRANSACTIONS_SUCCESS,
        GET_TRANSACTIONS_EXCEL_FAILURE, GET_TRANSACTIONS_EXCEL_REQUEST, GET_TRANSACTIONS_EXCEL_SUCCESS,
        GET_TRANSACTIONS_CSV_FAILURE, GET_TRANSACTIONS_CSV_REQUEST, GET_TRANSACTIONS_CSV_SUCCESS
} from "../actions/transactions";

const initialState = {transactions: [], transactionsExcel: [], transactionsCSV: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TRANSACTIONS_REQUEST:
            return {
                loadingTransactions: true
            };
        case GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loadingTransactions: false,
                transactions: action.transactions,
            };
        case GET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                loadingTransactions: false,
                error: action.error
            };
        case GET_TRANSACTIONS_EXCEL_REQUEST:
            return {
                ...state,
                loadingTransactionsExcel: true,
                excelError: false
            };
        case GET_TRANSACTIONS_EXCEL_SUCCESS:
            return {
                ...state,
                loadingTransactionsExcel: false,
                transactionsExcel: action.transactionsExcel["url"],
            };
        case GET_TRANSACTIONS_EXCEL_FAILURE:
            return {
                ...state,
                loadingTransactionsExcel: false,
                excelError: action.excelError
            };
        case GET_TRANSACTIONS_CSV_REQUEST:
            return {
                ...state,
                loadingTransactionsCSV: true,
                csvError: false
            };
        case GET_TRANSACTIONS_CSV_SUCCESS:
            return {
                ...state,
                loadingTransactionsCSV: false,
                transactionsCSV: action.transactionsCsv["url"]
            };
        case GET_TRANSACTIONS_CSV_FAILURE:
            return {
                ...state,
                loadingTransactionsCSV: false,
                csvError: action.csvError
            }
        default:
            return state;
    }
}
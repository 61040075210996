import React, { useEffect, useState } from "react";
import placeholder from "../../assets/images/placeholder.jpg";
import videoPlaceholder from "../../assets/images/placeholder_video.jpg";

const PreserveImageRatio = (props) => {
    const [imageStatus, setImageStatus] = useState({
        loaded: false,
        exists: false
    });

    useEffect(() => {
        let image = new Image();
        
        image.onload = () => {
            setImageStatus({ loaded: true, exists: true });
        }

        image.onerror = () => {
            setImageStatus({ loaded: true, exists: false });
        }
        
        image.src = props.src;
    }, [props.src])

    return (
        <div onClick={props.onClick} className={props.className} style={{
            height: props.height,
            width: props.width,
            overflow: 'hidden',
            backgroundColor: '#000',
            marginBottom: 4
         }}>
            {imageStatus.loaded &&
                <div
                    style={{
                        height: props.height,
                        width: props.width,
                        backgroundImage: `url('${(imageStatus.exists ? props.src : (props.isVideo ? videoPlaceholder : placeholder))}')`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
            }
        </div>
    )
}

export default PreserveImageRatio;
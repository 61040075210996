import axios from "axios";
import LocalStorage from "../localStorage";
import {loggedOut} from "./auth";

export const GET_LINELIST_REQUEST = 'GET_LINELIST_REQUEST';
export const GET_LINELIST_SUCCESS = 'GET_LINELIST_SUCCESS';
export const GET_LINELIST_FAILURE = 'GET_LINELIST_FAILURE';

const localStorageService = LocalStorage.getService();

export const getLinelistRequest = () => {
    return {type: GET_LINELIST_REQUEST}
};

export const getLinelistSuccess = (pg_linelist = {}) => {
    return {type: GET_LINELIST_SUCCESS, pg_linelist}
};

export const getLinelistFailure = (error) => {
    return {type: GET_LINELIST_FAILURE, error}
};

export const startGetLinelist = () => {
    console.log('Start startGetLinelist');
    let token = localStorageService.getAccessToken();
    return (dispatch) => {
        dispatch(getLinelistRequest());
        return axios({
                url: `${process.env.REACT_APP_API_ROOTURL}/linelist/list?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    Authorization: 'Bearer ' + token,
                },
                data: JSON.stringify({}),
            }
        )
            .then(function (response) {
                if (response.status === 200 && response.data !== null) {
                    console.log("Response linelist search ", response.data);
                    dispatch(getLinelistSuccess(response.data));

                }
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 401) dispatch(loggedOut());
                dispatch(getLinelistFailure(error));
            });
    };
};

import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import { Paper } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import SearchFieldRow from "./SearchFieldRow/SearchFieldRow";
import { useStyles } from "./SearchFieldTableStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButtonElement from "../../IconButtonElement/IconButtonElement";

const SearchFieldTable = (props) => {
	const {
		rowsRaw,
		deleteContextHandler,
		deleteAllContextHandler,
		onChangeOperator,
		searchCellChangeHandler,
		options,
		loadings,
		callOptionList,
		favDetails,
		title,
		deleteLabel,
		renameLabel,
		deleteAction,
		renameAction,
		renameTitle,
	} = props;
	const classes = useStyles();

	const rowsComponent = rowsRaw.map((rowRaw, index) => {
		//console.log('Row raw: ', rowRaw);
		//console.log('Row value: ', rowRaw.value);
		console.log("favDetails: ", favDetails);
		return (
			<SearchFieldRow
				key={index}
				rowData={rowRaw}
				rowIndex={index}
				value={rowRaw["value"]}
				deleteContextHandler={deleteContextHandler}
				operatorHandler={onChangeOperator}
				searchCellChangeHandler={searchCellChangeHandler}
				options={options}
				callOptionList={callOptionList}
				loadings={loadings}
			/>
		);
	});

    let favIcons;
    if (favDetails && Object.entries(favDetails).length !== 0) {
        favIcons=<span>
        <IconButtonElement
            // fontSize="small"
            labelId={renameLabel.labelId}
            label={renameLabel.label}
            icon={"edit"}
            onClick={(title, name, id) =>
                renameAction(
                    renameTitle,
                    favDetails.favourite_name,
                    favDetails.guid
                )
            }
        />
        <IconButtonElement
            fontSize="small"
            labelId={deleteLabel.labelId}
            label={deleteLabel.label}
            icon={"delete"}
            onClick={(id) => deleteAction(favDetails.guid)}
        />
    </span>
    } else {
        favIcons=null
    }
	return (
		<TableContainer component={Paper} className={classes.root}>
			<Table size="small">
				<TableHead>
					<TableRow className={classes.headerRow}>
						<TableCell colSpan={2} className={classes.headerCell}>
							{title}
						</TableCell>
						<TableCell align="right" colSpan={2} className={classes.headerCell}>
							{favIcons}
							<IconButtonElement
								labelId={"search.tooltip.delete.all.filters"}
								label={"Delete All Filters"}
								icon={"close"}
								onClick={deleteAllContextHandler}
							/>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className={classes.tableBody}>{rowsComponent}</TableBody>
			</Table>
		</TableContainer>
	);
};

export default SearchFieldTable;

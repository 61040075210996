import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    drawerRoot: {
        "& .MuiDrawer-paperAnchorBottom": {
            backgroundColor: theme.palette.background.default
        },
        "& .MuiDivider-root": {
            backgroundColor: theme.palette.background.default
        }
    },
    drawerPaper: {
        backgroundColor: theme.palette.background.default
    },
    itemCenter: {
        textAlign: 'center',
        backgroundColor: theme.palette.background.default
    },
    movieFrameContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center'
    },
    movieIcon: {
        position: 'absolute',
        bottom: 0,    
        marginLeft: -360,
        marginBottom: 80,
        color: theme.palette.button.deactive
    },
    draftImgStyle: {
        border: '5px solid '.concat(theme.palette.isDraft.main),
        position: "relative",
        boxSizing: "border-box"
    },
    approvedLookImageStyle: {
        border: '5px solid '.concat(theme.palette.button.edit),
        position: "relative",
        boxSizing: "border-box"
    },
    captionStyle: {
        backgroundColor: theme.palette.background.main,
        color: theme.palette.text.main,
        margin: '0 auto',
        padding: '4px 0',
        borderRadius: 4,
        width: 400
    }
}));
import {GET_BRAND_FAILURE, GET_BRAND_REQUEST, GET_BRAND_SUCCESS} from "../actions/brand";

const initialState = {brand: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BRAND_REQUEST:
            return {
                loadingBrand: true
            };
        case GET_BRAND_SUCCESS:
            return {
                ...state,
                loadingBrand: false,
                brand: action.brand,
            };
        case GET_BRAND_FAILURE:
            return {
                ...state,
                loadingBrand: false,
                error: action.error
            };
        default:
            return state;
    }
}
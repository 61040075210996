import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "./PostProductionItemStyle";
import Card from "@material-ui/core/Card";
import {backgroundColorUsingStatus, onErrorSrc} from "../../../../utils/CommonFunction";
import CreateIcon from '@material-ui/icons/Create';
import PreserveImageRatio from "../../../PreserveImageRatio/PreserveImageRatio";

const PostProductionItem = props => {
    const classes = useStyles();
    const { items, onClickHandler, genericStyle } = props;
    const [elements, setElements] = useState();


    useEffect(() => {
        const gridElements = items.map((item, index) => {
            const statusCssClass = backgroundColorUsingStatus(item.status, genericStyle);

            // console.log('Notes: ', item.notes);

            return (
                <Grid item xs={1}
                      key={index}>
                    <Grid container
                          direction="column"
                          justify="center"
                          alignItems="center">
                        <Grid item xs={12}>
                            <div className={classes.textBox}>
                                <Typography>{item.asset_name.slice(0, 9)}...</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.paperImage}>
                            <Paper elevation={0} square>
                                {item.notes &&
                                <CreateIcon/>
                                }
                                <PreserveImageRatio
                                     src={item.asset_img_url}
                                     alt={"Image not found"}
                                     height={140}
                                     width={100}
                                     onClick={(event) => onClickHandler(event, item.index)}
                                     onError={(event) => onErrorSrc(event)}/>
                            </Paper>
                            <div className={`${classes.textBox} ${statusCssClass}`}>
                                <Typography>{item.view.length < 9 ? item.view : item.view.slice(0, 9).concat("...")}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )
        });
        setElements(gridElements);
    }, [items]);


    return (
        <Card raised>
            <Grid container
                  direction="row"
                  spacing={2}>
                {elements}
            </Grid>
        </Card>
    )
};

export default PostProductionItem;
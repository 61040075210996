import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.main,
        '&:hover': {
            color: theme.palette.focus.main,
            backgroundColor: theme.palette.primary.main,
        },
    },
    delete: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.background.hover
        },
    },
    approve: {
        color: theme.palette.button.edit,
        '&:hover': {
            color: theme.palette.background.hover
        },
    }
}));
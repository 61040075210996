import LocalStorage from "../../localStorage";
import axios from "axios";
import {loggedOut} from "../auth";
import {ACTION_REQUEST} from "../../../utils/constant";

export const POST_COUNT_REQUEST = 'POST_COUNT_REQUEST';
export const POST_SEARCH_REQUEST = 'POST_SEARCH_PRODUCT_REQUEST';
export const POST_SEARCH_ASSETS_REQUEST_SUCCESS = 'POST_SEARCH_ASSETS_REQUEST_SUCCESS';
export const POST_SEARCH_PRODUCTS_REQUEST_SUCCESS = 'POST_SEARCH_PRODUCTS_REQUEST_SUCCESS';
export const POST_SEARCH_REQUEST_FAILED = 'POST_SEARCH_REQUEST_FAILED';
export const CLEAR_RESPONSE_SEARCH = "CLEAR_RESPONSE_SEARCH";
export const CLEAR_SEARCH_FLAG = "CLEAR_SEARCH_FLAG";

const localStorageService = LocalStorage.getService();

export const postSearchRequest = () => {
    return { type: POST_SEARCH_REQUEST }
};

export const postCountRequest = () => {
    return { type: POST_COUNT_REQUEST }
}

export const postSearchAssetsRequestSuccess = (assets) => {
    return { type: POST_SEARCH_ASSETS_REQUEST_SUCCESS, assets }
};

export const postSearchProductsRequestSuccess = (products) => {
    return { type: POST_SEARCH_PRODUCTS_REQUEST_SUCCESS, products }
};

export const postSearchRequestFailed = (error) => {
    return { type: POST_SEARCH_REQUEST_FAILED, error }
};

export const clearResponseSearch = () => {
    return { type: CLEAR_RESPONSE_SEARCH }
}

export const clearSearchFlag = () => {
    return { type: CLEAR_SEARCH_FLAG }
}

/**
 * This function is used to invoke search api and get a result of products or assets list. It depends by user action
 * (which button has been clicked) and then by the tabs that user is viewing.
 *
 * @param body {Object}: {"filter_list": [{ "search_type_guid":"7",
 *                                          "name":"export_date",
 *                                          "search_operator_guid":"5",
 *                                          "and_or":"-",
 *                                          "value": {
 *                                              "date_start":"1-11-2020",
 *                                              "date_end":"30-11-2020"
 *                                              }
 *                                          }],
 *                        "asset": true,
 *                        "order": [{
 *                                      "name": "style",
 *                                      "order_type": "ASC"
 *                                    },
 *                                    {
 *                                      "name": "fabric",
 *                                      "order_type": "DESC"
 *                                     }
 *                                   ],
 *                         "filters_table": [{
 *                                              "name": "column_name",
 *                                              "value": "selected_value"
 *                                             }]
 *                         }
 */
export const startPostSearchRequest = (body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(postSearchRequest());
        console.log('Calling startPostSearchRequest with:\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/search_paginated?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: ACTION_REQUEST.post,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify(body)
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startPostSearchRequest response: ', response.data);
                if (body.asset) {
                    dispatch(postSearchAssetsRequestSuccess(response.data.Assets));
                } else {
                    dispatch(postSearchProductsRequestSuccess(response.data.Products));
                }

            }
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postSearchRequestFailed(error));
        })
    }
};


export const POST_SEARCH_COUNT_SUCCESS = 'POST_SEARCH_COUNT_SUCCESS';

export const postSearchCountSuccess = (assetsCount, productsCount) => {
    return { type: POST_SEARCH_COUNT_SUCCESS, assetsCount, productsCount }
};

/**
 * This function is used to invoke search api and get a result of products or assets list. It depends by user action
 * (which button has been clicked) and then by the tabs that user is viewing.
 *
 * @param body {Object}: {"filter_list": [{ "search_type_guid":"7",
 *                                          "name":"export_date",
 *                                          "search_operator_guid":"5",
 *                                          "and_or":"-",
 *                                          "value": {
 *                                              "date_start":"1-11-2020",
 *                                              "date_end":"30-11-2020"
 *                                              }
 *                                          }],
 *                        "asset": true,
 *                        "order": [{
 *                                      "name": "style",
 *                                      "order_type": "ASC"
 *                                    },
 *                                    {
 *                                      "name": "fabric",
 *                                      "order_type": "DESC"
 *                                     }
 *                                   ],
 *                         "filters_table": [{
 *                                              "name": "column_name",
 *                                              "value": "selected_value"
 *                                             }]
 *                         }
 */
export const startPostSearchWithCountRequest = (body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(postSearchRequest());
        dispatch(postCountRequest());
        dispatch(startPostSearchRequest(body));

        console.log('Calling startPostSearchWithCountRequest with:\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/search_paginated/count?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: ACTION_REQUEST.post,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify({ filter_list: body.filter_list })
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startPostSearchWithCountRequest response: ', response.data);
                dispatch(postSearchCountSuccess(response.data.asset_count, response.data.product_count));
            }
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postSearchRequestFailed(error));
        })
    }
};


export const POST_SEARCH_FILTER_TABLE_COUNT_SUCCESS = 'POST_SEARCH_FILTER_TABLE_COUNT_SUCCESS';
export const CLEAR_FILTER_TABLE_COUNT = 'CLEAR_FILTER_TABLE_COUNT';

export const postSearchFilterTableCountSuccess = (count) => {
    return { type: POST_SEARCH_FILTER_TABLE_COUNT_SUCCESS, count }
};

export const clearFilterTableCount = () => {
    return { type: CLEAR_FILTER_TABLE_COUNT }
};

/**
 * This function is used to invoke search api and get a result of products or assets list with a count of assets or
 * products. It depends by user action (which button has been clicked) and then by the tabs that user is viewing.
 *
 * @param body {Object}: {"filter_list": [{ "search_type_guid":"7",
 *                                          "name":"export_date",
 *                                          "search_operator_guid":"5",
 *                                          "and_or":"-",
 *                                          "value": {
 *                                              "date_start":"1-11-2020",
 *                                              "date_end":"30-11-2020"
 *                                              }
 *                                          }],
 *                        "asset": true,
 *                        "order": [{
 *                                      "name": "style",
 *                                      "order_type": "ASC"
 *                                    },
 *                                    {
 *                                      "name": "fabric",
 *                                      "order_type": "DESC"
 *                                     }
 *                                   ],
 *                         "filters_table": [{
 *                                              "name": "column_name",
 *                                              "value": "selected_value"
 *                                             }]
 *                         }
 */
export const startPostSearchWithFilterTableCountRequest = (body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(postSearchRequest());
        dispatch(postCountRequest());
        dispatch(startPostSearchRequest(body));

        console.log('Calling startPostSearchWithFilterTableCountRequest with:\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/search_paginated/filter_count?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: ACTION_REQUEST.post,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify({
                filter_list: body.filter_list,
                filters_table: body.filters_table,
                asset: body.asset
            })
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startPostSearchWithFilterTableCountRequest response: ', response.data);
                dispatch(postSearchFilterTableCountSuccess(response.data.count));
            }
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postSearchRequestFailed(error));
        })
    }
};


/**
 * This function is used to invoke search api and get a result of products or assets list with a count of assets or
 * products and the count with filter table applied. It depends by user action (which button has been clicked) and then
 * by the tabs that user is viewing.
 *
 * @param body {Object}: {"filter_list": [{ "search_type_guid":"7",
 *                                          "name":"export_date",
 *                                          "search_operator_guid":"5",
 *                                          "and_or":"-",
 *                                          "value": {
 *                                              "date_start":"1-11-2020",
 *                                              "date_end":"30-11-2020"
 *                                              }
 *                                          }],
 *                        "asset": true,
 *                        "order": [{
 *                                      "name": "style",
 *                                      "order_type": "ASC"
 *                                    },
 *                                    {
 *                                      "name": "fabric",
 *                                      "order_type": "DESC"
 *                                     }
 *                                   ],
 *                         "filters_table": [{
 *                                              "name": "column_name",
 *                                              "value": "selected_value"
 *                                             }]
 *                         }
 */
export const startPostSearchWithCountsRequest = (body) => {
    let token = localStorageService.getAccessToken();

    return (dispatch) => {
        dispatch(postSearchRequest());
        console.log('Calling startPostSearchWithCountsRequest with:\n- body', body);
        return axios({
            url: `${process.env.REACT_APP_API_ROOTURL}/search_paginated/filter_count?key=${process.env.REACT_APP_SERVICE_ACCOUNT_KEY}`,
            method: ACTION_REQUEST.post,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                Authorization: 'Bearer ' + token
            },
            data: JSON.stringify({
                filter_list: body.filter_list,
                filters_table: body.filters_table,
                asset: body.asset
            })
        }).then(response => {
            if (response.status === 200 && response.data !== null) {
                console.log('startPostSearchWithCountsRequest response: ', response.data);
                dispatch(postSearchFilterTableCountSuccess(response.data.count));
                dispatch(startPostSearchWithCountRequest(body));
            }
        }).catch(error => {
            if (error.response.status === 401) dispatch(loggedOut());
            dispatch(postSearchRequestFailed(error));
        })
    }
};

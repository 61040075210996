import {
    CLEAR_GALLERY_DOWNLOAD_INFO,
    GET_EXCEL_URL_FAILURE,
    GET_EXCEL_URL_REQUEST,
    GET_EXCEL_URL_SUCCESS,
    GET_EXCEL_GALLERIES_FAILURE,
    GET_EXCEL_GALLERIES_REQUEST,
    GET_EXCEL_GALLERIES_SUCCESS,
    GALLERY_ASSETS_DOWNLOAD_FAILURE,
    GALLERY_ASSETS_DOWNLOAD_SUCCESS,
    GALLERY_ASSETS_DOWNLOAD_REQUEST
} from "../../../actions/showroom/composition/downloads";

const initialState = { 
    loading: null, 
    galleryExcelUrl: null, 
    galleryAssetsUrl: null,
    actionType: null, 
    error: null, 
    excelSent: false 
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EXCEL_GALLERIES_REQUEST:
        case GET_EXCEL_URL_REQUEST:
            return {
                ...state,
                loading: true,
                actionType: 'GET'
            };
        case GET_EXCEL_URL_SUCCESS:
            return {
                ...state,
                loading: false,
                galleryExcelUrl: action.excelInfos.temporary_xlsx_url,
            };
        case GET_EXCEL_GALLERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                excelSent: true
            };
        case GET_EXCEL_GALLERIES_FAILURE:
        case GET_EXCEL_URL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case CLEAR_GALLERY_DOWNLOAD_INFO:
            return initialState;
        case GALLERY_ASSETS_DOWNLOAD_REQUEST:
            return {
                ...state,
                loading: true,
                actionType: 'POST'
            };
        case GALLERY_ASSETS_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                galleryAssetsUrl: action.assetsInfo.zip_url
            };
        case GALLERY_ASSETS_DOWNLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};
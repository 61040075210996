import {GET_L3_PRODUCT_FAILURE, GET_L3_PRODUCT_REQUEST, GET_L3_PRODUCT_SUCCESS} from "../../actions/search/l3_product";

const initialState = {l3_product: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_L3_PRODUCT_REQUEST:
            return {
                loadingL3Product: true
            };
        case GET_L3_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingL3Product: false,
                l3_product: action.l3_product,
            };
        case GET_L3_PRODUCT_FAILURE:
            return {
                ...state,
                loadingL3Product: false,
                error: action.error
            }
        default:
            return state;
    }
}
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.main,
        textAlign: "left",
        maxHeight: "33vh",
        overflowY: "auto"
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));
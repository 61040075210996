import {CLEAR_FABRIC_BY_STYLE, GET_FABRIC_FAILURE, GET_FABRIC_REQUEST, GET_FABRIC_SUCCESS} from "../actions/fabric";

const initialState = { fabric: undefined };

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_FABRIC_BY_STYLE:
            return {
                fabric: undefined,
                error: undefined
            }
        case GET_FABRIC_REQUEST:
            return {
                loadingFabric: true
            };
        case GET_FABRIC_SUCCESS:
            return {
                ...state,
                loadingFabric: false,
                fabric: action.fabric,
            };
        case GET_FABRIC_FAILURE:
            return {
                ...state,
                loadingFabric: false,
                error: action.error
            }
        default:
            return state;
    }
}
import React from "react";
import {Grid} from "@material-ui/core";
import ReusableModal from "../../Modal/ReusableModal/ReusableModal";
import InputTextElement from "../../InputTextElement/InputTextElement";
import DropDownElement from "../../DropDownElement/DropDownElement";
import AutocompleteMultivalueElement from "../../AutocompleteMultivalueElement/AutocompleteMultivalueElement";
import DatePickerCustomizable from "../../Pickers/Date/DatePickerCustomizable";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const languageOptions = [
	{ key: "it", label: "IT", value: "IT" },
	{ key: "en", label: "EN", value: "EN" },
];

const UserAddForm = (props) => {
	const {
		reusableModal,
		okReusableModal,
		closeReusableModal,
		callOptionList,
		nameOptionList,
		handleOnChange,
		roles,
		user,
	} = props;

	return (
		<ReusableModal
			reusableModal={reusableModal}
			submitClick={okReusableModal}
			closeReusableModal={closeReusableModal}>
			<Grid container direction="row" justify="center" spacing={1}>
				<Grid item xs={4}>
					<InputTextElement
						fullWidth={true}
						name={"first_name"}
						value={user.first_name || ""}
						error={!!user.firstname_error || false}
						helperText={user.firstname_error ? user.firstname_error : null}
						label={"First Name"}
						labelId={"admin.users.first_name"}
						handleChange={(event) => handleOnChange(event, "first_name")}
						autoFocus={true}
					/>
				</Grid>
				<Grid item xs={4}>
					<InputTextElement
						fullWidth={true}
						name={"last_name"}
						value={user.last_name || ""}
						error={!!user.lastname_error || false}
						helperText={user.lastname_error ? user.lastname_error : null}
						label={"Last Name"}
						labelId={"admin.users.last_name"}
						handleChange={(event) => handleOnChange(event, "last_name")}
					/>
				</Grid>
				<Grid item xs={4}>
					<InputTextElement
						fullWidth={true}
						name={"nickname"}
						value={user.nickname || ""}
						label={"Nickname"}
						labelId={"admin.users.nickname"}
						handleChange={(event) => handleOnChange(event, "nickname")}
					/>
				</Grid>
				<Grid item xs={6}>
					<InputTextElement
						fullWidth={true}
						name={"email"}
						value={user.email || ""}
						error={!!user.email_error || false}
						helperText={user.email_error ? user.email_error : null}
						label={"Email"}
						labelId={"admin.users.email"}
						handleChange={(event) => handleOnChange(event, "email")}
					/>
				</Grid>
				<Grid item xs={6}>
					<InputTextElement
						fullWidth={true}
						name={"password"}
						value={user.password || ""}
						error={!!user.password_error || false}
						helperText={user.password_error ? user.password_error : null}
						label={"Password"}
						labelId={"admin.users.password"}
						handleChange={(event) => handleOnChange(event, "password")}
						type={"password"}
					/>
				</Grid>
				<Grid item xs={4}>
					<InputTextElement
						fullWidth={true}
						name={"telephone"}
						value={user.telephone || ""}
						error={!!user.telephone_error || false}
						helperText={user.telephone_error ? user.telephone_error : null}
						label={"Telephone"}
						labelId={"admin.users.telephone"}
						handleChange={(event) => handleOnChange(event, "telephone")}
					/>
				</Grid>

				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Grid container justify={"center"} alignItems={"center"} item xs={4}>
						<DatePickerCustomizable
							keyBoardInput
							clearable
							upLabel={{ id: "admin.users.expiration_date", defaultMessage: "Expiration Date" }}

							value={user.expiration_date || null}
							onChangeHandler={(date) => handleOnChange(date, "expiration_date")}
						/>
					</Grid>
				</MuiPickersUtilsProvider>

				<Grid item xs={4}>
					<DropDownElement
						fullWidth={true}
						upLabel={{ id: "admin.users.language", defaultMessage: "Language" }}
						name="language"
						value={user.language || ""}
						onChangeHandler={(data) => handleOnChange(data, "language")}
						options={languageOptions}
					/>
				</Grid>
				<Grid item xs={6}>
					<AutocompleteMultivalueElement
						handleOnChange={(event, autocompleteValues) =>
							handleOnChange(event, "roles", autocompleteValues)
						}
						// loading={loadings[rowData.name]}
						upLabel={{ id: "admin.users.roles", defaultMessage: "Roles" }}

						error={!!user.roles_error || false}
						helperText={user.roles_error ? user.roles_error : null}
						callOptionList={callOptionList}
						name={nameOptionList}
						optionList={roles || []}
						value={user.roles || []}
					/>
				</Grid>
				<Grid item xs={6}>
					<InputTextElement
						multiline
						fullWidth={true}
						labelId={"admin.users.notes"}
						label={"Notes"}
						// name={"notes"}
						value={user.notes || ""}
						handleChange={(event) => handleOnChange(event, "notes")}
					/>
				</Grid>
			</Grid>
		</ReusableModal>
	);
};

export default UserAddForm;

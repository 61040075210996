import {GET_LINELIST_FAILURE, GET_LINELIST_REQUEST, GET_LINELIST_SUCCESS} from "../actions/linelist";

const initialState = {pg_linelist: []};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LINELIST_REQUEST:
            return {
                loadingLinelist: true
            };
        case GET_LINELIST_SUCCESS:
            return {
                ...state,
                loadingLinelist: false,
                pg_linelist: action.pg_linelist,
            };
        case GET_LINELIST_FAILURE:
            return {
                ...state,
                loadingLinelist: false,
                error: action.error
            };
        default:
            return state;
    }
}
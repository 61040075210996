import {LOGGED_IN, LOGGED_OUT, LOGIN_ERROR, LOGIN_REQUEST, LOGOUT_REQUEST} from '../actions/auth';
import LocalStorage from "../localStorage";
import * as jwt from "jsonwebtoken";
import * as Sentry from "@sentry/react";

const localStorageService = LocalStorage.getService();

const persistedData = localStorageService.getAccessToken();
let defaultstate = persistedData === ""
    ? {state: 'LOGGED_OUT', isUserAuthenticated: false, wrong_credential: null, auth: persistedData}
    : {
        state: 'LOGGED_IN',
        isUserAuthenticated: true,
        auth: jwt.decode(persistedData, {complete: true}).payload,
    };

export default (state = defaultstate, action) => {
    switch (action.type) {
        case LOGGED_IN:
            Sentry.setUser({ 
                email: action.auth.email,
                id: action.auth.user_id,
                username: action.auth.nickname
            });

            return {
                auth: action.auth,
                state: 'LOGGED_IN',
                loading: false,
                isUserAuthenticated: true,
                wrong_credential: false
            };
        case LOGGED_OUT:
            Sentry.configureScope(scope => scope.setUser(null));

            return {
                auth: {},
                state: 'LOGGED_OUT',
                loading: false,
                isUserAuthenticated: false,
                wrong_credential: null
            };
        case LOGIN_REQUEST:
            return {
                ...state,
                state: 'LOGGING',
                loading: true,
                wrong_credential: null,
                isUserAuthenticated: false
            };
        case LOGOUT_REQUEST:
            return {
                ...state,
                state: 'LOGGING_OUT',
                loading: true,
                wrong_credential: null,
                isUserAuthenticated: true
            };
        case LOGIN_ERROR:
            return {
                ...state,
                state: "LOGIN_ERROR",
                wrong_credential: true,
                loading: false
            };
        default:
            return state;
    }
};

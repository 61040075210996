import React, {useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import logo from "../../assets/images/logo.png";
import logo_dev from "../../assets/images/logo_dev.png";
import logo_qas from "../../assets/images/logo_qas.png";
import IconButton from '@material-ui/core/IconButton';
import Icon from "@material-ui/core/Icon";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import {NavLink} from "react-router-dom";
import {useStyles} from "./UpperBarComponentStyle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ListItemText from "@material-ui/core/ListItemText";

const UpperBarComponent = props => {

    const classes = useStyles();
    const { user, path, handleLogout, pathLabel, pathLabel2, pathLabel3 } = props;
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    // console.log(`${process.env.REACT_APP_ENV}`);
    // console.log("path ", path, "path 0", path.split("/")[0], "path 1", path.split("/")[1], "path 2", path.split("/")[2], "path 3", path.split("/")[3], "path 4", path.split("/")[4], 'pathLabel: ', pathLabel, 'pathLabel2: ', pathLabel2, 'pathLabel3: ', pathLabel3);
    const handleAccountClose = () => {
        setAccountAnchorEl(null);
    };
    const handleAccountClick = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };

    return (
        <AppBar position="fixed" className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={0}>
                    <Grid item container justify="center" xs={7} md={4}>
                        <Box>
                            <img src={`${process.env.REACT_APP_ENV}` === "development" ? logo_dev : (`${process.env.REACT_APP_ENV}` === "qas" ? logo_qas : logo)}
                                 alt={'Giorgio Armani'} className={classes.logo}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box display="flex" alignItems="center" justifyContent="space-around">
                            {path !== "/home" &&
                            <Breadcrumbs aria-label="breadcrumb" className={classes.link}>
                                <Link component={NavLink} to={"/"} className={classes.link}>
                                    <HomeIcon className={classes.icon} fontSize='small'/></Link>
                                {
                                    path.split("/")[2] && pathLabel ?
                                        <Link
                                            component={NavLink}
                                            to={"/" + path.split("/")[1]}
                                            className={classes.link}>
                                            {pathLabel}
                                        </Link> :
                                        pathLabel ?
                                            <span>{pathLabel}</span> : null
                                }
                                {
                                    path.split("/")[2] && path.split("/")[3] && pathLabel2 ?
                                        <Link
                                            component={NavLink}
                                            to={"/".concat(path.split("/")[1], "/", path.split("/")[2])}
                                            className={classes.link}>
                                            {pathLabel2}
                                        </Link> :
                                        pathLabel2 ?
                                            <span>{pathLabel2}</span> :
                                            null
                                }
                                {
                                    /*path.split("/")[3] && path.split("/")[4] && pathLabel3 ?
                                        <Link
                                            component={NavLink}
                                            to={path.split("/")[2]}
                                            className={classes.link}>
                                            {pathLabel2}
                                        </Link> :*/
                                    pathLabel3 ?
                                        <span>{pathLabel3}</span> :
                                        null
                                }
                            </Breadcrumbs>}
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <div data-cy="account-button">
                                <IconButton
                                    edge="start"
                                    aria-label="account of current user"
                                    aria-haspopup="true"
                                    className={classes.accountButton}
                                    onClick={handleAccountClick}
                                >
                                    <Icon>account_circle</Icon>
                                </IconButton>
                                <Menu
                                    anchorEl={accountAnchorEl}
                                    keepMounted
                                    open={Boolean(accountAnchorEl)}
                                    onClose={handleAccountClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    classes={{ paper: classes.paperMenu }}
                                >
                                    <MenuItem onClick={handleLogout} className={classes.menuItem} dense>
                                        <ListItemText primary="Logout " className={classes.logoutText}/>
                                        <ExitToApp className={classes.logoutText} fontSize="small"/>
                                    </MenuItem>
                                </Menu>
                                <Typography variant="subtitle1" noWrap>
                                    {user.first_name + " " + user.last_name}
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default UpperBarComponent;
import {
    CLEAR_UPLOAD_ASSETS,
    GET_SIGNED_URL_ASSET_FAILURE,
    LOAD_ASSETS_FAILED,
    PHYSICAL_UPLOAD_COMPLETE,
    POST_UPLOAD_ASSETS_REQUEST,
    POST_UPLOAD_ASSETS_SUCCESS,
    PUT_ASSET_FAILURE,
    PUT_ASSET_SUCCESS
} from "../../../actions/showroom/composition/uploadAssets";

const initialState = {
    loadingUploadShowroom: false,
    numberError: 0,
    numberSuccess: 0,
    processed: 0,
    totalAssets: 0,
    uploadedAssets: [],
    failedAssets: [],
    physicalUploadComplete: false,
    error: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_UPLOAD_ASSETS:
            return initialState;
        case POST_UPLOAD_ASSETS_REQUEST:
            return {
                ...state,
                totalAssets: action.totalAssets,
                loadingUploadShowroom: true
            };
        case POST_UPLOAD_ASSETS_SUCCESS:
            return {
                ...state,
                loadingUploadShowroom: false
            };
        case GET_SIGNED_URL_ASSET_FAILURE:
        case PUT_ASSET_FAILURE:
            return {
                ...state,
                numberError: state.numberError + 1,
                processed: state.processed + 1,
                failedAssets: [
                    ...state.failedAssets,
                    action.asset.name
                ],
                loadingUploadShowroom: state.numberError + 1 === state.totalAssets ? false : state.loadingUploadShowroom
            };
        case PUT_ASSET_SUCCESS:
            return {
                ...state,
                numberSuccess: state.numberSuccess + 1,
                processed: state.processed + 1,
                uploadedAssets: [
                    ...state.uploadedAssets,
                    {
                        filename: action.asset.name,
                        display_filename: action.asset.display_name,
                        type: action.asset.type
                    }
                ]
            };
        case LOAD_ASSETS_FAILED:
            return {
                ...state,
                loadingUploadShowroom: false,
                finalizedError: true
            };
        case PHYSICAL_UPLOAD_COMPLETE:
            return {
                ...state,
                physicalUploadComplete: true
            };
        default:
            return state;
    }
}
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    gridRoot: {
        backgroundColor: theme.palette.secondary.main,
        minHeight: "450px",
        maxHeight: "500px",
        '& .Mui-Grid-root': {
            maxHeight: "500px",
            minHeight: "450px",
        }
    },
    paperImage: {
        margin: theme.spacing(2),
        '& .MuiPaper-root': {
            height: '140px',
            width: '100px',
        }
    },
    paper: {
        position: "relative",
        '& img': { display: "inline-block" }
    },
    icon: {
        position: "absolute",
        top: 0,
        right: 0
    },
    label: {
        fontSize: "x-small",
        textAlign: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: theme.palette.text.main
    },
    img: {
        border: '4px solid '.concat(theme.palette.deleted.main)
    }
}));